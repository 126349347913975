import { FiFireIcon } from '@/assets/icons';
import { Box, Flex, Tab, TabList, TabPanel, TabPanels, Tabs, Text } from '@chakra-ui/react';
import { TrendingToken } from './TrendingToken';
import { MemeCoin } from './Memecoin';
import { BASE_BORDER_COLOR } from '@/app-theme/theme';

export const TrendingTabs = () => {
	return (
		<Flex direction={'column'} bgColor="white" borderRadius={8} p={4} gap={4}>
			<Flex justifyContent={'space-between'} borderBottom={`1px solid ${BASE_BORDER_COLOR}`} pb={2}>
				<Flex alignItems={'center'} gap={2} fontSize={'12px'} fontWeight={'500'}>
					<FiFireIcon />
					<Text lineHeight={'24px'} fontSize="xs" fontWeight={600}>
						Trending
					</Text>
				</Flex>
			</Flex>
			<TrendingToken />
		</Flex>
	);
	return (
		<Box bgColor="white" borderRadius={8} px={4} py={1}>
			<Tabs isLazy>
				<TabList borderBottom="1px" borderColor="rgba(0, 0, 0, 0.08)">
					<Tab
						fontSize="xs"
						fontWeight={600}
						_selected={{
							color: 'black',
							borderColor: 'black',
						}}
					>
						<FiFireIcon /> <Text ml={1}>Trending</Text>
					</Tab>
					{/* <Tab
						fontSize="xs"
						fontWeight={600}
						_selected={{
							color: 'black',
							borderColor: 'black',
						}}
					>
						Memecoin
					</Tab> */}
				</TabList>
				<TabPanels>
					<TabPanel px={0}>
						<TrendingToken />
					</TabPanel>
					{/* <TabPanel px={0}>
						<MemeCoin />
					</TabPanel> */}
				</TabPanels>
			</Tabs>
		</Box>
	);
};
