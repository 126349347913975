import { Card } from '@chakra-ui/react';
import NoData from '@/app-components/common/NoData';
import { useTranslation } from 'react-i18next';

export const EmptyContact = ({ searchKey }: { searchKey: string }) => {
	const { t } = useTranslation();
	return (
		<Card p={4}>
			<NoData msg={searchKey ? t('contact.noMatch') : t('contact.noData')} />
		</Card>
	);
};
