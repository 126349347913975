import { CHAIN_CONFIG } from '@/app-constants/chains';
import { getSigner } from '@/app-helpers/web3';
import { usePendingEvmTransaction, useTransactionWatcherStore } from '@/app-store';
import { TransactionType } from '@/app-types';
import { useMutation } from '@tanstack/react-query';
import { TransactionResponse } from 'ethers';
import { useEstimateSpeedUpGasFee } from '.';

const BUFFER_GAS_PERCENT = 20n;

export function useSpeedUpTransaction({
	txHash,
	transactionType,
	bufferGasPercent = BUFFER_GAS_PERCENT,
}: {
	txHash: string;
	transactionType: TransactionType;
	bufferGasPercent?: bigint;
}) {
	let transactionRs: TransactionResponse | null = null;
	const { addPendingEvmTransaction } = useTransactionWatcherStore();
	const transaction = usePendingEvmTransaction(txHash);
	const { data: estSpeedUpGasFeeData } = useEstimateSpeedUpGasFee({
		transaction: transaction,
		bufferGasPercent: bufferGasPercent,
		hasGasSetting: transactionType === TransactionType.Send,
	});

	console.log('estSpeedUpGasFeeData', estSpeedUpGasFeeData);

	const chainId = Number(transaction?.chainId);
	const { mutateAsync: speedUpTransaction, ...result } = useMutation({
		mutationKey: ['speed-up-transaction', txHash],
		mutationFn: async () => {
			try {
				const chainConfig = CHAIN_CONFIG[chainId];
				const { signer, provider } = getSigner(chainId);
				const transactionRaw = await signer.signTransaction({
					to: transaction.to,
					data: transaction.data,
					value: transaction.value,
					chainId: Number(transaction.chainId),
					nonce: transaction.nonce,
					type: transaction.type,
					gasLimit: transaction.gasLimit,
					...(chainConfig.isEIP1559 && {
						maxPriorityFeePerGas: estSpeedUpGasFeeData?.maxPriorityFeePerGas,
						maxFeePerGas: estSpeedUpGasFeeData?.maxFeePerGas,
					}),
					...(!chainConfig.isEIP1559 && {
						gasPrice: estSpeedUpGasFeeData?.gasPrice,
					}),
				});
				transactionRs = await provider.broadcastTransaction(transactionRaw);
				console.log('Speed up transaction result:', transactionRs);
				return transactionRs;
			} catch (error) {
				console.log('Speed up transaction error', error);
				throw new Error(error);
			}
		},
		onSuccess: (receipt) => {
			addPendingEvmTransaction({ transaction: receipt, metadata: { transactionType } });
		},
	});
	return {
		...result,
		estSpeedUpGasFeeData,
		speedUpTransaction,
		chainId: chainId,
	};
}
