import { useMutation } from '@tanstack/react-query';
import { MpcWallet } from '@/app-cores/mpc-wallet/wallet';
import { isNativeToken } from '@/app-helpers/address';
// import { useTransactionWatcherStore } from '../../../app-store';
import { tronWallet } from '@/app-cores/mpc-wallet/tron';
import { TransactionType, TSentTronTransaction } from '@/app-types';
import { getTronTransferBalance } from '@/app-helpers/tron';
import { useTransactionWatcherStore } from '@/app-store';
import { ChainId } from '@/app-constants/chains';

const signWithExtend = async (unsignedTransaction, extendTimeInSeconds?: number) => {
	if (extendTimeInSeconds) {
		const newTransaction = await tronWallet.tronWeb.transactionBuilder.extendExpiration(
			unsignedTransaction,
			extendTimeInSeconds,
		);
		return await tronWallet.signTransaction(newTransaction);
	}
	return await tronWallet.signTransaction(unsignedTransaction);
};

async function sendTron(sendData: TSentTronTransaction, extendTimeInSeconds?: number) {
	try {
		// if u want to auto deduct fee, please careful with this, swap txs is also use this function
		const amount = getTronTransferBalance(sendData.amount, sendData.token.decimals) as any;

		const unsignedTransaction = await tronWallet.tronWeb.transactionBuilder.sendTrx(
			sendData.to,
			amount,
			MpcWallet.getTronWalletAddress(),
		);
		return signWithExtend(unsignedTransaction, extendTimeInSeconds);
	} catch (error) {
		console.error('sendTron error:', error);
		throw error;
	}
}
async function sendTRC20(sendData: TSentTronTransaction, extendTimeInSeconds?: number) {
	try {
		const unsignedTransaction = await tronWallet.tronWeb.transactionBuilder.triggerSmartContract(
			sendData.token.address,
			'transfer(address,uint256)',
			{
				feeLimit: 50000000,
				callValue: 0,
			},
			[
				{ type: 'address', value: sendData.to },
				{ type: 'uint256', value: getTronTransferBalance(sendData.amount, sendData.token.decimals) as any },
			],
			MpcWallet.getTronWalletAddress(),
		);

		return signWithExtend(unsignedTransaction.transaction, extendTimeInSeconds);
	} catch (error) {
		console.error('TransferTrc20 error:', error);
		throw error;
	}
}

export const prepareSendTronToken = async (params: TSentTronTransaction, extendTimeInSeconds?: number) => {
	if (isNativeToken(params.token.address)) {
		return sendTron(params, extendTimeInSeconds);
	} else {
		return sendTRC20(params, extendTimeInSeconds);
	}
};

export function useSentTronToken() {
	const { addPendingTronTransaction } = useTransactionWatcherStore();
	const { mutateAsync: sendTransaction, ...result } = useMutation({
		mutationKey: ['sent-tron-transaction'],
		mutationFn: async (params: TSentTronTransaction) => {
			const signedTransaction = await prepareSendTronToken(params);
			const resp = await tronWallet.sendRawTransaction(signedTransaction);
			if (resp.code) throw resp.code;
			return resp;
		},
		onSuccess: (data, { to, token, message, amount, metadata, transactionType = TransactionType.Send }) => {
			const hash = data.transaction.txID;
			addPendingTronTransaction({
				hash: data.transaction.txID,
				trackingData: metadata,
			});
		},
	});
	return {
		...result,
		sendTransaction,
	};
}
