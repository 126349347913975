import { Box, Flex, Text } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
interface IntroItemProps {
	img: React.ReactNode;
	title: string;
	description: string;
}
export const IntroItem: React.FunctionComponent<IntroItemProps> = ({ description, img, title }) => {
	const { t } = useTranslation();

	return (
		<Flex className="item" gap={5} mt={8}>
			<Box>{img}</Box>
			<Box>
				<Text fontWeight={500}>{t(title)}</Text>
				<Text fontSize="sm" color="gray.400">
					{t(description)}
				</Text>
			</Box>
		</Flex>
	);
};
