import { isMobile } from 'react-device-detect';

export const useHideKeyboardOnEnter = (onKeyDown) => {
	const onHideKeyBoard = (event) => {
		if (event.key === 'Enter' && isMobile) {
			event.target?.blur();
		}
		onKeyDown?.(event);
	};

	return onHideKeyBoard;
};
