import { AppLogoIconCircle } from '@/assets/images/svg';
import { CheckCircleIcon } from '@/assets/images/svg/check-circle-icon';
import {
	Box,
	Button,
	Card,
	Center,
	Drawer,
	DrawerBody,
	DrawerContent,
	DrawerFooter,
	DrawerHeader,
	DrawerOverlay,
	Image,
	List,
	ListItem,
	Text,
} from '@chakra-ui/react';
import { toast } from 'react-toastify';
import { useModalStore } from '../store/ModalStore';
import { useManifest } from '../hooks/useManifest';
import { useConnectMutation, useRejectMutation } from '../hooks/useMutationConnect';
import { Toast } from '@/app-components/common';
import { useTAppConnections } from '../store/ConnectionStore';
import { ConnectionType } from '@/app-types/connection-type';
import { paringTrackingManager } from '@/app-features/app-wallet-connect/utils/HelperUtil';
import { getValidReturnStrategy } from '../utils/common';

export default function SessionProposalModal() {
	const { data, open, onClose, setReturnStrategyData } = useModalStore();
	const { data: manifest } = useManifest(data.connectParams?.request);
	const { mutateAsync: onConnect, isPending } = useConnectMutation();
	const { mutateAsync: onReject, isPending: isRejectLoading } = useRejectMutation();
	const { addConnection } = useTAppConnections();
	if (!data?.connectParams || !manifest) return null;

	const handleApprove = async () => {
		if (isPending) return;
		try {
			await onConnect(data.connectParams);
			addConnection({
				clientSessionId: data.connectParams.clientSessionId,
				manifest: manifest,
				sessionKeyPair: data.connectParams.sessionKeyPair,
			});
			paringTrackingManager.setActiveTime(ConnectionType.TonConnect, data.connectParams.clientSessionId);
			toast(<Toast type="success" message={`Connect with ${manifest.name} successfully`} />);
		} catch (error) {
			console.error('Connect with tApp error', error);
			toast(<Toast type="error" message={`Connect with ${manifest.name} Failed: ${error.message}`} />);
		} finally {
			onClose();
			setReturnStrategyData({
				isOpen: true,
				dappName: manifest.name,
				returnStrategy: getValidReturnStrategy(data.connectParams.returnStrategy),
			});
		}
	};
	const handleReject = async () => {
		if (isRejectLoading) return;
		try {
			await onReject(data.connectParams);
		} catch (error) {
			console.error('Connect with tApp error', error);
		} finally {
			const rt = getValidReturnStrategy(data.connectParams.returnStrategy);
			setReturnStrategyData({
				isOpen: true,
				dappName: manifest.name,
				returnStrategy: rt,
				isRejected: true,
			});

			onClose();
		}
	};
	return (
		<Drawer isOpen={open} placement="bottom" onClose={onClose} trapFocus={false}>
			<DrawerOverlay />
			<DrawerContent>
				<DrawerHeader>
					<Center gap={2}>
						<AppLogoIconCircle />
						<Image
							width={16}
							height={16}
							borderRadius="50%"
							src={manifest?.iconUrl}
							alt=""
							fallbackSrc="/assets/images/dapp-fallback.svg"
						/>
					</Center>

					<Text textAlign="center" mt={4}>
						Connect with {manifest?.name}
					</Text>
					<Text
						mt={2}
						textAlign="center"
						as="a"
						display="block"
						fontSize="xs"
						href={manifest.url}
						target="_blank"
						color="gray"
					>
						{manifest.url}
					</Text>
				</DrawerHeader>

				<DrawerBody pt={0}>
					<Card p={5}>
						<Box>
							<Text fontSize="xs" fontWeight={500}>
								PERMISSION REQUEST
							</Text>
							<Text py={5} fontSize="sm">
								By connecting, you're allowing the site to perform these actions:
							</Text>
							<hr />
						</Box>
						<Box pt={5}>
							<List>
								<ListItem display="flex" alignItems="center" gap={2}>
									<CheckCircleIcon className="me-1" />
									<Text fontSize="sm">View your balance & activities</Text>
								</ListItem>
								<ListItem display="flex" alignItems="center" gap={2} mt={3}>
									<CheckCircleIcon className="me-1" />
									<Text fontSize="sm">Send approval requests</Text>
								</ListItem>
							</List>
						</Box>
					</Card>
				</DrawerBody>

				<DrawerFooter display="flex" flexDirection="column" gap={2}>
					<Button
						size="lg"
						fontWeight="medium"
						width="100%"
						onClick={handleReject}
						isLoading={isRejectLoading}
					>
						Deny
					</Button>
					<Button
						isLoading={isPending}
						size="lg"
						fontWeight="medium"
						colorScheme="cyan"
						width="100%"
						onClick={handleApprove}
					>
						Approve
					</Button>
				</DrawerFooter>
			</DrawerContent>
		</Drawer>
	);
}
