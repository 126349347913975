import { axiosBotAPI } from '@/app-cores/api/axios';
import { IActivity, ObserverTransaction, WalletActivity } from './types';
import { parseStartParams } from '../../telegram';
import { TEST_NETS } from '@/app-constants/chains';
import { isEmpty } from 'lodash';

export type GetActivityParams = {
	chainId?: string;
	tokenAddress?: string;
	includeSpam?: boolean;
	includeTestnet?: boolean;
};
export type GetActivityDetailParams = {
	activityKeys: {
		tobiId: string;
		chainId: string;
	}[];
};
export type GetWalletActivityParams = {
	includeSpam?: boolean;
	excludeChainIds?: string[];
};

export type SwapFeeResponse = {
	fee: number;
	feeAmount?: string; // bigint
	percentage: number;
	wallets: { EVM: string; SOL: string; TON: string; TRON: string };
};
class ActivitiesService {
	async getActivities({ includeTestnet, ...payload }: GetActivityParams = {}): Promise<IActivity[]> {
		const response = await axiosBotAPI.get(`/transaction/transactions/me`, {
			params: {
				offset: 0,
				limit: 50,
				excludeChainIds:
					includeTestnet || includeTestnet === undefined ? undefined : TEST_NETS.map((e) => e.id).join(','),
				...payload,
			},
		});
		return response.data.data?.transactions;
	}
	async getWalletActivities({ includeTestnet, ...payload }: GetActivityParams = {}): Promise<WalletActivity[]> {
		const response = await axiosBotAPI.post(`/v2/wallet/portfolio/activity`, {});

		return response.data.data;
	}

	async submitMetadataSwapTxs(payload: ObserverTransaction) {
		return axiosBotAPI.post('/fee/tobi-fee', payload);
	}

	async submitMetadataSwap2LegTxs(payload: {
		metadata: {
			fromChain: string;
			toChain: string;
			fromToken: string;
			toToken: string;
			providerId: string;
			toTokenSymbol: string;
			fromTokenSymbol: string;
		};
		hash: string;
		provider: string;
	}) {
		return axiosBotAPI.post('/swap/create-two-leg-swap', payload);
	}

	async checkSwapTransaction(payload: {
		hash?: string;
		provider?: string;
		providerId?: string;
		isSameChain?: boolean;
		walletAddress?: string;
		chainId?: string;
	}): Promise<any> {
		const data = await axiosBotAPI.post('/fee/check-swap', payload);
		return data.data.data;
	}
	async getSwapFee({ amount }: { amount: string }): Promise<SwapFeeResponse> {
		const data = await axiosBotAPI.get('/fee/tobi-fee', {
			params: { amount },
		});
		return data.data.data;
	}
}

export const ActivitiesServiceAPI = new ActivitiesService();
