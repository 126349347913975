import { Box, Center, Skeleton, useDisclosure } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { isNativeTobiToken } from '@/app-helpers/address';
import { useNavigateToSentPage, useNavigateToSwapPage } from '@/app-helpers/navigate';
import { usePortfolioBalanceByCategories } from '@/app-hooks/api/portfolio/usePortfolioBalance';
import { useOnEventCallback } from '@/app-hooks/common';
import { MenuActionItem } from '@/app-views/portfolio/pages/token/components/MenuAction';
import { FiClockIcon, FiDownLeftIcon, FiPlusIcon, FiSwapIcon, FiUpRightIcon } from '@/assets/icons';
import { DATADOG_ACTIONS, dataDogAddAction } from '@/app-services/monitor/datadog';
import { useDepositStore } from '@/app-features/deposit/store';
import { ChainId } from '@/app-constants/chains';
import { NAVIGATE_PATHS } from '@/app-constants/router';
import { TOUR_GUIDE_STEPS_TARGET } from '../TourGuide/stepConfigs';
import { FeatureConfigWrap } from '@/app-helpers/components';
import { FeatureConfig, useQueryFeatureConfig } from '@/app-hooks/api/configuration';
import { useQueryGetRequestFund } from '@/app-hooks/api/payment/useClaimFund';
import { Badge, RequireWallet } from '@/app-components/common';
import { useCountSol2Tcat } from '@/app-hooks/swap/sol_2_tcat';
import { useEffect } from 'react';
import { useParseBotStartRedirectParams } from '@/app-cores/telegram';
import { Actions } from '@/app-features/app-bot-connector/hooks';
import { getNativeTobiId } from '@/app-helpers/token';

interface MenuActionProps {}

let showedActity = false;
export const MenuAction: React.FC<MenuActionProps> = () => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const { isOpen, onOpen, onClose } = useDisclosure();
	const { onOpen: onOpenDeposit, setInitToken } = useDepositStore();

	const {
		data: { mainTokens },
	} = usePortfolioBalanceByCategories();
	const navigateToSentPage = useNavigateToSentPage();

	const onSendClick = useOnEventCallback(() => {
		const firstNativeBalance = mainTokens?.find((e) => isNativeTobiToken(e)) || mainTokens?.[0];
		navigateToSentPage({ token: firstNativeBalance });
	});

	const navigateToSwap = useNavigateToSwapPage();
	const onSwapClick = useOnEventCallback(() => {
		dataDogAddAction(DATADOG_ACTIONS.TRADE);
		navigateToSwap({
			tokenIn: getNativeTobiId(ChainId.SOL),
			tokenOut: getNativeTobiId(ChainId.TON),
			chainIn: ChainId.SOL,
			chainOut: ChainId.TON,
		});
	});

	const onDepositClick = useOnEventCallback(() => {
		dataDogAddAction(DATADOG_ACTIONS.DEPOSIT);
		setInitToken(null);
		onOpenDeposit();
	});

	const onRequestFundClick = useOnEventCallback(() => {
		navigate(NAVIGATE_PATHS.RequestFund.Main);
	});

	const onHistoryClick = useOnEventCallback(() => {
		onOpen();
	});

	const { action } = useParseBotStartRedirectParams();
	const [searchParams] = useSearchParams();
	const actionParam = searchParams.get('action');
	useEffect(() => {
		if ((action === Actions.ACTIVITIES && !showedActity) || actionParam === Actions.ACTIVITIES) {
			onOpen();
			showedActity = true;
			navigate(NAVIGATE_PATHS.Home, { replace: true });
		}
	}, [action, actionParam, onOpen, navigate]);

	const isSupportRequestFund = useQueryFeatureConfig(FeatureConfig.REQUEST_FUND);
	const { data } = useQueryGetRequestFund(isSupportRequestFund);

	useCountSol2Tcat();
	const totalBadge = data?.totalToPaid ?? 0;
	return (
		<Center width={'100%'}>
			<Box display="flex" maxWidth={'350px'} justifyContent="center" mt={4} flex={1}>
				<RequireWallet
					loadingComponent={<Skeleton height="78px" width="78px" startColor="white" endColor="#EBEFF6" />}
				>
					<MenuActionItem
						text={t('home.wallet.send')}
						to={null}
						onClick={onSendClick}
						wrapperId={TOUR_GUIDE_STEPS_TARGET.HOME_PAGE.SEND}
					>
						<FiUpRightIcon />
					</MenuActionItem>
				</RequireWallet>

				<FeatureConfigWrap feature={FeatureConfig.REQUEST_FUND}>
					<RequireWallet
						loadingComponent={<Skeleton height="78px" width="78px" startColor="white" endColor="#EBEFF6" />}
					>
						<MenuActionItem text={t('home.wallet.requestFund')} to={null} onClick={onRequestFundClick}>
							<FiDownLeftIcon />
						</MenuActionItem>
					</RequireWallet>
				</FeatureConfigWrap>

				<RequireWallet
					loadingComponent={<Skeleton height="78px" width="78px" startColor="white" endColor="#EBEFF6" />}
				>
					<MenuActionItem
						text={t('home.wallet.deposit')}
						to={null}
						onClick={onDepositClick}
						wrapperId={TOUR_GUIDE_STEPS_TARGET.HOME_PAGE.DEPOSIT}
					>
						<FiPlusIcon />
					</MenuActionItem>
				</RequireWallet>

				<RequireWallet
					loadingComponent={<Skeleton height="78px" width="78px" startColor="white" endColor="#EBEFF6" />}
				>
					<MenuActionItem
						text={t('home.wallet.swap')}
						to={null}
						onClick={onSwapClick}
						wrapperId={TOUR_GUIDE_STEPS_TARGET.HOME_PAGE.TRADE}
					>
						<FiSwapIcon />
					</MenuActionItem>
				</RequireWallet>

				{/* <RequireWallet
					loadingComponent={<Skeleton height="78px" width="78px" startColor="white" endColor="#EBEFF6" />}
				>
					<MenuActionItem
						id="activityHome" 
						text={t('home.wallet.activity')}
						to={null}
						onClick={onHistoryClick}
						wrapperId={TOUR_GUIDE_STEPS_TARGET.HOME_PAGE.ACTIVITY}
					>
						<FiClockIcon fill="black" />
						{!!totalBadge && (
							<Badge type="danger" sx={{ position: 'absolute', right: '-10px', top: '-4px' }}>
								{totalBadge}
							</Badge>
						)}
						<Activities isOpen={isOpen} onClose={onClose} />
					</MenuActionItem>
				</RequireWallet> */}
			</Box>
		</Center>
	);
};
