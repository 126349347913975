import { motion } from 'framer-motion';
import React from 'react';
interface BackDropProps {}
export const BackDrop: React.FC<React.PropsWithChildren<BackDropProps>> = ({ children }) => {
	return (
		<motion.div
			style={{
				position: 'absolute',
				top: 0,
				left: 0,
				width: '100%',
				height: '100%',
				zIndex: 10,
				backgroundColor: 'rgba(0, 0, 0, 0.5)',
			}}
			initial={{
				opacity: 0,
			}}
			animate={{
				opacity: 1,
			}}
			exit={{
				opacity: 0,
			}}
		>
			{children}
		</motion.div>
	);
};
