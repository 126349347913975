import { CHAIN_CONFIG, ChainId, TEST_NETS, MAINNET_CHAINS } from '@/app-constants/chains';
import { ITokenSearch } from '@/app-cores/api/bff';
import { formatUsd } from '@/app-helpers/number';
import { Box, Text, Flex, Skeleton } from '@chakra-ui/react';
import { useMemo, useRef } from 'react';
import { usePortfolioState } from './state';
import { FiGasIcon } from '@/assets/icons/fi-gas-icon';
import { compareChain, isNativeToken } from '@/app-helpers/address';
import { SnapList, SnapItem, useDragToScroll } from 'react-snaplist-carousel';
import { NetWorkChain } from '@/app-contexts/wallet-provider/type';
import { FeatureConfig, useQueryFeatureConfig } from '@/app-hooks/api/configuration';
import useChainList from '@/app-hooks/wallet/useChainList';
import { TokenLabel } from '@/app-views/portfolio/components/ListCrypto';
import { getTokenInfo, isTestnetChain } from '@/app-helpers/token';

const ChainItem = ({
	balance: { totalUsd, hasNative, chainId, percent },
	onClickChain,
}: {
	balance: ChainBalance;
	onClickChain: (e: string) => void;
}) => {
	const { selectedChain, setSelectedChain } = usePortfolioState();
	const chain = CHAIN_CONFIG[chainId];
	const testnet = isTestnetChain(chainId);
	return (
		<Flex
			opacity={!selectedChain || selectedChain === chain.id ? 1 : 0.4}
			onClick={() => {
				const v = selectedChain === chain.id ? '' : chain.id;
				setSelectedChain(v);
				onClickChain(v.toString());
			}}
			backgroundColor="gray.100"
			minWidth={'100px'}
			justifyContent="center"
			alignItems="center"
			flexDirection="column"
			padding={3}
			height={'100px'}
			borderRadius="1rem"
			gap={'4px'}
			cursor={'pointer'}
			position={'relative'}
		>
			{!hasNative && <FiGasIcon style={{ position: 'absolute', right: 8, top: 8, width: 12 }} />}
			<img src={chain.logo} width={24} height={24} alt="" />
			<Text fontSize="10px">{chain.name}</Text>
			<Flex fontSize="10px" gap={'4px'}>
				<Flex direction={'column'} as="span" fontWeight={'500'} align={'center'} gap={1}>
					{testnet && <TokenLabel type="testnet" />}
					{formatUsd(totalUsd, { testnet })}
				</Flex>
				{!testnet && (
					<Text as="span" color="gray" fontWeight={'400'}>
						{percent.toFixed(2)}%
					</Text>
				)}
			</Flex>
		</Flex>
	);
};

export type ChainBalance = { totalUsd: number; percent: number; chainId: ChainId; hasNative: boolean };
const calcChainBalance = (balances: ITokenSearch[] = [], supportChains: NetWorkChain[]): ChainBalance[] => {
	let totalUsdAllChain = 0;
	const chainHasNative = {};
	const map = balances.reduce((rs, token) => {
		const { chainId, usdValue, address } = getTokenInfo(token);

		if (!supportChains.some((chain) => compareChain(chain.id, chainId))) {
			return rs;
		}

		if (!rs[chainId]) rs[chainId] = { totalUsd: 0, percent: 0, chainId, hasNative: false };
		rs[chainId].totalUsd += usdValue;
		totalUsdAllChain += usdValue;
		if (isNativeToken(address)) chainHasNative[chainId] = true;
		return rs;
	}, {} as Record<ChainId, ChainBalance>);

	let totalPercent = 0;
	const chainIds = Object.keys(map);
	chainIds.forEach((chain, i) => {
		const isLast = i === chainIds.length - 1;
		const percent = totalUsdAllChain ? map[chain].totalUsd / totalUsdAllChain : 0;

		map[chain].percent = isLast ? 1 - totalPercent : percent;
		map[chain].hasNative = chainHasNative[chain];
		totalPercent += percent;
	});

	supportChains.forEach(({ id: chainId }) => {
		if (map[chainId]) {
			map[chainId].percent *= 100;
			return;
		}
		map[chainId] = { totalUsd: 0, percent: 0, chainId, hasNative: false };
	});

	return Object.values(map).sort((a, b) => b.totalUsd - a.totalUsd);
};

export default function ChainSelector({
	balances,
	isLoading,
	onClickChain,
}: {
	balances: ITokenSearch[];
	isLoading: boolean;
	onClickChain: (e: string) => void;
}) {
	const { chainsSupport } = useChainList();
	const displayChains = useMemo(() => calcChainBalance(balances, chainsSupport), [balances, chainsSupport]);

	const refList = useRef<HTMLDivElement>(null);
	useDragToScroll({ ref: refList });

	return (
		<SnapList direction="horizontal" snapType="mandatory" ref={refList}>
			{isLoading
				? new Array(4).fill('').map((_, el) => (
						<SnapItem key={el} snapAlign="center" margin={{ right: '8px' }}>
							<Skeleton
								endColor={'gray.200'}
								height="100px"
								width={'100px'}
								borderRadius="1rem"
								key={el}
							/>
						</SnapItem>
				  ))
				: displayChains.map((el) => {
						return (
							<SnapItem key={el.chainId} snapAlign="center" margin={{ right: '8px' }}>
								<ChainItem balance={el} onClickChain={onClickChain} />
							</SnapItem>
						);
				  })}
		</SnapList>
	);
}
