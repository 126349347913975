import React from 'react';

interface IProps extends React.SVGAttributes<HTMLOrSVGElement> {}

// tobi logo black bg
export const AppLogoIconCircle: React.FC<IProps> = ({ width = 64, height = 64, ...rest }) => {
	return (
		<svg width={width} height={height} viewBox="0 0 64 65" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
			<rect y="0.791016" width="64" height="64" rx="32" fill="#161515" />
			<path
				d="M18.945 25.1129C19.727 20.6211 23.6259 17.3423 28.1853 17.3423H45.3874C49.0899 17.3423 51.9295 20.6289 51.3921 24.2921L48.7081 42.5879C47.9531 47.7346 43.5382 51.5492 38.3364 51.5492H22.8716C18.4185 51.5492 15.0417 47.5337 15.8055 43.1466L18.945 25.1129Z"
				fill="#00E9DB"
			/>
			<path
				d="M16.1872 22.3542C16.9691 17.8623 20.8681 14.5835 25.4275 14.5835H43.4461C46.4437 14.5835 48.7579 17.2195 48.3698 20.1919L45.6391 41.1057C45.0651 45.5019 41.3194 48.7904 36.8858 48.7904H20.1138C15.6607 48.7904 12.2839 44.7749 13.0476 40.3878L16.1872 22.3542Z"
				fill="#161515"
			/>
			<path
				d="M15.7499 22.3558C16.5312 17.8632 20.4305 14.5835 24.9905 14.5835H40.6877C43.6852 14.5835 45.9992 17.219 45.6116 20.1912L43.0196 40.0629C42.5536 43.6352 39.51 46.3076 35.9074 46.3076H20.1118C15.6592 46.3076 12.2826 42.293 13.0455 37.9063L15.7499 22.3558Z"
				fill="#00E9DB"
			/>
			<path
				d="M25.6539 17.3426C19.6953 13.1495 13.7919 15.5954 11.585 17.3426C15.3367 18.2254 17.3781 20.377 17.6539 22.0322L25.6539 17.3426Z"
				fill="#00E9DB"
			/>
			<path
				d="M22.0672 26.7216C22.0672 27.6357 21.3261 28.3768 20.412 28.3768C19.4979 28.3768 18.7568 27.6357 18.7568 26.7216C18.7568 25.8075 19.4979 25.0664 20.412 25.0664C21.3261 25.0664 22.0672 25.8075 22.0672 26.7216Z"
				fill="#161515"
			/>
			<path
				d="M41.9295 26.7216C41.9295 27.6357 41.1884 28.3768 40.2743 28.3768C39.3602 28.3768 38.6191 27.6357 38.6191 26.7216C38.6191 25.8075 39.3602 25.0664 40.2743 25.0664C41.1884 25.0664 41.9295 25.8075 41.9295 26.7216Z"
				fill="#161515"
			/>
			<path
				d="M25.1614 29.1845C24.8723 28.857 25.1263 28.377 25.5632 28.377H34.5727C35.0096 28.377 35.2636 28.857 34.9745 29.1845C32.3865 32.1162 27.7494 32.1162 25.1614 29.1845Z"
				fill="#161515"
			/>
			<path
				d="M36.9683 33.1343C34.5656 35.8439 35.1915 38.2485 36.2317 39.6611C36.6866 40.279 37.4435 40.5549 38.2095 40.5994C41.4939 40.7905 46.7047 40.7905 48.0715 40.7905C49.142 40.7905 51.106 40.3222 51.106 39.2517V34.854C51.106 34.4099 50.9689 33.9704 50.6722 33.6399C50.0154 32.9082 49.3976 32.5293 48.9115 32.3534C48.3752 32.1595 47.7843 32.2388 47.2141 32.2388H39.1351C38.3206 32.2388 37.5086 32.5249 36.9683 33.1343Z"
				fill="#161515"
			/>
		</svg>
	);
};
