import { useQuery } from '@tanstack/react-query';
import { EvmChainFamily, EvmSignTypedDataData, RequestMetadata } from '@blowfishxyz/api-client/v20230605';
import { BlowFistClientAPI } from '../../services/BlowFish/browser';

export const useScanSignTypeData = (
	requestId: number,
	payload: {
		typedData: EvmSignTypedDataData;
		userAccount: string;
		metadata: RequestMetadata;
		network: EvmChainFamily;
	},
) => {
	const res = useQuery({
		queryKey: ['blowfish-scan-sign-type-data', requestId],
		queryFn: async () => {
			return null;
			const data = await BlowFistClientAPI.scanSignTypedData(payload);
			return data;
		},
	});
	return res;
};
