import React from 'react';

interface IProps extends React.SVGAttributes<HTMLOrSVGElement> {}

export const FiTimeIcon: React.FC<IProps> = ({ width = 12, height = 12, fill = 'black', ...rest }) => {
	return (
		<svg
			stroke="currentColor"
			fill="currentColor"
			strokeWidth="0"
			viewBox="0 0 512 512"
			height={width}
			width={width}
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				fill="none"
				strokeMiterlimit="10"
				strokeWidth="32"
				d="M256 64C150 64 64 150 64 256s86 192 192 192 192-86 192-192S362 64 256 64z"
			></path>
			<path fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="32" d="M256 128v144h96"></path>
		</svg>
	);
};
