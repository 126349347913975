import { TelegramCore } from '@/app-cores/telegram';
import { ChainInfo, ConfirmTransactionArgs, KeygenArgs, SyncUserWalletArgs } from './types';
import { axiosBotAPI } from '@/app-cores/api/axios';

export const ServiceBot = {
	updateUserWalletAddress: async (args: KeygenArgs): Promise<void> => {
		await axiosBotAPI.post('/callback/keygen', args);
	},
	syncUserWalletAddress: async (args: KeygenArgs): Promise<void> => {
		await axiosBotAPI.post('callback/backfill-solana-address', args);
	},

	confirmTransaction: async ({ txid, sessionKey }: ConfirmTransactionArgs): Promise<void> => {
		await axiosBotAPI.post(
			'/bot/transaction',
			TelegramCore.withInitData({
				txid,
				sessionKey: sessionKey,
			}),
		);
	},

	getChainList: async (): Promise<ChainInfo[]> => {
		const response = await axiosBotAPI.get<{ chains: ChainInfo[] }>('/chain/list');
		return response.data.chains;
	},
	recoveryUserWallet: async (args: KeygenArgs): Promise<void> => {
		await axiosBotAPI.post('/callback/recovery', args);
	},
};
