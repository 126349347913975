import {
	AlertDialog,
	AlertDialogBody,
	AlertDialogContent,
	AlertDialogOverlay,
	Button,
	Center,
	Text,
} from '@chakra-ui/react';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FiUpArrowCircleIcon } from '@/assets/icons';
import { MpcWallet } from '@/app-cores/mpc-wallet/wallet';
import { useMutationDeleteBackupType } from '@/app-hooks/api/user/useMutationSetBackupType';
import keyshareManagerV2 from '@/app-cores/mpc-wallet/wallet/keyshare-manager-v2';
// import { useSyncUserWallet } from '@/app-hooks/api/mpc-wallet';
import { AuthClient } from '@/app-cores/auth';
import { queryClient } from '@/app-cores/query-client';
import { TelegramCore } from '@/app-cores/telegram';
import { STORAGE_KEYS } from '@/app-constants/storage';

interface CreateEddsaWalletProps {}

export const CreateEddsaWalletAlert: React.FC<CreateEddsaWalletProps> = () => {
	const [isOpen, setIsOpen] = useState(true);
	const [isLoading, setIsLoading] = useState(false);
	const cancelRef = React.useRef();
	const { mutateAsync: deleteBackupType } = useMutationDeleteBackupType();
	// const { mutateAsync: syncUserWallet } = useSyncUserWallet();
	const { t } = useTranslation();
	const handleCreateTonWallet = async () => {
		try {
			if (isLoading) return;
			setIsLoading(true);
			const { keyShare2, tonAddress, solAddress } = await MpcWallet.createEddsaWallet();
			// await syncUserWallet({
			// 	'ton-base': tonAddress,
			// 	'solana': solAddress,
			// });
			await AuthClient.authenticate();
			localStorage.removeItem(STORAGE_KEYS.TOBI_USE_BACKUP_KEYSHARE);
			MpcWallet.persistKeyShare();
			MpcWallet.storeUnSecuredEddsaKeyShare(keyShare2);
			setIsOpen(false);
			await deleteBackupType();
			queryClient.invalidateQueries({
				queryKey: ['query-backup-type', TelegramCore.getUserId()],
			});
		} catch (error) {
			console.error('create ton wallet error', error);
		} finally {
			setIsLoading(false);
		}
	};
	if (!MpcWallet.isCreateEddsaWallet() || keyshareManagerV2.shouldMigrate()) return null;

	return (
		<AlertDialog
			size="xs"
			isCentered
			isOpen={isOpen}
			leastDestructiveRef={cancelRef}
			onClose={() => setIsOpen(false)}
			autoFocus={false}
			trapFocus={false}
			closeOnEsc={false}
			closeOnOverlayClick={false}
		>
			<AlertDialogOverlay>
				<AlertDialogContent borderRadius="1.5rem">
					<AlertDialogBody py={10} px={8}>
						<Center flexDirection="column" textAlign="center">
							<FiUpArrowCircleIcon />
							<Text mt={4} fontSize="1.25rem">
								{t('upgrade.updateTonWalletTitle')}
							</Text>

							<Text mt={5} fontSize="xs">
								{t('upgrade.updateTonWalletDes')}
							</Text>
							<Button
								onClick={handleCreateTonWallet}
								mt={6}
								ref={cancelRef}
								size="lg"
								fontWeight="medium"
								colorScheme="cyan"
								width="100%"
								isLoading={isLoading}
								isDisabled={isLoading}
							>
								{t('button.ok')}
							</Button>
						</Center>
					</AlertDialogBody>
				</AlertDialogContent>
			</AlertDialogOverlay>
		</AlertDialog>
	);
};
