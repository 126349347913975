import React from 'react';

interface IProps extends React.SVGAttributes<HTMLOrSVGElement> {}

export const FiMinimizeIcon: React.FC<IProps> = ({ width = 20, height = 20, ...rest }) => {
	return (
		<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M2.5 11.6663C2.5 11.2061 2.8731 10.833 3.33333 10.833H8.33333C8.79357 10.833 9.16667 11.2061 9.16667 11.6663V16.6663C9.16667 17.1266 8.79357 17.4997 8.33333 17.4997C7.8731 17.4997 7.5 17.1266 7.5 16.6663V12.4997H3.33333C2.8731 12.4997 2.5 12.1266 2.5 11.6663Z"
				fill="black"
				fillOpacity="0.64"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M11.6663 2.5C12.1266 2.5 12.4997 2.8731 12.4997 3.33333V7.5H16.6663C17.1266 7.5 17.4997 7.8731 17.4997 8.33333C17.4997 8.79357 17.1266 9.16667 16.6663 9.16667H11.6663C11.2061 9.16667 10.833 8.79357 10.833 8.33333V3.33333C10.833 2.8731 11.2061 2.5 11.6663 2.5Z"
				fill="black"
				fillOpacity="0.64"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M18.0889 1.91107C18.4144 2.23651 18.4144 2.76414 18.0889 3.08958L12.2556 8.92291C11.9302 9.24835 11.4025 9.24835 11.0771 8.92291C10.7516 8.59748 10.7516 8.06984 11.0771 7.7444L16.9104 1.91107C17.2359 1.58563 17.7635 1.58563 18.0889 1.91107Z"
				fill="black"
				fillOpacity="0.64"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M8.92291 11.0771C9.24835 11.4025 9.24835 11.9302 8.92291 12.2556L3.08958 18.0889C2.76414 18.4144 2.23651 18.4144 1.91107 18.0889C1.58563 17.7635 1.58563 17.2359 1.91107 16.9104L7.7444 11.0771C8.06984 10.7516 8.59748 10.7516 8.92291 11.0771Z"
				fill="black"
				fillOpacity="0.64"
			/>
		</svg>
	);
};
