import React from 'react';

interface IProps extends React.SVGAttributes<HTMLOrSVGElement> {}

export const FiContactSupportIcon: React.FC<IProps> = ({ width = 24, height = 24, ...rest }) => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 24 24" fill="none" {...rest}>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M12 4C9.87827 4 7.84344 4.84285 6.34315 6.34315C4.84285 7.84344 4 9.87827 4 12V18C4 18.5523 3.55228 19 3 19C2.44772 19 2 18.5523 2 18V12C2 9.34784 3.05357 6.8043 4.92893 4.92893C6.8043 3.05357 9.34784 2 12 2C14.6522 2 17.1957 3.05357 19.0711 4.92893C20.9464 6.8043 22 9.34784 22 12V18C22 18.5523 21.5523 19 21 19C20.4477 19 20 18.5523 20 18V12C20 9.87827 19.1571 7.84344 17.6569 6.34315C16.1566 4.84285 14.1217 4 12 4Z"
				fill="black"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M2 14C2 13.4477 2.44772 13 3 13H6C6.79565 13 7.55871 13.3161 8.12132 13.8787C8.68393 14.4413 9 15.2044 9 16V19C9 19.7956 8.68393 20.5587 8.12132 21.1213C7.55871 21.6839 6.79565 22 6 22H5C4.20435 22 3.44129 21.6839 2.87868 21.1213C2.31607 20.5587 2 19.7956 2 19V14ZM4 15V19C4 19.2652 4.10536 19.5196 4.29289 19.7071C4.48043 19.8946 4.73478 20 5 20H6C6.26522 20 6.51957 19.8946 6.70711 19.7071C6.89464 19.5196 7 19.2652 7 19V16C7 15.7348 6.89464 15.4804 6.70711 15.2929C6.51957 15.1054 6.26522 15 6 15H4ZM18 15C17.7348 15 17.4804 15.1054 17.2929 15.2929C17.1054 15.4804 17 15.7348 17 16V19C17 19.2652 17.1054 19.5196 17.2929 19.7071C17.4804 19.8946 17.7348 20 18 20H19C19.2652 20 19.5196 19.8946 19.7071 19.7071C19.8946 19.5196 20 19.2652 20 19V15H18ZM15.8787 13.8787C16.4413 13.3161 17.2043 13 18 13H21C21.5523 13 22 13.4477 22 14V19C22 19.7957 21.6839 20.5587 21.1213 21.1213C20.5587 21.6839 19.7957 22 19 22H18C17.2043 22 16.4413 21.6839 15.8787 21.1213C15.3161 20.5587 15 19.7957 15 19V16C15 15.2043 15.3161 14.4413 15.8787 13.8787Z"
				fill="black"
			/>
		</svg>
	);
};
