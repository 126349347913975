import React from 'react';

interface IProps extends React.SVGAttributes<HTMLOrSVGElement> {}

export const FiQuestionIcon: React.FC<IProps> = ({ width = 20, height = 20, ...rest }) => {
	return (
		<svg viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg" width={width} height={height} {...rest}>
			<path
				d="M16.2603 9.93603C15.1923 14.2215 10.8508 16.8296 6.56402 15.7602C2.27722 14.6909 -0.329523 10.3514 0.739843 6.06522C1.80921 1.77974 6.14868 -0.829688 10.4348 0.239683C14.7203 1.30905 17.3297 5.64921 16.2603 9.93603Z"
				fill="url(#pattern0_10439_17137)"
			/>
			<path
				d="M16.2603 9.93603C15.1923 14.2215 10.8508 16.8296 6.56402 15.7602C2.27722 14.6909 -0.329523 10.3514 0.739843 6.06522C1.80921 1.77974 6.14868 -0.829688 10.4348 0.239683C14.7203 1.30905 17.3297 5.64921 16.2603 9.93603Z"
				fill="black"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M8.5 3.5C6.01472 3.5 4 5.51472 4 8C4 10.4853 6.01472 12.5 8.5 12.5C10.9853 12.5 13 10.4853 13 8C13 5.51472 10.9853 3.5 8.5 3.5ZM3 8C3 4.96243 5.46243 2.5 8.5 2.5C11.5376 2.5 14 4.96243 14 8C14 11.0376 11.5376 13.5 8.5 13.5C5.46243 13.5 3 11.0376 3 8Z"
				fill="white"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M8.62716 6.01049C8.3944 5.97057 8.15502 6.01431 7.95142 6.13397C7.74782 6.25362 7.59314 6.44148 7.51477 6.66425C7.42314 6.92475 7.13768 7.06164 6.87718 6.97C6.61669 6.87836 6.4798 6.59291 6.57144 6.33241C6.72817 5.88686 7.03754 5.51115 7.44474 5.27183C7.85194 5.03252 8.3307 4.94504 8.79622 5.02489C9.26174 5.10473 9.68398 5.34676 9.98816 5.7081C10.2923 6.06936 10.4587 6.52657 10.4581 6.99879C10.4579 7.76397 9.89058 8.26928 9.48546 8.53936C9.26764 8.68457 9.05338 8.79134 8.89555 8.86149C8.81592 8.89688 8.74887 8.92373 8.70056 8.94214C8.67636 8.95136 8.65674 8.9585 8.64246 8.96358L8.62509 8.96966L8.61956 8.97155L8.61761 8.97221L8.61685 8.97247C8.61669 8.97252 8.61622 8.97267 8.45811 8.49833L8.61622 8.97267C8.35425 9.06 8.07109 8.91842 7.98376 8.65645C7.8965 8.39465 8.03783 8.11169 8.29947 8.02417L8.29883 8.02438C8.29888 8.02436 8.29892 8.02435 8.29947 8.02417L8.30745 8.02137C8.31514 8.01863 8.32774 8.01406 8.34456 8.00765C8.37828 7.99481 8.42841 7.97479 8.48941 7.94768C8.61283 7.89282 8.77357 7.8121 8.93076 7.70731C9.27556 7.47744 9.45811 7.2329 9.45811 6.99833L9.45811 6.99759C9.45846 6.76143 9.37522 6.53277 9.22313 6.3521C9.07104 6.17143 8.85992 6.05042 8.62716 6.01049Z"
				fill="white"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M8 10.5C8 10.2239 8.22386 10 8.5 10H8.505C8.78114 10 9.005 10.2239 9.005 10.5C9.005 10.7761 8.78114 11 8.505 11H8.5C8.22386 11 8 10.7761 8 10.5Z"
				fill="white"
			/>
			<defs>
				<pattern id="pattern0_10439_17137" patternContentUnits="objectBoundingBox" width="1" height="1">
					<use xlinkHref="#image0_10439_17137" transform="scale(0.0005)" />
				</pattern>
			</defs>
		</svg>
	);
};
