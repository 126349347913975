import React from 'react';

interface IProps extends React.SVGAttributes<HTMLOrSVGElement> {}

export const FiClockIcon: React.FC<IProps> = ({ width = 24, height = 24, fill = 'currentColor', ...rest }) => {
	return (
		<svg width={width} height={height} {...rest} viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M12.8999 3C7.92934 3 3.8999 7.02944 3.8999 12C3.8999 16.9706 7.92934 21 12.8999 21C17.8705 21 21.8999 16.9706 21.8999 12C21.8999 7.02944 17.8705 3 12.8999 3ZM1.8999 12C1.8999 5.92487 6.82477 1 12.8999 1C18.975 1 23.8999 5.92487 23.8999 12C23.8999 18.0751 18.975 23 12.8999 23C6.82477 23 1.8999 18.0751 1.8999 12Z"
				fill={fill}
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M12.8999 5C13.4522 5 13.8999 5.44772 13.8999 6V11.382L17.3471 13.1056C17.8411 13.3526 18.0413 13.9532 17.7943 14.4472C17.5473 14.9412 16.9467 15.1414 16.4527 14.8944L12.4527 12.8944C12.1139 12.725 11.8999 12.3788 11.8999 12V6C11.8999 5.44772 12.3476 5 12.8999 5Z"
				fill={fill}
			/>
		</svg>
	);
};
