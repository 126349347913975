import { Loading } from '@/app-components/common';
import PageTransition from '@/app-components/layout/PageTransition/PageTransition';
import PageNavigation from '@/app-components/layout/PageWrapper/PageWrapper';
import { useQueryInviteCodesHistory, useQueryUserInviteCodes } from '@/app-hooks/api/user/useQueryUserInviteCodes';
import { ChevronRightIcon, ShareIcon, StampIcon } from '@/assets/images/svg';
import { Box, Button, Container, Divider, Flex, Text, useDisclosure } from '@chakra-ui/react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TelegramCore } from '../../../../app-cores/telegram';
import { FiMailIcon, FiSettingIcon } from '../../../../assets/icons';
import { COMMUNITY_LINK } from '@/app-constants';
import { FiUserIcon } from '@/assets/icons/fi-user-icon';
import { useNavigate } from 'react-router';
import { NAVIGATE_PATHS } from '@/app-constants/router';
import { formatNumber } from '@/app-helpers/number';
import i18n from '@/app-cores/i18n';
import BackgroundImage from '@/app-components/layout/BackGround/ContainerWithBackgroundImage';
import { useSearchParams } from 'react-router-dom';

const inviteFriend = (code: string) => {
	const link = `https://t.me/${import.meta.env.VITE_BOT_USERNAME}${code ? `?start=${code}` : ''}`;
	TelegramCore.WebApp.openTelegramLink(
		`https://t.me/share/url?url=${encodeURIComponent(link)}&text=${encodeURIComponent(
			i18n.t('referral.tobiInvite'),
		)}`,
	);
};

export const useInviteFriend = () => {
	const { data, isLoading } = useQueryUserInviteCodes();
	const code = data?.code?.code;

	const onInvite = useCallback(() => {
		inviteFriend(code);
	}, [code]);

	return { onInvite, isLoading, code, data };
};

const InviteCodePage = () => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const { isLoading, onInvite, data } = useInviteFriend();
	const { data: history } = useQueryInviteCodesHistory();
	const [searchParams] = useSearchParams();

	const { walletUsers = [], totalUser } = history || {};

	const invitedCount = walletUsers?.length;

	const autoInvite = data ? searchParams.get('autoInvite') : '';
	useEffect(() => {
		if (autoInvite) {
			onInvite();
		}
	}, [onInvite, autoInvite]);

	if (isLoading) {
		return <Loading />;
	}

	return (
		<PageTransition>
			<PageNavigation>
				<BackgroundImage
					py={4}
					px={10}
					h={'100vh'}
					display={'flex'}
					alignItems={'center'}
					justifyContent={'center'}
				>
					<Box>
						<StampIcon style={{ transform: 'translateY(1px)' }} />
						<Flex direction={'column'}>
							<Flex
								alignItems={'center'}
								justifyContent={'center'}
								direction={'column'}
								width={'100%'}
								backgroundColor={'white'}
								py={9}
								px={6}
								position={'relative'}
							>
								<Flex alignItems={'end'} gap={1}>
									<Text
										color={invitedCount > 0 ? 'cyan.400' : 'gray.400'}
										fontSize={96}
										lineHeight={'1em'}
										fontWeight={'860'}
										textShadow={invitedCount > 0 ? '0px 4px 4px rgba(2, 125, 131, 0.80)' : ''}
									>
										{invitedCount}
									</Text>
								</Flex>

								<Divider variant={'dashed'} borderColor={'white'} opacity={1} />
								<Flex
									px={5}
									pt={4}
									justify={'space-between'}
									backgroundColor={'white'}
									align={'center'}
									gap={'8px'}
									fontWeight={'600'}
									onClick={() =>
										totalUser > 0 && navigate(NAVIGATE_PATHS.AccountSettings.InvitationCodeDetail)
									}
								>
									<Text>{t('referral.successfullyReferred')}</Text>
									{totalUser > 0 && <ChevronRightIcon />}
								</Flex>
							</Flex>

							<Divider variant={'dashed'} borderColor={'white'} opacity={1} />

							<Flex flexDirection={'column'} backgroundColor={'white'} width={'100%'} p={5} gap={2}>
								<Flex align={'flex-start'} gap={2}>
									<FiMailIcon style={{ minHeight: '20px' }} />
									<Text fontSize={'sm'}>{t('referral.clickToInvite')}</Text>
								</Flex>

								<Button
									size="lg"
									fontWeight="medium"
									width="100%"
									fontSize={'md'}
									colorScheme="cyan"
									px={6}
									mb={2.5}
									onClick={onInvite}
									leftIcon={<ShareIcon width={20} height={20} className="text-white" />}
								>
									{t('referral.inviteNow')}
								</Button>
							</Flex>
						</Flex>
						<StampIcon style={{ transform: 'scaleY(-1) translateY(1px)' }} />
					</Box>
				</BackgroundImage>
			</PageNavigation>
		</PageTransition>
	);
};

export default InviteCodePage;
