import ErrorView from '@/app-components/common/ErrorView';
import { Box, Text } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useRouteError } from 'react-router';

export const RouterErrorBoundary = () => {
	const error = useRouteError() as Error;
	const { t } = useTranslation();
	return (
		<ErrorView
			title={t('errors.somethingWentWrong')}
			message={
				<Box>
					<Text fontWeight={500} mb={2}>
						Error: {error?.message}
					</Text>
					{error?.stack ? <pre>{error?.stack}</pre> : null}
				</Box>
			}
			onRetry={() => window.location.reload()}
		/>
	);
};
