import React from 'react';

interface IProps extends React.SVGAttributes<HTMLOrSVGElement> {}

export const FiQRCodeIcon: React.FC<IProps> = ({ width = 20, height = 20, ...rest }) => {
	return (
		<svg
			widths={width}
			height={height}
			xmlns="http://www.w3.org/2000/svg"
			viewBox="0 0 21 20"
			fill="none"
			{...rest}
		>
			<path
				d="M3.25 9.16667H9.91667V2.5H3.25V9.16667ZM4.91667 4.16667H8.25V7.5H4.91667V4.16667ZM3.25 17.5H9.91667V10.8333H3.25V17.5ZM4.91667 12.5H8.25V15.8333H4.91667V12.5ZM11.5833 2.5V9.16667H18.25V2.5H11.5833ZM16.5833 7.5H13.25V4.16667H16.5833V7.5ZM16.5833 15.8333H18.25V17.5H16.5833V15.8333ZM11.5833 10.8333H13.25V12.5H11.5833V10.8333ZM13.25 12.5H14.9167V14.1667H13.25V12.5ZM11.5833 14.1667H13.25V15.8333H11.5833V14.1667ZM13.25 15.8333H14.9167V17.5H13.25V15.8333ZM14.9167 14.1667H16.5833V15.8333H14.9167V14.1667ZM14.9167 10.8333H16.5833V12.5H14.9167V10.8333ZM16.5833 12.5H18.25V14.1667H16.5833V12.5Z"
				fill="black"
			/>
		</svg>
	);
};
