import { CopilotServiceAPI } from '@/app-cores/api/app-copilot';
import { Message } from '@/app-cores/api/app-copilot/type';
import { UseMutationOptions, useMutation } from '@tanstack/react-query';

export const useMutationReplyMessage = (options?: UseMutationOptions<Message[], unknown, string>) => {
	const response = useMutation<Message[], unknown, string>({
		mutationKey: ['mutation-user-reply-message'],
		mutationFn: async (message: string) => {
			return CopilotServiceAPI.sendMessage(message);
		},
		...options,
	});
	return response;
};
