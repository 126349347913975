import { MpcWalletProvider } from '@/app-cores/mpc-wallet/wallet';
import { useNetwork } from '.';

export function useWallet() {
	const { networkChain } = useNetwork();
	const provider = new MpcWalletProvider(networkChain.rpcUrls.default.http);
	const signer = provider.getSigner();
	const address = signer.getAddress();
	return {
		provider,
		signer,
		address,
	};
}

export function useWalletByChainId(chainId: number) {
	const { getMpcWallet } = useNetwork();

	return getMpcWallet(chainId);
}
