import { SolWallet } from '@/app-cores/mpc-wallet/solana/SolWallet';
import { Transaction } from '@solana/web3.js';
import { useQuery } from '@tanstack/react-query';

export const useEstimateSolGasFee = (id: number, transactionData: string) => {
	const response = useQuery({
		queryKey: ['estimate-sol-gas-fee', id],
		queryFn: async () => {
			const { fromPubKey, connection, blockHash } = await SolWallet.init('mainnet-beta', {
				commitment: 'confirmed',
			});
			const swapTransactionBuf = Buffer.from(transactionData, 'base64');
			const transaction = Transaction.from(swapTransactionBuf);
			transaction.feePayer = fromPubKey;
			transaction.recentBlockhash = blockHash;
			const gasFee = await transaction.getEstimatedFee(connection);
			return gasFee;
		},
	});
	return response;
};
