import { CTAButton } from '@/app-components/common';
import ErrorView from '@/app-components/common/ErrorView';
import { NAVIGATE_PATHS } from '@/app-constants/router';
import { CloudKitRecord, ServiceCloudKit } from '@/app-cores/api';
import { MpcWallet } from '@/app-cores/mpc-wallet/wallet';
import { TelegramCore } from '@/app-cores/telegram';
import { createICloudAuthUrl } from '@/app-helpers/backup';
import { parseErrorMessage } from '@/app-helpers/error-handling';
import { useMutationSetBackupType } from '@/app-hooks/api/user/useMutationSetBackupType';
import { useOnEventCallback } from '@/app-hooks/common';
import { Box, Center, Container, Spinner, Text } from '@chakra-ui/react';
import { orderBy } from 'lodash-es';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';

interface BackupICloudCallbackProps {}

const BackupICloudCallback: React.FC<BackupICloudCallbackProps> = () => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const [searchParams] = useSearchParams();

	const [progressText, setProgressText] = useState<string>('');
	const [errorMessage, setErrorMessage] = useState<string | null>(null);

	const authTokenWait = useRef<boolean>(false);
	const stateRef = useRef<string>(searchParams.get('state') || '');

	const { mutateAsync: setBackupType } = useMutationSetBackupType();

	const onCancel = useOnEventCallback(() => {
		TelegramCore.WebApp.showConfirm(t('storeBackup.confirmCancel'), (isOk: boolean) => {
			isOk && navigate(-1);
		});
	});

	const getCurrentVersion = useOnEventCallback(async (authToken: string) => {
		try {
			let currentRecord: CloudKitRecord | null = null;

			try {
				currentRecord = await ServiceCloudKit.getCurrentRecord(authToken);
			} catch (e) {
				currentRecord = null;
			}

			if (!currentRecord) {
				// fallback: get all records
				const records = await ServiceCloudKit.getRecords(authToken);
				if (!records.length) {
					return 0;
				}
				const sortedRecords = orderBy(records, (item) => item.fields.version.value, ['desc']);
				return Number(sortedRecords[0].fields.version.value);
			}
			return Number(currentRecord.fields.version.value);
		} catch (e) {
			return 0;
		}
	});

	const waitForAuthToken = useOnEventCallback(async (state: string) => {
		return new Promise<string>((resolve, reject) => {
			const _poll = async () => {
				try {
					const ckAuthToken = await ServiceCloudKit.getCKAuthTokenByAuthState(state);
					console.log('CKAuthToken', ckAuthToken);

					if (!ckAuthToken && authTokenWait.current) {
						await new Promise((resolve) => setTimeout(resolve, 2000));
						return requestAnimationFrame(_poll);
					}

					resolve(ckAuthToken);
				} catch (e) {
					reject(e);
				}
			};
			_poll();
		});
	});

	const handleBackup = useOnEventCallback(async () => {
		try {
			setErrorMessage(null);
			const state = stateRef.current;

			if (!state) {
				throw new Error('State not found');
			}

			setProgressText('Waiting for auth token...');
			authTokenWait.current = true;
			const ckAuthToken = await waitForAuthToken(state);
			authTokenWait.current = false;

			setProgressText('Retrieving current version...');
			const lastVersion = await getCurrentVersion(ckAuthToken);
			console.log('iCloud Last Version', lastVersion);

			setProgressText(`Uploading backup with version: ${lastVersion + 1}`);
			await ServiceCloudKit.createRecord(
				{
					version: lastVersion + 1,
					data: MpcWallet.getUnsecuredKeyShare2(),
				},
				ckAuthToken,
			);
			console.log('iCloud Upload Successfully');

			setProgressText('Successfully backed up');

			// Remove unsecured key
			MpcWallet.removeUnsecuredKeyShare2();
			await setBackupType({
				type: 'icloud',
				email: '',
			});

			navigate(NAVIGATE_PATHS.Home);
		} catch (e) {
			console.error(e);
			// toast.error(<Alert variant="danger">Failed to backup: {parseErrorMessage(e)}</Alert>);
			// navigate(-1);
			setErrorMessage(parseErrorMessage(e));
		}
	});

	const onBackupRetry = useOnEventCallback(async () => {
		const { url, state: newState } = await createICloudAuthUrl();
		stateRef.current = newState;
		TelegramCore.openExternalURL(url);
		setTimeout(() => {
			handleBackup();
		}, 0);
	});

	useEffect(() => {
		handleBackup();
		return () => {
			authTokenWait.current = false;
		};
		// eslint-disable-next-line
	}, []);

	if (errorMessage) {
		return (
			<ErrorView
				title={t('errors.errorOccurred')}
				message={errorMessage}
				onRetry={onBackupRetry}
				backButtonText={t('storeBackup.selectAnotherMethod')}
				onBack={() => navigate(-1)}
			/>
		);
	}

	return (
		<Container className="pageContent">
			<Box className="pageFixed" py={4} px={4}>
				<Center flexDirection="column" overflow="hidden" flexGrow={1}>
					<Spinner color="cyan.400" />
					<Text fontSize={14} mt={2}>
						{progressText}
					</Text>
				</Center>

				<CTAButton size="lg" fontWeight="medium" width="100%" onClick={onCancel}>
					{t('button.cancel')}
				</CTAButton>
			</Box>
		</Container>
	);
};

export default BackupICloudCallback;
