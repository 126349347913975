import { QUERY_KEYS } from '@/app-constants';
import { UseQueryOptions, useQuery } from '@tanstack/react-query';
import { ActivitiesServiceAPI, GetActivityParams, WalletActivity } from '../../../app-cores/api/activities';
import { TEN_SECONDS } from '../portfolio/constant';

export const useQueyWalletActivities = (
	payload: GetActivityParams,
	options?: Omit<UseQueryOptions<WalletActivity[]>, 'queryKey'>,
) => {
	const response = useQuery({
		queryKey: [QUERY_KEYS.GET_WALLET_ACTIVITIES, JSON.stringify(payload || {})],
		queryFn: async () => {
			return ActivitiesServiceAPI.getWalletActivities(payload);
		},
		refetchInterval: TEN_SECONDS,
		...options,
	});
	return response;
};
