import { GDriveSession } from './gdrive-session';

class GDriveService {
	public async createSession(accessToken: string) {
		return new GDriveSession(accessToken);
	}
}

export type IGDriveService = typeof GDriveService;

export default new GDriveService();
