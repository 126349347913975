import { copilotManager } from '@/app-features/copilot/helper';
import { useEffect, useRef } from 'react';
import { isMobile } from 'react-device-detect';

interface UsePullToRefreshConfigs {
	shouldIgnore?: boolean;
	triggerThreshold?: number;
	onRefresh: (isPullToRefresh?: boolean) => Promise<any>;
	containerId: string;
}

export const usePullToRefresh = ({
	onRefresh,
	shouldIgnore,
	triggerThreshold = 0,
	containerId,
}: UsePullToRefreshConfigs) => {
	const isRefreshRef = useRef(false);

	useEffect(() => {
		if (shouldIgnore || copilotManager.isDragging()) return;
		let touchstartY = 0;
		const touchStart = (e: TouchEvent) => {
			if (!e?.touches) return;
			touchstartY = e.touches?.[0]?.clientY;
		};

		const touchMove = (e: TouchEvent) => {
			if (!e?.touches) return;
			const touchY = e.touches?.[0]?.clientY;
			const touchDiff = touchY - touchstartY;
			const container = document.getElementById(containerId);

			if (touchDiff > triggerThreshold && container.scrollTop === 0) {
				const activeElement = document.activeElement;
				isRefreshRef.current = activeElement === document.body;
				e.preventDefault();
			}
		};
		const touchEnd = () => {
			if (isRefreshRef.current) {
				onRefresh(true).finally(() => {
					isRefreshRef.current = false;
				});
			}
		};

		document.addEventListener('touchstart', touchStart);
		document.addEventListener('touchmove', touchMove);
		document.addEventListener('touchend', touchEnd);
		return () => {
			document.removeEventListener('touchstart', touchStart);
			document.removeEventListener('touchmove', touchMove);
			document.removeEventListener('touchend', touchEnd);
		};
		// eslint-disable-next-line
	}, []);
};
