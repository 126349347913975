import { Box, Flex, Text } from '@chakra-ui/react';

export const TransactionItemInfo = ({ title, value }: { title: string; value: string }) => {
	return (
		<Flex justifyContent={'space-between'} my={2} gap={4}>
			<Text fontSize="xs">{title}</Text>
			<Box textAlign={'right'}>
				<Text fontSize="xs" wordBreak="break-all">
					{value}
				</Text>
			</Box>
		</Flex>
	);
};
