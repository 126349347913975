import React from 'react';

interface IProps extends React.SVGAttributes<HTMLOrSVGElement> {}

export const FiUploadFileIcon: React.FC<IProps> = ({ width = 48, height = 48, fill = 'white', ...rest }) => {
	return (
		<svg width={width} height={height} viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
			<g clipPath="url(#clip0_6529_8598)">
				<rect x="12" y="10" width="24" height="28" fill="black" />
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M24 0C10.7813 0 0 10.7813 0 24C0 37.2187 10.7812 48 24 48C37.2188 48 48 37.2188 48 24C48 10.7812 37.2187 0 24 0ZM23.9675 11.3377L29.3514 16.7212L27.2737 18.799L25.4368 16.9624V27.7104H22.4977V16.9624L20.6613 18.799L18.5835 16.7212L23.9675 11.3377ZM35.2741 33.1177H12.6609V26.3H15.5995V30.1792H32.3352V26.3H35.2741V33.1177Z"
					fill="#00E9DB"
				/>
			</g>
			<defs>
				<clipPath id="clip0_6529_8598">
					<rect width="48" height="48" fill="white" />
				</clipPath>
			</defs>
		</svg>
	);
};
