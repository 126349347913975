import isEmpty from 'lodash/isEmpty';
import { useEffect } from 'react';
import { toast as toastify } from 'react-toastify';
import { Toast } from '.';
import { useGlobalToast } from '@/app-store';

export const GlobalToast = () => {
	const { toast, removeToast } = useGlobalToast();
	useEffect(() => {
		if (isEmpty(toast)) return;
		toastify(<Toast message={toast.message} type={toast.type} />, {
			onClose: () => removeToast(),
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [toast]);
	return null;
};
