import { Button, Collapse, useDisclosure } from '@chakra-ui/react';
import { CHAIN_CONFIG, ChainId, NATIVE_TOKEN_ADDRESS } from '../../../../app-constants/chains';
import { FaucetTestnet, TopupToken } from '../../../../app-components/common/TopupToken';
import { FiGasIcon } from '@/assets/icons/fi-gas-icon';
import Warning from '@/app-components/common/Warning';
import { useTranslation } from 'react-i18next';
import { DATADOG_ACTIONS, dataDogAddAction } from '@/app-services/monitor/datadog';
import { compareChain } from '@/app-helpers/address';
import { colors } from '@/app-theme/theme';
import { FiFaucetIcon } from '@/assets/icons/fi-faucet-icon';
import { useQueryStatusClaim } from '@/app-hooks/api/payment/useClaimFund';
import { isTronChain } from '@/app-helpers/token';

interface GasWarningProps {
	chainId: string | ChainId;
	callbackUrl?: string;
}
export const GasWarning: React.FC<GasWarningProps> = ({ chainId, callbackUrl }) => {
	const { isOpen, onOpen, onClose } = useDisclosure();
	const { t } = useTranslation();
	const chainInfo = CHAIN_CONFIG[chainId];

	const { isFetching, canClaim } = useQueryStatusClaim({ chainId, tokenAddress: NATIVE_TOKEN_ADDRESS });

	if (compareChain(chainId, ChainId.BERACHAIN_TESTNET)) {
		return (
			<Collapse in={!isFetching && canClaim}>
				<Warning
					background={colors.gray[100]}
					icon={<FiFaucetIcon style={{ width: '24px', display: 'block' }} />}
					msg={t('testnet.faucetForTobiUser')}
					action={
						<>
							<Button
								fontSize={'xs'}
								borderRadius={'1rem'}
								colorScheme={'white'}
								background={'white'}
								color={'black'}
								border={`1px solid #00000014`}
								size={'sm'}
								onClick={onOpen}
							>
								{t('button.claim')} {chainInfo?.nativeToken?.symbol}
							</Button>
							<FaucetTestnet isOpen={isOpen} onClose={onClose} chainId={`${chainId}`} />
						</>
					}
				/>
			</Collapse>
		);
	}

	return (
		<Warning
			background="red.100"
			icon={<FiGasIcon />}
			msg={t('cryptos.topUpGasFeeDescription', {
				tokenSymbol: chainInfo.nativeToken?.symbol,
				networkName: chainInfo.name,
			})}
			action={
				<>
					<Button
						fontSize={'xs'}
						borderRadius={'1rem'}
						colorScheme={'white'}
						background={'white'}
						color={'black'}
						border={`1px solid #00000014`}
						size={'sm'}
						onClick={() => {
							onOpen();
							dataDogAddAction(DATADOG_ACTIONS.DEPOSIT_TOP_UP_GAS_BY_DEPOSIT);
						}}
					>
						{t('cryptos.topUp')} {chainInfo?.nativeToken?.symbol}
					</Button>
					<TopupToken isOpen={isOpen} onClose={onClose} chainId={`${chainId}`} />
				</>
			}
		/>
	);
};
