import React from 'react';

interface IProps extends React.SVGAttributes<HTMLOrSVGElement> {}

export const FiBuoyIcon: React.FC<IProps> = ({ width = 24, height = 24, fill = 'currentColor', ...rest }) => {
	return (
		<svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3ZM1 12C1 5.92487 5.92487 1 12 1C18.0751 1 23 5.92487 23 12C23 18.0751 18.0751 23 12 23C5.92487 23 1 18.0751 1 12Z"
				fill={fill}
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M12 9C10.3431 9 9 10.3431 9 12C9 13.6569 10.3431 15 12 15C13.6569 15 15 13.6569 15 12C15 10.3431 13.6569 9 12 9ZM7 12C7 9.23858 9.23858 7 12 7C14.7614 7 17 9.23858 17 12C17 14.7614 14.7614 17 12 17C9.23858 17 7 14.7614 7 12Z"
				fill={fill}
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M4.22307 4.22295C4.61359 3.83242 5.24676 3.83242 5.63728 4.22295L9.87728 8.46295C10.2678 8.85347 10.2678 9.48664 9.87728 9.87716C9.48676 10.2677 8.85359 10.2677 8.46307 9.87716L4.22307 5.63716C3.83254 5.24664 3.83254 4.61347 4.22307 4.22295Z"
				fill={fill}
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M14.123 14.1228C14.5135 13.7323 15.1467 13.7323 15.5372 14.1228L19.7772 18.3628C20.1677 18.7534 20.1677 19.3865 19.7772 19.7771C19.3867 20.1676 18.7535 20.1676 18.363 19.7771L14.123 15.5371C13.7324 15.1465 13.7324 14.5134 14.123 14.1228Z"
				fill={fill}
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M19.7772 4.22295C20.1677 4.61347 20.1677 5.24664 19.7772 5.63716L15.5372 9.87716C15.1467 10.2677 14.5135 10.2677 14.123 9.87716C13.7324 9.48664 13.7324 8.85347 14.123 8.46295L18.363 4.22295C18.7535 3.83242 19.3867 3.83242 19.7772 4.22295Z"
				fill={fill}
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M19.0672 4.93291C19.4577 5.32343 19.4577 5.9566 19.0672 6.34712L15.5372 9.87712C15.1467 10.2676 14.5135 10.2676 14.123 9.87712C13.7324 9.4866 13.7324 8.85343 14.123 8.46291L17.653 4.93291C18.0435 4.54238 18.6767 4.54238 19.0672 4.93291Z"
				fill={fill}
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M9.87728 14.1228C10.2678 14.5134 10.2678 15.1465 9.87728 15.5371L5.63728 19.7771C5.24676 20.1676 4.61359 20.1676 4.22307 19.7771C3.83254 19.3865 3.83254 18.7534 4.22307 18.3628L8.46307 14.1228C8.85359 13.7323 9.48676 13.7323 9.87728 14.1228Z"
				fill={fill}
			/>
		</svg>
	);
};
