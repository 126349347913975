import TobiTabs from '@/app-components/common/TobiTabs';
import { Box, Center, Divider, Flex, Text, background } from '@chakra-ui/react';
import { OrderMode } from '@/app-store/swap/limit';
import { useLimitStore } from '@/app-store/swap/limit';

export default function BuySellGroup({
	orderMode,
	setOrderMode,
}: {
	orderMode: OrderMode;
	setOrderMode: (v: OrderMode) => void;
}) {
	const { tokenInfoIn } = useLimitStore();
	const token = tokenInfoIn;
	const tabs = [
		{ label: `Buy ${token?.symbol ?? ''}`, value: OrderMode.BUY },
		{
			label: `Sell ${token?.symbol ?? ''}`,
			value: OrderMode.SELL,
		},
	];
	return (
		<Flex direction={'column'} gap={'12px'} px={4}>
			<Divider variant={'dashed'} />
			<TobiTabs
				tabs={tabs}
				value={orderMode}
				onChange={setOrderMode}
				activeStyle={{
					background: orderMode === OrderMode.BUY ? 'cyan.400' : 'orange.100',
					color: orderMode === OrderMode.BUY ? 'black' : 'white',
				}}
			/>
		</Flex>
	);
}
