import { BASE_BORDER_COLOR, colors } from '@/app-theme/theme';
import { Box, Center } from '@chakra-ui/react';
import { CSSProperties } from 'react';

export const CustomRadio = ({
	selected,
	style,
	onClick,
	size = 24,
}: {
	selected: boolean;
	style?: CSSProperties;
	size?: number;
	onClick?: () => void;
}) => {
	const innerSize = `${(14 * size) / 24}px`;
	return (
		<Center
			onClick={onClick}
			style={style}
			background={'white'}
			minWidth={`${size}px`}
			height={`${size}px`}
			borderRadius={'100%'}
			border={`1px solid ${BASE_BORDER_COLOR}`}
		>
			<Box
				width={innerSize}
				height={innerSize}
				background={selected ? colors.cyan[400] : 'gray.100'}
				borderRadius={'100%'}
			/>
		</Center>
	);
};
