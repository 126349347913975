import { Box, Center, Img, List, ListItem, Text } from '@chakra-ui/react';
import { useCombobox } from 'downshift';
import { useNavigate } from 'react-router-dom';
import Highlighter from 'react-highlight-words';
import { NAVIGATE_PATHS } from '@/app-constants/router';
import { FiRightIcon } from '@/assets/icons';
import { BaseInput, Loading, LocalLoader } from '@/app-components/common';
interface DappInputSearchProps<T> {
	onInputValueChange?: (inputValue: string) => void;
	data: T[];
	onChange?: (value: T) => void;
	inputValue?: string;
	isFetching: boolean;
}

export const DappInputSearch = <
	T extends {
		name: string;
		logoUrl: string;
		url: string;
	},
>({
	data,
	onInputValueChange,
	onChange,
	inputValue,
	isFetching,
}: DappInputSearchProps<T>) => {
	const navigate = useNavigate();
	const { isOpen, getMenuProps, getInputProps, getItemProps, closeMenu } = useCombobox({
		onInputValueChange({ inputValue }) {
			onInputValueChange(inputValue);
		},
		items: data,
		itemToString(item) {
			return item ? item.name : '';
		},
		onSelectedItemChange: ({ selectedItem }) => {
			navigate(
				`${NAVIGATE_PATHS.Dapp.TobiDapp}/?dappUrl=${selectedItem.url}&parentUrl=${NAVIGATE_PATHS.Dapp.DappBrowser}`,
			);
		},
	});
	const isShow = isOpen && inputValue;

	return (
		<Box>
			<BaseInput
				fontSize="sm"
				placeholder="dApps name or URL"
				border="none"
				boxShadow={
					isShow
						? '-2px -2px 10px 0 rgba(0, 0, 0, 0.12), 2px -2px 10px 0 rgba(0, 0, 0, 0.12),0 2px 10px 0 rgba(0, 0, 0, 0.12)'
						: 'none'
				}
				borderRadius={16}
				transition="300ms"
				borderBottomLeftRadius={isShow ? 0 : '16px'}
				borderBottomRightRadius={isShow ? 0 : '16px'}
				backgroundColor={isShow ? 'white' : '#F9F9F9'}
				_focus={{
					boxShadow: isShow
						? '-2px -2px 10px 0 rgba(0, 0, 0, 0.12), 2px -2px 10px 0 rgba(0, 0, 0, 0.12),0 2px 10px 0 rgba(0, 0, 0, 0.12)'
						: 'none',
				}}
				{...getInputProps()}
			/>
			<Box position="relative">
				<List
					position="absolute"
					left={0}
					top="-2px"
					display={!isShow ? 'none' : 'block'}
					// maxHeight={isShow ? '1000px' : '0'}
					backgroundColor="white"
					zIndex={1}
					width="100%"
					boxShadow="4px 4px 4px 0 rgba(0, 0, 0, 0.12), -4px 4px 4px 0 rgba(0, 0, 0, 0.12),  0 4px 4px 0 rgba(0, 0, 0, 0.12) "
					borderBottomLeftRadius={16}
					borderBottomRightRadius={16}
					transition="200ms"
					{...getMenuProps()}
				>
					{isShow && (
						<Box px={5}>
							{isFetching && (
								<LocalLoader
									height="100%"
									style={{
										position: 'absolute',
										top: 0,
										left: 0,
										opacity: 0.8,
									}}
								/>
							)}
							<Box as="hr" borderStyle="solid" mt={2}></Box>
							<Text mb={2} my={4} fontWeight={500} fontSize="xs" color="gray.400">
								Tobi Suggestion
							</Text>
							{data.map((item, index) => (
								<Box as="li" key={item.name} py={1} cursor="pointer" {...getItemProps({ item, index })}>
									<Center justifyContent="space-between">
										<Center gap={2.5}>
											<Img src={item.logoUrl} alt="" width={6} height={6} borderRadius="50%" />
											<Highlighter
												highlightClassName="hight-light-text"
												searchWords={[inputValue]}
												autoEscape={true}
												textToHighlight={item.name}
												style={{
													fontSize: '14px',
												}}
											/>
										</Center>
										<Box>
											<FiRightIcon />
										</Box>
									</Center>
								</Box>
							))}
							<Text my={2} fontWeight={500} fontSize="xs" color="gray.400">
								Or
							</Text>
							<ListItem
								py={2}
								onClick={() => {
									closeMenu();
									navigate(`${NAVIGATE_PATHS.Dapp.GoogleSearchControl}?searchValue=${inputValue}`);
								}}
								cursor="pointer"
							>
								<Center justifyContent="space-between" wordBreak="break-all">
									<Box>
										<Text fontSize="sm" fontWeight={600}>
											{inputValue}
										</Text>
										<Text fontSize="xs">Search by google</Text>
									</Box>
									<Box>
										<FiRightIcon />
									</Box>
								</Center>
							</ListItem>
						</Box>
					)}
				</List>
			</Box>
		</Box>
	);
};
