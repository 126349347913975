import { axiosBotConnectorServiceApi } from './axios-instance';

interface PayloadResponse {
	client: {
		id: string;
		publicKey: string;
		metadata: {
			name: string;
			description: string;
			url: string;
			icons: string[];
		};
	};
	payload: string;
}

class BotConnectorService {
	async emit(clientId: string, data: Record<string, any>): Promise<any> {
		const response = await axiosBotConnectorServiceApi.post(`/api/v1/emit`, data, {
			params: {
				clientId,
			},
		});
		return response.data.data;
	}

	async query(clientId: string, requestId: string, publicKey: string): Promise<PayloadResponse> {
		const response = await axiosBotConnectorServiceApi.post(
			`/api/v1/query`,
			{
				requestId,
				publicKey,
			},
			{
				params: {
					clientId,
				},
			},
		);
		return response.data.data;
	}
}

export const BotConnectorServiceAPI = new BotConnectorService();
