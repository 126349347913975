import { BoxProps, Center } from '@chakra-ui/react';
import React from 'react';

export const ActionBlock: React.FC<React.PropsWithChildren<BoxProps>> = ({ children, ...boxProps }) => {
	return (
		<Center padding={2} bgColor="white" borderRadius={8} cursor="pointer" w={'48px'} height={'48px'} {...boxProps}>
			{children}
		</Center>
	);
};
