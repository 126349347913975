/* eslint-disable react-hooks/rules-of-hooks */
import { useCallback, useState } from 'react';
import {
	Card,
	Center,
	Drawer,
	DrawerBody,
	DrawerContent,
	DrawerFooter,
	DrawerHeader,
	DrawerOverlay,
	Flex,
	Image,
	Text,
} from '@chakra-ui/react';
import { toast } from 'react-toastify';
import { getChainId, getHostName } from '../utils/HelperUtil';
import { web3wallet } from '../utils/WalletConnectUtil';
import { useModalStore } from '../store/ModalStore';
import { Toast } from '@/app-components/common';
import { CommonTransactionInfo } from '../components/CommonTransactionInfo';
import { MpcWallet } from '@/app-cores/mpc-wallet/wallet';
import { ActionConfirm } from '../components/ActionConfirm';
import { RawTransaction } from '../components/RawTransaction';
import { approveTronRequest, rejectTronRequest } from '../utils/TronRequestHandlerUtil';

export default function SessionSignMessageTronModal() {
	// Get request and wallet data from store
	const { data, open, onClose } = useModalStore();
	const requestEvent = data?.requestEvent;
	const requestSession = data?.requestSession;
	const [isLoadingApprove, setIsLoadingApprove] = useState(false);
	const [isLoadingReject, setIsLoadingReject] = useState(false);
	const walletAddress = MpcWallet.getTronWalletAddress();

	if (!requestEvent || !requestSession) {
		toast(<Toast type="error" message="Missing request data" />);
		return null;
	}

	const { topic, params } = requestEvent;
	const { request, chainId } = params;
	const { peer } = requestSession;
	const chainID = getChainId(chainId);
	const message = request.params.message;
	const onApprove = useCallback(async () => {
		if (isLoadingApprove) return;
		if (requestEvent) {
			setIsLoadingApprove(true);
			const response = await approveTronRequest(requestEvent);
			try {
				await web3wallet.respondSessionRequest({
					topic,
					response,
				});
				if ((response as any)?.result) {
					toast(<Toast type="success" title="Success" message="Sign message successfully" />);
				}
			} catch (e) {
				setIsLoadingApprove(false);
				toast(<Toast type="error" message={(e as Error).message} />);
			}
			onClose();
			setIsLoadingApprove(false);
		}
		// eslint-disable-next-line
	}, [requestEvent, topic]);

	// Handle reject action
	const onReject = useCallback(async () => {
		if (isLoadingReject) return;
		if (requestEvent) {
			setIsLoadingReject(true);
			const response = rejectTronRequest(requestEvent);
			try {
				await web3wallet.respondSessionRequest({
					topic,
					response,
				});
				// toast(<Toast type="success" message="Reject sign message successfully" />);
			} catch (e) {
				toast(<Toast type="error" message={(e as Error).message} />);
			} finally {
				onClose();
			}
			setIsLoadingReject(false);
		}
		// eslint-disable-next-line
	}, [requestEvent, topic]);

	return (
		<Drawer isOpen={open} placement="bottom" closeOnOverlayClick={false} onClose={onClose} trapFocus={false}>
			<DrawerOverlay />
			<DrawerContent>
				<DrawerHeader>
					<DrawerHeader borderBottomWidth="1px" borderColor="rgba(0, 0, 0, 0.08)">
						<Center>
							<Text fontSize={'16px'} fontWeight={'500'}>
								Signature request
							</Text>
						</Center>
					</DrawerHeader>
				</DrawerHeader>
				<DrawerBody pt={0}>
					<Flex flexDirection={'column'} alignItems={'center'} gap={'20px'}>
						<Center border="1px solid rgba(0, 0, 0, 0.08)" gap={2} borderRadius={52} px={2} py={1}>
							<Image
								width={8}
								height={8}
								src={peer?.metadata?.icons?.[0]}
								alt=""
								borderRadius="100%"
								fallbackSrc="/assets/images/dapp-fallback.svg"
							/>
							<Text fontSize="sm">{getHostName(peer?.metadata?.url)}</Text>
						</Center>
						<Text textAlign={'center'} fontSize={'24px'} lineHeight={'30px'}>
							You are signing
						</Text>
						<Text textAlign="center" fontSize="sm" wordBreak="break-all">
							{message}
						</Text>
						<Card padding={'16px'} width={'100%'}>
							<CommonTransactionInfo chainId={chainID} gasFee="0" walletAddress={walletAddress}>
								<RawTransaction rawData={JSON.stringify(request, null, 2)} />
							</CommonTransactionInfo>
						</Card>
					</Flex>
				</DrawerBody>

				<DrawerFooter display="flex" gap={2}>
					<ActionConfirm
						onApprove={onApprove}
						onReject={onReject}
						isLoadingApprove={isLoadingApprove}
						isLoadingReject={isLoadingReject}
					/>
				</DrawerFooter>
			</DrawerContent>
		</Drawer>
	);
}
