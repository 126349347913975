import { TronWeb, utils, Types } from 'tronweb';
import { joinSignature } from '@ethersproject/bytes';
import { ethers } from 'ethers';
import MpcClient from '../mpc';
import keyShareManager from '../wallet/keyshare-manager';
import { MpcWallet } from '../wallet';

class TronWallet {
	tronWeb: TronWeb;
	constructor() {
		this.tronWeb = new TronWeb({
			fullHost: 'https://api.trongrid.io',
			fullNode: 'https://api.trongrid.io',
			headers: { 'TRON-PRO-API-KEY': import.meta.env.VITE_TRON_PRO_API_KEY },
		});
	}
	public async signMessage(message: string): Promise<string> {
		const messageDigest = utils.message.hashMessage(message);
		const keyShare = keyShareManager.keyShares.ecdsaKeyShare;
		const signatureBytes = await MpcClient.sign(keyShare, messageDigest);
		const signature = joinSignature(signatureBytes);
		return signature;
	}
	public verifySignature(message: string, signature: string) {
		const tronSignerAddress = utils.message.verifyMessage(message, signature);
		return tronSignerAddress === MpcWallet.getTronWalletAddress();
	}
	public async signTransaction(transaction: Types.Transaction) {
		const keyShare = keyShareManager.keyShares.ecdsaKeyShare;
		const signatureBytes = await MpcClient.sign(keyShare, transaction.txID);
		const signatureHash = ethers.hexlify(signatureBytes);
		(transaction as any).signature = [signatureHash];
		return transaction as Types.SignedTransaction;
	}
	public async sendRawTransaction(signedTransaction: Types.SignedTransaction) {
		const broadcastRes = await this.tronWeb.trx.sendRawTransaction(signedTransaction);
		return broadcastRes;
	}
}
export const tronWallet = new TronWallet();
