import axios from 'axios';
import toArray from 'lodash/toArray';
import { chunk } from 'lodash-es';
import { CHAIN_CONFIG, ChainId } from '@/app-constants/chains';
import { ITokenBalance } from '../bff';
import { axiosBotAPI } from '@/app-cores/api/axios';

class ServiceTokenPrice {
	#tokenPriceURLs = {
		erc20: 'https://price.kyberswap.com',
		sol: 'https://price.jup.ag/v4/price',
		bera: 'https://api.goldsky.com/api/public/project_clq1h5ct0g4a201x18tfte5iv/subgraphs/bex-subgraph/v1/gn',
	};

	async getTokenOnErc20Price(chainId: ChainId, ids: string) {
		try {
			const response = await axios.post(
				`${this.#tokenPriceURLs.erc20}/${CHAIN_CONFIG[chainId].kyberAggregatorSlug}/api/v1/prices`,
				{
					ids: ids,
				},
			);
			return response?.data?.data?.prices || [];
		} catch (error) {
			console.error('Error fetching Ethereum price:', error);
			throw error;
		}
	}

	async getTokenOnTonPrice(addresses: string) {
		try {
			const response = await axiosBotAPI.get('/token/tokens/prices', {
				params: { chainId: ChainId.TON, addresses },
			});
			const tokens = response?.data?.data ?? {};
			return Object.keys(tokens).map((address) => ({ address, marketPrice: tokens[address] }));
		} catch (error) {
			console.error('Error fetching TON price:', error);
			throw error;
		}
	}
	async getTokenOnSolPrice(ids: string) {
		try {
			const response = await axios.get(this.#tokenPriceURLs.sol, {
				params: {
					ids: ids,
				},
			});
			const tokens = toArray(response.data?.data);
			return tokens.map((e) => ({ ...e, marketPrice: e.price, address: e.id }));
		} catch (error) {
			console.error('Error fetching token on Sol price:', error);
			throw error;
		}
	}

	async getSingleTokenOnBeraPrice(token: ITokenBalance) {
		try {
			const data = {
				operationName: 'GetTokenHoneyPrice',
				variables: {
					id: token.address.toLowerCase(),
				},
				query: `
				  query GetTokenHoneyPrice($id: String) {
					tokenHoneyPrice(id: $id) {
					  id
					  price
					  __typename
					}
				  }
				`,
			};
			const response = await axios({
				url: this.#tokenPriceURLs.bera,
				data: data,
				method: 'post',
			});
			return response.data?.data?.tokenHoneyPrice?.price;
		} catch (error) {
			console.error('Error fetching token on bera price:', error);
			throw error;
		}
	}

	async getTokenOnBeraPrice(list: ITokenBalance[]) {
		try {
			const response = await Promise.allSettled(list.map((e) => this.getSingleTokenOnBeraPrice(e)));
			const data = response.map((e) => (e.status === 'fulfilled' ? e.value : null));
			const rs = list.map((e, i) => ({ ...e, marketPrice: data[i], address: e.address }));
			return rs;
		} catch (error) {
			console.error('Error fetching token on Sol price:', error);
			throw error;
		}
	}

	async getTokenPrices(tokens: ITokenBalance[], chainId: ChainId): Promise<any[]> {
		try {
			if (!chainId) return [];
			switch (chainId.toString()) {
				case ChainId.TON: {
					return await this.getTokenOnTonPrice(tokens.map((e) => e.address).join(','));
				}
				case ChainId.SOL: {
					const chunkTokens = chunk(tokens, 100) as ITokenBalance[][];
					const promises = chunkTokens.map((tokens) => {
						const ids = tokens.map((e) => e.address).join(',');
						return this.getTokenOnSolPrice(ids);
					});
					const data = await Promise.all(promises);
					return Promise.resolve(data.flat());
				}
				case ChainId.BERACHAIN_TESTNET.toString():
					return await this.getTokenOnBeraPrice(tokens);
				default: {
					const ids = tokens.map((e) => e.address?.toLowerCase()).join(',');
					return await this.getTokenOnErc20Price(chainId, ids);
				}
			}
		} catch (error) {
			console.error('Error fetching token price:', error);
			throw error;
		}
	}
}
export const ServiceTokenPriceAPI = new ServiceTokenPrice();
