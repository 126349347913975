import React from 'react';

interface IProps extends React.SVGAttributes<HTMLOrSVGElement> {
	size?: number;
}

export const FiRefreshIcon: React.FC<IProps> = ({ size = 20, ...rest }) => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 16 16" fill="none" {...rest}>
			<path
				d="M14.3357 8.52884C13.8702 8.45801 13.4567 8.77709 13.39 9.23201C13.2223 10.3746 12.695 11.4171 11.8649 12.2472C9.75224 14.3598 6.31724 14.3582 4.20541 12.2472C2.09399 10.1353 2.09399 6.69909 4.20541 4.58768C4.99966 3.79384 6.00233 3.27626 7.12449 3.08784C7.60708 2.99626 8.12591 2.98043 8.66424 3.04143C9.40341 3.12651 10.0914 3.37176 10.7192 3.73184L9.63433 3.91709C9.18108 3.99484 8.87583 4.42534 8.95399 4.87901C9.02316 5.28509 9.37558 5.57193 9.77433 5.57193C9.82074 5.57193 9.86791 5.56826 9.91591 5.56009L12.8244 5.06284C13.0417 5.02543 13.2362 4.90334 13.364 4.72268C13.4917 4.54243 13.5422 4.31859 13.5047 4.10051L13.0067 1.19243C12.9302 0.739178 12.5053 0.435594 12.0447 0.511678C11.5915 0.589428 11.2862 1.02034 11.3644 1.47401L11.4955 2.23943C10.6889 1.78693 9.79916 1.49418 8.85383 1.38534C8.14583 1.30476 7.45899 1.32718 6.83033 1.44759C5.38299 1.69009 4.06791 2.36843 3.02699 3.40926C0.265742 6.17051 0.265742 10.6635 3.02699 13.4255C4.40758 14.8065 6.22158 15.4967 8.03516 15.4967C9.84916 15.4967 11.6622 14.8066 13.0433 13.4255C14.1282 12.3407 14.8182 10.9743 15.0387 9.47451C15.1056 9.01876 14.7906 8.59551 14.3357 8.52884Z"
				fill="black"
				fillOpacity="0.48"
			/>
		</svg>
	);
};
