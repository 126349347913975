import React from 'react';

interface IProps extends React.SVGAttributes<HTMLOrSVGElement> {}

export const FiExternalLinkIcon: React.FC<IProps> = ({ width = 20, height = 20, ...rest }) => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M3.3335 4.66671C3.15668 4.66671 2.98712 4.73695 2.86209 4.86197C2.73707 4.98699 2.66683 5.15656 2.66683 5.33337V12.6667C2.66683 12.8435 2.73707 13.0131 2.86209 13.1381C2.98712 13.2631 3.15669 13.3334 3.3335 13.3334H10.6668C10.8436 13.3334 11.0132 13.2631 11.1382 13.1381C11.2633 13.0131 11.3335 12.8435 11.3335 12.6667V8.66671C11.3335 8.29852 11.632 8.00004 12.0002 8.00004C12.3684 8.00004 12.6668 8.29852 12.6668 8.66671V12.6667C12.6668 13.1971 12.4561 13.7058 12.081 14.0809C11.706 14.456 11.1973 14.6667 10.6668 14.6667H3.3335C2.80306 14.6667 2.29436 14.456 1.91928 14.0809C1.54421 13.7058 1.3335 13.1971 1.3335 12.6667V5.33337C1.3335 4.80294 1.54421 4.29423 1.91928 3.91916C2.29436 3.54409 2.80306 3.33337 3.3335 3.33337H7.3335C7.70169 3.33337 8.00016 3.63185 8.00016 4.00004C8.00016 4.36823 7.70169 4.66671 7.3335 4.66671H3.3335Z"
				fill="#00E9DB"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M9.3335 2.00004C9.3335 1.63185 9.63197 1.33337 10.0002 1.33337H14.0002C14.3684 1.33337 14.6668 1.63185 14.6668 2.00004V6.00004C14.6668 6.36823 14.3684 6.66671 14.0002 6.66671C13.632 6.66671 13.3335 6.36823 13.3335 6.00004V2.66671H10.0002C9.63197 2.66671 9.3335 2.36823 9.3335 2.00004Z"
				fill="black"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M14.4714 1.52864C14.7318 1.78899 14.7318 2.2111 14.4714 2.47145L7.13807 9.80478C6.87772 10.0651 6.45561 10.0651 6.19526 9.80478C5.93491 9.54443 5.93491 9.12232 6.19526 8.86197L13.5286 1.52864C13.7889 1.26829 14.2111 1.26829 14.4714 1.52864Z"
				fill="black"
			/>
		</svg>
	);
};
