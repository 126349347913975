import { Toast } from '@/app-components/common';
import { TokenInfo } from '@/app-cores/api/bff';
import { AddRemoveTokenPayload } from '@/app-cores/api/bot/service-token';
import { parseErrorMessage } from '@/app-helpers/error-handling';
import { useMutationHideUnhideToken } from '@/app-hooks/api/tokens/useMutationUserSettingTokens';
import { useTokenLabels } from '@/app-hooks/api/tokens/useUserTokenSettings';
import { FiCheckIcon, FiSettingIcon } from '@/assets/icons';
import { Button, Switch, useDisclosure } from '@chakra-ui/react';
import {
	Box,
	Drawer,
	DrawerBody,
	DrawerCloseButton,
	DrawerContent,
	DrawerHeader,
	DrawerOverlay,
	Flex,
	List,
	ListIcon,
	ListItem,
	Text,
} from '@chakra-ui/react';
import { CSSProperties, useEffect, useState } from 'react';
import { toast } from 'react-toastify';

export const SettingItem = ({
	text,
	selected,
	onChange,
}: {
	text: string;
	selected: boolean;
	onChange: (v: boolean) => void;
}) => {
	return (
		<ListItem
			display={'flex'}
			cursor={'pointer'}
			justifyContent={'space-between'}
			fontSize={'14px'}
			fontWeight={'500'}
			alignItems={'center'}
		>
			{text}
			<Switch
				size={'lg'}
				isChecked={selected}
				colorScheme={'green'}
				onChange={(e) => onChange(e.target.checked)}
			/>
		</ListItem>
	);
};

export function SettingToken({
	isSpam,
	isHidden,
	tobiId,
	chainId,
	style,
	tokenInfo,
}: {
	isSpam: boolean;
	isHidden: boolean;
	tobiId: string;
	chainId: string;
	style: CSSProperties;
	tokenInfo: TokenInfo;
}) {
	const { isOpen, onOpen, onClose } = useDisclosure();
	const { mutateAsync: addToken } = useMutationHideUnhideToken();

	const [checkedSpam, setCheckedSpam] = useState(isSpam);
	const [checkedHidden, setCheckedHidden] = useState(isHidden);

	useEffect(() => {
		setCheckedSpam(isSpam);
	}, [isSpam]);

	useEffect(() => {
		setCheckedHidden(isHidden);
	}, [isHidden]);

	const onChange = async (action: AddRemoveTokenPayload['action']) => {
		try {
			const payload: AddRemoveTokenPayload = {
				action,
				tokens: tokenInfo?.allTokens?.map((e) => ({ tobiId, chainId: e.chainId })) ?? [],
			};
			await addToken(payload);
		} catch (error) {
			toast(<Toast type="error" message={`Save error: ${parseErrorMessage(error)}`} />, {
				autoClose: 5000,
			});
		}
	};

	return (
		<>
			<FiSettingIcon onClick={onOpen} style={style} />
			<Drawer placement={'bottom'} onClose={onClose} isOpen={isOpen}>
				<DrawerOverlay />
				<DrawerContent>
					<DrawerCloseButton />
					<DrawerHeader borderBottomWidth="1px">Setting</DrawerHeader>
					<DrawerBody py={5}>
						<List spacing={3}>
							<SettingItem
								text="Hide from my portfolio"
								selected={checkedHidden}
								onChange={(val) => {
									onChange(val ? 'hide' : 'unhide');
									setCheckedHidden(val);
								}}
							/>
							{/* <SettingItem
								text="Mark as Spam"
								selected={checkedSpam}
								onChange={(val) => {
									onChange(val, 'spam');
									setCheckedSpam(!checkedSpam);
								}}
							/> */}
						</List>
					</DrawerBody>
				</DrawerContent>
			</Drawer>
		</>
	);
}
