export const TOUR_GUIDE_STEPS_TARGET = {
	HOME_PAGE: {
		AVATAR: 'avatar',
		SEND: 'send',
		DEPOSIT: 'deposit',
		TRADE: 'trade',
		ACTIVITY: 'activity',
		TRENDING_TOKENS: 'trending_token',
		TOBI_FARM: 'tobi_farm',
		MY_PORTFOLIO: 'my_portfolio',
		COPILOT: 'copilot',
		SEARCH_TOKEN: 'search_token',
	},
	TRADE: {
		FROM: 'trade_from',
		TO: 'trade_to',
		AMOUNT: 'trade_amount',
		ROUTE: 'trade_route',
		SWAP: 'trade_swap',
		BUY: 'trade_buy',
	},
};
