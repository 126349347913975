import { TelegramCore } from '@/app-cores/telegram';
import { Box, Text } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { TOUR_GUIDE_THEME_CONFIG } from './TourGuide';

export const StepContent = ({ title, description }: { title: string; description: string }) => {
	const { t } = useTranslation();
	const userName = `${TelegramCore.getUser().last_name} ${TelegramCore.getUser().first_name}`;
	return (
		<Box>
			<Text fontSize="20px" mb={2} color={TOUR_GUIDE_THEME_CONFIG.titleColor}>
				{t(title, {
					username: userName,
				})}
			</Text>
			<Text m={0} color={TOUR_GUIDE_THEME_CONFIG.descriptionColor}>
				{t(description)}
			</Text>
		</Box>
	);
};
