import { CHAIN_CONFIG } from '@/app-constants/chains';
import { getSigner } from '@/app-helpers/web3';
import { usePendingEvmTransaction, useTransactionWatcherStore } from '@/app-store';
import { useMutation } from '@tanstack/react-query';
import { TransactionResponse, ethers } from 'ethers';
import { useEstimateCancelGasFee } from '.';
import { useWallet } from '../wallet';
import { TransactionType } from '../../app-types';

const BUFFER_GAS_PERCENT = 20n;

export function useCancelTransaction({
	txHash,
	bufferGasPercent = BUFFER_GAS_PERCENT,
}: {
	txHash: string;
	bufferGasPercent?: bigint;
}) {
	let transactionRs: TransactionResponse | null = null;
	const { addPendingEvmTransaction } = useTransactionWatcherStore();
	const transaction = usePendingEvmTransaction(txHash);
	const { address } = useWallet();
	const { data: estCancelGasFeeData } = useEstimateCancelGasFee({
		transaction: transaction,
		bufferGasPercent: bufferGasPercent,
	});
	const chainId = Number(transaction?.chainId);
	const { mutateAsync: cancelTransaction, ...result } = useMutation({
		mutationKey: ['cancel-transaction', chainId],
		mutationFn: async () => {
			try {
				const chainConfig = CHAIN_CONFIG[chainId];
				const { signer, provider } = getSigner(chainId);
				const transactionRaw = await signer.signTransaction({
					to: address,
					value: ethers.parseEther('0'),
					gasLimit: estCancelGasFeeData?.gasLimit || 21000,
					chainId: chainId,
					nonce: estCancelGasFeeData?.nonce,
					...(chainConfig.isEIP1559 && {
						maxPriorityFeePerGas: estCancelGasFeeData?.maxPriorityFeePerGas,
						maxFeePerGas: estCancelGasFeeData?.maxFeePerGas,
						type: 2,
					}),
					...(!chainConfig.isEIP1559 && {
						gasPrice: estCancelGasFeeData?.gasPrice,
					}),
				});
				transactionRs = await provider.broadcastTransaction(transactionRaw);
				console.log('Cancel transaction result:', transactionRs);
				return transactionRs;
			} catch (error) {
				console.log('cancel transaction error', error);
				throw new Error(error);
			}
		},
		onSuccess: (receipt) => {
			addPendingEvmTransaction({
				transaction: receipt,
				metadata: { transactionType: TransactionType.Send },
			});
		},
	});
	return {
		...result,
		cancelTransaction,
	};
}
