import { useEffect, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useParseStartApp } from '../../../app-cores/telegram';
import { ConnectionType } from '../../../app-types/connection-type';
import { paringTrackingManager } from '../../app-wallet-connect/utils/HelperUtil';
import { useModalStore } from '../store/ModalStore';
import { ModalData, SessionProposal } from '../store/store.type';
import { botConnectorHandler } from '../utils/bot-connector.handler';
import { useRequestInfo } from './useRequestInfo';
import { v4 as uuid } from 'uuid';

const useBotConnectorParams = () => {
	const [searchParams] = useSearchParams();
	const startParams = useParseStartApp('botconnect');

	return useMemo(() => {
		const searchParamsValue = Object.fromEntries(searchParams.entries());

		// Deprecated
		if (searchParamsValue.protocol === 'text_bot') {
			return searchParamsValue;
		}

		return startParams?.data || {};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
};

export const useBotConnector = () => {
	const params = useBotConnectorParams();
	const { open, onOpen } = useModalStore();
	const { mutate } = useRequestInfo({
		onSuccess: (data, variable) => {
			const { client, payload } = data;
			const matchClient = botConnectorHandler.getClient(client.id);

			if (!matchClient) {
				botConnectorHandler.setClient(variable[0], client);
				onOpen('SessionProposalModal', {
					clientId: variable[0],
					payload: {
						id: uuid(),
						expiryTimestamp: `${Math.round(Date.now() / 1000) + 60 * 60}`,
						method: 'connect',
						connectionData: {
							publicKey: client.publicKey,
							metadata: client.metadata,
						},
					},
				});
				return;
			}

			paringTrackingManager.setActiveTime(ConnectionType.TextBot, client.id);
			const rawData = botConnectorHandler.keyPair.decrypt(
				payload,
				client.publicKey,
				matchClient.topicId,
			) as Exclude<ModalData['payload'], SessionProposal>;

			if (
				rawData.method === 'personal_sign' ||
				rawData.method === 'eth_signMessage' ||
				rawData.method === 'eth_signTransaction'
			) {
				onOpen('SessionSignModal', {
					clientId: client.id,
					topicId: matchClient.topicId,
					payload: rawData,
				});

				return;
			}

			if (rawData.method === 'eth_sendTransaction') {
				onOpen('SessionSendTransactionModal', {
					clientId: client.id,
					topicId: matchClient.topicId,
					payload: rawData,
				});
			}

			if (rawData.method === 'eth_signTypedData_v4') {
				onOpen('SessionSignTypedDataModal', {
					clientId: client.id,
					topicId: matchClient.topicId,
					payload: rawData,
				});
			}
		},
	});

	useEffect(() => {
		const { protocol, clientId, requestId } = params;

		if (protocol !== 'text_bot') {
			return;
		}

		mutate([clientId, requestId, botConnectorHandler.getPublicKey(clientId)]);
	}, [mutate, params]);
};
