import {
	Box,
	Center,
	Divider,
	Drawer,
	DrawerBody,
	DrawerCloseButton,
	DrawerContent,
	DrawerHeader,
	DrawerOverlay,
	Flex,
	Select,
	Stack,
	Text,
} from '@chakra-ui/react';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Badge, LocalLoader } from '../../../../app-components/common';
import { RequestFundActivity } from './Activity';
import CancelActivity from './Cancel';
import { useActivitiesV2 } from './state';
import NoData from '@/app-components/common/NoData';
import { IActivity, WalletActivity } from '@/app-cores/api/activities';
import { FilterActivity } from '@/app-views/wallet/components/Activities/Filter';
import { useUserSettingsStore } from '@/app-store/settings';
import { BASE_BORDER_COLOR } from '@/app-theme/theme';
import BaseSelect, { SelectCustom } from '@/app-components/common/BaseSelect';
import { useQueryGetRequestFund } from '@/app-hooks/api/payment/useClaimFund';
import { FeatureConfig, useQueryFeatureConfig } from '@/app-hooks/api/configuration';
import { ActivitySkeleton } from './ActivitySkeleton';
import { ActivityItem } from './ActivityItem';

enum FilterTxsType {
	ACTIVITY = 'Activity',
	REQUEST_FUND = 'Request fund',
}

const ListTxs = ({ completeActivities }: { completeActivities: WalletActivity[] }) => {
	const renderItem = (item: WalletActivity, isPending: boolean) => {
		try {
			return (
				<ActivityItem
					key={`${item.transactionHash}-${item.transactionType}-${
						item?.sendDetail?.idTobi || item?.receiveDetail?.idTobi
					}`}
					data={item}
					isPending={isPending}
					listData={completeActivities}
				/>
			);
		} catch (error) {
			console.warn('render txs err', error);
			return null;
		}
	};

	return (
		<>
			{/* {pendingActivities?.map((item) => renderItem(item, true))} */}
			{completeActivities?.map((item) => renderItem(item, false))}
		</>
	);
};

const Activities: React.FC<{ isOpen: boolean; onClose: () => void; tokenAddress?: string; chainId?: string }> = ({
	isOpen,
	onClose,
	tokenAddress,
	chainId,
}) => {
	const [filterType, setFilterType] = useState(FilterTxsType.ACTIVITY);

	const { t } = useTranslation();
	const { showSpamActivity, showTestnetActivity } = useUserSettingsStore();
	const { completeActivities, isFetching, error, refetch } = useActivitiesV2(
		isOpen && filterType === FilterTxsType.ACTIVITY,
	);
	const responseRequestPayment = useQueryGetRequestFund(isOpen && filterType === FilterTxsType.REQUEST_FUND);
	const requestFunds = responseRequestPayment?.data?.data;
	const totalToPaid = responseRequestPayment?.data?.totalToPaid;

	useEffect(() => {
		if (!isOpen) return;
		refetch();
	}, [refetch, isOpen]);

	const options = useMemo(() => {
		return [
			{ label: t('activity.transactions'), value: FilterTxsType.ACTIVITY },
			{
				label: `${t('activity.requests')} ${totalToPaid ? `(${totalToPaid})` : ''}`,
				value: FilterTxsType.REQUEST_FUND,
			},
		];
	}, [totalToPaid]);

	const isTxsActivity = filterType === FilterTxsType.ACTIVITY;

	const renderActivities = useMemo(() => {
		const nodata = (
			<Stack alignItems={'center'} justifyContent={'center'} py={5}>
				<NoData msg={isTxsActivity ? t('activity.empty') : t('requestPayment.emptyList')} />
			</Stack>
		);

		if (isTxsActivity) {
			if (!completeActivities?.length) {
				return nodata;
			}
			return <ListTxs {...{ completeActivities }} />;
		}

		// request fund
		if (!requestFunds?.length) return nodata;
		return requestFunds.map((item) => <RequestFundActivity data={item} key={item.id} />);
	}, [isTxsActivity, t, requestFunds, completeActivities]);

	const isSupportRequestFund = useQueryFeatureConfig(FeatureConfig.REQUEST_FUND);

	return (
		<>
			<Drawer placement="bottom" onClose={onClose} isOpen={isOpen} trapFocus={false} isFullHeight>
				<DrawerOverlay />
				<DrawerContent>
					<DrawerHeader px={0} pb={0}>
						<Text>{t('activity.activity')}</Text>
						<DrawerCloseButton />
						<Divider height={2} borderBottomWidth="1px" borderBottomColor="rgba(0, 0, 0, 0.08)" />
						<Flex px={4} mt={3} justifyContent={isSupportRequestFund ? 'space-between' : 'flex-end'}>
							{isSupportRequestFund && (
								<Box position={'relative'}>
									<SelectCustom
										value={filterType}
										borderRadius={'16px'}
										onChange={(e) => setFilterType(e as FilterTxsType)}
										options={options}
									/>
									{!!totalToPaid && (
										<Badge type="danger" sx={{ position: 'absolute', right: '-8px', top: '-8px' }}>
											{totalToPaid}
										</Badge>
									)}
								</Box>
							)}
							{/* {isTxsActivity && <FilterActivity />} */}
						</Flex>
					</DrawerHeader>
					<DrawerBody px={4} py={0} mt={4} className="hide-scrollbar">
						{isFetching ? <ActivitySkeleton /> : renderActivities}
					</DrawerBody>
				</DrawerContent>
			</Drawer>
			<CancelActivity />
		</>
	);
};

export default Activities;
