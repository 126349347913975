import { MpcWalletProvider } from '@/app-cores/mpc-wallet/wallet';
import { createContext, useCallback, useMemo, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import { NetWorkChain } from './type';
import { CHAIN_CONFIG, ChainId, ethereum } from '@/app-constants/chains';
import { getRPC } from '@/app-helpers/token';
import { Toast } from '@/app-components/common';
interface WalletContext {
	networkChain: NetWorkChain;
	onConnect: (chainId: number) => void;
	getMpcWallet: (chainId: number) => MpcWalletProvider;
}
export const WalletContext = createContext<WalletContext>({
	networkChain: ethereum,
	onConnect: (chainId) => {},
	getMpcWallet: (chainId) => undefined,
});

export const WalletProvider: React.FunctionComponent<React.PropsWithChildren> = ({ children }) => {
	const [chainId, setChainId] = useState<ChainId>(ethereum.id);
	const mpcWalletProvider = useRef<Record<string, MpcWalletProvider>>({});

	const onSelectChain = useCallback(
		(networkChainId: number) => {
			if (chainId === networkChainId) return;
			if (!CHAIN_CONFIG.has(networkChainId)) {
				toast(<Toast type="error" message="Network does not support the requested chain." />);
				return;
			}
			setChainId(CHAIN_CONFIG[networkChainId].id);
		},
		[chainId],
	);

	const getMpcWallet = useCallback((chainId: number) => {
		if (!mpcWalletProvider.current[chainId]) {
			mpcWalletProvider.current[chainId] = new MpcWalletProvider(getRPC(chainId.toString()).rpc);
		}

		return mpcWalletProvider.current[chainId];
	}, []);

	const values = useMemo(
		() => ({
			networkChain: CHAIN_CONFIG[chainId],
			onConnect: onSelectChain,
			getMpcWallet,
		}),
		[chainId, onSelectChain, getMpcWallet],
	);
	return <WalletContext.Provider value={values}>{children}</WalletContext.Provider>;
};
