import {
	Box,
	Button,
	Center,
	Drawer,
	DrawerBody,
	DrawerContent,
	DrawerHeader,
	DrawerOverlay,
	Text,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { FiCheckIcon } from '@/assets/icons';
import { useNavigate } from 'react-router';
import { DATADOG_ACTIONS, dataDogAddAction } from '@/app-services/monitor/datadog';

interface DepositResultProps {
	isOpen: boolean;
	onClose: () => void;
	data?: any;
	callbackUrl: string;
}

export const DepositResult: React.FC<DepositResultProps> = ({ isOpen, onClose, data, callbackUrl }) => {
	const { t } = useTranslation();
	const navigate = useNavigate();

	return (
		<Drawer isOpen={isOpen} onClose={() => {}} placement="bottom" closeOnOverlayClick={false}>
			<DrawerOverlay />
			<DrawerContent>
				<DrawerHeader borderBottomWidth="1px" borderBottomColor="rgba(0, 0, 0, 0.08)">
					<Text align="center" fontSize="16px" fontWeight={500}>
						{t('deposit.deposit')}
					</Text>
				</DrawerHeader>
				{/* <DrawerCloseButton /> */}
				<DrawerBody
					pt={5}
					display="flex"
					justifyContent="space-between"
					alignItems="center"
					flexDirection="column"
					gap={5}
				>
					<Center width={14} height={14} borderRadius="100%" backgroundColor="#00E9DB">
						<FiCheckIcon />
					</Center>
					<Text textAlign="center" fontSize="28px" fontWeight={700} lineHeight="125%">
						{t('deposit.depositResult', {
							amount: data?.cryptoAmount,
							symbol: data?.cryptoCurrency,
						})}
					</Text>
					<Center flexDirection="column" width="100%" gap={3}>
						{callbackUrl !== '/' && callbackUrl !== 'null' && (
							<Button
								width="100%"
								colorScheme="cyan"
								size="lg"
								fontWeight="medium"
								onClick={() => {
									dataDogAddAction(DATADOG_ACTIONS.DEPOSIT_BY_TRANSAK_RESULT.REDIRECT_BACK);
									history.replaceState(null, null, '/');
									navigate(callbackUrl);
								}}
							>
								{t('button.continue')}
							</Button>
						)}
						<Button
							width="100%"
							size="lg"
							colorScheme={callbackUrl === '/' ? 'cyan' : 'gray'}
							fontWeight="medium"
							onClick={() => navigate('/')}
						>
							{t('deposit.checkYourBalance')}
						</Button>
					</Center>
				</DrawerBody>
			</DrawerContent>
		</Drawer>
	);
};
