import { isEmpty } from 'lodash';
import { memo, useEffect, useRef, useState } from 'react';
import bs58 from 'bs58';
import { UserProfile, WalletType } from '@/app-cores/api';
import { useWalletStore } from '@/app-store';
import { MpcWallet } from '@/app-cores/mpc-wallet/wallet';
import { TelegramCore } from '@/app-cores/telegram';
import { useSyncUserWallet } from '@/app-hooks/api/mpc-wallet';
import { AuthClient } from '@/app-cores/auth';
import { compareAddress } from '@/app-helpers/address';
import { DATADOG_ACTIONS, dataDogAddAction } from '@/app-services/monitor/datadog';
import { BackgroundApp } from '@/app-components/layout/BackGround';

interface WalletCreationProps {
	userProfile: UserProfile;
}
export const WalletCreation: React.FC<WalletCreationProps> = memo(({ userProfile }) => {
	const { dklsInitializeStatus, setWalletStatus } = useWalletStore();
	const { mutateAsync: syncUserWallet } = useSyncUserWallet();
	const initializeRef = useRef(false);

	useEffect(() => {
		async function initWallet() {
			console.time('precomputed_wallet');
			setWalletStatus('INITIALIZING');
			const userTonAddress = userProfile.wallets?.find((w) => w.type === WalletType.TON)?.formatedAddress;
			const userEvmAddress = userProfile.wallets?.find((w) => w.type === WalletType.EVM)?.formatedAddress;
			const walletStatus = await MpcWallet.walletStatus(
				{
					evmWallet: userEvmAddress,
					tonWallet: userTonAddress,
				},
				userProfile?.backupType,
			);
			setWalletStatus(walletStatus);

			if (walletStatus !== 'VALIDATED' && walletStatus !== 'REQUIRE_BACKUP') return;

			const localWallet = MpcWallet.getWalletAddress();
			const localEvmAddress = localWallet?.evmAddress;

			//TODO: Will remove after all users already sync sol wallet
			const syncUserSolWallet = async () => {
				const payload = JSON.stringify(
					TelegramCore.withInitData({
						address: {
							evm: localWallet.evmAddress,
							'ton-base': localWallet.tonAddress,
							'solana': localWallet.solAddress,
						},
						timestamp: Date.now(),
					}),
				);
				const signature = await MpcWallet.signEddsaMessage(Buffer.from(payload, 'utf-8'));
				await syncUserWallet({
					payload,
					signature: bs58.encode(signature),
				});
				await AuthClient.authenticate();
			};

			if (!compareAddress(localEvmAddress, userEvmAddress)) {
				await MpcWallet.removeCloudKeyShare();
			} else {
				MpcWallet.saveToCloudIfNeeded();
				const userSolAddress = userProfile.wallets?.find((w) => w.type === WalletType.SOL)?.formatedAddress;
				if (!userSolAddress && localWallet.solAddress) {
					try {
						await syncUserSolWallet();
						dataDogAddAction(DATADOG_ACTIONS.SYNC_USER_WALLETS.SOLANA.SUCCESS);
					} catch (error) {
						console.error('Sync sol wallet error', error);
						dataDogAddAction(DATADOG_ACTIONS.SYNC_USER_WALLETS.SOLANA.ERROR);
					}
				}
			}

			console.timeEnd('precomputed_wallet');
		}

		if (dklsInitializeStatus !== 'SUCCESS') return;
		if (isEmpty(userProfile) || initializeRef.current) return;
		initializeRef.current = true;
		initWallet();
	}, [dklsInitializeStatus, setWalletStatus, syncUserWallet, userProfile]);

	return <BackgroundApp />;
});
