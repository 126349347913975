import { TelegramCore } from '@/app-cores/telegram';
import { useEffect, useState } from 'react';
import { useOnEventCallback } from '.';

export const useAppExpansion = () => {
	const [isExpanded, setIsExpanded] = useState<boolean>(TelegramCore.WebApp.isExpanded);

	const expand = useOnEventCallback(() => {
		TelegramCore.WebApp.expand();
	});

	useEffect(() => {
		const handle = () => {
			setIsExpanded(TelegramCore.WebApp.isExpanded);
		};

		TelegramCore.WebApp.onEvent('viewportChanged', handle);

		return () => {
			TelegramCore.WebApp.offEvent('viewportChanged', handle);
		};
	}, []);

	return [isExpanded, expand];
};
