import { SessionTypes, SignClientTypes } from '@walletconnect/types';
import { Web3WalletTypes } from '@walletconnect/web3wallet';
import { StoreApi, create } from 'zustand';

interface ModalData {
	proposal?: SignClientTypes.EventArguments['session_proposal'];
	requestEvent?: SignClientTypes.EventArguments['session_request'];
	requestSession?: SessionTypes.Struct;
	request?: Web3WalletTypes.AuthRequest;
}

type ModalView =
	| 'SessionProposalModal'
	| 'SessionSendTransactionModal'
	| 'SessionSignModal'
	| 'SessionSignTypedDataModal'
	| 'SessionUnSupportedMethodModal'
	| 'AuthRequestModal'
	| 'SessionApprovalModal'
	| 'SessionSignMessageSolanaModal'
	| 'SessionSignTransactionSolanaModal'
	| 'SessionSignMessageTronModal'
	| 'SessionSignTransactionTronModal';

export interface IModalStore {
	open: boolean;
	view?: ModalView;
	data?: ModalData;
	openWalletConnect: boolean;
	isWaiting?: boolean;
	onOpen: (view: ModalView, data?: ModalData) => void;
	onClose: () => void;
	onOpenWalletConnect: () => void;
	onCloseWalletConnect: () => void;
	setIsWaiting: (waiting: boolean) => void;
}

export const useModalStore = create<IModalStore>((set: StoreApi<IModalStore>['setState']) => ({
	open: false,
	// open: true,
	openWalletConnect: false,
	isWaiting: false,
	// view: 'SessionApprovalModal',
	onOpen: (view: ModalView, data?: ModalData) => {
		set(() => ({ open: true, view, data, isWaiting: false }));
	},
	onClose: () => {
		set(() => ({ open: false, data: undefined }));
	},
	onOpenWalletConnect: () => {
		set(() => ({ openWalletConnect: true }));
	},
	onCloseWalletConnect: () => {
		set(() => ({ openWalletConnect: false }));
	},
	setIsWaiting: (isWaiting) => {
		set(() => ({ isWaiting }));
	},
}));
