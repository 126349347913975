import { create } from 'zustand';

import { ContactAccount } from '@/app-cores/api/contact';

const initializeData = {
	friends: [],
	amountMapping: {},
	message: '',
	totalAmount: '',
};
interface IRequestFundStore {
	friends: ContactAccount[];
	amountMapping: Record<string, string>;
	message: string;
	totalAmount: string;
	setMessage: (v: string) => void;
	setTotalAmount: (v: string) => void;
	setFriends: (friends: ContactAccount[]) => void;
	setAmount: (id: string, amount: string) => void;
	reset: () => void;
}

export const useRequestFundStore = create<IRequestFundStore>()((set) => ({
	...initializeData,
	setFriends: (friends) => {
		set({ friends });
	},
	setMessage: (message) => {
		set({ message });
	},
	setTotalAmount: (totalAmount) => {
		set({ totalAmount });
	},
	setAmount: (userId, amount) => {
		set((state) => {
			const amountMapping = { ...state.amountMapping, [userId]: amount };
			return { amountMapping };
		});
	},
	reset: () =>
		set((state) => ({
			...state,
			...initializeData,
		})),
}));
