import { Box, Container, Heading, Link, Text } from '@chakra-ui/react';
import PageTransition from '@/app-components/layout/PageTransition/PageTransition';
import PageNavigation from '@/app-components/layout/PageWrapper/PageWrapper';

const PrivacyPolicy = () => {
	return (
		<PageTransition>
			<PageNavigation>
				<Container height="100vh" overflow="auto" py={4}>
					<Heading as="h1">Privacy Policy</Heading>
					<Box mt={4} fontSize="sm">
						<Text mb={3}>Last Updated: May 10, 2024</Text>
						<Text>
							This Privacy Policy describes the policies and procedures of TBW Labs S.A. (“we,” “our,” or
							“us”) pertaining to the collection, use, and disclosure of your information on the Tobi
							application and website.
						</Text>
						<Box>
							<Heading as="h2" fontSize={24} mt={4} mb={2}>
								Overview
							</Heading>
							<Text>
								Your right to privacy and the protection of your personal data is important to us. The
								following sections provide further details as to how we process your personal
								information through Tobi. We don’t share your information with third parties except to
								deliver you our Services and products, comply with the law, make Tobi better, protect
								our rights, or effectuate a business transfer. If you have any questions or concerns
								about this policy, please reach out to us at support@tobilabs.io.
							</Text>
						</Box>
						<Box>
							<Heading as="h2" fontSize={24} mt={4} mb={2}>
								How do you accept this policy
							</Heading>
							<Text>
								By using Tobi, you agree to the use, disclosure, and procedures outlined in this Privacy
								Policy.
							</Text>
						</Box>
						<Box>
							<Heading as="h2" fontSize={24} mt={4} mb={2}>
								What personal information do we collect from our users
							</Heading>
							<Text>
								We do our best to minimize the amount of Personal Information that we collect from Tobi
								users. You may provide us with information about yourself when you register to use our
								Services, such as when you provide us with personal details, such as your Telegram
								username, profile name, profile picture, Telegram ID, email, and phone number. This also
								includes information you provide by continuing to use our Services, participating in a
								promotion or survey, and reporting problems with our Services. The information you give
								us may include your name, date of birth, address, phone number, identification
								information (such as proof of identity and nationality), and financial information. We
								may also receive information that you pass on to third parties (e.g., Telegram) when
								using Tobi Services.
							</Text>
						</Box>
						<Box>
							<Heading as="h3" fontSize={21} mt={4} mb={2}>
								Third Parties
							</Heading>
							<Text>
								Information about our users is an important part of our business and we are not in the
								business of selling our users’ personal information to others. We may access your
								personal data via third parties and/or transfer personal data to our service providers
								or third parties in connection with Tobi's operation of its business, as certain
								features on Tobi rely on various third-party products and services (collectively “Third
								Party Services”). These third party services providers only have access to certain
								Personal Information, such as your public blockchain addresses, to perform their
								functions and may not use it for other purposes. Furthermore, they must process the
								personal information in accordance with our contractual agreements and only as permitted
								by applicable data protections laws.
							</Text>
						</Box>
						<Box>
							<Heading as="h3" fontSize={21} mt={4} mb={2}>
								Google APIs
							</Heading>
							<Text>
								Some Tobi features, should you choose to use them, involve accessing information via
								Google APIs. Tobi's use and transfer to any other app of information received from
								Google APIs will adhere to{' '}
								<Link
									textDecoration="underline"
									href="https://developers.google.com/terms/api-services-user-data-policy#additional_requirements_for_specific_api_scopes"
									target="_blank"
								>
									Google API Services User Data Policy, including the Limited Use requirements.
								</Link>
							</Text>
						</Box>
						<Box>
							<Heading as="h2" fontSize={24} mt={4} mb={2}>
								How we use the information we gather
							</Heading>
							<Text>
								We primarily use the limited information that we collect to enhance Tobi. Except if we
								sell all or a portion of our business, or as otherwise described below, we DO NOT rent,
								trade, or sell your Personal Information.
							</Text>
							<Text mt={3}>Some ways we may use your Personal Information are to:</Text>
							<Box as="ul" listStylePosition="inside" transform="translateX(8px)">
								<Box as="li">Contact you when necessary;</Box>
								<Box as="li">
									Respond to your comments, questions, or issues related to bugs or errors with Tobi;
								</Box>
								<Box as="li">Provide you with additional information;</Box>
								<Box as="li">
									Send you information and marketing materials about services and products available
									through Tobi, using push notifications or other means;
								</Box>
								<Box as="li">Train our team members;</Box>
								<Box as="li">Other internal business purposes.</Box>
							</Box>
						</Box>
						<Box>
							<Heading as="h3" fontSize={21} mt={4} mb={2}>
								Aggregated Personal Data and Non-Personal Information
							</Heading>
							<Text>
								We may share or disclose aggregated Personal Data or Non-Personal Information with
								service providers or with other persons we conduct business with, including but not
								limited potential third-parties for the purpose of showcasing the performance of the
								company. These service providers and other persons may also share with us aggregated
								Non-Personal Information that they have independently developed or acquired.
								Additionally, we may combine aggregate information with similar data we collect from
								other visitors to help us improve our Services. When doing so, we do our best to ensure
								that aggregated information cannot be linked back to you.
							</Text>
						</Box>
						<Box>
							<Heading as="h3" fontSize={21} mt={4} mb={2}>
								Agents or Third Party Partners
							</Heading>
							<Text>
								We may provide your Personal Information to our employees, contractors, agents, service
								providers, and designees (“Agents”) to enable them to perform certain services for us,
								including improvement of website-related services and features, and performance of
								maintenance services.
							</Text>
						</Box>
						<Box>
							<Heading as="h3" fontSize={21} mt={4} mb={2}>
								Business Transfers
							</Heading>
							<Text>
								We may choose to buy or sell assets. In these types of transactions, customer
								information is typically one of the business assets that would be transferred. Also, if
								we (or our assets) are acquired, or if we go out of business, enter bankruptcy, or go
								through some other change of control, your Personal Information could be one of the
								assets transferred to or acquired by a third party. In such transactions, user
								information generally is one of the transferred business assets but remains subject to
								the promises made in any pre-existing Privacy Policy (unless, of course, the user
								consents otherwise).
							</Text>
						</Box>
						<Box>
							<Heading as="h3" fontSize={21} mt={4} mb={2}>
								Protection of Us and Others
							</Heading>
							<Text>
								We will share personal information outside of Tobi if we have a reasonable belief that
								access, use, preservation, or disclosure of the information is reasonably necessary to
								comply with any applicable law, regulation, legal process, or enforceable governmental
								request; to cooperate with law enforcement; to enforce or apply our Terms of Service and
								other agreements; or to protect the rights, property, or safety of Tobi, our employees,
								our users, or others.
							</Text>
						</Box>
						<Box>
							<Heading as="h2" fontSize={24} mt={4}>
								Your rights with respect to the processing of personal data
							</Heading>
							<Text>In relation to your personal data, you have the right to:</Text>
							<Box as="ul" listStylePosition="inside" transform="translateX(8px)">
								<Box as="li">Access to your personal data;</Box>
								<Box as="li">The rectification of your personal data;</Box>
								<Box as="li">Restrict processing;</Box>
								<Box as="li">Object to processing;</Box>
								<Box as="li">Data portability;</Box>
								<Box as="li">Request erasure of your personal data.</Box>
							</Box>
							<Text mt={3}>
								You shall also have the right to ask us not to process your personal data for marketing
								purposes. We will seek your explicit consent (before collecting your data) if we intend
								to use your data for such purposes or if we intend to disclose your information to any
								third party for such purposes. You can exercise your right to prevent such processing by
								checking certain boxes on the forms we use to collect your data. You can also withdraw
								your consent by contacting our Data Protection Officer.
							</Text>
							<Text mt={3}>
								Our site may, from time to time, contain links to and from the websites of our partner
								networks, advertisers and affiliates. If you follow a link to any of these websites,
								please note that these websites have their own privacy policies and that we do not
								accept any responsibility or liability for these policies. Please check these policies
								before you submit any personal data to these websites.
							</Text>
						</Box>
						<Box>
							<Heading as="h2" fontSize={24} mt={4} mb={2}>
								Data retention
							</Heading>
							<Text>
								Please note that even if you delete your Tobi addresses and/or account from the Tobi
								application, uninstall Tobi applications from your device, or request that your
								information be deleted, we still may retain some information that you have provided to
								us to maintain Tobi or to comply with the laws and regulations to which Tobi is subject.
								If you have any question or objection as to how we collect and process your personal
								information, please contact support@tobilabs.io.
							</Text>
						</Box>
						<Box>
							<Heading as="h2" fontSize={24} mt={4} mb={2}>
								Data security
							</Heading>
							<Text>
								We are committed to making sure your information is protected in accordance with
								applicable laws and our data privacy policies. We will use all reasonable efforts to
								protect your information in a highly secure data centre, adhering to strict computer
								security standards. We have put in place privacy protection control systems designed to
								ensure that our customers’ information remains safe, secure and private.
							</Text>
							<Text mt={3}>
								Access to Personal Data is only limited to authorised personnel of Tobi who are fully
								trained in handling your information. These authorised personnel are required to ensure
								the confidentiality of your information and to respect your data protection rights at
								all times. Personnel who have access to your information will be subjected to
								disciplinary action should they fail to observe this Privacy Policy and other
								guidelines, code of conduct or policies which we may issue to them from time to time.
							</Text>
							<Text mt={3}>
								The data that we collect from you may be transferred to, and stored at, via third
								parties for certain purposes. It may also be processed by staff who work for us or for
								one of our suppliers. Such staff may be engaged in, among other things, the fulfilment
								of your order, the processing of your payment details and the provision of support
								services. By submitting your personal data, you agree to this transfer, storing or
								processing. We take all steps reasonably necessary to ensure that your data is treated
								securely using sufficient and appropriate safeguards and in compliance with all legal
								requirements as well as this Privacy Policy and our strict codes of conduct.
							</Text>

							<Text mt={3}>
								Unfortunately, the transmission of information via the internet is not completely
								secure. Although we will do our best to protect your personal data, we cannot guarantee
								the security of your data transmitted to our site; any transmission is at your own risk.
								Once we have received your data, we will use strict procedures and security features to
								try to prevent unauthorised access.
							</Text>
							<Text mt={3}>
								In line with applicable law, we shall, where lawfully obliged, notify the competent
								authorities and/or you in cases of personal data breach and will keep a log of any such
								breaches.
							</Text>
						</Box>
						<Box>
							<Heading as="h2" fontSize={24} mt={4} mb={2}>
								Conditions of use, policies, changes and updates to privacy policy
							</Heading>
							<Text>
								If you choose to use Tobi, your use and any dispute over privacy is subject to this
								Policy and our Terms of Service. If you have any concerns about privacy at Tobi, please
								contact us with a complete description, and we will try to resolve it. You also have the
								right to contact your local Data Protection Authority. We reserve the right to update
								and revise this Privacy Policy at any time. We occasionally review this Privacy Policy
								to make sure it complies with applicable laws and conforms to changes in our business.
								If we do revise this Privacy Policy, we will update the “Last Updated” date at the top
								of this page so that you can tell if it has changed since your last visit and will do
								our best to notify you. Please review this Privacy Policy regularly to ensure that you
								are aware of its terms. Any use of Tobi after an amendment to our Privacy Policy
								constitutes your acceptance to the revised or amended terms.
							</Text>
						</Box>
						<Box>
							<Heading as="h2" fontSize={24} mt={4} mb={2}>
								Questions
							</Heading>
							<Text>
								We’d be happy to answer them; shoot us an email or send us a note at
								support@tobilabs.io.
							</Text>
						</Box>
					</Box>
				</Container>
			</PageNavigation>
		</PageTransition>
	);
};
export default PrivacyPolicy;
