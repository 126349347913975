import { ChainId } from '@/app-constants/chains';
import { axiosInfuraService } from '../axios';
import { EIP155GasPrice } from './type';
import { delayWithData } from '@/app-helpers';
class InfuraService {
	async getEIP155GasPrice(chainId: number | string): Promise<EIP155GasPrice> {
		try {
			if ([ChainId.BERACHAIN_TESTNET].includes(chainId as any)) {
				const data = await delayWithData<EIP155GasPrice>(100, {} as any);
				return data;
			}
			const response = await axiosInfuraService.get(`/networks/${chainId}/suggestedGasFees`);
			return response.data;
		} catch (error) {
			console.log('getEIP155GasPrice error:', error);
			return {} as EIP155GasPrice;
		}
	}
}

export const InfuraServiceAPI = new InfuraService();
