import { Box, Center, Text } from '@chakra-ui/react';
import { useNavigate } from 'react-router';
import { isNil } from 'lodash-es';
import { CTAButton } from '@/app-components/common';
import AppLayout from '@/app-components/layout/PageLayout';
import { FiRefreshKeyshareIcon } from '@/assets/icons';
import { NAVIGATE_PATHS } from '@/app-constants/router';
import { getUnsecuredKeyStore } from '@/app-cores/mpc-wallet/wallet/helper';

export const RefreshKeyshare = () => {
	const navigate = useNavigate();
	const handleRefreshKeyShare = () => {
		const unsecuredKey = getUnsecuredKeyStore();
		if (isNil(unsecuredKey)) {
			return navigate(NAVIGATE_PATHS.RefreshKeyshareMethod);
		}
		navigate(NAVIGATE_PATHS.ProcessRefreshKeyshare);
	};
	return (
		<AppLayout
			header={null}
			footer={
				<Box px={4} my={4}>
					<CTAButton
						size="lg"
						fontWeight="medium"
						colorScheme="cyan"
						width="100%"
						onClick={handleRefreshKeyShare}
					>
						Do Key-Refresh now
					</CTAButton>
				</Box>
			}
		>
			<Box mt={6} p={3} fontWeight={500} borderRadius="12px">
				<Center>
					<FiRefreshKeyshareIcon />
				</Center>
				<Box mt={12} fontSize="xs">
					<Text fontWeight={500} fontSize="medium">
						Why do I need to refresh my Key Share?
					</Text>
					<Text color="gray.400" mt={3}>
						<Box color="black" as="strong">
							Dummy Content.
						</Box>{' '}
						Tobi operates on a Multi-Party Computation (MPC) wallet system, meaning multiple parties hold
						fragments of your wallet's access key shares. One of these key shares will be backed up in a
						file you store on iCloud or Google Drive. Securely saving this file in the cloud allows you to
						recover your wallet if your phone is lost, or you need to access it from another device, like a
						desktop computer or a spare phone.
					</Text>
					<Text fontSize="xs" color="gray.400" mt={3}>
						<Box color="black" as="strong">
							Important:
						</Box>{' '}
						This backup file is encrypted and under your control alone; Tobi or any other party cannot
						access this backup key share or any of your funds. Never share or send this file to anyone to
						ensure your assets remain secure.
					</Text>
				</Box>
			</Box>
		</AppLayout>
	);
};
