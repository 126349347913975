import { motion } from 'framer-motion';
import { usePortfolioState } from '../Portfolio/state';
import { LoadingSpinner } from '@/app-components/common';

export const RefreshLoading = () => {
	const { isPullToRefresh } = usePortfolioState();
	return (
		<motion.div
			initial={{ scale: 0, opacity: 0, height: 0 }}
			animate={{
				scale: isPullToRefresh ? 1 : 0,
				opacity: isPullToRefresh ? 1 : 0,
				height: isPullToRefresh ? 60 : 0,
			}}
			transition={{ duration: 0.4 }}
		>
			{isPullToRefresh ? <LoadingSpinner /> : null}
		</motion.div>
	);
};
