import { Box, BoxProps, Container } from '@chakra-ui/react';
import Bg from '@/assets/images/bg.svg';
import Overlay from '@/assets/images/bg-overlay.png';

export default function ContainerWithBackgroundImage({
	children,
	hasBackground = true,
	...rest
}: BoxProps & { hasBackground?: boolean }) {
	return (
		<Container
			{...rest}
			backgroundImage={hasBackground ? Bg : undefined}
			backgroundSize={'cover'}
			backgroundRepeat={'no-repeat'}
			backgroundPosition={'center'}
			position={'relative'}
		>
			{hasBackground && (
				<Box
					sx={{ width: '100%', height: '100%', mixBlendMode: 'overlay', position: 'absolute', inset: 0 }}
					backgroundImage={Overlay}
					backgroundSize={'64px 64px'}
					backgroundRepeat={'repeat'}
					backgroundPosition={'center'}
				/>
			)}
			{children}
		</Container>
	);
}
