import { ChevronRightIcon } from '@/assets/images/svg';
import {
	Drawer,
	DrawerBody,
	DrawerCloseButton,
	DrawerContent,
	DrawerHeader,
	DrawerOverlay,
	Flex,
} from '@chakra-ui/react';
import { useState } from 'react';
import { CodeBlock } from 'react-code-blocks';

const TransactionDataDetail = ({ transactionData }: { transactionData: Record<string, any> }) => {
	const [isViewFullDetail, setIsViewFullDetail] = useState(false);
	return (
		<Flex flexDirection={'column'}>
			<Flex
				cursor={'pointer'}
				onClick={() => setIsViewFullDetail(true)}
				fontSize={'12px'}
				alignItems={'center'}
				fontWeight={'500'}
			>
				View full transaction details <ChevronRightIcon width={20} />
			</Flex>
			<Drawer
				placement={'bottom'}
				onClose={() => setIsViewFullDetail(false)}
				isOpen={isViewFullDetail}
				trapFocus={false}
			>
				<DrawerOverlay />
				<DrawerContent>
					<DrawerCloseButton />
					<DrawerHeader borderBottomWidth="1px">Raw Data</DrawerHeader>
					<DrawerBody padding={0}>
						<CodeBlock
							showLineNumbers={false}
							text={JSON.stringify(transactionData, null, 2)}
							language="json"
							wrapLongLines
							customStyle={{
								fontSize: '12px',
							}}
						/>
					</DrawerBody>
				</DrawerContent>
			</Drawer>
		</Flex>
	);
};

export default TransactionDataDetail;
