import { useQuery } from '@tanstack/react-query';
import { useERC20Contract } from '@/app-hooks/wallet/useERC20Contract';

export function useDecimal(chainId: string, contract: string) {
	const erc20Contract = useERC20Contract(contract, chainId);
	const decimal = useQuery({
		queryKey: ['get-token-decimal'],
		queryFn: async () => {
			return erc20Contract?.decimals?.();
		},
		enabled: !!chainId && !!contract,
	});
	return decimal;
}
