import { ServiceUser, UpdateAffiliateCodeArgs } from '@/app-cores/api/bot';
import { TelegramCore } from '@/app-cores/telegram';
import { useMutation } from '@tanstack/react-query';

export const useMutationUpdateAffiliateCode = () => {
	return useMutation({
		mutationKey: ['mutation-update-affiliate-code', TelegramCore.getUserId()],
		mutationFn: async (args: UpdateAffiliateCodeArgs): Promise<boolean> => {
			return ServiceUser.updateAffiliateCode(args);
		},
	});
};
