import React from 'react';

interface IProps extends React.SVGAttributes<HTMLOrSVGElement> {}

export const PolygonIcon: React.FC<IProps> = ({ width = 56, height = 56, ...rest }) => {
	return (
		<svg width={width} height={height} viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
			<g clipPath="url(#clip0_8601_5465)">
				<path
					d="M28.033 0.0439453C43.4798 0.0439453 56 12.5642 56 28.0109C56 43.4577 43.4798 55.9669 28.044 55.9669C12.5973 55.9669 0.0880199 43.4467 0.0880199 28.0109C0.077018 12.5642 12.5973 0.0439453 28.033 0.0439453Z"
					fill="#8247E5"
				/>
				<mask
					id="mask0_8601_5465"
					// style="mask-type:luminance"
					maskUnits="userSpaceOnUse"
					x="9"
					y="12"
					width="38"
					height="33"
				>
					<path d="M46.1643 12.1353H9.51672V44.25H46.1643V12.1353Z" fill="white" />
				</mask>
				<g mask="url(#mask0_8601_5465)">
					<path
						d="M37.1976 21.916C36.5265 21.5309 35.6684 21.5309 34.9092 21.916L29.5623 25.0845L25.9316 27.0979L20.6837 30.2555C20.0126 30.6405 19.1544 30.6405 18.3953 30.2555L14.2916 27.758C13.6204 27.3729 13.1474 26.6028 13.1474 25.7446V20.9478C13.1474 20.1777 13.5324 19.4185 14.2916 18.9344L18.3953 16.536C19.0664 16.1509 19.9246 16.1509 20.6837 16.536L24.7874 19.0334C25.4586 19.4185 25.9316 20.1887 25.9316 21.0468V24.2154L29.5623 22.103V18.8464C29.5623 18.0763 29.1772 17.3171 28.4181 16.8331L20.7827 12.3222C20.1116 11.9372 19.2534 11.9372 18.4943 12.3222L10.6719 16.9321C9.91278 17.3171 9.52771 18.0873 9.52771 18.8464V27.857C9.52771 28.6272 9.91278 29.3863 10.6719 29.8704L18.4063 34.3812C19.0774 34.7663 19.9356 34.7663 20.6947 34.3812L25.9426 31.3116L29.5733 29.1993L34.8212 26.1297C35.4923 25.7446 36.3505 25.7446 37.1096 26.1297L41.2134 28.5281C41.8845 28.9132 42.3576 29.6833 42.3576 30.5415V35.3384C42.3576 36.1085 41.9725 36.8676 41.2134 37.3517L37.2087 39.7501C36.5375 40.1352 35.6794 40.1352 34.9202 39.7501L30.8055 37.3517C30.1344 36.9666 29.6613 36.1965 29.6613 35.3384V32.2688L26.0307 34.3812V37.5498C26.0307 38.3199 26.4157 39.079 27.1749 39.5631L34.9092 44.0739C35.5804 44.459 36.4385 44.459 37.1976 44.0739L44.932 39.5631C45.6032 39.178 46.0762 38.4079 46.0762 37.5498V28.4401C46.0762 27.67 45.6912 26.9109 44.932 26.4268L37.1976 21.916Z"
						fill="white"
					/>
				</g>
			</g>
			<defs>
				<clipPath id="clip0_8601_5465">
					<rect width="56" height="56" fill="white" />
				</clipPath>
			</defs>
		</svg>
	);
};
