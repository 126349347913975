import React from 'react';

interface IProps extends React.SVGAttributes<HTMLOrSVGElement> {}

export const FiDollarIcon: React.FC<IProps> = ({ width = 15, height = 26, fill = 'black', ...rest }) => {
	return (
		<svg width={width} height={height} {...rest} viewBox="0 0 15 26" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M7.51406 22.7047C6.19219 22.7047 4.99219 22.4891 3.91406 22.0578C2.84531 21.6172 1.98281 20.9844 1.32656 20.1594C0.670312 19.3344 0.299999 18.35 0.215624 17.2062L0.201562 17.0094H3.08437L3.1125 17.1781C3.21562 17.7312 3.43125 18.2375 3.75937 18.6969C4.0875 19.1562 4.56094 19.5219 5.17969 19.7937C5.79844 20.0562 6.59531 20.1875 7.57031 20.1875C8.56406 20.1875 9.36094 20.0609 9.96094 19.8078C10.5703 19.5453 11.0109 19.1937 11.2828 18.7531C11.5641 18.3125 11.7047 17.8203 11.7047 17.2766V17.2484C11.7047 16.5359 11.4703 15.95 11.0016 15.4906C10.5328 15.0312 9.63281 14.6422 8.30156 14.3234L6.12187 13.8312C4.92187 13.55 3.91406 13.1797 3.09844 12.7203C2.29219 12.2516 1.67812 11.675 1.25625 10.9906C0.84375 10.2969 0.6375 9.47656 0.6375 8.52969V8.51562C0.6375 7.39062 0.932812 6.42031 1.52344 5.60469C2.11406 4.77969 2.925 4.14219 3.95625 3.69219C4.99687 3.23281 6.17344 3.00312 7.48594 3.00312C8.77969 3.00312 9.93281 3.22344 10.9453 3.66406C11.9578 4.10469 12.7687 4.73281 13.3781 5.54844C13.9875 6.35469 14.3297 7.30625 14.4047 8.40312L14.4328 8.62812H11.5359L11.5219 8.50156C11.4469 7.87344 11.2312 7.33906 10.875 6.89844C10.5187 6.44844 10.05 6.10625 9.46875 5.87187C8.89687 5.6375 8.23594 5.52031 7.48594 5.52031C6.63281 5.52031 5.91562 5.65625 5.33437 5.92812C4.75312 6.19062 4.3125 6.5375 4.0125 6.96875C3.72187 7.39062 3.57656 7.84062 3.57656 8.31875V8.33281C3.57656 8.77344 3.675 9.17187 3.87187 9.52812C4.07812 9.875 4.41094 10.1844 4.87031 10.4562C5.33906 10.7281 5.9625 10.9531 6.74062 11.1312L8.93437 11.6375C10.8 12.0594 12.2156 12.6875 13.1813 13.5219C14.1563 14.3562 14.6438 15.5234 14.6438 17.0234V17.0375C14.6438 18.2562 14.3203 19.2875 13.6734 20.1312C13.0359 20.975 12.1781 21.6172 11.1 22.0578C10.0219 22.4891 8.82656 22.7047 7.51406 22.7047ZM6.68437 25.0391V0.668749H8.27344V25.0391H6.68437Z"
				fill={fill}
			/>
		</svg>
	);
};
