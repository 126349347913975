import { Box, Button, Center, Textarea } from '@chakra-ui/react';
import { useState } from 'react';
import { isMobile } from 'react-device-detect';
import { FiRightIcon } from '@/assets/icons';

interface InputMessageProps {
	onReply: (message: string) => void;
	onFocus: () => void;
	onBlur: () => void;
}
export const InputMessage: React.FC<InputMessageProps> = ({ onReply, onFocus, onBlur }) => {
	const [inputValue, setInputValue] = useState('');

	const handleChangeMessage = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
		const { value } = e.target;
		setInputValue(value);
	};

	const handleKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
		if (e.key === 'Enter') {
			if (isMobile) return;
			onReply(inputValue);
			setInputValue('');
			e.preventDefault();
		}
	};

	return (
		<Center
			py={1}
			pr={2}
			backgroundColor="gray.100"
			gap={2}
			flex={1}
			borderRadius={12}
			border="1px solid rgba(0, 0, 0, 0.08)"
			_focusWithin={{
				backgroundColor: 'white',
			}}
			transition="all 250ms"
		>
			<Textarea
				rows={1}
				size="sm"
				placeholder="Write a message..."
				border="none"
				_focus={{
					boxShadow: 'none',
				}}
				value={inputValue}
				onChange={handleChangeMessage}
				onKeyDown={handleKeyDown}
				resize="none"
				className="hide-scrollbar"
				onBlur={() => {
					window.scrollTo(0, 0);
					onBlur();
				}}
				onFocus={onFocus}
			></Textarea>
			<Button
				width={10}
				height={10}
				borderRadius="100%"
				colorScheme="cyan"
				transition="300ms"
				onClick={() => {
					if (!inputValue) return;
					onReply(inputValue);
					setInputValue('');
				}}
				isDisabled={!inputValue}
			>
				<Box>
					<FiRightIcon />
				</Box>
			</Button>
		</Center>
	);
};
