import { ContactAccount } from '@/app-cores/api/contact';
import { create } from 'zustand';
import { persist, createJSONStorage } from 'zustand/middleware';

const initializeData = {
	searchKey: '',
	contact: null,
	isOpen: false,
	address: '',
};
export interface IContactStore {
	searchKey: string;
	contact: ContactAccount;
	isOpen: boolean;
	address: string;
	setAddress: (address: string) => void;
	setSearchKey: (message: string) => void;
	setContact: (message: ContactAccount) => void;
	setOpen: (open: boolean) => void;
	reset: () => void;
}
export const useContactStore = create<IContactStore>()(
	persist(
		(set) => ({
			...initializeData,
			setSearchKey: (text) => set({ searchKey: text, address: '' }),
			setContact: (contact) => set({ contact: contact }),
			setOpen: (open) => set({ isOpen: open }),
			setAddress: (address) => set({ address: address }),
			reset: () =>
				set((state) => ({
					...state,
					...initializeData,
				})),
		}),
		{
			name: 'contact-storage',
			storage: createJSONStorage(() => sessionStorage),
		},
	),
);
