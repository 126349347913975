import React, { ReactNode, useEffect, useMemo, useRef, useState } from 'react';

import { Box, Center, Flex } from '@chakra-ui/react';
import { LocalLoader } from '@/app-components/common';
import NoData from '@/app-components/common/NoData';
import { ChartOptions, createChart, DeepPartial, ISeriesApi, PriceFormat, TickMarkType } from 'lightweight-charts';
import { formatCurrency } from '@/app-helpers/number';
import { CHART_CONFIG_DEFAULT, ChartMode, useUserSettingsStore } from '@/app-store/settings';
import uniqBy from 'lodash/uniqBy';
import dayjs from 'dayjs';
import { BASE_BORDER_COLOR, colors } from '@/app-theme/theme';

function calculateMovingAverageSeriesData(candleData, maLength = 20) {
	const maData = [];
	let sampleValue;
	for (let i = 0; i < candleData.length; i++) {
		if (i < maLength) {
			// Provide whitespace data points until the MA can be calculated
			maData.push({ time: candleData[i].time });
		} else {
			// Calculate the moving average, slow but simple way
			let sum = 0;
			for (let j = 0; j < maLength; j++) {
				sum += candleData[i - j].close;
			}
			const maValue = sum / maLength;
			if (!sampleValue) sampleValue = maValue;
			maData.push({ time: candleData[i].time, value: maValue });
		}
	}

	const uniqueData = uniqBy(
		maData.filter((e) => e.value !== undefined),
		'value',
	);
	// if too many same value data => chart crash
	return uniqueData.length < candleData.length / 2 ? null : maData;
}

const formatVolumeData = (chartData) => {
	return chartData.map((e) => ({
		value: e.volume ?? 0,
		time: e.time,
		color: e.close > e.open ? '#26a69a' : '#ef5350',
	}));
};

const formatter: DeepPartial<ChartOptions> = {
	localization: {
		locale: 'en-US',
		priceFormatter: (p) => formatCurrency(p),
		timeFormatter: (timestamp) => {
			return dayjs(timestamp * 1000).format('MM-DD-YYYY HH:mm');
		},
	},
};

const priceFormat: DeepPartial<PriceFormat> = {
	type: 'price',
	precision: 18,
	minMove: 1e-17,
};

const chartOptions = {
	layout: { textColor: colors.gray[400], background: { type: 'solid', color: 'white' } },
	grid: { horzLines: { visible: false }, vertLines: { visible: true } },
	rightPriceScale: {
		borderVisible: false,
	},
	timeScale: {
		tickMarkFormatter: (time: number, tickMarkType: TickMarkType, locale: string) =>
			dayjs(time * 1000).format('MM/DD HH:mm'),
		borderColor: BASE_BORDER_COLOR,
	},
} as ChartOptions;

export const TradingViewChart: React.FunctionComponent<{
	mode: ChartMode;
	isLoading: boolean;
	chartData: any[];
	rangesNode?: ReactNode;
	fitContent?: boolean;
	onFetchMore?: () => Promise<any>;
	options?: Partial<ChartOptions>;
}> = ({ chartData, isLoading, mode, rangesNode, onFetchMore, fitContent, options }) => {
	const { chartConfig: { showMa, showVolume } = CHART_CONFIG_DEFAULT } = useUserSettingsStore();

	const chartHeight = '250px';
	const ref = useRef<HTMLDivElement>(null);

	useEffect(() => {
		const node = ref.current;
		if (!node) return;
		node.innerHTML = '';

		const renderChartLine = () => {
			const isBullish = chartData?.[0]?.value < chartData?.[chartData.length - 1]?.value;
			const chart = createChart(node, { ...chartOptions, ...options });

			const series = chart.addAreaSeries({
				lineColor: isBullish ? '#2DA771' : '#ff5640',
				topColor: isBullish ? 'rgba(208,235,211,1)' : 'rgb(234, 197, 197)',
				bottomColor: isBullish ? 'rgba(208,235,211,1)' : 'rgb(234, 197, 197)',
				priceFormat,
			});

			series.setData(chartData);
			chart.applyOptions(formatter);
			return { timeScale: fitContent ? chart.timeScale().fitContent() : chart.timeScale(), series };
		};

		const renderChartCandle = () => {
			const chart = createChart(node, { ...chartOptions, ...options });

			const series = chart.addCandlestickSeries({
				upColor: '#26a69a',
				downColor: '#ef5350',
				borderVisible: false,
				wickUpColor: '#26a69a',
				wickDownColor: '#ef5350',
				priceFormat,
			});

			series.setData(chartData);
			chart.applyOptions(formatter);

			let volumeSeries: ISeriesApi<'Histogram'>;
			if (showVolume) {
				volumeSeries = chart.addHistogramSeries({
					priceScaleId: '', // set as an overlay by setting a blank priceScaleId
					lastValueVisible: false,
					priceLineVisible: false,
				});
				volumeSeries.priceScale().applyOptions({
					scaleMargins: {
						top: 0.7, // highest point of the series will be 70% away from the top
						bottom: 0,
					},
				});
				volumeSeries.setData(formatVolumeData(chartData));
			}

			let maSeries;
			if (showMa) {
				const maData = calculateMovingAverageSeriesData(chartData);
				if (maData) {
					maSeries = chart.addLineSeries({ color: '#2962FF', lineWidth: 1 });
					maSeries.setData(maData);
				}
			}

			return { timeScale: chart.timeScale(), series, volumeSeries, maSeries };
		};

		let chart;
		if (mode === ChartMode.CANDLE) chart = renderChartCandle();
		if (mode === ChartMode.LINE) chart = renderChartLine();

		if (!chart || !onFetchMore) return;

		const { volumeSeries, maSeries, series, timeScale } = chart;
		let fetching = false;
		let currentData = chartData;
		let outOfData = false;

		timeScale.subscribeVisibleLogicalRangeChange(async (logicalRange) => {
			if (fetching || logicalRange.from >= 100 || outOfData) return;
			fetching = true;

			const data = await onFetchMore();
			if (!data?.length) outOfData = true;
			// todo refetch interval will effect this data, better fetch more by number of chart instead
			currentData = uniqBy((data || []).concat(currentData), 'time');
			series.setData(currentData);
			if (showVolume && volumeSeries) {
				volumeSeries.setData(formatVolumeData(currentData));
			}
			if (showMa && maSeries) {
				const maData = calculateMovingAverageSeriesData(currentData);
				maData && maSeries.setData(maData);
			}
			fetching = false;
		});
	}, [chartData, mode, chartHeight, showMa, showVolume, onFetchMore, fitContent]);

	return (
		<Box display="flex" gap={'20px'} flexDirection={'column'} alignItems={'center'} position={'relative'}>
			<Flex flexDirection={'column'} gap={'4px'} width={'100%'}>
				{isLoading ? (
					<LocalLoader height={chartHeight} style={{ background: colors.white }} />
				) : chartData?.length ? (
					<Box
						ref={ref}
						id="chartTradingView"
						display="flex"
						gap={'20px'}
						style={{ height: chartHeight, width: '100vw' }}
						flexDirection={'column'}
						alignItems={'center'}
						position={'relative'}
					/>
				) : (
					<Center height={chartHeight}>
						<NoData msg={'No chart data for this time frame.'} />
					</Center>
				)}
			</Flex>
			{rangesNode && (
				<Box display="flex" width="100%" gap={1}>
					{rangesNode}
				</Box>
			)}
		</Box>
	);
};
