import { Message } from '@/app-cores/api/app-copilot/type';
import { MessageItem } from './MessageItem';

interface MessagesProps {
	data: Message[];
	messageLocalStatus: Record<string, 'Pending' | 'Success'>;
}
export const Messages: React.FC<MessagesProps> = ({ data, messageLocalStatus }) => {
	return data.map((ms) => {
		return <MessageItem key={ms.id} data={ms} status={messageLocalStatus[ms.id]} />;
	});
};
