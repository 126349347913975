import {
	Breadcrumb,
	ButtonHome,
	CTAButton,
	InputSearch,
	InputSearchSelect,
	Loading,
	LocalLoader,
	TooltipInfo,
} from '@/app-components/common';
import {
	Box,
	Button,
	Card,
	CardBody,
	Center,
	Divider,
	Flex,
	HStack,
	Image,
	List,
	ListItem,
	Text,
	VStack,
	useDisclosure,
	Avatar,
} from '@chakra-ui/react';
import React, { ReactNode, useMemo, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { TelegramCore } from '@/app-cores/telegram';
import { FiArrowRightIcon, FiMailIcon, FiSettingIcon } from '@/assets/icons';
import { COMMUNITY_LINK } from '@/app-constants';
import { FiUserIcon } from '@/assets/icons/fi-user-icon';
import { useNavigate } from 'react-router';
import { NAVIGATE_PATHS } from '@/app-constants/router';
import { formatNumber, formatUnits } from '@/app-helpers/number';
import i18n from '@/app-cores/i18n';
import AppLayout from '@/app-components/layout/PageLayout';
import { BASE_BORDER_COLOR, colors } from '@/app-theme/theme';

import { useQueryContactList } from '@/app-hooks/api/accouts';
import { ContactAccount } from '@/app-cores/api/contact';
import { useDebounce } from '@/app-hooks/common';
import { useRequestFundStore } from '@/app-store/request-fund';
import { CustomRadio } from '@/app-components/common/CustomRadio';
import NoData from '@/app-components/common/NoData';
import { FiCloseIcon } from '@/assets/icons/fi-close-icon';
import { useInviteFriend } from '@/app-views/account-setting/pages/InviteCode/InviteCode';
import { UserRequestPayment } from '@/app-cores/api';
import truncate from 'lodash/truncate';
import { HomeIcon } from '@/app-components/layout/AppLayout/Header/HomeIcon';
import { ActionBlock } from '@/app-components/common/ActionBlock';

export const RequestFundLayout = ({
	children,
	title,
	action,
}: {
	action?: ReactNode;
	title: string;
	children: ReactNode;
}) => {
	const { t } = useTranslation();
	return (
		<AppLayout
			footer={action}
			header={
				<Center gap={1} justifyContent="start" px={4}>
					<HomeIcon />
					<Breadcrumb flex={1} px={4} transform="translateX(-24px)">
						<Text>{t('requestPayment.title')}</Text>
					</Breadcrumb>
				</Center>
			}
		>
			{children}
		</AppLayout>
	);
};

export const UserInfoRequestFund = ({
	data,
	onClick,
	maxLengthUsername = 50,
}: {
	data: ContactAccount | UserRequestPayment;
	onClick?: () => void;
	maxLengthUsername?: number;
}) => {
	const { t } = useTranslation();
	const { avatar, firstName, username, lastName } = data;
	const isWalletCreated = (data as ContactAccount).defaultWallet || (data as UserRequestPayment).hasWallet;

	return (
		<Flex gap={4}>
			<Avatar src={avatar} boxSize={'48px'} onClick={onClick} />
			<VStack align={'flex-start'} justify={username ? 'flex-start' : 'center'} spacing={1} onClick={onClick}>
				<Flex fontWeight={'500'} whiteSpace={'nowrap'} align={'center'} gap={1}>
					{truncate(`${firstName} ${lastName}`, { length: maxLengthUsername })}{' '}
					{!isWalletCreated && (
						<TooltipInfo label={t('requestPayment.nonWalletUser')} color={colors.orange[100]} />
					)}
				</Flex>
				{username && (
					<Text fontSize={'12px'} color={colors.gray[400]}>
						@{username}
					</Text>
				)}
			</VStack>
		</Flex>
	);
};

const UserItem = ({ data, onSelectFriend }: { data: ContactAccount; onSelectFriend: (v: ContactAccount) => void }) => {
	return (
		<Flex>
			<UserInfoRequestFund data={data} onClick={() => onSelectFriend(data)} />
		</Flex>
	);
};

const SelectedFriend = ({ data, onRemove }: { data: ContactAccount; onRemove: () => void }) => {
	const { avatar, firstName, username, lastName } = data;
	const isWalletCreated = !!data.defaultWallet;
	const { t } = useTranslation();
	return (
		<Flex
			align={'center'}
			gap={1}
			border={`1px solid ${BASE_BORDER_COLOR}`}
			width={'fit-content'}
			sx={{
				borderRadius: '24px',
				padding: '4px 6px',
			}}
		>
			<Avatar src={avatar} boxSize={'24px'} />
			<Flex
				fontWeight={'500'}
				whiteSpace={'nowrap'}
				align={'center'}
				gap={1}
				fontSize={'12px'}
				color={colors.gray[400]}
			>
				{username ? `@${username}` : `${firstName} ${lastName}`}
				{!isWalletCreated && (
					<TooltipInfo label={t('requestPayment.nonWalletUser')} color={colors.orange[100]} />
				)}
			</Flex>
			<FiCloseIcon height={'16px'} onClick={onRemove} />
		</Flex>
	);
};

const RequestFund = () => {
	const { t } = useTranslation();
	const [query, setQuery] = useState('');
	const debounceQuery = useDebounce(query.trim().length >= 3 ? query.trim() : '', 500);
	const { friends = [], setFriends } = useRequestFundStore();

	const onSelectFriend = (data: ContactAccount) => {
		setFriends(
			friends.some((e) => data._id === e._id) ? friends.filter((e) => e._id !== data._id) : friends.concat(data),
		);
	};

	const { data, isFetching } = useQueryContactList({ q: debounceQuery });
	const contacts = useMemo(() => {
		if (!data) return [];
		const list = debounceQuery ? data.walletUsers.concat(data.nonWalletUsers) : data.recentUsers;
		return list?.filter((e) => e.telegramId?.toString() !== TelegramCore.getUserId()?.toString()) || [];
	}, [data, debounceQuery]);

	const navigate = useNavigate();
	const onNext = () => {
		if (!friends.length) return;
		navigate(NAVIGATE_PATHS.RequestFund.EditAmount);
	};

	const { onInvite } = useInviteFriend();

	const minHeight = '150px';

	const visibleFriends = contacts?.filter((e) => !friends.some((fr) => fr._id === e._id)) ?? [];

	return (
		<RequestFundLayout
			title={t('requestPayment.chooseRecipient')}
			action={
				<Flex p={4}>
					<CTAButton size={'lg'} colorScheme={'cyan'} height={'56px'} width={'100%'} onClick={onNext}>
						Continue
					</CTAButton>
				</Flex>
			}
		>
			<Flex align={'center'} flexDirection={'column'} gap={2}>
				<Center border={`1px solid ${colors.cyan[600]}`} boxSize={'56px'} borderRadius={'100%'}>
					<FiUserIcon />
				</Center>
				<Text textAlign={'center'} fontSize={'12px'}>
					<Trans
						i18nKey="requestPayment.desc"
						values={{ action: 'here' }}
						components={[<b style={{ textDecoration: 'underline' }} onClick={onInvite} />]}
					/>
				</Text>
			</Flex>

			<Flex direction={'column'} gap={4} py={4}>
				<InputSearchSelect
					style={{
						background: 'white',
						borderRadius: '8px',
					}}
					inputStyle={{ border: 'none' }}
					placeholder={t('requestPayment.inputPlaceholder')}
					value={query}
					onChange={(e) => setQuery(e.target.value)}
					onClear={() => setQuery('')}
					selectedNodes={
						friends?.length
							? friends.map((e) => (
									<SelectedFriend data={e} key={e._id} onRemove={() => onSelectFriend(e)} />
							  ))
							: null
					}
				/>

				<Card background={'white'} display={'flex'} gap={4} p={4}>
					<Text fontSize={'12px'} color={colors.gray[400]}>
						{debounceQuery ? 'Result' : 'Recent Tobi contacts'}
					</Text>
					<List spacing={4} minHeight={minHeight}>
						{isFetching ? (
							<LocalLoader height={minHeight} />
						) : !visibleFriends?.length ? (
							<NoData
								msg={
									debounceQuery
										? t('requestPayment.canNotFindUser')
										: t('requestPayment.noRecentUser')
								}
							/>
						) : (
							visibleFriends?.map((el) => {
								return (
									<ListItem key={el._id}>
										<UserItem data={el} onSelectFriend={onSelectFriend} />
									</ListItem>
								);
							})
						)}
					</List>
				</Card>
			</Flex>
		</RequestFundLayout>
	);
};

export default RequestFund;
