import { compareChain, isNativeToken } from '@/app-helpers/address';
import { getSigner } from '@/app-helpers/web3';
import { GenericRoute, SelectedRoute, SwapDisableType, useSwapStore } from '@/app-store/swap';
import { useMutation } from '@tanstack/react-query';
import {
	TransferParams,
	SwingSDK,
	TransferStepResult,
	TransferStepResults,
	TransferStep,
	TransferRoute,
	Chain,
	Token,
} from '@swing.xyz/sdk';

import { isEvmChain, isSolanaChain, isTronChain } from '@/app-helpers/token';
import { calculatePriceImpact, isRouteParamsEmpty } from '@/app-hooks/swap';
import {
	ArgsGetRoute,
	ExtractRouteInfo,
	SwapAbstract,
	SwapProvider,
	SwapStepInfo,
	UpdateStatusFn,
	UsdRouteInfo,
} from '@/app-hooks/swap/type';
import { useTransactionWatcherStore } from '../../app-store';
import { TransactionType } from '../../app-types';
import { AUTO_SLIPPAGE } from '@/app-views/swap/components/SlippageSetting';
import { uniqueId } from '@/app-helpers/random';
import { ethers } from 'ethers';
import axios from 'axios';
import { SOL_ADDRESS_DEBRIDGE_OR_SWING, ZERO_ADDRESS } from '@/app-constants/chains';
import { useSubmitSolTransaction } from '@/app-hooks/transactions/sol/useMutationSentSolAsset';
import {
	calcRateSwap,
	formatListRoutesBestReturn,
	getMyWalletAddressByChain,
	MAP_SWAP_STATUS,
} from '@/app-hooks/swap/helper';
import { parseErrorMessage } from '@/app-helpers/error-handling';
import { TokenInfo } from '@/app-cores/api/bff';
import { useTakeFeeSwap } from '@/app-hooks/swap/useTakeFeeSwap';

const PROJECT_ID = 'tobilabs';

const getTokenAddress = (token: TokenInfo) =>
	isNativeToken(token.address)
		? isEvmChain(token.chainId) || isTronChain(token.chainId)
			? ZERO_ADDRESS
			: SOL_ADDRESS_DEBRIDGE_OR_SWING
		: token.address;

export type RouteSwing = TransferRoute;

type RouteResponse = {
	fromToken: Token;
	toToken: Token;
	fromChain: Chain;
	toChain: Chain;
	routes: RouteSwing[];
	debug?: string[] | undefined;
};

const API_BASE_URL = 'https://swap.prod.swing.xyz';

class Swing extends SwapAbstract<RouteSwing> {
	#initTask: Promise<void>;
	#isReady: boolean = false;
	#swingSDK: SwingSDK;
	#swapData: { data: string; txId: string; to: string };

	public get swingSDK() {
		return this.#swingSDK;
	}
	public get swapData() {
		return this.#swapData;
	}

	async init() {
		try {
			try {
				const result = await axios.get(`${API_BASE_URL}/v0/transfer/config/location`);
				this.isBlocked = result.status !== 200;
			} catch (error) {}

			if (this.#isReady) return;
			this.#swingSDK = new SwingSDK({ projectId: PROJECT_ID });
			this.#initTask = this.#swingSDK.init();
			await this.#initTask;
			this.#isReady = true;
		} catch (error) {
			throw new Error('Swing is not ready');
		}
	}

	async switchChain(chainId: string | number) {
		if (isEvmChain(chainId)) await this.#swingSDK.wallet.connect(getSigner(chainId).signer, chainId);
	}

	async buildRoute(data: {
		route: SelectedRoute<RouteSwing>;
		slippage: number | string;
	}): Promise<SelectedRoute<GenericRoute>> {
		const { route } = data;
		const chainId = route.tokenIn.chainId;
		if (isSolanaChain(chainId)) {
			await this._buildRouteSolana(data);
		}
		if (isEvmChain(chainId)) {
			await this.switchChain(chainId);
		}
		return route;
	}

	private async _buildRouteSolana({ route }: { route: SelectedRoute<RouteSwing> }) {
		const { metadata, tokenIn, tokenOut, route: routeSwing } = route;

		const transferParams: TransferParams = metadata.transferParams;
		const { fromUserAddress, toUserAddress, fromToken, toToken, toChain, fromChain, maxSlippage } = transferParams;
		const { amountIn } = metadata;

		const params = {
			fromChain,
			tokenSymbol: fromToken,
			fromTokenAddress: getTokenAddress(tokenIn),
			fromUserAddress,
			toChain,
			toTokenSymbol: toToken,
			toTokenAddress: getTokenAddress(tokenOut),
			toUserAddress,
			tokenAmount: amountIn,
			projectId: PROJECT_ID,
			maxSlippage,
			route: routeSwing.route,
		};

		const { data: result } = await axios.post(`${API_BASE_URL}/v0/transfer/send`, params);
		this.#swapData = result.tx;
		route.metadata.txsId = this.#swapData.txId;
		return route;
	}
	formatSlippage(slippage: string | number): number | string {
		return +slippage === AUTO_SLIPPAGE ? undefined : +slippage / 100;
	}

	async getRoute(params: ArgsGetRoute, signal: AbortSignal) {
		await this.#initTask;
		if (!this.#isReady) throw new Error('Swing is not ready');
		if (!params) return;
		const transferParams = getRouteParams(params, this.formatSlippage(params.slippage));
		const data = await this.#swingSDK.getQuote(transferParams, { signal });
		if (!data?.routes?.length) throw new Error('Empty route');
		return formatListRoute(data as any, params, transferParams);
	}

	formatSteps(data: { routeInfo?: any }, route: SelectedRoute): SwapStepInfo[] {
		const { tokenIn, tokenOut } = route;

		if (isSolanaChain(tokenIn?.chainId)) return super.formatSteps(data, route);

		const isCrossChain = !compareChain(tokenIn?.chainId, tokenOut?.chainId);
		const hasApprove = isEvmChain(tokenIn?.chainId) && !isNativeToken(tokenIn?.address);

		const { routeInfo } = data;
		const steps = routeInfo?.steps ?? [];
		const formatSubStepSwing = (step: string, message: string): SwapStepInfo => {
			const stepInfo = routeInfo[step];
			if (!stepInfo || !steps.includes(step)) return;
			return {
				id: stepInfo.step,
				status: MAP_SWAP_STATUS[stepInfo.status],
				message,
				desc: parseErrorMessage(stepInfo.error),
			};
		};
		return routeInfo
			? [
					hasApprove ? formatSubStepSwing('allowance', 'Check allowance') : null,
					formatSubStepSwing('approve', 'Check token balance'),
					formatSubStepSwing('send', isCrossChain ? 'Send token' : 'Execute Swap'),
					formatSubStepSwing('bridge', isCrossChain ? 'Bridge token' : 'Confirm transaction'),
			  ].filter(Boolean)
			: [];
	}

	extractRoute(params: SelectedRoute<RouteSwing>, prices: UsdRouteInfo): ExtractRouteInfo {
		return getExtractRoute(params, prices);
	}
}

export const SwingSwap = new Swing();

const getRouteParams = (args: ArgsGetRoute, slippage) => {
	const { amountIn, tokenIn, tokenOut } = args;
	if (isRouteParamsEmpty(args)) return;

	const swingSDK = SwingSwap.swingSDK;

	const chainIn = swingSDK.getChain(tokenIn.chainId);
	const chainOut = swingSDK.getChain(tokenOut.chainId);

	if (!chainIn || !chainOut) return;

	const fromToken = isNativeToken(tokenIn.address)
		? chainIn.nativeToken.symbol
		: swingSDK.getTokenForChainByAddress(chainIn.slug, tokenIn.address)?.address;

	const toToken = isNativeToken(tokenOut.address)
		? chainOut.nativeToken.symbol
		: swingSDK.getTokenForChainByAddress(chainOut.slug, tokenOut.address)?.address;

	if (!fromToken || !toToken) return;

	const transferParams: TransferParams = {
		fromChain: chainIn.slug,
		fromToken,
		fromUserAddress: getMyWalletAddressByChain(tokenIn?.chainId), // Source chain wallet address
		amount: ethers.formatUnits(amountIn, tokenIn.decimals), // Amount to transfer in token decimals
		toChain: chainOut.slug,
		toToken,
		toUserAddress: getMyWalletAddressByChain(tokenOut?.chainId), // Ending chain wallet address

		maxSlippage: slippage,
	};
	return transferParams;
};

const getExtractRoute = (
	{ route, tokenIn, tokenOut, metadata: { amountIn } }: SelectedRoute<RouteSwing>,
	{ usdPriceIn, usdPriceOut }: UsdRouteInfo,
): ExtractRouteInfo => {
	const quote = route?.quote;
	return {
		duration: route?.duration * 60,
		amountOut: quote?.amount,
		amountIn,
		gasUsd: +route?.gasUSD + +quote?.bridgeFeeInNativeTokenUSD,
		gasNative: BigInt(route?.gas || 0) + BigInt(quote?.bridgeFeeInNativeToken || 0),
		tokenIn,
		tokenOut,
		dappInfo: {
			logo: '',
			domain: route?.quote?.integration,
		},
	};
};

const formatRoute = (
	route: RouteSwing,
	params: ArgsGetRoute,
	transferParams: TransferParams,
): SelectedRoute | undefined => {
	const { tokenIn, tokenOut, amountIn } = params;
	return {
		route,
		id: uniqueId(),
		tokenIn,
		tokenOut,
		routerAddress: '',
		provider: SwapProvider.SWING,
		timestamp: Date.now(),
		metadata: { amountIn, transferParams },
		disabled: SwingSwap.isBlocked ? SwapDisableType.LOCATION : undefined,
		params,
	};
};

const formatListRoute = (
	routeResponse: RouteResponse,
	params: ArgsGetRoute,
	transferParams: TransferParams,
): SelectedRoute | undefined => {
	const allRoutes = formatListRoutesBestReturn(
		routeResponse?.routes?.map((el) => formatRoute(el, params, transferParams)),
	);
	const bestRoute = allRoutes?.[0];
	if (!bestRoute) return;
	return { ...bestRoute, allRoutes };
};

export const useExecuteRouteSwing = () => {
	const { addPendingEvmTransaction } = useTransactionWatcherStore();
	const { sendTransaction: submitSolTxs } = useSubmitSolTransaction();
	const swingSDK = SwingSwap.swingSDK;
	const takeFee = useTakeFeeSwap();

	return useMutation({
		mutationKey: ['exe-swing'],
		mutationFn: async ({
			route: routeData,
			onUpdate,
			onUpdateStatus,
		}: {
			route: SelectedRoute<RouteSwing>;
			onUpdate: (data: any) => void;
			onUpdateStatus: UpdateStatusFn;
		}): Promise<{ hash: string }> => {
			const {
				route,
				metadata: { transferParams },
				tokenIn,
			} = routeData;
			console.log('exe route swing', route);
			if (isSolanaChain(tokenIn.chainId)) {
				const hash = await submitSolTxs({
					data: Buffer.from(SwingSwap.swapData.data, 'hex'),
					metadata: routeData,
					transactionType: TransactionType.Swap,
					callback: onUpdateStatus,
				});
				return { hash };
			}
			// evm
			const steps = route.route[0]?.steps ?? [];
			const hasBridge = route.route[0]?.bridge;
			if (hasBridge) steps.push('bridge');
			return new Promise(async (resolve, reject) => {
				try {
					const onTransfer = async (
						transferStep: TransferStepResult<TransferStep>,
						transferResults: TransferStepResults,
					) => {
						onUpdate({ routeInfo: { ...transferResults, steps } });
						if (transferResults?.id) {
							routeData.metadata ||= {};
							routeData.metadata.txsId = transferResults?.id;
						}
						switch (transferStep.status) {
							case 'CHAIN_SWITCH_REQUIRED':
								await SwingSwap.switchChain(transferStep.chain.chainId);
								break;

							case 'SUCCESS': {
								const lastStep = steps[steps.length - 1];
								if (
									transferResults[lastStep]?.status === 'SUCCESS' &&
									(hasBridge ? transferResults?.bridge?.status === 'SUCCESS' : true)
								)
									resolve({ hash: transferResults[lastStep]?.txHash });
								break;
							}
							case 'FAILED':
								reject(transferStep.error);
								break;
						}
					};
					swingSDK.on('TRANSFER', onTransfer);
					await swingSDK.transfer(route, transferParams);
				} catch (error) {
					reject(error);
				}
			});
		},
		onSuccess: async ({ hash }, { route }) => {
			const chainIdIn = route.tokenIn?.chainId;
			if (isEvmChain(chainIdIn)) {
				const { provider } = getSigner(chainIdIn);
				const tx = await provider.getTransaction(hash);
				takeFee({ hash, route, nonce: tx?.nonce });
				if (!tx) return;
				addPendingEvmTransaction({
					transaction: tx,
					metadata: {
						transactionType: TransactionType.Swap,
					},
					trackingData: route,
				});
				return;
			}
			takeFee({ hash, route });
		},
	});
};
