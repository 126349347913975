import React from 'react';

interface IProps extends React.SVGAttributes<HTMLOrSVGElement> {}

export const FiCopyIcon: React.FC<IProps> = ({ width = 16, height = 16, fill = '#00E9DB', ...rest }) => {
	return (
		<svg width={width} height={height} {...rest} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M7.3335 6.66683C6.96531 6.66683 6.66683 6.96531 6.66683 7.3335V13.3335C6.66683 13.7017 6.96531 14.0002 7.3335 14.0002H13.3335C13.7017 14.0002 14.0002 13.7017 14.0002 13.3335V7.3335C14.0002 6.96531 13.7017 6.66683 13.3335 6.66683H7.3335ZM5.3335 7.3335C5.3335 6.22893 6.22893 5.3335 7.3335 5.3335H13.3335C14.4381 5.3335 15.3335 6.22893 15.3335 7.3335V13.3335C15.3335 14.4381 14.4381 15.3335 13.3335 15.3335H7.3335C6.22893 15.3335 5.3335 14.4381 5.3335 13.3335V7.3335Z"
				fill={fill}
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M2.6665 1.99984C2.48969 1.99984 2.32012 2.07008 2.1951 2.1951C2.07008 2.32012 1.99984 2.48969 1.99984 2.6665V8.6665C1.99984 8.84332 2.07008 9.01288 2.1951 9.13791C2.32012 9.26293 2.48969 9.33317 2.6665 9.33317H3.33317C3.70136 9.33317 3.99984 9.63165 3.99984 9.99984C3.99984 10.368 3.70136 10.6665 3.33317 10.6665H2.6665C2.13607 10.6665 1.62736 10.4558 1.25229 10.0807C0.877218 9.70564 0.666504 9.19694 0.666504 8.6665V2.6665C0.666504 2.13607 0.877217 1.62736 1.25229 1.25229C1.62736 0.877217 2.13607 0.666504 2.6665 0.666504H8.6665C9.19694 0.666504 9.70564 0.877218 10.0807 1.25229C10.4558 1.62736 10.6665 2.13607 10.6665 2.6665V3.33317C10.6665 3.70136 10.368 3.99984 9.99984 3.99984C9.63165 3.99984 9.33317 3.70136 9.33317 3.33317V2.6665C9.33317 2.48969 9.26293 2.32012 9.13791 2.1951C9.01288 2.07008 8.84332 1.99984 8.6665 1.99984H2.6665Z"
				fill="black"
			/>
		</svg>
	);
};
