import { ChainId } from '@/app-constants/chains';
import { axiosBotAPI } from '@/app-cores/api/axios';

export const PaymentService = {
	claim: async (data: { chainId: ChainId; tokenAddress: string }): Promise<any> => {
		const response = await axiosBotAPI.post<any>('/marketing/faucet/claim', data);
		return response.data;
	},
	getStatusClaim: async (params: {
		chainId: ChainId;
		tokenAddress: string;
	}): Promise<{ claimed: boolean; lastClaimAt: string; maxClaimTimes: number; claimedTimes: number }> => {
		const response = await axiosBotAPI.get<any>('/marketing/faucet/me/claimed', { params });
		return response.data?.data;
	},
};
