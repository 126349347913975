import { FiCommingSoonIcon } from '@/assets/icons';
import { Box, BoxProps, Flex } from '@chakra-ui/react';
import { PropsWithChildren } from 'react';

export const CommingSoon: React.FC<BoxProps> = ({ children, ...props }) => {
	return (
		<Box position="relative" {...props}>
			<Flex position="absolute" right={0} top={0} zIndex={2}>
				<FiCommingSoonIcon />
			</Flex>
			<Box
				position="absolute"
				top={0}
				left={0}
				width="100%"
				height="100%"
				zIndex={1}
				backgroundColor="white"
				opacity={0.6}
			></Box>
			{children}
		</Box>
	);
};
