import { CHAIN_CONFIG, ChainId, TON_NATIVE_ADDRESS } from '@/app-constants/chains';
import { TokenInfo } from '@/app-cores/api/bff';
import { isNativeToken } from '@/app-helpers/address';
import { commonGetRoute, isRouteParamsEmpty } from '@/app-hooks/swap';
import { ArgsGetRoute, FEE_ACCOUNTS, SwapAbstract, SwapType, UsdRouteInfo } from '@/app-hooks/swap/type';
import { ExtractRouteInfo, SwapProvider } from '@/app-hooks/swap/type';
import { SelectedRoute } from '@/app-store/swap';
import { useMutation } from '@tanstack/react-query';
import { AxiosRequestConfig } from 'axios';
import { Router, ROUTER_REVISION, ROUTER_REVISION_ADDRESS } from '@ston-fi/sdk';
import TonWeb from 'tonweb';
import { ethers, formatUnits } from 'ethers';
import { MpcWallet } from '@/app-cores/mpc-wallet/wallet';
import { useSubmitTonTransaction } from '@/app-features/app-ton-connect/hooks/useMutationSentTransaction';
import { Cell, fromNano, toNano } from '@ton/core';
import { TON_CENTER_API_KEY, TON_CENTER_BASE_API_URL } from '@/app-cores/api/axios';
import { AUTO_SLIPPAGE } from '@/app-views/swap/components/SlippageSetting';
import { uniqueId } from '@/app-helpers/random';
import { formatUsd } from '@/app-helpers/number';
import { filterParams, getMinAmount } from '@/app-hooks/swap/helper';
import { TransactionType } from '@/app-types';
import { TonWallet } from '@/app-cores/mpc-wallet/ton/TonWallet';
import { useTakeFeeSwap } from '@/app-hooks/swap/useTakeFeeSwap';
const PROXY_TON_ADDRESS = 'EQCM3B12QK1e4yZSf8GtBRT0aLMNyEsBc_DhVfRRtOEffLez';
const STON_API_DOMAIN = 'https://api.ston.fi';

const getAddress = (token: TokenInfo) => (isNativeToken(token?.address) ? TON_NATIVE_ADDRESS : token.address);

class Ston extends SwapAbstract<RouteSton> {
	async getRoute(paramsSwap: ArgsGetRoute, signal: AbortSignal) {
		const payload = getRouteParamsSwapSton(paramsSwap, signal, this.formatSlippage(paramsSwap.slippage));
		if (!payload) return;
		const data = await commonGetRoute(payload, paramsSwap);
		return formatRouteSton(data, paramsSwap);
	}
	formatSlippage(slippage: string | number): number | string {
		return +slippage === AUTO_SLIPPAGE ? '0.01' : +slippage / 100;
	}
	extractRoute(params: SelectedRoute<RouteSton>, prices: UsdRouteInfo): ExtractRouteInfo {
		return getExtractRouteSton(params, prices);
	}
}
export const StonSwap = new Ston();
const getRouteParamsSwapSton = (args: ArgsGetRoute, signal: AbortSignal, slippage) => {
	const { tokenIn, tokenOut, amountIn } = args;

	if (isRouteParamsEmpty(args)) return;
	const params = {
		offer_address: getAddress(tokenIn),
		ask_address: getAddress(tokenOut),
		units: amountIn,
		slippage_tolerance: slippage,
		referral_address: FEE_ACCOUNTS.TON,
	};

	filterParams(params);
	const config: AxiosRequestConfig = { url: `${STON_API_DOMAIN}/v1/swap/simulate`, params, signal, method: 'POST' };
	return config;
};

export type RouteSton = {
	offer_address: string;
	ask_address: string;
	router_address: string;
	pool_address: string;
	offer_units: string;
	ask_units: string;
	slippage_tolerance: string;
	min_ask_units: string;
	swap_rate: string;
	price_impact: string;
	fee_address: string;
	fee_units: string;
	fee_percent: string;
	// custom
	tokenIn: TokenInfo;
	tokenOut: TokenInfo;
};

const formatRouteSton = (routeData: RouteSton, params: ArgsGetRoute): SelectedRoute<RouteSton> =>
	routeData
		? {
				...routeData,
				route: routeData,
				routerAddress: routeData.router_address,
				id: uniqueId(),
				provider: SwapProvider.STON,
				timestamp: Date.now(),
				params,
		  }
		: undefined;

const getExtractRouteSton = (
	selectedRoute: SelectedRoute<RouteSton>,
	{ usdPriceIn, usdPriceOut, usdPriceNative }: UsdRouteInfo = {},
): ExtractRouteInfo => {
	const routeSwap = selectedRoute?.route;
	const tokenIn = selectedRoute?.tokenIn;
	const tokenOut = selectedRoute?.tokenOut;

	const gasNative = getMinAmount(SwapProvider.STON) + BigInt(CHAIN_CONFIG[ChainId.TON].minForGas);
	return {
		amountOut: routeSwap?.ask_units,
		amountIn: routeSwap?.offer_units,
		minAmountOut: routeSwap?.min_ask_units,
		tokenIn,
		tokenOut,
		gasUsd: undefined,
		gasNative,
		gasDisplay: usdPriceNative ? `max ${formatUsd(0.3 * usdPriceNative)} (0.08-0.3 TON)` : '0.08-0.3 TON',
		dappInfo: {
			logo: '/icons/brands/ston.fi.jpeg',
			domain: SwapProvider.STON,
		},
	};
};

// https://docs.ston.fi/docs/developer-section/dev-guide/swap#:~:text=Recommended%20gas%20values
const calcGasForStonSwap = (routeData: SelectedRoute<RouteSton>) => {
	const { tokenIn, tokenOut } = routeData;
	const { amountIn } = getExtractRouteSton(routeData);
	if (isNativeToken(tokenIn?.address)) {
		// ton to jetton
		const swapAmount = +ethers.formatUnits(amountIn, tokenIn.decimals);
		return { fwdAmount: 0.215, txAmount: swapAmount + 0.215 };
	}
	if (isNativeToken(tokenOut?.address)) {
		// jetton to ton
		return { fwdAmount: 0.125, txAmount: 0.185 };
	}
	// jetton to jetton
	return { fwdAmount: 0.205, txAmount: 0.265 };
};

export const useExecuteRouteSton = () => {
	const { mutateAsync } = useSubmitTonTransaction();
	const takeFee = useTakeFeeSwap();
	const response = useMutation({
		mutationKey: ['build-route-ston'],
		mutationFn: async (routeData: SelectedRoute<RouteSton>) => {
			const { tokenOut, route, tokenIn } = routeData;
			const { offer_units, min_ask_units } = route;

			const provider = new TonWeb.HttpProvider(`${TON_CENTER_BASE_API_URL}/jsonRPC`, {
				apiKey: TON_CENTER_API_KEY,
			});
			const router = new Router(provider, {
				revision: ROUTER_REVISION.V1,
				address: ROUTER_REVISION_ADDRESS.V1,
			});
			let swapTxParams;
			const payload = {
				minAskAmount: new TonWeb.utils.BN(min_ask_units),
				userWalletAddress: MpcWallet.getWalletAddress().tonAddress,
				offerAmount: new TonWeb.utils.BN(offer_units),
				forwardGasAmount: new TonWeb.utils.BN(toNano(calcGasForStonSwap(routeData).fwdAmount).toString()),
				referralAddress: FEE_ACCOUNTS.TON,
			};

			if (isNativeToken(tokenIn?.address)) {
				// ton - jetton
				swapTxParams = await router.buildSwapProxyTonTxParams({
					proxyTonAddress: PROXY_TON_ADDRESS,
					askJettonAddress: tokenOut.address,
					...payload,
				});
			} else
				swapTxParams = await router.buildSwapJettonTxParams({
					// jetton - jetton or jetton - ton
					offerJettonAddress: tokenIn.address,
					askJettonAddress: isNativeToken(tokenOut?.address) ? PROXY_TON_ADDRESS : tokenOut.address,
					...payload,
				});

			const base64 = await swapTxParams.payload.toBoc();

			const cell = Cell.fromBase64(base64);
			const hash = cell.hash().toString('base64');

			const resp = await mutateAsync({
				to: swapTxParams.to.toString(),
				body: cell,
				value: fromNano(swapTxParams.gasAmount),
				metadata: routeData,
				transactionType: TransactionType.Swap,
			});
			return { ...resp, hash };
		},
		onSuccess({ hash }, route) {
			takeFee({ hash, route });
		},
	});
	return response;
};
