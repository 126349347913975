import { CHAIN_CONFIG, ChainId } from '@/app-constants/chains';
import { compareChain } from '@/app-helpers/address';
import { isSolanaChain, isTronChain } from '@/app-helpers/token';

export const toSearchQuery = (searchParams: URLSearchParams | Record<string, any>) => {
	let entries: Record<string, any>;

	if (searchParams instanceof URLSearchParams) {
		entries = Object.fromEntries([...searchParams]);
	} else {
		entries = searchParams;
	}

	return Object.keys(entries)
		.map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(entries[key])}`)
		.join('&');
};

export const toQueryString = (url: string, data: Record<string, any>) =>
	`${url}?${new URLSearchParams(data).toString()}`;

// /swap?tokenIn=0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee&chainIn=1&tokenOut=0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee&chainOut=137 => object
export const parseUrlSearchParams = (queryString: string): Record<string, string> => {
	const url = new URL('http://example.com' + queryString); // Using a base URL to create a valid URL object
	const searchParams = new URLSearchParams(url.search);
	const params = {};
	searchParams.forEach((value, key) => {
		params[key] = value;
	});
	return params;
};

export function getExplorerLink(
	chainId: string | ChainId,
	data: string,
	type: 'transaction' | 'token' | 'address' | 'block',
): string {
	const prefix = CHAIN_CONFIG[chainId].explorerUrl;

	switch (type) {
		case 'transaction': {
			return `${prefix}/${chainId === ChainId.TON ? 'transaction' : 'tx'}/${data}`;
		}
		case 'token': {
			return `${prefix}/token/${data}`;
		}
		case 'block': {
			return `${prefix}/block/${data}`;
		}
		case 'address':
		default: {
			const map = {
				[ChainId.SOL]: 'account',
				[ChainId.TRON]: '#/contract',
			};
			return `${prefix}/${map[chainId] || 'address'}/${data}`;
		}
	}
}
