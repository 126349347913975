import { useMutation, useQuery } from '@tanstack/react-query';
import { Address } from '@ton/core';
import TonWeb from 'tonweb';
import { CHAIN_CONFIG, ChainId } from '../../app-constants/chains';
import { TokenInfo } from '../../app-cores/api/bff';
import { TonWallet } from '../../app-cores/mpc-wallet/ton/TonWallet';
import { isNativeToken } from '../../app-helpers/address';
import { ethers } from 'ethers';
import { SolWallet } from '@/app-cores/mpc-wallet/solana/SolWallet';
import { PublicKey } from '@solana/web3.js';
import { getAssociatedTokenAddress } from '@solana/spl-token';
import { MpcWalletProvider } from '@/app-cores/mpc-wallet/wallet';
import { getErc20Contract } from './useERC20Contract';
import { delay } from '@/app-helpers';
const tonweb = new TonWeb();

const getTonBalance = async (walletAddress: string, tokenAddress: string) => {
	try {
		if (isNativeToken(tokenAddress)) {
			return await tonweb.getBalance(walletAddress);
		}

		const tonSpaceAddress = Address.parse(walletAddress);
		const jettonWalletContract = await TonWallet.getTonSpaceJettonWalletAddress(tonSpaceAddress, tokenAddress);
		const jettonWallet = new TonWeb.token.jetton.JettonWallet(tonweb.provider, {
			address: jettonWalletContract.toRawString(),
		});
		const data = await jettonWallet.getData();

		return data.balance.toString();
	} catch (error) {
		return '0';
	}
};

export const getSolBalance = async (walletAddress: string, tokenAddress: string) => {
	try {
		const { connection, fromPubKey } = await SolWallet.init('mainnet-beta', {
			commitment: 'confirmed',
		});
		if (isNativeToken(tokenAddress)) {
			const balance = await connection.getBalance(new PublicKey(walletAddress));
			return balance;
		}
		const tokenMint = new PublicKey(tokenAddress);
		const fromTokenAccount = await getAssociatedTokenAddress(tokenMint, fromPubKey);
		const tokenBalance = await connection.getTokenAccountBalance(fromTokenAccount);
		return tokenBalance.value.amount;
	} catch (error) {
		console.error('get sol balance error', error);
		return '0';
	}
};

export const getErc20TokenBalance = async (walletAddress: string, chainId: string, tokenAddress: string) => {
	try {
		const networkChain = CHAIN_CONFIG[chainId];
		const provider = new MpcWalletProvider(networkChain.rpcUrls.default.http);
		const blockNumber = await provider.getBlockNumber();
		if (isNativeToken(tokenAddress)) {
			const ethBalance = await provider.getBalance(walletAddress, blockNumber);
			return ethBalance;
		}
		const erc20Contract = getErc20Contract(chainId, tokenAddress);
		const tokenBalance = await erc20Contract.balanceOf(walletAddress, { blockTag: blockNumber });
		return tokenBalance;
	} catch (error) {
		console.error('get sol balance error', error);
		return 0;
	}
};
const getRawBalance = async (walletAddress: string, token: TokenInfo) => {
	switch (token.chainId) {
		case ChainId.TON:
			return getTonBalance(walletAddress, token.address);
		case ChainId.SOL:
			return getSolBalance(walletAddress, token.address);
		default:
			return getErc20TokenBalance(walletAddress, token.chainId, token.address);
	}
};

export const useWalletTokenBalance = (walletAddress: string, token: TokenInfo) => {
	return useQuery({
		queryKey: ['useWalletTokenBalance', walletAddress, token?.address, token?.chainId],
		queryFn: async () => {
			try {
				const rawBalance = await getRawBalance(walletAddress, token);
				return {
					balance: rawBalance.toString(),
					balanceFormatted: +ethers.formatUnits(rawBalance, token.decimals) || 0,
				};
			} catch (error) {
				return { balanceFormatted: 0, balance: '0' };
			}
		},
		enabled: !!walletAddress || !!token?.chainId,
	});
};
