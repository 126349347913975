import { ChainId, NATIVE_TOKEN_ADDRESS, ZERO_ADDRESS } from '@/app-constants/chains';
import { compareChain, compareToken, isNativeToken } from '@/app-helpers/address';
import { getSigner } from '@/app-helpers/web3';
import { RouteExecuting, SelectedRoute, useSwapStore } from '@/app-store/swap';
import { LiFi, LifiStep, Process, Route, RouteOptions, RoutesRequest, RoutesResponse } from '@lifi/sdk';
import { useMutation } from '@tanstack/react-query';

import { TokenInfo } from '@/app-cores/api/bff';
import { MpcWallet, MpcWalletProvider } from '@/app-cores/mpc-wallet/wallet';
import { formatUnits } from '@/app-helpers/number';
import { isEvmChain } from '@/app-helpers/token';
import { useQueryGasPrice } from '@/app-hooks/api/transactions/useQueryGasPrice';
import { calculatePriceImpact, isRouteParamsEmpty } from '@/app-hooks/swap';
import {
	ArgsGetRoute,
	ExtractRouteInfo,
	SWAP_FEE_PERCENT,
	SwapAbstract,
	SwapProvider,
	SwapStepInfo,
	UsdRouteInfo,
} from '@/app-hooks/swap/type';
import { bufferGas, populateGasInfo } from '@/app-hooks/transactions';
import { useTransactionWatcherStore } from '../../app-store';
import { TransactionType } from '../../app-types';
import { AUTO_SLIPPAGE } from '@/app-views/swap/components/SlippageSetting';
import { uniqueId } from '@/app-helpers/random';
import { calcRateSwap, formatListRoutesBestReturn, MAP_SWAP_STATUS } from '@/app-hooks/swap/helper';
import { TxStatus } from '@/app-cores/api/activities';
import { parseErrorMessage } from '@/app-helpers/error-handling';
import { useTakeFeeSwap } from '@/app-hooks/swap/useTakeFeeSwap';

const lifiInstance = new LiFi({
	integrator: 'TobiWallet',
	apiKey: '54d17905-f638-4b64-a6fe-63c4c6c37ea8.309bb90d-bc9e-40a0-a6da-d2a523d339e5',
	defaultRouteOptions: {
		fee: SWAP_FEE_PERCENT / 100,
	},
});

export type RouteLifi = Route;

class FiFinance extends SwapAbstract<RouteLifi> {
	private _messageCached = {};

	async getRoute(params: ArgsGetRoute, signal: AbortSignal) {
		if (!params) return;
		this._messageCached = {};
		const data = await lifiInstance.getRoutes(
			getRouteParamsCrossChainLifi(params, this.formatSlippage(params.slippage)),
			{ signal },
		);
		return formatListRouteCrossChain(data, params);
	}

	formatSlippage(slippage: string | number): number | string {
		return +slippage === AUTO_SLIPPAGE ? 0.005 : +slippage / 100; // 0.5%
	}

	extractRoute(params: SelectedRoute<RouteLifi>, prices: UsdRouteInfo): ExtractRouteInfo {
		return getExtractRouteLifi(params, prices);
	}

	formatSteps({ routeInfo: route }: { routeInfo?: any }): SwapStepInfo[] {
		const formatSubStepLifi = (item: Process, id: string | number): SwapStepInfo => {
			const realMsg = item.message || parseErrorMessage(item?.error?.message);
			const isFailed = MAP_SWAP_STATUS[item.status] === TxStatus.Failed;
			const message = isFailed ? this._messageCached[id] || realMsg : realMsg;
			if (!isFailed) {
				this._messageCached[id] = message;
			}
			return {
				message,
				desc:
					item?.error?.htmlMessage || item?.error?.message
						? item?.error?.htmlMessage?.replace?.(/<br\/>/g, ' ') || item?.error?.message
						: '',
				id,
				status: MAP_SWAP_STATUS[item.status],
			};
		};

		const steps = route?.steps ?? [];
		if (steps.length === 1) {
			return steps?.[0].execution?.process?.map((step, j) => formatSubStepLifi(step, j));
		}
		return steps.map(({ id, toolDetails, execution }, i) => {
			return {
				id,
				message: toolDetails?.name,
				logo: toolDetails?.logoURI,
				steps: execution?.process?.map((step, j) => formatSubStepLifi(step, `${id}_${j}`)),
			};
		});
	}
}
export const LifiSwap = new FiFinance();

const getRouteAddressCrossChainLifi = (routeLifi: RouteLifi) => routeLifi.steps?.[0]?.estimate?.approvalAddress || '';

const getRouteParamsCrossChainLifi = (args: ArgsGetRoute, slippage) => {
	const { amountIn, tokenIn, tokenOut } = args;
	if (isRouteParamsEmpty(args) || !isEvmChain(tokenIn.chainId) || !isEvmChain(tokenOut.chainId)) return;

	const paramsCrossChain: RoutesRequest = {
		fromAmount: amountIn,
		toTokenAddress: isNativeToken(tokenOut.address) ? ZERO_ADDRESS : tokenOut?.address,
		fromTokenAddress: isNativeToken(tokenIn.address) ? ZERO_ADDRESS : tokenIn?.address,
		fromChainId: +tokenIn?.chainId,
		toChainId: +tokenOut?.chainId,
		options: getCrossChainOption(slippage, tokenIn, tokenOut),
	};
	return paramsCrossChain;
};

const formatTokenLifi = (token: any, tokenCatalog: TokenInfo): TokenInfo | undefined => {
	if (!token) return;
	const rs: TokenInfo = Object.assign(tokenCatalog || token, {
		...token,
		logo: tokenCatalog?.logo || token.logoURI,
		address: isNativeToken(token.address) ? NATIVE_TOKEN_ADDRESS : token.address,
		usdPrice: tokenCatalog?.priceUsd || +token.priceUSD,
	} as TokenInfo);
	return rs;
};

const calcGasItem = (item: LifiStep, fromChain: number) => {
	const fnUsd = (total, el) =>
		total + (isNativeToken(el.token.address) && compareChain(el.token.chainId, fromChain) ? +el.amountUSD : 0);
	const amountUsd = item.estimate.gasCosts.reduce<number>(fnUsd, 0) + item.estimate.feeCosts.reduce<number>(fnUsd, 0);

	const fnGas = (total, el) =>
		total + (isNativeToken(el.token.address) && compareChain(el.token.chainId, fromChain) ? BigInt(el.amount) : 0n);
	const amount = item.estimate.gasCosts.reduce<bigint>(fnGas, 0n) + item.estimate.feeCosts.reduce<bigint>(fnGas, 0n);

	return { amountUsd, amount };
};

const calcGasRouteCrossChain = (routeLifi: RouteLifi) => {
	try {
		const fromChain = routeLifi.fromChainId;
		const amountUsd = routeLifi.steps.reduce((total, item) => total + calcGasItem(item, fromChain).amountUsd, 0);
		const amount = routeLifi.steps.reduce((total, item) => total + calcGasItem(item, fromChain).amount, 0n);
		return { amountUsd, amount };
	} catch (error) {
		return { amountUsd: 0, amount: 0n };
	}
};

const getExtractRouteLifi = (
	{ route: routeLifi, tokenIn: originTokenIn, tokenOut: originTokenOut }: SelectedRoute<RouteLifi>,
	{ usdPriceIn, usdPriceOut }: UsdRouteInfo,
): ExtractRouteInfo => {
	const tokenIn = formatTokenLifi(routeLifi?.fromToken, originTokenIn);
	const tokenOut = formatTokenLifi(routeLifi?.toToken, originTokenOut);

	const { amountUsd: totalGasUsd, amount: gasNative } = calcGasRouteCrossChain(routeLifi);

	const totalTime = routeLifi.steps.reduce((total, item) => total + item.estimate.executionDuration, 0);
	const dappInfo = routeLifi.steps?.[0]?.toolDetails;

	return {
		minAmountOut: routeLifi?.toAmountMin,
		duration: totalTime,
		amountOut: routeLifi?.toAmount,
		amountIn: routeLifi?.fromAmount,
		gasUsd: totalGasUsd,
		gasNative,
		tokenIn,
		tokenOut,
		dappInfo: {
			logo: dappInfo?.logoURI,
			domain: dappInfo?.name,
		},
	};
};

const formatRouteCrossChain = (route: RouteLifi, params: ArgsGetRoute): SelectedRoute | undefined => {
	const { tokenIn, tokenOut } = params;
	if (!route) return;
	return {
		route,
		id: uniqueId(),
		tokenIn: formatTokenLifi(route.fromToken, tokenIn),
		tokenOut: formatTokenLifi(route.toToken, tokenOut),
		routerAddress: getRouteAddressCrossChainLifi(route),
		provider: SwapProvider.LIFI,
		timestamp: Date.now(),
		checkGasByUsd: true,
		params,
	};
};

const formatListRouteCrossChain = (routeResponse: RoutesResponse, params: ArgsGetRoute): SelectedRoute | undefined => {
	const allRoutes = formatListRoutesBestReturn(routeResponse?.routes?.map((el) => formatRouteCrossChain(el, params)));
	const bestRoute = allRoutes?.[0];
	if (!bestRoute) return;
	return { ...bestRoute, allRoutes };
};

// polyfill
if (!('structuredClone' in globalThis)) {
	globalThis.structuredClone = (v) => v;
}
type onUpdateFn = (data: RouteExecuting) => void;
export const useExecuteRouteLifi = (chainId: string) => {
	const { addPendingEvmTransaction } = useTransactionWatcherStore();
	const { data: gasPriceData } = useQueryGasPrice(+chainId);

	const switchChainHook = (requiredChainId: number) => {
		return getSigner(requiredChainId)?.signer as any;
	};

	const acceptExchangeRateUpdateHook = async (params, route: RouteLifi) => {
		try {
			console.log('acceptExchangeRateUpdateHook', params);
			const { newToAmount, oldToAmount, toToken } = params;
			if (!compareToken(route.fromToken, toToken)) return true;
			const oldBigInt = BigInt(oldToAmount || 1);
			const newBigInt = BigInt(newToAmount || 0);
			const diff = newBigInt - oldBigInt;
			if (diff > 0n) return true;
			const oldValue = formatUnits(oldToAmount, toToken.decimals);
			const newValue = formatUnits(newToAmount, toToken.decimals);
			const rateChange = ((Number((newBigInt * 1_000_000n) / oldBigInt) / 1_000_000) * 100 - 100).toFixed(2);
			const ok = confirm(
				`Rate has changed: ${rateChange}%. (from ${oldValue} to ${newValue}${toToken?.symbol}). Continue to process?`,
			);
			return ok;
		} catch (error) {
			return false;
		}
	};

	const updateTransactionRequestHook = async (txRequest: any, provider: MpcWalletProvider) => {
		try {
			const [estimatedGasLimit, nonce] = await Promise.all([
				provider.estimateGas(txRequest),
				provider.getTransactionCount(MpcWallet.getWalletAddress().evmAddress),
			]);
			if (estimatedGasLimit) {
				txRequest.gasLimit = bufferGas(estimatedGasLimit, 100n);
			}
			if (!isNaN(nonce)) txRequest.nonce = nonce;
		} catch (error) {
			console.log('err in updateTransactionRequestHook: gasLimit/nonce', error);
			txRequest.gasLimit = 1_000_000n;
		}
		try {
			txRequest = await populateGasInfo({
				chainId,
				gasPriceData,
				payload: txRequest,
				provider,
				gasLevel: 'aggressive',
			});
		} catch (error) {
			console.log('err in updateTransactionRequestHook: populateGasInfo', error);
		}
		return txRequest;
	};

	const updateRouteHook = (
		e: RouteLifi,
		{
			route,
			resolve,
			onUpdate,
		}: { route: RouteLifi; resolve: ({ hash }: { hash: string }) => void; onUpdate: onUpdateFn },
	) => {
		onUpdate({ routeInfo: e });
		if (!(compareToken(e.fromToken, route.fromToken) && compareToken(e.toToken, route.toToken))) return;
		const hash = e.steps[0]?.execution?.process?.find((el) => el.txHash)?.txHash;
		const isComplete = e.steps.every((step) => step?.execution?.status === 'DONE');
		console.log('exe lifi route step', e);
		if (hash && isComplete) {
			resolve({ hash });
		}
	};

	const takeFee = useTakeFeeSwap();
	return useMutation({
		mutationKey: ['build-route-crosschain'],
		mutationFn: ({
			route: { tokenIn, route },
			onUpdate,
		}: {
			route: SelectedRoute<RouteLifi>;
			onUpdate: onUpdateFn;
		}) => {
			const chainIdIn = tokenIn?.chainId;
			const { signer, provider } = getSigner(chainIdIn);
			console.log('exe route', route);
			if (!signer || !route) throw new Error('Missing require params: signer, route');
			return new Promise<{ hash: string }>(async (resolve, reject) => {
				lifiInstance
					.executeRoute(signer as any, route, {
						switchChainHook,
						acceptExchangeRateUpdateHook: async (params) => acceptExchangeRateUpdateHook(params, route),
						updateRouteHook: (e) => updateRouteHook(e, { route, resolve, onUpdate }),
						updateTransactionRequestHook: async (txRequest: any) =>
							updateTransactionRequestHook(txRequest, provider),
					})
					.catch(reject);
			});
		},
		onSuccess: async ({ hash }, { route }) => {
			const chainIdIn = route.tokenIn?.chainId;
			const { provider } = getSigner(chainIdIn);
			const tx = await provider.getTransaction(hash);
			takeFee({ route, hash, nonce: tx?.nonce });
			if (!tx) return;
			addPendingEvmTransaction({
				transaction: tx,
				metadata: {
					transactionType: TransactionType.Swap,
				},
				trackingData: route,
			});
		},
	});
};

const getCrossChainOption = (slippage: number, tokenIn: TokenInfo, tokenOut: TokenInfo): RouteOptions => {
	return {
		slippage,
		order: 'CHEAPEST',
		allowSwitchChain:
			!compareChain(tokenIn?.chainId, ChainId.POLYGON) && !compareChain(tokenOut?.chainId, ChainId.POLYGON), // polygon is too slow
	};
};
