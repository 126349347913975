import React from 'react';

interface IProps extends React.SVGAttributes<HTMLOrSVGElement> {}

export const FiTransakIcon: React.FC<IProps> = ({ width = 48, height = 48, style }) => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none" style={style}>
			<path
				d="M36.875 6H3.125C1.40195 6 0 7.40195 0 9.125V31.625C0 33.348 1.40195 34.75 3.125 34.75H36.875C38.598 34.75 40 33.348 40 31.625V9.125C40 7.40195 38.598 6 36.875 6Z"
				fill="#5D647F"
			/>
			<path
				d="M13.75 18.5H6.25C5.55961 18.5 5 17.9404 5 17.25V12.25C5 11.5596 5.55961 11 6.25 11H13.75C14.4404 11 15 11.5596 15 12.25V17.25C15 17.9404 14.4404 18.5 13.75 18.5Z"
				fill="#FFD100"
			/>
			<path
				d="M9.375 26.625H6.25C5.55961 26.625 5 26.0654 5 25.375V24.75C5 24.0596 5.55961 23.5 6.25 23.5H9.375C10.0654 23.5 10.625 24.0596 10.625 24.75V25.375C10.625 26.0654 10.0654 26.625 9.375 26.625Z"
				fill="#B8BAC0"
			/>
			<path
				d="M17.5 26.625H14.375C13.6846 26.625 13.125 26.0654 13.125 25.375V24.75C13.125 24.0596 13.6846 23.5 14.375 23.5H17.5C18.1904 23.5 18.75 24.0596 18.75 24.75V25.375C18.75 26.0654 18.1904 26.625 17.5 26.625Z"
				fill="#B8BAC0"
			/>
			<path
				d="M25.625 26.625H22.5C21.8096 26.625 21.25 26.0654 21.25 25.375V24.75C21.25 24.0596 21.8096 23.5 22.5 23.5H25.625C26.3154 23.5 26.875 24.0596 26.875 24.75V25.375C26.875 26.0654 26.3154 26.625 25.625 26.625Z"
				fill="#B8BAC0"
			/>
			<path
				d="M33.75 26.625H30.625C29.9346 26.625 29.375 26.0654 29.375 25.375V24.75C29.375 24.0596 29.9346 23.5 30.625 23.5H33.75C34.4404 23.5 35 24.0596 35 24.75V25.375C35 26.0654 34.4404 26.625 33.75 26.625Z"
				fill="#B8BAC0"
			/>
			<path
				d="M18.125 30.375H5.625C5.27953 30.375 5 30.0952 5 29.75C5 29.4048 5.27953 29.125 5.625 29.125H18.125C18.4705 29.125 18.75 29.4048 18.75 29.75C18.75 30.0952 18.4705 30.375 18.125 30.375Z"
				fill="#8A8895"
			/>
			<path
				d="M26.25 30.375H20.625C20.2795 30.375 20 30.0952 20 29.75C20 29.4048 20.2795 29.125 20.625 29.125H26.25C26.5955 29.125 26.875 29.4048 26.875 29.75C26.875 30.0952 26.5955 30.375 26.25 30.375Z"
				fill="#8A8895"
			/>
			<path d="M15 15.375H8.75V14.125H15V12.875H8.75V11H7.5V18.5H8.75V16.625H15V15.375Z" fill="#FF9500" />
			<path
				d="M31.25 18.5C33.3211 18.5 35 16.8211 35 14.75C35 12.6789 33.3211 11 31.25 11C29.1789 11 27.5 12.6789 27.5 14.75C27.5 16.8211 29.1789 18.5 31.25 18.5Z"
				fill="#FF4F19"
			/>
		</svg>
	);
};
