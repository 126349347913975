import AppLayout from '@/app-components/layout/PageLayout';
import { MutableRefObject, ReactNode, UIEventHandler, useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router';
import { useSearchParams } from 'react-router-dom';

const TradeLayout = ({
	children,
	action,
	header,
	onPageScroll,
	containerRef,
	backAble,
}: {
	children: ReactNode;
	action: ReactNode;
	header: ReactNode;
	onPageScroll?: UIEventHandler<HTMLDivElement>;
	containerRef?: MutableRefObject<HTMLDivElement>;
	backAble?: boolean;
}) => {
	const [searchParams] = useSearchParams();
	const disableTransition = searchParams.get('transition') === '0';

	return (
		<AppLayout
			disableTransition={disableTransition}
			containerRef={containerRef}
			backAble={backAble}
			onScroll={onPageScroll}
			header={header}
			footer={action}
		>
			{children}
		</AppLayout>
	);
};

export default TradeLayout;
