import { LocalStorageService } from '@/app-services/storage';
import { IStorage } from './types';

export class StorageImpl implements IStorage {
	public getItem(key: string): string | null {
		return LocalStorageService.getString(key);
	}

	public setItem(key: string, value: string): void {
		LocalStorageService.setString(key, value);
	}

	public clear(): void {
		localStorage.clear();
	}

	public keys(): string[] {
		const keys: string[] = [];
		for (let i = 0; i < localStorage.length; i++) {
			const iKey = localStorage.key(i);
			iKey && keys.push(localStorage.key(i) ?? '');
		}
		return keys;
	}
}
