import { ServiceEmail } from '@/app-cores/api/bot/service-email';
import { UseMutationOptions, useMutation } from '@tanstack/react-query';

export const useMutationSendVerifyCode = (
	options?: UseMutationOptions<void, unknown, Parameters<typeof ServiceEmail.sendVerifyCode>>,
) => {
	const response = useMutation<void, unknown, Parameters<typeof ServiceEmail.sendVerifyCode>>({
		mutationKey: ['mutation-send-verify-code'],
		mutationFn: async (payload) => {
			return ServiceEmail.sendVerifyCode(...payload);
		},
		...options,
	});

	return response;
};

export const useMutationVerifyCode = (
	options?: UseMutationOptions<{ success: boolean }, unknown, Parameters<typeof ServiceEmail.verifyCode>>,
) => {
	const response = useMutation<{ success: boolean }, unknown, Parameters<typeof ServiceEmail.verifyCode>>({
		mutationKey: ['mutation-verify-code'],
		mutationFn: async (payload) => {
			return ServiceEmail.verifyCode(...payload);
		},
		...options,
	});

	return response;
};
