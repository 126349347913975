import { Card, Flex, Skeleton, Stack } from '@chakra-ui/react';

export const ActivitySkeleton = ({ rows = 3 }: { rows?: number }) => {
	const Rows = Array.from({ length: rows }, (_, index) => index);

	return Rows.map((item) => (
		<Card px={5} py={4} gap={3} key={item} mb={3}>
			<Flex justifyContent="space-between">
				<Skeleton height="18px" width="150px" borderRadius={6} />
				<Skeleton height="18px" width="70px" borderRadius={6} />
			</Flex>
			<Skeleton height="32px" width="100%" borderRadius={6} />
			<Skeleton height="32px" width="100%" borderRadius={6} />
			<Skeleton height="32px" width="100%" borderRadius={6} />
		</Card>
	));
};
