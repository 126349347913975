import { TonConnectTAppRequest } from '../types';
import { useModalStore } from '../store/ModalStore';
import { useTAppConnections } from '../store/ConnectionStore';
import { ConnectionType } from '@/app-types/connection-type';
import { paringTrackingManager } from '@/app-features/app-wallet-connect/utils/HelperUtil';
import { tonConnectReturnStrategyManager } from '../utils/returnStrategyManager';

export const useHandlerTAppRequest = () => {
	const { onOpen } = useModalStore();
	const { removeConnection } = useTAppConnections();
	const handlerTAppRequest = (payload: TonConnectTAppRequest) => {
		paringTrackingManager.setActiveTime(ConnectionType.TonConnect, payload.clientSessionId);
		switch (payload.request.method) {
			case 'sendTransaction': {
				return onOpen('SessionSendTransactionModal', {
					requestParams: payload,
				});
			}
			case 'signData': {
				return;
			}
			case 'disconnect': {
				paringTrackingManager.removeParingTopic(ConnectionType.TonConnect, payload.clientSessionId);
				tonConnectReturnStrategyManager.removeReturnStrategy(payload.clientSessionId);
				return removeConnection(payload.connection);
			}

			default:
				break;
		}
	};
	return handlerTAppRequest;
};
