import { Label } from '@/app-components/ApproveModal';
import { InputCustom } from '@/app-components/common';
import { Card, Flex, Box } from '@chakra-ui/react';
import { MaxUint256 } from 'ethers';

const AllowanceForm = ({
	value,
	onChange,
	suggestionValue,
	maxValue,
}: {
	suggestionValue: string;
	maxValue: string;
	value: string;
	onChange: (v: string) => void;
}) => {
	return (
		<Card sx={{ background: 'gray.100', width: '100%' }} padding={'10px 16px'} gap={'8px'}>
			<Flex justifyContent={'space-between'}>
				<Label color="gray" text="Spending cap"></Label>
				<Label
					style={{ color: 'gray' }}
					text="Use site suggestion"
					onClick={() => onChange(suggestionValue)}
				></Label>
			</Flex>

			<Box style={{ position: 'relative', width: '100%' }}>
				<InputCustom
					inputMode="decimal"
					placeholder="Enter amount"
					sx={{
						border: 'none',
						background: 'white',
						width: '100%',
					}}
					value={value}
					color={value === MaxUint256.toString() ? 'red.500' : undefined}
					onChange={(e) => onChange(e.target.value)}
				/>
				<Label
					text="Max"
					onClick={() => onChange(maxValue)}
					style={{
						fontWeight: '500',
						position: 'absolute',
						right: '10px',
						top: '10px',
						background: 'white',
						cursor: 'pointer',
						paddingLeft: '6px',
						zIndex: 1,
					}}
				/>
			</Box>

			<Label
				style={{ color: 'gray' }}
				text="This allows the third party to spend all your token balance until it reaches the cap or you revoke the spending cap. If this is not intended consider setting a lower spending cap."
			/>
		</Card>
	);
};

export default AllowanceForm;
