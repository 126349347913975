import {
	Box,
	Drawer,
	DrawerBody,
	DrawerCloseButton,
	DrawerContent,
	DrawerHeader,
	DrawerOverlay,
} from '@chakra-ui/react';
import { ConnectForm } from './ConnectForm';
import { ConnectInstruction } from './ConnectInstruction';
import { useTranslation } from 'react-i18next';

interface ConnectWithLinkPopupProps {
	isOpen: boolean;
	onClose: () => void;
}
export const ConnectWithLinkPopup: React.FC<ConnectWithLinkPopupProps> = ({ isOpen, onClose }) => {
	const { t } = useTranslation();
	return (
		<Drawer isOpen={isOpen} placement="bottom" onClose={onClose} trapFocus={false}>
			<DrawerOverlay />
			<DrawerContent>
				<DrawerHeader display="flex" justifyContent="center" alignItems="center" fontSize="md">
					{t('walletConnect.connectDApp')}
				</DrawerHeader>
				<DrawerCloseButton />
				<Box as="hr"></Box>
				<DrawerBody mb={4}>
					<ConnectInstruction title={t('walletConnect.instruction.connectWithLinkTitle')} />
					<Box as="hr" my={4}></Box>
					<ConnectForm onSubmit={onClose} />
				</DrawerBody>
			</DrawerContent>
		</Drawer>
	);
};
