import { Toast } from '@/app-components/common';
import { RECOVERY_TYPE } from '@/app-constants/backup';
import { NAVIGATE_PATHS } from '@/app-constants/router';
import { useRecoveryWalletStore } from '@/app-store';
import { FiUploadFileIcon } from '@/assets/icons';
import { Box, Center, Text } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { toast } from 'react-toastify';
import { AccountType } from '../pages/RecoveryForNewTGAccount';

interface UploadFileProps {
	onUploaded?: (file: File) => void;
	showIcon?: boolean;
	title?: string;
	description?: string;
	email?: string;
	accountType?: AccountType;
}
export const UploadFile: React.FC<UploadFileProps> = ({
	onUploaded,
	showIcon,
	title,
	description,
	email,
	accountType,
}) => {
	const navigate = useNavigate();
	const { t } = useTranslation();
	const { setRecoveryFile } = useRecoveryWalletStore();
	const handleSelectFile = (e: React.ChangeEvent<HTMLInputElement>) => {
		if (!e.target?.files?.[0]) return;
		const file = e.target?.files?.[0];
		if (file?.type !== 'text/plain') {
			return toast(<Toast title="Error" message={t('toast.invalidFile')} type="error" />);
		}
		if (onUploaded) return onUploaded(file);
		setRecoveryFile(file);
		let path = `${NAVIGATE_PATHS.Recovery.InputSecretKey}?recoveryType=${RECOVERY_TYPE.FILE}`;
		if (accountType) {
			path = `${path}&accountType=${accountType}`;
		}
		navigate(path);
	};
	return (
		<>
			<input type="file" id="upload" accept=".txt" hidden onChange={handleSelectFile} />
			<Box
				as="label"
				htmlFor="upload"
				display="block"
				borderRadius={16}
				backgroundColor="white"
				p={6}
				border="1px solid rgba(0, 0, 0, 0.08)"
				cursor="pointer"
			>
				<Center gap={4} justifyContent="start">
					{showIcon && (
						<Box>
							<FiUploadFileIcon />
						</Box>
					)}
					<Box>
						<Text mb={2} fontWeight={500}>
							{title ? title : 'Upload'}
						</Text>
						<Text fontSize="xs" color="gray.400" lineHeight={4}>
							{description ? description : `Back up your wallet's key share with Google Drive.`}
						</Text>
						{email && (
							<Text fontSize="xs" color="gray.400">
								{email}
							</Text>
						)}
					</Box>
				</Center>
			</Box>
		</>
	);
};
