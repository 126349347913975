import { Box } from '@chakra-ui/react';
import { motion, useAnimation } from 'framer-motion';
import { useEffect, useRef, useState } from 'react';
import { isDesktop } from 'react-device-detect';
import { TOUR_GUIDE_STEPS_TARGET } from '@/app-views/wallet/components/TourGuide/stepConfigs';
import { copilotManager } from './helper';
import { CopilotIcon } from './components/CopilotIcon';
import { useCopilotStore } from './store';
import { tourGuideManager } from '@/app-views/wallet/components/TourGuide/helper';
import { MpcWallet } from '@/app-cores/mpc-wallet/wallet';
import { MessageBox } from './components/MessageBox';
import { CopilotFallback } from './components/CopilotFallback';
export const AppCopilot = () => {
	const [isOpenChatBox, setIsOpenChatBox] = useState(false);
	const { isEnable, setEnable } = useCopilotStore();

	const isDragging = useRef(false);
	const animation = useAnimation();
	const clientRef = useRef({
		width: document.body.clientWidth,
		hight: document.body.clientHeight,
	});
	const initIconPosition = useRef({
		x: clientRef.current.width - 60,
		y: clientRef.current.hight - 90,
	});
	const prevPositions = useRef(initIconPosition.current);

	useEffect(() => {
		if (!tourGuideManager.isEnableHomePage() && MpcWallet.getWalletAddress().evmAddress) {
			setEnable(true);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<Box
			position="absolute"
			width="100%"
			height="100vh"
			backgroundColor="transparent"
			top={0}
			left={0}
			pointerEvents="none"
			overflow="hidden"
			className={isEnable ? 'tobi-copilot-visible' : 'disabled'}
		>
			<motion.div
				style={{
					pointerEvents: 'all',
					x: initIconPosition.current.x,
					y: initIconPosition.current.y,
					position: 'absolute',
					zIndex: 100,
				}}
				drag={!isOpenChatBox}
				dragElastic={0}
				onDragStart={() => {
					if (window.scrollY < 2 && !isDesktop) {
						window.scrollTo({
							top: 2,
						});
					}
					copilotManager.setIsDragging();
					isDragging.current = true;
				}}
				animate={animation}
				whileDrag={{
					cursor: 'grabbing',
					scale: 1.1,
				}}
				onDragEnd={(_, info) => {
					isDragging.current = false;
					copilotManager.removeIsDragging();
					let newPosition = {
						x: 0,
						y: 0,
					};
					function getY() {
						if (info.point.y < 60) return 8;
						if (info.point.y > clientRef.current.hight - 56) return clientRef.current.hight - 56;
						return info.point.y - 24;
					}
					function getX() {
						if (info.point.x < 8) return 8;
						if (info.point.x > clientRef.current.width - 56) return clientRef.current.width - 56;
						return info.point.x - 24;
					}
					const x = getX();
					const y = getY();
					if (y === 8 || y === clientRef.current.hight - 56) {
						newPosition = {
							x: x,
							y: y,
						};
						prevPositions.current = newPosition;
						return animation.start(newPosition);
					}
					if (x < clientRef.current.width / 2) {
						newPosition = {
							x: 8,
							y: y,
						};
						prevPositions.current = newPosition;
						return animation.start(newPosition);
					} else {
						newPosition = {
							x: clientRef.current.width - 56,
							y: y,
						};
						prevPositions.current = newPosition;
						return animation.start(newPosition);
					}
				}}
				dragTransition={{ bounceStiffness: 600, bounceDamping: 20 }}
				id={TOUR_GUIDE_STEPS_TARGET.HOME_PAGE.COPILOT}
			>
				<Box position="relative" display={isEnable ? 'block' : 'none'} className="tobi-app-copilot-icon">
					<CopilotIcon
						skipAnimation={!isEnable}
						onClick={async () => {
							if (isDragging.current) return;
							if (!isOpenChatBox) {
								await animation.start({
									x: initIconPosition.current.x,
									y: initIconPosition.current.y,
								});
							}
							setIsOpenChatBox((pre) => !pre);
						}}
					/>
				</Box>
				<MessageBox
					open={isOpenChatBox}
					onClose={() => setIsOpenChatBox(false)}
					onCloseComplete={() => {
						animation.start({
							x: prevPositions.current.x,
							y: prevPositions.current.y,
						});
					}}
				/>
			</motion.div>
		</Box>
	);
};
export { CopilotFallback };
