import React from 'react';

interface IProps extends React.SVGAttributes<HTMLOrSVGElement> {}

export const FiUserIcon: React.FC<IProps> = ({ width = 21, height = 20, ...rest }) => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 21 20" fill="none">
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M1.72039 12.8869C2.50179 12.1055 3.5616 11.6665 4.66667 11.6665H11.3333C12.4384 11.6665 13.4982 12.1055 14.2796 12.8869C15.061 13.6683 15.5 14.7281 15.5 15.8332V17.4998C15.5 17.9601 15.1269 18.3332 14.6667 18.3332C14.2064 18.3332 13.8333 17.9601 13.8333 17.4998V15.8332C13.8333 15.1701 13.5699 14.5342 13.1011 14.0654C12.6323 13.5966 11.9964 13.3332 11.3333 13.3332H4.66667C4.00363 13.3332 3.36774 13.5966 2.8989 14.0654C2.43006 14.5342 2.16667 15.1701 2.16667 15.8332V17.4998C2.16667 17.9601 1.79357 18.3332 1.33333 18.3332C0.873096 18.3332 0.5 17.9601 0.5 17.4998V15.8332C0.5 14.7281 0.938987 13.6683 1.72039 12.8869Z"
				fill="black"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M7.99992 3.33317C6.61921 3.33317 5.49992 4.45246 5.49992 5.83317C5.49992 7.21388 6.61921 8.33317 7.99992 8.33317C9.38063 8.33317 10.4999 7.21388 10.4999 5.83317C10.4999 4.45246 9.38063 3.33317 7.99992 3.33317ZM3.83325 5.83317C3.83325 3.53198 5.69873 1.6665 7.99992 1.6665C10.3011 1.6665 12.1666 3.53198 12.1666 5.83317C12.1666 8.13436 10.3011 9.99984 7.99992 9.99984C5.69873 9.99984 3.83325 8.13436 3.83325 5.83317Z"
				fill="black"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M16.3599 12.4001C16.475 11.9545 16.9295 11.6865 17.3751 11.8016C18.269 12.0324 19.0609 12.5535 19.6265 13.2831C20.1922 14.0128 20.4994 14.9096 20.5001 15.8328L20.5001 17.5001C20.5001 17.9603 20.127 18.3334 19.6668 18.3334C19.2065 18.3334 18.8335 17.9603 18.8335 17.5001V15.8341C18.8335 15.834 18.8335 15.8341 18.8335 15.8341C18.833 15.2802 18.6486 14.742 18.3093 14.3043C17.9699 13.8665 17.4948 13.5538 16.9585 13.4153C16.5128 13.3002 16.2449 12.8457 16.3599 12.4001Z"
				fill="black"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M13.026 2.40174C13.1402 1.95588 13.5941 1.68699 14.04 1.80114C14.9363 2.03062 15.7307 2.55188 16.2979 3.28272C16.8652 4.01356 17.1732 4.91243 17.1732 5.8376C17.1732 6.76278 16.8652 7.66165 16.2979 8.39249C15.7307 9.12333 14.9363 9.64458 14.04 9.87406C13.5941 9.98822 13.1402 9.71932 13.026 9.27347C12.9118 8.82761 13.1807 8.37364 13.6266 8.25948C14.1643 8.12179 14.641 7.80904 14.9814 7.37053C15.3217 6.93203 15.5065 6.39271 15.5065 5.8376C15.5065 5.2825 15.3217 4.74318 14.9814 4.30467C14.641 3.86617 14.1643 3.55342 13.6266 3.41573C13.1807 3.30157 12.9118 2.84759 13.026 2.40174Z"
				fill="black"
			/>
		</svg>
	);
};
