import { KeygenArgs, ServiceBot, SyncUserWalletArgs } from '@/app-cores/api';
import { UseMutationOptions, useMutation } from '@tanstack/react-query';

export const useMutationUpdateUserWallet = (options?: UseMutationOptions<void, unknown, KeygenArgs>) => {
	const response = useMutation<void, unknown, KeygenArgs>({
		mutationKey: ['mutation-update-user-wallet'],
		mutationFn: async (args: KeygenArgs) => {
			return ServiceBot.updateUserWalletAddress(args);
		},
		...options,
	});

	return response;
};

export const useSyncUserWallet = (options?: UseMutationOptions<void, unknown, KeygenArgs>) => {
	const response = useMutation<void, unknown, KeygenArgs>({
		mutationKey: ['mutation-update-user-wallet'],
		mutationFn: async (args: KeygenArgs) => {
			return ServiceBot.syncUserWalletAddress(args);
		},
		...options,
	});

	return response;
};

export const useMutationRecoveryUserWallet = (options?: UseMutationOptions<void, unknown, KeygenArgs>) => {
	const response = useMutation<void, unknown, KeygenArgs>({
		mutationKey: ['mutation-recovery-user-wallet'],
		mutationFn: async (args: KeygenArgs) => {
			return ServiceBot.recoveryUserWallet(args);
		},
		...options,
	});

	return response;
};
