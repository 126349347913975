import { Box, BoxProps, Text } from '@chakra-ui/react';
import { FiDollarIcon } from '../../../assets/icons';
import { displayCurrency } from '../../../app-helpers/display';
import { formatUsd } from '@/app-helpers/number';

interface BalanceProps extends BoxProps {
	amount: number;
}
export const Balance: React.FC<BalanceProps> = ({ amount, ...boxProps }) => {
	const parse = displayCurrency(formatUsd(amount || 0, { showDollar: false }));

	return (
		<Box display="flex" gap={1} justifyContent="center" {...boxProps}>
			<Box as="span" transform="translateY(0.875rem)">
				<FiDollarIcon />
			</Box>
			<Box>
				<Text
					fontSize={parse.value.length > 8 ? '30px' : boxProps.fontSize || '48px'}
					as="span"
					fontWeight={700}
				>
					{parse.value}
				</Text>
				<Text fontSize="xl" as="span">
					.{parse.digit}
				</Text>
			</Box>
		</Box>
	);
};
