import { Center, BoxProps } from '@chakra-ui/react';

interface BadgeProps extends BoxProps {
	type?: 'success' | 'danger';
}
export const Badge: React.FC<React.PropsWithChildren<BadgeProps>> = ({ children, type = 'success', ...rest }) => {
	return (
		<Center
			backgroundColor={type === 'danger' ? 'red' : 'cyan.600'}
			color="white"
			width={5}
			height={5}
			borderRadius="50%"
			fontSize="xs"
			{...rest}
		>
			{children}
		</Center>
	);
};
