import React from 'react';

interface IProps extends React.SVGAttributes<HTMLOrSVGElement> {}

export const FiWalletConnectIcon: React.FC<IProps> = ({ width = 56, height = 56, fill = 'white', ...rest }) => {
	return (
		<svg width={width} height={height} viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
			<g clipPath="url(#clip0_8702_56848)">
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M28 0C43.4656 0 56 12.5344 56 28C56 43.4656 43.4656 56 28 56C12.5344 56 0 43.4656 0 28C0 12.5344 12.5344 0 28 0Z"
					fill="url(#paint0_radial_8702_56848)"
				/>
				<path
					d="M18.0408 22.1021C23.6408 16.6326 32.7313 16.6326 38.3313 22.1021L39.0054 22.7654C39.2882 23.0373 39.2882 23.4831 39.0054 23.755L36.7002 26.0058C36.5588 26.1472 36.3305 26.1472 36.1891 26.0058L35.2649 25.1033C31.3503 21.2866 25.0217 21.2866 21.1072 25.1033L20.1177 26.0711C19.9763 26.2124 19.748 26.2124 19.6066 26.0711L17.3014 23.8202C17.0186 23.5484 17.0186 23.1025 17.3014 22.8307L18.0408 22.1021ZM43.1049 26.7561L45.16 28.7569C45.4427 29.0287 45.4427 29.4746 45.16 29.7464L35.9064 38.7825C35.6237 39.0544 35.167 39.0544 34.8951 38.7825L28.3274 32.367C28.2621 32.3018 28.1425 32.3018 28.0773 32.367L21.5095 38.7825C21.2268 39.0544 20.7701 39.0544 20.4983 38.7825L11.212 29.7464C10.9293 29.4746 10.9293 29.0287 11.212 28.7569L13.2672 26.7561C13.5499 26.4843 14.0066 26.4843 14.2784 26.7561L20.8462 33.1717C20.9115 33.2369 21.0311 33.2369 21.0963 33.1717L27.6641 26.7561C27.9468 26.4843 28.4035 26.4843 28.6753 26.7561L35.2431 33.1717C35.3083 33.2369 35.428 33.2369 35.4932 33.1717L42.061 26.7561C42.3654 26.4843 42.8221 26.4843 43.1049 26.7561Z"
					fill="white"
				/>
			</g>
			<defs>
				<radialGradient
					id="paint0_radial_8702_56848"
					cx="0"
					cy="0"
					r="1"
					gradientUnits="userSpaceOnUse"
					gradientTransform="translate(0.000173569 28.0009) scale(56 56)"
				>
					<stop stopColor="#5D9DF6" />
					<stop offset="1" stopColor="#006FFF" />
				</radialGradient>
				<clipPath id="clip0_8702_56848">
					<rect width="56" height="56" fill="white" />
				</clipPath>
			</defs>
		</svg>
	);
};
