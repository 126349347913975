import { CopyToClipboard } from '@/app-components/common';
import { getShortAddress } from '@/app-helpers/address';
import { getExplorerLink } from '@/app-helpers/url';
import { FiBlackBoxIcon } from '@/assets/icons/fi-black-box';
import { FiExternalLinkIcon } from '@/assets/icons/fi-external-link-icon';
import { Flex, Text } from '@chakra-ui/react';

export const ContractInfo = ({
	msg,
	description,
	contract,
	chainId,
}: {
	msg: string;
	description: string;
	contract: string;
	chainId: string;
}) => {
	return (
		<Flex flexDirection={'column'} alignItems={'center'} gap={'16px'}>
			<Text textAlign={'center'} fontSize={'24px'} lineHeight={'30px'}>
				{msg}
			</Text>
			<Text textAlign={'center'} fontSize={'14px'}>
				{description}
			</Text>

			<Flex borderRadius={'80px'} background={'gray.100'} padding={'4px 12px'} alignItems={'center'} gap={'8px'}>
				<FiBlackBoxIcon />
				<CopyToClipboard text={contract} copyText="Copy address">
					<Text fontSize="sm" fontWeight={'500'}>
						{getShortAddress(contract)}
					</Text>
				</CopyToClipboard>
				<a href={getExplorerLink(chainId, contract, 'address')} target="_blank">
					<FiExternalLinkIcon />
				</a>
			</Flex>
		</Flex>
	);
};
