import { Box, Center, useDisclosure } from '@chakra-ui/react';
import Activities from '@/app-views/wallet/components/Activities';
import { FiClockIcon } from '@/assets/icons';
import { ActionBlock } from '@/app-components/common/ActionBlock';
import { useCountSol2Tcat } from '@/app-hooks/swap/sol_2_tcat';
import { useQueryGetRequestFund } from '@/app-hooks/api/payment/useClaimFund';
import { FeatureConfig, useQueryFeatureConfig } from '@/app-hooks/api/configuration';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Actions } from '@/app-features/app-bot-connector/hooks';
import { useEffect } from 'react';
import { useParseBotStartRedirectParams } from '@/app-cores/telegram';
import { NAVIGATE_PATHS } from '@/app-constants/router';
let showedActity = false;
export const Activity = () => {
	const { isOpen, onClose, onOpen } = useDisclosure();
	useCountSol2Tcat();
	const isSupportRequestFund = useQueryFeatureConfig(FeatureConfig.REQUEST_FUND);
	const { data } = useQueryGetRequestFund(isSupportRequestFund);
	const totalBadge = data?.totalToPaid ?? 0;

	const [searchParams] = useSearchParams();
	const actionParam = searchParams.get('action');
	const navigate = useNavigate();
	const { action } = useParseBotStartRedirectParams();

	useEffect(() => {
		if ((action === Actions.ACTIVITIES && !showedActity) || actionParam === Actions.ACTIVITIES) {
			onOpen();
			showedActity = true;
			navigate(NAVIGATE_PATHS.Home, { replace: true });
		}
	}, [action, actionParam, onOpen, navigate]);

	return (
		<>
			<ActionBlock onClick={() => onOpen()} position="relative">
				<Center width={8} height={8}>
					<FiClockIcon />
				</Center>
				{!!totalBadge && (
					<Box
						position="absolute"
						top={1.5}
						right={1.5}
						width={2}
						height={2}
						borderRadius="100%"
						backgroundColor="red"
					></Box>
				)}
			</ActionBlock>
			<Activities isOpen={isOpen} onClose={onClose} />
		</>
	);
};
