import { Avatar, Box, Center, Divider, Flex, Skeleton, Text } from '@chakra-ui/react';
import BigNumber from 'bignumber.js';
import { ChainId } from '@/app-constants/chains';
import { WalletActivity } from '@/app-cores/api/activities';
import { ITokenSearch } from '@/app-cores/api/bff';
import { formatCurrency, formatUsd } from '@/app-helpers/number';
import { getChainConfigByChainName, getNativeTobiId, getTokenInfo } from '@/app-helpers/token';
import { useSearchListToken } from '@/app-hooks/api/portfolio';
import { TransactionType } from '@/app-types';
import { FiArrowRightIcon } from '@/assets/icons';

function getAmount(amount: number, decimals: number = 18): BigNumber {
	return new BigNumber(amount).shiftedBy(-decimals);
}

function getAmountUsd(amount: number, decimals: number, tokenPrice: number) {
	return formatUsd(
		getAmount(amount, decimals)
			.multipliedBy(tokenPrice || 0)
			.toString(),
	);
}
interface ActivityItemTokenFlowProps {
	data: WalletActivity;
	visible: boolean;
}

interface ChainAndGasFeeProps {
	gasFeeToken: number;
	chainId: string;
	nativeToken: ITokenSearch;
}
export const ChainAndGasFee: React.FC<ChainAndGasFeeProps> = ({ gasFeeToken, chainId, nativeToken }) => {
	const localChainConfig = getChainConfigByChainName(chainId);
	const tokenInfo = getTokenInfo(nativeToken);

	return (
		<Box>
			<Divider height="1px" bgColor="rgba(0, 0, 0, 0.08)" my={3} />
			<Center justifyContent="space-between">
				<Center gap={1}>
					<Avatar width={4} height={4} src={localChainConfig?.logo}></Avatar>
					<Text fontSize="small">{localChainConfig?.name}</Text>
				</Center>
				{![ChainId.TON].includes(localChainConfig?.id) && (
					<Center gap={1} fontSize="xs">
						<Text>
							Gas: {formatCurrency(gasFeeToken)} {localChainConfig?.nativeToken?.symbol}
						</Text>
						<Text>({formatUsd(gasFeeToken * (tokenInfo?.priceUsd || 0))})</Text>
					</Center>
				)}
			</Center>
		</Box>
	);
};

export const FailReason = ({ reason }: { reason: string }) => {
	return (
		<Box fontSize="xs" mt={2} px={4} py={2} bgColor="gray.100" borderRadius={8}>
			{reason}
		</Box>
	);
};

const SwapTokenFlow = ({ data }: { data: WalletActivity['swapDetail'] }) => {
	const { data: tokenDetail, isPending } = useSearchListToken({
		query: [
			{
				chainId: data.tokenIn?.chainId,
				tobiId: data.tokenIn?.idTobi,
			},
			{
				chainId: data.tokenOut?.chainId,
				tobiId: data.tokenOut?.idTobi,
			},
			{
				chainId: data.tokenIn?.chainId,
				tobiId: getNativeTobiId(data.tokenIn?.chainId),
			},
		],
		staleTime: 10,
	});

	if (isPending) return <Skeleton width="100%" height={38} />;
	const tokenIn = getTokenInfo(tokenDetail?.[0]);
	const tokenOut = getTokenInfo(tokenDetail?.[1]);
	const localTokenInChainConfig = getChainConfigByChainName(data.tokenIn?.chainId);
	const localTokenOutChainConfig = getChainConfigByChainName(data.tokenOut?.chainId);

	return (
		<>
			<Center gap={4} fontSize="xs" bgColor="gray.100" borderRadius={8} py={3}>
				<Box>
					<Center gap={1} fontWeight={500}>
						<Text>{formatCurrency(getAmount(data.amountIn, tokenIn?.decimals).toString())}</Text>
						<Text>{tokenIn?.symbol}</Text>
						<Avatar width={4} height={4} src={tokenIn?.imageUrl}></Avatar>
					</Center>
					<Text fontSize="smaller" textAlign="end" color="gray.400">
						({getAmountUsd(data.amountIn, tokenIn?.decimals, tokenIn?.priceUsd)})
					</Text>
					<Text fontSize="smaller" textAlign="end">
						on {localTokenInChainConfig?.name}
					</Text>
				</Box>
				<FiArrowRightIcon width={16} height={16} />
				<Box>
					<Center gap={1} fontWeight={500}>
						<Avatar width={4} height={4} src={tokenOut?.imageUrl}></Avatar>
						<Text>{formatCurrency(getAmount(data.amountOut, tokenOut?.decimals).toString())}</Text>
						<Text>{tokenOut?.symbol}</Text>
					</Center>
					<Text fontSize="smaller" textAlign="start" color="gray.400">
						({getAmountUsd(data.amountOut, tokenOut?.decimals, tokenOut?.priceUsd)})
					</Text>
					<Text fontSize="smaller" textAlign="start">
						on {localTokenOutChainConfig?.name}
					</Text>
				</Box>
			</Center>
			{/* <FailReason reason="Fail reason: Submitting this attempt does not guarantee that origin transaction will be cancelled." /> */}
			<ChainAndGasFee
				chainId={data.tokenIn?.chainId}
				gasFeeToken={data.gasAmount}
				nativeToken={tokenDetail?.[2]}
			/>
		</>
	);
};
const SendTokenFlow = ({
	data,
	type,
}: {
	data: WalletActivity['sendDetail'];
	type: WalletActivity['transactionType'];
}) => {
	const { data: tokenDetail, isPending } = useSearchListToken({
		query: [
			{
				chainId: data.chainId,
				tobiId: data.idTobi,
			},
			{
				chainId: data.chainId,
				tobiId: getNativeTobiId(data.chainId),
			},
		],
		staleTime: 10,
	});

	if (isPending)
		return (
			<Flex gap={3} flexDirection="column">
				<Skeleton width="100%" height={38} borderRadius={6} />
				<Skeleton width="100%" height={38} borderRadius={6} />
			</Flex>
		);
	const tokenInfo = getTokenInfo(tokenDetail?.[0]);
	return (
		<>
			<Center gap={1} fontSize="xs" bgColor="gray.100" borderRadius={8} py={3}>
				<Avatar width={4} height={4} src={tokenInfo?.imageUrl}></Avatar>
				<Text fontWeight={500}>
					{type === 'send' ? ' - ' : ' + '}
					{formatCurrency(getAmount(data.amount, tokenInfo?.decimals).toString())}
				</Text>
				<Text fontWeight={500}>{tokenInfo?.symbol}</Text>
				<Text color="gray.400" fontSize="smaller">
					(
					{formatUsd(
						getAmount(data.amount, tokenInfo?.decimals).multipliedBy(tokenInfo?.priceUsd).toString(),
					)}
					)
				</Text>
			</Center>
			{/* <FailReason reason="Fail reason: Submitting this attempt does not guarantee that origin transaction will be cancelled." /> */}
			<ChainAndGasFee chainId={data.chainId} gasFeeToken={data.gasFee} nativeToken={tokenDetail?.[1]} />
		</>
	);
};

export const ActivityItemTokenFlow: React.FC<ActivityItemTokenFlowProps> = ({ data, visible }) => {
	if (!visible) return null;
	return (
		<Box mt={2}>
			{data.transactionType === TransactionType.Send && <SendTokenFlow data={data.sendDetail} type="send" />}
			{data.transactionType === TransactionType.Receive && (
				<SendTokenFlow data={data.receiveDetail} type="receive" />
			)}
			{data.transactionType === TransactionType.Swap && <SwapTokenFlow data={data.swapDetail} />}
		</Box>
	);
};
