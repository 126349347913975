import ReactQrReader from 'react-qr-reader-es6';
import { toast } from 'react-toastify';
import { useRef, useState } from 'react';
import { Box, Button, Center, Text } from '@chakra-ui/react';
import { Toast } from '@/app-components/common';
import { FiCameraIcon, FiImageIcon } from '@/assets/icons';
import { useTranslation } from 'react-i18next';
interface IProps {
	onConnect?: (uri: string) => Promise<void>;
	onScan?: (uri: string) => any;
}

/**
 * Component
 */
export function QrReader({ onScan }: IProps) {
	const [isBackCamera, setIsBackCamera] = useState(true);
	const qrReadImgRef = useRef<ReactQrReader>(null);
	const { t } = useTranslation();
	return (
		<>
			<div className="wallet__connect--qr-scan">
				<Box className="wallet__connect--qr-scan__box">
					<ReactQrReader
						showViewFinder={false}
						onError={() => toast(<Toast type="error" message="Failed to load camera" />)}
						onScan={onScan}
						facingMode={isBackCamera ? 'environment' : 'user'}
					/>
				</Box>
			</div>
			<Box display="none">
				<ReactQrReader
					ref={qrReadImgRef}
					showViewFinder={true}
					onError={() => console.error('Failed to load camera 2')}
					onScan={(uri) => {
						if (!uri) {
							toast(
								<Toast
									type="error"
									message="Unable to scan QR code from this image. Please try again with another image."
								/>,
							);
							return;
						}
						onScan(uri);
					}}
					facingMode="environment"
					legacyMode
				/>
			</Box>
			<Text textAlign="center" mt={8} fontSize="md" color="gray">
				{t('walletConnect.QRCodeInstruction')}
			</Text>
			<Center gap={6} mt={8}>
				<Button
					backgroundColor="gray"
					_hover={{
						backgroundColor: 'gray',
					}}
					width={10}
					height={10}
					borderRadius="50%"
					onClick={() => setIsBackCamera(!isBackCamera)}
					p={0}
				>
					<FiCameraIcon />
				</Button>
				{/* <Button
					_hover={{
						backgroundColor: 'gray',
					}}
					backgroundColor="gray"
					width={10}
					height={10}
					borderRadius="50%"
					p={0}
				>
					<ZapIcon />
				</Button> */}
			</Center>
			<Center mt={8}>
				<Button
					onClick={() => qrReadImgRef.current?.openImageDialog()}
					className="border-cyan"
					backgroundColor="transparent"
					py={4}
					px={6}
					borderRadius={96}
					gap={2}
				>
					<FiImageIcon />
					<Text color="white">{t('walletConnect.chooseFromGallery')}</Text>
				</Button>
			</Center>
		</>
	);
}
