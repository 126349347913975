import { BoxKeyPair, box, box_keyPair, box_keyPair_fromSecretKey, box_open, randomBytes } from 'tweetnacl-ts';

const BOT_CONNECTOR_KEY_PAIR = 'BOT_CONNECTOR_KEY_PAIR';

export class NaclHandler {
	private readonly nonceLength = 24;
	private keyPairStore: Record<string, BoxKeyPair> = {};

	constructor() {
		this.persistKeyPair();
	}

	public encrypt(message: string, receiverPublicKey: string, topicId: string): string {
		const encodedMessage = new TextEncoder().encode(message);
		const keyPair = this.getKeyPair(receiverPublicKey, topicId);
		const nonce = this.createNonce();
		const encrypted = box(encodedMessage, nonce, this.hexToByteArray(receiverPublicKey), keyPair.secretKey);

		return this.toHexString(this.concatUint8Arrays(nonce, encrypted));
	}

	public decrypt(message: string, senderPublicKey: string, topicId: string): string | Record<any, any> {
		const [nonce, internalMessage] = this.splitToUint8Arrays(
			typeof message === 'string' ? this.hexToByteArray(message) : message,
			this.nonceLength,
		);

		const keyPair = this.getKeyPair(senderPublicKey, topicId);
		const decrypted = box_open(internalMessage, nonce, this.hexToByteArray(senderPublicKey), keyPair.secretKey);

		if (!decrypted) {
			throw new Error(
				`Decryption error: \n message: ${message.toString()} \n sender pubkey: ${senderPublicKey.toString()}`,
			);
		}

		return this.safeParse(new TextDecoder().decode(decrypted));
	}

	public getKeyPair(partnerPublicKey: string, topicId: string) {
		const storeKey = [partnerPublicKey, topicId].join('-');
		if (!this.keyPairStore[storeKey]) {
			this.keyPairStore[storeKey] = box_keyPair();
			this.saveStorage();
		}

		return this.keyPairStore[storeKey];
	}

	private getStorage(): Record<string, string> {
		try {
			return JSON.parse(localStorage.getItem(BOT_CONNECTOR_KEY_PAIR)) || {};
		} catch {
			return {};
		}
	}

	private saveStorage() {
		const data = Object.entries(this.keyPairStore).reduce(
			(pre, [key, pair]) => ({ ...pre, [key]: this.toHexString(pair.secretKey) }),
			{},
		);
		localStorage.setItem(BOT_CONNECTOR_KEY_PAIR, JSON.stringify(data));
	}

	private persistKeyPair() {
		try {
			const data = this.getStorage();

			Object.entries(data).forEach(([partnerPublicKey, secretKey]) => {
				try {
					const keyPair = box_keyPair_fromSecretKey(this.hexToByteArray(secretKey));
					this.keyPairStore[partnerPublicKey] = keyPair;
				} catch (error) {}
			});
		} catch {}
	}

	public toHexString(byteArray: Uint8Array): string {
		let hexString = '';
		byteArray.forEach((byte) => {
			hexString += ('0' + (byte & 0xff).toString(16)).slice(-2);
		});
		return hexString;
	}

	private hexToByteArray(hexString: string): Uint8Array {
		if (hexString.length % 2 !== 0) {
			throw new Error(`Cannot convert ${hexString} to bytesArray`);
		}
		const result = new Uint8Array(hexString.length / 2);
		for (let i = 0; i < hexString.length; i += 2) {
			result[i / 2] = parseInt(hexString.slice(i, i + 2), 16);
		}
		return result;
	}

	private createNonce(): Uint8Array {
		return randomBytes(this.nonceLength);
	}

	private concatUint8Arrays(buffer1: Uint8Array, buffer2: Uint8Array): Uint8Array {
		const mergedArray = new Uint8Array(buffer1.length + buffer2.length);
		mergedArray.set(buffer1);
		mergedArray.set(buffer2, buffer1.length);
		return mergedArray;
	}

	private splitToUint8Arrays(array: Uint8Array, index: number): [Uint8Array, Uint8Array] {
		if (index >= array.length) {
			throw new Error('Index is out of buffer');
		}

		const subArray1 = array.slice(0, index);
		const subArray2 = array.slice(index);
		return [subArray1, subArray2];
	}

	private safeParse(data: string) {
		try {
			return JSON.parse(data);
		} catch (error) {
			return data;
		}
	}
}
