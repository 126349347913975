import { useQuery } from '@tanstack/react-query';
import { get } from 'lodash-es';
import { Address, Cell, SendMode, fromNano, internal } from '@ton/core';
import { TonCenterServiceAPI } from '@/app-cores/api/toncenter/service-toncenter';
import { Sourcefees } from '@/app-cores/api/toncenter/type';
import { TonWallet } from '@/app-cores/mpc-wallet/ton/TonWallet';
import { MpcWallet } from '@/app-cores/mpc-wallet/wallet';
import { getFinalTonGasFee } from '@/app-helpers/ton';
import { toStateInit } from './useMutationSentTransaction';
export const useEstimateTonGasFee = (params) => {
	const response = useQuery({
		queryKey: ['estimate-ton-transaction-fee'],
		queryFn: async () => {
			const { contract } = await TonWallet.create('mainnet');
			const seqno = await contract.getSeqno();
			const walletAddress = Address.parse(MpcWallet.getTonWalletAddress());
			const data = JSON.parse(params);

			const transfer = await contract.createTransfer({
				seqno,
				sendMode: SendMode.PAY_GAS_SEPARATELY + SendMode.IGNORE_ERRORS,
				messages: data.messages.map((item) => {
					return internal({
						to: item.address,
						bounce: true,
						value: fromNano(item.amount),
						init: toStateInit(item.stateInit),
						body: item.payload ? Cell.fromBase64(item.payload) : undefined,
					});
				}),
			});
			const transferSigned = await contract.sign(transfer);
			const body = transferSigned.toBoc().toString('base64');
			const response = await TonCenterServiceAPI.estimateFee({
				address: walletAddress.toString({ bounceable: false }),
				body: body,
				ignore_chksig: true,
			});
			const source_fees = get(response, 'result.source_fees', {}) as Sourcefees;
			const fee = source_fees.in_fwd_fee + source_fees.storage_fee + source_fees.gas_fee + source_fees.fwd_fee;
			return fee ? getFinalTonGasFee(BigInt(fee)) : 0n;
		},
		staleTime: 1000 * 60,
		enabled: !!params,
	});
	return response;
};
