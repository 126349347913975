import nacl from 'tweetnacl';
import { encodeBase64, decodeBase64 } from 'tweetnacl-util';
import { v4 as uuidv4 } from 'uuid';
import bs58 from 'bs58';
import { phantomConnectStorages } from './storage';
import { PhantomConnectService } from '@/app-cores/api/bot/service-phantom-connect';

export function initKeyPairs(): nacl.BoxKeyPair {
	const storedPublicKeyBase64 = phantomConnectStorages.getKeyPairPublicKey();
	const storedSecretKeyBase64 = phantomConnectStorages.getKeyPairSecretKey();
	if (storedPublicKeyBase64 && storedSecretKeyBase64) {
		const storedPublicKey = decodeBase64(storedPublicKeyBase64);
		const storedSecretKey = decodeBase64(storedSecretKeyBase64);
		return {
			publicKey: storedPublicKey,
			secretKey: storedSecretKey,
		};
	}
	const keyPairs = nacl.box.keyPair();
	const publicKeyBase64 = encodeBase64(keyPairs.publicKey);
	const secretKeyBase64 = encodeBase64(keyPairs.secretKey);
	phantomConnectStorages.setKeyPairPublicKey(publicKeyBase64);
	phantomConnectStorages.setKeyPairSecretKey(secretKeyBase64);
	return keyPairs;
}
export function initSharedSecret() {
	const sharedSecret = phantomConnectStorages.getSharedSecret();
	if (!sharedSecret) return null;
	return decodeBase64(sharedSecret);
}

export const buildState = (method: string) => `${method}:${uuidv4()}`;

export const decryptPayload = (data: string, nonce: string, sharedSecret?: Uint8Array) => {
	if (!sharedSecret) throw new Error('missing shared secret');

	const decryptedData = nacl.box.open.after(bs58.decode(data), bs58.decode(nonce), sharedSecret);
	if (!decryptedData) {
		throw new Error('Unable to decrypt data');
	}
	return JSON.parse(Buffer.from(decryptedData).toString('utf8'));
};

export const encryptPayload = (payload: any, sharedSecret?: Uint8Array) => {
	if (!sharedSecret) throw new Error('missing shared secret');
	const nonce = nacl.randomBytes(24);
	const encryptedPayload = nacl.box.after(Buffer.from(JSON.stringify(payload)), nonce, sharedSecret);
	return [nonce, encryptedPayload];
};
export const buildPhantomDeepLink = (path: string, params: URLSearchParams) =>
	`https://phantom.app/ul/v1/${path}?${params.toString()}`;

export const buildRedirectLink = (state: string) => `${import.meta.env.VITE_BOT_API}/dapp/phantom/callback/${state}`;

export const pollingToGetDataFromPhantomWallet = async (state: string, abortController: AbortController) => {
	return new Promise<string>((resolve, reject) => {
		const _poll = async () => {
			try {
				const data = await PhantomConnectService.getCallbackData(state, abortController);
				if (!data) {
					await new Promise((resolve) => setTimeout(resolve, 1000));
					return requestAnimationFrame(_poll);
				}
				resolve(data);
			} catch (e) {
				reject(e);
			}
		};
		_poll();
	});
};
