import { axiosBotAPI } from '../axios';
import { Message } from './type';
class CopilotService {
	async getConversations(payload: { limit: number; skip: number }): Promise<Message[]> {
		const response = await axiosBotAPI.get('/conversations/messages', {
			params: {
				platform: 'web',
				...payload,
			},
		});
		return response.data.data;
	}
	async sendMessage(message: string): Promise<Message[]> {
		const response = await axiosBotAPI.post('/conversations/messages', {
			text: message,
		});
		const [_, ...botMessage] = response.data.data;
		return [...botMessage].filter((m) => !!m);
	}
}

export const CopilotServiceAPI = new CopilotService();
