import { FiErrorIcon } from '@/assets/icons';
import { Button, Center, Drawer, DrawerBody, DrawerContent, DrawerOverlay, Tag, Text } from '@chakra-ui/react';
import { useModalStore } from '../store/ModalStore';
import { getValidReturnStrategy, handleReturnStrategy } from '../utils/common';
import { useEffect, useRef, useState } from 'react';
import { useMutationOutdateTransaction } from '../hooks/useMutationSentTransaction';
import { tonConnectReturnStrategyManager } from '../utils/returnStrategyManager';

interface BadRequestProps {
	errorMessage?: string;
}
const CLOSE_RETURN_BUTTON_TIME = 3000;

export const BadRequest: React.FC<BadRequestProps> = ({ errorMessage }) => {
	const { data } = useModalStore();
	const [isOpen, setIsOpen] = useState(false);
	const timerRef = useRef(null);
	const { mutateAsync: rejectOutdateTransaction, isPending: isPendingRejectOutdate } =
		useMutationOutdateTransaction();

	useEffect(() => {
		setIsOpen(true);
		async function reject() {
			await rejectOutdateTransaction(data.requestParams);
		}
		reject();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [data.requestParams]);

	useEffect(() => {
		timerRef.current = setTimeout(() => {
			const rt = getValidReturnStrategy(
				tonConnectReturnStrategyManager.getReturnStrategy(data.requestParams.clientSessionId),
			);
			handleReturnStrategy(rt);
			setIsOpen(false);
		}, CLOSE_RETURN_BUTTON_TIME);
		return () => clearTimeout(timerRef.current);
	}, [data.requestParams]);

	return (
		<Drawer onClose={() => {}} isOpen={isOpen} closeOnOverlayClick={false} placement="bottom">
			<DrawerOverlay />
			<DrawerContent borderRadius="8px 8px 0 0" pt={4} pb={2}>
				<DrawerBody gap={2} display="flex" justifyContent="center" alignItems="center" flexDirection="column">
					<Center width={14} height={14} borderRadius="100%" backgroundColor="#00E9DB">
						<FiErrorIcon />
					</Center>
					<Text fontWeight={600} fontSize="20px">
						Something Wrong!
					</Text>
					<Tag p={2} color="gray.400">
						{errorMessage}
					</Tag>
					<Text fontWeight={500} textAlign="center">
						Now you need to go back to the application.
					</Text>
					<Button
						onClick={() => {
							getValidReturnStrategy(
								tonConnectReturnStrategyManager.getReturnStrategy(data.requestParams.clientSessionId),
							);
							setIsOpen(false);
						}}
						fontWeight="medium"
						size="lg"
						width="100%"
						colorScheme="cyan"
						mt={6}
					>
						Go back
					</Button>
				</DrawerBody>
			</DrawerContent>
		</Drawer>
	);
};
