import { Box, Center, Flex, Image, Text } from '@chakra-ui/react';
import BigNumber from 'bignumber.js';
import { TransactionItemInfo } from './TransactionItemInfo';
import { formatCurrency, formatUsd } from '@/app-helpers/number';
import { useTokenPriceSingleToken } from '@/app-hooks/api/portfolio/useTokenPrices';
import { FiDownLeftIcon, FiUpRightIcon } from '@/assets/icons';

interface TokenAmountProps {
	image: string;
	symbol: string;
	amount: number | string;
	decimals: number;
	type: 'IN' | 'OUT';
	layout?: 'full' | 'compact';
	dollarValuePerToken?: number;
	address?: string;
	chainId?: string;
	showActionIcon?: boolean;
	receiverName?: string;
}
const Icons = {
	OUT: <FiUpRightIcon />,
	IN: <FiDownLeftIcon />,
};
export const TokenAmount: React.FunctionComponent<TokenAmountProps> = ({
	amount,
	decimals,
	image,
	symbol,
	type,
	address,
	chainId,
	dollarValuePerToken,
	showActionIcon,
	receiverName,
}) => {
	// const { data: tokenPrice } = useTokenPriceSingleToken({
	// 	chainId: chainId,
	// 	address: address,
	// });
	return (
		<TransactionItemInfo
			title={
				<Center gap={2}>
					{showActionIcon && (
						<Center width={8} height={8} bgColor="#00E9DB" borderRadius="100%" p={2}>
							{Icons[type]}
						</Center>
					)}
					<Box>
						<Text fontWeight="600">{type === 'IN' ? 'Receive' : 'Send'}</Text>
						{receiverName && <Text>{receiverName}</Text>}
					</Box>
				</Center>
			}
			value={
				<Flex gap={2}>
					<Box>
						<Text fontSize="xs" fontWeight={600} color={type === 'IN' ? 'green' : 'red'}>
							{type === 'IN' ? '+' : '-'}
							{formatCurrency(
								BigNumber(amount)
									.div(10 ** decimals)
									.toString(),
							)}{' '}
							{symbol}
						</Text>
						{/* {tokenPrice && (
							<Text fontSize="smaller" color="gray.400">
								≈
								{formatUsd(
									(+BigNumber(amount)
										.div(10 ** decimals)
										.toNumber() || 0) * (dollarValuePerToken || tokenPrice),
								)}
							</Text>
						)} */}
					</Box>
					<Image borderRadius="100%" src={image} width={6} height={6}></Image>
				</Flex>
			}
		/>
	);
};
