import { TelegramCore } from '@/app-cores/telegram';

const COPILOT_CHAT_STORAGE = {
	IS_DRAGGING: {
		KEY: `@TOBI_${TelegramCore.getUserId()}_COPILOT_IS_DRAGGING`,
		VALUE: '1',
	},
};
export const copilotManager = {
	setIsDragging: () => {
		sessionStorage.setItem(COPILOT_CHAT_STORAGE.IS_DRAGGING.KEY, COPILOT_CHAT_STORAGE.IS_DRAGGING.VALUE);
	},
	removeIsDragging: () => {
		sessionStorage.removeItem(COPILOT_CHAT_STORAGE.IS_DRAGGING.KEY);
	},
	isDragging: () => {
		return sessionStorage.getItem(COPILOT_CHAT_STORAGE.IS_DRAGGING.KEY) === COPILOT_CHAT_STORAGE.IS_DRAGGING.VALUE;
	},
};
