import { useNavigate } from 'react-router-dom';
import { ConfirmSendTransaction, Source } from '@/app-components/common';
import { useSentTokenStore, useContactStore } from '@/app-store';
import { useEstimateGasFee } from '@/app-hooks/transactions/useEstimateGasFee';
import { useUserSettingsStore } from '@/app-store/settings';
import { useSentEvmToken } from '@/app-hooks/transactions';
import { NAVIGATE_PATHS } from '@/app-constants/router';
import { usePriceNativeToken, useTokenPriceSingleToken } from '@/app-hooks/api/portfolio/useTokenPrices';
import { TransactionType } from '@/app-types';
import { getWrapNativeToken } from '@/app-helpers/token';
import { displayFee } from '@/app-helpers/display';
import { DATADOG_ERROR_TAGS, dataDogAddError } from '@/app-services/monitor/datadog';
import { showTransactionError } from '@/app-helpers/error-handling';
interface ConfirmSentErc20AssetProps {
	source?: Source;
}
export const ConfirmSentErc20Asset: React.FC<ConfirmSentErc20AssetProps> = ({ source }) => {
	const { amount, messages, tokenInfo } = useSentTokenStore();
	const { contact, address } = useContactStore();
	const sentTo = contact?.wallets?.evm?.address || address;
	const { data: estimateGasFee } = useEstimateGasFee({ to: sentTo, amount, token: tokenInfo });
	const { gasFeeType } = useUserSettingsStore();

	const { data: feePriceUsd = 0 } = usePriceNativeToken({
		chainId: tokenInfo?.chainId,
	});
	const { sentTransaction, isPending } = useSentEvmToken();
	const navigate = useNavigate();
	const handleConfirmTransaction = async () => {
		try {
			if (isPending) return;
			const data = await sentTransaction({
				amount,
				to: sentTo,
				token: tokenInfo,
				message: messages,
				maxFeePerGas: estimateGasFee?.[gasFeeType]?.maxFeePerGas,
				maxPriorityFeePerGas: estimateGasFee?.[gasFeeType]?.maxPriorityFeePerGas,
			});
			navigate(NAVIGATE_PATHS.transactionResult(TransactionType.Send, tokenInfo.chainId, data.hash));
		} catch (error) {
			showTransactionError(error);
			dataDogAddError(error, {
				tags: {
					name: DATADOG_ERROR_TAGS.SEND,
					function: 'transferErc20Asset',
				},
			});
		}
	};

	const gasFee = displayFee({ estimateGasFee, feePriceUsd, gasFeeType });

	return (
		<ConfirmSendTransaction
			feeToken={gasFee.feeToken}
			feeUsd={gasFee.feeUsd}
			isLoading={isPending}
			onConfirm={handleConfirmTransaction}
			source={source}
		/>
	);
};
