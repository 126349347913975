import { ServiceEmail } from '@/app-cores/api/bot/service-email';
import { UseMutationOptions, useMutation } from '@tanstack/react-query';

export const useMutationSendBackupKeyToUsersTG = (options?: UseMutationOptions<void, unknown, FormData>) => {
	const response = useMutation<void, unknown, FormData>({
		mutationKey: ['mutation-send-backup-key-to-users-tg'],
		mutationFn: async (payload: FormData) => {
			return ServiceEmail.sendBackupKeyToUsersTG(payload);
		},
		...options,
	});

	return response;
};
