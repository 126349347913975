import { useModalStore } from '../store/ModalStore';
import SessionProposalModal from './SessionProposalModal';
import { SessionSendTransactionModal } from './SessionSendTransactionModal';

export const TonConnectRequestModal = () => {
	const { open, view } = useModalStore();
	if (!open) return null;
	switch (view) {
		case 'SessionProposalModal':
			return <SessionProposalModal />;
		case 'SessionSendTransactionModal':
			return <SessionSendTransactionModal />;
		default:
			break;
	}
};
