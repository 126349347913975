import React from 'react';

interface IProps extends React.SVGAttributes<HTMLOrSVGElement> {}

export const FiSwapIcon: React.FC<IProps> = ({ width = 24, height = 24, ...rest }) => {
	return (
		<svg width={width} height={height} {...rest} viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M16.9931 0.292893C17.3836 -0.0976311 18.0168 -0.0976311 18.4073 0.292893L22.4073 4.29289C22.7978 4.68342 22.7978 5.31658 22.4073 5.70711L18.4073 9.70711C18.0168 10.0976 17.3836 10.0976 16.9931 9.70711C16.6026 9.31658 16.6026 8.68342 16.9931 8.29289L20.286 5L16.9931 1.70711C16.6026 1.31658 16.6026 0.683417 16.9931 0.292893Z"
				fill="black"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M7.7002 6C6.90455 6 6.14148 6.31607 5.57887 6.87868C5.01627 7.44129 4.7002 8.20435 4.7002 9V11C4.7002 11.5523 4.25248 12 3.7002 12C3.14791 12 2.7002 11.5523 2.7002 11V9C2.7002 7.67392 3.22698 6.40215 4.16466 5.46447C5.10234 4.52678 6.37411 4 7.7002 4H21.7002C22.2525 4 22.7002 4.44772 22.7002 5C22.7002 5.55228 22.2525 6 21.7002 6H7.7002Z"
				fill="black"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M8.4073 14.2929C8.79783 14.6834 8.79783 15.3166 8.4073 15.7071L5.11441 19L8.4073 22.2929C8.79783 22.6834 8.79783 23.3166 8.4073 23.7071C8.01678 24.0976 7.38361 24.0976 6.99309 23.7071L2.99309 19.7071C2.60256 19.3166 2.60256 18.6834 2.99309 18.2929L6.99309 14.2929C7.38361 13.9024 8.01678 13.9024 8.4073 14.2929Z"
				fill="black"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M21.7002 12C22.2525 12 22.7002 12.4477 22.7002 13V15C22.7002 16.3261 22.1734 17.5979 21.2357 18.5355C20.298 19.4732 19.0263 20 17.7002 20H3.7002C3.14791 20 2.7002 19.5523 2.7002 19C2.7002 18.4477 3.14791 18 3.7002 18H17.7002C18.4958 18 19.2589 17.6839 19.8215 17.1213C20.3841 16.5587 20.7002 15.7956 20.7002 15V13C20.7002 12.4477 21.1479 12 21.7002 12Z"
				fill="black"
			/>
		</svg>
	);
};
