import { CHAIN_CONFIG } from '@/app-constants/chains';
import { useQuery } from '@tanstack/react-query';
import { TransactionResponse, ethers } from 'ethers';
import { isEmpty } from 'lodash-es';

const BUFFER_GAS_PERCENT = 20n;

export function getGasAfterBuffer(gas: bigint, bufferPercent: bigint) {
	if (!gas) return 0n;
	return gas + (gas * bufferPercent) / 100n;
}

export function getMaxFeePerGas(maxPriorityFeePerGas: bigint, maxFeePerGas: bigint) {
	if (maxPriorityFeePerGas) return maxPriorityFeePerGas + maxFeePerGas;
	return getGasAfterBuffer(maxFeePerGas, 5n);
}

export const useEstimateCancelGasFee = ({
	transaction,
	bufferGasPercent = BUFFER_GAS_PERCENT,
}: {
	transaction: TransactionResponse;
	bufferGasPercent?: bigint;
}) => {
	const chainConfig = CHAIN_CONFIG[Number(transaction?.chainId)];

	const response = useQuery({
		queryKey: ['estimate-cancel-gas-fee', transaction?.hash],
		queryFn: async () => {
			const maxPriorityFeePerGas = getGasAfterBuffer(
				BigInt(transaction?.maxPriorityFeePerGas || 0n),
				bufferGasPercent,
			);
			const maxFeePerGas = getMaxFeePerGas(maxPriorityFeePerGas, BigInt(transaction?.maxFeePerGas || 0n));
			const gasLimit = BigInt(transaction?.gasLimit || 0n);
			const gasPrice = getGasAfterBuffer(BigInt(transaction?.gasPrice || 0n), bufferGasPercent);
			const gasFeeInNativeToken = chainConfig.isEIP1559
				? ethers.formatEther(maxFeePerGas * gasLimit)
				: ethers.formatEther(gasPrice * gasLimit);
			return {
				maxPriorityFeePerGas: maxPriorityFeePerGas,
				maxFeePerGas: maxFeePerGas,
				gasLimit: gasLimit,
				gasFeeInNativeToken: gasFeeInNativeToken,
				nonce: transaction?.nonce,
				gasPrice: gasPrice,
			};
		},
		enabled: !isEmpty(transaction),
	});
	return response;
};
