import { TON_CENTER_API_KEY } from '@/app-cores/api/axios';
import keyShareManager from '../wallet/keyshare-manager';
import { WalletContractV4 } from './WalletContractV4';
import { TonClient4 } from './client/TonClient4';
import { Address, beginCell, Cell, storeMessage } from '@ton/core';
import { JettonMaster, TonClient } from '@ton/ton';
import { getHttpEndpoint, getHttpV4Endpoint } from '@orbs-network/ton-access';
import { TonCenterServiceAPI } from '@/app-cores/api/toncenter/service-toncenter';
import { MpcWallet } from '@/app-cores/mpc-wallet/wallet';

enum NetWorkEndpoint {
	testnet = 'https://testnet-v4.tonhubapi.com',
	mainnet = 'https://mainnet-v4.tonhubapi.com',
}
export const TON_CENTER_RPC = 'https://toncenter.com/api/v2/jsonRPC';
export class TonWallet {
	static async create(network: 'testnet' | 'mainnet') {
		let client: TonClient4;
		if (network === 'testnet') {
			client = new TonClient4({ endpoint: NetWorkEndpoint[network] });
		} else {
			const endpoint = await getHttpV4Endpoint(); // get the decentralized RPC endpoint
			client = new TonClient4({ endpoint: endpoint });
		}

		const wallet = WalletContractV4.create({
			workchain: 0,
			publicKey: Buffer.from(keyShareManager?.keyShares?.eddsaKeyShare?.publicKey()),
		});
		const contract = await client.open(wallet);
		return { wallet, contract, client };
	}
	static async getUserJettonWalletAddress(jettonAddress: string) {
		const client = new TonClient({
			endpoint: TON_CENTER_RPC,
			apiKey: TON_CENTER_API_KEY,
		});
		const wallet = WalletContractV4.create({
			workchain: 0,
			publicKey: Buffer.from(keyShareManager?.keyShares?.eddsaKeyShare?.publicKey()),
		});
		const userAddress = wallet.address;
		const jettonMasterAddress = Address.parse(jettonAddress); // for example EQBlqsm144Dq6SjbPI4jjZvA1hqTIP3CvHovbIfW_t-SCALE
		const jettonMaster = client.open(JettonMaster.create(jettonMasterAddress));
		const userJettonWallet = await jettonMaster.getWalletAddress(userAddress);
		return userJettonWallet;
	}
	static async getTonSpaceJettonWalletAddress(tonSpaceWallet: Address, jettonAddress: string) {
		const client = new TonClient({
			endpoint: TON_CENTER_RPC,
			apiKey: TON_CENTER_API_KEY,
		});
		const jettonMasterAddress = Address.parse(jettonAddress); // for example EQBlqsm144Dq6SjbPI4jjZvA1hqTIP3CvHovbIfW_t-SCALE
		const jettonMaster = client.open(JettonMaster.create(jettonMasterAddress));
		const tonSpaceJettonWallet = await jettonMaster.getWalletAddress(tonSpaceWallet);
		return tonSpaceJettonWallet;
	}

	static async getTxHashByBOC(base64Hash: string) {
		const transactions = await TonCenterServiceAPI.getTransactions({
			address: MpcWallet.getWalletAddress().tonAddress,
			limit: 5,
		});
		const tx = transactions.find(
			(e) => e.in_msg?.body_hash === base64Hash || e.out_msgs?.some((msg) => msg.body_hash === base64Hash),
		)?.transaction_id?.hash;
		return tx;
	}

	static getQueryIdFromBody(body: string): number {
		try {
			// Decode the Base64-encoded string into a Buffer
			const buffer = Buffer.from(body, 'base64');

			// Convert the buffer back to a Cell
			const cell = Cell.fromBoc(buffer)[0]; // The first cell in the BOC

			// Create a Slice to read the Cell
			const slice = cell.beginParse();

			// Read the fields in the same order they were stored
			const transferOp = slice.loadUint(32); // Read the operation (JettonWallet.TRANSFER)
			const queryId = slice.loadUint(64); // Read the queryId (64-bit integer)

			// Extract other fields if needed, but for queryId we stop here
			return queryId;
		} catch (error) {
			return -1;
		}
	}
}
