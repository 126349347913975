import { axiosBotAPI } from '@/app-cores/api/axios';
import { OnrampCrypto, PaymentMethod } from './types';

class ServiceOnRamp {
	async getPaymentMethods(): Promise<PaymentMethod[]> {
		const response = await axiosBotAPI.get('/trading/onramp/payment-methods');
		return response.data;
	}
	async getListOnrampCryptos(): Promise<OnrampCrypto[]> {
		const response = await axiosBotAPI.get('/trading/onramp/cryptos');
		return response.data;
	}
}

export const ServiceOnRampAPI = new ServiceOnRamp();
