import React from 'react';

interface IProps extends React.SVGAttributes<HTMLOrSVGElement> {}

export const CancelIcon: React.FC<IProps> = ({ width = 20, height = 20, fill = 'white', ...rest }) => {
	return (
		<svg width={width} height={height} {...rest} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M15.5891 4.41107C15.9145 4.73651 15.9145 5.26414 15.5891 5.58958L5.58909 15.5896C5.26366 15.915 4.73602 15.915 4.41058 15.5896C4.08514 15.2641 4.08514 14.7365 4.41058 14.4111L14.4106 4.41107C14.736 4.08563 15.2637 4.08563 15.5891 4.41107Z"
				fill={fill}
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M4.41058 4.41107C4.73602 4.08563 5.26366 4.08563 5.58909 4.41107L15.5891 14.4111C15.9145 14.7365 15.9145 15.2641 15.5891 15.5896C15.2637 15.915 14.736 15.915 14.4106 15.5896L4.41058 5.58958C4.08514 5.26414 4.08514 4.73651 4.41058 4.41107Z"
				fill={fill}
			/>
		</svg>
	);
};
