import { MarketingServiceAPI } from '@/app-cores/api/dapp/service-dapp';
import { DappQuery } from '@/app-cores/api/dapp/type';
import { useQuery, keepPreviousData } from '@tanstack/react-query';
import { ONE_MINUTE } from '../portfolio/constant';

export const useQueryDapps = (params: DappQuery) => {
	const response = useQuery({
		queryKey: ['get-dapps', params],
		queryFn: async () => {
			return MarketingServiceAPI.getDapp(params);
		},
		staleTime: ONE_MINUTE * 60 * 24,
		placeholderData: keepPreviousData,
	});
	return response;
};
