import AllowanceForm from '@/app-components/ApproveModal/AllowanceForm';
import { ContractInfo } from '@/app-components/ApproveModal/ContractInfo';
import { DappInfo, IDAppInfo } from '@/app-components/ApproveModal/DappInfo';
import TransactionDataDetail from '@/app-components/ApproveModal/TransactionDataDetail';
import { ButtonSecondary, CTAButton, TextSmall, Toast } from '@/app-components/common';
import TokenLogo from '@/app-components/common/Avatar/TokenLogo';
import { CHAIN_CONFIG, NATIVE_TOKEN_ADDRESS } from '@/app-constants/chains';
import { TokenInfo } from '@/app-cores/api/bff';
import { MpcWallet } from '@/app-cores/mpc-wallet/wallet';
import { getShortAddress } from '@/app-helpers/address';
import { parseErrorMessage } from '@/app-helpers/error-handling';
import { formatUnits, formatUsd } from '@/app-helpers/number';
import { usePriceNativeToken, useTokenPriceSingleToken } from '@/app-hooks/api/portfolio/useTokenPrices';
import { useDebounce } from '@/app-hooks/common';
import { useApproveToken } from '@/app-hooks/wallet';
import { useTransactionWatcherStore } from '@/app-store';
import { TransactionType } from '@/app-types';
import { ChevronRightIcon } from '@/assets/images/svg';
import {
	Box,
	Card,
	Center,
	Drawer,
	DrawerBody,
	DrawerCloseButton,
	DrawerContent,
	DrawerFooter,
	DrawerHeader,
	DrawerOverlay,
	Flex,
	Skeleton,
	Spinner,
	Text,
} from '@chakra-ui/react';
import { MaxUint256, ethers } from 'ethers';
import { CSSProperties, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

export const Label = ({
	text,
	style,
	color,
	onClick,
}: {
	color?: string;
	text: string;
	style?: CSSProperties;
	onClick?: () => void;
}) => (
	<Text
		color={color}
		fontSize={'12px'}
		fontWeight={'500'}
		lineHeight={'16x'}
		style={style}
		cursor={onClick ? 'pointer' : undefined}
		onClick={onClick}
	>
		{text}
	</Text>
);

const GasFeeInfo = ({ chainId, gasAmount }: { chainId: string; gasAmount: bigint }) => {
	const nativeToken = CHAIN_CONFIG[chainId].nativeToken;
	const { data: usdPrice } = usePriceNativeToken({
		chainId,
	});
	return (
		<Card padding={'16px'} width={'100%'} background={'gray.100'} gap={'8px'}>
			<Flex justifyContent={'space-between'}>
				<Label text="Transaction fee" style={{ color: 'gray' }} />
			</Flex>
			<Flex justifyContent={'space-between'}>
				<Label text="A fee associated with this request." style={{ maxWidth: '50%' }} />
				<Box textAlign={'right'}>
					{gasAmount ? (
						<>
							<Text fontSize={'14px'} fontWeight={'600'}>
								{formatUsd(
									+ethers.formatUnits(gasAmount.toString(), nativeToken.decimals).toString() *
										usdPrice,
								)}
							</Text>
							<Text fontSize={'14px'}>
								{formatUnits(gasAmount, nativeToken.decimals)} {nativeToken.symbol}
							</Text>
						</>
					) : (
						<Skeleton height={'42px'} width={'100px'} endColor={'gray.200'} borderRadius="10px" />
					)}
				</Box>
			</Flex>
		</Card>
	);
};

const EditAllowanceButton = ({ onClick }: { onClick: () => void }) => {
	return (
		<Flex cursor={'pointer'} onClick={onClick} fontSize={'12px'} alignItems={'center'} fontWeight={'500'}>
			Edit Permission <ChevronRightIcon width={20} />
		</Flex>
	);
};

type ApproveInfo = { contract: string; chainId: string; amount: string; token: TokenInfo; type: TransactionType };

const TokenBalance = ({ token }: { token: TokenInfo }) => {
	return (
		<Card
			background={'gray.100'}
			width={'100%'}
			padding={'12px 12px'}
			display={'flex'}
			flexDirection={'row'}
			gap={'12px'}
			alignItems={'center'}
		>
			<TokenLogo logo={token?.logo} size={40} symbol={token?.symbol} />
			<Flex justifyContent={'space-between'} flex={1}>
				<Flex fontWeight={'600'} flexDirection={'column'} gap={'4px'}>
					<Text>{token.name}</Text>
					<TextSmall color={'gray'} my={0}>
						{getShortAddress(token?.address)}
					</TextSmall>
				</Flex>
				<Flex fontWeight={'600'} flexDirection={'column'} gap={'4px'} alignItems={'flex-end'}>
					<Text>Balance</Text>
					<TextSmall color={'gray'} my={0} textAlign={'right'}>
						{formatUnits(token.balance, token.decimals)} {token.symbol}
					</TextSmall>
				</Flex>
			</Flex>
		</Card>
	);
};

enum Steps {
	SHOW_APPROVE,
	EDIT_ALLOWANCE,
}

export default function ApproveModal({
	isOpen,
	dappInfo,
	approveInfo,
	onClose,
}: {
	isOpen: boolean;
	dappInfo?: IDAppInfo;
	approveInfo: ApproveInfo;
	onClose: () => void;
}) {
	const { t } = useTranslation();
	const [step, setStep] = useState<Steps>(Steps.SHOW_APPROVE);
	const isReviewAllowance = step === Steps.SHOW_APPROVE;
	const onCloseWrap = () => {
		setStep(Steps.SHOW_APPROVE);
		onClose();
	};

	const [isLoadingApprove, setIsLoadingApprove] = useState(false);
	const { contract, amount, chainId, token } = approveInfo;
	const [approveValue, setApproveValue] = useState(MaxUint256.toString());
	const approveValueDebounced = useDebounce(approveValue, 500);

	const { approveToken, isPending, estimateGas } = useApproveToken({
		spenderContract: contract,
		tokenContract: token?.address,
		chainId,
	});
	const { addPendingEvmTransaction } = useTransactionWatcherStore();

	const [gas, setGas] = useState<bigint>();
	useEffect(() => {
		if (step === Steps.EDIT_ALLOWANCE) {
			return;
		}
		if (!approveValueDebounced || !isOpen) {
			setGas(undefined);
			return;
		}
		estimateGas(approveValueDebounced)
			.then((gas) => setGas(gas))
			.catch(() => setGas(undefined));
	}, [estimateGas, approveValueDebounced, isOpen, step]);

	const onClickApprove = async () => {
		if (isLoadingApprove || !approveValueDebounced) return;
		try {
			setIsLoadingApprove(true);
			const data = await approveToken({ amount: approveValueDebounced });
			onCloseWrap();
		} catch (error) {
			console.log('approve error', error);
			toast(
				<Toast
					type="error"
					message={t('toast.approveTokenError', {
						msg: parseErrorMessage(error),
					})}
				/>,
			);
		} finally {
			setIsLoadingApprove(false);
		}
	};

	const showLoading = isLoadingApprove || isPending;

	const renderApproveInfo = () => (
		<>
			{dappInfo && <DappInfo dapp={dappInfo} />}
			<ContractInfo
				{...approveInfo}
				msg={`Give permission to access your ${token?.symbol}?`}
				description={'By granting permission, you are allowing the following contract to access your funds'}
			/>
			<EditAllowanceButton onClick={() => setStep(Steps.EDIT_ALLOWANCE)} />
			<GasFeeInfo chainId={chainId} gasAmount={gas} />
			<TransactionDataDetail transactionData={{ from: MpcWallet.getWalletAddress().evmAddress, to: contract }} />
		</>
	);

	const renderEditAllowance = () => (
		<>
			{dappInfo && <DappInfo dapp={dappInfo} />}
			<TokenBalance token={token} />
			<Text textAlign={'center'} fontSize={'24px'} lineHeight={'30px'}>
				Edit spending cap
			</Text>
			<AllowanceForm
				value={approveValue}
				maxValue={token?.balance}
				suggestionValue={amount}
				onChange={setApproveValue}
			/>
			<TransactionDataDetail transactionData={{ from: MpcWallet.getWalletAddress(), to: contract }} />
		</>
	);

	return (
		<Drawer placement={'bottom'} onClose={onCloseWrap} isOpen={isOpen} autoFocus={false}>
			<DrawerOverlay />
			<DrawerContent>
				<DrawerCloseButton />
				<DrawerHeader borderBottomWidth="1px">Approve {token?.symbol}</DrawerHeader>
				<DrawerBody>
					<Flex flexDirection={'column'} alignItems={'center'} gap={'16px'}>
						{step === Steps.SHOW_APPROVE ? renderApproveInfo() : renderEditAllowance()}
					</Flex>
				</DrawerBody>
				<DrawerFooter display="flex" gap={2}>
					<ButtonSecondary
						height="48px"
						fontWeight="medium"
						fontSize={'14px'}
						disabled={showLoading}
						flex={1}
						onClick={isReviewAllowance ? onCloseWrap : () => setStep(Steps.SHOW_APPROVE)}
					>
						{isReviewAllowance ? 'Reject' : 'Back'}
					</ButtonSecondary>
					<CTAButton
						colorScheme={'cyan'}
						height="48px"
						fontWeight="medium"
						fontSize={'14px'}
						flex={1}
						onClick={onClickApprove}
					>
						{showLoading ? <Spinner size="sm" color="white" /> : isReviewAllowance ? 'Confirm' : 'Approve'}
					</CTAButton>
				</DrawerFooter>
			</DrawerContent>
		</Drawer>
	);
}
