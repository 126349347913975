import { OnrampCrypto, PaymentMethod } from '@/app-cores/api';
import { ServiceOnRampAPI } from '@/app-cores/api/bot/service-onramp';
import { UseQueryOptions, useQuery } from '@tanstack/react-query';
import { ONE_MINUTE } from '../portfolio/constant';

export const useQueryOnrampCryptos = (options?: UseQueryOptions<OnrampCrypto[]>) => {
	const response = useQuery({
		queryKey: ['query-onramp-cryptos'],
		queryFn: async () => {
			return ServiceOnRampAPI.getListOnrampCryptos();
		},
		staleTime: ONE_MINUTE * 60 * 24,
		...options,
	});
	return response;
};
