import React from 'react';

interface IProps extends React.SVGAttributes<HTMLOrSVGElement> {}

export const FiSwapIconColor: React.FC<IProps> = ({ width = 24, height = 24, ...rest }) => {
	return (
		<svg width={width} height={height} {...rest} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M13.2346 16.873H8.99637C8.37349 16.873 7.86837 16.3679 7.86837 15.745V11.7554L9.49362 12.0685C10.0561 12.0685 10.3396 11.3902 9.94249 10.9908L8.81037 9.85866L6.62749 7.67578C6.50187 7.55241 6.34137 7.48828 6.17862 7.48828C6.01812 7.48828 5.85799 7.55241 5.73237 7.67578L3.54949 9.85866L2.41774 10.9908C2.02062 11.3902 2.30412 12.0685 2.86662 12.0685L4.49187 11.7554V17.0894C4.49187 18.8347 5.90674 20.2499 7.65237 20.2499H7.86837H13.3051C14.2546 20.2499 15.0211 19.4658 14.9926 18.5099C14.9649 17.5855 14.159 16.873 13.2346 16.873Z"
				fill="black"
			/>
			<path
				d="M10.7656 7.1265H15.0039C15.6267 7.1265 16.1319 7.63162 16.1319 8.2545V12.2441L14.5066 11.931C13.9441 11.931 13.6606 12.6094 14.0577 13.0087L15.1899 14.1409L17.3727 16.3237C17.4984 16.4471 17.6589 16.5112 17.8216 16.5112C17.9821 16.5112 18.1422 16.4471 18.2679 16.3237L20.4507 14.1409L21.5829 13.0087C21.98 12.6094 21.6965 11.931 21.134 11.931L19.5087 12.2441V6.9105C19.5087 5.16525 18.0939 3.75 16.3482 3.75H16.1322H10.6951C9.74561 3.75 8.97911 4.53413 9.00761 5.49C9.03536 6.414 9.84123 7.1265 10.7656 7.1265Z"
				fill="#00E9DB"
			/>
		</svg>
	);
};
