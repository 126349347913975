import { axiosBotAPI } from '@/app-cores/api/axios';
import { CloudKitCreateRecordArgs, CloudKitRecord } from './types';

export const ServiceCloudKit = {
	getAuthUrl: async (): Promise<string> => {
		const response = await axiosBotAPI.get('/accounts/oauth/icloud/auth_url');
		const { data } = response.data;
		return data as string;
	},

	createRecord: async (args: CloudKitCreateRecordArgs, authToken: string): Promise<void> => {
		await axiosBotAPI.post(
			`/accounts/oauth/icloud/records?auth_code=${encodeURIComponent(authToken)}`,
			{
				version: args.version,
				data: args.data,
			},
			{
				headers: {
					'Content-Type': 'application/json',
				},
			},
		);
	},

	getRecords: async (authToken: string): Promise<CloudKitRecord[]> => {
		const response = await axiosBotAPI.get(
			`/accounts/oauth/icloud/records?auth_code=${encodeURIComponent(authToken)}`,
		);
		const { data } = response.data;
		return (data.records || []) as CloudKitRecord[];
	},

	getCurrentRecord: async (authToken: string): Promise<CloudKitRecord> => {
		const response = await axiosBotAPI.get(
			`/accounts/oauth/icloud/records/current?auth_code=${encodeURIComponent(authToken)}`,
		);
		const { data } = response.data;
		return data as CloudKitRecord;
	},

	getCKAuthTokenByAuthState: async (state: string): Promise<string> => {
		const response = await axiosBotAPI.get('/accounts/oauth/icloud/polling', {
			params: {
				state,
			},
		});
		const { data } = response.data;
		return data as string;
	},
};
