import React from 'react';

interface IProps extends React.SVGAttributes<HTMLOrSVGElement> {}

export const FiMaximizeIcon: React.FC<IProps> = ({ width = 20, height = 20, ...rest }) => {
	return (
		<svg width={width} height={height} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M11.667 2.50033C11.667 2.04009 12.0401 1.66699 12.5003 1.66699H17.5003C17.9606 1.66699 18.3337 2.04009 18.3337 2.50033V7.50033C18.3337 7.96056 17.9606 8.33366 17.5003 8.33366C17.0401 8.33366 16.667 7.96056 16.667 7.50033V3.33366H12.5003C12.0401 3.33366 11.667 2.96056 11.667 2.50033Z"
				fill="black"
				fillOpacity="0.64"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M2.50033 11.667C2.96056 11.667 3.33366 12.0401 3.33366 12.5003V16.667H7.50033C7.96056 16.667 8.33366 17.0401 8.33366 17.5003C8.33366 17.9606 7.96056 18.3337 7.50033 18.3337H2.50033C2.04009 18.3337 1.66699 17.9606 1.66699 17.5003V12.5003C1.66699 12.0401 2.04009 11.667 2.50033 11.667Z"
				fill="black"
				fillOpacity="0.64"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M18.0889 1.91107C18.4144 2.23651 18.4144 2.76414 18.0889 3.08958L12.2556 8.92291C11.9302 9.24835 11.4025 9.24835 11.0771 8.92291C10.7516 8.59748 10.7516 8.06984 11.0771 7.7444L16.9104 1.91107C17.2359 1.58563 17.7635 1.58563 18.0889 1.91107Z"
				fill="black"
				fillOpacity="0.64"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M8.92291 11.0771C9.24835 11.4025 9.24835 11.9302 8.92291 12.2556L3.08958 18.0889C2.76414 18.4144 2.23651 18.4144 1.91107 18.0889C1.58563 17.7635 1.58563 17.2359 1.91107 16.9104L7.7444 11.0771C8.06984 10.7516 8.59748 10.7516 8.92291 11.0771Z"
				fill="black"
				fillOpacity="0.64"
			/>
		</svg>
	);
};
