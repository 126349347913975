import React from 'react';

interface IProps extends React.SVGAttributes<HTMLOrSVGElement> {}

export const FiWalletAddressIcon: React.FC<IProps> = ({ width = 32, height = 32, ...rest }) => {
	return (
		<svg width={width} height={height} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
			<g clipPath="url(#clip0_8183_40525)">
				<path
					d="M31.0325 8.11625C30.1931 5.02375 26.9769 1.8075 23.8844 0.968125C21.9888 0.495 19.4481 0.008125 16 0C12.5525 0.00875 10.0119 0.495 8.11625 0.968125C5.02375 1.80813 1.8075 5.02375 0.968125 8.11625C0.495 10.0119 0.00875 12.5525 0 16C0.00875 19.4481 0.495 21.9888 0.968125 23.8844C1.8075 26.9769 5.02375 30.1925 8.11625 31.0325C10.0119 31.5056 12.5538 31.9919 16 32.0006C19.4481 31.9919 21.9888 31.5056 23.8844 31.0325C26.9769 30.1925 30.1931 26.9769 31.0325 23.8844C31.5056 21.9888 31.9919 19.4469 32.0006 16C31.9919 12.5525 31.5056 10.0119 31.0325 8.11625Z"
					fill="#5C5C7A"
				/>
				<path
					d="M22.0625 9.53383C21.5369 8.04758 20.1975 6.86445 18.6725 6.66883C17.6 6.53508 16.1363 6.4257 14.135 6.45258C12.1337 6.47945 10.6675 6.63383 9.59188 6.79633C7.81437 7.07633 6.22688 8.63945 5.92188 10.4295C5.92188 10.4295 5.45125 12.9551 5.35938 16.3563H22.5881C22.5881 16.3563 22.5881 11.852 22.0625 9.53383Z"
					fill="url(#paint0_linear_8183_40525)"
				/>
				<path
					d="M26.4619 13.7863C26.2594 12.2038 24.85 10.8651 23.2006 10.6463C20.2944 10.3582 16.0012 10.3338 16 10.3445C14.1431 10.3251 9.79562 10.5026 8.8 10.6463C7.79125 10.7763 6.88 11.3338 6.27 12.0976C5.87597 12.5823 5.62297 13.1661 5.53875 13.7851C5.42062 14.7413 5.32687 16.0476 5.37437 17.8351C5.42187 19.6226 5.58937 20.9326 5.75687 21.892C6.04562 23.4801 7.47437 24.8751 9.06437 25.142C10.0275 25.3257 14.2131 25.5795 16.0019 25.5482C17.7894 25.5807 21.975 25.3257 22.9394 25.142C24.5294 24.8751 25.9581 23.4801 26.2469 21.892C26.4144 20.9326 26.5787 19.6232 26.6294 17.8351C26.68 16.047 26.58 14.7426 26.4619 13.7863Z"
					fill="url(#paint1_linear_8183_40525)"
				/>
				<path
					d="M22.9263 19.4206C22.6347 19.4208 22.3497 19.3344 22.1072 19.1725C21.8648 19.0106 21.6758 18.7805 21.5642 18.5112C21.4526 18.2418 21.4233 17.9455 21.4802 17.6595C21.537 17.3736 21.6774 17.1109 21.8835 16.9048C22.0897 16.6987 22.3523 16.5583 22.6383 16.5014C22.9242 16.4446 23.2206 16.4738 23.4899 16.5854C23.7592 16.6971 23.9894 16.8861 24.1513 17.1285C24.3132 17.371 24.3995 17.656 24.3994 17.9475C24.3994 18.3382 24.2442 18.7129 23.9679 18.9892C23.6916 19.2654 23.3169 19.4206 22.9263 19.4206Z"
					fill="white"
				/>
			</g>
			<defs>
				<linearGradient
					id="paint0_linear_8183_40525"
					x1="10.6406"
					y1="19.4038"
					x2="18.3075"
					y2="6.12445"
					gradientUnits="userSpaceOnUse"
				>
					<stop />
					<stop offset="0.54" stopColor="#18183F" />
					<stop offset="1" stopColor="#3E3E6E" />
				</linearGradient>
				<linearGradient
					id="paint1_linear_8183_40525"
					x1="23.6906"
					y1="25.4013"
					x2="8.31063"
					y2="10.0213"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#00E9DB" />
					<stop offset="0.54" stopColor="#18DACE" />
					<stop offset="1" stopColor="#05CCC0" />
				</linearGradient>
				<clipPath id="clip0_8183_40525">
					<rect width="32" height="32" fill="white" />
				</clipPath>
			</defs>
		</svg>
	);
};
