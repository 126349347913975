import { TooltipInfo } from '@/app-components/common';
import { TxStatus } from '@/app-cores/api/activities';
import { SwapService } from '@/app-hooks/swap';
import { SwapProvider, SwapStepInfo } from '@/app-hooks/swap/type';
import { GenericRoute, SelectedRoute, useSwapStore } from '@/app-store/swap';
import { MAP_COLOR_BY_TXS_STATUS } from '@/app-views/wallet/components/Activities/Activity';
import { Box, Flex, Image, Text } from '@chakra-ui/react';
import React, { ReactNode, useEffect } from 'react';

export const TransactionSteps = ({
	provider,
	selectedRoute,
}: {
	provider: SwapProvider;
	selectedRoute: SelectedRoute;
}) => {
	const { routeExecuting, setRouteExecuting } = useSwapStore(false);

	const steps = SwapService.formatSteps(provider, routeExecuting || {}, selectedRoute);

	useEffect(() => {
		return () => {
			setRouteExecuting(undefined);
		};
	}, [setRouteExecuting]);

	if (!steps || !routeExecuting) return null;

	const renderIcon = (status: string) => {
		const size = '18px';
		const boxSize = '26px';

		const { background, color, icon } = MAP_COLOR_BY_TXS_STATUS[status || TxStatus.Waiting] || {};

		return (
			<Flex
				sx={{
					borderRadius: '50%',
					height: boxSize,
					width: boxSize,
					justifyContent: 'center',
					alignItems: 'center',
					background,
					color,
				}}
			>
				{icon && React.createElement(icon, { height: size, width: size })}
			</Flex>
		);
	};

	const renderStepMsg = ({ message, desc, status, id, steps }: SwapStepInfo, isParent?: boolean) => {
		const isFailed = status === TxStatus.Failed;
		return (
			<Flex key={id} gap={'8px'} align={'center'} fontSize={'14px'} fontWeight={isParent ? '600' : undefined}>
				{!steps?.length && renderIcon(status)}
				{message ?? 'Unknown Error'}
				{desc && isFailed && (
					<TooltipInfo label={typeof desc === 'string' ? desc : (desc as any).toString?.()} />
				)}
			</Flex>
		);
	};

	const renderListSteps = (steps: SwapStepInfo[] | undefined, step: number) => {
		if (!steps?.length) return null;
		return (
			<Flex flexDirection={'column'} gap={'6px'}>
				{steps.map((e) => renderStepMsg(e))}
			</Flex>
		);
	};

	return (
		<Flex direction={'column'} align={'flex-start'} gap={'6px'}>
			{steps?.map((step, index) => {
				const { id, steps: children } = step;
				return (
					<Flex key={id} gap={'6px'} direction={'column'}>
						{renderStepMsg(
							step,
							steps.some((e) => !!e.steps?.length),
						)}
						{renderListSteps(children, index)}
					</Flex>
				);
			})}
		</Flex>
	);
};
