import { Box, Flex, Text } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
interface StoreBackupTypeProps {
	img: React.ReactNode;
	title: string;
	description: string;
}
export const StoreBackupType: React.FunctionComponent<{ backupType: StoreBackupTypeProps; onClick?: () => void }> = ({
	backupType,
	onClick,
}) => {
	const { t } = useTranslation();
	return (
		<Box
			mt={3}
			borderRadius={16}
			backgroundColor="white"
			p={6}
			border="1px solid rgba(0, 0, 0, 0.08)"
			cursor="pointer"
		>
			<Flex onClick={onClick} gap={4}>
				<Box>{backupType.img}</Box>
				<Box>
					<Text mb={2} fontWeight={500}>
						{t(backupType.title)}
					</Text>
					<Text fontSize="sm" color="gray.400" lineHeight={4}>
						{t(backupType.description)}
					</Text>
				</Box>
			</Flex>
		</Box>
	);
};
