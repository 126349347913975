import { HttpClient, Api } from 'tonapi-sdk-js';
import { Cell, SendMode, beginCell, external, fromNano, internal, storeMessage } from '@ton/core';
import { TonWallet } from '@/app-cores/mpc-wallet/ton/TonWallet';
import { toStateInit } from '../hooks/useMutationSentTransaction';
import { tonAPIService } from '@/app-cores/api/axios';

export const externalMessage = (contract: any, seqno: number, body: Cell) => {
	return beginCell()
		.storeWritable(
			storeMessage(
				external({
					to: contract.address,
					init: seqno === 0 ? contract.init : undefined,
					body: body,
				}),
			),
		)
		.endCell();
};
class TonAPI {
	async eventsEmulate(params) {
		const { contract } = await TonWallet.create('mainnet');
		const seqno = await contract.getSeqno();
		const data = JSON.parse(params);
		try {
			const transfer = await contract.createTransfer({
				seqno,
				sendMode: SendMode.PAY_GAS_SEPARATELY + SendMode.IGNORE_ERRORS,
				messages: data.messages.map((item) => {
					return internal({
						to: item.address,
						bounce: true,
						value: fromNano(item.amount),
						init: toStateInit(item.stateInit),
						body: item.payload ? Cell.fromBase64(item.payload) : undefined,
					});
				}),
			});
			const cell = await contract.sign(transfer);
			const events = await tonAPIService.post('/v2/events/emulate', {
				boc: externalMessage(contract, seqno, cell).toBoc({ idx: false }).toString('base64'),
			});
			return events.data;
		} catch (error) {
			console.error('TonAPI eventsEmulate error:', error);
			throw error;
		}
	}
}
export const TonAPIService = new TonAPI();
