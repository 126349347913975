import { MpcWalletStatus } from '@/app-cores/mpc-wallet/wallet';
import { create } from 'zustand';

export interface IWalletStore {
	isNonWallet: boolean;
	walletStatus: MpcWalletStatus;
	mpcAddress: string;
	dklsInitializeStatus: 'SUCCESS' | 'ERROR' | 'INITIALING';
	setIsNonWallet: (isNonWallet: boolean) => void;
	setWalletStatus: (status: MpcWalletStatus) => void;
	setMPCAddress: (address: string) => void;
	setDklsInitializeStatus: (status: IWalletStore['dklsInitializeStatus']) => void;
}

export const useWalletStore = create<IWalletStore>((set) => ({
	isNonWallet: false,
	dklsInitializeStatus: 'INITIALING',
	mpcAddress: '',
	walletStatus: 'INITIALIZING',
	setIsNonWallet: (isNonWallet: boolean) => set(() => ({ isNonWallet })),
	setWalletStatus: (status: MpcWalletStatus) => set(() => ({ walletStatus: status })),
	setMPCAddress: (address: string) => set(() => ({ mpcAddress: address })),
	setDklsInitializeStatus: (status: IWalletStore['dklsInitializeStatus']) =>
		set(() => ({ dklsInitializeStatus: status })),
}));

export * from './selector-hooks';
