import {
	Box,
	Button,
	Drawer,
	DrawerBody,
	DrawerCloseButton,
	DrawerContent,
	DrawerHeader,
	DrawerOverlay,
	Text,
} from '@chakra-ui/react';
import { QRCodeSVG } from 'qrcode.react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { FiCopyIcon } from '@/assets/icons';
import { Toast } from '@/app-components/common';

interface QrCodeURIProps {
	uri: string;
	isOpen: boolean;
	onClose: () => void;
}
export const QrCodeURI: React.FC<QrCodeURIProps> = ({ isOpen, onClose, uri }) => {
	const { t } = useTranslation();
	return (
		<Drawer isOpen={isOpen} onClose={onClose} placement="bottom">
			<DrawerOverlay />
			<DrawerContent>
				<DrawerHeader borderBottomWidth="1px" borderBottomColor="rgba(0, 0, 0, 0.08)">
					<Text align="center" fontSize="16px" fontWeight={500}>
						{t('deposit.connectYourWallet')}
					</Text>
				</DrawerHeader>
				<DrawerCloseButton />
				<DrawerBody
					py={5}
					display="flex"
					justifyContent="space-between"
					alignItems="center"
					flexDirection="column"
					gap={5}
				>
					<Text fontSize="sm" fontWeight={600} textAlign="center" px={12}>
						{t('deposit.instructionsDeposit')}
					</Text>
					<Box border="1px solid rgba(0, 0, 0, 0.08)" p={4} borderRadius={12}>
						<QRCodeSVG
							value={uri}
							style={{
								width: '300px',
								height: '300px',
							}}
							imageSettings={{
								src: '/assets/images/walletconnect.svg',
								width: 24,
								height: 24,
								excavate: true,
							}}
						/>
					</Box>
					<CopyToClipboard
						text={uri}
						onCopy={(_, success) => {
							toast(
								<Toast
									type="success"
									title="Success"
									message={t('deposit.copyWalletConnectLinkSuccess')}
								/>,
							);
						}}
					>
						<Button
							fontSize="xs"
							fontWeight={600}
							size="sm"
							variant="outline"
							backgroundColor="white"
							border="1px solid rgba(0, 0, 0, 0.08)"
							py={5}
						>
							<FiCopyIcon fill="black" width={20} height={20} style={{ marginRight: 4 }} />
							{t('deposit.copyToClipboard')}
						</Button>
					</CopyToClipboard>
				</DrawerBody>
			</DrawerContent>
		</Drawer>
	);
};
