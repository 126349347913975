import TokenLogo from '@/app-components/common/Avatar/TokenLogo';
import { formatUnits, formatUsd } from '@/app-helpers/number';
import { isTestnetChain } from '@/app-helpers/token';
import { TransactionType } from '@/app-types';
import { TokenLabel } from '@/app-views/portfolio/components/ListCrypto';
import { CardPanel, Status } from '@/app-views/wallet/components/Activities/Activity';
import { FiArrowRightIcon, FiContractInteractionIcon, FiDownLeftIcon, FiSwapIcon, FiUpRightIcon } from '@/assets/icons';
import { Avatar, Flex, Image, Stack, Text } from '@chakra-ui/react';
import { MaxUint256 } from 'ethers';
import { CSSProperties, Fragment, memo } from 'react';
import { CopyToClipboard } from '../../../../app-components/common';
import { CHAIN_CONFIG } from '../../../../app-constants/chains';
import {
	IActivity,
	IBalanceChange,
	TxDetail,
	TxDetailTokenApprove,
	TxDetailTokenContractInteraction,
	TxDetailTokenReceive,
	TxDetailTokenSend,
	TxStatus,
} from '../../../../app-cores/api/activities';
import { getShortTxHash } from '../../../../app-helpers/web3';
import { AppLogoIconCircle } from '../../../../assets/images/svg';

const TokenRender: React.FC<{
	style?: CSSProperties;
	tokenStyle?: CSSProperties;
	data: IBalanceChange;
	sign?: string;
}> = ({ data: { amount, tokenDecimals, tokenSymbol, tokenLogo, amountUsd }, style, tokenStyle, sign }) => {
	return (
		<Flex
			fontSize={'xs'}
			textTransform={'capitalize'}
			style={{
				gap: '4px',
				alignItems: 'flex-end',
				...style,
			}}
		>
			<Flex align={'center'} whiteSpace={'nowrap'} style={tokenStyle} gap={1}>
				<TokenLogo size={16} logo={tokenLogo} />
				<Text as={'span'} fontWeight={'500'}>
					{`${sign ?? ''}${
						amount === MaxUint256.toString() ? 'Unlimited' : formatUnits(amount, tokenDecimals)
					}`}{' '}
					{tokenSymbol}
				</Text>
			</Flex>
			{amountUsd && (
				<Text fontSize={'10px'} as="span" color={'gray.400'}>
					({formatUsd(amountUsd)})
				</Text>
			)}
		</Flex>
	);
};

type ActorInfo = { address: string; logo: string; name: string };

const getTokenFlowChanges = (data: TxDetailTokenContractInteraction) => {
	return { received: data.received, sent: data.sent };
};
const getActorInfo = ({ contracts = [] }: TxDetailTokenContractInteraction): ActorInfo => {
	const { name, address, logo } = contracts[0] || {};
	return { name, address, logo };
};

const swapInfo = { icon: <FiSwapIcon width={18} />, prefix: 'via', getActorInfo, getTokenFlowChanges };

const mapRenderTxsInfo: {
	[k in TransactionType]: {
		icon: any;
		prefix?: string;
		getActorInfo: (data: TxDetail) => ActorInfo;
		getTokenFlowChanges: (data: TxDetail) =>
			| {
					sent: IBalanceChange[];
					received: IBalanceChange[];
			  }
			| { token: IBalanceChange };
	};
} = {
	[TransactionType.ContractInteraction]: {
		icon: <FiContractInteractionIcon />,
		prefix: 'via',
		getActorInfo,
		getTokenFlowChanges,
	},
	[TransactionType.Receive]: {
		icon: <FiDownLeftIcon />,
		prefix: 'from',
		getActorInfo: ({ received: [{ fromAddress, fromAvatar, fromName }] }: TxDetailTokenReceive): ActorInfo => {
			return { name: fromName, logo: fromAvatar, address: fromAddress };
		},
		getTokenFlowChanges,
	},
	[TransactionType.Send]: {
		icon: <FiUpRightIcon />,
		prefix: 'to',
		getActorInfo: ({ sent: [{ toAddress, toAvatar, toName }] }: TxDetailTokenSend): ActorInfo => {
			return { name: toName, logo: toAvatar, address: toAddress };
		},
		getTokenFlowChanges,
	},
	[TransactionType.Swap]: swapInfo,
	[TransactionType.SwapCrossChain]: swapInfo,
	[TransactionType.Approve]: {
		icon: <FiContractInteractionIcon />,
		getActorInfo: ({ spenderAddress, spenderName, spenderAvatar }: TxDetailTokenApprove): ActorInfo => {
			return { name: spenderName, logo: spenderAvatar, address: spenderAddress };
		},
		getTokenFlowChanges: (data: TxDetailTokenApprove) => ({ token: data }),
	},
};

const toTokenFlowChanges = (data: TxDetail[]) => {
	const response = data.map((el) => {
		const { getTokenFlowChanges } =
			mapRenderTxsInfo[el.type] || mapRenderTxsInfo[TransactionType.ContractInteraction];
		const response = getTokenFlowChanges(el);
		if ('chainId' in el) {
			if ('token' in response) {
				response.token.chainId = el.chainId;
			}
			if ('sent' in response) {
				response.sent?.forEach((sent) => (sent.chainId = sent.chainId || el.chainId));
			}
			if ('received' in response) {
				response.received?.forEach((received) => (received.chainId = received.chainId || el.chainId));
			}
		}

		return response;
	});

	const sent: IBalanceChange[] = response
		.map((el) => el['sent'])
		.flat()
		.filter(Boolean);
	const received: IBalanceChange[] = response
		.map((el) => el['received'])
		.flat()
		.filter(Boolean);
	const token: IBalanceChange[] = response.map((el) => el['token']).filter(Boolean);

	return { sent, received, token };
};

const TokenFlow = memo(({ data, crossChainData }: { data: TxDetail; crossChainData: TxDetail[] }) => {
	if (!data) return null;

	const { sent, received, token } = toTokenFlowChanges([data, ...(crossChainData || [])]);

	const isCrossChainTx = !!crossChainData?.length;
	const isOnlySendOrOnlyReceive = !received?.length || !sent?.length;

	if (!token && !received?.length && !sent?.length) return null;

	return (
		<>
			{token.length > 0 &&
				token.map((item) => (
					<CardPanel key={item.chainId + item.tokenAddress}>
						<TokenRender data={item} />
					</CardPanel>
				))}
			{(!!received.length || !!sent.length) && (
				<CardPanel>
					<Flex gap={'16px'} align={'flex-start'} justify={'space-around'}>
						{sent?.length > 0 && (
							<Flex
								flexDirection={'column'}
								gap={2}
								justifyContent={'center'}
								alignItems={!isOnlySendOrOnlyReceive ? 'end' : 'center'}
							>
								{sent?.map((el, i) => (
									<TokenRender
										sign="-"
										data={el}
										key={i}
										style={isOnlySendOrOnlyReceive ? undefined : { flexDirection: 'column' }}
										tokenStyle={{
											flexDirection: isOnlySendOrOnlyReceive ? 'row' : 'row-reverse',
										}}
									/>
								))}
								{isCrossChainTx && <span>(On {CHAIN_CONFIG[sent[0].chainId].name})</span>}
							</Flex>
						)}
						{!isOnlySendOrOnlyReceive && <FiArrowRightIcon width={20} />}
						{received?.length > 0 && (
							<Flex
								flexDirection={'column'}
								gap={2}
								justifyContent={'center'}
								alignItems={!isOnlySendOrOnlyReceive ? 'start' : 'center'}
							>
								{received?.map((el, i) => (
									<TokenRender
										sign="+"
										data={el}
										key={i}
										style={
											isOnlySendOrOnlyReceive
												? undefined
												: { flexDirection: 'column', alignItems: 'flex-start' }
										}
									/>
								))}
								{isCrossChainTx && <span>(On {CHAIN_CONFIG[received[0].chainId].name})</span>}
							</Flex>
						)}
					</Flex>
				</CardPanel>
			)}
		</>
	);
});

const ContractInfo = ({ data, chainId, isScam }: { data: TxDetail } & IActivity) => {
	const type = data.type;
	const { prefix, icon, getActorInfo } =
		mapRenderTxsInfo[type] || mapRenderTxsInfo[TransactionType.ContractInteraction];

	const actor = getActorInfo(data);

	return (
		<Stack direction={'row'} align={'center'} flex={1} justifyContent={'space-between'}>
			<Flex align={'center'} gap={'10px'}>
				<Avatar
					bg={icon ? 'cyan.400' : undefined}
					width={'32px'}
					height={'32px'}
					icon={icon || <AppLogoIconCircle />}
					src={''}
				/>
				<Flex
					fontWeight={'600'}
					lineHeight={'22px'}
					fontSize={'sm'}
					as={'span'}
					textTransform={'capitalize'}
					gap={2}
					flexWrap={'wrap'}
				>
					{type} {isScam && <Status status={TxStatus.Failed} title="Spam" />}
					{isTestnetChain(chainId) && <TokenLabel type="testnet" />}
				</Flex>
			</Flex>
			<Flex
				as="span"
				color={'gray.400'}
				fontSize={'12px'}
				gap={'4px'}
				align={'center'}
				flexWrap={'wrap'}
				justifyContent={'flex-end'}
			>
				{!!(actor?.address || actor?.name) && prefix}{' '}
				{actor?.logo && <Image src={actor?.logo} borderRadius={'50%'} height={'16px'} width={'16px'} />}{' '}
				<CopyToClipboard text={actor.address} copyText="Copy address" showIcon={!!actor.address}>
					{actor.name || getShortTxHash(actor.address)}
				</CopyToClipboard>
			</Flex>
		</Stack>
	);
};

export const ActivityRenderer: React.FC<{ data: IActivity }> = memo(({ data }) => {
	if (!data.details?.length) return null;

	const notApprovedDetails = data.details.findIndex((el) => el.type !== TransactionType.Approve);

	const crossChainDetails = data.crossChainTx?.details
		? data.crossChainTx?.details.map((item) => ({ ...item, chainId: data.crossChainTx.chainId }))
		: undefined;

	return data.details?.map((el, i) => {
		const info: TxDetail = { ...el, chainId: data.chainId };
		return (
			<Fragment key={i}>
				<ContractInfo {...data} data={info} />
				<TokenFlow data={info} crossChainData={i === notApprovedDetails ? crossChainDetails : undefined} />
				<CardPanel msg={data.memo} />
			</Fragment>
		);
	});
});
