import { UseMutationOptions, useMutation } from '@tanstack/react-query';
import { BotConnectorServiceAPI } from '../service';

export const useRequestInfo = (
	options?: UseMutationOptions<
		Awaited<ReturnType<typeof BotConnectorServiceAPI.query>>,
		unknown,
		Parameters<typeof BotConnectorServiceAPI.query>
	>,
) => {
	const response = useMutation<unknown, unknown, Parameters<typeof BotConnectorServiceAPI.query>>({
		mutationKey: ['query-payload'],
		mutationFn: async ([clientId, requestId, publicKey]) =>
			BotConnectorServiceAPI.query(clientId, requestId, publicKey),
		...(options || {}),
	});
	return response;
};
