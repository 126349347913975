import { get, isEmpty } from 'lodash-es';
import { TonCenterServiceAPI } from '@/app-cores/api/toncenter/service-toncenter';
import { Sourcefees } from '@/app-cores/api/toncenter/type';
import { TonWallet } from '@/app-cores/mpc-wallet/ton/TonWallet';
import { MpcWallet } from '@/app-cores/mpc-wallet/wallet';
import { isNativeTobiToken, isNativeToken } from '@/app-helpers/address';
import {
	getFinalTonGasFee,
	getTonCoinTransferBalance,
	jettonForwardPayload,
	jettonTransferBody,
} from '@/app-helpers/ton';
import { TSentTonTransaction } from '@/app-types';
import { useQuery } from '@tanstack/react-query';
import { Address, SendMode, comment, internal, toNano } from '@ton/core';
import { getTokenId } from '@/app-helpers/token';

export const estimateTonGasFee = async (sendData: TSentTonTransaction) => {
	const { contract } = await TonWallet.create('mainnet');
	const { decimals, address } = sendData.token;
	const seqno = await contract.getSeqno();
	const sendAmount = getTonCoinTransferBalance(sendData.amount, decimals);
	const walletAddress = Address.parse(MpcWallet.getTonWalletAddress());
	let body;
	if (isNativeToken(sendData.token.address)) {
		const transfer = contract.createTransfer({
			seqno: seqno,
			messages: [
				internal({
					to: sendData.to,
					value: sendAmount,
					bounce: false,
					body: comment(sendData.message || ''),
				}),
			],
		});
		const transferSigned = await contract.sign(transfer);
		body = transferSigned.toBoc().toString('base64');
	} else {
		const destinationAddress = Address.parse(sendData.to);
		const forwardPayload = jettonForwardPayload(sendData.message || '');
		const jettonWalletAddress = await TonWallet.getUserJettonWalletAddress(address);
		const internalMessage = internal({
			to: jettonWalletAddress,
			value: toNano('0.05'),
			bounce: true,
			body: await jettonTransferBody({
				jettonAmount: sendAmount, // Jetton amount (in basic indivisible units)
				toAddress: destinationAddress, // recepient user's wallet address (not Jetton wallet)
				forwardAmount: sendAmount, // some amount of TONs to invoke Transfer notification message
				forwardPayload: forwardPayload, // text comment for Transfer notification message
				responseAddress: walletAddress, // return the TONs after deducting commissions back to the sender's wallet address
			}),
		});

		const transfer = contract.createTransfer({
			seqno: seqno,
			messages: [internalMessage],
			sendMode: SendMode.PAY_GAS_SEPARATELY + SendMode.IGNORE_ERRORS,
		});
		const transferSigned = await contract.sign(transfer);
		body = transferSigned.toBoc().toString('base64');
	}
	const response = await TonCenterServiceAPI.estimateFee({
		address: walletAddress.toString({ bounceable: false }),
		body: body,
		ignore_chksig: true,
	});
	const source_fees = get(response, 'result.source_fees', {}) as Sourcefees;
	const fee = source_fees.in_fwd_fee + source_fees.storage_fee + source_fees.gas_fee + source_fees.fwd_fee;
	return fee ? getFinalTonGasFee(BigInt(fee)) : 0n;
};

export const useEstimateTonGasFee = (sendData: TSentTonTransaction) => {
	const response = useQuery({
		queryKey: ['estimate-transfer-ton-asset', sendData.to, getTokenId(sendData.token), sendData?.message],
		queryFn: () => estimateTonGasFee(sendData),
		enabled: !isEmpty(sendData.amount) && !isEmpty(sendData.to) && !isEmpty(sendData.token),
		staleTime: 1000 * 60,
		retry: 5,
	});
	return response;
};
