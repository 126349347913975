import { useEffect, useRef } from 'react';
import { useTonConnectEventsManager } from '../hooks/useTonConnectEventsManager';
import { TonConnectRequestModal } from './TonConnectRequestModal';
import { useModalStore } from '../store/ModalStore';
import { handleReturnStrategy } from '../utils/common';
import { Button, Center, Drawer, DrawerBody, DrawerContent, DrawerOverlay, Tag, Text } from '@chakra-ui/react';
import { FiCheckIcon, FiErrorIcon } from '@/assets/icons';

const CLOSE_RETURN_BUTTON_TIME = 3000;

const ReturnDrawer = () => {
	const { returnStrategyData, setReturnStrategyData } = useModalStore();
	const timerRef = useRef(null);
	useEffect(() => {
		if (returnStrategyData.isOpen) {
			timerRef.current = setTimeout(() => {
				handleReturnStrategy(returnStrategyData.returnStrategy);
				setReturnStrategyData({});
			}, CLOSE_RETURN_BUTTON_TIME);
		}

		return () => clearTimeout(timerRef.current);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [returnStrategyData.isOpen]);
	const isError = returnStrategyData?.isRejected || !!returnStrategyData.errorMessage;
	return (
		<Drawer onClose={() => {}} isOpen={!!returnStrategyData.isOpen} closeOnOverlayClick={false} placement="bottom">
			<DrawerOverlay />
			<DrawerContent borderRadius="8px 8px 0 0" pt={4} pb={2}>
				<DrawerBody gap={2} display="flex" justifyContent="center" alignItems="center" flexDirection="column">
					<Center width={14} height={14} borderRadius="100%" backgroundColor="#00E9DB">
						{isError ? <FiErrorIcon /> : <FiCheckIcon />}
					</Center>
					<Text fontWeight={600} fontSize="20px">
						{isError ? 'Something wrong!' : 'Well done!'}
					</Text>
					{isError && (
						<Tag p={2} color="gray.400">
							{returnStrategyData.isRejected
								? 'Wallet declined the request'
								: returnStrategyData.errorMessage}
						</Tag>
					)}
					<Text fontWeight={500} textAlign="center">
						Now you need to go back to the application.
					</Text>
					<Button
						onClick={() => {
							handleReturnStrategy(returnStrategyData.returnStrategy);
							setReturnStrategyData({});
						}}
						fontWeight="medium"
						size="lg"
						width="100%"
						colorScheme="cyan"
						mt={6}
					>
						Go back
					</Button>
				</DrawerBody>
			</DrawerContent>
		</Drawer>
	);
};
export const TonConnect = () => {
	useTonConnectEventsManager();
	return (
		<>
			<TonConnectRequestModal />
			<ReturnDrawer />
		</>
	);
};
