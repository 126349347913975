import React from 'react';

interface IProps extends React.SVGAttributes<HTMLOrSVGElement> {}

export const FiRocketIcon: React.FC<IProps> = ({ width = 280, height = 280, ...rest }) => {
	return (
		<svg width="280" height="280" viewBox="0 0 280 280" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M78.5012 163.062L11.4527 185.668H44.9766L19.2763 198.518L41.6248 197.121L0 240.144L69.2814 204.664L50.5646 228.13L95.8032 201.59L78.5012 163.062Z"
				fill="#EF4374"
			/>
			<path
				d="M84.4618 169.838L46.4671 177.162L60.0635 181.384L40.6943 191.255H51.6815L30.4502 214.535L60.6224 197.959L43.6753 211.184L78.8749 195.582L67.3271 214.535L91.5815 192.186L84.4618 169.838Z"
				fill="#F2955E"
			/>
			<path
				d="M210.116 62.9939C208.93 62.7358 208.764 59.2379 207.563 59.011C187.141 55.149 164.533 55.9354 141.358 65.8207C79.7526 92.1024 75.1484 146.836 75.1484 146.836L101.408 206.34C101.408 206.34 159.434 218.487 207.564 186.246C208.763 185.442 208.739 181.758 209.925 180.898L210.116 62.9939Z"
				fill="#00E9DB"
			/>
			<path
				d="M115.098 224.497L69.562 120.017C69.562 120.017 44.1394 128.118 48.608 167.229L70.6782 153.261L94.9819 216.956L73.7522 224.497C73.7522 224.497 98.3353 246.568 115.098 224.497Z"
				fill="#21222E"
			/>
			<path
				d="M266.613 83.8421C257.37 137.549 233.961 168.57 207.565 186.247V59.002C230.91 63.4174 251.404 73.9125 266.613 83.8421Z"
				fill="#21222E"
			/>
			<path
				d="M214.853 168.806C214.853 171.016 214.098 172.804 213.165 172.804C212.233 172.804 211.477 171.016 211.477 168.806C211.477 166.598 212.233 164.808 213.165 164.808C214.098 164.807 214.853 166.598 214.853 168.806Z"
				fill="#00E9DB"
			/>
			<path
				d="M214.853 138.838C214.853 141.046 214.098 142.836 213.165 142.836C212.233 142.836 211.477 141.046 211.477 138.838C211.477 136.63 212.233 134.84 213.165 134.84C214.098 134.839 214.853 136.63 214.853 138.838Z"
				fill="#00E9DB"
			/>
			<path
				d="M214.853 103.639C214.853 105.847 214.098 107.637 213.165 107.637C212.233 107.637 211.477 105.846 211.477 103.639C211.477 101.431 212.233 99.6406 213.165 99.6406C214.098 99.6406 214.853 101.432 214.853 103.639Z"
				fill="#00E9DB"
			/>
			<path
				d="M214.853 70.571C214.853 72.7766 214.098 74.567 213.165 74.567C212.233 74.567 211.477 72.776 211.477 70.571C211.477 68.3611 212.233 66.5723 213.165 66.5723C214.098 66.5723 214.853 68.3611 214.853 70.571Z"
				fill="#00E9DB"
			/>
			<path
				d="M108.231 198.593C108.231 200.256 107.578 201.601 106.776 201.601C105.97 201.601 105.32 200.256 105.32 198.593C105.32 196.931 105.97 195.582 106.776 195.582C107.578 195.582 108.231 196.93 108.231 198.593Z"
				fill="#000F47"
			/>
			<path
				d="M98.7148 175.815C98.7148 177.477 98.063 178.826 97.2596 178.826C96.4552 178.826 95.8027 177.477 95.8027 175.815C95.8027 174.152 96.4546 172.804 97.2596 172.804C98.063 172.804 98.7148 174.152 98.7148 175.815Z"
				fill="#000F47"
			/>
			<path
				d="M84.5009 142.836C84.5009 144.498 83.8501 145.847 83.0456 145.847C82.2406 145.847 81.5898 144.499 81.5898 142.836C81.5898 141.173 82.2406 139.827 83.0456 139.827C83.8501 139.827 84.5009 141.173 84.5009 142.836Z"
				fill="#000F47"
			/>
			<path
				d="M193.459 117.459C199.284 140.185 182.402 164.146 155.754 170.975C129.106 177.805 102.779 164.921 96.9548 142.197C91.1284 119.474 108.01 95.5133 134.659 88.6812C161.308 81.8513 187.634 94.7357 193.459 117.459Z"
				fill="#002F2C"
			/>
			<path
				d="M128.708 121.074C129.716 115.282 134.743 111.055 140.622 111.055H162.801C167.575 111.055 171.236 115.292 170.543 120.015L167.083 143.605C166.109 150.241 160.417 155.159 153.71 155.159H133.771C128.029 155.159 123.675 149.982 124.66 144.325L128.708 121.074Z"
				fill="#00E9DB"
			/>
			<path
				d="M125.152 117.517C126.16 111.726 131.187 107.498 137.066 107.498H160.298C164.163 107.498 167.147 110.897 166.646 114.729L163.126 141.694C162.386 147.362 157.556 151.602 151.84 151.602H130.215C124.473 151.602 120.12 146.425 121.104 140.768L125.152 117.517Z"
				fill="#002F2C"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M125.568 114.421C125.112 115.386 124.779 116.425 124.588 117.519L121.101 137.569C120.118 143.225 124.471 148.401 130.212 148.401H150.578C155.223 148.401 159.147 144.955 159.748 140.349L163.09 114.728C163.59 110.896 160.606 107.498 156.741 107.498H136.503C134.822 107.498 133.212 107.843 131.745 108.472C126.203 107.153 121.36 109.359 119.218 111.055C122.151 111.745 124.273 113.036 125.568 114.421Z"
				fill="#00E9DB"
			/>
			<path
				d="M132.733 123.149C132.733 124.327 131.778 125.283 130.599 125.283C129.421 125.283 128.465 124.327 128.465 123.149C128.465 121.97 129.421 121.015 130.599 121.015C131.778 121.015 132.733 121.97 132.733 123.149Z"
				fill="#002F2C"
			/>
			<path
				d="M158.342 123.149C158.342 124.327 157.387 125.283 156.208 125.283C155.03 125.283 154.074 124.327 154.074 123.149C154.074 121.97 155.03 121.015 156.208 121.015C157.387 121.015 158.342 121.97 158.342 123.149Z"
				fill="#002F2C"
			/>
			<path
				d="M136.723 126.322C136.35 125.9 136.678 125.281 137.241 125.281H148.857C149.421 125.281 149.748 125.9 149.375 126.322C146.039 130.102 140.06 130.102 136.723 126.322Z"
				fill="#002F2C"
			/>
			<path
				d="M151.946 131.416C148.848 134.909 149.655 138.009 150.996 139.831C151.583 140.627 152.559 140.983 153.546 141.041C157.781 141.287 164.5 141.287 166.262 141.287C168.254 141.287 169.7 143.895 170.174 144.844V132.751C168.467 130.474 166.855 130.142 166.262 130.261H154.74C153.69 130.261 152.643 130.63 151.946 131.416Z"
				fill="#002F2C"
			/>
			<path
				d="M141.398 175.041C118.828 175.041 99.662 161.764 94.7893 142.753C91.8061 131.119 94.2971 118.846 101.802 108.193C109.228 97.652 120.701 89.953 134.104 86.5176C139.022 85.2565 144.038 84.6172 149.014 84.6172C171.584 84.6172 190.751 97.8942 195.624 116.905C198.606 128.54 196.116 140.815 188.611 151.466C181.182 162.008 169.711 169.706 156.308 173.14C151.39 174.401 146.374 175.041 141.398 175.041ZM149.014 89.0868C144.413 89.0868 139.77 89.678 135.213 90.8461C122.839 94.0191 112.271 101.093 105.455 110.768C98.7164 120.332 96.466 131.297 99.1189 141.643C103.484 158.676 120.87 170.571 141.398 170.571C145.999 170.571 150.642 169.979 155.198 168.812C167.572 165.64 178.14 158.566 184.956 148.892C191.694 139.328 193.945 128.362 191.293 118.015C186.927 100.982 169.542 89.0868 149.014 89.0868Z"
				fill="#F2955E"
			/>
			<path
				d="M147.865 54.1129C147.865 54.1129 138.323 49.2856 137.144 38.6828C135.968 28.08 148.099 30.0711 148.688 39.677C148.688 39.677 154.461 30.0061 159.29 34.8754C164.121 39.7443 158.348 49.6367 147.865 54.1129Z"
				fill="#EF4374"
			/>
			<path
				d="M192.865 250.216C192.865 250.216 177.965 242.676 176.126 226.121C174.287 209.566 193.233 212.673 194.153 227.674C194.153 227.674 203.165 212.572 210.708 220.173C218.25 227.777 209.236 243.226 192.865 250.216Z"
				fill="#FFB206"
			/>
			<path
				d="M243.546 203.474C243.546 203.474 232.87 202.85 227.562 193.598C222.257 184.343 234.176 181.33 238.547 189.906C238.547 189.906 239.984 178.735 246.354 181.274C252.724 183.812 251.376 195.188 243.546 203.474Z"
				fill="#F15B85"
			/>
			<path
				d="M253.529 48.0624L246.222 44.0828C246.222 44.0828 254.106 29.7776 252.792 28.3475C251.481 26.9202 243.585 31.3608 242.721 40.4871L232.997 33.2842C232.997 33.2842 225.119 43.6912 229.442 55.6142C233.764 67.5371 245.45 52.9733 245.45 52.9733L253.16 53.5765L253.529 48.0624Z"
				fill="#00E9DB"
			/>
			<path d="M255.449 58.0759L245.81 55.9245L248.884 43.2676L255.449 46.3416V58.0759Z" fill="#000F47" />
			<path
				d="M153.716 227.34L146.909 227.895C146.909 227.895 145.885 214.526 144.34 214.135C142.796 213.746 139.373 220.348 142.887 227.001L132.967 226.433C132.967 226.433 132.242 237.121 140.575 243.367C148.908 249.608 150.378 234.348 150.378 234.348L155.943 231.291L153.716 227.34Z"
				fill="#000F47"
			/>
			<path d="M159.541 233.353L151.956 236.21L148.369 226.139L154.261 225.296L159.541 233.353Z" fill="#00E9DB" />
			<path
				d="M259.888 154.569C259.888 157.023 257.761 159.014 255.137 159.014C252.514 159.014 250.388 157.023 250.388 154.569C250.388 152.114 252.514 150.123 255.137 150.123C257.761 150.123 259.888 152.114 259.888 154.569Z"
				fill="#000F47"
			/>
			<path
				d="M269.385 135.781C269.385 138.235 267.258 140.226 264.635 140.226C262.012 140.226 259.888 138.235 259.888 135.781C259.888 133.323 262.012 131.335 264.635 131.335C267.258 131.335 269.385 133.323 269.385 135.781Z"
				fill="#000F47"
			/>
			<path
				d="M280 152.246C280 154.701 277.875 156.692 275.252 156.692C272.629 156.692 270.502 154.701 270.502 152.246C270.502 149.79 272.629 147.801 275.252 147.801C277.875 147.801 280 149.79 280 152.246Z"
				fill="#000F47"
			/>
			<path
				d="M263.613 134.344L254.113 156.242L256.163 157.132L265.664 135.233L263.613 134.344Z"
				fill="#000F47"
			/>
			<path
				d="M273.836 149.464L255.178 154.828L255.795 156.975L274.453 151.611L273.836 149.464Z"
				fill="#000F47"
			/>
			<path
				d="M80.9513 73.3712C78.8578 74.6564 76.0502 73.8836 74.6803 71.6458C73.3087 69.4124 73.8933 66.5599 75.9867 65.2769C78.0802 63.989 80.8889 64.7645 82.2594 67.0001C83.6293 69.2357 83.0453 72.0882 80.9513 73.3712Z"
				fill="#FFB206"
			/>
			<path
				d="M101.935 71.6479C99.8411 72.9309 97.0323 72.1603 95.6624 69.9225C94.2908 67.6869 94.8765 64.8344 96.9689 63.5514C99.0623 62.2684 101.871 63.0417 103.241 65.2768C104.615 67.5124 104.027 70.3649 101.935 71.6479Z"
				fill="#FFB206"
			/>
			<path
				d="M93.4456 89.3041C91.3522 90.5898 88.5424 89.8188 87.1719 87.5809C85.8003 85.3453 86.3871 82.4906 88.4784 81.2093C90.574 79.9263 93.3817 80.6969 94.7532 82.9325C96.1248 85.1709 95.538 88.0212 93.4456 89.3041Z"
				fill="#FFB206"
			/>
			<path d="M100.136 65.9756L76.502 69.3223L76.8152 71.5347L100.449 68.188L100.136 65.9756Z" fill="#FFB206" />
			<path d="M78.256 69.485L76.7471 71.1338L91.0676 84.2391L92.5765 82.5903L78.256 69.485Z" fill="#FFB206" />
		</svg>
	);
};
