export interface ITelifiError {
	name: string;
	code: number;
	message: string;
	toString(): string;
}

export type ErrorCodes = {
	[key: number]: string;
};

export class TelifiError implements ITelifiError {
	name: string;

	code: number;

	message: string;

	public constructor(code?: number, message?: string) {
		this.code = code ?? -1;
		this.message = message || '';

		// Set name explicitly as minification can mangle class names
		Object.defineProperty(this, 'name', { value: 'TelifiError' });
	}

	toJSON(): ITelifiError {
		return {
			name: this.name,
			code: this.code,
			message: this.message,
		};
	}

	toString(): string {
		return JSON.stringify(this.toJSON());
	}
}
