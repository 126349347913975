import React from 'react';

interface IProps extends React.SVGAttributes<HTMLOrSVGElement> {}

export const FiContractInteractionIcon: React.FC<IProps> = ({ width = 40, height = 40, ...rest }) => {
	return (
		<svg width={width} height={height} viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M39.4013 24.841C36.7312 35.5548 25.8775 42.075 15.1605 39.4016C4.44342 36.7281 -2.07348 25.8794 0.599946 15.164C3.27337 4.45032 14.1221 -2.07324 24.8375 0.600185C35.5512 3.27361 42.0748 14.124 39.4013 24.841Z"
				fill="black"
				fillOpacity="0.08"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M18.7519 11.1689C19.1315 10.9501 19.5619 10.835 20.0001 10.835C20.4383 10.835 20.8687 10.9501 21.2483 11.1689C21.2489 11.1692 21.2495 11.1696 21.2501 11.1699L27.0834 14.5032C27.4631 14.7224 27.7784 15.0376 27.9978 15.4172C28.2172 15.7967 28.333 16.2273 28.3334 16.6657V23.3341C28.333 23.7725 28.2172 24.2031 27.9978 24.5827C27.7784 24.9622 27.4631 25.2774 27.0834 25.4966L27.0802 25.4985L21.2501 28.8299C21.2496 28.8302 21.2491 28.8305 21.2486 28.8308C20.8689 29.0497 20.4383 29.1649 20.0001 29.1649C19.5618 29.1649 19.1313 29.0497 18.7516 28.8308C18.7511 28.8305 18.7506 28.8302 18.7501 28.8299L12.92 25.4985L12.9167 25.4966C12.5371 25.2774 12.2217 24.9622 12.0023 24.5827C11.7829 24.2031 11.6672 23.7725 11.6667 23.3341V16.6657C11.6672 16.2273 11.7829 15.7967 12.0023 15.4172C12.2217 15.0376 12.5371 14.7224 12.9167 14.5032L12.92 14.5014L18.7519 11.1689ZM20.0001 12.5016C19.8538 12.5016 19.7101 12.5401 19.5834 12.6133L19.5802 12.6151L13.7501 15.9466C13.7496 15.9469 13.7492 15.9471 13.7488 15.9474C13.6228 16.0204 13.5182 16.1252 13.4453 16.2513C13.3722 16.3777 13.3336 16.5211 13.3334 16.6671V23.3328C13.3336 23.4788 13.3722 23.6222 13.4453 23.7486C13.5182 23.8747 13.6228 23.9794 13.7488 24.0525C13.7492 24.0527 13.7496 24.053 13.7501 24.0532L19.5834 27.3866C19.7101 27.4597 19.8538 27.4982 20.0001 27.4982C20.1464 27.4982 20.2901 27.4597 20.4167 27.3866L20.42 27.3847L26.2501 24.0532C26.2505 24.053 26.251 24.0527 26.2514 24.0525C26.3774 23.9794 26.482 23.8747 26.5549 23.7486C26.628 23.6221 26.6666 23.4785 26.6667 23.3324V16.6674C26.6666 16.5213 26.628 16.3778 26.5549 16.2513C26.482 16.1252 26.3774 16.0204 26.2514 15.9474C26.251 15.9471 26.2505 15.9469 26.2501 15.9466L20.4167 12.6133C20.2901 12.5401 20.1464 12.5016 20.0001 12.5016Z"
				fill="black"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M12.0038 15.383C12.2342 14.9846 12.744 14.8485 13.1424 15.0789L20.0001 19.0459L26.8578 15.0789C27.2562 14.8485 27.766 14.9846 27.9964 15.383C28.2269 15.7814 28.0908 16.2912 27.6924 16.5216L20.4174 20.7299C20.1592 20.8793 19.841 20.8793 19.5828 20.7299L12.3078 16.5216C11.9094 16.2912 11.7733 15.7814 12.0038 15.383Z"
				fill="black"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M20.0001 19.167C20.4603 19.167 20.8334 19.5401 20.8334 20.0003V28.4003C20.8334 28.8606 20.4603 29.2337 20.0001 29.2337C19.5398 29.2337 19.1667 28.8606 19.1667 28.4003V20.0003C19.1667 19.5401 19.5398 19.167 20.0001 19.167Z"
				fill="black"
			/>
		</svg>
	);
};
