import { uniqueId } from '@/app-helpers/random';
import { BuildRouteDataKyber, GetRouteDataKyber, getExtractRouteKyberSwap } from '@/app-hooks/swap/kyberswap';
import { ArgsGetRoute, ExtractRouteInfo, SwapAbstract, SwapProvider, UsdRouteInfo } from '@/app-hooks/swap/type';
import { SelectedRoute } from '@/app-store/swap';

const formatRoute = (routeData: GetRouteDataKyber, params: ArgsGetRoute): SelectedRoute =>
	routeData
		? {
				...routeData,
				route: routeData,
				id: uniqueId(),
				provider: SwapProvider.CONTRACT,
				timestamp: Date.now(),
				params,
		  }
		: undefined;

const getRouteWrap = (params: ArgsGetRoute) => {
	const { amountIn, tokenOut, tokenIn } = params;
	return !amountIn || amountIn === '0' || !tokenOut || !tokenIn
		? undefined
		: {
				routerAddress: '',
				routeSummary: { amountIn, amountOut: amountIn } as any,
				tokenIn,
				tokenOut,
		  };
};

class Contract extends SwapAbstract<GetRouteDataKyber> {
	extractRoute(
		params: SelectedRoute<BuildRouteDataKyber | GetRouteDataKyber>,
		prices: UsdRouteInfo,
	): ExtractRouteInfo {
		return getExtractRouteKyberSwap(params, prices);
	}
	async getRoute(paramsSwap: ArgsGetRoute) {
		return formatRoute(getRouteWrap(paramsSwap), paramsSwap);
	}
}

export const ContractSwap = new Contract();
