import { formatCurrency, formatUsd } from '@/app-helpers/number';
import { useTokenPriceSingleToken } from '@/app-hooks/api/portfolio/useTokenPrices';
import { Box, Flex, Image, Text } from '@chakra-ui/react';
import BigNumber from 'bignumber.js';

interface SwapTokenAmountProps {
	image: string;
	symbol: string;
	amount: number | string;
	decimals: number;
	type: 'IN' | 'OUT';
	layout?: 'full' | 'compact';
	dollarValuePerToken?: number;
	address?: string;
	chainId?: string;
}
export const SwapTokenAmount: React.FunctionComponent<SwapTokenAmountProps> = ({
	image,
	dollarValuePerToken,
	symbol,
	chainId,
	address,
	type,
	decimals,
	amount,
}) => {
	// const { data: tokenPrice } = useTokenPriceSingleToken({
	// 	chainId: chainId,
	// 	address: address,
	// });
	return (
		<Flex gap={2}>
			<Box>
				<Text fontSize="xs" fontWeight={600} color={type === 'IN' ? 'green' : 'red'}>
					{type === 'IN' ? '+' : '-'}
					{formatCurrency(
						BigNumber(amount)
							.div(10 ** decimals)
							.toString(),
					)}{' '}
					{symbol}
				</Text>
				{/* {tokenPrice && (
					<Text fontSize="smaller" color="gray.400">
						≈
						{formatUsd(
							(+BigNumber(amount)
								.div(10 ** decimals)
								.toNumber() || 0) * (dollarValuePerToken || tokenPrice),
						)}
					</Text>
				)} */}
			</Box>
			<Image borderRadius="100%" src={image} width={6} height={6}></Image>
		</Flex>
	);
};
