import { Flex, Image, Text } from '@chakra-ui/react';

interface NoDataProps extends React.PropsWithChildren {
	msg: string;
}
export default function NoData({ msg, children }: NoDataProps) {
	return (
		<Flex justifyContent={'center'} mt={3} flexDirection={'column'} alignItems={'center'} gap={'4px'}>
			<Image src="/assets/images/no-data.svg" height={'64px'} />
			<Text fontSize={'small'}>{msg}</Text>
			{children}
		</Flex>
	);
}
