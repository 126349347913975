import { memo, useEffect, useState } from 'react';
import { StepContent } from './StepContent';
import { TOUR_GUIDE_STEPS_TARGET } from './stepConfigs';
import { tourGuideManager } from './helper';
import { Step, TobiTourGuide } from './TourGuide';

const steps: Step[] = [
	{
		target: TOUR_GUIDE_STEPS_TARGET.TRADE.FROM,
		content: <StepContent title="tourGuide.trade.step1.title" description="tourGuide.trade.step1.description" />,
		offset: {
			left: 30,
			top: 90,
		},
	},
	{
		target: TOUR_GUIDE_STEPS_TARGET.TRADE.TO,
		content: <StepContent title="tourGuide.trade.step2.title" description="tourGuide.trade.step2.description" />,
		offset: {
			left: 30,
			top: 90,
		},
	},
	{
		target: TOUR_GUIDE_STEPS_TARGET.TRADE.AMOUNT,
		content: <StepContent title="tourGuide.trade.step3.title" description="tourGuide.trade.step3.description" />,
		offset: {
			left: 30,
			top: 105,
		},
	},
	{
		target: TOUR_GUIDE_STEPS_TARGET.TRADE.ROUTE,
		content: <StepContent title="tourGuide.trade.step4.title" description="tourGuide.trade.step4.description" />,
		offset: {
			left: 10,
			top: -55,
		},
		placement: 'top',
	},
	{
		target: TOUR_GUIDE_STEPS_TARGET.TRADE.SWAP,
		content: <StepContent title="tourGuide.trade.step5.title" description="tourGuide.trade.step5.description" />,
		offset: {
			left: 10,
			top: -55,
		},
	},
	{
		target: TOUR_GUIDE_STEPS_TARGET.TRADE.BUY,
		content: <StepContent title="tourGuide.trade.step6.title" description="tourGuide.trade.step6.description" />,
		offset: {
			left: -10,
			top: -55,
		},
	},
];

const TourGuide = () => {
	const [run, setRun] = useState(false);

	useEffect(() => {
		setTimeout(() => {
			setRun(true);
		}, 500);
	}, []);

	return (
		<TobiTourGuide
			run={run}
			steps={steps}
			onComplete={() => {
				tourGuideManager.disableTradePage();
				setRun(false);
			}}
		/>
	);
};
export const TradeTourGuide = memo(TourGuide);
