import { Button } from '@chakra-ui/react';
import { useScanWC } from '../hooks';
import { useTranslation } from 'react-i18next';
import { useBackButton } from '@/app-store';

interface ConnectWithQrCodeProps {
	onClick: () => void;
}
export const ConnectWithQrCode = ({ onClick }) => {
	const { onScan, setOpen, setOnScan } = useScanWC();
	const { setShow: setShowBackButton } = useBackButton();

	const { t } = useTranslation();
	return (
		<Button
			size="lg"
			fontSize="sm"
			width="100%"
			colorScheme="gray"
			onClick={() => {
				setOpen(true);
				setOnScan(onScan);
				onClick();
				setShowBackButton({
					isShow: true,
					backButtonCallback: () => setOpen(false),
					skipNavigation: true,
				});
			}}
		>
			{t('walletConnect.scanQRCode')}
		</Button>
	);
};
