import { Box, Drawer, DrawerBody, DrawerCloseButton, DrawerContent, DrawerOverlay } from '@chakra-ui/react';
import { Loading } from '@/app-components/common';
import { useModalStore } from '../store/ModalStore';
import { QrReader } from '../components';
import { useQrCodeReaderStore } from '@/app-store';
import { BackButton } from '@vkruglikov/react-telegram-web-app';

interface ScanQrCodePopupProps {}
export const ScanQrCodePopup: React.FC<ScanQrCodePopupProps> = () => {
	const { isOpen, onScan, setOpen } = useQrCodeReaderStore();
	const { isWaiting } = useModalStore();
	const { pathname } = window.location;

	return (
		<Drawer isOpen={isOpen} size="full" placement="bottom" onClose={() => setOpen(false)} trapFocus={false}>
			<DrawerOverlay />
			{isOpen && (
				<DrawerContent backgroundColor="black">
					<DrawerCloseButton cursor="pointer" color="white" />
					<DrawerBody mt={12}>
						<Box>
							{isWaiting && <Loading opacity="0.8" />}
							<QrReader onScan={onScan} />
						</Box>
					</DrawerBody>
					{pathname === '/' && <BackButton onClick={() => setOpen(false)} />}
				</DrawerContent>
			)}
		</Drawer>
	);
};
