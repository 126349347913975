import ReactDOM from 'react-dom/client';
import { ErrorBoundary } from 'react-error-boundary';
import { ChakraProvider } from '@chakra-ui/react';
import App from './App.tsx';
import 'react-toastify/dist/ReactToastify.css';
import { Buffer } from 'buffer';
import '@/app-services/monitor/datadog';
import { theme } from './app-theme/theme.ts';
import 'public/assets/styles/app.scss';
import 'public/assets/styles/custom.scss';
import { Fallback } from './app-components/layout/Fallback';

globalThis.Buffer = Buffer;

ReactDOM.createRoot(document.getElementById('root')!).render(
	<ErrorBoundary FallbackComponent={Fallback}>
		<ChakraProvider theme={theme}>
			<App />
		</ChakraProvider>
	</ErrorBoundary>,
);
