import React from 'react';

interface IProps extends React.SVGAttributes<HTMLOrSVGElement> {}

export const FiCommingSoonIcon: React.FC<IProps> = ({ width = 67, height = 67, ...rest }) => {
	return (
		<svg width="67" height="67" viewBox="0 0 67 67" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path d="M0.25 0H67.25V67L34.25 33L0.25 0Z" fill="#001716" />
			<path d="M0.25 0H67.25V67L34.25 33L0.25 0Z" fill="url(#paint0_radial_13661_22109)" fillOpacity="0.9" />
			<path d="M0.25 0H67.25V67L34.25 33L0.25 0Z" fill="url(#paint1_radial_13661_22109)" fillOpacity="0.85" />
			<path d="M0.25 0H67.25V67L34.25 33L0.25 0Z" fill="url(#paint2_radial_13661_22109)" fillOpacity="0.8" />
			<path d="M0.25 0H67.25V67L34.25 33L0.25 0Z" fill="#0B373D" />
			<path
				d="M57.7608 30.7045C57.4915 30.4352 57.2947 30.1617 57.1704 29.8841C57.0441 29.6086 56.9902 29.3414 57.0088 29.0824C57.0275 28.8276 57.1135 28.5946 57.2668 28.3833L57.2761 28.3864L57.8354 28.9457V28.9519C57.738 29.1073 57.7215 29.2979 57.7857 29.5237C57.8478 29.7516 57.9991 29.9857 58.2394 30.226C58.5398 30.5263 58.8422 30.6921 59.1467 30.7231C59.4513 30.7584 59.7268 30.6527 59.9733 30.4062L60.6507 29.7288L60.601 29.6791C60.4083 29.7309 60.2157 29.7474 60.023 29.7288C59.8304 29.7101 59.645 29.6573 59.4668 29.5703C59.2907 29.4812 59.126 29.36 58.9727 29.2067C58.6848 28.9188 58.5035 28.6008 58.4289 28.2528C58.3585 27.9047 58.3875 27.5526 58.5159 27.1962C58.6444 26.8399 58.865 26.5054 59.1778 26.1926L59.184 26.1863C59.4968 25.8735 59.8314 25.6529 60.1877 25.5245C60.5482 25.396 60.9035 25.3681 61.2536 25.4406C61.6057 25.511 61.9279 25.6923 62.22 25.9844C62.3774 26.1418 62.4975 26.3117 62.5804 26.494C62.6633 26.6763 62.7099 26.8638 62.7202 27.0564C62.7327 27.2511 62.7099 27.4459 62.6519 27.6406L62.6892 27.6779L63.2423 27.1248L63.783 27.6655L60.4798 30.9686C60.2312 31.2172 59.9536 31.3726 59.647 31.4347C59.3425 31.499 59.0287 31.4689 58.7055 31.3446C58.3802 31.2224 58.0654 31.009 57.7608 30.7045ZM59.5693 28.8463C59.7765 29.0534 60.0013 29.1829 60.2436 29.2347C60.4902 29.2865 60.7377 29.2668 60.9863 29.1757C61.237 29.0866 61.4731 28.9312 61.6948 28.7096L61.701 28.7033C61.9227 28.4817 62.0791 28.2445 62.1702 27.9917C62.2635 27.7411 62.2842 27.4925 62.2324 27.246C62.1827 27.0015 62.0542 26.7757 61.8471 26.5686C61.6399 26.3614 61.4151 26.234 61.1728 26.1863C60.9325 26.1408 60.688 26.1656 60.4394 26.2609C60.1929 26.3583 59.9588 26.5178 59.7371 26.7395L59.7309 26.7457C59.5093 26.9673 59.3508 27.2004 59.2555 27.4448C59.1623 27.6914 59.1384 27.9348 59.184 28.1751C59.2337 28.4154 59.3622 28.6391 59.5693 28.8463Z"
				fill="white"
			/>
			<path
				d="M53.8456 24.4284L57.1954 21.0786L57.736 21.6193L57.2326 22.1227L57.2824 22.1724C57.5455 22.0792 57.8054 22.0636 58.0623 22.1258C58.3213 22.1858 58.574 22.3391 58.8205 22.5857C59.1976 22.9627 59.3788 23.3594 59.3643 23.7758C59.354 24.1922 59.1499 24.5993 58.7522 24.997L56.5832 27.166L56.0425 26.6253L58.081 24.5868C58.3834 24.2844 58.5429 23.9964 58.5595 23.723C58.5802 23.4495 58.457 23.1792 58.1897 22.9119C58.0116 22.7338 57.8189 22.6178 57.6117 22.5639C57.4046 22.51 57.1943 22.5173 56.9809 22.5857C56.7696 22.6561 56.5656 22.7897 56.3688 22.9865L54.3863 24.969L53.8456 24.4284Z"
				fill="white"
			/>
			<path
				d="M52.2855 22.8677L55.6353 19.5179L56.176 20.0586L52.8262 23.4084L52.2855 22.8677ZM56.5551 19.145C56.4536 19.0435 56.4018 18.9192 56.3997 18.7721C56.4018 18.6251 56.4536 18.5008 56.5551 18.3993C56.6587 18.2957 56.783 18.2439 56.928 18.2439C57.0751 18.246 57.1994 18.2977 57.3009 18.3993C57.4044 18.5028 57.4562 18.6271 57.4562 18.7721C57.4562 18.9172 57.4044 19.0415 57.3009 19.145C57.1994 19.2465 57.0751 19.2983 56.928 19.3004C56.783 19.3004 56.6587 19.2486 56.5551 19.145Z"
				fill="white"
			/>
			<path
				d="M46.7357 17.318L50.0855 13.9682L50.6262 14.5089L50.1166 15.0185L50.1663 15.0682C50.4211 14.9626 50.6749 14.9367 50.9276 14.9906C51.1824 15.0465 51.4196 15.1843 51.6392 15.4038C51.7904 15.5551 51.9012 15.7156 51.9717 15.8855C52.0421 16.0554 52.0763 16.2283 52.0742 16.4044C52.0742 16.5784 52.0421 16.7493 51.9779 16.9171L52.0276 16.9669C52.2058 16.9089 52.3891 16.8871 52.5776 16.9016C52.7682 16.914 52.9536 16.9627 53.1339 17.0477C53.3182 17.1326 53.4871 17.2517 53.6404 17.405C53.8682 17.6329 54.0184 17.868 54.0909 18.1104C54.1655 18.3548 54.1624 18.6003 54.0816 18.8468C54.0029 19.0913 53.8465 19.3306 53.6124 19.5647L51.2974 21.8797L50.7567 21.339L52.9474 19.1483C53.1131 18.9825 53.224 18.8199 53.2799 18.6604C53.34 18.5009 53.3452 18.3424 53.2954 18.185C53.2478 18.0255 53.1452 17.867 52.9878 17.7095C52.8324 17.5542 52.6615 17.4537 52.4751 17.4081C52.2886 17.3625 52.1043 17.3667 51.922 17.4205C51.7417 17.4723 51.5802 17.5697 51.4372 17.7126L49.2838 19.8661L48.7431 19.3254L51.0177 17.0508C51.1503 16.9182 51.2363 16.7742 51.2756 16.6188C51.3191 16.4635 51.315 16.306 51.2632 16.1465C51.2135 15.9891 51.1182 15.8399 50.9773 15.699C50.8323 15.554 50.6624 15.4608 50.4677 15.4194C50.275 15.38 50.0793 15.3914 49.8804 15.4536C49.6857 15.5157 49.5096 15.6255 49.3521 15.7829L47.2764 17.8587L46.7357 17.318Z"
				fill="white"
			/>
			<path
				d="M44.2933 14.9937C43.9764 14.6767 43.7713 14.3328 43.678 13.962C43.589 13.5912 43.6107 13.2131 43.7433 12.8278C43.878 12.4446 44.1234 12.0748 44.4798 11.7185L44.486 11.7123C44.8444 11.3539 45.2152 11.1074 45.5984 10.9727C45.9837 10.8401 46.3608 10.8194 46.7295 10.9106C47.1003 11.0038 47.4442 11.2089 47.7612 11.5258C48.0802 11.8449 48.2853 12.1887 48.3764 12.5575C48.4676 12.9262 48.4458 13.3022 48.3112 13.6855C48.1786 14.0708 47.9331 14.4426 47.5747 14.801L47.5685 14.8072C47.2122 15.1636 46.8414 15.408 46.4561 15.5406C46.0728 15.6752 45.6958 15.698 45.325 15.609C44.9562 15.5178 44.6123 15.3127 44.2933 14.9937ZM44.7719 14.5151C44.9832 14.7264 45.2121 14.8559 45.4586 14.9036C45.7051 14.9512 45.9599 14.9201 46.223 14.8103C46.4882 14.7026 46.7523 14.5172 47.0154 14.2541L47.0216 14.2479C47.2868 13.9827 47.4732 13.7176 47.5809 13.4524C47.6907 13.1893 47.7208 12.9355 47.6711 12.6911C47.6234 12.4446 47.4939 12.2157 47.2826 12.0044C47.0713 11.7931 46.8424 11.6636 46.5959 11.6159C46.3515 11.5662 46.0966 11.5952 45.8315 11.7029C45.5684 11.8127 45.3043 12.0002 45.0391 12.2654L45.0329 12.2716C44.7698 12.5347 44.5833 12.7978 44.4735 13.0609C44.3658 13.326 44.3358 13.5819 44.3834 13.8284C44.4311 14.0749 44.5606 14.3038 44.7719 14.5151Z"
				fill="white"
			/>
			<path
				d="M40.1884 10.9823C39.7824 10.5763 39.5266 10.1299 39.4209 9.64303C39.3152 9.1562 39.3567 8.65695 39.5452 8.14526C39.7358 7.63565 40.0714 7.14053 40.552 6.65992L40.5582 6.65371C41.0409 6.17102 41.537 5.83439 42.0467 5.6438C42.5583 5.45528 43.0566 5.41282 43.5413 5.5164C44.0281 5.62205 44.4746 5.87789 44.8806 6.28393C45.1955 6.59881 45.4192 6.94684 45.5518 7.32801C45.6885 7.70919 45.731 8.09347 45.6792 8.48086C45.6295 8.86618 45.4834 9.22353 45.2411 9.55291L45.2317 9.55602L44.6693 8.99358L44.6724 8.98426C44.8153 8.74188 44.8972 8.49225 44.9179 8.23537C44.9407 7.97642 44.9055 7.72369 44.8122 7.47717C44.7211 7.23272 44.5719 7.00691 44.3648 6.79975C44.0789 6.51387 43.7557 6.33986 43.3953 6.27771C43.0369 6.21763 42.664 6.26735 42.2766 6.42687C41.8892 6.58638 41.508 6.85362 41.1331 7.22858L41.1269 7.23479C40.754 7.60768 40.4878 7.98782 40.3283 8.37521C40.1687 8.7626 40.119 9.13549 40.1791 9.49387C40.2413 9.85433 40.4163 10.1785 40.7043 10.4665C40.9135 10.6757 41.1362 10.8321 41.3723 10.9357C41.6106 11.0372 41.8499 11.0838 42.0902 11.0755C42.3325 11.0652 42.5625 10.9968 42.78 10.8705H42.7924L43.358 11.436L43.3518 11.4422C43.0286 11.6659 42.6826 11.7965 42.3139 11.8337C41.9472 11.869 41.5805 11.8151 41.2139 11.6722C40.8493 11.5313 40.5075 11.3013 40.1884 10.9823Z"
				fill="white"
			/>
			<path
				d="M47.7941 35.8302L53.7727 29.8516L54.8168 30.8956L53.6443 38.225L53.2134 36.9074L53.8349 37.5289L57.6425 33.7213L58.8398 34.9187L52.8612 40.8973L51.8254 39.8615L53.0062 32.5074L53.4412 33.829L52.8156 33.2034L48.9914 37.0276L47.7941 35.8302Z"
				fill="white"
			/>
			<path
				d="M43.5762 31.9187C42.9851 31.3276 42.6012 30.6895 42.4244 30.0045C42.2504 29.3167 42.2808 28.6207 42.5156 27.9163C42.7531 27.2148 43.1937 26.5422 43.8372 25.8986L43.8455 25.8903C44.4919 25.244 45.1658 24.8048 45.8674 24.5728C46.5745 24.3408 47.2706 24.3131 47.9556 24.4899C48.6406 24.6667 49.2759 25.0479 49.8615 25.6334C50.4498 26.2218 50.831 26.8571 51.005 27.5393C51.1818 28.2243 51.1528 28.919 50.918 29.6233C50.6832 30.3277 50.244 31.0016 49.6004 31.6452L49.5922 31.6535C48.9486 32.2971 48.2746 32.7363 47.5703 32.971C46.8687 33.2086 46.174 33.2404 45.4862 33.0663C44.8012 32.8896 44.1646 32.507 43.5762 31.9187ZM44.6369 30.858C44.9601 31.1812 45.3274 31.3773 45.739 31.4463C46.1505 31.5154 46.5773 31.4588 47.0192 31.2765C47.4639 31.0969 47.8962 30.7972 48.316 30.3774L48.3243 30.3691C48.7469 29.9465 49.0466 29.5142 49.2234 29.0723C49.4057 28.6304 49.461 28.205 49.3891 27.7962C49.3201 27.3846 49.124 27.0173 48.8008 26.6941C48.4776 26.3709 48.1103 26.1748 47.6987 26.1058C47.2899 26.0339 46.8632 26.0878 46.4185 26.2673C45.9765 26.4441 45.5442 26.7438 45.1216 27.1664L45.1133 27.1747C44.6935 27.5945 44.3924 28.0254 44.2101 28.4674C44.0306 28.9121 43.974 29.3388 44.0403 29.7476C44.1093 30.1592 44.3082 30.5293 44.6369 30.858Z"
				fill="white"
			/>
			<path
				d="M36.8973 25.24C36.3062 24.6489 35.9222 24.0108 35.7455 23.3258C35.5714 22.638 35.6018 21.942 35.8366 21.2376C36.0741 20.536 36.5147 19.8635 37.1583 19.2199L37.1666 19.2116C37.8129 18.5653 38.4869 18.1261 39.1885 17.8941C39.8956 17.662 40.5916 17.6344 41.2766 17.8112C41.9616 17.988 42.5969 18.3691 43.1825 18.9547C43.7708 19.5431 44.152 20.1783 44.326 20.8606C44.5028 21.5456 44.4738 22.2403 44.239 22.9446C44.0042 23.649 43.5651 24.3229 42.9215 24.9665L42.9132 24.9748C42.2696 25.6184 41.5957 26.0576 40.8913 26.2923C40.1897 26.5299 39.4951 26.5616 38.8073 26.3876C38.1223 26.2109 37.4856 25.8283 36.8973 25.24ZM37.9579 24.1793C38.2811 24.5025 38.6485 24.6986 39.06 24.7676C39.4716 24.8367 39.8983 24.7801 40.3403 24.5978C40.785 24.4182 41.2172 24.1185 41.6371 23.6987L41.6454 23.6904C42.068 23.2678 42.3677 22.8355 42.5445 22.3936C42.7268 21.9516 42.782 21.5263 42.7102 21.1175C42.6411 20.7059 42.445 20.3386 42.1218 20.0154C41.7987 19.6922 41.4313 19.4961 41.0198 19.427C40.611 19.3552 40.1842 19.4091 39.7395 19.5886C39.2976 19.7654 38.8653 20.0651 38.4427 20.4877L38.4344 20.496C38.0145 20.9158 37.7135 21.3467 37.5312 21.7887C37.3516 22.2334 37.295 22.6601 37.3613 23.0689C37.4304 23.4805 37.6292 23.8506 37.9579 24.1793Z"
				fill="white"
			/>
			<path
				d="M30.6867 19.0294C30.2116 18.5543 29.8704 18.0695 29.6633 17.5751C29.4561 17.0807 29.3815 16.6028 29.4396 16.1415C29.5031 15.6803 29.6992 15.2604 30.0279 14.882L30.09 14.8116L31.2957 16.0172L31.2584 16.0628C31.1258 16.2285 31.0554 16.4205 31.0471 16.6387C31.0444 16.8569 31.101 17.0876 31.217 17.3306C31.333 17.5737 31.507 17.8113 31.739 18.0433C31.9628 18.267 32.192 18.43 32.4268 18.5322C32.6616 18.6344 32.8839 18.6744 33.0939 18.6523C33.3093 18.6302 33.493 18.5432 33.6449 18.3913L33.649 18.3872C33.8452 18.191 33.9225 17.9535 33.8811 17.6745C33.8424 17.3983 33.6753 17.0351 33.3797 16.5849L32.8784 15.8267C32.384 15.0781 32.1409 14.418 32.1492 13.8462C32.1575 13.2744 32.3881 12.7621 32.8411 12.3091L32.8453 12.3049C33.2154 11.9348 33.6366 11.7097 34.1089 11.6296C34.5813 11.5495 35.0646 11.6047 35.5591 11.7953C36.0563 11.9831 36.5258 12.298 36.9678 12.74C37.4318 13.204 37.7536 13.6777 37.9331 14.1611C38.1182 14.6445 38.1734 15.1113 38.0989 15.5615C38.027 16.009 37.8364 16.4122 37.5271 16.7713L37.4774 16.8293L36.3007 15.6526L36.3421 15.5946C36.4609 15.4261 36.5258 15.2397 36.5369 15.0353C36.5507 14.8337 36.5079 14.6224 36.4084 14.4014C36.3145 14.1804 36.1626 13.965 35.9527 13.7551C35.7483 13.5507 35.5384 13.4015 35.3229 13.3076C35.1075 13.2137 34.9017 13.1792 34.7056 13.204C34.5122 13.2261 34.341 13.3117 34.1918 13.4609L34.1877 13.465C33.9943 13.6584 33.9128 13.8945 33.9432 14.1735C33.9791 14.4525 34.1366 14.8033 34.4155 15.2259L34.921 15.9882C35.258 16.4965 35.4859 16.9564 35.6046 17.3679C35.7262 17.7823 35.74 18.1634 35.6461 18.5115C35.5522 18.8595 35.3491 19.1896 35.037 19.5017L35.0329 19.5058C34.6379 19.9008 34.1973 20.1453 33.7112 20.2392C33.2306 20.3331 32.7293 20.2792 32.2072 20.0776C31.6907 19.876 31.1838 19.5265 30.6867 19.0294Z"
				fill="white"
			/>
			<defs>
				<radialGradient
					id="paint0_radial_13661_22109"
					cx="0"
					cy="0"
					r="1"
					gradientUnits="userSpaceOnUse"
					gradientTransform="translate(22.8477 11.0016) rotate(63.1327) scale(25.2208 29.9186)"
				>
					<stop stopColor="#7D7194" />
					<stop offset="1" stopColor="#7D7194" stopOpacity="0" />
				</radialGradient>
				<radialGradient
					id="paint1_radial_13661_22109"
					cx="0"
					cy="0"
					r="1"
					gradientUnits="userSpaceOnUse"
					gradientTransform="translate(65.4662 22.6969) rotate(161.19) scale(33.5056)"
				>
					<stop stopColor="#4A8F9F" />
					<stop offset="1" stopColor="#4A8F9F" stopOpacity="0" />
				</radialGradient>
				<radialGradient
					id="paint2_radial_13661_22109"
					cx="0"
					cy="0"
					r="1"
					gradientUnits="userSpaceOnUse"
					gradientTransform="translate(44.8507 65.2163) rotate(-108.939) scale(21.3762)"
				>
					<stop stopColor="#5C4763" />
					<stop offset="1" stopColor="#5C4763" stopOpacity="0" />
				</radialGradient>
			</defs>
		</svg>
	);
};
