import { useMutation } from '@tanstack/react-query';
import { TelegramCore } from '../../../app-cores/telegram';
import { ModalData } from '../store/store.type';
import { botConnectorHandler } from '../utils/bot-connector.handler';
import { useCallback } from 'react';

export const useProposalAction = (data: ModalData) => {
	const { mutate: approveProposal, isPending: isPendingApprove } = useMutation({
		mutationFn: () => {
			return botConnectorHandler.approve(data);
		},
		onSuccess: () => {
			TelegramCore.closeWebApp();
		},
	});

	const { mutate: rejectProposal, isPending: isPendingReject } = useMutation({
		mutationFn: () => {
			return botConnectorHandler.reject(data);
		},
		onSuccess: () => {
			TelegramCore.closeWebApp();
		},
	});

	const onApprove = useCallback(async () => {
		if (isPendingApprove) return;
		approveProposal();
	}, [approveProposal, isPendingApprove]);

	const onReject = useCallback(async () => {
		if (isPendingReject) return;
		rejectProposal();
	}, [isPendingReject, rejectProposal]);

	return {
		onApprove,
		isPendingApprove,
		onReject,
		isPendingReject,
	};
};
