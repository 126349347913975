import { useMutation } from '@tanstack/react-query';
import { useCallback, useEffect } from 'react';
import { erc20Abi, useERC20Contract, useTRC20Contract } from '.';
import { queryClient } from '../../app-cores/query-client';

import { bufferGas } from '@/app-hooks/transactions';
import { getSigner, getSignerContract } from '@/app-helpers/web3';
import { QUERY_KEYS } from '@/app-constants';
import { useTransactionWatcherStore } from '../../app-store';
import { TransactionType } from '../../app-types';
import { isEvmChain, isTronChain } from '@/app-helpers/token';
import { MpcWallet } from '@/app-cores/mpc-wallet/wallet';
import { tronWallet } from '@/app-cores/mpc-wallet/tron';
import { estimateTronGasFee } from '@/app-hooks/transactions/tron/useEstimateTronGasFee';
import { waitForTronTransaction } from '@/app-store/transaction-watcher/tronWatcher';
import { parseUnits } from 'ethers';

export type TApproveParams = {
	spenderContract: string;
	tokenContract: string;
	chainId: string | number;
	estimateGas?: boolean;
};

export function useApproveToken({ spenderContract, tokenContract, chainId }: TApproveParams) {
	const erc20Contract = useERC20Contract(tokenContract, chainId);

	const { addPendingEvmTransaction, addPendingTronTransaction } = useTransactionWatcherStore();
	const estimateGasErc20 = useCallback(
		async (amount: string) => {
			if (!erc20Contract) throw new Error('Empty contract');
			const { provider } = getSigner(chainId);
			const [gasAmount, { gasPrice }] = await Promise.all([
				erc20Contract.approve.estimateGas(spenderContract, amount),
				provider.getFeeData(),
			]);
			return gasAmount * gasPrice;
		},
		[spenderContract, erc20Contract, chainId],
	);

	const estimateGasTrc20 = useCallback(
		async (amount: string) => {
			const { totalFeeBigInt } = await estimateTronGasFee({
				contractAddress: tokenContract,
				functionSelector: 'approve(address,uint256)', // Method to call
				options: {},
				parameters: [
					{ type: 'address', value: spenderContract }, // Spender's address
					{ type: 'uint256', value: amount }, // Amount to approve
				],
			});
			return totalFeeBigInt;
		},
		[spenderContract, tokenContract],
	);

	const approveErc20 = async ({ amount }) => {
		const { contract: erc20Contract, provider } = getSignerContract(tokenContract, chainId, erc20Abi);
		let gasLimit = 200_000n;
		try {
			gasLimit = await erc20Contract.approve.estimateGas(spenderContract, amount);
			gasLimit = bufferGas(gasLimit);
		} catch (error) {
			console.log('failed to estimate gas', error, gasLimit);
		}
		const gas = await provider.getFeeData();
		const txReceipt = await erc20Contract.approve(spenderContract, amount, {
			gasLimit,
			gasPrice: gas.gasPrice,
		});
		return txReceipt.wait();
	};

	const approveTrc20 = async ({ amount }) => {
		const unsignedTransaction = await tronWallet.tronWeb.transactionBuilder.triggerSmartContract(
			tokenContract,
			'approve(address,uint256)', // Method to call
			{},
			[
				{ type: 'address', value: spenderContract }, // Spender's address
				{ type: 'uint256', value: amount }, // Amount to approve
			],
			MpcWallet.getTronWalletAddress(),
		);

		const signedTransaction = await tronWallet.signTransaction(unsignedTransaction.transaction);
		const txResults = await tronWallet.sendRawTransaction(signedTransaction);
		return waitForTronTransaction(txResults.transaction.txID);
	};

	const { mutateAsync: approveToken, ...result } = useMutation({
		mutationKey: ['approve-token'],
		mutationFn: async ({ amount }: { amount: string }) => {
			return isTronChain(chainId) ? approveTrc20({ amount }) : approveErc20({ amount });
		},
		onSuccess: (receipt) => {
			if (isEvmChain(chainId))
				addPendingEvmTransaction({
					transaction: receipt,
					metadata: {
						transactionType: TransactionType.Approve,
						chainId: receipt.chainId || chainId,
					},
				});
			if (isTronChain(chainId))
				addPendingTronTransaction({
					hash: receipt.hash,
				});
			queryClient.invalidateQueries({
				queryKey: [QUERY_KEYS.GET_TOKEN_ALLOWANCE],
			});
		},
	});

	return {
		...result,
		approveToken,
		estimateGas: isTronChain(chainId) ? estimateGasTrc20 : estimateGasErc20,
	};
}
