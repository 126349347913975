import React from 'react';

interface IProps extends React.SVGAttributes<HTMLOrSVGElement> {}

export const FiInfoIcon: React.FC<IProps> = ({ width = 16, height = 16, fill = 'black', ...rest }) => {
	return (
		<svg width={width} height={height} {...rest} viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M7.99984 2.49984C4.68613 2.49984 1.99984 5.18613 1.99984 8.49984C1.99984 11.8135 4.68613 14.4998 7.99984 14.4998C11.3135 14.4998 13.9998 11.8135 13.9998 8.49984C13.9998 5.18613 11.3135 2.49984 7.99984 2.49984ZM0.666504 8.49984C0.666504 4.44975 3.94975 1.1665 7.99984 1.1665C12.0499 1.1665 15.3332 4.44975 15.3332 8.49984C15.3332 12.5499 12.0499 15.8332 7.99984 15.8332C3.94975 15.8332 0.666504 12.5499 0.666504 8.49984Z"
				fill={fill}
				fillOpacity="0.64"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M8.00016 7.8335C8.36835 7.8335 8.66683 8.13197 8.66683 8.50016V11.1668C8.66683 11.535 8.36835 11.8335 8.00016 11.8335C7.63197 11.8335 7.3335 11.535 7.3335 11.1668V8.50016C7.3335 8.13197 7.63197 7.8335 8.00016 7.8335Z"
				fill={fill}
				fillOpacity="0.64"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M7.3335 5.83317C7.3335 5.46498 7.63197 5.1665 8.00016 5.1665H8.00683C8.37502 5.1665 8.6735 5.46498 8.6735 5.83317C8.6735 6.20136 8.37502 6.49984 8.00683 6.49984H8.00016C7.63197 6.49984 7.3335 6.20136 7.3335 5.83317Z"
				fill={fill}
				fillOpacity="0.64"
			/>
		</svg>
	);
};
