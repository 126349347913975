import React from 'react';

interface IProps extends React.SVGAttributes<HTMLOrSVGElement> {}

export const FiEmptyBoxIcon: React.FC<IProps> = ({ width = 64, height = 64, ...rest }) => {
	return (
		<svg width={width} height={height} viewBox="0 0 65 64" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M52.5 35.6171H12.5V54.1291C12.5 55.0121 13.079 55.7911 13.925 56.0451C17.944 57.2501 29.246 60.6411 31.925 61.4451C32.3 61.5571 32.7 61.5571 33.075 61.4451C35.754 60.6411 47.056 57.2501 51.075 56.0451C51.921 55.7911 52.5 55.0121 52.5 54.1291C52.5 49.4931 52.5 35.6171 52.5 35.6171Z"
				fill="url(#paint0_linear_3821_18443)"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M52.5 35.6171H32.5C32.5 35.6171 32.306 61.5291 32.5 61.5291C32.694 61.5291 32.887 61.5011 33.075 61.4451C35.754 60.6411 47.056 57.2501 51.075 56.0451C51.921 55.7911 52.5 55.0121 52.5 54.1291C52.5 49.4931 52.5 35.6171 52.5 35.6171Z"
				fill="url(#paint1_linear_3821_18443)"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M32.5 42.1061L12.5 35.6171C12.5 35.6171 8.99301 40.8171 7.42501 43.1411C7.24901 43.4021 7.20601 43.7291 7.30801 44.0261C7.41101 44.3231 7.64601 44.5541 7.94501 44.6521C11.744 45.8861 22.99 49.5401 25.94 50.4981C26.363 50.6361 26.827 50.4771 27.077 50.1081C28.418 48.1291 32.5 42.1061 32.5 42.1061Z"
				fill="url(#paint2_linear_3821_18443)"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M52.5 35.6171L32.5 42.1061C32.5 42.1061 36.582 48.1291 37.923 50.1081C38.173 50.4771 38.637 50.6361 39.06 50.4981C42.01 49.5401 53.256 45.8861 57.055 44.6521C57.354 44.5541 57.589 44.3231 57.692 44.0261C57.794 43.7291 57.751 43.4021 57.575 43.1411C56.007 40.8171 52.5 35.6171 52.5 35.6171Z"
				fill="url(#paint3_linear_3821_18443)"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M27.659 21.9861C27.409 21.6191 26.946 21.4601 26.523 21.5981C23.575 22.5551 12.326 26.2101 8.52698 27.4451C8.22798 27.5421 7.99198 27.7731 7.88998 28.0701C7.78798 28.3671 7.83098 28.6951 8.00698 28.9551C9.45098 31.0971 12.5 35.6171 12.5 35.6171L32.5 29.1061C32.5 29.1061 28.908 23.8231 27.659 21.9861Z"
				fill="url(#paint4_linear_3821_18443)"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M56.993 28.9551C57.169 28.6951 57.212 28.3671 57.11 28.0701C57.008 27.7731 56.772 27.5421 56.473 27.4451C52.674 26.2101 41.425 22.5551 38.477 21.5981C38.054 21.4601 37.591 21.6191 37.341 21.9861C36.092 23.8231 32.5 29.1061 32.5 29.1061L52.5 35.6171C52.5 35.6171 55.549 31.0971 56.993 28.9551Z"
				fill="url(#paint5_linear_3821_18443)"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M52.5 35.6171L32.5 29.1061L12.5 35.6171L32.5 42.1061L52.5 35.6171Z"
				fill="#00B9AD"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M32.5 42.1061V29.1061L12.5 35.6171L32.5 42.1061Z"
				fill="url(#paint6_linear_3821_18443)"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M28.4821 31.978C28.0661 31.706 27.6911 31.415 27.3581 31.11C26.9521 30.737 26.3191 30.764 25.9451 31.171C25.5721 31.577 25.5991 32.21 26.0061 32.583C26.4151 32.959 26.8761 33.317 27.3881 33.652C27.8501 33.954 28.4701 33.824 28.7721 33.362C29.0741 32.9 28.9441 32.28 28.4821 31.978Z"
				fill="url(#paint7_linear_3821_18443)"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M25.3661 28.906C25.0691 28.503 24.8181 28.098 24.6121 27.693C24.3621 27.201 23.7591 27.005 23.2671 27.255C22.7751 27.505 22.5791 28.108 22.8291 28.6C23.0831 29.098 23.3911 29.597 23.7561 30.092C24.0831 30.536 24.7091 30.631 25.1541 30.304C25.5981 29.976 25.6931 29.35 25.3661 28.906Z"
				fill="url(#paint8_linear_3821_18443)"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M23.904 24.825C23.914 24.422 23.973 24.031 24.081 23.656C24.233 23.126 23.926 22.571 23.395 22.419C22.865 22.267 22.31 22.574 22.158 23.105C22.005 23.641 21.919 24.2 21.904 24.775C21.891 25.327 22.327 25.786 22.879 25.799C23.431 25.813 23.89 25.377 23.904 24.825Z"
				fill="url(#paint9_linear_3821_18443)"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M25.442 21.485C25.699 21.247 25.987 21.027 26.302 20.828C26.769 20.533 26.909 19.915 26.615 19.449C26.32 18.982 25.702 18.842 25.235 19.136C24.812 19.403 24.428 19.698 24.083 20.018C23.678 20.393 23.654 21.026 24.029 21.431C24.404 21.836 25.037 21.86 25.442 21.485Z"
				fill="url(#paint10_linear_3821_18443)"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M29.161 19.76C29.664 19.667 30.197 19.607 30.759 19.584C31.311 19.56 31.739 19.093 31.716 18.542C31.693 17.991 31.226 17.562 30.674 17.585C30.014 17.613 29.388 17.684 28.797 17.793C28.255 17.894 27.895 18.416 27.996 18.958C28.096 19.501 28.618 19.86 29.161 19.76Z"
				fill="url(#paint11_linear_3821_18443)"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M34.373 19.701C35.226 19.711 36.02 19.681 36.757 19.616C37.307 19.567 37.714 19.081 37.665 18.531C37.616 17.982 37.13 17.575 36.58 17.624C35.905 17.683 35.178 17.711 34.396 17.701C33.844 17.695 33.391 18.138 33.385 18.689C33.378 19.241 33.821 19.695 34.373 19.701Z"
				fill="url(#paint12_linear_3821_18443)"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M40.044 19.003C41.034 18.7 41.87 18.312 42.57 17.867C43.035 17.57 43.173 16.952 42.876 16.486C42.58 16.021 41.961 15.883 41.495 16.18C40.932 16.538 40.257 16.846 39.459 17.09C38.932 17.252 38.634 17.811 38.796 18.339C38.957 18.867 39.517 19.164 40.044 19.003Z"
				fill="url(#paint13_linear_3821_18443)"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M45.1341 15.0679C45.6061 14.0919 45.7871 13.0599 45.7411 12.0649C45.7151 11.5129 45.2461 11.0869 44.6951 11.1129C44.1441 11.1389 43.7171 11.6069 43.7431 12.1579C43.7751 12.8339 43.6531 13.5349 43.3331 14.1979C43.0931 14.6949 43.3011 15.2939 43.7981 15.5339C44.2951 15.7739 44.8941 15.5649 45.1341 15.0679Z"
				fill="url(#paint14_linear_3821_18443)"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M44.497 8.529C43.768 7.544 42.779 6.858 41.701 6.637C41.16 6.527 40.631 6.875 40.52 7.416C40.41 7.957 40.759 8.486 41.299 8.597C41.921 8.724 42.469 9.151 42.89 9.72C43.219 10.163 43.846 10.256 44.289 9.928C44.733 9.599 44.826 8.972 44.497 8.529Z"
				fill="url(#paint15_linear_3821_18443)"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M30.5002 6.83105C31.5822 0.704046 40.9592 1.10005 35.5002 6.83105H30.5002Z"
				fill="url(#paint16_linear_3821_18443)"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M30.5002 8.38306C31.5822 14.5091 40.9592 14.1141 35.5002 8.38306H30.5002Z"
				fill="url(#paint17_linear_3821_18443)"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M31.343 8.61706H38.039C38.591 8.61706 39.039 8.16907 39.039 7.61707C39.039 7.06507 38.591 6.61707 38.039 6.61707H31.343C30.792 6.61707 30.343 7.06507 30.343 7.61707C30.343 8.16907 30.792 8.61706 31.343 8.61706Z"
				fill="url(#paint18_linear_3821_18443)"
			/>
			<defs>
				<linearGradient
					id="paint0_linear_3821_18443"
					x1="12.5"
					y1="48.5731"
					x2="52.5"
					y2="48.5731"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#75FEF6" />
					<stop offset="1" stopColor="#00E9DB" />
				</linearGradient>
				<linearGradient
					id="paint1_linear_3821_18443"
					x1="32.414"
					y1="48.5731"
					x2="52.5"
					y2="48.5731"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#9AFFFA" />
					<stop offset="1" stopColor="#A4F7F2" />
				</linearGradient>
				<linearGradient
					id="paint2_linear_3821_18443"
					x1="12.633"
					y1="35.6091"
					x2="15.4283"
					y2="54.1024"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#B9F9F5" />
					<stop offset="1" stopColor="#00E0D3" />
				</linearGradient>
				<linearGradient
					id="paint3_linear_3821_18443"
					x1="32.581"
					y1="42.0381"
					x2="53.6647"
					y2="54.164"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#6DEFE7" />
					<stop offset="1" stopColor="#00EBDB" />
				</linearGradient>
				<linearGradient
					id="paint4_linear_3821_18443"
					x1="9.88198"
					y1="31.7361"
					x2="12.899"
					y2="17.5138"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#74F0E9" />
					<stop offset="1" stopColor="#00F0E0" />
				</linearGradient>
				<linearGradient
					id="paint5_linear_3821_18443"
					x1="37.854"
					y1="21.3571"
					x2="38.9328"
					y2="38.9118"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#8BF4ED" />
					<stop offset="1" stopColor="#00E1D3" />
				</linearGradient>
				<linearGradient
					id="paint6_linear_3821_18443"
					x1="34.548"
					y1="28.8151"
					x2="34.5"
					y2="45"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#46EAE0" />
					<stop offset="1" stopColor="#00BEB1" />
				</linearGradient>
				<linearGradient
					id="paint7_linear_3821_18443"
					x1="21.4911"
					y1="6.83098"
					x2="41.2151"
					y2="30.104"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#00E9DB" />
					<stop offset="1" stopColor="#01D1C4" />
				</linearGradient>
				<linearGradient
					id="paint8_linear_3821_18443"
					x1="21.4911"
					y1="6.83102"
					x2="41.2151"
					y2="30.104"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#00E9DB" />
					<stop offset="1" stopColor="#00DFD2" />
				</linearGradient>
				<linearGradient
					id="paint9_linear_3821_18443"
					x1="21.491"
					y1="6.83102"
					x2="41.215"
					y2="30.104"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#00E9DB" />
					<stop offset="1" stopColor="#00B1A6" />
				</linearGradient>
				<linearGradient
					id="paint10_linear_3821_18443"
					x1="21.491"
					y1="6.83099"
					x2="41.2151"
					y2="30.104"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#00E9DB" />
					<stop offset="1" stopColor="#00A99F" />
				</linearGradient>
				<linearGradient
					id="paint11_linear_3821_18443"
					x1="21.491"
					y1="6.83098"
					x2="41.2151"
					y2="30.104"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#00E9DB" />
					<stop offset="1" stopColor="#00BBB0" />
				</linearGradient>
				<linearGradient
					id="paint12_linear_3821_18443"
					x1="21.491"
					y1="6.831"
					x2="41.215"
					y2="30.104"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#00E9DB" />
					<stop offset="1" stopColor="#00C4B8" />
				</linearGradient>
				<linearGradient
					id="paint13_linear_3821_18443"
					x1="21.491"
					y1="6.831"
					x2="41.215"
					y2="30.104"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#00E9DB" />
					<stop offset="1" stopColor="#00CEC2" />
				</linearGradient>
				<linearGradient
					id="paint14_linear_3821_18443"
					x1="21.4911"
					y1="6.83095"
					x2="41.2151"
					y2="30.104"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#00E9DB" />
					<stop offset="1" stopColor="#00C8BC" />
				</linearGradient>
				<linearGradient
					id="paint15_linear_3821_18443"
					x1="21.491"
					y1="6.831"
					x2="41.215"
					y2="30.104"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#00E9DB" />
					<stop offset="1" stopColor="#00CDC1" />
				</linearGradient>
				<linearGradient
					id="paint16_linear_3821_18443"
					x1="30.5002"
					y1="4.60705"
					x2="37.1955"
					y2="4.60705"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#C8F9F6" />
					<stop offset="1" stopColor="#00E9DB" />
				</linearGradient>
				<linearGradient
					id="paint17_linear_3821_18443"
					x1="30.5002"
					y1="10.6069"
					x2="37.1955"
					y2="10.6069"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#C8F9F6" />
					<stop offset="1" stopColor="#00E9DB" />
				</linearGradient>
				<linearGradient
					id="paint18_linear_3821_18443"
					x1="21.491"
					y1="6.83107"
					x2="41.215"
					y2="30.1041"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#00E9DB" />
					<stop offset="1" stopColor="#03DED0" />
				</linearGradient>
			</defs>
		</svg>
	);
};
