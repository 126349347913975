import { CTAButton, Toast } from '@/app-components/common';
import { NAVIGATE_PATHS } from '@/app-constants/router';
import { CloudKitRecord, ServiceCloudKit, WalletType } from '@/app-cores/api';
import { MpcWallet } from '@/app-cores/mpc-wallet/wallet';
import { TelegramCore } from '@/app-cores/telegram';
import { parseErrorMessage } from '@/app-helpers/error-handling';
import { useQueryUserProfile } from '@/app-hooks/api/user/useQueryUserProfile';
import { useOnEventCallback } from '@/app-hooks/common';
import { Box, Center, Container, Spinner, Text } from '@chakra-ui/react';
import { orderBy } from 'lodash-es';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';

interface RecoveryICloudCallbackProps {}

const RecoveryICloudCallback: React.FC<RecoveryICloudCallbackProps> = () => {
	const { t } = useTranslation();
	const { data: userProfile } = useQueryUserProfile();
	const navigate = useNavigate();
	const [searchParams] = useSearchParams();
	const [progressText, setProgressText] = useState<string>('');

	const onCancel = useOnEventCallback(() => {
		TelegramCore.showConfirm(t('storeBackup.confirmCancel'), {
			onOk: () => {
				navigate(-1);
			},
		});
	});

	const getCurrentRecord = useOnEventCallback(async (authToken: string): Promise<CloudKitRecord> => {
		try {
			let currentRecord: CloudKitRecord | null = null;

			try {
				currentRecord = await ServiceCloudKit.getCurrentRecord(authToken);
			} catch (e) {
				currentRecord = null;
			}

			if (!currentRecord) {
				// fallback: get all records
				const records = await ServiceCloudKit.getRecords(authToken);
				if (!records.length) {
					return null;
				}
				const sortedRecords = orderBy(records, (item) => item.fields.version.value, ['desc']);
				return sortedRecords[0];
			}

			return currentRecord;
		} catch (e) {
			return null;
		}
	});

	const waitForAuthToken = useOnEventCallback(async (state: string) => {
		return new Promise<string>((resolve, reject) => {
			const _poll = async () => {
				try {
					const ckAuthToken = await ServiceCloudKit.getCKAuthTokenByAuthState(state);
					console.log('CKAuthToken', ckAuthToken);

					if (!ckAuthToken) {
						await new Promise((resolve) => setTimeout(resolve, 2000));
						return requestAnimationFrame(_poll);
					}

					resolve(ckAuthToken);
				} catch (e) {
					reject(e);
				}
			};
			_poll();
		});
	});

	const handleRecovery = useOnEventCallback(async () => {
		try {
			const state = searchParams.get('state');
			const wallets = userProfile.wallets;
			const mpcWallet = (wallets || []).find((wallet) => !wallet.isAccountAbstraction);
			const tonWallet = (wallets || []).find((wallet) => wallet.type === WalletType.TON);

			if (!state) {
				throw new Error('State not found');
			}

			setProgressText('Waiting for auth token...');
			const ckAuthToken = await waitForAuthToken(state);

			setProgressText('Retrieving backup...');
			const currentRecord = await getCurrentRecord(ckAuthToken);
			console.log('Current Record', currentRecord);

			if (!currentRecord) {
				throw new Error('Backup not found');
			}

			const keyShareHex = String(currentRecord.fields.data.value);
			await MpcWallet.recoverKeyShare(
				keyShareHex,
				{
					evmWallet: mpcWallet.address,
					tonWallet: tonWallet?.formatedAddress,
				},
				userProfile?.backupType,
			);

			navigate(NAVIGATE_PATHS.Home);
		} catch (e) {
			console.error(e);
			toast(<Toast type="error" message={`Failed to backup: ${parseErrorMessage(e)}`} />);
			navigate(-1);
		}
	});

	useEffect(() => {
		if (userProfile?.defaultWallet) {
			handleRecovery();
		}
		// eslint-disable-next-line
	}, [userProfile]);

	return (
		<Container className="pageContent">
			<Box className="pageFixed" py={6} px={4}>
				<Center flexDirection="column" overflow="hidden" flexGrow={1}>
					<Spinner color="cyan.400" />
					<Text fontSize={14} mt={2}>
						{progressText}
					</Text>
				</Center>

				<CTAButton size="lg" fontWeight="medium" width="100%" onClick={onCancel}>
					{t('button.cancel')}
				</CTAButton>
			</Box>
		</Container>
	);
};

export default RecoveryICloudCallback;
