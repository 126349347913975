import { StoreApi, create } from 'zustand';
import { TonConnectParams, TonConnectTAppRequest } from '../types';
import { ReturnStrategy } from '@tonconnect/ui-react';

interface ModalData {
	connectParams?: TonConnectParams;
	requestParams?: TonConnectTAppRequest;
}

type ModalView = 'SessionProposalModal' | 'SessionSendTransactionModal' | 'SessionSignModal';

export interface IModalStore {
	open: boolean;
	returnStrategyData: {
		isOpen?: boolean;
		returnStrategy?: ReturnStrategy;
		dappName?: string;
		isRejected?: boolean;
		errorMessage?: string;
	};
	view?: ModalView;
	data?: ModalData;
	platform?: string;
	onOpen: (view: ModalView, data?: ModalData) => void;
	setReturnStrategyData: (payload: IModalStore['returnStrategyData']) => void;
	setPlatform?: (platform: string) => void;
	onClose: () => void;
}

export const useModalStore = create<IModalStore>((set: StoreApi<IModalStore>['setState']) => ({
	open: false,
	returnStrategyData: {},
	onOpen: (view: ModalView, data?: ModalData) => {
		set(() => ({ open: true, view, data }));
	},
	setPlatform: (platform: string) => {
		set(() => ({ platform: platform }));
	},
	onClose: () => {
		set(() => ({ open: false, data: undefined }));
	},
	setReturnStrategyData: (data) => {
		set(() => ({ returnStrategyData: data }));
	},
}));
