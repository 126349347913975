import React from 'react';

interface IProps extends React.SVGAttributes<HTMLOrSVGElement> {}

export const FiUpArrowCircleIcon: React.FC<IProps> = ({ width = 80, height = 80, ...rest }) => {
	return (
		<svg width={width} height={height} viewBox="0 0 81 80" fill="none" xmlns="http://www.w3.org/2000/svg">
			<g id="up-arrow 1" clipPath="url(#clip0_6819_26276)">
				<path
					id="Vector"
					d="M72.5 40C72.5 57.6732 58.1732 72 40.5 72C22.8268 72 8.5 57.6732 8.5 40C8.5 22.3268 22.8268 8 40.5 8C58.1732 8 72.5 22.3268 72.5 40Z"
					fill="#00E9DB"
				/>
				<path
					id="Vector_2"
					d="M40.5 0C18.4089 0 0.5 17.9089 0.5 40C0.5 62.0911 18.4089 80 40.5 80C62.5911 80 80.5 62.0911 80.5 40C80.4753 17.9193 62.5807 0.0247397 40.5 0ZM40.5 77.3333C19.8815 77.3333 3.16667 60.6185 3.16667 40C3.16667 19.3815 19.8815 2.66667 40.5 2.66667C61.1185 2.66667 77.8333 19.3815 77.8333 40C77.8099 60.6087 61.1087 77.3099 40.5 77.3333Z"
					fill="black"
				/>
				<path
					id="Vector_3"
					d="M41.3537 20.3092C41.3133 20.2845 41.271 20.2617 41.228 20.2415C41.1753 20.2012 41.1199 20.1654 41.0613 20.1335C41.0242 20.1172 40.9865 20.1029 40.9481 20.0905C40.8849 20.0703 40.8205 20.0547 40.7547 20.0443C40.6902 20.028 40.6251 20.0176 40.5587 20.0117C40.5373 20.0117 40.5216 20 40.5001 20C40.4787 20 40.4617 20.0104 40.4402 20.0117C40.3758 20.0176 40.312 20.028 40.2495 20.043C40.1818 20.0534 40.1154 20.0697 40.0509 20.0905C40.0119 20.1055 39.9748 20.1159 39.9376 20.1335C39.8804 20.1654 39.8257 20.2012 39.7736 20.2402C39.7332 20.2682 39.6857 20.2773 39.6466 20.3092L23.6466 33.6426C23.2677 33.9434 23.0815 34.4264 23.1596 34.9036C23.2384 35.3815 23.5698 35.7786 24.0255 35.9421C24.4806 36.1055 24.9891 36.0091 25.3537 35.6908L39.1668 24.1797V58.6667C39.1668 59.403 39.7638 60 40.5001 60C41.2365 60 41.8335 59.403 41.8335 58.6667V24.1797L55.6466 35.6908C56.0112 36.0091 56.5197 36.1055 56.9748 35.9421C57.4305 35.7786 57.7619 35.3815 57.8406 34.9036C57.9188 34.4264 57.7326 33.9434 57.3537 33.6426L41.3537 20.3092Z"
					fill="black"
				/>
			</g>
			<defs>
				<clipPath id="clip0_6819_26276">
					<rect width="80" height="80" fill="white" transform="translate(0.5)" />
				</clipPath>
			</defs>
		</svg>
	);
};
