import React from 'react';

interface IProps extends React.SVGAttributes<HTMLOrSVGElement> {}

export const FiCheckMarkDoneIcon: React.FC<IProps> = ({ width = 16, height = 16, fill = 'black', ...rest }) => {
	return (
		<svg
			stroke="currentColor"
			fill="currentColor"
			strokeWidth="0"
			viewBox="0 0 512 512"
			height={height}
			width={width}
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				fill="none"
				strokeLinecap="round"
				strokeLinejoin="round"
				strokeWidth="32"
				d="M464 128 240 384l-96-96m0 96-96-96m320-160L232 284"
			></path>
		</svg>
	);
};
