import React from 'react';

interface IProps extends React.SVGAttributes<HTMLOrSVGElement> {}

export const FiStartIcon: React.FC<IProps> = ({ width = 24, height = 24, ...rest }) => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 25 24" fill="none">
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M11.4663 2.98192C11.6584 2.59488 12.0532 2.3501 12.4853 2.3501C12.9173 2.3501 13.3121 2.59488 13.5042 2.98192L15.4885 6.98021L19.9043 7.63185C20.3317 7.69495 20.6865 7.99475 20.8201 8.40565C20.9536 8.81661 20.8427 9.26769 20.534 9.56998L17.3446 12.6927L18.0894 17.0938C18.1615 17.5198 17.986 17.9498 17.6364 18.2039C17.2869 18.4578 16.8236 18.4918 16.4407 18.2916L12.4853 16.2232L8.52978 18.2916C8.14686 18.4918 7.68357 18.4578 7.33402 18.2039C6.98447 17.9498 6.80899 17.5198 6.88112 17.0938L7.62593 12.6927L4.4365 9.56998C4.12773 9.26769 4.0169 8.81661 4.15045 8.40565C4.28393 7.99475 4.63874 7.69495 5.06623 7.63185L9.48202 6.98021L11.4663 2.98192Z"
				fill="url(#paint0_linear_13356_4588)"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M12.4851 2.4126C12.9172 2.4126 13.312 2.65738 13.504 3.04442L15.4883 7.04271L19.9041 7.69435C20.3316 7.75745 20.6864 8.05725 20.8199 8.46815C20.822 8.47476 20.8241 8.48129 20.8261 8.4879L12.4851 11.1763V2.4126Z"
				fill="url(#paint1_linear_13356_4588)"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M12.4851 11.1763L4.14404 8.4879C4.146 8.48129 4.14809 8.47476 4.15025 8.46815C4.28373 8.05725 4.63854 7.75745 5.06602 7.69435L9.48182 7.04271L11.4661 3.04442C11.6582 2.65738 12.053 2.4126 12.4851 2.4126V11.1763Z"
				fill="url(#paint2_linear_13356_4588)"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M17.6434 18.1818C17.6329 18.1899 17.6222 18.1979 17.6114 18.2057C17.2618 18.4575 16.7986 18.4912 16.4157 18.2927L12.4602 16.242L8.5047 18.2927C8.12838 18.4878 7.67447 18.4586 7.32715 18.2185L12.4602 11.1763L17.6434 18.1818Z"
				fill="url(#paint3_linear_13356_4588)"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M12.2043 18.2369V22.0685C12.2043 22.2235 12.3302 22.3494 12.4853 22.3494C12.6403 22.3494 12.7661 22.2235 12.7661 22.0685V18.2369C12.7661 18.0819 12.6403 17.9561 12.4853 17.9561C12.3302 17.9561 12.2043 18.0819 12.2043 18.2369Z"
				fill="url(#paint4_radial_13356_4588)"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M19.259 13.4693L22.8802 14.7213C23.0267 14.772 23.1868 14.6942 23.2374 14.5477C23.2881 14.4012 23.2103 14.2411 23.0638 14.1904L19.4425 12.9383C19.2961 12.8877 19.1359 12.9655 19.0853 13.112C19.0346 13.2585 19.1125 13.4186 19.259 13.4693Z"
				fill="url(#paint5_radial_13356_4588)"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M5.52764 12.9383L1.90648 14.1904C1.75991 14.2411 1.68212 14.4012 1.73275 14.5477C1.78344 14.6942 1.94349 14.772 2.09005 14.7213L5.71129 13.4693C5.85778 13.4186 5.93558 13.2585 5.88495 13.112C5.83425 12.9655 5.67421 12.8877 5.52764 12.9383Z"
				fill="url(#paint6_radial_13356_4588)"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M17.2747 5.21675L19.4466 2.06021C19.5345 1.93252 19.5021 1.75744 19.3744 1.6696C19.2467 1.58169 19.0717 1.61405 18.9838 1.7418L16.8119 4.89834C16.724 5.02603 16.7564 5.2011 16.8841 5.28895C17.0118 5.37686 17.1869 5.3445 17.2747 5.21675Z"
				fill="url(#paint7_radial_13356_4588)"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M8.43369 4.87713L5.97011 1.94253C5.87041 1.82381 5.6931 1.8083 5.57438 1.90801C5.4556 2.00772 5.44016 2.18502 5.53987 2.30374L8.00337 5.23835C8.10308 5.35706 8.28038 5.3725 8.39917 5.27286C8.51789 5.17315 8.53333 4.99585 8.43369 4.87713Z"
				fill="url(#paint8_radial_13356_4588)"
			/>
			<defs>
				<linearGradient
					id="paint0_linear_13356_4588"
					x1="12.4853"
					y1="8.8156"
					x2="12.4853"
					y2="17.0444"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#FFB541" />
					<stop offset="1" stopColor="#F59500" />
				</linearGradient>
				<linearGradient
					id="paint1_linear_13356_4588"
					x1="12.2042"
					y1="2.78426"
					x2="18.1292"
					y2="10.9235"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#FFD952" />
					<stop offset="1" stopColor="#FFA501" />
				</linearGradient>
				<linearGradient
					id="paint2_linear_13356_4588"
					x1="7.92492"
					y1="3.9397"
					x2="13.4978"
					y2="9.23035"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#FFDF6C" />
					<stop offset="1" stopColor="#FFA501" />
				</linearGradient>
				<linearGradient
					id="paint3_linear_13356_4588"
					x1="12.4853"
					y1="8.23189"
					x2="12.4853"
					y2="17.0236"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#FFA841" />
					<stop offset="1" stopColor="#F27B00" />
				</linearGradient>
				<radialGradient
					id="paint4_radial_13356_4588"
					cx="0"
					cy="0"
					r="1"
					gradientUnits="userSpaceOnUse"
					gradientTransform="translate(12.4853 11.1759) rotate(18.8508) scale(11.0207 11.0207)"
				>
					<stop stopColor="#FFA501" />
					<stop offset="0.57" stopColor="#FFAF0E" />
					<stop offset="1" stopColor="#FFD541" />
				</radialGradient>
				<radialGradient
					id="paint5_radial_13356_4588"
					cx="0"
					cy="0"
					r="1"
					gradientUnits="userSpaceOnUse"
					gradientTransform="translate(12.4851 11.176) rotate(18.8508) scale(11.0207)"
				>
					<stop stopColor="#FFA501" />
					<stop offset="0.57" stopColor="#FFAF0E" />
					<stop offset="1" stopColor="#FFD541" />
				</radialGradient>
				<radialGradient
					id="paint6_radial_13356_4588"
					cx="0"
					cy="0"
					r="1"
					gradientUnits="userSpaceOnUse"
					gradientTransform="translate(12.4852 11.176) rotate(18.8508) scale(11.0207)"
				>
					<stop stopColor="#FFA501" />
					<stop offset="0.57" stopColor="#FFAF0E" />
					<stop offset="1" stopColor="#FFD541" />
				</radialGradient>
				<radialGradient
					id="paint7_radial_13356_4588"
					cx="0"
					cy="0"
					r="1"
					gradientUnits="userSpaceOnUse"
					gradientTransform="translate(12.4851 11.1762) rotate(18.8508) scale(11.0207)"
				>
					<stop stopColor="#FFA501" />
					<stop offset="0.57" stopColor="#FFAF0E" />
					<stop offset="1" stopColor="#FFD541" />
				</radialGradient>
				<radialGradient
					id="paint8_radial_13356_4588"
					cx="0"
					cy="0"
					r="1"
					gradientUnits="userSpaceOnUse"
					gradientTransform="translate(12.485 11.1763) rotate(18.8508) scale(11.0207)"
				>
					<stop stopColor="#FFA501" />
					<stop offset="0.57" stopColor="#FFAF0E" />
					<stop offset="1" stopColor="#FFD541" />
				</radialGradient>
			</defs>
		</svg>
	);
};
