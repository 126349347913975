import { forwardRef, useState } from 'react';
import { FiEyeIcon, FiEyeOffIcon } from '@/assets/icons';
import { Box, InputGroup, InputProps, InputRightElement } from '@chakra-ui/react';
import { InputCustom } from '..';

interface InputPasswordProps extends InputProps {
	label: string;
	placeHolderStyle?: React.CSSProperties;
}
export const InputPassword = forwardRef<HTMLInputElement, InputPasswordProps>(({ label, ...rest }, ref) => {
	const [show, setShow] = useState(false);
	return (
		<>
			<Box as="label" fontWeight={500} fontSize="xs">
				{label}
			</Box>

			<InputGroup size="lg" variant="filled" mt={2}>
				<InputCustom
					type={show ? 'text' : 'password'}
					fontSize="xs"
					backgroundColor="gray.100"
					_focus={{
						backgroundColor: 'gray.100',
					}}
					_hover={{
						backgroundColor: 'gray.100',
					}}
					ref={ref}
					{...rest}
				/>
				<InputRightElement>
					<Box as="span" onClick={() => setShow(!show)} cursor="pointer">
						{show ? <FiEyeIcon /> : <FiEyeOffIcon />}
					</Box>
				</InputRightElement>
			</InputGroup>
		</>
	);
});
