import React from 'react';

interface IProps extends React.SVGAttributes<HTMLOrSVGElement> {}

export const FiSuccessIcon: React.FC<IProps> = ({ width = 24, height = 24, ...rest }) => {
	return (
		<svg width={width} height={height} viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
			<rect y="0.457031" width="24" height="24" rx="12" fill="#00C170" />
			<path
				d="M10.0001 15.2369L7.2201 12.4569L6.27344 13.3969L10.0001 17.1236L18.0001 9.12359L17.0601 8.18359L10.0001 15.2369Z"
				fill="white"
			/>
		</svg>
	);
};
