import {
	Accordion,
	AccordionButton,
	AccordionIcon,
	AccordionItem,
	AccordionPanel,
	Box,
	Center,
	Text,
} from '@chakra-ui/react';
import { useState } from 'react';
import { CodeBlock } from 'react-code-blocks';

interface RawTransactionProps {
	rawData: string;
}
export const RawTransaction: React.FC<RawTransactionProps> = ({ rawData }) => {
	return (
		<Accordion allowToggle marginX={-4}>
			<AccordionItem border="none">
				<AccordionButton>
					<Center width="100%">
						<Text textAlign="center" fontSize="xs">
							More Details
						</Text>
						<AccordionIcon />
					</Center>
				</AccordionButton>
				<AccordionPanel p={0}>
					<Box>
						<CodeBlock
							customStyle={{
								fontSize: '12px',
							}}
							showLineNumbers={false}
							text={rawData}
							language="json"
						/>
					</Box>
				</AccordionPanel>
			</AccordionItem>
		</Accordion>
	);
};
