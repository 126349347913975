import { useCallback, useState } from 'react';
import { Box, Center, Image, Text } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import { NetWorkChain } from '@/app-contexts/wallet-provider/type';
import { getShortAddress } from '@/app-helpers/address';
import { ChainIcons } from '../ChainIcons';
import { CopyToClipboard } from '../CopyToClipboard';
import { getMyWalletAddressByChain } from '@/app-hooks/swap/helper';
import { DATADOG_ACTIONS, dataDogAddAction } from '@/app-services/monitor/datadog';
import { Toast } from '../Toast';
import { FiQRCodeIcon, FiRightIcon } from '@/assets/icons';
import {
	ChainId,
	MAINNET_CHAINS,
	NATIVE_TOKEN_ADDRESS,
	NON_EVM_CHAINS,
	ethereum,
	solana,
	sui,
	ton,
	tron,
} from '@/app-constants/chains';
import { QrCodeAddress } from '../QrCodePopup';
import { CardSelection } from '../CardSelection';
import { FeatureConfig, useQueryFeatureConfig } from '@/app-hooks/api/configuration';

const chainIds = MAINNET_CHAINS.reduce((ids, chain) => {
	if (![solana.id, ton.id, tron.id, sui.id].includes(chain.id)) {
		ids.push(chain.id);
	}
	return ids;
}, []);

interface AddressesProps {
	onClickQrCode?: (chain: NetWorkChain) => void;
	showCopyAndQRCode?: boolean;
	onItemClick?: (chain: NetWorkChain) => void;
	visibleChain?: ChainId;
}
export const Addresses: React.FC<AddressesProps> = ({
	onClickQrCode,
	showCopyAndQRCode = true,
	onItemClick,
	visibleChain,
}) => {
	const { t } = useTranslation();
	const [address, setAddress] = useState('');
	const [networkChain, setNetworkChain] = useState<NetWorkChain | null>(null);
	const isDisableSuiNetwork = useQueryFeatureConfig(FeatureConfig.DISABLE_SUI_NETWORK);

	const renderWalletItem = useCallback((chain: NetWorkChain, isEvm?: boolean) => {
		return (
			<CardSelection
				key={chain.id}
				icon={<Image src={chain.logo} width="40px" height="40px" alt="" />}
				onClick={() => onItemClick?.(chain)}
				cursor={onItemClick ? 'pointer' : 'auto'}
				leftContent={
					<Box>
						<Text fontSize="sm" fontWeight={600}>
							{chain.name}
						</Text>
						<Text fontSize="xs" color="gray.400" mb={1}>
							{getShortAddress(getMyWalletAddressByChain(chain.id))}
						</Text>
						{isEvm && <ChainIcons chains={chainIds} translatePlacement="left" />}
					</Box>
				}
				rightContent={
					showCopyAndQRCode ? (
						<Center gap={3}>
							<CopyToClipboard
								iconSize={24}
								text={getMyWalletAddressByChain(chain.id)}
								callback={() => {
									dataDogAddAction(DATADOG_ACTIONS.DEPOSIT_BY_COPY_CHAIN_ADDRESS);
									toast(
										<Toast
											type="success"
											title="Success"
											message={t('deposit.copyWalletAddressSuccess', {
												network: chain.name,
											})}
										/>,
									);
								}}
							></CopyToClipboard>
							<Box
								cursor="pointer"
								onClick={() => {
									onClickQrCode?.(chain);
									setAddress(getMyWalletAddressByChain(chain.id));
									setNetworkChain(chain);
									dataDogAddAction(DATADOG_ACTIONS.DEPOSIT_BY_SCAN_CHAIN_QR_CODE);
								}}
							>
								<FiQRCodeIcon />
							</Box>
						</Center>
					) : (
						<Box>
							<FiRightIcon width={20} height={20} />
						</Box>
					)
				}
			/>
		);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	let nonEvmChains = visibleChain ? NON_EVM_CHAINS.filter((c) => c.id === visibleChain) : NON_EVM_CHAINS;
	if (isDisableSuiNetwork) {
		nonEvmChains = nonEvmChains.filter((c) => c.id !== sui.id);
	}
	return (
		<>
			{nonEvmChains.map((chain) => {
				return renderWalletItem(chain);
			})}
			{![ChainId.TON, ChainId.SOL, ChainId.TRON, ChainId.SUI].includes(visibleChain) && (
				<Box>{renderWalletItem(ethereum, true)}</Box>
			)}
			{!!address && (
				<QrCodeAddress
					onClose={() => {
						setAddress('');
						setNetworkChain(null);
					}}
					isOpen={!!address}
					address={address}
					token={{
						address: NATIVE_TOKEN_ADDRESS,
						chainId: networkChain?.id as any,
						logo: networkChain?.logo,
						symbol: networkChain?.nativeToken?.symbol,
					}}
				/>
			)}
		</>
	);
};
