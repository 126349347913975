import { Box, Center, CenterProps, Image, Text } from '@chakra-ui/react';
import { CopyToClipboard } from '../CopyToClipboard';
import { FiRightIcon } from '@/assets/icons';
import React from 'react';

interface CardSelectionProps extends CenterProps {
	icon: React.ReactNode;
	leftContent: React.ReactNode;
	rightContent?: React.ReactNode;
	onClick?: () => void;
}

export const CardSelection: React.FunctionComponent<CardSelectionProps> = ({
	icon,
	leftContent,
	rightContent,
	onClick,
	...rest
}) => {
	return (
		<Center
			justifyContent="space-between"
			p={4}
			mb={3}
			border="1px solid rgba(0, 0, 0, 0.08)"
			borderRadius={12}
			bgColor="white"
			onClick={() => onClick?.()}
			{...rest}
		>
			<Center gap={4}>
				{icon}
				<Box>{leftContent}</Box>
			</Center>
			<Box>{rightContent ? rightContent : <FiRightIcon width={20} height={20} />}</Box>
		</Center>
	);
};
