import { Network, Networkish } from '@ethersproject/networks';
import { FetchRequest, ethers } from 'ethers';
import { MpcWalletSigner } from './signer';

export class MpcWalletProvider extends ethers.JsonRpcProvider {
	constructor(url?: FetchRequest | string, network?: Networkish) {
		super(url, network);
	}
	// eslint-disable-next-line @typescript-eslint/ban-ts-comment
	// @ts-ignore
	public getSigner(): MpcWalletSigner {
		return new MpcWalletSigner(this);
	}
}
