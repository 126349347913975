import {
	Button,
	Center,
	PlacementWithLogical,
	Popover,
	PopoverArrow,
	PopoverBody,
	PopoverContent,
	PopoverFooter,
	PopoverHeader,
	PopoverTrigger,
	Portal,
	Text,
} from '@chakra-ui/react';
import { motion, useAnimation } from 'framer-motion';
import { useEffect, useRef, useState } from 'react';
import { FiChristmasStartsIcon } from '@/assets/icons';
import { BackDrop } from './BackDrop';
import { CopilotIcon } from '@/app-features/copilot/components/CopilotIcon';
import { useCopilotStore } from '@/app-features/copilot/store';
import { useTranslation } from 'react-i18next';

export interface Step {
	target: string;
	content: React.ReactNode;
	offset?: {
		top: number;
		left: number;
	};
	skipActiveState?: boolean;
	placement?: PlacementWithLogical;
}
interface TobiTourGuideProps {
	run?: boolean;
	onComplete?: () => void;
	steps?: Step[];
	animationDuration?: number;
	initIconPosition?: {
		top: number;
		left: number;
	};
}
export const TOUR_GUIDE_THEME_CONFIG = {
	bgColor: '#22222E',
	titleColor: '#FFF',
	descriptionColor: 'rgba(255, 255, 255, 0.64)',
};
export const TobiTourGuide: React.FC<TobiTourGuideProps> = ({
	run = true,
	onComplete,
	steps,
	animationDuration = 0.36,
	initIconPosition,
}) => {
	const [step, setStep] = useState(1);
	const [isOpenPopover, setIsOpenPopover] = useState(false);
	const { setEnable } = useCopilotStore();
	const { t } = useTranslation();
	const animation = useAnimation();
	const currentActiveElement = useRef<HTMLElement>(null);
	const clientRef = useRef({
		width: document.body.clientWidth,
		hight: document.body.clientHeight,
	});
	const isLasted = step === steps.length;

	useEffect(() => {
		if (!run) return;
		async function handleActiveStep() {
			setEnable(false);
			const stepData = steps[step - 1];
			currentActiveElement.current = document.getElementById(stepData.target);
			const position = currentActiveElement.current?.getBoundingClientRect();
			await animation.start({
				scale: 0.5,
				transition: { duration: 0.5 },
			});
			await animation.start({
				top: (position?.top || clientRef.current.hight - 60) + (stepData?.offset?.top || 0),
				left: (position?.left || clientRef.current.width - 60) + (stepData?.offset?.left || 0),
				transition: { duration: animationDuration },
			});
			!stepData.skipActiveState && currentActiveElement.current?.classList?.add('walk-through-active');
			await animation.start({
				scale: 1,
				transition: { duration: animationDuration },
			});

			setIsOpenPopover(true);
		}
		handleActiveStep();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [step, run]);

	const handleComplete = () => {
		setEnable(true);
		onComplete?.();
	};
	if (!run) return null;

	return (
		<BackDrop>
			<Popover isOpen={isOpenPopover} placement={steps[step - 1].placement || 'bottom'}>
				<PopoverTrigger>
					<motion.div
						animate={animation}
						style={{
							left: initIconPosition?.left || 0,
							top: initIconPosition?.top || 0,
							position: 'absolute',
						}}
						initial={{
							scale: 0,
						}}
					>
						<CopilotIcon skipAnimation />
					</motion.div>
				</PopoverTrigger>
				<Portal>
					<PopoverContent
						width={clientRef.current.width - 1}
						position="relative"
						fontSize="sm"
						px={2}
						bgColor="transparent"
						border="none"
						boxShadow="none"
						_focus={{
							boxShadow: 'none',
						}}
					>
						<motion.div
							style={{
								backgroundColor: TOUR_GUIDE_THEME_CONFIG.bgColor,
								borderRadius: '12px',
							}}
							initial={{
								scale: 0,
							}}
							animate={{
								scale: 1,
							}}
							exit={{
								scale: 0,
							}}
							transition={{
								ease: 'linear',
								duration: 0.5,
							}}
						>
							<PopoverArrow bgColor={TOUR_GUIDE_THEME_CONFIG.bgColor} />
							<PopoverHeader
								display="flex"
								justifyContent="space-between"
								fontSize="xs"
								borderBottom="none"
								mb={0}
								pb={0}
							>
								<Center gap={2}>
									<FiChristmasStartsIcon />
									<Text fontWeight={500} color="#00E9DB">
										{t('tourGuide.tobiAssist')}
									</Text>
								</Center>
								<Text color="#FFF" opacity={0.48}>
									{step}/{steps.length}
								</Text>
							</PopoverHeader>
							<PopoverBody>{steps[step - 1].content}</PopoverBody>
							<PopoverFooter
								border="none"
								display="flex"
								alignItems="center"
								justifyContent={0 < step && step < steps.length ? 'space-between' : 'flex-end'}
							>
								{!isLasted && step > 0 && (
									<Text
										cursor="pointer"
										textDecoration="underline"
										color="white"
										fontWeight={500}
										onClick={async () => {
											setIsOpenPopover(false);
											if (currentActiveElement.current) {
												currentActiveElement.current?.classList?.remove('walk-through-active');
											}
											if (initIconPosition) {
												await animation.start({
													top: initIconPosition.top,
													left: initIconPosition.left,
													transition: { duration: animationDuration },
												});
											}
											handleComplete();
										}}
									>
										{t('tourGuide.skipIntro')}
									</Text>
								)}
								<Center gap={2}>
									{step > 1 && (
										<Button
											border="1px solid rgba(255, 255, 255, 0.16)"
											bgColor="transparent"
											size="sm"
											color="white"
											onClick={() => {
												if (currentActiveElement.current) {
													currentActiveElement.current?.classList?.remove(
														'walk-through-active',
													);
												}
												if (isOpenPopover) {
													setIsOpenPopover(false);
												}
												setStep((pre) => pre - 1);
											}}
										>
											{t('button.back')}
										</Button>
									)}
									<Button
										size="sm"
										colorScheme="cyan"
										onClick={() => {
											if (currentActiveElement.current) {
												currentActiveElement.current?.classList?.remove('walk-through-active');
											}
											if (isLasted) return handleComplete();
											if (isOpenPopover) {
												setIsOpenPopover(false);
											}
											setStep((pre) => pre + 1);
										}}
									>
										{isLasted ? t('button.done') : t('button.continue')}
									</Button>
								</Center>
							</PopoverFooter>
						</motion.div>
					</PopoverContent>
				</Portal>
			</Popover>
		</BackDrop>
	);
};
