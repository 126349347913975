import { Ed25519PublicKey } from '@mysten/sui/keypairs/ed25519';
import {
	getFullnodeUrl,
	SuiClient,
	type DryRunTransactionBlockResponse,
	type ExecuteTransactionRequestType,
	type SuiTransactionBlockResponse,
	type SuiTransactionBlockResponseOptions,
} from '@mysten/sui/client';
import { SuinsClient } from '@mysten/suins';

import {
	IntentScope,
	messageWithIntent,
	SerializeSignatureInput,
	SIGNATURE_SCHEME_TO_FLAG,
	SignatureWithBytes,
} from '@mysten/sui/cryptography';
import { isTransaction, type Transaction } from '@mysten/sui/transactions';
import { fromBase64, toBase64 } from '@mysten/sui/utils';
import keyShareManager from '../wallet/keyshare-manager';
import { blake2b } from '@noble/hashes/blake2b';
import { MpcWallet } from '../wallet';
import { PublicKey } from '@solana/web3.js';

export type SignedTransaction = {
	transactionBlockBytes: string;
	signature: string;
};

export type SignedMessage = {
	messageBytes: string;
	signature: string;
};

export function toSerializedSignature({ signature, signatureScheme, publicKey }: SerializeSignatureInput): string {
	if (!publicKey) {
		throw new Error('`publicKey` is required');
	}
	const pubKeyBytes = publicKey.toRawBytes();
	const serializedSignature = new Uint8Array(1 + signature.length + pubKeyBytes.length);
	serializedSignature.set([SIGNATURE_SCHEME_TO_FLAG[signatureScheme]]);
	serializedSignature.set(signature, 1);
	serializedSignature.set(pubKeyBytes, 1 + signature.length);
	return toBase64(serializedSignature);
}
const SUI_NETWORKS = {
	mainnet: { url: getFullnodeUrl('mainnet') },
};

export class WalletSigner {
	client = new SuiClient(SUI_NETWORKS.mainnet);
	suinsClient = new SuinsClient({
		client: this.client as any,
		network: 'mainnet',
	});
	getAddress(): string {
		return MpcWallet.getSuiWalletAddress();
	}

	async signWithIntent(bytes: Uint8Array, intent: IntentScope): Promise<SignatureWithBytes> {
		const intentMessage = messageWithIntent(intent, bytes);
		const digest = blake2b(intentMessage, { dkLen: 32 });
		const publicKey = new Ed25519PublicKey(
			Buffer.from(keyShareManager.keyShares.eddsaKeyShare.publicKey()).toString('base64'),
		);
		const signature = toSerializedSignature({
			signature: await MpcWallet.signEddsaMessage(Buffer.from(digest)),
			signatureScheme: 'ED25519',
			publicKey: publicKey,
		});

		return {
			signature,
			bytes: toBase64(bytes),
		};
	}
	async signMessage(message: string): Promise<SignatureWithBytes> {
		return await this.signWithIntent(Buffer.from(message), 'PersonalMessage');
	}
	protected async prepareTransactionBlock(transactionBlock: Uint8Array | Transaction | string) {
		if (isTransaction(transactionBlock)) {
			transactionBlock.setSenderIfNotSet(this.getAddress());
			return await transactionBlock.build({
				client: this.client,
			});
		}

		if (typeof transactionBlock === 'string') {
			return fromBase64(transactionBlock);
		}

		if (transactionBlock instanceof Uint8Array) {
			return transactionBlock;
		}
		throw new Error('Unknown transaction format');
	}
	async estimateGasFee(transactionBlock: Transaction): Promise<string | number> {
		try {
			transactionBlock.setSenderIfNotSet(this.getAddress());
			await transactionBlock.build({
				client: this.client,
			});
			return transactionBlock.getData().gasData.budget;
		} catch (error) {
			console.log('🚀 ~ WalletSigner ~ estimateGasFee ~ error:', error);
			return 0;
		}
	}
	async signTransactionBlock(
		input: {
			transactionBlock: Uint8Array | Transaction;
		},
		clientIdentifier?: string,
	): Promise<SignedTransaction> {
		const data = await this.prepareTransactionBlock(input.transactionBlock);

		const res = await this.signWithIntent(data, 'TransactionData');
		return {
			transactionBlockBytes: res.bytes,
			signature: res.signature,
		};
	}

	async signAndExecuteTransactionBlock(
		input: {
			transactionBlock: Uint8Array | Transaction;
			options?: SuiTransactionBlockResponseOptions;
			requestType?: ExecuteTransactionRequestType;
		},
		clientIdentifier?: string,
	): Promise<SuiTransactionBlockResponse> {
		const signed = await this.signTransactionBlock({
			transactionBlock: input.transactionBlock,
		});

		return this.client.executeTransactionBlock({
			transactionBlock: signed.transactionBlockBytes,
			signature: signed.signature,
			options: input.options,
			requestType: input.requestType,
		});
	}

	async dryRunTransactionBlock(input: {
		transactionBlock: Transaction | string | Uint8Array;
	}): Promise<DryRunTransactionBlockResponse> {
		return this.client.dryRunTransactionBlock({
			transactionBlock: await this.prepareTransactionBlock(input.transactionBlock),
		});
	}
	getSuiAddressFromSolanaAddress(solWalletAddress) {
		const publicKey = new PublicKey(solWalletAddress);
		const suiPublicKey = new Ed25519PublicKey(publicKey.toBuffer().toString('base64'));
		const suiAddress = suiPublicKey.toSuiAddress();
		console.log('🚀 ~ getSuiAddressFromSolanaAddress ~ suiAddress:', suiAddress);
		return suiAddress;
	}
}
export const suiWallet = new WalletSigner();
