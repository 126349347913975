import {
	AlertDialog,
	AlertDialogBody,
	AlertDialogContent,
	AlertDialogOverlay,
	Button,
	Center,
	Text,
} from '@chakra-ui/react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { AppLogoIcon } from '@/assets/icons';
import { TelegramCore } from '@/app-cores/telegram';

interface MaintenanceProps {}

export const Maintenance: React.FC<MaintenanceProps> = () => {
	const cancelRef = React.useRef();
	const { t } = useTranslation();
	const handleCloseApp = () => {
		TelegramCore.WebApp.close();
	};

	return (
		<AlertDialog
			size="xs"
			isCentered
			isOpen={true}
			leastDestructiveRef={cancelRef}
			onClose={() => handleCloseApp()}
			autoFocus={false}
			trapFocus={false}
			closeOnEsc={false}
			closeOnOverlayClick={false}
		>
			<AlertDialogOverlay>
				<AlertDialogContent borderRadius="1.5rem">
					<AlertDialogBody py={10} px={8}>
						<Center flexDirection="column" textAlign="center">
							<AppLogoIcon />
							<Text mt={4} fontSize="1.25rem">
								{t('maintenance.title')}
							</Text>

							<Text mt={2} fontSize="xs">
								{t('maintenance.description')}
							</Text>
							<Button
								onClick={() => handleCloseApp()}
								mt={6}
								ref={cancelRef}
								size="lg"
								fontWeight="medium"
								colorScheme="cyan"
								width="100%"
							>
								{t('maintenance.exitTobi')}
							</Button>
						</Center>
					</AlertDialogBody>
				</AlertDialogContent>
			</AlertDialogOverlay>
		</AlertDialog>
	);
};
