export type SetupDefs = {
	relay: string;
	endpoint: string;
};

//export const configs = async (): Promise<Array<ClusterDef>> => {
export const configs = (): SetupDefs => {
	return {
		relay: import.meta.env.VITE_MPC_RELAY_API,
		endpoint: import.meta.env.VITE_MPC_API,
	};
	// return {
	// 	relay: 'wss://msg-relay.tin-zin.com/v1/msg-relay',
	// 	endpoint: 'https://dkls.tin-zin.com',
	// };
	// return {
	// 	relay: 'ws://localhost:8000/v1/msg-relay',
	// 	endpoint: 'http://localhost:8082',
	// };
};

export const wsUrl = (path) => {
	if (path.startsWith('ws://') || path.startsWith('wss://')) {
		return path;
	}

	// https: => wss:
	// http:  => ws:
	const proto = window.location.protocol.replace('http', 'ws');

	return proto + window.location.host + path;
};
