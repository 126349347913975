import {
	Box,
	Button,
	Card,
	Center,
	Divider,
	Drawer,
	DrawerBody,
	DrawerContent,
	DrawerFooter,
	DrawerHeader,
	DrawerOverlay,
	Flex,
	Spinner,
	Text,
} from '@chakra-ui/react';
import { approveEIP155Request, rejectEIP155Request } from '../utils/EIP155RequestHandlerUtil';
import { getChainId, getHostName, getSignTypedDataParamsData } from '../utils/HelperUtil';
import { web3wallet } from '../utils/WalletConnectUtil';
import { useCallback, useState } from 'react';
import { CodeBlock } from 'react-code-blocks';
import { useModalStore } from '../store/ModalStore';
import { toast } from 'react-toastify';
import { CHAIN_CONFIG } from '@/app-constants/chains';
import { FiRightIcon } from '@/assets/icons';
import { useDecimal } from '@/app-hooks/wallet/useDecimal';
import { TransactionItemInfo } from '../components/TransactionItemInfo';
import { ContractInfo } from '@/app-components/ApproveModal/ContractInfo';
import { DappInfo } from '@/app-components/ApproveModal/DappInfo';
import { Toast } from '@/app-components/common';

export default function SessionApprovalModal() {
	const { data, open, onClose } = useModalStore();
	const requestEvent = data?.requestEvent;
	const requestSession = data?.requestSession;
	const [isLoadingApprove, setIsLoadingApprove] = useState(false);
	const [isLoadingReject, setIsLoadingReject] = useState(false);
	const [isViewFullDetail, setIsViewFullDetail] = useState(false);
	const { topic, params } = requestEvent;
	const { request, chainId } = params;
	const { peer } = requestSession;
	const transaction = getSignTypedDataParamsData(request.params);
	const { data: decimal = 1 } = useDecimal(getChainId(chainId), transaction?.domain?.verifyingContract);
	const decimalNumber = Math.pow(10, Number(decimal));
	const amount = +transaction?.message?.value / decimalNumber;

	const onApprove = useCallback(async () => {
		if (requestEvent) {
			setIsLoadingApprove(true);
			const response = await approveEIP155Request(requestEvent);
			try {
				await web3wallet.respondSessionRequest({
					topic,
					response,
				});
				if ((response as any)?.result) {
					toast(<Toast type="success" title="Success" message="Sign type data successfully" />);
				}
			} catch (e) {
				setIsLoadingApprove(false);
				toast(<Toast type="error" message={(e as Error).message} />);
			} finally {
				setIsLoadingApprove(false);
				onClose();
			}
		}
		// eslint-disable-next-line
	}, [requestEvent, topic]);

	// Handle reject action
	const onReject = useCallback(async () => {
		if (requestEvent) {
			setIsLoadingReject(true);
			const response = rejectEIP155Request(requestEvent);
			try {
				await web3wallet.respondSessionRequest({
					topic,
					response,
				});
				// toast(<Toast type="success" message="Reject sign type data successfully" />);
			} catch (e) {
				setIsLoadingReject(false);
			} finally {
				setIsLoadingReject(false);
				onClose();
			}
		}
		// eslint-disable-next-line
	}, [requestEvent, topic]);
	return (
		<Drawer
			isOpen={true || open}
			placement="bottom"
			onClose={onClose}
			autoFocus={false}
			trapFocus={false}
			closeOnOverlayClick={false}
		>
			<DrawerOverlay />
			<DrawerContent>
				<DrawerHeader borderBottomWidth="1px">
					<Center>
						<Text fontSize={'16px'} fontWeight={'500'}>
							Approve {transaction?.domain?.name}
						</Text>
					</Center>
				</DrawerHeader>

				<DrawerBody>
					<Flex mt={2} flexDirection={'column'} alignItems={'center'} gap={'20px'}>
						<DappInfo
							dapp={{
								domain: getHostName(peer?.metadata?.url),
								logo: peer?.metadata?.icons?.[0],
							}}
						/>
						<ContractInfo
							chainId={transaction?.domain?.chainId}
							contract={transaction?.message?.spender}
							description={
								'By granting permission, you are allowing the following contract to access your funds'
							}
							msg={`Give permission to access your ${transaction?.domain?.name}?`}
						/>
						<Card padding={'16px'} width={'100%'} background={'gray.100'} gap={'8px'}>
							<Flex justifyContent={'space-between'}>
								<Text fontSize="xs" fontWeight={500}>
									Transaction information
								</Text>
							</Flex>
							<TransactionItemInfo title="Token" value={transaction?.domain?.name} />
							<Divider />
							<TransactionItemInfo
								title="Network"
								value={CHAIN_CONFIG[transaction?.domain?.chainId]?.name}
							/>
							<Divider />
							<TransactionItemInfo title="Value" value={amount?.toString()} />
							<Divider />
							<TransactionItemInfo title="Spender" value={transaction?.message?.spender} />
						</Card>
					</Flex>
					<Center mt={4}>
						<Button
							backgroundColor="transparent"
							size="xs"
							onClick={() => setIsViewFullDetail(!isViewFullDetail)}
						>
							View full transaction details <FiRightIcon />
						</Button>
					</Center>
					{isViewFullDetail && (
						<Box pt={2}>
							<CodeBlock
								showLineNumbers={false}
								text={JSON.stringify(transaction, null, 2)}
								language="json"
							/>
						</Box>
					)}
				</DrawerBody>

				<DrawerFooter display="flex" flexDirection="column" gap={2}>
					<Button size="lg" fontWeight="medium" width="100%" onClick={onReject}>
						{isLoadingReject ? <Spinner size="sm" /> : 'Deny'}
					</Button>
					<Button size="lg" fontWeight="medium" colorScheme="cyan" width="100%" onClick={onApprove}>
						{isLoadingApprove ? <Spinner size="sm" color="white" /> : 'Approve'}
					</Button>
				</DrawerFooter>
			</DrawerContent>
		</Drawer>
	);
}
