import { Heading, HeadingProps } from '@chakra-ui/react';
import { PropsWithChildren } from 'react';

interface HeaderProps extends HeadingProps {}
export const Header: React.FC<PropsWithChildren<HeaderProps>> = ({ children, ...rest }) => {
	return (
		<Heading fontWeight={600} fontSize="sm" textAlign="center" py={3} {...rest}>
			{children}
		</Heading>
	);
};
