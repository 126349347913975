import { ButtonSecondary, Loading } from '@/app-components/common';
import PageTransition from '@/app-components/layout/PageTransition/PageTransition';
import PageNavigation from '@/app-components/layout/PageWrapper/PageWrapper';
import { useQueryInviteCodesHistory, useQueryUserInviteCodes } from '@/app-hooks/api/user/useQueryUserInviteCodes';
import { Avatar, Box, Button, Card, Center, Container, Divider, Flex, Text, useDisclosure } from '@chakra-ui/react';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import dayjs from 'dayjs';
import { BASE_BORDER_COLOR } from '@/app-theme/theme';
import { InviteHistory } from '@/app-cores/api';
import { useInviteFriend } from '@/app-views/account-setting/pages/InviteCode/InviteCode';
import { displayUserName, getAccountFullName } from '@/app-helpers/display';
import { ContactAccount } from '@/app-cores/api/contact';
import BackgroundImage from '@/app-components/layout/BackGround/ContainerWithBackgroundImage';

interface ItemProps {
	contact: ContactAccount;
	isWalletCreated: boolean;
	createdAt: string;
	onClick: () => void;
}
const ContactListItem: React.FC<ItemProps> = ({ contact, isWalletCreated, createdAt, onClick }) => {
	const { t } = useTranslation();
	if (!contact) return null;
	return (
		<Flex alignItems="center" gap={3} py={2}>
			<Avatar src={contact.avatar} name={getAccountFullName(contact)} size="sm" loading="lazy"></Avatar>
			<Box flex={1} overflow={'hidden'}>
				<Flex alignItems="center" gap={1}>
					<Text
						fontSize="sm"
						fontWeight={600}
						whiteSpace={'nowrap'}
						textOverflow={'ellipsis'}
						overflow={'hidden'}
					>
						{getAccountFullName(contact)}
					</Text>
				</Flex>
				<Flex alignItems="center" gap={1}>
					<Text fontSize="xs" as="span" color="gray.400">
						{displayUserName(contact.username)}
					</Text>
				</Flex>
			</Box>
			{isWalletCreated ? (
				<Flex direction={'column'} marginLeft="auto" align={'flex-end'}>
					<Text fontSize={'10px'}>{t('referral.joinedOn')}</Text>
					<Text fontSize={'12px'} whiteSpace={'nowrap'}>
						{createdAt ? dayjs(createdAt).format('MMM DD, YYYY') : ''}
					</Text>
				</Flex>
			) : (
				<ButtonSecondary size={'sm'} marginLeft={'auto'} onClick={onClick}>
					{t('referral.remind')}
				</ButtonSecondary>
			)}
		</Flex>
	);
};

const InviteCodeDetail = () => {
	const { t } = useTranslation();
	const { data: history } = useQueryInviteCodesHistory();
	const { walletUsers = [], nonWalletUsers = [] } = history || {};
	const { onInvite, isLoading } = useInviteFriend();

	const onRemind = () => {
		onInvite();
	};

	if (isLoading) {
		return <Loading />;
	}

	const renderFriend = (invite: InviteHistory) => {
		const { isWalletCreated, invitee, id } = invite;
		return (
			<ContactListItem
				key={id}
				contact={invitee}
				isWalletCreated={isWalletCreated}
				createdAt={invitee?.defaultWallet?.createdAt ?? ''}
				onClick={!isWalletCreated ? onRemind : undefined}
			/>
		);
	};

	return (
		<PageTransition>
			<PageNavigation>
				<BackgroundImage
					py={4}
					px={4}
					height={'100vh'}
					display={'flex'}
					alignItems={'center'}
					justifyContent={'center'}
				>
					<Card
						width={'100%'}
						borderRadius={'24px'}
						background={'#FFF'}
						minHeight={'460px'}
						maxHeight={'100%'}
					>
						<Center py={4} fontSize={'14px'}>
							You successfully referred{' '}
							<Text as="b" mx={1}>
								{walletUsers?.length} friends
							</Text>{' '}
							to join Tobi.
						</Center>
						<Divider variant={'dashed'} borderColor={BASE_BORDER_COLOR} opacity={1} width={'100%'} />
						<Flex direction={'column'} px={4} py={2} flex={1} overflowY={'auto'} gap={'20px'}>
							{walletUsers?.length > 0 && (
								<Flex direction={'column'} gap={'6px'}>
									<Text fontSize={'14px'} fontWeight={'600'}>
										{t('referral.referredUser')}
									</Text>
									<Text fontSize={'12px'} color={'gray'}>
										{t('referral.referredUserDesc')}
									</Text>
									{walletUsers.map((e) => renderFriend(e))}
								</Flex>
							)}
							{nonWalletUsers?.length > 0 && (
								<Flex direction={'column'} gap={'6px'}>
									<Text fontSize={'14px'} fontWeight={'600'}>
										{t('referral.pendingRegistration')}
									</Text>
									<Text fontSize={'12px'} color={'gray'}>
										{t('referral.pendingRegistrationDesc')}
									</Text>
									{nonWalletUsers.map((e) => renderFriend(e))}
								</Flex>
							)}
						</Flex>
					</Card>
				</BackgroundImage>
			</PageNavigation>
		</PageTransition>
	);
};

export default InviteCodeDetail;
