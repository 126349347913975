import Warning from '@/app-components/common/Warning';
import { TokenInfo } from '@/app-cores/api/bff';
import { compareToken } from '@/app-helpers/address';
import { getNativeToken } from '@/app-helpers/token';
import useShowLoadingAtLeastTime from '@/app-hooks/common/useShowLoadingAtLeastTime';
import { SwapErrorInfo, SwapService } from '@/app-hooks/swap';
import { SwapErrorType } from '@/app-hooks/swap/type';
import { SelectedRoute } from '@/app-store/swap';
import RouteItem from '@/app-views/swap/components/ListRoute/RouteItem';
import { TOUR_GUIDE_STEPS_TARGET } from '@/app-views/wallet/components/TourGuide/stepConfigs';
import { FiRefreshIcon } from '@/assets/icons/fi-refresh-icon';
import { Box, Divider, Flex, Skeleton, Spinner, Text } from '@chakra-ui/react';
import { useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';

export default function ListRoute({
	loadingRouteByUserInput: loadingRouteCurrentPair,
	loadingSuggestedRoutes: loadingRoutes,
	suggestedRoutes,
	selectedRoute,

	onClickRoute,
	onChangeRoute,
	tokenOut,
	tokenIn,
	amountIn,
	errorMsg,
	refetch,
	quickSwap,
}: {
	loadingRouteByUserInput: boolean;
	loadingSuggestedRoutes: boolean;
	suggestedRoutes: SelectedRoute[];
	onClickRoute: (v: SelectedRoute) => void;
	onChangeRoute: (v: SelectedRoute) => void;
	selectedRoute: SelectedRoute | undefined;
	tokenOut: TokenInfo | undefined;
	tokenIn: TokenInfo | undefined;
	errorMsg: SwapErrorInfo;
	amountIn: bigint;
	refetch: () => Promise<any>;
	quickSwap: boolean;
}) {
	const { t } = useTranslation();

	// better ux
	const loadingRouteByUserInput = useShowLoadingAtLeastTime(loadingRouteCurrentPair, 1000);
	const loadingSuggestedRoutes = useShowLoadingAtLeastTime(loadingRoutes, 1000);

	const listHeight = useRef<Record<string, string>>({});

	// to keep prev height for skeleton
	const onChangeHeigh = (id: number, height: string) => {
		listHeight.current = { ...listHeight.current, [id]: height };
	};

	const listRoute = useMemo(() => {
		const listRoute = selectedRoute
			? suggestedRoutes.filter(
					(e) =>
						!(
							compareToken(e.tokenIn, selectedRoute?.tokenIn) &&
							compareToken(e.tokenOut, selectedRoute?.tokenOut)
						),
			  )
			: suggestedRoutes;
		return listRoute;
	}, [selectedRoute, suggestedRoutes]);

	if (!tokenOut) return null;

	const getSkeleton = (id: string | number, height?: string) => (
		<Skeleton key={id} width={'100%'} height={height || '134px'} endColor={'gray.200'} borderRadius="1rem" />
	);

	const renderRoute = (route: SelectedRoute, i: number, currentRoute: boolean) => {
		return (
			<RouteItem
				index={i}
				onChangeHeigh={onChangeHeigh}
				onChangeRoute={onChangeRoute}
				onClick={() => onClickRoute(route)}
				key={route.id}
				route={route}
				selected={
					selectedRoute?.id === route.id &&
					(currentRoute ? amountIn?.toString() === SwapService.extractRouteInfo({ route })?.amountIn : true)
				}
				selectedRoute={selectedRoute}
			/>
		);
	};

	const listRouteNode = loadingSuggestedRoutes ? (
		<>
			<Skeleton width={'140px'} height={'18px'} endColor={'gray.200'} borderRadius="1rem" />
			{new Array(listRoute?.length || 3).fill(0).map((_, i) => getSkeleton(i))}
		</>
	) : (
		listRoute?.length > 0 && (
			<>
				<Text fontSize={'12px'} fontWeight={'500'}>
					{t('tokenTrading.alternativeRoutes', {
						symbol: tokenOut?.symbol,
					})}
				</Text>
				{listRoute.map((route) => renderRoute(route, undefined, false))}
			</>
		)
	);

	const messages = errorMsg?.messages?.filter((e) => e.errorType === SwapErrorType.ROUTE);
	const refetchRoute = () => {
		try {
			refetch();
		} catch (error) {}
	};
	return (
		<>
			{tokenIn && (
				<Flex flexDirection={'column'} gap={'12px'} id={TOUR_GUIDE_STEPS_TARGET.TRADE.ROUTE} p={1}>
					<Flex align={'center'} fontSize={'12px'} fontWeight={'500'} gap={2}>
						{`Routes from ${tokenIn?.symbol ?? '??'} to ${tokenOut?.symbol ?? ''}`}
						{loadingRouteByUserInput ? (
							<Spinner boxSize={'14px'} />
						) : (
							<FiRefreshIcon size={14} onClick={refetchRoute} />
						)}
					</Flex>
					{!loadingRouteByUserInput &&
						messages.map((e) => (
							<Warning
								status={e.uiType}
								background={e.uiType ? undefined : 'gray.100'}
								title={e.title}
								msg={e.msg}
								key={e.msg}
							/>
						))}
					{loadingRouteByUserInput
						? getSkeleton(selectedRoute?.id, listHeight.current[0])
						: selectedRoute
						? renderRoute(selectedRoute, 0, true)
						: null}
				</Flex>
			)}
			{!quickSwap && (
				<>
					{listRouteNode && <Divider variant={'dashed'} />}
					<Flex flexDirection={'column'} gap={'12px'}>
						{listRouteNode}
					</Flex>
				</>
			)}
		</>
	);
}
