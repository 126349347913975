import React from 'react';

interface IProps extends React.SVGAttributes<HTMLOrSVGElement> {}

export const FiBackupIcon: React.FC<IProps> = ({ width = 81, height = 80, ...rest }) => {
	return (
		<svg width={width} height={height} viewBox="0 0 81 80" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M59.65 30.8529C59.4321 25.4058 57.0877 20.2611 53.1199 16.5227C49.1521 12.7843 43.8771 10.7501 38.4266 10.8565C32.9761 10.963 27.7845 13.2016 23.9657 17.092C20.1469 20.9825 18.0051 26.2148 18 31.6663V34.3663C14.0696 34.997 10.521 37.085 8.06089 40.2145C5.6008 43.3441 4.40979 47.2854 4.72506 51.2536C5.04033 55.2218 6.83874 58.9256 9.76213 61.6274C12.6855 64.3293 16.5193 65.8308 20.5 65.8329H58.8334C63.4005 65.8278 67.7847 64.0375 71.0499 60.8441C74.3151 57.6507 76.2025 53.3073 76.3091 48.7414C76.4157 44.1755 74.733 39.7488 71.6204 36.4065C68.5079 33.0642 64.212 31.0712 59.65 30.8529Z"
				fill="#00E9DB"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M40.5 35C41.0523 35 41.5 35.4477 41.5 36V50C41.5 50.5523 41.0523 51 40.5 51C39.9477 51 39.5 50.5523 39.5 50V36C39.5 35.4477 39.9477 35 40.5 35Z"
				fill="black"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M39.7929 35.2929C40.1834 34.9024 40.8166 34.9024 41.2071 35.2929L48.2071 42.2929C48.5976 42.6834 48.5976 43.3166 48.2071 43.7071C47.8166 44.0976 47.1834 44.0976 46.7929 43.7071L40.5 37.4142L34.2071 43.7071C33.8166 44.0976 33.1834 44.0976 32.7929 43.7071C32.4024 43.3166 32.4024 42.6834 32.7929 42.2929L39.7929 35.2929Z"
				fill="black"
			/>
		</svg>
	);
};
