import React from 'react';

interface IProps extends React.SVGAttributes<HTMLOrSVGElement> {}

export const FiFrownIcon: React.FC<IProps> = ({ width = 25, height = 25, ...rest }) => {
	return (
		<svg width={width} height={height} viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M12.5 3C7.52944 3 3.5 7.02944 3.5 12C3.5 16.9706 7.52944 21 12.5 21C17.4706 21 21.5 16.9706 21.5 12C21.5 7.02944 17.4706 3 12.5 3ZM1.5 12C1.5 5.92487 6.42487 1 12.5 1C18.5751 1 23.5 5.92487 23.5 12C23.5 18.0751 18.5751 23 12.5 23C6.42487 23 1.5 18.0751 1.5 12Z"
				fill="white"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M8.5 9C8.5 8.44772 8.94772 8 9.5 8H9.51C10.0623 8 10.51 8.44772 10.51 9C10.51 9.55228 10.0623 10 9.51 10H9.5C8.94772 10 8.5 9.55228 8.5 9Z"
				fill="white"
			/>
			<path
				d="M8.5 16C8.5 16 10.1 14 12.5 14C14.9 14 16.5 16 16.5 16"
				stroke="white"
				strokeWidth="2"
				strokeLinecap="round"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M14.5 9C14.5 8.44772 14.9477 8 15.5 8H15.51C16.0623 8 16.51 8.44772 16.51 9C16.51 9.55228 16.0623 10 15.51 10H15.5C14.9477 10 14.5 9.55228 14.5 9Z"
				fill="white"
			/>
		</svg>
	);
};
