import { get, set } from 'lodash-es';

/**
 * TODO: Update this class
 */

const TOBI_BOT_CONNECTOR_STORAGE = 'TOBI_BOT_CONNECTOR_STORAGE';

export class TobiStorage {
	private state: Record<string, any> = {};
	constructor() {
		this.persist();
	}

	get(key: string | string[]) {
		return get(this.state, key);
	}

	set(key: string | string[], value: any): void {
		set(this.state, key, value);
		this.save();
	}

	getAll(): Record<string, any> {
		return this.state;
	}

	private save() {
		localStorage.setItem(TOBI_BOT_CONNECTOR_STORAGE, JSON.stringify(this.state));
	}

	private persist() {
		try {
			this.state = JSON.parse(localStorage.getItem(TOBI_BOT_CONNECTOR_STORAGE)) || {};
		} catch {
			this.state = {};
		}
	}
}
