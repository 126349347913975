import { createMultiStyleConfigHelpers, cssVar, defineStyle, defineStyleConfig, extendTheme } from '@chakra-ui/react';
import { drawerAnatomy } from '@chakra-ui/anatomy';

export const BASE_BORDER_COLOR = '#00000014';
export const BASE_BACKGROUND_COLOR = '#f9f9f9';

export const colors = {
	white: '#fff',
	gray: {
		100: '#F9F9F9',
		150: 'rgba(0, 0, 0, 0.16)', // TODO: Update it later, don't use 150
		200: 'rgba(0, 0, 0, 0.32)',
		300: 'rgba(0, 0, 0, 0.48)',
		400: 'rgba(0, 0, 0, 0.64)',
	},
	green: {
		100: 'rgba(0, 193, 112, 0.08)',
		200: '#00C170',
	},
	cyan: {
		400: '#00E9DB',
		500: '#01cec2',
		600: '#00E9DB',
	},
	orange: {
		8: 'rgba(255, 77, 0, 0.08)',
		100: '#FF4D00',
	},
	blue: {
		100: '#08C',
		200: '#6644E1',
	},
	red: {
		100: 'rgb(254, 215, 215)',
		200: '#E32A2A',
	},
};
const configs = {
	initialColorMode: 'light',
	useSystemColorMode: false,
};

const $tooltip_bg = cssVar('tooltip-bg');
const tooltipBaseStyle = {
	bg: $tooltip_bg.reference,
	[$tooltip_bg.variable]: '#90dcd8',
	fontWeight: '500',
	borderRadius: 8,
	color: 'black',
	fontSize: 'xs',
	padding: '0.5rem',
};

export const buttonTheme = defineStyleConfig({
	variants: {
		solid: {
			borderRadius: '120px',
			fontWeight: 500,
		},
		outline: {
			borderRadius: '120px',
			fontWeight: 500,
			borderColor: 'gray.100',
		},
		link: {
			borderRadius: '120px',
			fontWeight: 500,
		},
	},
});
const tooltipTheme = defineStyleConfig({ baseStyle: tooltipBaseStyle });

const dividerSolid = defineStyle({
	borderColor: 'gray.100',
});

export const dividerTheme = defineStyleConfig({
	variants: { solid: dividerSolid },
});

const selectTheme = defineStyle({ defaultProps: { focusBorderColor: colors.cyan['600'] } });
const inputTheme = defineStyle({ defaultProps: { focusBorderColor: colors.cyan['600'] } });

const drawerHelpers = createMultiStyleConfigHelpers(drawerAnatomy.keys);
export const drawerTheme = drawerHelpers.defineMultiStyleConfig({
	baseStyle: drawerHelpers.definePartsStyle({
		header: {
			textAlign: 'center',
			fontSize: '16px',
			fontWeight: '500',
		},
	}),
});

const components = {
	Tooltip: tooltipTheme,
	Button: buttonTheme,
	Divider: dividerTheme,
	Input: inputTheme,
	Select: selectTheme,
	Drawer: drawerTheme,
};
const breakpoints = {
	base: '0em', // 0px
	xs: '400px', // => custom
	sm: '30em', // 480px
	md: '48em', // 768px
	lg: '62em', // 992px
	xl: '80em', // 1280px
	'2xl': '96em', // 1536px
};

export const theme = extendTheme({
	colors,
	configs,
	components,
	breakpoints,
});
