import React from 'react';

interface IProps extends React.SVGAttributes<HTMLOrSVGElement> {}

export const FiDownLeftIcon: React.FC<IProps> = ({ width = 24, height = 24, ...rest }) => {
	return (
		<svg width={width} height={height} {...rest} viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M18.0069 6.29289C18.3974 6.68342 18.3974 7.31658 18.0069 7.70711L8.00691 17.7071C7.61639 18.0976 6.98322 18.0976 6.5927 17.7071C6.20217 17.3166 6.20217 16.6834 6.5927 16.2929L16.5927 6.29289C16.9832 5.90237 17.6164 5.90237 18.0069 6.29289Z"
				fill="black"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M7.2998 6C7.85209 6 8.2998 6.44772 8.2998 7V16H17.2998C17.8521 16 18.2998 16.4477 18.2998 17C18.2998 17.5523 17.8521 18 17.2998 18H7.2998C6.74752 18 6.2998 17.5523 6.2998 17V7C6.2998 6.44772 6.74752 6 7.2998 6Z"
				fill="black"
			/>
		</svg>
	);
};
