import { CTAButton, InputPassword, Toast } from '@/app-components/common';
import AppLayout from '@/app-components/layout/PageLayout';
import { RECOVERY_TYPE } from '@/app-constants/backup';
import { NAVIGATE_PATHS } from '@/app-constants/router';
import { TelegramCore } from '@/app-cores/telegram';
import { createGoogleAuthUrl } from '@/app-helpers/backup';
import { parseErrorMessage } from '@/app-helpers/error-handling';
import { DATADOG_ERROR_TAGS, dataDogAddError } from '@/app-services/monitor/datadog';
import { useRecoveryWalletStore } from '@/app-store';
import { FiSecurityIcon } from '@/assets/icons';
import { Box, Center, Text } from '@chakra-ui/react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';

interface InputSecretKeyProps {}

export const InputSecretKey: React.FunctionComponent<InputSecretKeyProps> = () => {
	const [searchParams] = useSearchParams();
	const { t } = useTranslation();
	const recoveryType = searchParams.get('recoveryType');
	const accountType = searchParams.get('accountType');
	const [secretKey, setSecretKey] = useState('');
	const navigate = useNavigate();
	const { setRecoveryFile } = useRecoveryWalletStore();

	const handleChangeFile = (e: React.ChangeEvent<HTMLInputElement>) => {
		if (!e.target?.files?.[0]) return;
		const file = e.target?.files?.[0];
		if (file?.type !== 'text/plain') {
			return toast(<Toast title="Error" message={t('toast.invalidFile')} type="error" />);
		}
		setRecoveryFile(file);
		toast(<Toast type="success" title="Success" message={t('toast.uploadSuccess')} />);
	};

	const handleRecovery = async () => {
		if (!secretKey) return;
		try {
			if (recoveryType === RECOVERY_TYPE.DRIVE) {
				const { url, state } = await createGoogleAuthUrl();

				if (!state) {
					throw new Error('State not found');
				}

				TelegramCore.openExternalURL(url);
				return navigate(`${NAVIGATE_PATHS.Recovery.GDriveCallback}?state=${state}&secretKey=${secretKey}`);
			}
			if (recoveryType === RECOVERY_TYPE.FILE) {
				return navigate(
					`${NAVIGATE_PATHS.Recovery.FileUploadCallBack}?secretKey=${secretKey}&accountType=${accountType}`,
				);
			}
		} catch (e) {
			dataDogAddError(e, {
				tags: {
					name: DATADOG_ERROR_TAGS.RECOVERY_WALLET,
					function: 'recovery',
				},
			});
			console.log('ERROR', e);
			toast(<Toast type="error" message={`Failed to process: ${parseErrorMessage(e)}`} />);
		}
	};
	return (
		<AppLayout
			px={4}
			header={
				<Center flexDirection="column" mb={8}>
					<Box>
						<FiSecurityIcon width={80} height={80} />
					</Box>
					<Text as="h1" textAlign="center" fontSize={28} fontWeight={500} my={2}>
						Secret Key
					</Text>
					<Text textAlign="center" color="gray.400">
						Enter your Secret Key
					</Text>
				</Center>
			}
			footer={
				<Box px={4} my={4}>
					<CTAButton
						size="lg"
						isDisabled={!secretKey}
						fontWeight="medium"
						colorScheme="cyan"
						width="100%"
						onClick={handleRecovery}
					>
						Continue
					</CTAButton>
				</Box>
			}
		>
			<InputPassword label="Secret Key" value={secretKey} onChange={(e) => setSecretKey(e.target.value)} />
			<Text mt={8} fontSize="xs" fontWeight={500}>
				Please enter the Secret Key used in your previous device.
			</Text>
			{recoveryType === RECOVERY_TYPE.FILE && (
				<Center flexDirection="column" mt={4}>
					<input type="file" accept=".txt" id="upload" hidden onChange={handleChangeFile} />
					<Box
						as="label"
						htmlFor="upload"
						fontSize="xs"
						textDecor="underline"
						cursor="pointer"
						fontWeight={500}
					>
						Or Choose another file
					</Box>
				</Center>
			)}
		</AppLayout>
	);
};
