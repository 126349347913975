import { useQuery } from '@tanstack/react-query';
import { isEmpty } from 'lodash-es';
import { suiWallet } from '@/app-cores/mpc-wallet/sui';
import { TSentSUiTransaction } from '@/app-types';
import { createTokenTransferTransaction } from './createTokenTransferTransaction';
import { useGetCoins } from './useGetCoins';

export const useEstimateSuiGasFee = (sendData: TSentSUiTransaction) => {
	const { data: coins } = useGetCoins(sendData?.token?.platforms.SUI);
	const response = useQuery({
		queryKey: ['estimate-transfer-sui-asset', sendData?.amount, sendData?.to],
		queryFn: async () => {
			const tx = await createTokenTransferTransaction({
				amount: sendData.amount,
				to: sendData.to,
				token: sendData.token,
				coins: coins,
			});
			const gas = await suiWallet.estimateGasFee(tx);
			return gas;
		},
		enabled: !isEmpty(sendData.amount) && !isEmpty(sendData.to) && !isEmpty(sendData.token) && !isEmpty(coins),
		staleTime: 1000 * 30,
	});
	return response;
};
