import React from 'react';

interface IProps extends React.SVGAttributes<HTMLOrSVGElement> {}

export const ArbIcon: React.FC<IProps> = ({ width = 56, height = 56, ...rest }) => {
	return (
		<svg width={width} height={height} viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
			<g clipPath="url(#clip0_8601_5434)">
				<path
					d="M28 56C43.464 56 56 43.464 56 28C56 12.536 43.464 0 28 0C12.536 0 0 12.536 0 28C0 43.464 12.536 56 28 56Z"
					fill="#2C374B"
				/>
				<path
					d="M11.616 20.1599V35.8399C11.616 36.8479 12.144 37.7599 13.024 38.2719L26.608 46.1119C27.472 46.6079 28.544 46.6079 29.408 46.1119L42.992 38.2719C43.856 37.7759 44.4 36.8479 44.4 35.8399V20.1599C44.4 19.1519 43.872 18.2399 42.992 17.7279L29.408 9.88787C28.544 9.39187 27.472 9.39187 26.608 9.88787L13.024 17.7279C12.16 18.2239 11.632 19.1519 11.632 20.1599H11.616Z"
					fill="#213147"
				/>
				<path
					d="M30.96 31.04L29.024 36.352C28.976 36.496 28.976 36.656 29.024 36.816L32.352 45.952L36.208 43.728L31.584 31.04C31.472 30.752 31.072 30.752 30.96 31.04Z"
					fill="#12AAFF"
				/>
				<path
					d="M34.848 22.112C34.736 21.824 34.336 21.824 34.224 22.112L32.288 27.424C32.24 27.568 32.24 27.728 32.288 27.888L37.744 42.848L41.6 40.624L34.848 22.128V22.112Z"
					fill="#12AAFF"
				/>
				<path
					d="M28 10.48C28.096 10.48 28.192 10.512 28.272 10.56L42.96 19.04C43.136 19.136 43.232 19.328 43.232 19.52V36.48C43.232 36.672 43.12 36.864 42.96 36.96L28.272 45.44C28.192 45.488 28.096 45.52 28 45.52C27.904 45.52 27.808 45.488 27.728 45.44L13.04 36.96C12.864 36.864 12.768 36.672 12.768 36.48V19.504C12.768 19.312 12.88 19.12 13.04 19.024L27.728 10.544C27.808 10.496 27.904 10.464 28 10.464V10.48ZM28 8C27.472 8 26.96 8.128 26.48 8.4L11.792 16.88C10.848 17.424 10.272 18.416 10.272 19.504V36.464C10.272 37.552 10.848 38.544 11.792 39.088L26.48 47.568C26.944 47.84 27.472 47.968 28 47.968C28.528 47.968 29.04 47.84 29.52 47.568L44.208 39.088C45.152 38.544 45.728 37.552 45.728 36.464V19.504C45.728 18.416 45.152 17.424 44.208 16.88L29.504 8.4C29.04 8.128 28.512 8 27.984 8H28Z"
					fill="#9DCCED"
				/>
				<path d="M18.272 42.864L19.632 39.152L22.352 41.408L19.808 43.744L18.272 42.864Z" fill="#213147" />
				<path
					d="M26.752 18.3041H23.024C22.752 18.3041 22.496 18.4801 22.4 18.7361L14.416 40.6241L18.272 42.8481L27.072 18.7361C27.152 18.5121 26.992 18.2881 26.768 18.2881L26.752 18.3041Z"
					fill="white"
				/>
				<path
					d="M33.28 18.3041H29.552C29.28 18.3041 29.024 18.4801 28.928 18.7361L19.808 43.7281L23.664 45.9521L33.584 18.7361C33.664 18.5121 33.504 18.2881 33.28 18.2881V18.3041Z"
					fill="white"
				/>
			</g>
			<defs>
				<clipPath id="clip0_8601_5434">
					<rect width="56" height="56" fill="white" />
				</clipPath>
			</defs>
		</svg>
	);
};
