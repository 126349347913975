import { PublicKey } from '@solana/web3.js';

export const LOCAL_STORE_KEYS = {
	KEY_PAIR: {
		PUBLIC_KEY: 'publicKey',
		SECRET_KEY: 'secretKey',
	},
	SHARED_SECRET: 'sharedSecret',
	PHANTOM_WALLET_PUBLICKEY: 'phantom_wallet_publicKey',
	PHANTOM_CONNECT_SESSION: 'phantom_connect_session',
};

export const phantomConnectStorages = {
	setPhanTomWalletPublicKey: (v: string) => {
		localStorage.setItem(LOCAL_STORE_KEYS.PHANTOM_WALLET_PUBLICKEY, v);
	},
	getPhanTomWalletPublicKey: () => {
		const publicKey = localStorage.getItem(LOCAL_STORE_KEYS.PHANTOM_WALLET_PUBLICKEY);
		if (!publicKey) return null;
		return new PublicKey(publicKey!);
	},
	setSession: (v: string) => {
		return localStorage.setItem(LOCAL_STORE_KEYS.PHANTOM_CONNECT_SESSION, v);
	},
	getSession: () => {
		return localStorage.getItem(LOCAL_STORE_KEYS.PHANTOM_CONNECT_SESSION);
	},
	setSharedSecret: (v: string) => {
		return localStorage.setItem(LOCAL_STORE_KEYS.SHARED_SECRET, v);
	},
	getSharedSecret: () => {
		return localStorage.getItem(LOCAL_STORE_KEYS.SHARED_SECRET);
	},
	setKeyPairPublicKey: (v: string) => {
		localStorage.setItem(LOCAL_STORE_KEYS.KEY_PAIR.PUBLIC_KEY, v);
	},
	getKeyPairPublicKey: () => {
		return localStorage.getItem(LOCAL_STORE_KEYS.KEY_PAIR.PUBLIC_KEY);
	},
	setKeyPairSecretKey: (v: string) => {
		localStorage.setItem(LOCAL_STORE_KEYS.KEY_PAIR.SECRET_KEY, v);
	},
	getKeyPairSecretKey: () => {
		return localStorage.getItem(LOCAL_STORE_KEYS.KEY_PAIR.SECRET_KEY);
	},
	reset: () => {
		localStorage.removeItem(LOCAL_STORE_KEYS.KEY_PAIR.PUBLIC_KEY);
		localStorage.removeItem(LOCAL_STORE_KEYS.KEY_PAIR.SECRET_KEY);
		localStorage.removeItem(LOCAL_STORE_KEYS.SHARED_SECRET);
		localStorage.removeItem(LOCAL_STORE_KEYS.PHANTOM_WALLET_PUBLICKEY);
		localStorage.removeItem(LOCAL_STORE_KEYS.PHANTOM_CONNECT_SESSION);
	},
};

export const METHODS = {
	onConnect: 'onConnect',
	onDisconnect: 'onDisconnect',
	onSignAndSendTransaction: 'onSignAndSendTransaction',
};
