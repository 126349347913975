import { MpcWallet } from '@/app-cores/mpc-wallet/wallet';
import { getShortAddress } from '@/app-helpers/address';
import { formatCurrency, formatUsd } from '@/app-helpers/number';
import { Box, Center, Image, Skeleton, Text } from '@chakra-ui/react';
import { fromNano } from '@ton/core';
import { TON_TOKEN } from '../views/SessionSendTransactionModal';
import { useEstimateTonGasFee } from '../hooks/useEstimateGasFee';
import { usePriceNativeToken, useTokenPriceSingleToken } from '@/app-hooks/api/portfolio/useTokenPrices';
import { ChainId, NATIVE_TOKEN_ADDRESS } from '@/app-constants/chains';
import { TransactionItemInfo } from '@/app-features/app-wallet-connect/components/TransactionItemInfo';
import { CopyToClipboard } from '@/app-components/common';

interface CommonTransactionInfoProps {
	params: string;
}
export const CommonTransactionInfo: React.FC<CommonTransactionInfoProps> = ({ params }) => {
	const { data: estimateGasFee, isPending: isEstimating } = useEstimateTonGasFee(params);
	const { data: tonPrice } = usePriceNativeToken({
		chainId: ChainId.TON,
	});
	const walletAddress = MpcWallet.getTonWalletAddress();

	return (
		<Box>
			<TransactionItemInfo
				title="Network"
				value={
					<Center gap={2}>
						<Text>{TON_TOKEN?.symbol}</Text>
						<Image borderRadius="100%" src={TON_TOKEN.image} width={6} height={6}></Image>
					</Center>
				}
			/>
			<TransactionItemInfo
				title="Account"
				value={
					<CopyToClipboard text={walletAddress}>
						{getShortAddress(walletAddress, {
							start: 12,
							end: 8,
						})}
					</CopyToClipboard>
				}
			/>
			<TransactionItemInfo
				title="Gas fee"
				value={
					<Box>
						{isEstimating ? (
							<Skeleton opacity="0.1" height={'21px'} width="160px" />
						) : (
							`${formatCurrency(+fromNano(estimateGasFee))} TON ≈ ${formatUsd(
								+fromNano(estimateGasFee) * (tonPrice || 0),
							)} `
						)}
					</Box>
				}
			/>
		</Box>
	);
};
