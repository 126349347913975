import { useRef, useState } from 'react';
import { Box, Button, Center, Flex, Skeleton, Spinner, Text } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { SnapItem, SnapList, useDragToScroll } from 'react-snaplist-carousel';
import { BlockHeader } from '@/app-components/common';
import TokenLogo from '@/app-components/common/Avatar/TokenLogo';
import { ITokenSearch } from '@/app-cores/api/bff';
import { formatUsd } from '@/app-helpers/number';
import { useGetTrendingTokens } from '@/app-hooks/api/portfolio';
import { PriceChange } from '@/app-views/portfolio/pages/token/components/Balance';
import { FiHotIcon } from '@/assets/icons/fi-hot-icon';
import { ChainId } from '@/app-constants/chains';
import { DefinedFiResolution } from '@/app-store/settings';
import NoData from '@/app-components/common/NoData';
import { getTokenInfo, selectTokenDefaultFromUniversalToken } from '@/app-helpers/token';
import { navigateToTokenDetail, useNavigateToSwapPage } from '@/app-helpers/navigate';

const TrendingItem = ({ balance }: { balance: ITokenSearch }) => {
	const {
		symbol,
		percentChange24h: usdPrice24hPercentChange,
		priceUsd: usdPrice,
		chainId,
		logo,
	} = getTokenInfo(balance);

	const navigate = useNavigateToSwapPage();
	const [loading, setLoading] = useState(false);
	const onSelectToken = async (el: ITokenSearch) => {
		const { idTobi, chainTokens } = getTokenInfo(el);
		if (!chainTokens?.length) navigateToTokenDetail({ tobiId: idTobi });
		else {
			setLoading(true);
			const tokenOut = selectTokenDefaultFromUniversalToken(el);
			await navigate({ tokenOut });
			setLoading(false);
		}
	};
	return (
		<Box
			onClick={() => {
				const { idTobi } = getTokenInfo(balance);
				navigateToTokenDetail({ tobiId: idTobi });
			}}
			backgroundColor="gray.100"
			minWidth={'130px'}
			display="flex"
			justifyContent="space-between"
			alignItems="center"
			flexDirection="column"
			padding={3}
			height={'150px'}
			borderRadius="1rem"
			gap={'4px'}
			cursor={'pointer'}
			position={'relative'}
		>
			<TokenLogo chainId={chainId} logo={logo} size={32} />
			<Text fontSize="12px">{symbol}</Text>
			<Flex alignItems={'center'} gap={'4px'} lineHeight={'12px'}>
				{usdPrice ? (
					<>
						<Text fontSize={'10px'} fontWeight={500}>
							{formatUsd(usdPrice)}
						</Text>
						{!!usdPrice24hPercentChange && <PriceChange price={usdPrice24hPercentChange} fontSize="10px" />}
					</>
				) : (
					<Skeleton opacity="0.1" height={'12px'} width="40px" />
				)}
			</Flex>
			<Button
				onClick={(e) => {
					e.stopPropagation();
					onSelectToken(balance);
				}}
				width="100%"
				size="sm"
				bgColor="white"
				color="black"
				border="1px solid rgba(0, 0, 0, 0.08)"
				borderRadius={8}
			>
				{loading ? <Spinner size={'sm'} /> : 'Trade'}
			</Button>
		</Box>
	);
};

const RESOLUTIONS = [
	{ label: '1h', value: DefinedFiResolution.ONE_HOUR, order: 5, trending: true },
	{ label: '4h', value: DefinedFiResolution.FOUR_HOURS, order: 6, trending: true },
	{ label: '1d', value: DefinedFiResolution.ONE_DAY, order: 8, trending: true },
	{ label: '12h', value: DefinedFiResolution.TWELVE_HOURS, order: 7, trending: true },
];

const options = RESOLUTIONS.filter((e) => e.trending).sort((a, b) => a.order - b.order);

export function TrendingToken() {
	const [chainId, setChain] = useState<ChainId>();
	const [resolution, setResolution] = useState<DefinedFiResolution>(DefinedFiResolution.ONE_DAY);
	const { data: trendingTokens = [], isFetching } = useGetTrendingTokens({
		chainId,
		resolution,
	});

	const { t } = useTranslation();
	const refList = useRef<HTMLDivElement>(null);
	useDragToScroll({ ref: refList });

	return (
		<Box>
			<SnapList ref={refList} direction="horizontal" snapType="mandatory">
				{isFetching ? (
					new Array(3).fill('').map((_, el) => (
						<SnapItem key={el} snapAlign="center" margin={{ right: '8px' }}>
							<Skeleton endColor={'gray.200'} height="150px" width={'130px'} borderRadius="1rem" />
						</SnapItem>
					))
				) : !trendingTokens?.length ? (
					<Center width={'100%'}>
						<NoData msg={t('cryptos.notFoundToken')} />
					</Center>
				) : (
					trendingTokens?.map((item, i) => {
						return (
							<SnapItem key={i} snapAlign="center" margin={{ right: '8px' }}>
								<TrendingItem balance={item} />
							</SnapItem>
						);
					})
				)}
			</SnapList>
		</Box>
	);
}
