import { Box, Flex, Text } from '@chakra-ui/layout';
import { Link, useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { isEmpty } from 'lodash-es';
import { Card, Grid, Image } from '@chakra-ui/react';
import { NAVIGATE_PATHS } from '@/app-constants/router';
import NoData from '@/app-components/common/NoData';
import PageTransition from '@/app-components/layout/PageTransition/PageTransition';
import PageNavigation from '@/app-components/layout/PageWrapper/PageWrapper';
import { useTranslation } from 'react-i18next';
import { DappInputSearch } from '../components/DappSearch';
import { useQueryDapps } from '@/app-hooks/api/dapps/useQueryDapp';
import { useDebounce } from '@/app-hooks/common';
import { TDapp } from '@/app-cores/api/dapp/type';
import { Loading } from '@/app-components/common';
import { FeatureConfigWrap } from '@/app-helpers/components';
import { FeatureConfig } from '@/app-hooks/api/configuration';
interface DappCategoryProps {
	data: TDapp[];
	searchValue: string;
}
const DappCategory: React.FC<DappCategoryProps> = ({ data }) => {
	const { t } = useTranslation();
	if (isEmpty(data))
		return (
			<Card py={4} mt={2}>
				<NoData msg={t('dapp.noDappFound')}></NoData>
			</Card>
		);
	return (
		<Flex flexDirection={'column'} gap="16px" color="gray.500">
			<Text fontSize={'12px'} fontWeight={'500'} m={0}>
				{t('dapp.favorite')}
			</Text>
			<Grid templateColumns="repeat(4, 1fr)" rowGap={4} columnGap={2}>
				{data.map((dApp, i) => {
					return (
						<Link
							key={i}
							to={`${NAVIGATE_PATHS.Dapp.TobiDapp}/?dappUrl=${dApp.url}&parentUrl=${NAVIGATE_PATHS.Dapp.DappBrowser}`}
						>
							<Box
								backgroundColor="gray.100"
								display="flex"
								justifyItems="center"
								alignItems="center"
								flexDirection="column"
								padding={3}
								gap={'8px'}
								borderRadius="1rem"
							>
								<Box width={8} height={8}>
									<Image src={dApp.logoUrl} width={8} height={8} borderRadius="50%" loading="lazy" />
								</Box>
								<Text fontSize="xs" whiteSpace="nowrap">
									{dApp.name}
								</Text>
							</Box>
						</Link>
					);
				})}
			</Grid>
		</Flex>
	);
};

export const DappBrowser = () => {
	const [searchValue, setSearchValue] = useState('');
	const searchValueDebounce = useDebounce(searchValue);
	const { data, isFetching, isLoading } = useQueryDapps({
		name: searchValueDebounce,
	});
	const navigation = useNavigate();

	return (
		<PageTransition
			style={{
				flexDirection: 'column',
				flexGrow: 1,
				overflow: 'hidden',
			}}
		>
			<PageNavigation onBack={() => navigation('/')}>
				<Box overflow="auto" height="100%">
					<Flex flexDirection="column" height="100%" position="relative">
						<Box className="pageFixed" position="absolute" left={0}>
							<FeatureConfigWrap feature={FeatureConfig.DAPP_BROWSER_SEARCH_BAR}>
								<Box px={4} pt={4}>
									<DappInputSearch
										onInputValueChange={(value) => setSearchValue(value)}
										inputValue={searchValue}
										data={data?.Dapps || []}
										isFetching={isFetching}
									/>
									<Box as="hr" mt={5} mb={6} />
								</Box>
							</FeatureConfigWrap>
							<Box
								mt={0}
								flexDirection="column"
								overflow="hidden"
								className="scrollbar-trigger"
								flexGrow={1}
							>
								<Box px={4} overflow="auto" className=" hide-scrollbar" height="100%">
									{isLoading && <Loading opacity={0.8} />}
									<DappCategory data={data?.Dapps || []} searchValue={searchValue} />
								</Box>
							</Box>
						</Box>
					</Flex>
				</Box>
			</PageNavigation>
		</PageTransition>
	);
};
