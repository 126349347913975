import { motion } from 'framer-motion';
import { useNavigationType } from 'react-router';

const PUSH_ANIMATION = {
	initial: {
		opacity: 0,
		x: '100%',
	},
	animate: {
		opacity: 1,
		x: '0%',
	},
	exit: {
		opacity: 0,
		x: '100%',
	},
};
const POP_ANIMATION = {
	initial: {
		opacity: 0,
		x: '-100%',
	},
	animate: {
		opacity: 1,
		x: '0%',
	},
	exit: {
		opacity: 0,
		x: '-100%',
	},
};
interface PageTransitionProps {
	style?: React.CSSProperties;
	disableTransition?: boolean;
}
const PageTransition: React.FunctionComponent<React.PropsWithChildren<PageTransitionProps>> = ({
	children,
	style,
	disableTransition,
}) => {
	const action = useNavigationType();
	const animate = action === 'POP' ? POP_ANIMATION : PUSH_ANIMATION;

	function onComplete() {
		const pageMotion = document.getElementById('pageMotion');
		setTimeout(() => (pageMotion.style.transform = 'none'), 1);
	}

	const initial = disableTransition ? undefined : animate.initial;
	const animateProp = disableTransition ? undefined : animate.animate;
	const exit = disableTransition ? undefined : animate.exit;

	return (
		<motion.div
			id="pageMotion"
			initial={initial}
			animate={animateProp}
			exit={exit}
			transition={
				disableTransition
					? undefined
					: {
							ease: 'linear',
							duration: 0.2,
					  }
			}
			onAnimationComplete={onComplete}
			style={style}
		>
			{children}
		</motion.div>
	);
};
export default PageTransition;
