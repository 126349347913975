import { UseQueryOptions, useQuery } from '@tanstack/react-query';
import { TApproveParams, useERC20Contract, useTRC20Contract, useWallet } from '.';
import { QUERY_KEYS } from '@/app-constants';
import { isNativeToken } from '@/app-helpers/address';
import { isChainNeedApprove, isEvmChain, isTronChain } from '@/app-helpers/token';
import { MpcWallet } from '@/app-cores/mpc-wallet/wallet';

export function useAllowance(
	{ spenderContract, tokenContract, chainId }: TApproveParams,
	options?: UseQueryOptions<bigint>,
) {
	const { address } = useWallet();

	const erc20Contract = useERC20Contract(tokenContract, chainId);
	const trc20Contract = useTRC20Contract(tokenContract, chainId);

	const allowance = useQuery({
		queryKey: [QUERY_KEYS.GET_TOKEN_ALLOWANCE, chainId, tokenContract, spenderContract],
		queryFn: async () => {
			if (!spenderContract || isNativeToken(tokenContract)) return null;

			if (isEvmChain(chainId)) return erc20Contract.allowance(address, spenderContract);

			if (isTronChain(chainId)) {
				const data = await trc20Contract.methods
					.allowance(MpcWallet.getTronWalletAddress(), spenderContract)
					.call();
				return data?.[0];
			}

			return null;
		},
		enabled: isChainNeedApprove(chainId) && !!tokenContract && !!spenderContract,
		...options,
	});
	return allowance;
}
