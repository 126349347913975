import { ChainId } from '@/app-constants/chains';
import { NAVIGATE_PATHS } from '@/app-constants/router';
import { QueryTokenParam } from '@/app-cores/api/bff';
import { useNavigateToSwapPage } from '@/app-helpers/navigate';
import { getNativeTobiId, getTokenInfo } from '@/app-helpers/token';
import { useSearchListToken } from '@/app-hooks/api/portfolio';
import { useSwapStore } from '@/app-store/swap';
import { BASE_BORDER_COLOR } from '@/app-theme/theme';
import SwapWidget from '@/app-views/swap/components/SwapWidget';
import { FiArrowLeftIcon, FiSwapIcon } from '@/assets/icons';
import { FiSwapIconColor } from '@/assets/icons/fi-swap-icon-color';
import { Box, Flex, Tab, TabList, TabPanel, TabPanels, Tabs, Text } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';

const query: QueryTokenParam[] = [
	{ tobiId: getNativeTobiId(ChainId.SOL), chainId: ChainId.SOL },
	{ tobiId: getNativeTobiId(ChainId.TON), chainId: ChainId.TON },
];
export const Swap = () => {
	const [showConfirm, setShowConfirm] = useState(false);
	const { setPair, tokenIn, tokenOut } = useSwapStore(true);
	const { data } = useSearchListToken({ query });

	useEffect(() => {
		data && setPair(data?.[0], data?.[1]);
	}, [data, setPair]);

	const navigate = useNavigateToSwapPage();
	const navigateToSwap = () => {
		navigate({ tokenIn, tokenOut });
	};

	const { t } = useTranslation();
	return (
		<Box bgColor="white" borderRadius={8} p={4}>
			<Flex justifyContent={'space-between'} borderBottom={`1px solid ${BASE_BORDER_COLOR}`} pb={2}>
				<Flex
					alignItems={'center'}
					gap={2}
					fontSize={'12px'}
					fontWeight={'500'}
					onClick={() => {
						if (showConfirm) setShowConfirm(false);
					}}
				>
					<FiSwapIconColor height={24} width={24} />
					<Text lineHeight={'24px'} fontSize="xs" fontWeight={600}>
						Trade
					</Text>
				</Flex>
				<Text as="u" fontSize={'12px'} onClick={navigateToSwap} cursor="pointer">
					{t('tokenTrading.advancedOptions')}
				</Text>
			</Flex>

			<SwapWidget
				confirmAsModal
				disableTransition
				selectTokenProps={{ px: 0 }}
				onBuildRouteSuccess={() => setShowConfirm(true)}
				onBack={() => setShowConfirm(false)}
				onViewActivity={() => setShowConfirm(false)}
				showConfirm={showConfirm}
				containerProps={{ style: { paddingBottom: 0 } }}
				actionProps={{ borderBottom: 'none' }}
				confirmActionProps={{ p: 0, pt: 4 }}
			/>
		</Box>
	);
};
