import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import ChainedBackend from 'i18next-chained-backend';
import HttpBackend from 'i18next-http-backend';
import LocalStorageBackend from 'i18next-localstorage-backend';
import intervalPlural from 'i18next-intervalplural-postprocessor';
import { ServiceTranslate } from '../api/bot/service-translate';

const STORAGE_PREFIX = 'TOBI_';

intervalPlural.setOptions({
	intervalSeparator: ';',
	intervalRegex: /\((\S*)\).*{((.|\n)*)}/,
	intervalSuffix: '_interval',
});
export function initI18n() {
	i18n.use(ChainedBackend)
		.use(intervalPlural)
		.use(initReactI18next)
		.init({
			fallbackLng: 'en',
			lng: 'en',
			interpolation: {
				escapeValue: false,
			},
			backend: {
				backends: [LocalStorageBackend, HttpBackend],
				backendOptions: [
					{
						prefix: STORAGE_PREFIX,
						expirationTime: 0,
					},
					{
						loadPath: '{{lng}}',
						/** Use local file */
						// loadPath: '/locales/{{lng}}.json',
						request: async (options, url, _, callback) => {
							try {
								/** Use local file */
								// const rs = await fetch(url, options);
								// const resource = await rs.json();
								const resource = await ServiceTranslate.getLanguage(url);
								callback(null, {
									data: resource.webApps,
									status: 200,
								});
							} catch (error) {
								const cacheResource = localStorage.getItem(`${STORAGE_PREFIX}en-translation`);
								console.error('load language resource error', error);
								callback(null, {
									data: cacheResource,
									status: 200,
								});
							}
						},
					},
				],
			},
		});
}
export default i18n;
