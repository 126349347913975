import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import { Avatar, Box, Button, Center, Container, Flex, List, ListItem, Skeleton, Text } from '@chakra-ui/react';
import { Badge, Loading, RequireWallet } from '@/app-components/common';
import PageTransition from '@/app-components/layout/PageTransition/PageTransition';
import PageNavigation from '@/app-components/layout/PageWrapper/PageWrapper';
import { NAVIGATE_PATHS } from '@/app-constants/router';
import { displayUserName } from '@/app-helpers/display';
import { useQueryInviteCodesHistory } from '@/app-hooks/api/user/useQueryUserInviteCodes';
import { useQueryUserProfile } from '@/app-hooks/api/user/useQueryUserProfile';
import {
	FiAddFriend,
	FiAlertIcon,
	FiBuoyIcon,
	FiContactIcon,
	FiFileIcon,
	FiFileMinusIcon,
	FiGiftIcon,
} from '@/assets/icons';
import { DappConnections } from './components/DappConnnections';
import { FeatureConfigWrap } from '../../app-helpers/components';
import { FeatureConfig } from '../../app-hooks/api/configuration';
import { getEnvironment } from '@/app-helpers';
import { TelegramCore } from '@/app-cores/telegram';
import { TOBI_SUPPORT_BOT_LINK } from '@/app-constants';
import { FiContactSupportIcon } from '@/assets/icons/fi-support-icon';

export const MAPPING_BACKUP_TYPE = {
	'drive': 'storeBackup.backupViaDrive',
	'email': 'storeBackup.backupViaEmail',
	'telegram': 'storeBackup.backupTelegram',
};
const AccountSetting = () => {
	const { t } = useTranslation();
	const { data: userProfile } = useQueryUserProfile();
	const { data: { totalWalletUser } = {} } = useQueryInviteCodesHistory();
	const navigate = useNavigate();
	if (!userProfile) {
		return <Loading />;
	}
	const backupIdentifier = userProfile?.backupType === 'telegram' ? '@' : '';
	const backupInfo = userProfile?.backupEmail ? `: ${backupIdentifier}${userProfile.backupEmail}` : '';

	{
		backupInfo;
	}
	return (
		<PageTransition>
			<PageNavigation>
				<Container position="relative" pb={4} className="overflow-y-auto hide-scrollbar">
					<Center
						px={4}
						py={3}
						backgroundColor="white"
						border="1px solid rgba(255, 255, 255, 0.08)"
						borderRadius={12}
						gap={3}
						mt={3}
						justifyContent="start"
					>
						<Avatar
							name={userProfile.firstName || userProfile.lastName}
							src={userProfile.avatar}
							width={10}
							height={10}
						/>
						<Box>
							<Text fontSize="sm" fontWeight={400}>
								{userProfile.firstName} {userProfile.lastName}
							</Text>
							<Text fontSize="xs" color="gray.400">
								{displayUserName(userProfile.username)}
							</Text>
						</Box>
					</Center>

					<List backgroundColor="white" borderRadius={12} mt={3} pb={12}>
						<FeatureConfigWrap feature={FeatureConfig.INVITATION_CODE}>
							<RequireWallet
								loadingComponent={
									<Skeleton height="50px" width="100%" startColor="white" endColor="#EBEFF6" />
								}
							>
								<ListItem
									onClick={() => navigate(NAVIGATE_PATHS.AccountSettings.InvitationCode)}
									display="flex"
									alignItems="center"
									cursor="pointer"
									px={4}
									borderBottom="1px dashed rgba(0, 0, 0, 0.08)"
								>
									<Box className="w-100">
										<Center justifyContent="space-between">
											<Center>
												<FiAddFriend />
												<Text ml={3} py={4} fontSize="sm">
													{t('appSetting.inviteFriends')}
												</Text>
											</Center>
											{totalWalletUser > 0 && (
												<Badge type="success" color="black" width={6} height={6}>
													{totalWalletUser}
												</Badge>
											)}
										</Center>
									</Box>
								</ListItem>
							</RequireWallet>
						</FeatureConfigWrap>

						<FeatureConfigWrap feature={FeatureConfig.DAPP_SETTING}>
							<RequireWallet
								loadingComponent={
									<Skeleton height="50px" width="100%" startColor="white" endColor="#EBEFF6" />
								}
							>
								<ListItem
									onClick={() => navigate(NAVIGATE_PATHS.Wallet.WCconnection)}
									px={4}
									display="flex"
									alignItems="center"
									cursor="pointer"
								>
									<DappConnections />
								</ListItem>
							</RequireWallet>
						</FeatureConfigWrap>

						{!userProfile.backupType ? (
							<Box className="w-100">
								<RequireWallet
									loadingComponent={
										<Skeleton height="50px" width="100%" startColor="white" endColor="#EBEFF6" />
									}
								>
									<ListItem
										onClick={() => {
											navigate(`${NAVIGATE_PATHS.CreateWallet.StoreBackupKey}?fromSettings=true`);
										}}
										px={4}
										display="flex"
										alignItems="center"
										cursor="pointer"
										justifyContent="space-between"
									>
										<Center>
											<FiBuoyIcon />
											<Text ml={3} py={4} fontSize="sm">
												{t('appSetting.backupYourKey')}
											</Text>
										</Center>
										<FiAlertIcon width={24} height={24} fill="#FF4D00" />
									</ListItem>
								</RequireWallet>
							</Box>
						) : (
							<ListItem px={4} display="flex" alignItems="center">
								<Center flex={1} justifyContent="space-between">
									<Center>
										<FiBuoyIcon />
										<Box ml={3} py={4}>
											<Text fontSize="sm">{t('appSetting.accountBackup')}</Text>
											<Text fontSize="xs" color="gray.400" fontWeight={400}>
												{t(MAPPING_BACKUP_TYPE[userProfile?.backupType])}
												{backupInfo}
											</Text>
										</Box>
									</Center>
								</Center>
							</ListItem>
						)}

						<Link to={NAVIGATE_PATHS.AccountSettings.TermOfService}>
							<ListItem px={4} display="flex" alignItems="center">
								<Center>
									<FiFileMinusIcon />
									<Text ml={3} py={4} fontSize="sm">
										{t('appSetting.termsOfService')}
									</Text>
								</Center>
							</ListItem>
						</Link>
						<Link to={NAVIGATE_PATHS.AccountSettings.PrivacyPolicy}>
							<ListItem px={4} display="flex" alignItems="center">
								<Center>
									<FiFileIcon />
									<Text ml={3} py={4} fontSize="sm">
										{t('appSetting.privacyPolicy')}
									</Text>
								</Center>
							</ListItem>
						</Link>
						<FeatureConfigWrap feature={FeatureConfig.CANCEL_PENDING_TRANSACTIONS}>
							<Link to={NAVIGATE_PATHS.CancelPendingTransactions}>
								<ListItem px={4} display="flex" alignItems="center">
									<Center>
										<FiFileIcon />
										<Text ml={3} py={4} fontSize="sm">
											Cancel Pending Transactions
										</Text>
									</Center>
								</ListItem>
							</Link>
						</FeatureConfigWrap>

						<ListItem
							display="flex"
							alignItems="center"
							onClick={() => {
								TelegramCore.WebApp.openTelegramLink(TOBI_SUPPORT_BOT_LINK);
							}}
							px={4}
						>
							<Center>
								<FiContactSupportIcon />
								<Text ml={3} py={4} fontSize="sm">
									Contact Support
								</Text>
							</Center>
						</ListItem>

						{window.location.host === 'telifi.local:3001' && (
							<>
								<Link to={NAVIGATE_PATHS.Testing.AppIcon}>
									<ListItem display="flex" alignItems="center">
										<Center>
											<FiFileIcon />
											<Text ml={3} py={4} fontSize="sm">
												Icons list
											</Text>
										</Center>
									</ListItem>
								</Link>
							</>
						)}
					</List>
					<Button
						size="md"
						fontWeight="medium"
						colorScheme="cyan"
						position="fixed"
						right={8}
						bottom={20}
						onClick={() => {
							TelegramCore.WebApp.openTelegramLink(TOBI_SUPPORT_BOT_LINK);
						}}
					>
						<FiContactIcon />
						<Text ml={1}>{t('help')}</Text>
					</Button>
				</Container>
			</PageNavigation>
		</PageTransition>
	);
};
export default AccountSetting;
