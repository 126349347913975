import React from 'react';

interface IProps extends React.SVGAttributes<HTMLOrSVGElement> {}

export const TonIcon: React.FC<IProps> = ({ width = 56, height = 56, ...rest }) => {
	return (
		<svg width={width} height={height} viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
			<g clipPath="url(#clip0_8601_5431)">
				<path
					d="M28 56C43.464 56 56 43.464 56 28C56 12.536 43.464 0 28 0C12.536 0 0 12.536 0 28C0 43.464 12.536 56 28 56Z"
					fill="#0088CC"
				/>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M20.2 18.5H35.9C36.5 18.5 37 18.6 37.6 18.9C38.3 19.2 38.7 19.7 38.9 20.1C38.9 20.1 38.9 20.2 39 20.2C39.3 20.7 39.5 21.3 39.5 22C39.5 22.6 39.4 23.2 39 23.8L29.1 40.8C28.9 41.2 28.5 41.4 28 41.4C27.6 41.4 27.2 41.2 26.9 40.8L17.2 23.8C17 23.4 16.6 22.9 16.6 22.1C16.5 21.4 16.7 20.8 17 20.2C17.3 19.6 17.8 19.1 18.5 18.9C19.1 18.5 19.8 18.5 20.2 18.5ZM26.8 20.9H20.2C19.8 20.9 19.6 20.9 19.5 21C19.3 21.1 19.2 21.2 19.1 21.4C19 21.5 19 21.7 19 21.9C19 22 19.1 22.1 19.3 22.5L26.8 35.5V20.9ZM29.2 20.9V35.6L36.8 22.5C36.9 22.3 36.9 22.1 36.9 21.9C36.9 21.7 36.9 21.5 36.8 21.4C36.7 21.3 36.7 21.2 36.6 21.2L36.5 21.1C36.3 21 36.1 21 35.8 21H29.2V20.9Z"
					fill="white"
				/>
			</g>
			<defs>
				<clipPath id="clip0_8601_5431">
					<rect width="56" height="56" fill="white" />
				</clipPath>
			</defs>
		</svg>
	);
};
