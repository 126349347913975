import { MarketingServiceAPI } from '@/app-cores/api/dapp/service-dapp';
import { DappQuery } from '@/app-cores/api/dapp/type';
import { useQuery, keepPreviousData } from '@tanstack/react-query';
import { ONE_MINUTE } from '../portfolio/constant';
import axios from 'axios';
import { MpcWallet } from '@/app-cores/mpc-wallet/wallet';
import { QUERY_KEYS } from '@/app-constants';

export enum FARM_IDS {
	DEBRIDGE = 'DEBRIDGE',
	BERA = 'BERA',
	KYBER = 'KYBER',
}

export type TobiFarmType = {
	id: string;
	key: FARM_IDS;
	order: number;
	title: string;
	description: string;
	logo: string;
	showAtHome: boolean;
	ctaUrl: string;
	ctaName?: string;
	disabled: boolean;
};

export const useQueryTobiFarm = () => {
	const response = useQuery({
		queryKey: ['get-tobi-farm'],
		queryFn: async () => {
			const data = await MarketingServiceAPI.getTobiFarm();
			return data?.filter((e) => !e.disabled).sort((a, b) => a.order - b.order) ?? [];
		},
		staleTime: ONE_MINUTE * 60,
		placeholderData: keepPreviousData,
	});
	return response;
};

const fetchDebridgePoint = async (address: string): Promise<number> => {
	if (!address) return 0;
	const data = await axios.get(`https://points-api.debridge.finance/api/Points/${address}/shortSummary`);
	return data?.data?.totalPoints ?? 0;
};

export const useQueryDebridgePoint = (enabled = true) => {
	const response = useQuery({
		queryKey: [QUERY_KEYS.GET_TOBI_FARM_POINT, 'debridge'],
		queryFn: async () => {
			const { evmAddress, solAddress } = MpcWallet.getWalletAddress();
			const data = await Promise.all([fetchDebridgePoint(evmAddress), fetchDebridgePoint(solAddress)]);
			return data.reduce((rs, cur) => rs + cur, 0);
		},
		staleTime: ONE_MINUTE * 60 * 24,
		refetchInterval: ONE_MINUTE * 15,
		placeholderData: keepPreviousData,
		enabled,
	});
	return response;
};

type KyberPointInfo = {
	totalReward: string;
};
export const useQueryKyberPoint = (enabled = true) => {
	const response = useQuery({
		queryKey: [QUERY_KEYS.GET_TOBI_FARM_POINT, 'kyber'],
		queryFn: async () => {
			const { evmAddress } = MpcWallet.getWalletAddress();
			const { data } = await axios.get(
				`https://kyberswap-arbitrum-stip.kyberengineering.io/api/v1/stip/rewards?campaign=trading-incentive&wallet=${evmAddress}`,
			);
			const result: KyberPointInfo = data?.data;
			return result;
		},
		staleTime: ONE_MINUTE * 60 * 24,
		refetchInterval: ONE_MINUTE * 15,
		placeholderData: keepPreviousData,
		enabled,
	});
	return response;
};
