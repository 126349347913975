import React from 'react';

interface IProps extends React.SVGAttributes<HTMLOrSVGElement> {}

export const FiMoreIcon: React.FC<IProps> = ({ width = 16, height = 16, ...rest }) => {
	return (
		<svg width={width} height={height} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M6.66666 8.00008C6.66666 7.2637 7.26361 6.66675 7.99999 6.66675C8.73637 6.66675 9.33332 7.2637 9.33332 8.00008C9.33332 8.73646 8.73637 9.33341 7.99999 9.33341C7.26361 9.33341 6.66666 8.73646 6.66666 8.00008Z"
				fill="black"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M11.3333 8.00008C11.3333 7.2637 11.9303 6.66675 12.6667 6.66675C13.4031 6.66675 14 7.2637 14 8.00008C14 8.73646 13.4031 9.33341 12.6667 9.33341C11.9303 9.33341 11.3333 8.73646 11.3333 8.00008Z"
				fill="black"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M2 8.00008C2 7.2637 2.59695 6.66675 3.33333 6.66675C4.06971 6.66675 4.66667 7.2637 4.66667 8.00008C4.66667 8.73646 4.06971 9.33341 3.33333 9.33341C2.59695 9.33341 2 8.73646 2 8.00008Z"
				fill="black"
			/>
		</svg>
	);
};
