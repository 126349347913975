import { colors, drawerTheme } from '@/app-theme/theme';
import { FiXIcon } from '@/assets/icons';
import { Box, DrawerProps, Flex, Text } from '@chakra-ui/react';
import { CSSProperties, useEffect, useState } from 'react';
/**
 *
 * custom drawer with body still in the dom if isOpen=false
 * @returns
 */
export default function TobiDrawer({ isOpen: propIsOpen, children, title, onClose }: DrawerProps & { title: string }) {
	const [isOpen, setIsOpen] = useState(false);

	useEffect(() => {
		// Trigger the animation after the component mounts
		setTimeout(() => {
			setIsOpen(propIsOpen);
		}, 50); // A slight delay to ensure the transition applies after mount
	}, [propIsOpen]);

	return (
		<Flex
			className="custom-drawer-wrapper"
			position="fixed"
			top="0"
			left="0"
			width="100vw"
			height="100vh"
			background={colors.white}
			transform={isOpen ? 'translateY(0)' : 'translateY(100%)'}
			transition="transform 0.5s ease"
			zIndex="1000"
			direction="column"
			p={4}
			gap={4}
		>
			<Flex justify={'space-between'} style={drawerTheme.baseStyle.header as CSSProperties}>
				<Box boxSize={'16px'} />
				<Text>{title}</Text>
				<FiXIcon onClick={onClose} style={{ cursor: 'pointer' }} />
			</Flex>
			<Box style={{ display: isOpen ? 'block' : 'none', flex: 1 }} className="custom-container">
				{children}
			</Box>
		</Flex>
	);
}
