import { Tag, TagProps } from '@chakra-ui/react';
import { ReactNode } from 'react';

const TobiTag = ({
	background,
	fontSize,
	color,
	children,
	...rest
}: {
	background?: string;
	color?: string;
	fontSize?: string;
	children: ReactNode;
} & TagProps) => {
	return (
		<Tag
			onClick={(e) => e.stopPropagation()}
			display={'flex'}
			gap={1}
			alignItems={'center'}
			fontSize={fontSize || '10px'}
			fontWeight={'500'}
			color={color}
			background={background || 'rgba(0, 0, 0, 0.08)'}
			padding={'2px 6px'}
			lineHeight={'10px'}
			borderRadius={'100px'}
			minHeight={'unset'}
			width={'fit-content'}
			textTransform={'capitalize'}
			whiteSpace={'nowrap'}
			{...rest}
		>
			{children}
		</Tag>
	);
};
export default TobiTag;
