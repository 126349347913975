import { ITransactionStatus, TransactionType } from '@/app-types';
import { Box, Center, Text } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

const TransactionResultConfig = {
	processing: 'transactionResult.processing',
	success: 'transactionResult.success',
	error: 'transactionResult.error',
	canceling: 'transactionResult.canceling',
	cancel_error: 'transactionResult.cancelFailed',
	cancel_success: 'transactionResult.cancelSuccess',
};

export default function Status({ status, errorMsg }: { status: ITransactionStatus; errorMsg: string }) {
	const { t } = useTranslation();
	return (
		<Center
			fontSize="sm"
			fontWeight={400}
			color={[ITransactionStatus.ERROR, ITransactionStatus.CANCEL_ERROR].includes(status) ? '#E32A2A' : 'black'}
			textAlign="center"
			px={4}
		>
			<Text lineHeight="21px">{errorMsg || t(TransactionResultConfig[status])}</Text>{' '}
			{[ITransactionStatus.PROCESSING, ITransactionStatus.CANCELING].includes(status) && (
				<Box ml={4} transform="translateY(2px)" className="dot-loader" />
			)}
		</Center>
	);
}
