import { ButtonHome } from '@/app-components/common';
import TokenLogo from '@/app-components/common/Avatar/TokenLogo';

import { useTokenLabels } from '@/app-hooks/api/tokens/useUserTokenSettings';
import { TokenLabel } from '@/app-views/portfolio/components/ListCrypto';
import { SettingToken } from '@/app-views/portfolio/pages/token/components/SettingToken';
import { FiDollarIcon, FiShareIcon } from '@/assets/icons';
import { Box, Center, Flex, Image, Skeleton, Text } from '@chakra-ui/react';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Actions } from '../../../../../app-features/app-bot-connector/hooks';
import { useSharingStore } from '../../../../../app-components/common/Sharing';
import { TelegramCore, toStartParam } from '../../../../../app-cores/telegram';
import { formatUsd } from '../../../../../app-helpers/number';
import { useQueryUserInviteCodes } from '@/app-hooks/api/user/useQueryUserInviteCodes';
import { PriceChange } from '@/app-views/portfolio/pages/token/components/Balance';
import { ITokenSearch, TokenInfo } from '@/app-cores/api/bff';
import { useWalletStore } from '@/app-store';
import { getTokenInfo } from '@/app-helpers/token';

interface BasicInformationProps {
	showSetting?: boolean;
	data: ITokenSearch | undefined;
}

export const BasicInformation: React.FunctionComponent<BasicInformationProps> = ({ showSetting = true, data }) => {
	const tokenInfo: TokenInfo = getTokenInfo(data);
	const { symbol, percentChange24h, name, imageUrl, priceUsd, idTobi: tobiId, chainId } = tokenInfo ?? {};
	const percent24h = percentChange24h;

	const labels = useTokenLabels(data);

	return (
		<Box display="flex" alignItems="center" justifyContent="center" flexDirection={'column'} gap={2} m={1} py={3}>
			<Center position={'relative'} width={'100%'}>
				<TokenLogo size={32} symbol={symbol} logo={imageUrl} />
				<Box position={'absolute'} right={0} display={'flex'} gap={3}>
					{showSetting && (
						<SettingToken
							{...{
								isSpam: labels?.includes('spam'),
								isHidden: labels?.includes('hidden'),
								tobiId,
								chainId,
								style: {},
								tokenInfo,
							}}
						/>
					)}
				</Box>
			</Center>

			<Flex
				as="span"
				alignItems={'center'}
				fontWeight={'500'}
				fontSize={'14px'}
				gap={'4px'}
				flexWrap={'wrap'}
				justifyContent={'center'}
			>
				{name ?? '??'} ({symbol || '??'}){' '}
				{labels?.length > 0 && (
					<Flex gap={'4px'}>
						{labels.map((e) => (
							<TokenLabel key={e} type={e} />
						))}
					</Flex>
				)}
			</Flex>

			<Flex gap={'6px'}>
				<Box as="span" transform="translateY(0.875rem)">
					<FiDollarIcon />
				</Box>
				<Text as="span" minHeight={'72px'} fontSize={'5xl'}>
					{formatUsd(priceUsd, { showDollar: false })}{' '}
					{Boolean(priceUsd && percent24h) && <PriceChange price={percent24h} fontSize="16px" />}
				</Text>
			</Flex>
		</Box>
	);
};
