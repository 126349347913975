import { NAVIGATE_PATHS } from '@/app-constants/router';
import { toQueryString } from '@/app-helpers/url';
import { isEmpty } from 'lodash-es';
import { useMemo } from 'react';
import { useQueryOnrampCryptos } from '../api/on-ramp';
import { MpcWallet } from '@/app-cores/mpc-wallet/wallet';
import { isSolanaChain, isTonChain } from '@/app-helpers/token';

function getAddress(chainId: string) {
	const walletAddress = MpcWallet.getWalletAddress();
	if (isTonChain(chainId)) {
		return walletAddress.tonAddress;
	}
	if (isSolanaChain(chainId)) {
		return walletAddress.solAddress;
	}
	return walletAddress.evmAddress;
}

export const useBuyOnRamp = (chainId: string, addressOrSymbol: string, callbackUrl = '') => {
	const { data } = useQueryOnrampCryptos({
		enabled: !!addressOrSymbol,
	} as any);
	const token = useMemo(() => {
		if (isEmpty(data)) return;
		return data.find(
			(tk) =>
				[tk.address?.toLowerCase(), tk.symbol?.toLowerCase()].includes(addressOrSymbol?.toLowerCase()) &&
				tk.chainId == chainId,
		);
	}, [addressOrSymbol, chainId, data]);

	return {
		isAllowBuy: !isEmpty(token),
		url: toQueryString(NAVIGATE_PATHS.Deposit.Transak, {
			chainId: token?.chainId,
			tokenSymbol: token?.symbol,
			callbackUrl: callbackUrl,
			address: getAddress(chainId),
		}),
	};
};

export const useBuyOnTransak = () => {
	function buildTransakURL({ chainId = '', tokenSymbol = '', callbackUrl = NAVIGATE_PATHS.Home }) {
		return toQueryString(NAVIGATE_PATHS.Deposit.Transak, {
			chainId: chainId,
			tokenSymbol: tokenSymbol,
			callbackUrl: callbackUrl,
		});
	}
	return { buildTransakURL };
};
