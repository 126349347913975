import { ButtonPrimary, ButtonSecondary } from '@/app-components/common/Button/Button';
import {
	Button,
	Center,
	Drawer,
	DrawerBody,
	DrawerCloseButton,
	DrawerContent,
	DrawerFooter,
	DrawerHeader,
	DrawerOverlay,
	Text,
} from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import Picker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { useTranslation } from 'react-i18next';

const tomorrow = new Date();
tomorrow.setDate(tomorrow.getDate() + 1);

export default function DatePicker({
	onChange,
	date,
	isOpen,
	onClose,
}: {
	isOpen: boolean;
	onChange: (v: Date) => void;
	onClose: () => void;
	date: Date;
}) {
	const [value, setValue] = useState(date);
	const { t } = useTranslation();

	useEffect(() => {
		if (isOpen) {
			setValue(date);
		}
	}, [isOpen, date]);

	return (
		<Drawer isOpen={isOpen} placement="bottom" onClose={onClose}>
			<DrawerOverlay />
			<DrawerContent>
				<DrawerCloseButton />
				<DrawerHeader>{t('limit.customExpiry')}</DrawerHeader>
				<DrawerBody
					overflow={'hidden'}
					p={0}
					justifyContent={'center'}
					display={'flex'}
					className="customDatePicker"
				>
					<Picker selected={value} onChange={setValue} inline minDate={tomorrow} />
				</DrawerBody>
				<DrawerFooter>
					<ButtonSecondary variant="outline" size={'sm'} mr={3} onClick={onClose}>
						{t('button.cancel')}
					</ButtonSecondary>
					<ButtonPrimary
						size={'sm'}
						onClick={() => {
							onChange(value);
							onClose();
						}}
					>
						{t('save')}
					</ButtonPrimary>
				</DrawerFooter>
			</DrawerContent>
		</Drawer>
	);
}
