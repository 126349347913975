import { useCallback, useEffect, useRef, useState } from 'react';
import { useSnapshot } from 'valtio';
import SettingsStore from '../store/SettingsStore';
import { createWeb3Wallet, web3wallet } from '../utils/WalletConnectUtil';

export default function useInitialization() {
	const [initialized, setInitialized] = useState(false);
	const prevRelayerURLValue = useRef<string>('');

	const { relayerRegionURL } = useSnapshot(SettingsStore.state);

	const onInitialize = useCallback(async () => {
		try {
			console.log('onInitialize relayerRegionURL', relayerRegionURL);
			await createWeb3Wallet(relayerRegionURL);
			setInitialized(true);
		} catch (err: unknown) {
			console.error(err);
		}
	}, [relayerRegionURL]);

	const onRelayerRegionChange = useCallback(() => {
		try {
			web3wallet?.core?.relayer.restartTransport(relayerRegionURL);
			prevRelayerURLValue.current = relayerRegionURL;
		} catch (err: unknown) {
			console.error(err);
		}
	}, [relayerRegionURL]);

	useEffect(() => {
		if (!initialized) {
			onInitialize();
		}
		if (prevRelayerURLValue.current !== relayerRegionURL) {
			onRelayerRegionChange();
		}
	}, [initialized, onInitialize, relayerRegionURL, onRelayerRegionChange]);

	return initialized;
}
