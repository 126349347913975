import React from 'react';

interface IProps extends React.SVGAttributes<HTMLOrSVGElement> {}

export const FiBlackBoxIcon: React.FC<IProps> = ({ width = 20, height = 20, ...rest }) => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M7.00159 0.935216C7.30528 0.760214 7.64964 0.668091 8.00016 0.668091C8.3507 0.668091 8.69507 0.760222 8.99877 0.935238C8.99923 0.935505 8.9997 0.935772 9.00016 0.93604L13.6668 3.6027C13.9706 3.77807 14.2228 4.03022 14.3984 4.33387C14.5739 4.63752 14.6665 4.98198 14.6668 5.33271V10.6674C14.6665 11.0181 14.5739 11.3626 14.3984 11.6662C14.2228 11.9699 13.9706 12.222 13.6668 12.3974L13.6643 12.3989L9.00016 15.0641C8.99976 15.0643 8.99936 15.0645 8.99896 15.0648C8.69522 15.2399 8.35077 15.332 8.00016 15.332C7.64956 15.332 7.30513 15.2399 7.0014 15.0648C7.00098 15.0645 7.00057 15.0643 7.00016 15.0641L2.33607 12.3989L2.3335 12.3974C2.02976 12.222 1.77748 11.9699 1.60196 11.6662C1.42644 11.3626 1.33386 11.0181 1.3335 10.6674V5.33271C1.33386 4.98198 1.42644 4.63752 1.60196 4.33387C1.77748 4.03022 2.02976 3.77807 2.3335 3.60271L2.33607 3.60122L7.00159 0.935216ZM8.00016 2.00142C7.88314 2.00142 7.76817 2.03223 7.66683 2.09074L7.66426 2.09222L3.00016 4.75741C2.99981 4.75761 2.99946 4.75781 2.99911 4.75802C2.89833 4.81644 2.81462 4.90026 2.75632 5.00113C2.69786 5.10226 2.667 5.21698 2.66683 5.33379V10.6663C2.667 10.7831 2.69786 10.8979 2.75632 10.999C2.81462 11.0999 2.89833 11.1837 2.99911 11.2421C2.99946 11.2423 2.99981 11.2425 3.00016 11.2427L7.66683 13.9094C7.76818 13.9679 7.88314 13.9987 8.00016 13.9987C8.11719 13.9987 8.23215 13.9679 8.3335 13.9094L8.33607 13.9079L13.0002 11.2427C13.0005 11.2425 13.0009 11.2423 13.0012 11.2421C13.102 11.1837 13.1857 11.0998 13.244 10.999C13.3025 10.8978 13.3334 10.7829 13.3335 10.666V5.33407C13.3334 5.21716 13.3025 5.10234 13.244 5.00113C13.1857 4.90027 13.102 4.81645 13.0012 4.75802C13.0009 4.75781 13.0005 4.75761 13.0002 4.75741L8.33349 2.09075C8.23215 2.03223 8.11719 2.00142 8.00016 2.00142Z"
				fill="black"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M1.60289 4.30623C1.78725 3.98752 2.19507 3.87861 2.51378 4.06297L7.99996 7.23654L13.4861 4.06297C13.8049 3.87861 14.2127 3.98752 14.397 4.30623C14.5814 4.62494 14.4725 5.03275 14.1538 5.21712L8.33378 8.58378C8.12726 8.70324 7.87266 8.70324 7.66614 8.58378L1.84615 5.21712C1.52744 5.03275 1.41853 4.62494 1.60289 4.30623Z"
				fill="black"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M8.00016 7.33337C8.36835 7.33337 8.66683 7.63185 8.66683 8.00004V14.72C8.66683 15.0882 8.36835 15.3867 8.00016 15.3867C7.63197 15.3867 7.3335 15.0882 7.3335 14.72V8.00004C7.3335 7.63185 7.63197 7.33337 8.00016 7.33337Z"
				fill="black"
			/>
		</svg>
	);
};
