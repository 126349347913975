import { FiRightIcon } from '@/assets/icons';
import { Box, Text } from '@chakra-ui/react';
import { Link } from 'react-router-dom';

interface BlockHeaderProps {
	leftContent: React.ReactNode;
	rightContent?: React.ReactNode;
	to?: string;
}
export const BlockHeader: React.FC<BlockHeaderProps> = ({ leftContent, rightContent, to }) => {
	return (
		<Box display="flex" marginY={3} justifyContent="space-between" alignItems={'center'}>
			<Box fontWeight={500}>{leftContent}</Box>
			{rightContent && (
				<Link to={to} className="ms-2">
					<Box display="flex" alignItems="center">
						<Text as="span" fontSize="12px" fontWeight={'500'}>
							{rightContent}
						</Text>
						{to && (
							<Box as="span">
								<FiRightIcon />
							</Box>
						)}
					</Box>
				</Link>
			)}
		</Box>
	);
};
