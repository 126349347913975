import init, { genInstanceId } from '@telifi/dkls-wasm';
import axios from 'axios';

export function createKeygenSetupOpts(signingKey: Uint8Array, parties: Array<any>, threshold, ttl = 50) {
	const instance = genInstanceId();
	const result = {
		instance,
		signingKey: signingKey,
		threshold,
		ttl,
		parties,
	};
	return result;
}

export function createKeyMigrateSetupOpts(signingKey: Uint8Array, parties: Array<any>, threshold, ttl = 50) {
	const instance = genInstanceId();
	const result = {
		instance,
		signingKey: signingKey,
		threshold,
		ttl,
		parties,
	};
	return result;
}

export const askForPartyId = async (
	endpoint: string,
	keyshare_version: number,
	public_key: string,
	authFn?: () => Promise<string>,
): Promise<any> => {
	const headers = {
		'Content-Type': 'application/json',
	};

	if (authFn) {
		headers['Authorization'] = await authFn();
	}
	const resp = await axios.post(
		endpoint + '/v1/party-id',
		{
			keyshare_version,
			public_key,
		},
		{
			headers: headers,
		},
	);
	return resp.data;
};

export const askForEddsaPartyId = async (
	endpoint: string,
	keyshare_version: number,
	public_key: string,
	authFn?: () => Promise<string>,
): Promise<any> => {
	const headers = {
		'Content-Type': 'application/json',
	};

	if (authFn) {
		headers['Authorization'] = await authFn();
	}
	const resp = await axios.post(
		endpoint + '/v1/eddsa-party-id',
		{
			keyshare_version,
			public_key,
		},
		{
			headers: headers,
		},
	);
	return resp.data;
};

export const askForPartyKey = async (endpoint: string, authFn?: () => Promise<string>): Promise<any> => {
	const headers = {
		'Content-Type': 'application/json',
	};

	if (authFn) {
		headers['Authorization'] = await authFn();
	}

	const resp = await axios.post(
		endpoint + '/v1/party-keys',
		{},
		{
			headers: headers,
		},
	);
	return resp.data;
};

export const askForPartyEncKey = async (endpoint: string, authFn?: () => Promise<string>): Promise<any> => {
	const headers = {
		'Content-Type': 'application/json',
	};

	if (authFn) {
		headers['Authorization'] = await authFn();
	}

	const resp = await axios.post(
		endpoint + '/v1/party-enc-keys',
		{},
		{
			headers: headers,
		},
	);

	if (resp.status != 200) {
		throw new Error('status ' + resp.status);
	}

	return resp.data;
};

const start = async (
	endpoint: string,
	instance: Uint8Array,
	setup_vk: Uint8Array,
	party_vk?: Uint8Array,
	authFn?: () => Promise<string>,
): Promise<any> => {
	const headers = {
		'Content-Type': 'application/json',
	};

	if (authFn) {
		headers['Authorization'] = await authFn();
	}
	const resp = await axios.post(
		endpoint,
		{
			// instance: btoa(instance.reduce((s, b) => s + String.fromCharCode(b), ''))
			instance: instance.reduce((s, b) => s + b.toString(16).padStart(2, '0'), ''),
			setup_vk: setup_vk.reduce((s, b) => s + b.toString(16).padStart(2, '0'), ''),
			party_vk: party_vk.reduce((s, b) => s + b.toString(16).padStart(2, '0'), ''),
		},
		{
			headers: headers,
		},
	);
	return resp.data;
};

const _startDkm = async (
	endpoint: string,
	instance: Uint8Array,
	setup_vk: Uint8Array,
	party_vk: Uint8Array,
	address: Uint8Array,
	authFn?: () => Promise<string>,
): Promise<any> => {
	const headers = {
		'Content-Type': 'application/json',
	};

	if (authFn) {
		headers['Authorization'] = await authFn();
	}

	const resp = await axios.post(
		endpoint,
		{
			// instance: btoa(instance.reduce((s, b) => s + String.fromCharCode(b), ''))
			instance: instance.reduce((s, b) => s + b.toString(16).padStart(2, '0'), ''),
			setup_vk: setup_vk.reduce((s, b) => s + b.toString(16).padStart(2, '0'), ''),
			party_vk: party_vk.reduce((s, b) => s + b.toString(16).padStart(2, '0'), ''),
			address: address.reduce((s, b) => s + b.toString(16).padStart(2, '0'), ''),
		},
		{
			headers: headers,
		},
	);

	return resp.data;
};

const _startDkr = async (
	endpoint: string,
	instance: Uint8Array,
	setup_vk: Uint8Array,
	party_vk: Uint8Array,
	address: Uint8Array,
	lost_party_ids: Uint8Array,
	authFn?: () => Promise<string>,
): Promise<any> => {
	const headers = {
		'Content-Type': 'application/json',
	};

	if (authFn) {
		headers['Authorization'] = await authFn();
	}

	const resp = await axios.post(
		endpoint,
		{
			// instance: btoa(instance.reduce((s, b) => s + String.fromCharCode(b), ''))
			instance: instance.reduce((s, b) => s + b.toString(16).padStart(2, '0'), ''),
			setup_vk: setup_vk.reduce((s, b) => s + b.toString(16).padStart(2, '0'), ''),
			party_vk: party_vk.reduce((s, b) => s + b.toString(16).padStart(2, '0'), ''),
			address: address.reduce((s, b) => s + b.toString(16).padStart(2, '0'), ''),
			lost_party_ids: Array.from(lost_party_ids),
		},
		{
			headers: headers,
		},
	);
	return resp.data;
};

const _startEddsaDkr = async (
	endpoint: string,
	instance: Uint8Array,
	setup_vk: Uint8Array,
	party_vk: Uint8Array,
	party_enc_key: Uint8Array,
	address: Uint8Array,
	lost_party_ids: Uint8Array,
	authFn?: () => Promise<string>,
): Promise<any> => {
	const headers = {
		'Content-Type': 'application/json',
	};

	if (authFn) {
		headers['Authorization'] = await authFn();
	}
	const resp = await axios.post(
		endpoint,
		{
			// instance: btoa(instance.reduce((s, b) => s + String.fromCharCode(b), ''))
			instance: instance.reduce((s, b) => s + b.toString(16).padStart(2, '0'), ''),
			setup_vk: setup_vk.reduce((s, b) => s + b.toString(16).padStart(2, '0'), ''),
			party_vk: party_vk.reduce((s, b) => s + b.toString(16).padStart(2, '0'), ''),
			party_enc_key: party_enc_key.reduce((s, b) => s + b.toString(16).padStart(2, '0'), ''),
			address: address.reduce((s, b) => s + b.toString(16).padStart(2, '0'), ''),
			lost_party_ids: Array.from(lost_party_ids),
		},
		{
			headers: headers,
		},
	);
	return resp.data;
};

const _start_eddsa_dkg = async (
	endpoint: string,
	instance: Uint8Array,
	setup_vk: Uint8Array,
	party_vk: Uint8Array,
	party_enc_key: Uint8Array,
	authFn?: () => Promise<string>,
): Promise<any> => {
	const headers = {
		'Content-Type': 'application/json',
	};

	if (authFn) {
		headers['Authorization'] = await authFn();
	}

	const resp = await axios.post(
		endpoint,
		{
			instance: instance.reduce((s, b) => s + b.toString(16).padStart(2, '0'), ''),
			setup_vk: setup_vk.reduce((s, b) => s + b.toString(16).padStart(2, '0'), ''),
			party_vk: party_vk.reduce((s, b) => s + b.toString(16).padStart(2, '0'), ''),
			party_enc_key: party_enc_key.reduce((s, b) => s + b.toString(16).padStart(2, '0'), ''),
		},
		{
			headers: headers,
		},
	);
	return await resp.data;
};

export async function startDkg(
	endpoint: string,
	instance: Uint8Array,
	setup_vk: Uint8Array,
	party_vk: Uint8Array,
	authFn?: () => Promise<string>,
): Promise<any> {
	const resp = await start(endpoint + '/v1/keygen', instance, setup_vk, party_vk, authFn);

	return resp;
}

export async function startEddsaDkg(
	endpoint: string,
	instance: Uint8Array,
	setup_vk: Uint8Array,
	party_vk: Uint8Array,
	party_enc_key: Uint8Array,
	authFn?: () => Promise<string>,
): Promise<any> {
	const resp = await _start_eddsa_dkg(
		endpoint + '/v1/eddsa-keygen',
		instance,
		setup_vk,
		party_vk,
		party_enc_key,
		authFn,
	);

	return resp;
}

export async function startDkm(
	endpoint: string,
	instance: Uint8Array,
	setup_vk: Uint8Array,
	party_vk: Uint8Array,
	address: Uint8Array,
	authFn?: () => Promise<string>,
): Promise<any> {
	const resp = await _startDkm(endpoint + '/v1/key-migration', instance, setup_vk, party_vk, address, authFn);

	return resp;
}

export async function startDkr(
	endpoint: string,
	instance: Uint8Array,
	setup_vk: Uint8Array,
	party_vk: Uint8Array,
	address: Uint8Array,
	lost_party_ids: Uint8Array,
	authFn?: () => Promise<string>,
): Promise<any> {
	const resp = await _startDkr(
		endpoint + '/v1/key-refresh',
		instance,
		setup_vk,
		party_vk,
		address,
		lost_party_ids,
		authFn,
	);

	return resp;
}

export async function startEddsaDkr(
	endpoint: string,
	instance: Uint8Array,
	setup_vk: Uint8Array,
	party_vk: Uint8Array,
	party_enc_key: Uint8Array,
	address: Uint8Array,
	lost_party_ids: Uint8Array,
	authFn?: () => Promise<string>,
): Promise<any> {
	const resp = await _startEddsaDkr(
		endpoint + '/v1/eddsa-key-refresh',
		instance,
		setup_vk,
		party_vk,
		party_enc_key,
		address,
		lost_party_ids,
		authFn,
	);

	return resp;
}

export async function startDsg(
	endpoint: string,
	instance: Uint8Array,
	setup_vk: Uint8Array,
	party_vk?: Uint8Array,
	authFn?: () => Promise<string>,
): Promise<any> {
	const resp = await start(endpoint + '/v1/signgen', instance, setup_vk, party_vk, authFn);
	return resp;
}
export async function startDsgPublicSign(
	endpoint: string,
	instance: Uint8Array,
	setup_vk: Uint8Array,
	party_vk?: Uint8Array,
	authFn?: () => Promise<string>,
): Promise<any> {
	const resp = await start(endpoint + '/v1/signgen-custom', instance, setup_vk, party_vk, authFn);
	return resp;
}

export function createSignSetupOptsWebCloud(
	signingKey: Uint8Array,
	publicKey: Uint8Array,
	parties: Array<any>,
	message: Uint8Array,
	ttl = 60,
) {
	const instance = genInstanceId();
	const result = {
		instance,
		message,
		signingKey: signingKey,
		publicKey: publicKey,
		parties,
		ttl,
	};
	return result;
}

export async function startEddsaDsg(
	endpoint: string,
	instance: Uint8Array,
	setup_vk: Uint8Array,
	party_vk?: Uint8Array,
	authFn?: () => Promise<string>,
): Promise<any> {
	const resp = await start(endpoint + '/v1/eddsa-signgen', instance, setup_vk, party_vk, authFn);
	return resp;
}
