import React from 'react';

interface IProps extends React.SVGAttributes<HTMLOrSVGElement> {}

export const FiGasIcon: React.FC<IProps> = ({ width = 48, height = 48, ...rest }) => {
	return (
		<svg width="15" height="16" viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
			<path
				d="M13.4752 4.025L13.4835 4.01667L10.3835 0.916667L9.50016 1.8L11.2585 3.55833C10.4752 3.85833 9.91683 4.60833 9.91683 5.5C9.91683 5.77359 9.97072 6.0445 10.0754 6.29726C10.1801 6.55002 10.3336 6.77968 10.527 6.97314C10.9177 7.36384 11.4476 7.58333 12.0002 7.58333C12.3002 7.58333 12.5752 7.51667 12.8335 7.40833V13.4167C12.8335 13.875 12.4585 14.25 12.0002 14.25C11.5418 14.25 11.1668 13.875 11.1668 13.4167V9.66667C11.1668 8.75 10.4168 8 9.50016 8H8.66683V2.16667C8.66683 1.25 7.91683 0.5 7.00016 0.5H2.00016C1.0835 0.5 0.333496 1.25 0.333496 2.16667V15.5H8.66683V9.25H9.91683V13.4167C9.91683 13.9692 10.1363 14.4991 10.527 14.8898C10.9177 15.2805 11.4476 15.5 12.0002 15.5C12.5527 15.5 13.0826 15.2805 13.4733 14.8898C13.864 14.4991 14.0835 13.9692 14.0835 13.4167V5.5C14.0835 4.925 13.8502 4.4 13.4752 4.025ZM7.00016 6.33333H2.00016V2.16667H7.00016V6.33333ZM12.0002 6.33333C11.5418 6.33333 11.1668 5.95833 11.1668 5.5C11.1668 5.04167 11.5418 4.66667 12.0002 4.66667C12.4585 4.66667 12.8335 5.04167 12.8335 5.5C12.8335 5.95833 12.4585 6.33333 12.0002 6.33333Z"
				fill="#E32A2A"
			/>
		</svg>
	);
};
