import { Box, Center, Skeleton } from '@chakra-ui/react';
import { Outlet, useLocation } from 'react-router';
import { Global } from './Global';
import { UserAvatarOrHome } from './Header/UserAvatarOrHome';
import { GlobalSearch } from './Header/GlobalSearch';
import { QRScanner } from './Header/QRScanner';
import { WalletAddress } from './Header/WalletAddress';
import { Activity } from './Header/Activity';
import { NAVIGATE_PATHS } from '@/app-constants/router';
import { RequireWallet } from '@/app-components/common';
import { ReactNode } from 'react';

export const AppHeader = ({ nodes }: { nodes?: ReactNode }) => {
	return (
		<Center gap={1} px={4} pb={3} position="sticky" top={3} left={0} zIndex={10} backgroundColor="#ebeff6">
			<UserAvatarOrHome />
			<GlobalSearch />
			{nodes}
		</Center>
	);
};

export const AppLayout = () => {
	const { pathname } = useLocation();
	const isShow = [NAVIGATE_PATHS.Home, NAVIGATE_PATHS.Wallet.Wallet, NAVIGATE_PATHS.AccountSettings.Menu].includes(
		pathname,
	);

	const renderActionsHeader = () => {
		return (
			<>
				<RequireWallet
					loadingComponent={<Skeleton height="48px" width="48px" startColor="white" endColor="#EBEFF6" />}
				>
					<QRScanner />
				</RequireWallet>
				<RequireWallet
					loadingComponent={<Skeleton height="48px" width="48px" startColor="white" endColor="#EBEFF6" />}
				>
					<WalletAddress />
				</RequireWallet>
				<RequireWallet
					loadingComponent={<Skeleton height="48px" width="48px" startColor="white" endColor="#EBEFF6" />}
				>
					<Activity />
				</RequireWallet>
			</>
		);
	};

	return (
		<Box position="relative">
			{isShow && <AppHeader nodes={renderActionsHeader()} />}
			<Global />
			<Outlet />
		</Box>
	);
};
