import { QUERY_KEYS } from '@/app-constants';
import { ChainId, EVM_CHAINS } from '@/app-constants/chains';
import { TxStatus } from '@/app-cores/api/activities';
import { TokenInfo } from '@/app-cores/api/bff';
import { queryClient } from '@/app-cores/query-client';
import { isEvmChain, isSolanaChain } from '@/app-helpers/token';
import { ONE_MINUTE } from '@/app-hooks/api/portfolio/constant';
import { getLimitType } from '@/app-hooks/limit/helper';
import { JupiterLimitOrder } from '@/app-hooks/limit/jupiter';
import { KyberLimitOrder } from '@/app-hooks/limit/kyberswap';
import {
	ArgsCreateOrder,
	ArgsGetOrders,
	CancelParams,
	FormatOrder,
	LimitOrderStatus,
	LimitProvider,
	ListOrderResponse,
} from '@/app-hooks/limit/type';
import { tryParseAmount } from '@/app-hooks/swap/helper';
import { SwapProvider } from '@/app-hooks/swap/type';
import { useSubmitEVMTransaction } from '@/app-hooks/transactions';
import { useTransactionWatcherStore } from '@/app-store';
import { TransactionType } from '@/app-types';
import { useMutation, useQuery } from '@tanstack/react-query';

const mapInstance = {
	[LimitProvider.JUPITER]: JupiterLimitOrder,
	[LimitProvider.KYBER]: KyberLimitOrder,
};

type CreateParams = { callback: (status: TxStatus) => void } & ArgsCreateOrder;

export class LimitOrderService {
	static init() {
		JupiterLimitOrder.init();
	}
	static createOrder({ callback, ...data }: CreateParams) {
		const { amountIn, amountOut, tokenIn, tokenOut, ...rest } = data;
		const payload: ArgsCreateOrder = {
			amountIn: tryParseAmount(amountIn, tokenIn.decimals)?.toString(),
			amountOut: tryParseAmount(amountOut, tokenOut.decimals)?.toString(),
			tokenIn,
			tokenOut,
			...rest,
		};
		const provider = getLimitType(tokenIn, tokenOut);
		return mapInstance[provider]?.createOrder(payload, callback);
	}
}

export type LimitOrderConfig = {
	contractAddress: string;
	dappInfo: {
		logo: string;
		domain: string;
	};
};
export const useGetLimitOrderConfig = ({ tokenIn, tokenOut }: { tokenIn: TokenInfo; tokenOut: TokenInfo }) => {
	return useQuery({
		queryKey: ['get-lo-config', tokenIn?.chainId],
		queryFn: async (): Promise<LimitOrderConfig> => {
			const type = getLimitType(tokenIn, tokenOut);
			if (type === LimitProvider.KYBER) {
				const response = await KyberLimitOrder.getLimitOrderContract(tokenIn?.chainId);
				return {
					contractAddress: response?.latest,
					dappInfo: {
						logo: '/icons/brands/kyberswap.png',
						domain: SwapProvider.KYBER,
					},
				};
			}
			return null;
		},
	});
};

export const useGetActiveMakingAmount = ({ tokenIn, balanceIn }: { tokenIn: TokenInfo; balanceIn: bigint }) => {
	return useQuery({
		queryKey: [QUERY_KEYS.GET_LIMIT_ORDERS_ACTIVE_AMOUNT, tokenIn?.chainId, tokenIn?.address],
		queryFn: async () => {
			if (isEvmChain(tokenIn?.chainId)) {
				const activeAmount = await KyberLimitOrder.getActiveMakingAmount(tokenIn);
				return balanceIn - activeAmount;
			}
			return balanceIn;
		},
		enabled: !!tokenIn && !!balanceIn,
		gcTime: ONE_MINUTE * 10,
		staleTime: ONE_MINUTE * 10,
		refetchInterval: 60_000,
	});
};

const chains = EVM_CHAINS.map((e) => e.id).concat(ChainId.SOL);
export const useGetTotalLimitOrders = (enabled: boolean) => {
	return useQuery({
		queryKey: [QUERY_KEYS.GET_TOTAL_LIMIT_ORDER],
		queryFn: async () => {
			const data = { status: LimitOrderStatus.OPEN, pageSize: 20 };
			const response = await Promise.allSettled(
				chains.map((chain) =>
					isEvmChain(chain)
						? KyberLimitOrder.getOrders({ ...data, chainId: chain })
						: JupiterLimitOrder.getOrders({ ...data, chainId: chain }),
				),
			);
			const formatData: ListOrderResponse[] = response.map((e, i) =>
				e.status === 'fulfilled'
					? { ...e.value, chainId: chains[i] }
					: { total: 0, chainId: chains[i], orders: [] },
			);

			let total = 0;
			let chainHasOrder;
			const orderCountMap = {};
			let orders = [];
			formatData.forEach((item) => {
				total += item.total;
				orders = orders.concat(item.orders ?? []);
				if (item.total) {
					orderCountMap[item.chainId] = item.total;
					if (!chainHasOrder) {
						chainHasOrder = item.chainId;
					}
				}
			});
			return {
				total,
				orders,
				chainHasOrder,
				orderCountMap,
			};
		},
		enabled,
		gcTime: ONE_MINUTE * 60,
		staleTime: ONE_MINUTE * 60,
	});
};

export const useGetLimitOrders = (data: ArgsGetOrders) => {
	const { page, chainId, status, cursor } = data;
	return useQuery({
		queryKey: [QUERY_KEYS.GET_LIMIT_ORDERS, chainId, status, cursor, page],
		queryFn: async () => {
			if (isEvmChain(data.chainId)) {
				return KyberLimitOrder.getOrders(data);
			}
			if (isSolanaChain(data.chainId)) {
				return JupiterLimitOrder.getOrders(data);
			}
			return null;
		},
		gcTime: ONE_MINUTE * 10,
		staleTime: ONE_MINUTE * 10,
		refetchInterval: 20_000,
		enabled: !!chainId,
	});
};

export const invalidateLimitOrder = () => {
	[QUERY_KEYS.GET_LIMIT_ORDERS, QUERY_KEYS.GET_LIMIT_ORDERS_ACTIVE_AMOUNT, QUERY_KEYS.GET_TOTAL_LIMIT_ORDER].forEach(
		(key) => {
			queryClient.invalidateQueries({
				queryKey: [key],
			});
		},
	);
};

export const useMutationCreateOrder = () => {
	return useMutation({
		mutationKey: ['mutation-create-order'],
		mutationFn: (args: CreateParams) => LimitOrderService.createOrder(args),
		onSuccess: () => {
			invalidateLimitOrder();
		},
	});
};

export const useMutationCancelOrder = (chainId: ChainId) => {
	const { sentTransaction } = useSubmitEVMTransaction(chainId);
	const { addPendingEvmTransaction } = useTransactionWatcherStore();
	return useMutation({
		mutationKey: ['mutation-cancel-order'],
		mutationFn: async (args: CancelParams) => {
			const { data, callback } = args;
			const txsPayload = await mapInstance[data.provider]?.cancelOrder(args);
			if (txsPayload && data.provider === LimitProvider.KYBER) {
				const resp = await sentTransaction(txsPayload);
				addPendingEvmTransaction({
					transaction: resp,
					callback,
					metadata: { transactionType: TransactionType.ContractInteraction },
				});
			}
		},
		onSuccess: () => {
			invalidateLimitOrder();
		},
	});
};
