import { useEffect } from 'react';
import { useLocation } from 'react-router';
import { datadogRum } from '@/app-services/monitor/datadog';
import { NAVIGATE_PATHS } from '@/app-constants/router';

const MAPPING_VIEW_NAME = {
	[NAVIGATE_PATHS.Home]: 'Home',
	[NAVIGATE_PATHS.CreateWallet.Onboarding]: 'Onboarding',
	[NAVIGATE_PATHS.CreateWallet.StoreBackupKey]: 'StoreBackupKey',
	[NAVIGATE_PATHS.CreateWallet.BackupGdriveCallback]: 'BackupKeyOnGDrive',
	[NAVIGATE_PATHS.Recovery.SelectMethod]: 'Recovery',
	[NAVIGATE_PATHS.Recovery.InputSecretKey]: 'InputRecoveryPassword',
	[NAVIGATE_PATHS.Recovery.GDriveCallback]: 'RecoveryWithGDrive',
	[NAVIGATE_PATHS.Recovery.FileUploadCallBack]: 'RecoveryWithUploadFile',
	[NAVIGATE_PATHS.Recovery.SelectMethod]: 'Recovery',
	[NAVIGATE_PATHS.CommingSoon]: 'CommingSoon',
	[NAVIGATE_PATHS.Deposit.DepositMethod]: 'SelectTransakPaymentMethod',
	[NAVIGATE_PATHS.Deposit.Transak]: 'Transak',
	[NAVIGATE_PATHS.Deposit.DepositResult]: 'TransakDepositResult',
	[NAVIGATE_PATHS.AccountSettings.Menu]: 'AccountSettings',
	[NAVIGATE_PATHS.AccountSettings.ContactSupport]: 'ContactSupport',
	[NAVIGATE_PATHS.AccountSettings.InvitationCode]: 'InvitationCode',
	[NAVIGATE_PATHS.AccountSettings.TermOfService]: 'TermOfService',
	[NAVIGATE_PATHS.AccountSettings.PrivacyPolicy]: 'PrivacyPolicy',
	[NAVIGATE_PATHS.Portfolio.Main]: 'Portfolio',
	'/portfolio/assets': 'Portfolio',
	[NAVIGATE_PATHS.Swap.Main]: 'Swap',
	[NAVIGATE_PATHS.Swap.ConfirmTransaction]: 'SwapConfirmTransaction',
	[NAVIGATE_PATHS.Wallet.ScanQRCode]: 'ScanQRCode',
	[NAVIGATE_PATHS.Wallet.Sent.Sent]: 'Sent',
	[NAVIGATE_PATHS.Wallet.Sent.ConfirmTransaction]: 'SentConfirmTransaction',
	[NAVIGATE_PATHS.Wallet.WCconnection]: 'WalletConnect',
	['/transaction-result']: 'TransactionResult',
	[NAVIGATE_PATHS.Dapp.DappBrowser]: 'DappBrowser',
	[`${NAVIGATE_PATHS.Dapp.TobiDapp}/`]: 'Dapp',
	[NAVIGATE_PATHS.Dapp.GoogleSearchControl]: 'GoogleSearchControl',
};
export const useMonitorView = () => {
	const { pathname } = useLocation();
	useEffect(() => {
		let view = MAPPING_VIEW_NAME[pathname];
		if (pathname.startsWith('/portfolio')) {
			view = 'Portfolio';
		} else if (pathname.startsWith('/transaction-result/swap')) {
			view = 'SwapTransactionResult';
		} else if (pathname.startsWith('/transaction-result/send')) {
			view = 'SendTransactionResult';
		}
		if (view) {
			datadogRum.startView(view);
		}
	}, [pathname]);
};
