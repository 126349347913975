import {
	navigateToTokenDetail,
	useFetchRouteAndRedirectToSwapConfirm,
	useNavigateToSentPage,
	useNavigateToSwapPage,
} from '@/app-helpers/navigate';
import { MainRouter } from '@/app-router';
import { NAVIGATE_PATHS } from '../../../app-constants/router';
import { useParseBotStartRedirectParams, useParseSwapStartParams } from '../../../app-cores/telegram/useStartAppParam';
import { DATADOG_ACTIONS, DATADOG_ERROR_TAGS, dataDogAddAction, dataDogAddError } from '@/app-services/monitor/datadog';
import { ChainId, TON_TCAT_TOBI_ID } from '@/app-constants/chains';
import { getNativeTobiId } from '@/app-helpers/token';

export enum Actions {
	SWAP = 'botSw',
	SEND = 'send',
	SWAP_CONFIRM = 'swapConfirm',
	TOKEN_DETAIL = 'tokenDetail',
	REFERRAL = 'referral',
	REFERRAL_INVITE = 'referral_invite',
	REFERRAL_DETAIL = 'referral_detail',
	REQUEST_FUND = 'request_fund',
	SOL_TCAT = 'sol_tcat',
	ACTIVITIES = 'activity',
}

export function useBotRedirect() {
	// common params
	const { action, data, requestId, tobiId } = useParseBotStartRedirectParams();
	const swapPrams = useParseSwapStartParams();

	const navigateToSend = useNavigateToSentPage();
	const navigateToSwap = useNavigateToSwapPage();

	const navigateToConfirm = useFetchRouteAndRedirectToSwapConfirm();

	// todo chop chop HOME
	return async (replace: boolean) => {
		try {
			switch (action) {
				case Actions.SWAP: {
					const { toChain, fromChain, toToken, fromToken, amount, amountUsd, tobiId } = swapPrams;
					const payload = {
						amount,
						amountUsd,
					};
					// only 2 cases for now: only token out, both tokenIn and tokenOut
					if (fromToken && toToken) {
						dataDogAddAction(DATADOG_ACTIONS.BOT_TRADE);
						navigateToSwap(
							{
								...payload,
								tokenIn: fromToken,
								tokenOut: toToken,
								chainIn: fromChain,
								chainOut: toChain,
							},
							{ replace },
						);
					} else if (tobiId) {
						dataDogAddAction(DATADOG_ACTIONS.BOT_TRADE);
						navigateToSwap({ ...payload, chainOut: toChain, tokenOut: tobiId }, { replace });
					}
					break;
				}
				case Actions.SWAP_CONFIRM: {
					const { toChain, fromChain, toToken, fromToken, amount } = swapPrams;
					if ([toChain, fromChain, toToken, fromToken].some((e) => !e)) return;
					navigateToConfirm({ toChain, fromChain, toTobiId: toToken, fromTobiId: fromToken, amount });
					break;
				}
				case Actions.TOKEN_DETAIL: {
					if (!tobiId) return;
					navigateToTokenDetail({ tobiId }, { replace });
					break;
				}
				case Actions.REFERRAL: {
					MainRouter.navigate(NAVIGATE_PATHS.AccountSettings.InvitationCode);
					break;
				}
				case Actions.REFERRAL_DETAIL: {
					MainRouter.navigate(NAVIGATE_PATHS.AccountSettings.InvitationCodeDetail);
					break;
				}
				case Actions.REFERRAL_INVITE: {
					MainRouter.navigate(`${NAVIGATE_PATHS.AccountSettings.InvitationCode}?autoInvite=1`);
					break;
				}
				case Actions.REQUEST_FUND: {
					MainRouter.navigate(
						requestId
							? NAVIGATE_PATHS.RequestFund.requestDetail(requestId)
							: NAVIGATE_PATHS.RequestFund.Main,
					);
					break;
				}
				case Actions.SEND: {
					navigateToSend(data);
					break;
				}
				case Actions.SOL_TCAT: {
					navigateToSwap({
						tokenIn: getNativeTobiId(ChainId.SOL),
						tokenOut: TON_TCAT_TOBI_ID,
						chainIn: ChainId.SOL,
						chainOut: ChainId.TON,
					});
					break;
				}
				default:
					break;
			}
		} catch (error) {
			dataDogAddError(error, {
				tags: {
					name: DATADOG_ERROR_TAGS.BOT_REDIRECT,
					function: 'botRedirect',
				},
			});
		}
	};
}
