import { Web3Wallet, IWeb3Wallet } from '@walletconnect/web3wallet';
import { Core } from '@walletconnect/core';
import { WalletConnectServiceAPI } from '@/app-cores/api/walletconnect';
import { STORAGE_KEYS } from '@/app-constants/storage';

let web3wallet: IWeb3Wallet;

export async function createWeb3Wallet(relayerRegionURL: string) {
	const projectId = import.meta.env.VITE_WALLET_CONNECT_PROJECT_ID;
	const relayUrl = relayerRegionURL ?? import.meta.env.VITE_RELAY_URL;

	const core = new Core({
		projectId,
		relayUrl,
		customStoragePrefix: 'tobi_wc',
	});

	web3wallet = await Web3Wallet.init({
		core: core as any,
		metadata: {
			name: 'Tobi wallet',
			description: 'Wallet for WalletConnect',
			url: 'https://walletconnect.com/',
			icons: ['https://avatars.githubusercontent.com/u/37784886'],
		},
	});

	try {
		const clientId = await web3wallet.engine.signClient.core.crypto.getClientId();
		const activeSession = Object.values(web3wallet.getActiveSessions());
		console.log('web3wallet.getActiveSessions()', activeSession);
		localStorage.setItem(STORAGE_KEYS.TOBI_WALLET_CONNECT_CLIENT_ID, clientId);

		if (activeSession.length) {
			WalletConnectServiceAPI.subscribe(
				activeSession.map((item) => ({
					topic: item.topic,
					key: web3wallet.core.crypto.keychain.get(item.topic),
					dapp: item.peer.metadata,
				})),
			);
		}
	} catch (error) {
		console.error('Failed to set WalletConnect clientId in localStorage: ', error);
	}
}

export async function updateSignClientChainId(chainId: string, address: string) {
	console.log('chainId', chainId, address);
	const sessions = web3wallet.getActiveSessions();
	if (!sessions) return;
	const namespace = chainId.split(':')[0];

	Object.values(sessions).forEach(async (session) => {
		await web3wallet.updateSession({
			topic: session.topic,
			namespaces: {
				...session.namespaces,
				[namespace]: {
					...session.namespaces[namespace],
					chains: [...new Set([chainId].concat(Array.from(session?.namespaces?.[namespace]?.chains || [])))],
					accounts: [
						...new Set(
							[`${chainId}:${address}`].concat(
								Array.from(session?.namespaces?.[namespace]?.accounts || []),
							),
						),
					],
				},
			},
		});

		await new Promise((resolve) => setTimeout(resolve, 1000));

		const chainChanged = {
			topic: session.topic,
			event: {
				name: 'chainChanged',
				data: parseInt(chainId.split(':')[1]),
			},
			chainId: chainId,
		};

		const accountsChanged = {
			topic: session.topic,
			event: {
				name: 'accountsChanged',
				data: [`${chainId}:${address}`],
			},
			chainId,
		};

		await web3wallet.emitSessionEvent(chainChanged);
		await web3wallet.emitSessionEvent(accountsChanged);
	});
}

export { web3wallet };
