import { create } from 'zustand';

type TToastItem = {
	type: 'success' | 'warning' | 'error';
	message: string;
	title?: string;
};
export interface IToast {
	toast?: TToastItem;
	addToast: (toast: TToastItem) => void;
	removeToast: () => void;
}

const initializeData = {
	toast: null,
};
export const useGlobalToast = create<IToast>((set) => ({
	...initializeData,
	addToast: (toast) => set({ toast: toast }),
	removeToast: () => set({ toast: null }),
}));
