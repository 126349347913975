import { Box, BoxProps } from '@chakra-ui/react';
import { PropsWithChildren } from 'react';

interface BlockProps extends BoxProps {}
export const Block: React.FC<PropsWithChildren<BlockProps>> = ({ children, ...rest }) => {
	return (
		<Box backgroundColor="gray.100" py={4} px={5} borderRadius={16} {...rest}>
			{children}
		</Box>
	);
};
