import { ServiceUser, UserInviteCode } from '@/app-cores/api';
import { TelegramCore } from '@/app-cores/telegram';
import { keepPreviousData, useQuery } from '@tanstack/react-query';
import { ONE_MINUTE, TEN_SECONDS } from '../portfolio/constant';
import { sample } from 'lodash-es';
import { useMemo } from 'react';

export const useQueryUserInviteCodes = () => {
	const response = useQuery({
		queryKey: ['query-user-invite-codes', TelegramCore.getUserId()],
		queryFn: getInviteCodes,
		placeholderData: keepPreviousData,
		staleTime: 0,
		refetchInterval: TEN_SECONDS,
	});

	return response;
};

export const useQueryInviteCodesHistory = () => {
	const response = useQuery({
		queryKey: ['query-user-invite-history', TelegramCore.getUserId()],
		queryFn: ServiceUser.getInviteHistory,
		placeholderData: keepPreviousData,
		staleTime: 0,
		refetchInterval: TEN_SECONDS,
	});

	const data = useMemo(() => {
		const list = (response.data || []).sort(
			(a, b) =>
				new Date(b?.invitee?.defaultWallet?.createdAt || 0).getTime() -
				new Date(a?.invitee?.defaultWallet?.createdAt || 0).getTime(),
		);

		const walletUsers = list?.filter((e) => e.isWalletCreated) || [];
		const nonWalletUsers = list?.filter((e) => !e.isWalletCreated) || [];
		return {
			walletUsers,
			nonWalletUsers,
			totalUser: list?.length,
			totalWalletUser: walletUsers?.length,
		};
	}, [response.data]);

	return { ...response, data };
};

const getInviteCodes = async (): Promise<{ code: UserInviteCode }> => {
	const codes = await ServiceUser.getInviteCodes();
	return { code: sample(codes || []) };
};
