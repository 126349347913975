export const BACKUP_FOLDER_NAME = 'DO NOT MOVE OR DELETE Tobi backup';

export const BACK_WARNING_FUND_THRESHOLD_KEY = 'backup_warning_fund_threshold';

export enum RECOVERY_TYPE {
	DRIVE = 'drive',
	FILE = 'file',
	EMAIL = 'email',
	TELEGRAM = 'telegram',
}

export enum RECOVERY_ACTION {
	RECOVERY = 'recovery',
	MIGRATE_KEYSHARE = 'migrate_keyshare',
}

export const IS_UPGRADED_EDDSA = 'is_ugraded_eddsa';
