import { Addresses } from '@/app-components/common/AddressByChain/Addresses';
import {
	Center,
	Drawer,
	DrawerBody,
	DrawerCloseButton,
	DrawerContent,
	DrawerHeader,
	DrawerOverlay,
	Text,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { isDesktop } from 'react-device-detect';
import { useDepositStore } from './store';
import { Or } from '@/app-components/common';
import { Fiat } from './fiat';
import { ConnectWallet } from './connect-wallet';
import { getTokenInfo, isSolanaChain, isTronChain } from '@/app-helpers/token';
import { ChainId } from '@/app-constants/chains';

export const Deposit = () => {
	const { t } = useTranslation();
	const { isOpen, onClose, reset, token, initToken, tokenInfo } = useDepositStore();
	return (
		<Drawer
			isOpen={isOpen}
			placement="bottom"
			onClose={() => {
				onClose();
				reset();
			}}
			trapFocus={false}
		>
			<DrawerOverlay />
			<DrawerContent>
				<DrawerCloseButton top="0.75rem" />
				<DrawerHeader borderBottomWidth="1px" borderBottomColor="gray.100">
					{t('deposit.deposit')}
				</DrawerHeader>

				<DrawerBody mt={3}>
					<Addresses visibleChain={getTokenInfo(initToken)?.chainId as ChainId} />
					<Or my={3}></Or>
					<Fiat />
					{isDesktop && tokenInfo && isSolanaChain(tokenInfo?.chainId) ? null : isTronChain(
							tokenInfo?.chainId,
					  ) ? null : (
						<ConnectWallet />
					)}
				</DrawerBody>
			</DrawerContent>
		</Drawer>
	);
};
