class LocalStorageService {
	public setNumber(key: string, value: number) {
		return localStorage.setItem(key, value.toString());
	}

	public getNumber(key: string) {
		const res = localStorage.getItem(key);

		if (res) {
			return Number(res);
		} else {
			return 0;
		}
	}

	public getString(key: string) {
		return localStorage.getItem(key);
	}

	public popString(key: string) {
		const item = localStorage.getItem(key);
		localStorage.removeItem(key);
		return item;
	}

	public setString(key: string, value: string) {
		return localStorage.setItem(key, value);
	}

	public getObject(key: string) {
		const res = localStorage.getItem(key);

		try {
			if (!res) {
				return {};
			}

			return JSON.parse(res as string);
		} catch (e) {
			return {};
		}
	}

	public setObject(key: string, value: any) {
		return localStorage.setItem(key, JSON.stringify(value));
	}

	public getArray(key: string) {
		const res = localStorage.getItem(key);

		try {
			return JSON.parse(res as string);
		} catch (e) {
			return [];
		}
	}

	public setArray(key: string, value: any[]) {
		return localStorage.setItem(key, JSON.stringify(value));
	}

	public remove(key: string) {
		return localStorage.removeItem(key);
	}

	public clear() {
		return localStorage.clear();
	}
}

export type ILocalStorageService = typeof LocalStorageService;

export default new LocalStorageService();
