import { WalletActivity } from '@/app-cores/api/activities';
import { UserProfile } from './types';
import { axiosBotAPI } from '@/app-cores/api/axios';

interface Metadata {
	activity: WalletActivity;
}

interface Notification {
	id: string;
	idTobi: string;
	userId: string;
	userTelegramId: string;
	userRead: boolean;
	metadata: Metadata;
	createdAt: string;
	updatedAt: string;
}

export const ServiceNotification = {
	getNotifications: async (): Promise<Notification[]> => {
		const response = await axiosBotAPI.post('/v2/notification/all');
		return response.data?.data ?? [];
	},
	readNotification: async (payload: { ids: string[] }): Promise<UserProfile> => {
		const response = await axiosBotAPI.post<UserProfile>('v2/notification/read', payload);
		return response.data;
	},
};
