import { Box, Flex, Text } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

interface RecoveryTypeProps {
	img: React.ReactNode;
	title: string;
	description: string;
}

export const RecoveryType: React.FunctionComponent<{
	recoveryType: RecoveryTypeProps;
	onClick?: () => void;
	email?: string;
}> = ({ recoveryType, onClick, email }) => {
	const { t } = useTranslation();
	return (
		<Box
			mt={4}
			borderRadius={16}
			backgroundColor="white"
			p={6}
			border="1px solid rgba(0, 0, 0, 0.08)"
			cursor="pointer"
		>
			<Flex onClick={onClick} gap={3}>
				<Box>{recoveryType.img}</Box>
				<Box>
					<Text mb={2} fontWeight={500}>
						{t(recoveryType.title)}
					</Text>
					<Text fontSize="xs" color="gray.400" lineHeight={4}>
						{t(recoveryType.description)}
					</Text>
					{email && (
						<Text fontSize="xs" color="gray.400">
							(Email: {email})
						</Text>
					)}
				</Box>
			</Flex>
		</Box>
	);
};
