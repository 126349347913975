import { QueryClientProvider } from '@tanstack/react-query';
import { THEME, TonConnectUIProvider } from '@tonconnect/ui-react';
import { WebAppProvider } from '@vkruglikov/react-telegram-web-app';
import { useLayoutEffect } from 'react';
import { isAndroid, isIOS } from 'react-device-detect';
import { ToastContainer } from 'react-toastify';
import { WalletProvider } from './app-contexts/wallet-provider';
import { queryClient } from './app-cores/query-client';
import { InitializeApp } from './app-views/apps/InitializeApp';

console.log('App version: ', import.meta.env.VITE_VERSION);

const App = () => {
	useLayoutEffect(() => {
		isAndroid ? document.body.classList.add('tobi_android') : (document.body.style.overflowY = 'hidden');
	}, []);
	return (
		<WebAppProvider>
			<TonConnectUIProvider
				manifestUrl={`${import.meta.env.VITE_APP_URL}/assets/miniapp/manifest.json`}
				uiPreferences={{ theme: THEME.LIGHT }}
				actionsConfiguration={{
					twaReturnUrl: `https://t.me/${import.meta.env.VITE_BOT_USERNAME}/wallet`,
				}}
			>
				<QueryClientProvider client={queryClient}>
					<WalletProvider>
						<InitializeApp />
						<ToastContainer
							position="top-center"
							hideProgressBar
							bodyClassName="small"
							closeButton={false}
							newestOnTop
							draggableDirection="y"
							draggablePercent={50}
							icon={false}
							closeOnClick
							autoClose={3000}
						/>
					</WalletProvider>
				</QueryClientProvider>
			</TonConnectUIProvider>
		</WebAppProvider>
	);
};

export default App;
