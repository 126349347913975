import { STORAGE_KEYS } from '@/app-constants/storage';
import { TelegramCore } from '@/app-cores/telegram';
import { useGlobalToast } from '@/app-store';
import { Address } from '@ton/core';
import { ReturnStrategy } from '@tonconnect/ui-react';

export const seeIfValidTonAddress = (value: string): boolean => {
	try {
		if (value.includes('://')) {
			return false; // ignore links
		}
		Address.parse(value);
		return true;
	} catch (e) {
		return false;
	}
};

export function concatUint8Arrays(buffer1: Uint8Array, buffer2: Uint8Array): Uint8Array {
	const mergedArray = new Uint8Array(buffer1.length + buffer2.length);
	mergedArray.set(buffer1);
	mergedArray.set(buffer2, buffer1.length);
	return mergedArray;
}

export const decodeHex = (s: string): Uint8Array => {
	const bytes = s.match(/[0-9A-Fa-f]{2}/g);
	if (!bytes) {
		throw new Error('bad hex string');
	}
	return Uint8Array.from(bytes.map((byte) => parseInt(byte, 16)));
};

export const encodeHex = (a: Uint8Array): string => a.reduce((s, b) => s + b.toString(16).padStart(2, '0'), '');

function isTelegramLink(url) {
	const telegramPatterns = [/^https?:\/\/(t\.me|telegram\.me)\/.+$/, /^tg:\/\/resolve\?domain=.+$/];

	return telegramPatterns.some((pattern) => pattern.test(url));
}

export function handleReturnStrategy(returnStrategy: ReturnStrategy) {
	console.log('returnStrategy:', returnStrategy);
	if (returnStrategy === 'back') return TelegramCore.WebApp.close();
	if (!returnStrategy || returnStrategy === 'none') return;
	try {
		const url = new URL(decodeURIComponent(returnStrategy));
		return isTelegramLink(url)
			? TelegramCore.WebApp.openTelegramLink(url.toString())
			: TelegramCore.WebApp.openLink(url.toString());
	} catch {}
}

export function getValidReturnStrategy(returnStrategy: ReturnStrategy) {
	try {
		if (returnStrategy !== 'none' && returnStrategy !== 'back' && !new URL(decodeURIComponent(returnStrategy))) {
			return 'none';
		}
		return returnStrategy;
	} catch (error) {
		return 'none';
	}
}

export const tonConnectEventId = {
	getEventId: () => {
		return localStorage.getItem(STORAGE_KEYS.TOBI_TON_CONNECT_EVENT_ID) || 1;
	},
	storeEventId: (eventId) => {
		localStorage.setItem(STORAGE_KEYS.TOBI_TON_CONNECT_EVENT_ID, eventId);
	},
};
