import { create } from 'zustand';
import { ITokenSearch, TokenInfo } from '@/app-cores/api/bff';
import { DATADOG_ACTIONS, dataDogAddAction } from '@/app-services/monitor/datadog';
import { InputMode } from '@/app-store/swap';
import { getTokenInfo } from '@/app-helpers/token';

type DepositAssetsType = 'Ton' | 'Sol' | 'Evm';

const initializeData = {
	isOpen: false,
	isOpenNetworkSelection: false,
	isOpenWalletProvider: false,
	state: {
		usdValue: 0,
		tokenValue: 0,
		rawValue: '0',
	},
	inputMode: InputMode.USD,
	isOpenDepositResult: false,
	amountDeposited: '',
	initToken: null,
	tokenInfo: null,
	token: null,
};

export interface IDepositStore {
	isOpen: boolean;
	onOpen: () => void;
	onClose: (keepPreData?: boolean) => void;
	isOpenNetworkSelection: boolean;
	onToggleNetworkSelection: (opened: boolean) => void;
	isOpenWalletProvider: boolean;
	onToggleWalletProvider: (opened: boolean) => void;
	depositAssetsType?: DepositAssetsType;
	setDepositAssetType: (type: DepositAssetsType) => void;
	disconnectingType?: DepositAssetsType;
	setDisconnectingType: (type: DepositAssetsType) => void;
	inputMode: InputMode;
	token?: ITokenSearch;
	tokenInfo?: TokenInfo;
	initToken?: ITokenSearch;
	setToken: (token: ITokenSearch) => void;
	setInitToken: (token: ITokenSearch) => void;
	state: {
		usdValue: number;
		tokenValue: number;
		rawValue: string;
	};
	setValue: (rawValue: string, price: number, availableAmount: number) => void;
	toggleInputMode: () => void;
	errorMessage?: string;
	setErrorMessage: (msg: string) => void;
	isOpenDepositResult?: boolean;
	setShowDepositResult: (isOpen?: boolean) => void;
	amountDeposited: string | number;
	setAmountDeposited: (amount: string | number) => void;
	reset: () => void;
}

export const useDepositStore = create<IDepositStore>((set, get) => ({
	...initializeData,
	onOpen: () => {
		set({ isOpen: true });
		dataDogAddAction(DATADOG_ACTIONS.DEPOSIT);
	},
	onClose: () => {
		const store = get();
		set({ ...initializeData, initToken: store.initToken });
	},
	onToggleNetworkSelection: (opened: boolean) => set({ isOpenNetworkSelection: opened }),
	onToggleWalletProvider: (opened: boolean) => set({ isOpenWalletProvider: opened }),
	setDepositAssetType: (type?: DepositAssetsType) => {
		set({ depositAssetsType: type });
	},
	setDisconnectingType: (type?: DepositAssetsType) => set({ disconnectingType: type }),
	setToken: (token: ITokenSearch) => {
		set({ token: token, state: initializeData.state, tokenInfo: getTokenInfo(token) });
	},
	setInitToken: (token: ITokenSearch) => {
		set({ initToken: token, token: token, tokenInfo: getTokenInfo(token) });
	},
	setValue: (val: string, price: number, availableAmount: number) => {
		const store = get();
		if (store.inputMode === InputMode.AMOUNT) {
			let errorMessage = '';
			if (+val > availableAmount) {
				errorMessage = 'Insufficient fund.';
			}
			set({
				state: {
					usdValue: +val * price,
					tokenValue: +val,
					rawValue: val,
				},
				errorMessage: errorMessage,
			});
		} else {
			const tokenValue = price ? +val / price : 0;
			let errorMessage = '';
			if (tokenValue > availableAmount) {
				errorMessage = 'Insufficient fund.';
			}
			set({
				state: {
					usdValue: +val,
					tokenValue: tokenValue,
					rawValue: val,
				},
				errorMessage: errorMessage,
			});
		}
	},
	toggleInputMode: () => {
		const store = get();
		set({
			state: {
				...store.state,
				rawValue:
					store.inputMode === InputMode.AMOUNT
						? `${+store.state.usdValue.toFixed(4)}`
						: `${+store.state.tokenValue.toFixed(4)}`,
			},
			inputMode: store.inputMode === InputMode.AMOUNT ? InputMode.USD : InputMode.AMOUNT,
		});
	},
	setErrorMessage: (msg) => set({ errorMessage: msg }),
	setShowDepositResult: (isOpen: boolean) => {
		if (isOpen) {
			set({ isOpenDepositResult: isOpen, isOpenWalletProvider: false });
		} else {
			set({ isOpenDepositResult: isOpen });
		}
	},
	setAmountDeposited: (amount: string) => set({ amountDeposited: amount }),

	reset: () => set({ ...initializeData }),
}));
