import { Connection, PublicKey, SystemProgram, Transaction } from '@solana/web3.js';
import nacl from 'tweetnacl';
import { useEffect, useRef, useState } from 'react';
import bs58 from 'bs58';
import { encodeBase64 } from 'tweetnacl-util';
import { v4 as uuidv4 } from 'uuid';
import {
	createTransferInstruction,
	TOKEN_PROGRAM_ID,
	getAssociatedTokenAddress,
	createAssociatedTokenAccountInstruction,
	ASSOCIATED_TOKEN_PROGRAM_ID,
} from '@solana/spl-token';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { formatUnits } from 'ethers';
import { FiPhantomWallet } from '@/assets/icons';
import { getShortAddress, isNativeToken } from '@/app-helpers/address';
import { solana } from '@/app-constants/chains';
import {
	buildPhantomDeepLink,
	buildRedirectLink,
	buildState,
	decryptPayload,
	encryptPayload,
	initKeyPairs,
	initSharedSecret,
	pollingToGetDataFromPhantomWallet,
} from './helper';
import { LOCAL_STORE_KEYS, METHODS, phantomConnectStorages } from './storage';
import { Toast } from '@/app-components/common/Toast';
import { TelegramCore } from '@/app-cores/telegram';
import { MpcWallet } from '@/app-cores/mpc-wallet/wallet';
import { checkIfTokenAccountExists, getSolCoinTransferBalance } from '@/app-helpers/solana';
import { Loading } from '@/app-components/common/Loading';
import { DATADOG_ACTIONS, DATADOG_ERROR_TAGS, dataDogAddAction, dataDogAddError } from '@/app-services/monitor/datadog';
import { ProviderSelection } from '@/app-features/deposit/components/ProviderSelection';
import { DepositForm } from '@/app-features/deposit/components/DepositForm';
import { ITokenSearch } from '@/app-cores/api/bff';
import { useDepositStore } from '@/app-features/deposit/store';

const RPC = solana.rpcUrls.default.http;
const connection = new Connection(RPC);

const DEFAULT_TOKEN = {
	'tokenImmutableDataDto': {
		'idTobi': 'cgk-solana',
		'symbol': 'sol',
		'address': null,
		'chainId': null,
		'isNative': true,
		'maxSupply': null,
		'createdAt': '2024-08-15T08:09:38.726Z',
		'updatedAt': '2024-09-06T14:34:20.854Z',
	},
	'tokenMutableDataDto': {
		'imageUrl': 'https://coin-images.coingecko.com/coins/images/4128/large/solana.png?1718769756',
		'platforms': {
			'': '',
		},
		'name': 'Solana',
	},
	'tokenMarketDataDto': {
		'idTobi': 'cgk-solana',
		'priceUsd': 131.93,
		'marketCapUsd': 61741926898,
		'holders': null,
		'fullyDilutedValueUsd': 77095436053,
		'flatChange1h': null,
		'flatChange4h': null,
		'flatChange12h': null,
		'flatChange24h': null,
		'flatChange7d': null,
		'flatChange30d': null,
		'percentChange1h': null,
		'percentChange4h': null,
		'percentChange12h': null,
		'percentChange24h': -2.36583,
		'percentChange7d': null,
		'percentChange30d': null,
		'volume1hUsd': null,
		'volume4hUsd': null,
		'volume12hUsd': null,
		'volume24hUsd': 2270713543,
		'high1hUsd': null,
		'high4hUsd': null,
		'high12hUsd': null,
		'high24hUsd': 137.81,
		'low1hUsd': null,
		'low4hUsd': null,
		'low12hUsd': null,
		'low24hUsd': 131.32,
	},
} as any as ITokenSearch;

export const PhantomConnect = () => {
	const {
		token,
		initToken,
		tokenInfo,
		setDepositAssetType,
		depositAssetsType,
		state,
		setValue,
		setErrorMessage,
		setDisconnectingType,
		disconnectingType,
		setAmountDeposited,
		setShowDepositResult,
		setToken,
	} = useDepositStore();
	const [dappKeyPair, setDappKeyPair] = useState(() => initKeyPairs());
	const [session, setSession] = useState(() => phantomConnectStorages.getSession());
	const [phantomPublicKey, setPhantomPublicKey] = useState(() => phantomConnectStorages.getPhanTomWalletPublicKey());
	const [poolingId, setPoolingId] = useState<string>('');
	const [sharedSecret, setSharedSecret] = useState<Uint8Array | null>(() => initSharedSecret());
	const [isDepositLoading, setIsDepositLoading] = useState(false);
	const [isConnecting, setIsConnecting] = useState(false);
	const { t } = useTranslation();
	const pollingState = useRef(buildState(METHODS.onConnect));
	const solWallet = MpcWallet.getSolanaWalletAddress();
	const isOpen = depositAssetsType === 'Sol';
	useEffect(() => {
		if (!poolingId) return;
		const abortController = new AbortController();
		async function getData() {
			const res = await pollingToGetDataFromPhantomWallet(pollingState.current, abortController);
			console.log('pooling data form phantom wallet', res);
			const params = new URLSearchParams(res);
			const phantom_encryption_public_key = params.get('phantom_encryption_public_key');
			const nonce = params.get('nonce');
			const data = params.get('data');
			const errorMessage = params.get('errorMessage');
			console.log('phantom_encryption_public_key:', phantom_encryption_public_key, nonce, data);
			if (errorMessage) {
				setErrorMessage(errorMessage);
				toast(<Toast title="Error" type="error" message={errorMessage} />);
			}
			if (/onConnect/.test(pollingState.current)) {
				try {
					setIsConnecting(false);
					const sharedSecretDapp = nacl.box.before(
						bs58.decode(phantom_encryption_public_key!),
						dappKeyPair.secretKey,
					);
					const connectData = decryptPayload(data!, nonce!, sharedSecretDapp);
					setSharedSecret(sharedSecretDapp);
					setSession(connectData.session);
					const PhantomPublicKey = new PublicKey(connectData.public_key);
					setPhantomPublicKey(PhantomPublicKey);
					console.log('connectData:', connectData);
					phantomConnectStorages.setSession(connectData.session);
					phantomConnectStorages.setPhanTomWalletPublicKey(connectData.public_key);
					phantomConnectStorages.setSharedSecret(encodeBase64(sharedSecretDapp));
					if (!errorMessage) {
						setDepositAssetType('Sol');
						dataDogAddAction(DATADOG_ACTIONS.DEPOSIT_PHANTOM_CONNECT_SUCCESS);
						toast(<Toast title="Success" type="success" message="Connect with Phantom wallet success" />);
					}
				} catch (error) {
					dataDogAddAction(DATADOG_ACTIONS.DEPOSIT_PHANTOM_CONNECT_FAIL);
					dataDogAddError(error, {
						tags: {
							name: DATADOG_ERROR_TAGS.DEPOSIT,
							function: 'connectWithPhantom',
						},
					});
					console.error('connect to Phantom error', error);
				}
			} else if (/onSignAndSendTransaction/.test(pollingState.current)) {
				setIsDepositLoading(false);
				const signAndSendTransactionData = decryptPayload(data!, nonce!, sharedSecret!);
				if (signAndSendTransactionData?.signature) {
					setValue('0', 0, 0);
					toast(
						<Toast
							title="Success"
							type="success"
							message={t('deposit.depositSuccessMessage', {
								symbol: tokenInfo.symbol,
							})}
						/>,
					);
					setDepositAssetType(undefined);
					setShowDepositResult(true);
					dataDogAddAction(DATADOG_ACTIONS.DEPOSIT_PHANTOM_CONNECT_SIGN_TRANSACTION.SUCCESS);
				} else {
					dataDogAddAction(DATADOG_ACTIONS.DEPOSIT_PHANTOM_CONNECT_SIGN_TRANSACTION.FAIL);
					dataDogAddError(
						{
							message: 'Phantom sign transaction failed',
						},
						{
							tags: {
								name: DATADOG_ERROR_TAGS.DEPOSIT,
								function: 'connectWithPhantom',
							},
						},
					);
				}
			} else if (/onDisconnect/.test(pollingState.current)) {
				setDisconnectingType('Sol');
				console.log('Disconnected!');
			}
		}
		getData();
		return () => abortController.abort();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dappKeyPair?.secretKey, poolingId]);

	const handleConnect = () => {
		setIsConnecting(true);
		pollingState.current = buildState(METHODS.onConnect);
		const params = new URLSearchParams({
			dapp_encryption_public_key: bs58.encode(dappKeyPair.publicKey),
			cluster: 'mainnet-beta',
			app_url: 'https://phantom.app',
			redirect_link: buildRedirectLink(pollingState.current),
		});
		setPoolingId(uuidv4());
		const url = buildPhantomDeepLink('connect', params);
		console.log('url', url);
		dataDogAddAction(DATADOG_ACTIONS.DEPOSIT_USE_PHANTOM);
		(window as any)?.Telegram?.WebApp.openLink(url);
	};

	const handleDisconnect = () => {
		setDisconnectingType('Sol');
		const payload = {
			session,
		};
		pollingState.current = buildState(METHODS.onDisconnect);
		const [nonce, encryptedPayload] = encryptPayload(payload, sharedSecret!);
		const params = new URLSearchParams({
			dapp_encryption_public_key: bs58.encode(dappKeyPair.publicKey),
			nonce: bs58.encode(nonce),
			redirect_link: buildRedirectLink(pollingState.current),
			payload: bs58.encode(encryptedPayload),
		});
		phantomConnectStorages.reset();
		setDappKeyPair(initKeyPairs());
		const url = buildPhantomDeepLink('disconnect', params);
		dataDogAddAction(DATADOG_ACTIONS.DEPOSIT_PHANTOM_DISCONNECT);
		setTimeout(() => {
			setDisconnectingType('Sol');
		}, 500);
		(window as any)?.Telegram?.WebApp.openLink(url);
	};

	const buildTransferSolTransaction = async () => {
		if (!phantomPublicKey) throw new Error('missing public key from user');
		const amount = getSolCoinTransferBalance(state.tokenValue?.toString(), tokenInfo.decimals);
		const transaction = new Transaction().add(
			SystemProgram.transfer({
				fromPubkey: phantomPublicKey,
				toPubkey: new PublicKey(solWallet),
				lamports: amount,
			}),
		);
		transaction.feePayer = phantomPublicKey;
		transaction.recentBlockhash = (await connection.getLatestBlockhash()).blockhash;
		setAmountDeposited(formatUnits(amount, tokenInfo.decimals));
		return transaction;
	};

	const handleDepositSol = async () => {
		try {
			pollingState.current = buildState(METHODS.onSignAndSendTransaction);
			const transaction = await buildTransferSolTransaction();
			const serializedTransaction = transaction.serialize({
				requireAllSignatures: false,
			});
			const payload = {
				session,
				transaction: bs58.encode(serializedTransaction),
			};
			const [nonce, encryptedPayload] = encryptPayload(payload, sharedSecret!);
			const params = new URLSearchParams({
				dapp_encryption_public_key: bs58.encode(dappKeyPair.publicKey),
				nonce: bs58.encode(nonce),
				redirect_link: buildRedirectLink(pollingState.current),
				payload: bs58.encode(encryptedPayload),
			});

			const url = buildPhantomDeepLink('signAndSendTransaction', params);
			console.log('Sending transaction...');
			setPoolingId(uuidv4());
			TelegramCore.WebApp.openLink(url);
		} catch (error) {
			console.error('Deposit sol error:', error);
			throw error;
		}
	};

	async function buildSLPTransaction() {
		const tokenMint = new PublicKey(tokenInfo.address);
		const fromTokenAccount = await getAssociatedTokenAddress(tokenMint, phantomPublicKey!);
		const toPublicKey = new PublicKey(solWallet);
		const toTokenAccount = await getAssociatedTokenAddress(tokenMint, toPublicKey);
		const isTokenAccountAlreadyMade = await checkIfTokenAccountExists(connection, toTokenAccount);
		const transferTransaction = new Transaction();
		if (!isTokenAccountAlreadyMade) {
			const createAccountInstruction = createAssociatedTokenAccountInstruction(
				phantomPublicKey!,
				toTokenAccount,
				toPublicKey,
				tokenMint,
				TOKEN_PROGRAM_ID,
				ASSOCIATED_TOKEN_PROGRAM_ID,
			);
			transferTransaction.add(createAccountInstruction);
		}
		const amount = getSolCoinTransferBalance(state.tokenValue?.toString(), tokenInfo.decimals);
		const transferInstruction = await createTransferInstruction(
			fromTokenAccount,
			toTokenAccount,
			phantomPublicKey!,
			amount,
		);
		transferTransaction.add(transferInstruction);
		transferTransaction.feePayer = phantomPublicKey!;
		transferTransaction.recentBlockhash = (await connection.getLatestBlockhash()).blockhash;
		setAmountDeposited(formatUnits(amount, tokenInfo.decimals));
		return transferTransaction;
	}

	const handleDepositSlpToken = async () => {
		try {
			pollingState.current = buildState(METHODS.onSignAndSendTransaction);
			const transaction = await buildSLPTransaction();
			const serializedTransaction = transaction.serialize({
				requireAllSignatures: false,
			});
			const payload = {
				session,
				transaction: bs58.encode(serializedTransaction),
			};
			const [nonce, encryptedPayload] = encryptPayload(payload, sharedSecret!);
			const params = new URLSearchParams({
				dapp_encryption_public_key: bs58.encode(dappKeyPair.publicKey),
				nonce: bs58.encode(nonce),
				redirect_link: buildRedirectLink(pollingState.current),
				payload: bs58.encode(encryptedPayload),
			});

			const url = buildPhantomDeepLink('signAndSendTransaction', params);
			console.log('Sending transaction...');
			setPoolingId(uuidv4());
			TelegramCore.WebApp.openLink(url);
		} catch (error) {
			console.error('Deposit Slp token error', error);
			throw error;
		}
	};

	const handleDeposit = async () => {
		try {
			setIsDepositLoading(true);
			if (isNativeToken(tokenInfo.address)) {
				await handleDepositSol();
			} else {
				await handleDepositSlpToken();
			}
		} catch (error) {
			console.error('Deposit Sol assets token error', error);
			dataDogAddError(error, {
				tags: {
					name: DATADOG_ERROR_TAGS.DEPOSIT,
					function: 'handleDepositSolAssets',
				},
			});
		}
	};
	const phantomAddress = localStorage.getItem(LOCAL_STORE_KEYS.PHANTOM_WALLET_PUBLICKEY);
	const isConnected = !!session && !!phantomAddress;
	return (
		<>
			<ProviderSelection
				onClick={() => {
					!initToken && setToken(DEFAULT_TOKEN);
					if (phantomConnectStorages.getSession()) {
						return setDepositAssetType('Sol');
					}
					handleConnect();
				}}
				icon={<FiPhantomWallet width={48} height={48} />}
				text={
					phantomAddress
						? getShortAddress(phantomAddress, {
								start: 6,
								end: 4,
						  })
						: t('deposit.phantomWallet')
				}
				onDisconnect={() => {
					handleDisconnect();
				}}
				isConnected={isConnected}
				isDisconnecting={disconnectingType === 'Sol'}
			/>
			{isConnecting && <Loading backgroundColor="transparent" showSpinner />}

			{isOpen && (
				<DepositForm
					isOpen={isOpen}
					onClose={() => setDepositAssetType(undefined)}
					onDeposit={handleDeposit}
					isLoading={isDepositLoading}
					fromWallet={{
						address: phantomAddress,
						logo: <FiPhantomWallet width={40} height={40} />,
					}}
					toWallet={{
						address: solWallet,
						logo: '',
						isTobi: true,
					}}
				/>
			)}
		</>
	);
};
