import { create } from 'zustand';
const initializeData = {
	isOpen: false,
};
export interface IWalletAddressStore {
	isOpen: boolean;
	setOpen: (open: boolean) => void;
	reset: () => void;
}

export const useWalletAddressStore = create<IWalletAddressStore>((set) => ({
	...initializeData,
	setOpen: (open) => set({ isOpen: open }),
	reset: () => set(initializeData),
}));
