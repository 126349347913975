import { isNativeToken } from '@/app-helpers/address';
import { TSentSUiTransaction } from '@/app-types';
import { CoinStruct } from '@mysten/sui/client';
import { Transaction } from '@mysten/sui/transactions';
import { SUI_TYPE_ARG } from '@mysten/sui/utils';
import BigNumber from 'bignumber.js';

export function parseAmount(amount: string, coinDecimals: number) {
	try {
		return BigInt(new BigNumber(amount).shiftedBy(coinDecimals).integerValue().toString());
	} catch (e) {
		return BigInt(0);
	}
}

export function createTokenTransferTransaction({
	to,
	amount,
	token,
	coins,
	isPayAllSui,
}: TSentSUiTransaction & { coins: CoinStruct[]; isPayAllSui?: boolean }) {
	const tx = new Transaction();
	const bigIntAmount = parseAmount(amount, token.decimals);

	if (isNativeToken(token.address)) {
		if (isPayAllSui) {
			tx.transferObjects([tx.gas], to);
			tx.setGasPayment(
				coins
					.filter((coin) => coin.coinType === SUI_TYPE_ARG)
					.map((coin) => ({
						objectId: coin.coinObjectId,
						digest: coin.digest,
						version: coin.version,
					})),
			);
		} else {
			const coin = tx.splitCoins(tx.gas, [bigIntAmount]);
			tx.transferObjects([coin], to);
		}
		return tx;
	}
	const [primaryCoin, ...mergeCoins] = coins.filter((coin) => coin.coinType === token.address);
	const primaryCoinInput = tx.object(primaryCoin.coinObjectId);
	if (mergeCoins.length) {
		tx.mergeCoins(
			primaryCoinInput,
			mergeCoins.map((coin) => tx.object(coin.coinObjectId)),
		);
	}
	const coin = tx.splitCoins(primaryCoinInput, [bigIntAmount]);
	tx.transferObjects([coin], to);
	return tx;
}
