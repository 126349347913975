import { useQrCodeReaderStore } from '@/app-store';
import { useModalStore } from '../store/ModalStore';
import { useConnect } from '.';
import { useTonConnect } from '@/app-features/app-ton-connect/hooks/useConnect';
import { formatTelegramStartParams } from '@/app-helpers/string';
import { getTonConnectParams } from '../utils/HelperUtil';

export const useScanWC = () => {
	const { setOpen, setOnScan } = useQrCodeReaderStore();
	const { isWaiting } = useModalStore();
	const { onConnect } = useConnect();
	const { onConnect: onTonConnect } = useTonConnect();

	async function onScan(data: string | null) {
		try {
			if (!data) return;
			const { connectionUrl, isTonConnect } = getTonConnectParams(data);
			if ((data as string).startsWith('tc://') || isTonConnect) {
				onTonConnect(connectionUrl);
				return setOpen(false);
			}
			if (!isWaiting) {
				await onConnect(data);
				setOpen(false);
			}
		} catch (error) {
			console.error('scan error', error);
		}
	}

	return {
		onScan,
		setOpen,
		setOnScan,
	};
};
