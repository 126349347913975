import { ReactNode, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { ContactAccount } from '@/app-cores/api/contact';
import { getShortAddress } from '@/app-helpers/address';
import { displayUserName, getAccountFullName } from '@/app-helpers/display';
import { FiCheckIcon } from '@/assets/icons';
import { Avatar, Box, Button, Flex, Text } from '@chakra-ui/react';
import { Badge, CopyToClipboard } from '..';
import { useTranslation } from 'react-i18next';
import { getContactWalletAddress } from '@/app-helpers/contact';
import { WalletType } from '@/app-cores/api';

interface ContactListItemProps {
	contact: ContactAccount;
	selected?: boolean;
	onSelect?: (contact: ContactAccount) => void;
	onChange?: () => void;
	blockChainName?: WalletType;
	action?: ReactNode;
}
export const ContactListItem: React.FC<ContactListItemProps> = ({
	contact,
	selected,
	onSelect,
	onChange,
	blockChainName,
	action,
}) => {
	const { t } = useTranslation();
	const wrapperEleRef = useRef<HTMLDivElement>(null);
	const userNameRef = useRef<HTMLDivElement>(null);
	const [fullNameWidth, setFullNameWidth] = useState('150px');
	useEffect(() => {
		if (!selected) return;
		wrapperEleRef.current?.scrollIntoView({
			inline: 'start',
			behavior: 'smooth',
		});
	}, [selected]);

	useLayoutEffect(() => {
		if (!userNameRef.current || !contact) return;
		const width = userNameRef.current.getBoundingClientRect().width;
		const fullNameWidth = `calc(100vw - 162px - ${width}px)`;
		setFullNameWidth(fullNameWidth);
	}, [contact]);

	if (!contact) return null;
	const wallet = getContactWalletAddress(contact, blockChainName);
	return (
		<Flex ref={wrapperEleRef} alignItems="center" gap={3} py={2} onClick={() => onSelect && onSelect(contact)}>
			<Avatar src={contact.avatar} name={getAccountFullName(contact)} size="sm" loading="lazy"></Avatar>
			<Box>
				<Flex alignItems="center" gap={1}>
					<Text
						fontSize="sm"
						fontWeight={600}
						whiteSpace="nowrap"
						overflow="hidden"
						textOverflow="ellipsis"
						maxWidth={fullNameWidth}
						title={getAccountFullName(contact)}
					>
						{getAccountFullName(contact)}
					</Text>
					<Text
						ref={userNameRef}
						fontSize="xs"
						color="gray.400"
						whiteSpace="nowrap"
						overflow="hidden"
						textOverflow="ellipsis"
						title={displayUserName(contact.username)}
					>
						{displayUserName(contact.username)}
					</Text>
				</Flex>
				<Flex alignItems="center" gap={1}>
					<Text fontSize="xs" as="span" color="gray.400">
						{getShortAddress(wallet)}
					</Text>
					{wallet && (
						<Box as="span" onClick={(e) => e.stopPropagation()}>
							<CopyToClipboard copyText="Copy address" text={wallet} />
						</Box>
					)}
				</Flex>
			</Box>
			{selected && (
				<Box marginLeft="auto" as="span">
					<Badge width={8} height={8} backgroundColor="rgba(0, 193, 112, 0.08)">
						<FiCheckIcon fill="#00C170" />
					</Badge>
				</Box>
			)}
			{onChange && (
				<Box marginLeft="auto" as="span">
					<Button size="sm" variant="outline" backgroundColor="white" onClick={onChange}>
						{t('button.change')}
					</Button>
				</Box>
			)}
			{action}
		</Flex>
	);
};
