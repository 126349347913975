import { SwapFeeResponse, TxStatus } from '@/app-cores/api/activities';
import { BffServiceAPI, TokenInfo } from '@/app-cores/api/bff';
import { ServiceConfiguration } from '@/app-cores/api/bot/service-configuration';
import i18n from '@/app-cores/i18n';
import { FeatureConfig } from '@/app-hooks/api/configuration';
import { formatStepsSwap } from '@/app-hooks/swap/helper';
import { GenericRoute, RouteExecuting, SelectedRoute } from '@/app-store/swap';

export type PayloadExtractRoute = {
	route: SelectedRoute;
} & UsdRouteInfo;

export enum SwapType {
	SWAP_EVM = 'Swap',
	SWAP_BERA = 'Swap Bera',
	SWAP_TON = 'Swap Ton',
	SWAP_TRON = 'Swap Tron',
	SWAP_SOL = 'Swap Sol',
	SWAP_SUI = 'Swap Sui',

	WRAP_EVM = 'Wrap',
	UNWRAP_EVM = 'Unwrap',

	CROSSCHAIN_EVM = 'Cross-chain EVM - EVM',
	CROSSCHAIN_EVM_TON = 'Cross-chain EVM - Ton',
	CROSSCHAIN_SOL_TON = 'Cross-chain SOL - Ton',
	CROSSCHAIN_EVM_SOL = 'Cross-chain EVM - SOL',
	CROSSCHAIN_SOL_EVM = 'Cross-chain SOL - EVM',
	CROSSCHAIN_TON_EVM = 'Cross-chain TON - EVM',
	CROSSCHAIN_TON_SOL = 'Cross-chain TON - SOL',

	CROSSCHAIN_SOL_TRON = 'Cross-chain SOL - Tron',
	CROSSCHAIN_EVM_TRON = 'Cross-chain EVM - Tron',
	CROSSCHAIN_TON_TRON = 'Cross-chain TON - Tron',
	CROSSCHAIN_TRON_SOL = 'Cross-chain Tron - SOL',
	CROSSCHAIN_TRON_EVM = 'Cross-chain Tron - EVM',
	CROSSCHAIN_TRON_TON = 'Cross-chain Tron - TON',

	CROSSCHAIN_EVM_SUI = 'Cross-chain EVM - Sui',
	CROSSCHAIN_SOL_SUI = 'Cross-chain SOL - Sui',
	CROSSCHAIN_TON_SUI = 'Cross-chain TON - Sui',
	CROSSCHAIN_TRON_SUI = 'Cross-chain TRON - Sui',
	CROSSCHAIN_SUI_EVM = 'Cross-chain Sui - EVM',
	CROSSCHAIN_SUI_SOL = 'Cross-chain Sui - SOL',
	CROSSCHAIN_SUI_TON = 'Cross-chain Sui - TON',
	CROSSCHAIN_SUI_TRON = 'Cross-chain Sui - TRON',

	NOT_SUPPORT = '404',
}

export enum SwapProvider {
	KYBER = 'KyberSwap',
	LIFI = 'LI.FI',
	STON = 'STON.fi',
	ROCKET = '3rd-Party Agg',
	JUPITER = 'Jupiter',
	DEBRIDGE = 'Debridge',
	SWING = 'Swing',
	BERA = 'Bex',
	CONTRACT = 'Contract Call',
	DEDUST = 'DeDust',
	RETROBRIDGE = 'RetroBridge',
	Sol2Tcat = '2 Third-Parties',
	SUNSWAP = 'SunSwap',
	SEVEN_K_SWAP = '7K Agg',
}

// please dont change these value, they used by backend
export const SwapProviderTracking: { [k in SwapProvider]: string } = {
	[SwapProvider.KYBER]: 'kyber',
	[SwapProvider.LIFI]: 'lifi',
	[SwapProvider.STON]: 'ston',
	[SwapProvider.ROCKET]: 'rocketx',
	[SwapProvider.JUPITER]: 'jupiter',
	[SwapProvider.DEBRIDGE]: 'debridge',
	[SwapProvider.SWING]: 'swing',
	[SwapProvider.BERA]: 'bex',
	[SwapProvider.CONTRACT]: 'contract_call',
	[SwapProvider.DEDUST]: 'dedust',
	[SwapProvider.RETROBRIDGE]: 'retrobridge',
	[SwapProvider.Sol2Tcat]: 'sol2tcat',
	[SwapProvider.SUNSWAP]: 'sunswap',
	[SwapProvider.SEVEN_K_SWAP]: '7k',
};

export enum SwapErrorType {
	FUND = 'not enough fund',
	GAS = 'not enough gas',

	VALIDATE_AMOUNT = 'validated amount in failed',
	VALIDATE_AMOUNT_OUT = 'validated amount out failed',
	VALIDATE = 'common validation',

	ROUTE = 'route related error, show at route section',
}

export type ExtractRouteInfo = {
	amountInUsd?: string | number;
	amountOutUsd?: string | number;
	rate?: number; // 1 usdt = <rate> eth
	duration?: number; // in second
	amountOut: string;
	amountIn: string;
	priceImpact?: number;
	gasUsd: string | number;
	gasNative?: bigint; // to calc and show warning fee
	gasDisplay?: string;
	minAmountOut?: string;
	tokenIn: TokenInfo;
	tokenOut: TokenInfo;
	feeInfo?: SwapFeeResponse;
	dappInfo: {
		logo: string;
		domain: string;
	};
};

export type ArgsGetRoute = {
	amountIn: string | undefined; // amount to call api swap (deduced fee), bigint
	originalAmountIn?: string | undefined; // user input, bigint
	amountInDefault?: string | undefined; // use in case got min amount error, bigint
	tokenIn: TokenInfo | undefined;
	tokenOut: TokenInfo | undefined;
	slippage: number;
};

export type UsdRouteInfo = {
	usdPriceIn?: number;
	usdPriceOut?: number;
	usdPriceNative?: number;
};

export type SwapStatusResponse = { amount_out: number; status: 'Completed'; destination_tx_hash: string };

export type UpdateStatusFn = (v: { status: TxStatus; msg?: string }) => void;

export enum InternalStep {
	SRC_SUBMIT = 1.1,
	SRC_CONFIRM = 1.2,

	DES_WAITING_FOR_ACTION = 1.3,

	DES_SUBMIT = 2.1,
	DES_CONFIRM = 2.2,

	SUCCESS = 3,
}

export abstract class SwapAbstract<T extends GenericRoute> {
	isBlocked: boolean = false;
	abstract getRoute(params: ArgsGetRoute, signal: AbortSignal): Promise<SelectedRoute>;
	abstract extractRoute(params: SelectedRoute<T>, prices: UsdRouteInfo): ExtractRouteInfo;
	formatSlippage(slippage: string | number): number | string {
		return slippage;
	}

	async buildRoute({ route }: { route: SelectedRoute<T>; slippage: number }): Promise<SelectedRoute<GenericRoute>> {
		return route;
	}

	async checkIP(provider: SwapProvider) {
		try {
			const info = {
				[SwapProvider.ROCKET]: {
					featureFlagKey: FeatureConfig.ROCKETX_IP_BLOCKING,
					valueKey: 'isRocketXBlocked',
				},
				[SwapProvider.DEBRIDGE]: {
					featureFlagKey: FeatureConfig.DEBRIDGE_IP_BLOCKING,
					valueKey: 'isDeBridgeBlocked',
				},
			};
			const { valueKey, featureFlagKey } = info[provider] || {};
			const data = await ServiceConfiguration.getFeatureConfig();
			const needCheckBlock = data?.[featureFlagKey];
			this.isBlocked = needCheckBlock ? (await BffServiceAPI.getBlockIPInfo())?.[valueKey] : false;
		} catch (error) {}
	}

	// format step to show in confirmation page
	formatSteps({ status, error }: RouteExecuting = {}, route: SelectedRoute<GenericRoute>): SwapStepInfo[] {
		const stepNum = status;
		const steps = [
			{
				message: i18n.t('tokenTrading.submitTransaction'),
				desc: error,
				id: InternalStep.SRC_SUBMIT,
				status: TxStatus.Waiting,
			},
			{
				message: i18n.t('tokenTrading.confirmTransaction'),
				desc: error,
				id: InternalStep.SRC_CONFIRM,
				status: TxStatus.Waiting,
			},
		];
		return formatStepsSwap({ steps, isFailed: !!error, stepNum });
	}
}

export const JUPITER_REFERRAL_PUBLIC_KEY = '2Scn8CDgERJf6X5UdyC4DhGRRGTfrx4v6Q1WDwcd5Bzm'; // ceo public key
export const MAX_FEE_JUPITER_SWAP = 305000n;

export const SWAP_FEE_PERCENT = 0.25;

export const FEE_ACCOUNTS = {
	EVM: '0x7c154a4595d2e25366ed77d804e77352eea1959d',
	TON: 'UQCdMiuzltVkjLlgYeuauGff-yQPkSdRr8sK5lP8O_Lg9VX0',
	SOL: '6k1nBirdd1ELvymLfo6uCfCRPJqawXkZPwyWJWo9TQEM',
	SUI: '0x02f7b7a44d3c1a2229ca343387aa843d49db6e0b9db7498740c5417341be6057',
};

export class MinAmountError extends Error {
	constructor({ err }: { err?: string }) {
		super(err ?? '');
	}
	get minAmountError() {
		return this.message?.toLowerCase()?.includes('min');
	}
	get isGeneral() {
		return this.minAmountError && !this.minAmount;
	}
	get minAmount() {
		return this.getAmount();
	}
	private getAmount() {
		// 0.00123 ETH
		const amountStr = this.message
			.replace('Min. Amount:', '') // rocketx
			.replace('The amount sent is less than the minimum required: ', ''); // retrobridge

		// 0.00123
		return +amountStr.match(/[\d.]+/g)?.[0];
	}
}

export type SwapStepInfo = {
	id: string | number;
	logo?: string;
	message?: string;
	desc?: string; // explain for message
	steps?: SwapStepInfo[];
	status?: string;
};
