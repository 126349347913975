import React from 'react';

interface IProps extends React.SVGAttributes<HTMLOrSVGElement> {}

export const FiAlertIcon: React.FC<IProps> = ({ width = 16, height = 16, fill = '#E32A2A', ...rest }) => {
	return (
		<svg width={width} height={height} viewBox="0 0 16 17" fill="none" {...rest} xmlns="http://www.w3.org/2000/svg">
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M7.01886 2.31289C7.31839 2.14426 7.65632 2.05566 8.00005 2.05566C8.34379 2.05566 8.68172 2.14426 8.98125 2.31289C9.28078 2.48153 9.53178 2.72451 9.71005 3.01841L9.71197 3.02157L15.3586 12.4482L15.364 12.4575C15.5387 12.7599 15.6311 13.1028 15.6321 13.452C15.633 13.8012 15.5426 14.1446 15.3696 14.448C15.1967 14.7514 14.9473 15.0042 14.6463 15.1813C14.3454 15.3584 14.0033 15.4536 13.654 15.4575L13.6467 15.4575L2.34606 15.4575C1.99686 15.4537 1.65475 15.3584 1.35377 15.1813C1.05279 15.0042 0.803423 14.7514 0.630487 14.448C0.457552 14.1446 0.367072 13.8012 0.36805 13.452C0.369028 13.1028 0.461429 12.7599 0.636061 12.4575L0.641476 12.4482L6.29005 3.0184C6.46832 2.72451 6.71933 2.48153 7.01886 2.31289ZM8.00005 3.389C7.88548 3.389 7.77283 3.41853 7.67299 3.47474C7.5736 3.5307 7.49023 3.61122 7.43086 3.70859L1.78851 13.128C1.73173 13.2279 1.7017 13.3408 1.70138 13.4557C1.70105 13.5721 1.73121 13.6866 1.78886 13.7877C1.8465 13.8888 1.92962 13.9731 2.02995 14.0322C2.1294 14.0907 2.24232 14.1224 2.35765 14.1242H13.6425C13.7578 14.1224 13.8707 14.0907 13.9702 14.0322C14.0705 13.9731 14.1536 13.8888 14.2113 13.7877C14.2689 13.6866 14.2991 13.5721 14.2987 13.4557C14.2984 13.3408 14.2684 13.2279 14.2116 13.1281L8.57005 3.70991C8.56979 3.70947 8.56952 3.70903 8.56925 3.70859C8.50987 3.61122 8.42651 3.5307 8.32712 3.47474C8.22728 3.41853 8.11463 3.389 8.00005 3.389Z"
				fill={fill}
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M8.00004 6.12451C8.36823 6.12451 8.66671 6.42299 8.66671 6.79118V9.45784C8.66671 9.82603 8.36823 10.1245 8.00004 10.1245C7.63185 10.1245 7.33337 9.82603 7.33337 9.45784V6.79118C7.33337 6.42299 7.63185 6.12451 8.00004 6.12451Z"
				fill={fill}
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M7.33337 12.1242C7.33337 11.756 7.63185 11.4575 8.00004 11.4575H8.00671C8.3749 11.4575 8.67337 11.756 8.67337 12.1242C8.67337 12.4924 8.3749 12.7909 8.00671 12.7909H8.00004C7.63185 12.7909 7.33337 12.4924 7.33337 12.1242Z"
				fill={fill}
			/>
		</svg>
	);
};
