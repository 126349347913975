import React from 'react';

interface IProps extends React.SVGAttributes<HTMLOrSVGElement> {}

export const FiDoubleRightIcon: React.FC<IProps> = ({ width = 21, height = 21, ...rest }) => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 21 20" fill="none">
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M10.7441 5.24408C11.0695 4.91864 11.5972 4.91864 11.9226 5.24408L16.0893 9.41074C16.4147 9.73618 16.4147 10.2638 16.0893 10.5893L11.9226 14.7559C11.5972 15.0814 11.0695 15.0814 10.7441 14.7559C10.4186 14.4305 10.4186 13.9028 10.7441 13.5774L14.3215 10L10.7441 6.42259C10.4186 6.09715 10.4186 5.56951 10.7441 5.24408Z"
				fill="black"
				fillOpacity="0.32"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M4.91083 5.24408C5.23626 4.91864 5.7639 4.91864 6.08934 5.24408L10.256 9.41074C10.5814 9.73618 10.5814 10.2638 10.256 10.5893L6.08934 14.7559C5.7639 15.0814 5.23626 15.0814 4.91083 14.7559C4.58539 14.4305 4.58539 13.9028 4.91083 13.5774L8.48824 10L4.91083 6.42259C4.58539 6.09715 4.58539 5.56951 4.91083 5.24408Z"
				fill="black"
			/>
		</svg>
	);
};
