import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { Badge, CTAButton } from '@/app-components/common';
import PageTransition from '@/app-components/layout/PageTransition/PageTransition';
import { NAVIGATE_PATHS } from '@/app-constants/router';
import { useOnEventCallback } from '@/app-hooks/common';
import { Box, Center, Container, Text } from '@chakra-ui/react';
import { FiCheckIcon, FiPlusIcon } from '@/assets/icons';

const INFO_ITEMS = [
	{
		img: (
			<Badge w={10} height={10} type="success">
				<FiPlusIcon />
			</Badge>
		),
		title: 'allSet.actions.deposit',
		pathTo: '/deposit',
	},
];

const AllSet = () => {
	const navigate = useNavigate();
	const { t } = useTranslation();

	const onStartExploring = useOnEventCallback(() => {
		navigate(NAVIGATE_PATHS.Home);
	});

	const onDeposit = useOnEventCallback(() => {
		navigate(NAVIGATE_PATHS.Deposit.Transak);
	});

	return (
		<PageTransition>
			<Container py={4} display="flex" flexDirection="column" height="100vh">
				<Center>
					<Badge width={14} height={14} type="success">
						<FiCheckIcon />
					</Badge>
				</Center>
				<Text fontSize={28} mt={6} fontWeight={700} textAlign="center" as="h1">
					{t('allSet.title')}
				</Text>
				<Text fontSize={14} color="gray.400" textAlign="center">
					{t('allSet.description')}
				</Text>
				<Box mt={6}>
					{INFO_ITEMS.map((a, i) => (
						<Center
							flexDir="column"
							className="border-cyan"
							py={8}
							borderRadius={16}
							key={i}
							onClick={onDeposit}
						>
							{a.img}
							<Text mt={3}>{t(a.title)}</Text>
						</Center>
					))}
				</Box>
				<Box className="bottom">
					<CTAButton size="lg" fontWeight="medium" colorScheme="cyan" width="100%" onClick={onStartExploring}>
						{t('button.startExploring')}
					</CTAButton>
				</Box>
			</Container>
		</PageTransition>
	);
};
export default AllSet;
