import { FiScanIcon } from '@/assets/icons';
import { InputSearch, TextSmall } from '..';
import { useContactStore } from '@/app-store';
import { Box, Button } from '@chakra-ui/react';
import { METAMASK_QR_CODE_REGEX } from '@/app-constants/regex';
import { useTranslation } from 'react-i18next';
import { getBlockChainNameByAddress } from '@/app-helpers/address';
import { useScanWC } from '@/app-features/app-wallet-connect/hooks';

interface ContactSearchProps {
	titleElement?: React.ReactNode;
}
export const ContactSearch: React.FC<ContactSearchProps> = ({ titleElement }) => {
	const { searchKey, setContact, setSearchKey, setAddress } = useContactStore();
	const { setOpen, setOnScan } = useScanWC();
	const { t } = useTranslation();
	const onScan = (data: string) => {
		if (!data) return;
		const isMetamaskQrCode = METAMASK_QR_CODE_REGEX.test(data);
		const address = isMetamaskQrCode ? data.split(':')[1].split('@')[0] : data;
		const blockChainName = getBlockChainNameByAddress(address);
		if (isMetamaskQrCode || blockChainName) {
			setSearchKey(address);
			setOpen(false);
		}
	};

	return (
		<>
			{titleElement ? titleElement : <TextSmall>To</TextSmall>}
			<InputSearch
				autoComplete="off"
				fontSize="sm"
				rightElement={
					<Box cursor="pointer">
						<Button
							_hover={{
								backgroundColor: 'transparent',
							}}
							backgroundColor="transparent"
							onClick={() => {
								setOpen(true);
								setOnScan(onScan);
							}}
						>
							<FiScanIcon />
						</Button>
					</Box>
				}
				placeholder={t('contact.searchPlaceholder')}
				onChange={(e) => {
					setSearchKey(e.target.value);
					setContact(null);
					setAddress('');
				}}
				value={searchKey}
			/>
		</>
	);
};
