import React from 'react';

interface IProps extends React.SVGAttributes<HTMLOrSVGElement> {}

export const FiFaucetIcon: React.FC<IProps> = ({ width = 24, height = 24, ...rest }) => {
	return (
		<svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
			<mask
				id="mask0_10239_19244"
				style={{
					maskType: 'luminance',
				}}
				maskUnits="userSpaceOnUse"
				x="0"
				y="0"
				width="24"
				height="24"
			>
				<path
					d="M18 0H6C2.68629 0 0 2.68629 0 6V18C0 21.3137 2.68629 24 6 24H18C21.3137 24 24 21.3137 24 18V6C24 2.68629 21.3137 0 18 0Z"
					fill="white"
				/>
			</mask>
			<g mask="url(#mask0_10239_19244)">
				<path
					d="M18 0H6C2.68629 0 0 2.68629 0 6V18C0 21.3137 2.68629 24 6 24H18C21.3137 24 24 21.3137 24 18V6C24 2.68629 21.3137 0 18 0Z"
					fill="#0284C7"
				/>
				<path
					d="M19.5141 10.5147H18.8322V9.01954C18.8322 5.45229 15.9207 2.54077 12.3534 2.54077C8.78613 2.54077 5.87461 5.45229 5.87461 9.01954V24.5214H4.51067C4.091 24.5214 3.75 24.8625 3.75 25.282V27.4591H10.9107V25.282C10.9107 24.8625 10.5698 24.5214 10.1501 24.5214H8.78613V9.01954C8.78613 7.05231 10.3861 5.47851 12.3272 5.47851C14.2682 5.47851 15.8682 7.07854 15.8682 9.01954V10.5147H15.16C14.7403 10.5147 14.3993 10.8556 14.3993 11.2753V12.6917C14.3993 13.1114 14.7403 13.4524 15.16 13.4524H19.4878C19.9075 13.4524 20.2486 13.1114 20.2486 12.6917V11.2753C20.2747 10.8556 19.9338 10.5147 19.5141 10.5147Z"
					fill="white"
				/>
				<path
					d="M17.8353 15.1835C17.7566 14.9999 17.5467 14.895 17.3631 14.895C17.1533 14.895 16.9697 14.9999 16.8911 15.1835C16.8911 15.1835 15.7893 17.3081 15.5534 17.8066C15.2648 18.4099 15.2648 19.0393 15.5534 19.6426C15.8943 20.3246 16.6025 20.7704 17.3631 20.7704C17.678 20.7704 17.9927 20.6918 18.2813 20.5607C18.7533 20.3246 19.1205 19.9049 19.3041 19.3804C19.4616 18.8557 19.4354 18.3049 19.1993 17.8327L17.8353 15.1835Z"
					fill="white"
				/>
			</g>
		</svg>
	);
};
