import { axiosWalletConnectServiceAPI } from '@/app-cores/api/axios';

class WalletConnectService {
	async subscribe(sessions: { topic: string; key: string }[]) {
		const response = await axiosWalletConnectServiceAPI.post(`/api`, { sessions });
		return response.data;
	}
}

export const WalletConnectServiceAPI = new WalletConnectService();
