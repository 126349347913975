import { CHAIN_CONFIG, ChainId, WRAP_SOL_ADDRESS } from '@/app-constants/chains';
import { BffServiceAPI, TokenInfo } from '@/app-cores/api/bff';
import { SolWallet } from '@/app-cores/mpc-wallet/solana/SolWallet';
import { isNativeToken } from '@/app-helpers/address';

import { commonGetRoute, displayMaxFee, isRouteParamsEmpty } from '@/app-hooks/swap';
import {
	ArgsGetRoute,
	ExtractRouteInfo,
	MAX_FEE_JUPITER_SWAP,
	SWAP_FEE_PERCENT,
	SwapAbstract,
	SwapProvider,
	UpdateStatusFn,
	UsdRouteInfo,
} from '@/app-hooks/swap/type';
import { GenericRoute, SelectedRoute } from '@/app-store/swap';
import { useMutation } from '@tanstack/react-query';
import axios, { AxiosRequestConfig } from 'axios';
import { ethers } from 'ethers';
import { AUTO_SLIPPAGE } from '@/app-views/swap/components/SlippageSetting';
import { uniqueId } from '@/app-helpers/random';
import { calcRateSwap, filterParams } from '@/app-hooks/swap/helper';
import { useSubmitSolTransaction } from '@/app-hooks/transactions/sol/useMutationSentSolAsset';
import { TransactionType } from '@/app-types';
import { useTakeFeeSwap } from '@/app-hooks/swap/useTakeFeeSwap';

const JUPITER_API_DOMAIN = 'https://quote-api.jup.ag';
const swapMode = 'ExactIn';

const getTokenAddress = (token: TokenInfo) => (isNativeToken(token?.address) ? WRAP_SOL_ADDRESS : token.address);

const getBuildRouteParams = (args): AxiosRequestConfig => {
	return { url: `${JUPITER_API_DOMAIN}/v6/swap`, method: 'POST', data: args };
};

class Jupiter extends SwapAbstract<RouteJupiter> {
	#swapData: { lastValidBlockHeight: number; prioritizationFeeLamports: number; swapTransaction: string };
	get swapData() {
		return this.#swapData;
	}

	async getRoute(paramsSwap: ArgsGetRoute, signal: AbortSignal) {
		const payload = getRouteParamsSwapJupiter(paramsSwap, signal);
		if (!payload) return;
		const data = await commonGetRoute(payload, paramsSwap);
		return formatRouteJupiter(data, paramsSwap);
	}

	async buildRoute({ route }: { route: SelectedRoute<RouteJupiter> }): Promise<SelectedRoute<GenericRoute>> {
		const { route: routeData } = route;
		const { fromPubKey } = await SolWallet.init('mainnet-beta', {
			commitment: 'confirmed',
		});
		let feeAccount;
		try {
			feeAccount = await BffServiceAPI.getJupiterAta(
				swapMode === 'ExactIn' ? routeData.outputMint : routeData.inputMint,
			);
		} catch (error) {
			console.error('Get referral account error', error);
		}
		const params = {
			userPublicKey: fromPubKey.toString(),
			quoteResponse: routeData,
			feeAccount: feeAccount?.toString?.(),
			prioritizationFeeLamports: 'auto',
			//  {
			// 	priorityLevelWithMaxLamports: {
			// 		global: false,
			// 		maxLamports: 4000000,
			// 		priorityLevel: 'veryHigh',
			// 	},
			// },
			dynamicComputeUnitLimit: true,
		};
		const response = await axios(getBuildRouteParams(params));
		const data = response.data;
		this.#swapData = data;
		return route;
	}

	extractRoute(params: SelectedRoute<RouteJupiter>, prices: UsdRouteInfo): ExtractRouteInfo {
		return getExtractRouteJupiter(params, prices);
	}
}
export const JupiterSwap = new Jupiter();

const getRouteParamsSwapJupiter = (args: ArgsGetRoute, signal?: AbortSignal): AxiosRequestConfig => {
	const { tokenIn, tokenOut, amountIn, slippage } = args;

	if (isRouteParamsEmpty(args)) return;
	const params = {
		inputMint: getTokenAddress(tokenIn),
		outputMint: getTokenAddress(tokenOut),
		amount: amountIn,
		slippageBps: (slippage * 100) | 0, // 0 5 10
		maxAccounts: 64,
		swapMode,
		autoSlippage: +slippage === AUTO_SLIPPAGE,
		computeAutoSlippage: true,
		platformFeeBps: SWAP_FEE_PERCENT * 100,
	};

	filterParams(params);
	return { url: `${JUPITER_API_DOMAIN}/v6/quote`, params, signal };
};

type SwapInfo = {
	ammKey: string;
	label: string;
	inputMint: string;
	outputMint: string;
	inAmount: string;
	outAmount: string;
	feeAmount: string;
	feeMint: string;
};

type RoutePlanItem = {
	swapInfo: SwapInfo;
	percent: number;
};

export type RouteJupiter = {
	inputMint: string;
	inAmount: string;
	outputMint: string;
	outAmount: string;
	otherAmountThreshold: string;
	swapMode: string;
	slippageBps: number;
	platformFee: null;
	priceImpactPct: string;
	routePlan: RoutePlanItem[];
	contextSlot: number;
	timeTaken: number;

	// custom
	tokenIn: TokenInfo;
	tokenOut: TokenInfo;
};

const formatRouteJupiter = (routeData: RouteJupiter, params: ArgsGetRoute): SelectedRoute<RouteJupiter> =>
	routeData
		? {
				...routeData,
				route: routeData,
				routerAddress: '',
				id: uniqueId(),
				provider: SwapProvider.JUPITER,
				timestamp: Date.now(),
				params,
		  }
		: undefined;

const getExtractRouteJupiter = (
	selectedRoute: SelectedRoute<RouteJupiter>,
	{ usdPriceIn, usdPriceOut, usdPriceNative }: UsdRouteInfo = {},
): ExtractRouteInfo => {
	const routeSwap = selectedRoute?.route;
	const tokenIn = selectedRoute?.tokenIn;
	const tokenOut = selectedRoute?.tokenOut;

	return {
		amountOut: routeSwap?.outAmount,
		amountIn: routeSwap?.inAmount,
		minAmountOut: routeSwap?.otherAmountThreshold,
		tokenIn,
		tokenOut,
		gasUsd: undefined,
		gasNative: BigInt(CHAIN_CONFIG[ChainId.SOL].minForGas),
		gasDisplay: displayMaxFee({
			usdPriceNative,
			chainId: ChainId.SOL,
			gasNative: MAX_FEE_JUPITER_SWAP,
		}),
		dappInfo: {
			logo: '/icons/brands/jupiter.png',
			domain: SwapProvider.JUPITER,
		},
	};
};

export const useExecuteRouteJupiter = () => {
	const { sendTransaction: submitSolTxs } = useSubmitSolTransaction();
	const takeFee = useTakeFeeSwap();

	const response = useMutation({
		mutationKey: ['exe-route-jupiter'],
		mutationFn: async ({
			route: routeData,
			onUpdateStatus,
		}: {
			route: SelectedRoute<RouteJupiter>;
			onUpdateStatus: UpdateStatusFn;
		}) => {
			const { swapTransaction, lastValidBlockHeight } = JupiterSwap.swapData;
			const swapTransactionBuf = Buffer.from(swapTransaction, 'base64');
			const hash = await submitSolTxs({
				data: swapTransactionBuf,
				metadata: routeData,
				transactionType: TransactionType.Swap,
				lastValidBlockHeight,
				callback: onUpdateStatus,
			});
			return { hash };
		},
		onSuccess: ({ hash }, { route }) => {
			takeFee({ hash, route });
		},
	});
	return response;
};
