import React from 'react';

interface IProps extends React.SVGAttributes<HTMLOrSVGElement> {}

export const OpIcon: React.FC<IProps> = ({ width = 56, height = 56, ...rest }) => {
	return (
		<svg width={width} height={height} viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
			<g clipPath="url(#clip0_8601_5476)">
				<path
					d="M28 56C43.464 56 56 43.464 56 28C56 12.536 43.464 0 28 0C12.536 0 0 12.536 0 28C0 43.464 12.536 56 28 56Z"
					fill="#FF0420"
				/>
				<path
					d="M19.8353 35.4369C18.1665 35.4369 16.8001 35.0449 15.7361 34.2609C14.6833 33.4657 14.1569 32.3233 14.1569 30.8561C14.1569 30.5425 14.1905 30.1729 14.2577 29.7249C14.4369 28.7169 14.6945 27.5073 15.0305 26.0849C15.9825 22.2321 18.4465 20.3057 22.4113 20.3057C23.4865 20.3057 24.4609 20.4849 25.3121 20.8545C26.1633 21.2017 26.8353 21.7393 27.3281 22.4561C27.8209 23.1617 28.0673 24.0017 28.0673 24.9761C28.0673 25.2673 28.0337 25.6369 27.9665 26.0849C27.7537 27.3281 27.5073 28.5489 27.2049 29.7249C26.7121 31.6401 25.8721 33.0849 24.6625 34.0369C23.4641 34.9777 21.8513 35.4369 19.8353 35.4369ZM20.1377 32.4129C20.9217 32.4129 21.5825 32.1777 22.1313 31.7185C22.6913 31.2593 23.0945 30.5537 23.3297 29.5905C23.6545 28.2689 23.9009 27.1265 24.0689 26.1409C24.1249 25.8497 24.1585 25.5473 24.1585 25.2337C24.1585 23.9569 23.4977 23.3185 22.1649 23.3185C21.3809 23.3185 20.7089 23.5537 20.1489 24.0129C19.6001 24.4721 19.2081 25.1777 18.9729 26.1409C18.7153 27.0817 18.4689 28.2241 18.2113 29.5905C18.1553 29.8705 18.1217 30.1617 18.1217 30.4753C18.1105 31.7745 18.7937 32.4129 20.1377 32.4129Z"
					fill="white"
				/>
				<path
					d="M29.0415 35.2351C28.8847 35.2351 28.7727 35.1903 28.6831 35.0895C28.6159 34.9775 28.5935 34.8543 28.6159 34.7087L31.5167 21.0447C31.5391 20.8879 31.6175 20.7647 31.7519 20.6639C31.8751 20.5631 32.0095 20.5183 32.1551 20.5183H37.7439C39.3007 20.5183 40.5439 20.8431 41.4847 21.4815C42.4367 22.1311 42.9183 23.0607 42.9183 24.2815C42.9183 24.6287 42.8735 24.9983 42.7951 25.3791C42.4479 26.9919 41.7423 28.1791 40.6671 28.9519C39.6143 29.7247 38.1695 30.1055 36.3327 30.1055H33.4991L32.5359 34.7087C32.5023 34.8655 32.4351 34.9887 32.3007 35.0895C32.1775 35.1903 32.0431 35.2351 31.8975 35.2351H29.0415ZM36.4783 27.2047C37.0719 27.2047 37.5759 27.0479 38.0127 26.7231C38.4607 26.3983 38.7519 25.9391 38.8975 25.3343C38.9423 25.0991 38.9647 24.8863 38.9647 24.7071C38.9647 24.3039 38.8415 23.9903 38.6063 23.7775C38.3711 23.5535 37.9567 23.4415 37.3855 23.4415H34.8655L34.0703 27.2047H36.4783Z"
					fill="white"
				/>
			</g>
			<defs>
				<clipPath id="clip0_8601_5476">
					<rect width="56" height="56" fill="white" />
				</clipPath>
			</defs>
		</svg>
	);
};
