import {
	AlertDialog,
	AlertDialogBody,
	AlertDialogContent,
	AlertDialogOverlay,
	Button,
	Center,
	Text,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { isEmpty } from 'lodash-es';
import { useTranslation } from 'react-i18next';
import { NAVIGATE_PATHS } from '@/app-constants/router';
import { FiBackupIcon } from '@/assets/icons';
import { useQueryUserProfile } from '@/app-hooks/api/user/useQueryUserProfile';
import { getUnsecuredKeyStore } from '@/app-cores/mpc-wallet/wallet/helper';

interface RequireBackupProps {}

export const RequireBackup: React.FC<RequireBackupProps> = () => {
	const navigate = useNavigate();
	const [isOpen, setIsOpen] = useState(false);
	const cancelRef = React.useRef();
	const { data: userProfile, isLoading } = useQueryUserProfile();
	const { t } = useTranslation();

	const onBackup = () => {
		setIsOpen(false);
		navigate(NAVIGATE_PATHS.CreateWallet.StoreBackupKey);
	};

	useEffect(() => {
		if (isEmpty(userProfile)) return;
		const unsecuredKey = getUnsecuredKeyStore();
		if (unsecuredKey && !userProfile.backupType && userProfile.defaultWallet) {
			setIsOpen(true);
		}
	}, [userProfile]);

	if (isLoading || userProfile?.backupType) return null;
	return (
		<AlertDialog
			size="xs"
			isCentered
			isOpen={isOpen}
			leastDestructiveRef={cancelRef}
			onClose={() => setIsOpen(false)}
			autoFocus={false}
			trapFocus={false}
			closeOnEsc={false}
			closeOnOverlayClick={false}
		>
			<AlertDialogOverlay>
				<AlertDialogContent borderRadius="1.5rem">
					<AlertDialogBody py={10} px={8}>
						<Center flexDirection="column" textAlign="center">
							<FiBackupIcon />
							<Text mt={2} fontSize="1.25rem">
								{t('storeBackup.title')}
							</Text>

							<Text mt={3} fontSize="xs">
								{t('storeBackup.reminder')}
							</Text>
							<Button
								onClick={onBackup}
								mt={6}
								ref={cancelRef}
								size="lg"
								fontWeight="medium"
								colorScheme="cyan"
								width="100%"
							>
								{t('storeBackup.backupNow')}
							</Button>
						</Center>
					</AlertDialogBody>
				</AlertDialogContent>
			</AlertDialogOverlay>
		</AlertDialog>
	);
};
