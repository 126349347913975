import { ButtonSecondary, CTAButton, InputSearch, Loading } from '@/app-components/common';
import {
	Box,
	Button,
	Card,
	CardBody,
	Center,
	Divider,
	Flex,
	HStack,
	Image,
	List,
	ListItem,
	Text,
	VStack,
	useDisclosure,
	Avatar,
} from '@chakra-ui/react';
import React, { ReactNode, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TelegramCore } from '@/app-cores/telegram';
import { FiArrowRightIcon, FiMailIcon, FiSettingIcon, FiSuccessIcon } from '@/assets/icons';
import { COMMUNITY_LINK } from '@/app-constants';
import { FiUserIcon } from '@/assets/icons/fi-user-icon';
import { useNavigate } from 'react-router';
import { NAVIGATE_PATHS } from '@/app-constants/router';
import { formatNumber, formatUnits } from '@/app-helpers/number';
import i18n from '@/app-cores/i18n';
import AppLayout from '@/app-components/layout/PageLayout';
import { colors } from '@/app-theme/theme';
import {
	FARM_IDS,
	TobiFarmType,
	useQueryDebridgePoint,
	useQueryKyberPoint,
	useQueryTobiFarm,
} from '@/app-hooks/api/dapps/useTobiFarm';
import { escapeScriptHtml } from '@/app-helpers/string';
import Bg from '@/assets/images/bg-image.svg';
import { FiHotIcon } from '@/assets/icons/fi-hot-icon';
import TobiTag from '@/app-components/common/TobiTag';
import { FiFarmIcon } from '@/assets/icons/fi-farm-icon';
import { useNavigateToUrl } from '@/app-helpers/navigate';
import { useQueryContactList } from '@/app-hooks/api/accouts';
import { ContactAccount } from '@/app-cores/api/contact';
import { useDebounce } from '@/app-hooks/common';
import { useRequestFundStore } from '@/app-store/request-fund';
import { UserItem } from '@/app-views/request-fund/ConfirmRequestFund';
import { RequestFundLayout } from '@/app-views/request-fund';
import { useSearchParams } from 'react-router-dom';

const RequestFundResult = () => {
	const { t } = useTranslation();
	const [searchParams] = useSearchParams();
	const { friends = [], setFriends, amountMapping } = useRequestFundStore();
	const navigate = useNavigate();
	const onBackHome = () => {
		navigate('/', { replace: true });
	};

	return (
		<RequestFundLayout
			title="Result"
			action={
				<Flex p={4} direction={'column'}>
					<ButtonSecondary
						display="block"
						mb={2}
						size="lg"
						fontWeight="medium"
						width="100%"
						onClick={onBackHome}
					>
						{t('button.backHome')}
					</ButtonSecondary>
					<CTAButton
						size={'lg'}
						colorScheme={'cyan'}
						height={'56px'}
						width={'100%'}
						onClick={() =>
							navigate(NAVIGATE_PATHS.RequestFund.requestDetail(searchParams.get('requestId')))
						}
					>
						{t('requestPayment.trackRequest')}
					</CTAButton>
				</Flex>
			}
		>
			<Flex direction={'column'} gap={4} py={4} flex={1} justify={'center'}>
				<Flex align={'center'} flexDirection={'column'} gap={2}>
					<Center>
						<FiSuccessIcon height={'56px'} width={'56px'} />
					</Center>
					<Center fontSize={'24px'} fontWeight={'bold'} textAlign={'center'} lineHeight={'30px'}>
						{t('requestPayment.sendSuccess')}
					</Center>
				</Flex>
				<Center fontSize={'14px'} color={colors.gray[400]} textAlign={'center'}>
					{t('requestPayment.sendSuccessDesc')}
				</Center>
				<Card gap={4} display={'flex'} p={4} flex={1} overflowY={'auto'}>
					{friends?.map((el, i) => {
						return (
							<UserItem
								key={el._id}
								data={el}
								amount={amountMapping[el._id]}
								isLast={i === friends.length - 1}
							/>
						);
					})}
				</Card>
			</Flex>
		</RequestFundLayout>
	);
};

export default RequestFundResult;
