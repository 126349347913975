import { formatJsonRpcError, formatJsonRpcResult } from '@json-rpc-tools/utils';
import { SignClientTypes } from '@walletconnect/types';
import { getSdkError } from '@walletconnect/utils';
import { TRON_MAINNET_CHAINS, TRON_SIGNING_METHODS, TRON_TEST_CHAINS } from '../data/TronData';
import { tronWallet } from '@/app-cores/mpc-wallet/tron';

export async function approveTronRequest(requestEvent: SignClientTypes.EventArguments['session_request']) {
	const { params, id } = requestEvent;
	const { request } = params;

	if (TRON_MAINNET_CHAINS[params.chainId]) {
		tronWallet.tronWeb.setFullNode(TRON_MAINNET_CHAINS[params.chainId].fullNode);
	} else if (TRON_TEST_CHAINS[params.chainId]) {
		tronWallet.tronWeb.setFullNode(TRON_TEST_CHAINS[params.chainId].fullNode);
	} else {
		throw new Error('Invalid chain id');
	}

	switch (request.method) {
		case TRON_SIGNING_METHODS.TRON_SIGN_MESSAGE: {
			const signedMessage = await tronWallet.signMessage(request.params.message);
			const res = {
				signature: signedMessage,
			};
			return formatJsonRpcResult(id, res);
		}

		case TRON_SIGNING_METHODS.TRON_SIGN_TRANSACTION: {
			console.log('request.params.transaction.transaction', request.params.transaction);
			const signedTransaction = await tronWallet.signTransaction(request.params.transaction.transaction);
			const resData = {
				result: signedTransaction,
			};
			return formatJsonRpcResult(id, resData);
		}
		default:
			throw new Error(getSdkError('INVALID_METHOD').message);
	}
}

export function rejectTronRequest(request: SignClientTypes.EventArguments['session_request']) {
	const { id } = request;

	return formatJsonRpcError(id, getSdkError('USER_REJECTED_METHODS').message);
}
