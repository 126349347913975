import {
	Center,
	Drawer,
	DrawerBody,
	DrawerCloseButton,
	DrawerContent,
	DrawerHeader,
	DrawerOverlay,
	Text,
} from '@chakra-ui/react';
import { isMobile } from 'react-device-detect';
import { useDepositStore } from '../../store';
import { TonConnect } from './ton/TonConnect';
import { PhantomConnect } from './sol/PhantomConnect';
import { WalletConnect } from './evm/WalletConnect';
import { getTokenInfo, isEvmChain, isSolanaChain, isTonChain } from '@/app-helpers/token';
import { useMemo } from 'react';

export const WalletProvider = () => {
	const { isOpenWalletProvider, onToggleWalletProvider, initToken } = useDepositStore();
	const availableProviders = useMemo(() => {
		const info = getTokenInfo(initToken);
		if (!initToken)
			return (
				<>
					<TonConnect />
					{isMobile && <PhantomConnect />}
					<WalletConnect />
				</>
			);
		return (
			<>
				{initToken && isTonChain(info.chainId) && <TonConnect />}
				{initToken && isMobile && isSolanaChain(info.chainId) && <PhantomConnect />}
				{initToken && isEvmChain(info.chainId) && <WalletConnect />}
			</>
		);
	}, [initToken]);
	return (
		<Drawer
			isOpen={isOpenWalletProvider}
			placement="bottom"
			onClose={() => onToggleWalletProvider(false)}
			trapFocus={false}
		>
			<DrawerOverlay />
			<DrawerContent>
				<DrawerCloseButton top="0.75rem" />
				<DrawerHeader borderBottomWidth="1px" borderBottomColor="gray.100">
					<Center>
						<Text fontSize="medium" fontWeight={600}>
							Connect Wallet
						</Text>
					</Center>
				</DrawerHeader>

				<DrawerBody mt={3}>{availableProviders}</DrawerBody>
			</DrawerContent>
		</Drawer>
	);
};
