export const WALLET_UNIVERSAL_LINKS = {
	'bitkeep://': 'https://bkapp.vip',
	'dfw://': 'https://wallet.crypto.com',
	'okex://main': 'https://www.okx.com/download',
	'tpoutside://': null,
	'robinhood-wallet://': null,
	'frontier://': null,
	'blockchain-wallet://': 'https://www.blockchain.com',
	'safepalwallet://': 'https://link.safepal.io',
	'omni://': 'https://links.omni.app',
	'zengo://get.zengo.com/': 'https://get.zengo.com/',
	'oneinch://': 'https://wallet.1inch.io/',
	'bnc://app.binance.com/cedefi/': 'https://app.binance.com/cedefi',
	'exodus://': 'https://exodus.com/m',
	'ledgerlive://': null,
	'mewwallet://': 'https://mewwallet.com',
	'awallet://': 'https://aw.app',
	'keyring://': 'https://keyring.app/',
	'lobstr://': 'https://lobstr.co/uni/wc',
	'ontoprovider://': null,
	'mathwallet://': 'https://www.mathwallet.org',
	'unstoppabledomains://': 'https://unstoppabledomains.com/mobile',
	'obvious://': 'https://wallet.obvious.technology',
	'fireblocks-wc://': null,
	'ambire://': 'https://mobile.ambire.com',
	'undefined': 'https://api.tobiwallet.app/dapp/wallet-connect/redirect',
	'internetmoney://': 'https://internetmoney.io',
	'nowwallet://': 'https://walletnow.app.link',
	'bitcoincom://': 'https://wallet.bitcoin.com/',
	'alpha-u-wallet://':
		'https://alphauwallet.page.link/?apn=com.kddi.wallet&ibi=com.kddi.wallet&isi=6444401106&link=https://web.wallet.alpha-u.io/intro.html',
	'coin98://': 'https://coin98.com/',
	'abc-wallet://abcwc':
		'https://abcwalletconnect.page.link/?apn=io.myabcwallet.mpc&ibi=io.myabcwallet.mpc&isi=1642837445&efr=1&ofl=https://myabcwallet.io/download&link=https://myabcwallet.io',
	'arculuswc://': 'https://gw.arculus.co/app',
	'cryptobrowser://': null,
	'chainapp://': null,
	'huddln://': null,
	'verso://': null,
	'haha://': 'https://haha.me',
	'modularwallet://': null,
	'link.kelp.finance://walletconnect': 'https://link.kelp.finance/walletconnect',
	'cling://': 'https://cling.carrieverse.com/apple-app-site-association',
	'broearn://wallet/': 'https://www.broearn.com/link/wallet',
	'coinomi://': 'https://coinomi.page.link',
	'ripio://portal': null,
	'myapp://kh.com.sabay.sabaywallet': null,
	'mtwallet://app': null,
	'metacubewallet://': null,
	'copiosa://': 'https://copiosa.io/action/',
	'libera://': null,
	'burrito://': 'https://app.burritowallet.com',
	'ancrypto://app': null,
	'cypherwallet://': null,
	'enjinwallet://': 'https://deeplink.wallet.enjin.io/',
	'everspace://': 'https://everspace.app/deeplink',
	'kriptomatapp://wallet-connect': null,
	'oxalus://':
		'https://oxalus.page.link/?apn=io.oxalus.wallet&isi=1620111723&ibi=io.oxalus.wallet&link=https://deeplink.oxalus.io',
	'wc://': null,
	'leapcosmos://': null,
	'islamiwallet://islami.com/path/': 'https://islamicoin.finance/.well-known/assetlinks.json',
	'wirexwallet://':
		'https://wwallet.app.link/wc?uri=wc:00e46b69-d0cc-4b3e-b6a2-cee442f97188@1?bridge=https%3A%2F%2Fbridge.walletconnect.org&key=91303dedf64285cbbaf9120f6e9d160a5c8aa3deb67017a3874cd272323f48ae',
	'celo://wallet': 'https://valoraapp.com',
	'paraswap://': 'https://wallet.paraswap.io/#/',
	'sahalwallet://': null,
	'ennowallet://': null,
	'loopring://': null,
	'bee://': 'https://main.apple.bee9527.com',
	'neon://uri=': null,
	'linen://': 'https://linen.app/',
	'spatium://': 'https://link.spatium.net',
	'cryptnox://': null,
	'assure://': 'https://www.assure.pro/Official',
	'floozwallet://': 'https://wallet.flooz.trade/wc',
	'keplrwallet://wcv2': null,
	'core://': 'https://core.app',
	'dcent://': 'https://link.dcentwallet.com',
	'kleverwallet://': 'https://klever.page.link',
	'edge://': 'https://deep.edge.app/wc',
	'nefti://nefti.id/asset/': 'https://nefti.id/asset/',
	'goldbit://': null,
	'coingrig://': 'https://link.coingrig.com',
	'xfunwallet://': 'https://xfun.io',
	'ricewallet://': 'https://ricewallet.io',
	'oksewallet://': 'https://okse.io/',
	'aktionariat://': 'https://app.aktionariat.com',
	'huobiwallet://': null,
	'zeluswallet://': null,
	'talkapp://': null,
	'cardwallet://': 'https://wallet.cardstack.com',
	'paybolt://wallet': null,
	'com.arianee.wallet://': 'https://i.arian.ee',
	'slaviwallet://': 'https://www.slaviwallet.io',
	'plasmawallet://': 'https://plasma-wallet.com',
	'defiantapp://': 'https://defiantapp.tech/',
	'strikex://': null,
	'avacus://': 'https://avacus.app.link',
	'hideoutwallet://': null,
	'coolwallet://': null,
	'opto://': null,
	'tk://': 'https://trustkeys.network',
	'beewallet.app://': 'https://beewallet.app/wc',
	'ttmwalletapp://': null,
	'pltwallet://': 'https://pltwallet.io/',
	'helix-id://helix-id.com': null,
	'airgap-wallet://': null,
	'qubic://': 'https://wallet.qubic.app',
	'holdstation://': 'https://staging-referral.holdstation.com/wallet-connect?uri=',
	'metaone://': null,
	'bhcwallet://': null,
	' payperless.com://': null,
	'minerva://': 'https://minerva.digital',
	'volt://': 'https://get.voltage.finance',
	'lif3wallet://walletview': null,
	'shinobi-wallet://': null,
	'kryptogo://': 'https://kryptogo.page.link',
	'autonomy-wc://': 'https://autonomy.io/apps/wc',
	'bifrostwallet://': 'https://app.bifrostwallet.com',
	'vimwallet://': 'https://apple.vimworld.org',
	'wallet3://': null,
	'abra://': null,
	'premawallet://': 'https://premanft.com',
	'onekey-wallet://': 'https://app.onekey.so/wc/connect',
	'slingshot://': 'https://app.slingshot.finance',
	'kriptonio://': 'https://app.kriptonio.com/mobile',
	'timeless-wallet://': 'https://timelesswallet.xyz',
	'bitski://': 'https://wallet.bitski.com/walletconnect/wc',
	'streakk://': null,
	'saitapro://app': null,
	'frw://': 'https://frw-link.lilico.app/wc',
	'hippowallet://': 'https://hippowallet.io',
	'cosmostation://': null,
	'bitizen://wallet/': 'https://bitizen.org/wallet/',
	'blocto://': 'https://blocto.app',
	'humblwallet://': 'https://wallet.search3.com',
	'com.wallet.passpay://': null,
	'ultimate://': null,
	'astrox://me/wcx': null,
	'thorwallet://': null,
	'fizz://wallet-connect': null,
	'stickyapp://': 'https://stickeyapp.page.link',
	'klipwallet://': null,
	'coinstats://': 'http://coinstats.app',
	'com.oice://': null,
	'krystalwallet://': 'https://wallet.krystal.app/',
	'pillarwallet://': null,
	'harti://': null,
	'stasis://': null,
	'novawallet://': null,
	'dotted://': null,
	'foxwallet://': 'https://link.foxwallet.com',
	'haqq://': null,
	'tomiwallet://': null,
	'bybitapp://open/route?targeturl=by://web3/walletconnect/': null,
	'subwallet://': 'https://mobile.subwallet.app/',
	'okto://':
		'https://okto.go.link/?adj_t=j39b9kp&adj_fallback=https%3A%2F%2Fokto.tech&adj_redirect_macos=https%3A%2F%2Fokto.tech',
	'catecoin://': 'https://catecoin.app/apple-app-site-association',
	'ukisshub://': null,
	'tellaw://walletconnect': null,
	'tangem://': 'https://app.tangem.com',
	'callback://': null,
	'saas://success': null,
	'xellar://': null,
	'talken-wallet://': null,
	'u2uwc://':
		'https://u2u.page.link/?apn=org.u2u.wallet&isi=6446194312&ibi=org.uniultra.wallet&link=https://uniultra.xyz/?referrer%3D',
	'chiawallet://ozonewallet.io': null,
	'tiduswallet://walletconnect': null,
	'impactsx://': null,
	'zel://': null,
	'app.dosivault://': 'https://dosivault.page.link/qL6j',
	'ullawallet://': null,
	'ellipal://': 'https://www.ellipal.com',
	'unstoppable.money://': null,
	'aurora-pass://': null,
	'bitverseapp://open/wallet':
		'https://bitverseapp.page.link/?apn=com.bitverse.app&afl=https://bitverse.zone/download?deeplink%3Dbitverseapp://open/wallet&isi=1645515614&ibi=com.bitverse.app&link=https://bitverse.zone/download?deeplink%3Dbitverseapp://open/wallet?uri=',
	'konio://': 'https://konio.io',
	'gtweb3wallet://': null,
	'utorg://mainactivity': 'https://link.utorg.com/zp0f',
	'coinwallet://': null,
	'ammerwallet://': null,
	'bncus://binance.us': 'https://binance.us/universal_JHHGDSKDJ',
	'muza://': 'https://muza.co/app',
	'fxwallet://': 'https://fx.wallet/wc',
	'ryipay://': 'https://ryipay.page.link/3N7P',
	'roninwallet://': 'https://wallet.roninchain.com',
	'metawallet://com.metawallet.client': 'http://www.dota168.org/',
	'wc-altme://': null,
	'bitpiewc://': null,
	'moonstake://': 'https://moonstake.io/launchApp',
	'indigg://walletconnect': null,
	'yuse://wallet://': 'https://yusewallet.page.link/tobR',
	'coininn_wallet_wc://request': null,
	'pockie://': 'https://pockie.app.link',
	'amazeapp://amazewallet.com': null,
	'paliwallet://': null,
	'co.theeasy.app://': 'https://link.easy.me',
	'superwallet://': 'https://www.superex.live',
	'secux://': 'https://wsweb.secuxtech.com',
	'abt://didwallet.io/i': 'https://didwallet.io/i',
	'halowallet://': 'https://link.kuwallet.com/universallink',
	'sinohopeapp://': null,
	'balletcrypto://': null,
	'opz-wallet://': null,
	'fw://': null,
	'com.kresus.superapp://': null,
	'midoin://': 'https://midoin.app.link',
	'oasys-wallet://': 'https://www.oasys-wallet.com/wc/',
	'gooddollar://': 'https://wallet.gooddollar.org/',
	'competenceid://': null,
	'spotonchain://': 'https://spotonchain.ai/',
	'dgg://': 'https://dggnetwork.app.link',
	'beanbag://wallet/connect': 'https://play.google.com/store/apps/details?id=com.beanbag.wallet',
	'gamic://': 'https://www.gamic.app/wc',
	'smartbody://?uri=': null,
	'network.gridlock.appgridlock://': 'https://gridlock.page.link/Fihx',
	'zeal://walletconnect/': null,
	'ivirse://': null,
	'tkwc://': 'https://trustkeys.network/',
	'concordiumwallet://': 'https://wallet.concordium.software',
	'ape://': 'https://zkape.io',
	'pitaka://': 'https://app.pitaka.io',
	'tw://': 'https://trusteeglobal.com/link/Pxxum8Yt',
	'rsswallet://rss.app.link': 'https://rss.app.link/rsswallet',
	'dropp://': null,
	'roam://': null,
	'wcqoin://': null,
	'meld://walletconnect': null,
	'bw://app/connect': 'https://best-wallet-prod.web.app/connect',
	'hyperpay://': null,
	'xucre.expo.client://': null,
	'herewallet://': 'https://my.herewallet',
	'cakewallet://': null,
	'unawallet://': null,
	'myapp://home': null,
	'pluswallet://': 'https://pluswallet.app',
	'atwallet://': null,
	'bifrostwallet://wallet-connect': null,
	'wemixwallet30://': null,
	'gem://': 'https://gemwallet.com',
	'caesium-app://walletconnect': null,
	'fintoken://': 'https://ios.fintoken.pro/app',
	'nodle://': null,
	'cryptokara://startscreen': 'https://play.google.com/store/apps/details?id=com.cryptokara&hl=en_US',
	'poolswallet://deeplink.poolsmobility.com': null,
	'veworld://org.vechain.veworld.app/': null,
	'azcoiner://': 'https://azcoiner.com/',
	'jbw://walletconnect': null,
	'uiwallet://?uri=': null,
	'm1nty://': null,
	'<root>://': null,
	'bitso://bitso.com/web3-wallet': 'https://bitso.com/web3-wallet',
	'puzzleapp://': 'https://jigsaw-dev.puzzle.online/',
	'blackfortwc://': null,
	'armanaportal://': 'https://portal.armana.io/wc?uri=',
	'bharatbox://': null,
	'kaikas://walletconnect': null,
	'blockaura://walletconnect': null,
	'x9wallet://a': null,
	'miraiapp://': 'https://go.miraiapp.io',
	'kigo-mobile-app://': 'https://kigo-digital.app.link/',
	'timeless-x://connect': 'https://timelesswallet.xyz/x',
	'getcogni://web3/': 'https://getcogni.com/web3_wc_connect',
	'fastex-wallet://': null,
	'wallacy://': null,
	'savl://': null,
	'sinum://': null,
	'soulwallet://': null,
	'panaromawallet://walletconnect': null,
	'nptwc://': null,
	'crypto.ab.wallet://': null,
	'hashpack://': 'https://link.hashpack.app',
	'wallet.pulse://': null,
	'pintu://web3wallet': null,
	'org.bladelabs.bladewallet://': null,
	'pandoshiwallet://': null,
	'pay.cool://deeplinkview': 'https://pay.cool/deepLinkView',
	'com.mpcvault.mobileapp://wccallback': 'https://ulink.mpcvault.com/wccallback',
	'legacy://network.io': null,
	'clave://link/wc/': 'https://getclave.io/link/wc',
	'iopay://': null,
	'mixin://': null,
	'bettatrade://': 'https://bettatrade.page.link/Fc4u',
	'krakenwallet://': null,
	'io.scramberry.v7bmfgda1vo42ue385xc://deeplink': 'https://link1.bsg.scramberry.io',
	'earthwallet://': 'https://wallet.earth',
	'nestwallet://': null,
	'echooo://echooo.valleysound.xyz/vss/walletconnect': 'https://api.valleysound.xyz/vss/*',
	'renegade-web3wallet://': null,
	'readywallet://': null,
	'hai://': 'https://hacken.ai',
	'plutope://': null,
	'trustassetapp://walletconnect': null,
	'dfinnwallet://': 'https://dfinnwallet-link.vercel.app/',
	'bmawalletapp://walletconnect': null,
	'spatium-next://': 'https://link.spatium.net',
	'transi://': null,
	'dolletwc://': null,
	'wombat-wc://': null,
	'dropmate://app': null,
	'dgpub://': 'https://dgpub.app.link',
	'icewal://': null,
	'metapro://': null,
	'bonuzapp://': null,
	'shido://': null,
	'w3akvrn:/': null,
	'clot://': null,
	'komet://': null,
	'guardiianwallet://': null,
	'nabox://': 'https://nabox.io/app',
};
