import init, {
	genInstanceId,
	dkgSetupMessage,
	eddsaDkgSetupMessage,
	verifyingKey,
	encPublicKey,
} from '@telifi/dkls-wasm';
import wasmUrl from '@telifi/dkls-wasm/dkls_wasm_bg.wasm?url';

export async function loadWasm() {
	await init(wasmUrl);
}
export { genInstanceId, dkgSetupMessage, eddsaDkgSetupMessage, verifyingKey, encPublicKey };
