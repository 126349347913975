import { Box, Button, Center } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { METAMASK_QR_CODE_REGEX } from '@/app-constants/regex';
import { NAVIGATE_PATHS } from '@/app-constants/router';
import { getBlockChainNameByAddress } from '@/app-helpers/address';
import { useBackButton, useContactStore } from '@/app-store';
import { useTonConnect } from '@/app-features/app-ton-connect/hooks/useConnect';
import { FiScanIcon } from '@/assets/icons';
import { useScanWC } from '@/app-features/app-wallet-connect/hooks';
import { ActionBlock } from '@/app-components/common/ActionBlock';
import { getTonConnectParams } from '@/app-features/app-wallet-connect/utils/HelperUtil';

export const QRScanner = () => {
	const { onScan, setOpen, setOnScan } = useScanWC();
	const { onConnect: onTonConnect } = useTonConnect();
	const { setSearchKey, setOpen: setOpenContactPopup } = useContactStore();
	const { setShow: setShowBackButton } = useBackButton();

	const navigate = useNavigate();
	const handleScan = (data: string) => {
		if (!data) return;
		const isMetamaskQrCode = METAMASK_QR_CODE_REGEX.test(data);
		const blockChainName = getBlockChainNameByAddress(data);

		if (isMetamaskQrCode || blockChainName) {
			const address = isMetamaskQrCode ? data.split(':')[1].split('@')[0] : data;
			setSearchKey(address);
			setOpenContactPopup(true);
			setOpen(false);
			setTimeout(() => {
				navigate(NAVIGATE_PATHS.Wallet.Sent.Sent);
			}, 100);
			return;
		}
		const { connectionUrl, isTonConnect } = getTonConnectParams(data);

		if ((data as string).startsWith('tc://') || isTonConnect) {
			onTonConnect(connectionUrl);
			return setOpen(false);
		}
		onScan(data);
	};

	return (
		<ActionBlock
			onClick={() => {
				setOpen(true);
				setOnScan(handleScan);
				setShowBackButton({
					isShow: true,
					backButtonCallback: () => setOpen(false),
					skipNavigation: true,
				});
			}}
		>
			<Center width={8} height={8}>
				<FiScanIcon />
			</Center>
		</ActionBlock>
	);
};
