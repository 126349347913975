import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { isEmpty } from 'lodash-es';
import { useQueryContactById } from '@/app-hooks/api/accouts';
import { useContactStore, useSentTokenStore } from '@/app-store';
import { calcAmount } from '@/app-hooks/swap/helper';
import { useSearchSingleToken } from '@/app-hooks/api/portfolio';
import { useTokenMaxBalancePortfolio } from '@/app-hooks/api/portfolio/usePortfolioBalance';

export const useInitSendData = () => {
	const { setAmount, setTokenSent, setUsdAmount, token } = useSentTokenStore();
	const { setContact } = useContactStore();
	const [searchParams] = useSearchParams();
	const {
		token: tokenAddress,
		chainId,
		amount,
		amountUsd,
		userTelegramId,
	} = Object.fromEntries(searchParams.entries());

	const tokenMaxBalance = useTokenMaxBalancePortfolio();

	const needFindToken = tokenAddress && chainId;
	const { data: contact } = useQueryContactById(userTelegramId);

	const { data } = useSearchSingleToken({ address: tokenAddress, chainId });
	const tokenInfo = needFindToken ? data : token || tokenMaxBalance;

	useEffect(() => {
		if (needFindToken && isEmpty(tokenInfo)) return;
		if (tokenInfo) {
			const data = calcAmount({
				amountUsd: amountUsd || undefined,
				amount: amount || undefined,
				token: tokenInfo,
			});
			setUsdAmount(data.amountUsd);
			setAmount(data.amount);
			setTokenSent(tokenInfo);
		} else {
			// set only amount usd
			amountUsd && setUsdAmount(amountUsd);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [tokenInfo, amountUsd, amount, needFindToken]);

	useEffect(() => {
		if (isEmpty(contact)) return;
		setContact(contact);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [contact]);
};
