import { useState } from 'react';
import { parseUri } from '@walletconnect/utils';
import { toast } from 'react-toastify';
import { web3wallet } from '../utils/WalletConnectUtil';
import { useModalStore } from '../store/ModalStore';
import SettingsStore from '../store/SettingsStore';
import { useGlobalToast } from '@/app-store';

export const useConnect = () => {
	const [isLoading, setIsLoading] = useState(false);
	const { setIsWaiting } = useModalStore();
	const { addToast } = useGlobalToast();
	async function onConnect(uri: string, isInternal?: boolean) {
		const { topic: pairingTopic, symKey, relay } = parseUri(uri);
		if (!symKey || !pairingTopic || !relay) {
			addToast({
				type: 'error',
				message: 'Your QR code or URI link is not valid',
			});
			return;
		}

		const pairingExpiredListener = ({ topic }: { topic: string }) => {
			if (pairingTopic === topic) {
				addToast({
					type: 'error',
					message: 'Pairing expired. Please try again with new Connection URI',
				});
				setIsWaiting(false);
				web3wallet.core.pairing.events.removeListener('pairing_expire', pairingExpiredListener);
			}
		};

		web3wallet.once('session_proposal', () => {
			web3wallet.core.pairing.events.removeListener('pairing_expire', pairingExpiredListener);
		});

		try {
			setIsLoading(true);
			web3wallet.core.pairing.events.on('pairing_expire', pairingExpiredListener);
			await web3wallet.pair({ uri });
			SettingsStore.setIsInternal(!!isInternal);
			setIsWaiting(true);
		} catch (error) {
			addToast({
				type: 'error',
				message: (error as Error).message,
			});
			throw error;
		} finally {
			setIsLoading(false);
		}
	}

	return { isLoading, onConnect };
};
