import {
	Button,
	Center,
	Drawer,
	DrawerBody,
	DrawerContent,
	DrawerFooter,
	DrawerHeader,
	DrawerOverlay,
	Text,
} from '@chakra-ui/react';
import { useModalStore } from '../store/ModalStore';

export const SessionUnSupportedMethodModal = () => {
	const { data, open, onClose } = useModalStore();

	return (
		<Drawer
			isOpen={true || open}
			placement="bottom"
			onClose={onClose}
			size="full"
			autoFocus={false}
			trapFocus={false}
		>
			<DrawerOverlay />
			<DrawerContent>
				<DrawerHeader borderBottomWidth="1px">
					<Center>
						<Text fontSize={'16px'} fontWeight={'500'}>
							UnSupported
						</Text>
					</Center>
				</DrawerHeader>

				<DrawerBody>
					<Center>Section UnSupported Method</Center>
				</DrawerBody>

				<DrawerFooter display="flex" flexDirection="column" gap={2}>
					<Button size="lg" fontWeight="medium" width="100%" onClick={onClose}>
						Close
					</Button>
				</DrawerFooter>
			</DrawerContent>
		</Drawer>
	);
};
