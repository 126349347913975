import { InputPassword, Toast } from '@/app-components/common';
import { NAVIGATE_PATHS } from '@/app-constants/router';
import { getRequireImportPreBackupKeyShare } from '@/app-cores/mpc-wallet/wallet/helper';
import { TelegramCore } from '@/app-cores/telegram';
import { getShortAddress } from '@/app-helpers/address';
import { createGoogleAuthUrl, storeNewUnsecuredKeyShare } from '@/app-helpers/backup';
import { parseErrorMessage } from '@/app-helpers/error-handling';
import { FiFileIcon, FiGoogleDriverIcon, FiUploadFileIcon } from '@/assets/icons';
import {
	AlertDialog,
	AlertDialogBody,
	AlertDialogContent,
	AlertDialogOverlay,
	Box,
	BoxProps,
	Center,
	Text,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { toast } from 'react-toastify';

const BoxWrapper: React.FC<
	BoxProps & {
		htmlFor?: string;
		isDisable?: boolean;
	}
> = ({ isDisable, ...rest }) => {
	return (
		<Box
			as="label"
			display="block"
			borderRadius={16}
			backgroundColor="white"
			px={6}
			py={2}
			border="1px solid rgba(0, 0, 0, 0.08)"
			cursor="pointer"
			width="100%"
			mt={4}
			pointerEvents={isDisable ? 'none' : 'auto'}
			opacity={isDisable ? 0.5 : 1}
			{...rest}
		/>
	);
};

interface ImportPreviousBackupFileProps {}

export const ImportPreviousBackupFile: React.FC<ImportPreviousBackupFileProps> = () => {
	const { t } = useTranslation();
	const [isOpen, setIsOpen] = useState(false);
	const [secretKey, setSecretKey] = useState('');
	const [file, setFile] = useState<File>(null);
	const [isLoading, setIsLoading] = useState(false);
	const navigate = useNavigate();
	const cancelRef = React.useRef();

	useEffect(() => {
		if (getRequireImportPreBackupKeyShare() === '1') {
			setIsOpen(true);
		}
	}, []);

	const handleUpload = async (e) => {
		if (!e.target?.files?.[0]) return;
		const file = e.target?.files?.[0];
		if (file?.type !== 'text/plain') {
			return toast(<Toast title="Error" message={t('toast.invalidFile')} type="error" />);
		}
		setFile(file);
		const reader = new FileReader();
		reader.onload = async function (event) {
			try {
				const keyShareEncryptedData = event.target.result as string;
				storeNewUnsecuredKeyShare(keyShareEncryptedData, secretKey);
				setIsOpen(false);
			} catch (error) {
				console.error('File imported invalid: ' + error);
				toast(
					<Toast type="error" title="Import Failed" message={'Your backup file or secret key incorrect'} />,
				);
			} finally {
				setIsLoading(false);
			}
		};
		setIsLoading(true);
		reader.readAsText(file);
	};

	const handleImportFromGG = async () => {
		try {
			const { url, state } = await createGoogleAuthUrl();
			if (!state) {
				throw new Error('State not found');
			}
			TelegramCore.openExternalURL(url);
			navigate(`${NAVIGATE_PATHS.Recovery.GDriveCallback}?state=${state}&importSecretKey=${secretKey}`);
		} catch (error) {
			toast(<Toast type="error" message={`Failed to process: ${parseErrorMessage(error)}`} />);
		}
	};
	const isDisable = !secretKey;
	return (
		<AlertDialog
			size="xs"
			isCentered
			isOpen={isOpen}
			leastDestructiveRef={cancelRef}
			onClose={() => setIsOpen(false)}
			autoFocus={false}
			trapFocus={false}
			closeOnEsc={false}
			closeOnOverlayClick={false}
		>
			<AlertDialogOverlay>
				<AlertDialogContent borderRadius="1.5rem">
					<AlertDialogBody py={8} px={8}>
						<Center flexDirection="column" textAlign="center">
							<Text fontSize="xs" mb={3}>
								Because you have just upgraded, we need you to upload the backup file before backing up
								again
							</Text>
							<input type="file" id="upload" accept=".txt" hidden onChange={handleUpload} />
							<Box textAlign="left" width="100%">
								<InputPassword
									fontSize="sm"
									label="Secret key"
									value={secretKey}
									onChange={(e) => setSecretKey(e.target.value)}
								/>
							</Box>
							<BoxWrapper onClick={handleImportFromGG} isDisable={isDisable}>
								<Center gap={4} justifyContent="start">
									<FiGoogleDriverIcon width={32} height={32} />
									<Box textAlign="left">
										<Text mb={2} fontWeight={500}>
											Google Drive
										</Text>
										<Text fontSize="xs" color="gray.400" lineHeight={4}>
											Load from Google Drive
										</Text>
									</Box>
								</Center>
							</BoxWrapper>
							<BoxWrapper htmlFor="upload" isDisable={isDisable}>
								<Center gap={4} justifyContent="start">
									{!file ? (
										<>
											<FiUploadFileIcon width={32} height={32} />
											<Box textAlign="left">
												<Text mb={2} fontWeight={500}>
													Upload
												</Text>
												<Text fontSize="xs" color="gray.400" lineHeight={4}>
													Upload your backup file
												</Text>
											</Box>
										</>
									) : (
										<Center height="3rem" fontSize="sm" justifyContent="start" gap={2}>
											<Box>
												<FiFileIcon width={32} height={32} fill="#00E9DB" />
											</Box>
											<Text>
												{getShortAddress(file.name, {
													start: 12,
													end: 10,
												})}
											</Text>
										</Center>
									)}
								</Center>
							</BoxWrapper>
						</Center>
					</AlertDialogBody>
				</AlertDialogContent>
			</AlertDialogOverlay>
		</AlertDialog>
	);
};
