import React from 'react';

interface IProps extends React.SVGAttributes<HTMLOrSVGElement> {}

export const FiChristmasStartsIcon: React.FC<IProps> = ({
	width = 20,
	height = 20,
	fill = 'currentColor',
	...rest
}) => {
	return (
		<svg width={width} height={height} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
			<g clipPath="url(#clip0_11455_21883)">
				<path
					d="M7.3494 0.155762C6.33465 5.66783 5.51202 6.49041 0 7.50517C5.51206 8.51992 6.33465 9.34251 7.3494 14.8546C8.36416 9.34251 9.18674 8.51992 14.6988 7.50517C9.18674 6.49041 8.36411 5.66783 7.3494 0.155762Z"
					fill="#00E9DB"
				/>
				<path
					d="M15.3263 10.4961C14.681 14.0015 14.1577 14.5247 10.6523 15.17C14.1577 15.8154 14.681 16.3386 15.3263 19.844C15.9716 16.3386 16.4948 15.8153 20.0002 15.17C16.4947 14.5247 15.9716 14.0015 15.3263 10.4961Z"
					fill="#00E9DB"
				/>
			</g>
			<defs>
				<clipPath id="clip0_11455_21883">
					<rect width="20" height="20" fill="white" />
				</clipPath>
			</defs>
		</svg>
	);
};
