import { useDisclosure, Tooltip as ChakraTooltip, TooltipProps as ChakraTooltipProps, Box } from '@chakra-ui/react';

interface TooltipProps extends ChakraTooltipProps {}
export const Tooltip: React.FC<TooltipProps> = ({ children, ...rest }) => {
	const { isOpen, onOpen, onToggle, onClose } = useDisclosure();

	return (
		<ChakraTooltip isOpen={isOpen} {...rest}>
			<Box as="span" cursor="pointer" onMouseEnter={onOpen} onMouseLeave={onClose} onClick={onToggle}>
				{children}
			</Box>
		</ChakraTooltip>
	);
};
