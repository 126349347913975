import { MpcWallet } from '@/app-cores/mpc-wallet/wallet';
import { useModalStore } from '@/app-features/app-wallet-connect/store/ModalStore';
import { useBackButton } from '@/app-store';
import { BackButton } from '@vkruglikov/react-telegram-web-app';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

interface PageWrapperProps {
	backable?: boolean;
	children?: React.ReactNode;
	onBack?: () => void;
}

const PageNavigation: React.FC<PageWrapperProps> = ({ children, onBack, backable = true }) => {
	const [showBackButton, setShowBackButton] = useState(false);
	const { open: isOpenWalletConnectModal, onClose } = useModalStore();
	const { reset, callBack, isShow, skipNavigation } = useBackButton();

	const [mainIdx, setMainIdx] = useState(0);
	const history = useLocation();
	const navigate = useNavigate();

	const forceBackButton = (!!onBack && typeof onBack === 'function') || isShow;
	const hasPreviousRoute = window.history?.state?.idx > mainIdx;

	const handleBack = () => {
		if (callBack) {
			callBack?.();
			reset();
			if (skipNavigation) return;
		}
		if (isOpenWalletConnectModal) return onClose();
		if (onBack) return onBack?.();
		if (!hasPreviousRoute) {
			return navigate('/');
		}
		navigate(-1);
	};

	useEffect(() => {
		// Clear history incase user back to home page
		if (history.pathname === '/' && history.state === '') {
			navigate('/', {
				replace: true,
			});
			setMainIdx(window.history.state.idx);
		}
	}, [history.pathname, history.state, navigate]);

	useEffect(() => {
		if (backable && hasPreviousRoute) {
			setShowBackButton(true);
		} else {
			setShowBackButton(false);
		}
	}, [backable, hasPreviousRoute]);

	return (
		<>
			{(showBackButton && hasPreviousRoute) || forceBackButton ? <BackButton onClick={handleBack} /> : null}
			{children}
		</>
	);
};

export default PageNavigation;
