import { AppLogoIconCircle } from '@/assets/images/svg';
import { CheckCircleIcon } from '@/assets/images/svg/check-circle-icon';
import {
	Avatar,
	Box,
	Button,
	Center,
	Drawer,
	DrawerBody,
	DrawerContent,
	DrawerFooter,
	DrawerHeader,
	DrawerOverlay,
	List,
	ListItem,
	Spinner,
	Text,
} from '@chakra-ui/react';
import { useProposalAction } from '../hooks';
import { useModalStore } from '../store/ModalStore';
import { botConnectorHandler } from '../utils/bot-connector.handler';

export default function SessionProposalModal() {
	const { data, open, onClose } = useModalStore();
	const { onApprove, isPendingApprove, isPendingReject, onReject } = useProposalAction(data);

	const metadata = botConnectorHandler.getClient(data.clientId)?.metadata;

	return (
		<Drawer isOpen={open} placement="bottom" onClose={onClose} size="full" trapFocus={false}>
			<DrawerOverlay />
			<DrawerContent>
				<DrawerHeader>
					<Center gap={2}>
						<AppLogoIconCircle />
						<Avatar src={metadata.icons?.[0]} width={16} height={16} name={metadata.name} />
					</Center>
					<Text textAlign="center" mt={4}>
						Connect with {metadata.name}
					</Text>
					<Text
						mt={2}
						textAlign="center"
						as="a"
						display="block"
						fontSize="xs"
						href={metadata.url}
						target="_blank"
						color="gray"
					>
						{metadata.name}
					</Text>
				</DrawerHeader>

				<DrawerBody>
					<Box>
						<Text fontSize="xs" fontWeight={500}>
							PERMISSION REQUEST
						</Text>
						<Text py={5} fontSize="sm">
							By connecting, you're allowing the site to perform these actions:
						</Text>
						<hr />
					</Box>
					<Box pt={5}>
						<List>
							<ListItem display="flex" alignItems="center" gap={2}>
								<CheckCircleIcon className="me-1" />
								<Text fontSize="sm">View your balance & activities</Text>
							</ListItem>
							<ListItem display="flex" alignItems="center" gap={2} mt={3}>
								<CheckCircleIcon className="me-1" />
								<Text fontSize="sm">Send approval requests</Text>
							</ListItem>
						</List>
					</Box>
				</DrawerBody>

				<DrawerFooter display="flex" flexDirection="column" gap={2}>
					<Button
						isDisabled={isPendingApprove || isPendingReject}
						size="lg"
						fontWeight="medium"
						width="100%"
						onClick={onReject}
					>
						{isPendingReject ? <Spinner size="sm" /> : 'Deny'}
					</Button>
					<Button
						isDisabled={isPendingApprove || isPendingReject}
						size="lg"
						fontWeight="medium"
						colorScheme="cyan"
						width="100%"
						onClick={onApprove}
					>
						{isPendingApprove ? <Spinner size="sm" color="white" /> : 'Approve'}
					</Button>
				</DrawerFooter>
			</DrawerContent>
		</Drawer>
	);
}
