import { CardSelection } from '@/app-components/common';
import { FiWalletCircleIcon } from '@/assets/icons';
import { Box, Text } from '@chakra-ui/react';
import { useDepositStore } from '../store';
import { WalletProvider } from './wallet-provider';
import { DepositResult } from '../components/DepositResult';

export const ConnectWallet = () => {
	const { isOpenWalletProvider, onToggleWalletProvider, isOpenDepositResult } = useDepositStore();

	return (
		<>
			<CardSelection
				icon={<FiWalletCircleIcon />}
				cursor="pointer"
				leftContent={
					<Box>
						<Text fontSize="sm" fontWeight={600}>
							Connect Wallet
						</Text>
						<Text fontSize="xs" fontWeight={400} color="gray.400">
							WC, Phantom, TON Connect
						</Text>
					</Box>
				}
				onClick={() => onToggleWalletProvider(true)}
			/>
			{isOpenWalletProvider && <WalletProvider />}
			{isOpenDepositResult && <DepositResult />}
		</>
	);
};
