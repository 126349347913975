import axios, { AxiosError } from 'axios';
import { AuthClient } from '@/app-cores/auth';
import { handleErrorWithRefreshToken } from '@/app-cores/api/axios';

const BOT_CONNECTOR_SERVICE_API = import.meta.env.VITE_BOT_CONNECTOR_SERVICE_API;

export const axiosBotConnectorServiceApi = axios.create({
	baseURL: BOT_CONNECTOR_SERVICE_API,
	timeout: 20000,
});

// Attach access token on each request
axiosBotConnectorServiceApi.interceptors.request.use(async (config) => {
	config.headers.Authorization = await AuthClient.authHeader();
	return config;
}, null);

axiosBotConnectorServiceApi.interceptors.response.use(
	(response) => response,
	(error: AxiosError) => handleErrorWithRefreshToken(axiosBotConnectorServiceApi, error),
);
