import { Box, Flex } from '@chakra-ui/react';
import { ReactNode } from 'react';

export const TransactionItemInfo = ({ title, value }: { title: ReactNode; value: ReactNode }) => {
	return (
		<Flex
			justifyContent={'space-between'}
			gap={4}
			py={4}
			borderBottom="1px dashed rgba(0, 0, 0, 0.16)"
			// _last={{
			// 	border: 'none',
			// }}
		>
			<Box fontSize="xs" fontWeight={500}>
				{title}
			</Box>
			<Box textAlign={'right'}>
				<Box fontSize="xs" wordBreak="break-all" fontWeight={500}>
					{value}
				</Box>
			</Box>
		</Flex>
	);
};
