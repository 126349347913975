import { create } from 'zustand';
import { ChainId } from '../../../../app-constants/chains';

interface IPortfolioState {
	selectedChain: ChainId | '';
	setSelectedChain: (chainId: ChainId | '') => void;
	refreshingBalance: boolean;
	isPullToRefresh: boolean;
	setRefreshingBalance: (v: boolean, isPullToRefresh?: boolean) => void;
}

export const usePortfolioState = create<IPortfolioState>((set) => ({
	selectedChain: '',
	refreshingBalance: false,
	isPullToRefresh: false,
	setSelectedChain: (chainId: ChainId | '') => {
		return set(() => ({ selectedChain: chainId }));
	},
	setRefreshingBalance: (refreshingBalance: boolean, isPullToRefresh: boolean = false) => {
		return set(() => ({ refreshingBalance, isPullToRefresh: isPullToRefresh }));
	},
}));
