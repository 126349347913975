import { UseQueryOptions, useQuery } from '@tanstack/react-query';
import { ContactAccount, ContactManagementAPI } from '@/app-cores/api/contact';

export const useQueryContactByWalletAddress = (address: string, options?: UseQueryOptions<ContactAccount>) => {
	const response = useQuery({
		queryKey: ['query-contact-detail-by-address', address],
		queryFn: async () => {
			return ContactManagementAPI.getContactByWalletAddress(address);
		},

		//cache 10mins
		staleTime: 60 * 1000 * 60 * 24,
		enabled: !!address,
		...options,
	});

	return response;
};
