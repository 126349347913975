import { Container, Flex } from '@chakra-ui/react';
import { Portfolio } from './containers/Portfolio';
import { Rewards } from './containers/Rewards';
import { Swap } from './containers/Swap';
import { TrendingTabs } from './containers/Trending';
import PageNavigation from '@/app-components/layout/PageWrapper/PageWrapper';

export const HomePage = () => {
	return (
		<PageNavigation backable={false}>
			<Container className="overflow-y-auto hide-scrollbar" pb={20}>
				<Flex flexDirection="column" gap={3} mt={3}>
					<Flex gap={3}>
						<Portfolio />
						<Rewards />
					</Flex>
					<Swap />
					<TrendingTabs />
				</Flex>
			</Container>
		</PageNavigation>
	);
};
