import { Container } from '@chakra-ui/react';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Loading } from '@/app-components/common';
import PageTransition from '@/app-components/layout/PageTransition/PageTransition';
import PageNavigation from '@/app-components/layout/PageWrapper/PageWrapper';
import { CHAIN_CONFIG_MAP, ChainId, EVM_CHAINS } from '@/app-constants/chains';
import { DepositResult } from './DepositResult';
import { getMyWalletAddressByChain } from '@/app-hooks/swap/helper';
import { NAVIGATE_PATHS } from '@/app-constants/router';
import { useDepositStore } from '@/app-features/deposit/store';
import { DATADOG_ACTIONS, DATADOG_ERROR_TAGS, dataDogAddAction, dataDogAddError } from '@/app-services/monitor/datadog';
const { VITE_TRANSAK_URL, VITE_TRANSAK_API_KEY } = import.meta.env;

const TRANSAK_STATUS = {
	SUCCESSFUL: 'TRANSAK_ORDER_SUCCESSFUL',
	FAILED: 'TRANSAK_ORDER_FAILED',
};

function getNetworks(chainId: ChainId) {
	if (chainId === ChainId.TON) return CHAIN_CONFIG_MAP[ChainId.TON].transakNetworkName;
	if (chainId === ChainId.SOL) return CHAIN_CONFIG_MAP[ChainId.SOL].transakNetworkName;
	if (chainId === ChainId.TRON) return CHAIN_CONFIG_MAP[ChainId.TRON].transakNetworkName;

	return EVM_CHAINS.map((chain) => {
		return chain.transakNetworkName;
	}).join(',');
}
const Transak: React.FC = () => {
	const [placeHolderVisible, setPlaceHolderVisible] = useState(true);
	const navigate = useNavigate();
	const iframeRef = useRef<HTMLIFrameElement>(null);
	const [searchParams] = useSearchParams();
	const { onOpen: onOpenDeposit } = useDepositStore();
	const chainId = searchParams.get('chainId');
	const walletAddress = getMyWalletAddressByChain(chainId);
	const network = CHAIN_CONFIG_MAP[chainId]?.transakNetworkName;
	const tokenSymbol = searchParams.get('tokenSymbol');
	const callbackUrl = searchParams.get('callbackUrl');
	const [isShowResult, setIsShowResult] = useState(false);
	const [transactions, setTransactions] = useState(null);
	const transakParams = useMemo(() => {
		const searchParams = new URLSearchParams({
			productsAvailed: 'BUY',
			defaultFiatCurrency: 'USD',
			hideMenu: 'true',
			apiKey: VITE_TRANSAK_API_KEY,
			defaultNetwork: network ? network : 'base',
			networks: getNetworks(chainId as ChainId),
			defaultCryptoCurrency: tokenSymbol ? tokenSymbol : 'USDC',
			walletAddress: walletAddress,
			disableWalletAddressForm: 'true',
			colorMode: 'LIGHT',
		});
		return searchParams.toString();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		const iframe = iframeRef.current;
		if (!iframe) return;
		const iframeLoadedHandler = () => {
			setPlaceHolderVisible(false);
		};
		iframe?.addEventListener('load', iframeLoadedHandler);
		return () => {
			iframe?.removeEventListener('load', iframeLoadedHandler);
		};
	}, []);

	useEffect(() => {
		if (!iframeRef.current) return;
		const messageHandler = (message) => {
			if (message.source !== iframeRef.current?.contentWindow) return;
			if (message?.data?.event_id === TRANSAK_STATUS.SUCCESSFUL && message?.data?.data?.status === 'COMPLETED') {
				setIsShowResult(true);
				setTransactions(message?.data?.data);
				dataDogAddAction(DATADOG_ACTIONS.DEPOSIT_BY_TRANSAK_RESULT.SUCCESS);
			}
			if (message?.data?.event_id === TRANSAK_STATUS.FAILED) {
				dataDogAddAction(DATADOG_ACTIONS.DEPOSIT_BY_TRANSAK_RESULT.FAILED);
				dataDogAddError(message?.data?.data, {
					tags: {
						name: DATADOG_ERROR_TAGS.DEPOSIT,
						function: 'transak',
					},
				});
			}
		};
		window.addEventListener('message', messageHandler);
		return () => {
			window.removeEventListener('message', messageHandler);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	return (
		<PageTransition>
			<PageNavigation
				onBack={() => {
					dataDogAddAction(DATADOG_ACTIONS.DEPOSIT_BY_TRANSAK_RESULT.BACK);
					history.replaceState(null, null, '/');
					if (callbackUrl === NAVIGATE_PATHS.Home) {
						onOpenDeposit();
					}
					navigate(callbackUrl);
				}}
			>
				<Container height="100vh" px={0}>
					{placeHolderVisible && (
						<div className="vw-100 vh-100">
							<Loading />
						</div>
					)}
					<iframe
						id="transakIframe"
						ref={iframeRef}
						src={`${VITE_TRANSAK_URL}/?${transakParams}`}
						allow="camera;microphone;payment nested-history 'none'"
						style={{
							width: '100%',
							height: '100%',
						}}
						title="transak"
					></iframe>
				</Container>
				<DepositResult
					callbackUrl={callbackUrl}
					isOpen={isShowResult}
					onClose={() => setIsShowResult(false)}
					data={transactions}
				/>
			</PageNavigation>
		</PageTransition>
	);
};

export default Transak;
