import { Header } from '@/app-components/common';
import PageTransition from '@/app-components/layout/PageTransition/PageTransition';
import PageNavigation from '@/app-components/layout/PageWrapper/PageWrapper';
import { Box, Button, Image, Container, Heading, Text } from '@chakra-ui/react';
import { FiRocketIcon } from '../../assets/icons/fi-rocket-icon';
import { useNavigate } from 'react-router-dom';

export const CommingSoon = () => {
	const navigate = useNavigate();

	return (
		<PageTransition>
			<PageNavigation>
				<Container className="pageContent">
					<Box className="pageFixed" py={4}>
						<Header>Tobi</Header>
						<Box
							display={'flex'}
							flexDirection="column"
							alignItems={'center'}
							justifyContent={'center'}
							flexGrow={1}
							pb={5}
							px={6}
						>
							<FiRocketIcon />
							<Heading mb={5}>Launching Soon!</Heading>
							<Text textAlign={'center'}>
								Get ready to invite and connect! Our Invitation Code feature is coming soon. Stay tuned
								for updates 🎉
							</Text>
						</Box>
						<Box px={4}>
							<Button
								onClick={() => navigate(-1)}
								borderRadius={'1rem'}
								size="lg"
								fontWeight="medium"
								width="100%"
							>
								Explore Now
							</Button>
						</Box>
					</Box>
				</Container>
			</PageNavigation>
		</PageTransition>
	);
};
