import { Box, Button, Center, Text } from '@chakra-ui/react';
import TokenLogo from '@/app-components/common/Avatar/TokenLogo';
import { ITransactionStatus } from '@/app-types';
import { useSentTokenStore } from '@/app-store';
import { formatCurrency, formatUsd } from '@/app-helpers/number';

interface SentResultProps {
	status: ITransactionStatus;
}

export const SentResult: React.FC<SentResultProps> = ({ status }) => {
	const { amount, usdAmount, tokenInfo } = useSentTokenStore();
	const { imageUrl, symbol } = tokenInfo ?? {};

	return (
		<Center flexDirection="column" width="100%" gap={4}>
			<TokenLogo logo={imageUrl} size={56} symbol={symbol} status={status} />
			<Box textAlign="center">
				<Text fontSize={28} fontWeight={700}>
					-{formatCurrency(amount)} {symbol}
				</Text>
				<Text fontSize="sm" color="gray.400">
					{formatUsd(usdAmount)}
				</Text>
			</Box>
		</Center>
	);
};
