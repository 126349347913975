import React from 'react';
import TgMainButton from '../TgMainButton';
import { useAppExpansion } from '@/app-hooks/common/useAppExpansion';
import { noop } from 'lodash-es';
import { Button, ButtonProps as ChakraButtonProps } from '@chakra-ui/react';
import { BASE_BORDER_COLOR } from '@/app-theme/theme';

interface ButtonProps extends ChakraButtonProps {
	children?: string | React.ReactNode;
	text?: string;
	loading?: boolean;
	secondary?: boolean;
	onClick?: (e?: any) => void;
}

export const CTAButton: React.FC<ButtonProps> = (props) => {
	const [isExpanded] = useAppExpansion();

	const renderMainButton = () => {
		return <Button {...props} />;
	};

	return (
		<>
			{!isExpanded ? (
				<TgMainButton
					text={
						Array.isArray(props.children) && props.children.every((e) => typeof e === 'string')
							? props.children.join('')
							: typeof props.children === 'string'
							? props.children
							: props.text
					}
					onClick={props.disabled ? noop : props.onClick}
					progress={props.loading}
					bgColor={props.secondary ? '#00E9DB' : undefined}
					disabled={props.isDisabled}
				/>
			) : null}
			{!isExpanded ? <div style={{ display: 'none' }}>{renderMainButton()}</div> : renderMainButton()}
		</>
	);
};

export const ButtonPrimary = ({ disabled, ...props }: ButtonProps) => {
	return (
		<Button
			{...props}
			colorScheme={disabled ? 'gray' : 'cyan'}
			pointerEvents={disabled ? 'none' : 'unset'}
			color={disabled ? 'gray.300' : undefined}
			borderRadius={'100px'}
		/>
	);
};

export const ButtonSecondary = ({ disabled, ...props }: ButtonProps) => {
	return (
		<Button
			borderRadius={'100px'}
			{...props}
			colorScheme={disabled ? 'gray' : '#fff'}
			pointerEvents={disabled ? 'none' : 'unset'}
			border={`1px solid ${BASE_BORDER_COLOR}`}
			color={'black'}
			variant={'outline'}
		/>
	);
};
