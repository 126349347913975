import { ServiceAuth } from '@/app-cores/api';
import { TelegramCore } from '@/app-cores/telegram';
import { parseErrorMessage } from '@/app-helpers/error-handling';
import { Mutex } from 'async-mutex';
import { AuthTokenManager } from './auth-token-manager';
import { IUser, IdPayload } from './types';

class AuthClient {
	#user: IUser;

	#tokenManager: AuthTokenManager;

	#refreshMutex: Mutex;

	get tokenManager() {
		return this.#tokenManager;
	}

	get user() {
		return this.#user;
	}

	async init() {
		this.#tokenManager = new AuthTokenManager();
		this.#refreshMutex = new Mutex();

		await this.#tokenManager.init();
		this.setUserFromJwtPayload();
	}
	async authHeader(): Promise<string> {
		return `Bearer ${this.#tokenManager.accessToken}`;
		// return 'Bearer eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJqdGkiOiI2NWQyZDI5MTJhM2VjOTY5ZGE3NjRmMjUiLCJleHAiOjE5MjYwOTEyNjAsInN1YiI6IjY1YTAxN2NlOGJlMGMzOTdhNzEzZTQ1NSIsInVzZXIiOnsiaWQiOiI2NWEwMTdjZThiZTBjMzk3YTcxM2U0NTUiLCJ0ZWxlZ3JhbV9pZCI6MTA1MzQyNTAzMCwidXNlcm5hbWUiOiJEdW5nTFE5NCIsImdpdmVuX25hbWUiOiJEdW5nIExRIiwiZmFtaWx5X25hbWUiOiIiLCJ3YWxsZXQiOiIweEJGZjc0OTFjNzhBNzdmMzI0NjJGNjRhNzRCMjREQmNhNGFhNkFkZDQiLCJ3YWxsZXRfYWRkcmVzc2VzIjpbIjB4NEI0ZDY3MDExZDQxY0U3MDI3RTQ1NzdDNzAyOTc5QkJFRWNhOTg2MyIsIjB4QkZmNzQ5MWM3OEE3N2YzMjQ2MkY2NGE3NEIyNERCY2E0YWE2QWRkNCJdfSwiaXNzIjoiMC4wLjAuMCIsImlhdCI6MTcwODMxNTI4MX0.RwS0nUeSedAM2EE5VEwmAXrRcrl8un5RlA6eukNKMSOoq7b2Bkrw2SWZ5FjgbK0mK06ZnaWCHwa9al4rtSGG1tk5fUKWNDwxtaJOjt6sZcdQKidf3mcWxiT8KjlK-hi5kzWwPgIQvisH32lh3sH7KG8a5kUMY1jyXy5Ah5VP50yZKdf67vSNIyRcItHWRP1BGB6VPennk45kX12rQtbw6CZnzJUvzgR56SEObY7qvcHkZvcsHcHm7wgBqix8v24lT7kZxAt0rwCI6_NZJ6jxOOrKhh2AXJFmYsyA6gN4tTCzn2SqvVeNGxS7Wye1ZSDRLA9yad1q6SGj_GXWsqqjdihZuhwHtjIb91NVJsrivmVqsy6CHyf1Q-CYbOPSnnr5MD9RwIwOmNZDBNGsTuYdLHV1fmK-T_5cMIuFpqGdj0g8r8lkAdanMVrbCLUatCAMFKtVhIw5cNU4lQNs0qvb7lJJ1VRaSNzoyJ3XWT02uIl9ece30qTtDL2pMZcVBrM-C7dvVxNagj5-1XFY-HE8lzqTIs0K1siYRxpHZzW7vEnYgqarPLbckbAX5zwu27Y0gv_N8xCiL_S4gwMB1UjO4K3-be4M4vW2QpC7X1F4xK1yEQkr7QcjyNHT0xhzo5k5pKZ9GNHd8TEmykhVmbwtJZ9t_z6UALHUTqrxw9t0y78';
		// return 'Bearer eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJqdGkiOiI2NWUxNTlmMDJkZTAxYjgxNGFmMjViN2IiLCJleHAiOjIxMTcwNDM0MDAsInN1YiI6IjY1ZTE1OWEzMGI1YmNlMjA5OGM0OWNkZiIsInVzZXIiOnsiaWQiOiI2NWUxNTlhMzBiNWJjZTIwOThjNDljZGYiLCJ0ZWxlZ3JhbV9pZCI6MTE2MDU2MTIwNiwidXNlcm5hbWUiOiJhbmh2aWV0bmd1eWVubnZhIiwiZ2l2ZW5fbmFtZSI6IkFuaCIsImZhbWlseV9uYW1lIjoiTmd1eeG7hW4gVmnhu4d0Iiwid2FsbGV0IjoiMHgxMUEzNTczQjA5NTljZEU2QjlEZjREODA2NzE4Nzk0NGQwMDA0NThmIiwid2FsbGV0X2FkZHJlc3NlcyI6WyIweGFEMEMzMTVjQzU0RTI3ZjA0OWRFQTgyNTIzMTRBYWFhRTY0N0Q0NTMiLCIweDlhMjhmMWM0YzdDNTc4OTJENWZlZDM5RDA0RERkNzQyYmExNDM1NmYiXX0sImlzcyI6IjAuMC4wLjAiLCJpYXQiOjE3MDkyNjc0NDB9.ghLmGwkS_v77-q5mGMIZR9notyUrhKYEEc_W6_S72cHBUH_POO18FUHpM5al0tu09lmczClARY6jQoNMwWPi1gmCeCI4yNa4z-s6t9tJEYA5zc8SaA52UgC-Qw_JIVxpJEsPirYMd0P5JCg0pIYL89qN10cF7Ki7fNewNMkSPO8ZYO9VJKuzEaaiYTnvFHRedfd4Uy9J53WWsvzBq_Nj8NamItJ1v6f93uZl-CNMmI9zm301oOFpDuhswhlO94h7cYNwKLAAJv9kJysTqjsu4980Zj1a-_q75siOUS1U5DKRhJ7JE4BxrZ35a20aka3C4d3WyJWfWdhf_9d_9skrUP7q3JGIaZ0xlYoybuew-uYewiM8xtgp5Bz-9Zbw0R30tiTN7s3AkX0UlxnLdKORARUmovyzAUaQCBZYMQS_p3VEmnH5ES2HplWNkyiVVLjExfeAcbv8HM4QVBSzsSMFYdKcVpiBnmM-svfZCM2dzGCmcDkd6W0ws03YZg1AfmyyxPYGBENVpzrvWj0inSLOD9MoVPCCJqZaCe8deBGJ63jhudFXW6ALaxZIlb5AcOChukj4GFDllqI79D9IcTgkUKMaEvtooxc9_Yx-L7y-oIiuANEyyqDFhP5w9aAVkSULt-65jLT6GJ6HLyaIEYKZdD91H_v7W6K30KClj_NDlf8';
	}

	async verifyAndRefreshToken() {
		const release = await this.#refreshMutex.acquire();
		try {
			return await this.authenticate();
		} catch (e) {
			console.error('Error while refresh token: ' + parseErrorMessage(e));
			throw e;
		} finally {
			release();
		}
	}

	async authenticate() {
		const initData = TelegramCore.getInitData();

		if (!initData) {
			throw new Error('Missing telegram initData');
		}

		const tokens = await ServiceAuth.authenticate();
		await this.#tokenManager.setTokens(tokens);
		this.setUserFromJwtPayload();
	}

	private setUserFromJwtPayload() {
		const jwtPayload = this.#tokenManager.payload as IdPayload;
		if (jwtPayload) {
			this.#user = {
				id: jwtPayload.sub,
				telegramId: TelegramCore.getUserId(),
				familyName: jwtPayload.user.family_name,
				givenName: jwtPayload.user.given_name,
				username: jwtPayload.user.username,
				wallet: jwtPayload.user.wallet,
			};
		}
	}
}

export type IAuthClient = typeof AuthClient;

export default new AuthClient();
