import { Card, CardBody, CardHeader, Text } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

interface ConnectInstructionProps {
	title: React.ReactNode;
}

export const ConnectInstruction: React.FC<React.PropsWithChildren<ConnectInstructionProps>> = ({ title }) => {
	const { t } = useTranslation();
	return (
		<>
			<Text fontSize="sm" mb={4}>
				{title}
			</Text>
			<hr />
			<Text fontSize="sm" mt={4}>
				<strong>{t('walletConnect.instruction.step1')}</strong>{' '}
				{t('walletConnect.instruction.step1Description')}
			</Text>
			<Text fontSize="sm" mt={4}>
				<strong>{t('walletConnect.instruction.step2')}</strong>{' '}
				{t('walletConnect.instruction.step2Description')}
			</Text>
			<Text fontSize="sm" my={4}>
				<strong>{t('walletConnect.instruction.step3')}</strong>{' '}
				{t('walletConnect.instruction.step3Description')}
			</Text>
		</>
	);
};
