import { BASE_BORDER_COLOR } from '@/app-theme/theme';
import { Box, Flex, Image, Text } from '@chakra-ui/react';
import { AppLogoIconCircle, ChevronRightIcon, ChevronUpRightIcon } from '@/assets/images/svg';

export type IDAppInfo = { logo: string; domain: string };

export const DappInfo = ({ dapp }: { dapp: IDAppInfo }) => {
	return (
		<Flex
			alignItems={'center'}
			gap={'6px'}
			border={`1px solid ${BASE_BORDER_COLOR}`}
			borderRadius={'50px'}
			padding={'4px 6px'}
			width={'fit-content'}
		>
			<Flex position={'relative'} width={'60px'}>
				<Box
					sx={{ border: `1px solid ${BASE_BORDER_COLOR}`, padding: '4px' }}
					borderColor={'gray.100'}
					height={'32px'}
					width={'32px'}
					borderRadius={'100%'}
				>
					<Image src={dapp?.logo} height={'100%'} width={'100%'} />
				</Box>
				<AppLogoIconCircle height={32} style={{ position: 'absolute', right: '-16px' }} />
			</Flex>
			<Text fontSize={'14px'} color={'gray'}>
				{dapp?.domain}
			</Text>
		</Flex>
	);
};
