import { Block, CTAButton, TextSmall, Toast, TooltipInfo } from '@/app-components/common';

import { Avatar, Box, Button, Card, Center, Flex, Image, Spinner, Text } from '@chakra-ui/react';
import { formatCurrency, formatNumber, formatUnits, formatUsd } from '@/app-helpers/number';
import { useNavigate } from 'react-router';
import { NAVIGATE_PATHS } from '@/app-constants/router';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import TokenLogo from '@/app-components/common/Avatar/TokenLogo';
import AppLayout from '@/app-components/layout/PageLayout';
import { parseErrorMessage } from '@/app-helpers/error-handling';
import { useGetAllPriceSwap } from '@/app-hooks/swap';
import { TransactionType } from '@/app-types';
import ProviderNote from '@/app-views/swap/components/ProviderNote';
import { useExecuteRoute } from '@/app-hooks/swap';
import { ArrowUpIcon } from '@/assets/images/svg';
import { InputMode, SelectedRoute, useSwapStore } from '@/app-store/swap';
import PageTransition from '@/app-components/layout/PageTransition/PageTransition';
import Warning from '@/app-components/common/Warning';
import { BASE_BORDER_COLOR, colors } from '@/app-theme/theme';

import { DATADOG_ACTIONS, DATADOG_ERROR_TAGS, dataDogAddAction, dataDogAddError } from '@/app-services/monitor/datadog';

import { TxStatus } from '@/app-cores/api/activities';
import { InfoItem, TokenRow } from '@/app-views/swap/pages/ConfirmTransaction';
import { OrderMode, useLimitStore } from '@/app-store/swap/limit';
import {
	calculateRatePercent,
	formatDisplayExpiredTime,
	getLimitType,
	getMarketWarningMsg,
} from '@/app-hooks/limit/helper';
import { invalidateLimitOrder, useMutationCreateOrder } from '@/app-hooks/limit';
import { useTranslation } from 'react-i18next';
import { getTokenInfo } from '@/app-helpers/token';

export const ConfirmTransactionLimit = () => {
	const {
		orderMode,
		rate,
		expiredAt,
		inputMode,
		amountInUsd: rawAmountInUsd,
		amountOutUsd: rawAmountOutUsd,
		...state
	} = useLimitStore();

	const isSellMode = orderMode === OrderMode.SELL;

	const tokenIn = isSellMode ? state.tokenIn : state.tokenOut;
	const tokenOut = isSellMode ? state.tokenOut : state.tokenIn;
	const tokenInfoIn = isSellMode ? state.tokenInfoIn : state.tokenInfoOut;
	const tokenInfoOut = isSellMode ? state.tokenInfoOut : state.tokenInfoIn;

	const amountIn = isSellMode ? state.amountIn : state.amountOut;
	const amountOut = isSellMode ? state.amountOut : state.amountIn;

	const swapType = getLimitType(tokenInfoIn, tokenInfoOut);

	const { usdPriceTokenIn, usdPriceTokenOut } = useGetAllPriceSwap({
		tokenIn: tokenInfoIn,
		tokenOut: tokenInfoOut,
	});

	const isTokenMode = inputMode === InputMode.AMOUNT;
	const amountInUsd = isTokenMode ? +amountIn * usdPriceTokenIn : rawAmountInUsd;
	const amountOutUsd = isTokenMode ? +amountOut * usdPriceTokenOut : rawAmountOutUsd;

	const marketRate = isSellMode ? usdPriceTokenIn / usdPriceTokenOut : usdPriceTokenOut / usdPriceTokenIn;

	const [isPending, setIsPending] = useState(false);

	const { t } = useTranslation();
	const navigate = useNavigate();

	useEffect(() => {
		if (!tokenIn && !tokenOut) navigate(NAVIGATE_PATHS.Limit.Main, { replace: true });
	}, [navigate, tokenOut, tokenIn]);

	const { mutateAsync } = useMutationCreateOrder();
	const handleConfirmTransaction = async () => {
		if (isPending) return;
		dataDogAddAction(DATADOG_ACTIONS.TRADE_CONFIRM_TRANSACTION);

		try {
			setIsPending(true);
			const data = await mutateAsync({
				tokenIn: tokenInfoIn,
				tokenOut: tokenInfoOut,
				amountIn,
				amountOut,
				expiredAt,
				callback: (status) => {
					if (status === TxStatus.Failed) {
						toast(
							<Toast
								type="error"
								title={t('limit.createOrderFailed', payload)}
								message={t('limit.createOrderFailedDesc', payload)}
							/>,
						);
					} else {
						invalidateLimitOrder();
					}
				},
			});
			navigate(`${NAVIGATE_PATHS.Limit.LimitHistory}?chainId=${tokenInfoIn?.chainId}`, { replace: true });
			const payload = {
				amountIn: formatCurrency(amountIn),
				amountOut: formatCurrency(amountOut),
				tokenIn: tokenInfoIn?.symbol,
				tokenOut: tokenInfoOut?.symbol,
				rate: formatCurrency(rate),
				action: orderMode,
			};
			toast(
				<Toast
					type="success"
					title={t('limit.orderSubmitted', payload)}
					message={t('limit.orderSubmittedDesc', payload)}
				/>,
			);
		} catch (e) {
			dataDogAddError(e, {
				tags: {
					name: DATADOG_ERROR_TAGS.TRADE,
					function: 'confirmLimitTransaction',
				},
			});
			dataDogAddAction(DATADOG_ACTIONS.TRADE_LIMIT_SIGN_AND_BROADCAST_ERROR);
			console.error(e);
			toast(<Toast type="error" message={`Failed to create order: ${parseErrorMessage(e)}`} />, {
				autoClose: 8_000,
			});
		} finally {
			setIsPending(false);
		}
	};

	const rateWarning = getMarketWarningMsg({
		orderMode,
		tokenIn: tokenInfoIn,
		tokenOut: tokenInfoOut,
		rate,
		marketRate,
	});
	const renderTradeInfo = () => {
		return (
			<Card
				flexDirection={'column'}
				background={'white'}
				gap={'10px'}
				borderRadius={'12px'}
				padding={'12px 16px'}
			>
				<InfoItem
					title={`${orderMode} ${getTokenInfo(state?.tokenIn)?.symbol} at price`}
					info1={
						<Center gap={1}>
							<Text fontSize="sm">
								{formatNumber(rate)} {getTokenInfo(state.tokenOut)?.symbol} (
								{calculateRatePercent(marketRate, rate, true)}% Market)
							</Text>
						</Center>
					}
				/>

				<InfoItem
					title="Market Price"
					info1={
						<Center gap={1}>
							<Text fontSize="sm">
								{formatNumber(marketRate)} {tokenInfoOut?.symbol}
							</Text>
						</Center>
					}
				/>

				<InfoItem title="Provider" info1={<ProviderNote provider={swapType} />} />

				<InfoItem
					title="Expiry"
					info1={
						<Center gap={1}>
							<Text fontSize="sm">{formatDisplayExpiredTime(expiredAt)}</Text>
						</Center>
					}
				/>
			</Card>
		);
	};

	const renderContent = () => {
		return (
			<Flex flexDirection={'column'} flex={1} gap={'16px'} padding={'8px 6px'}>
				<Card
					flexDirection={'column'}
					background={'white'}
					gap={'8px'}
					borderRadius={'12px'}
					position={'relative'}
				>
					<TokenRow token={tokenInfoIn} amount={formatCurrency(amountIn)} amountUsd={amountInUsd} />
					<Flex alignItems={'center'} px={5}>
						<ArrowUpIcon
							style={{
								transform: 'scaleY(-1)',
							}}
							height={18}
						/>
						<Box as="hr" flex={1} />
					</Flex>
					<TokenRow token={tokenInfoOut} amount={formatCurrency(amountOut)} amountUsd={amountOutUsd} />
				</Card>

				{renderTradeInfo()}

				{rateWarning && (
					<Warning msg={rateWarning?.msg} title={rateWarning?.title} status={rateWarning?.type} />
				)}
			</Flex>
		);
	};

	const renderAction = () => (
		<Box p={4}>
			<CTAButton
				onClick={handleConfirmTransaction}
				colorScheme="cyan"
				size="lg"
				fontWeight="medium"
				width="100%"
				isDisabled={false}
			>
				{isPending ? <Spinner size="sm" color="white" /> : 'Confirm'}
			</CTAButton>
		</Box>
	);

	return (
		<AppLayout
			header={'Confirmation'}
			headerStyle={{ borderBottom: `1px solid ${BASE_BORDER_COLOR}`, paddingBottom: '12px' }}
			footer={renderAction()}
		>
			{renderContent()}
		</AppLayout>
	);
};
