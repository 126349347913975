import React, { Suspense, useEffect, useState } from 'react';
import { AnimatePresence } from 'framer-motion';
import { ErrorViewCreateWallet, Loading } from '@/app-components/common';
import i18n from '@/app-cores/i18n';
import { TelegramCore } from '@/app-cores/telegram';
import { useQueryMaintenanceNotice } from '@/app-hooks/api/configuration';
import { useQueryUserProfile } from '@/app-hooks/api/user/useQueryUserProfile';
import { useMutationInitApp } from '@/app-hooks/app';
import { Maintenance } from '../wallet/components/Maintenance';
import { MainApp } from './MainApp';
import { WalletCreation } from './WalletCreation';

export const InitializeApp = React.memo(() => {
	const [initError, setInitError] = useState(false);
	const [initiated, setInitiated] = useState(false);
	const { isEnableMaintenance, isLoading: isLoadingMaintenance } = useQueryMaintenanceNotice();
	const { data: userProfile, isLoading: isLoadingProfile, isError: isGetUserProfileError } = useQueryUserProfile();
	console.log('InitializeApp...');

	const { mutateAsync: initApp } = useMutationInitApp({
		onCompleted: () => {
			setInitiated(true);
		},
	});

	const whiteListMaintenance = i18n.t('appConfigs.whiteListMaintenance') || '';
	const isWhiteListMaintenance =
		whiteListMaintenance.includes(TelegramCore.getUserId().toString()) ||
		whiteListMaintenance.includes(TelegramCore.getUser()?.username);
	const isAppLoading = !initiated || isLoadingMaintenance || isLoadingProfile;

	useEffect(() => {
		async function getInitAppResult() {
			const success = await initApp();
			setInitError(!success);
		}
		getInitAppResult();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	if (isAppLoading) return <Loading />;

	if (initError || isGetUserProfileError) return <ErrorViewCreateWallet />;

	if (isEnableMaintenance && !isLoadingMaintenance && !isWhiteListMaintenance) return <Maintenance />;
	return (
		<AnimatePresence mode="wait">
			<Suspense fallback={<Loading />}>
				<MainApp />
				<WalletCreation userProfile={userProfile} />
			</Suspense>
		</AnimatePresence>
	);
});
