import { FiInfoIcon } from '@/assets/icons';
import {
	Alert,
	AlertDescription,
	AlertIcon,
	AlertStatus,
	Box,
	Button,
	Flex,
	Text,
	useDisclosure,
} from '@chakra-ui/react';
import React, { CSSProperties, ReactNode } from 'react';

const mapBg = {
	warning: 'orange.8',
	error: 'red.100',
};
const mapColor = {
	warning: 'orange.100',
};

const Warning: React.FC<{
	msg?: string | ReactNode;
	title?: string;
	icon?: ReactNode;
	action?: ReactNode;
	style?: CSSProperties;
	background?: string;
	status?: AlertStatus;
}> = ({ msg, icon, action, status = 'warning', title, background, ...rest }) => {
	return (
		<Alert
			alignItems={'start'}
			borderRadius={'12px'}
			status={status}
			gap={'10px'}
			{...rest}
			background={background || mapBg[status]}
		>
			{icon ? (
				<Box style={{ minWidth: '18px', display: 'flex' }}>{icon}</Box>
			) : (
				<FiInfoIcon style={{ minWidth: '18px' }} width={'18px'} height={'18px'} />
			)}
			<AlertDescription fontWeight={'400'} fontSize={'12px'} lineHeight={'18px'}>
				{title && (
					<Text fontWeight={'500'} fontSize={'14px'} mb={1} color={mapColor[status]}>
						{title}
					</Text>
				)}
				{typeof msg === 'string' && msg?.includes('\n') ? (
					<Flex flexDirection={'column'} gap={'4px'}>
						{msg.split('\n').map((e, i) => (
							<div key={i}>{e}</div>
						))}
					</Flex>
				) : (
					msg
				)}
				{action && <Box mt={2}>{action}</Box>}
			</AlertDescription>
		</Alert>
	);
};

export default Warning;
