import { ErrorCode } from '@/app-constants/errors';
import { TelegramCore } from '@/app-cores/telegram';
import { TelifiError } from '@/app-helpers/error-handling';
import axios from 'axios';
import { AuthTokenResponse } from '.';
import { BOT_BASE_API_URL } from '@/app-cores/api/axios';

export const ServiceAuth = {
	authenticate: async (): Promise<AuthTokenResponse> => {
		const { data } = await axios.post(`${BOT_BASE_API_URL}/auth/telegram`, TelegramCore.getInitDataObject());
		if (data.status !== true) {
			throw new TelifiError(ErrorCode.FAILED, data.message || 'Authenticate failed');
		}
		return {
			accessToken: data.accessToken,
		};
	},
};
