import React from 'react';

interface IProps extends React.SVGAttributes<HTMLOrSVGElement> {}

export const FiTobiLogoIcon: React.FC<IProps> = ({ width = 161, height = 40, ...rest }) => {
	return (
		<svg width={width} height={height} viewBox="0 0 161 40" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M11.3016 11.9617C11.3016 10.8749 10.7663 10.3359 9.67834 10.3359H2.12326C1.09577 10.3359 0.5 9.73606 0.5 8.70151V2.61587C0.5 1.52915 1.09577 0.981445 2.12326 0.981445H29.7532C30.8325 0.981445 31.3765 1.52915 31.3765 2.61587V8.70151C31.3765 9.73606 30.8325 10.3359 29.7532 10.3359H22.1982C21.1707 10.3359 20.5749 10.8749 20.5749 11.9617V37.3909C20.5749 38.4255 20.0309 39.0167 18.9516 39.0167H12.9076C11.8801 39.0167 11.2843 38.4168 11.2843 37.3909V11.9617H11.3016Z"
				fill="#21222E"
			/>
			<path
				d="M60.6215 26.7942C60.6215 30.376 59.8617 40 47.0742 40C34.2867 40 33.5269 30.3847 33.5269 26.7942V13.2058C33.5269 9.6153 34.2867 0 47.0742 0C59.8617 0 60.6215 9.6153 60.6215 13.2058V26.7942ZM51.3396 16.0922C51.3396 11.9105 51.0719 9.35449 47.0742 9.35449C43.0765 9.35449 42.8088 11.9105 42.8088 16.0922V23.9165C42.8088 28.0982 43.0765 30.6542 47.0742 30.6542C51.0719 30.6542 51.3396 28.0982 51.3396 23.9165V16.0922Z"
				fill="#21222E"
			/>
			<path
				d="M78.4338 0.981445C88.5792 0.981445 92.4647 5.11098 92.4647 14.0221C92.4647 15.8739 91.9293 19.4557 89.7103 21.8986C91.9207 22.9332 93.544 25.6456 93.544 29.0188C93.544 35.7565 90.5737 39.0167 80.4284 39.0167H67.0969C66.0694 39.0167 65.4736 38.4168 65.4736 37.3909V2.66803C65.4736 1.58131 66.009 1.0423 67.0969 0.981445H78.4338ZM79.729 31.3053C82.3193 31.3053 83.6144 31.0358 83.6144 28.4798C83.6144 25.9239 82.4229 25.55 79.8326 25.55H76.327C75.2995 25.55 74.7038 26.089 74.7038 27.1758V29.6796C74.7038 30.7663 75.2995 31.3053 76.327 31.3053H79.729ZM79.2973 17.7169C81.8876 17.7169 83.1827 17.3953 83.1827 14.0221C83.1827 10.6489 81.8876 10.3272 79.2973 10.3272H76.3788C75.3513 10.3272 74.7556 10.8663 74.7556 11.953V16.0825C74.7556 17.1692 75.3513 17.7082 76.3788 17.7082H79.2973V17.7169Z"
				fill="#21222E"
			/>
			<path
				d="M104.656 0.981445C105.736 0.981445 106.28 1.52915 106.28 2.61587V37.3909C106.28 38.4255 105.744 39.0167 104.656 39.0167H98.716C97.6885 39.0167 97.0928 38.4168 97.0928 37.3909V2.61587C97.0928 1.52915 97.6885 0.981445 98.716 0.981445H104.656Z"
				fill="#21222E"
			/>
			<path
				d="M157.948 29.498L158.302 29.8892C156.317 35.8096 150.704 40 144.194 40H132.33C127.927 40 124.093 37.7831 121.822 34.4621C123.031 35.7662 124.749 36.5921 126.675 36.5921H144.159C148.986 36.5921 153.079 33.0885 153.916 28.333L155.747 28.446C156.584 28.4982 157.379 28.872 157.948 29.498Z"
				fill="#00E9DB"
			/>
			<path
				d="M150.065 0.0078125H133.236C132.701 0.0078125 132.166 0.0338937 131.647 0.112138C130.18 0.181688 128.772 0.546826 127.512 1.16408L127.598 1.02498C122.521 -1.68747 115.458 2.40729 115.458 2.40729C119.11 2.89414 121.122 6.04128 121.968 7.78004C121.847 8.22342 121.744 8.67549 121.675 9.13626L119.059 26.602C118.808 28.3408 119.257 30.01 120.181 31.3054C120.233 31.3749 120.285 31.4532 120.336 31.514C121.511 33.0354 123.333 34.0265 125.422 34.0265H142.285C146.205 34.0265 149.624 31.6444 151.101 28.1582L146.619 27.8887C145.462 27.8192 144.435 27.0541 144.107 25.9326C144.038 25.724 143.995 25.5066 143.969 25.298C143.943 25.0893 143.926 24.8807 143.926 24.672C143.926 24.0895 144.038 23.5331 144.185 22.968C144.262 22.6898 144.357 22.4203 144.452 22.1508C144.556 21.8726 144.659 21.6031 144.763 21.3423C145.238 20.1513 146.291 18.795 147.552 18.5864C149.279 18.2821 151.075 18.1691 152.689 18.143L154.235 5.65007C154.442 3.98086 153.795 2.42468 152.655 1.39012C155.116 1.9726 156.868 4.35469 156.54 7.01499L155.185 18.1604C155.806 18.1865 156.359 18.2126 156.825 18.2386C158 18.3169 158.949 18.9863 159.649 19.9165L160.452 11.3966C161.039 5.30231 156.221 0.0251996 150.065 0.0251996V0.0078125ZM126.924 14.6394C125.931 14.6394 125.129 13.8309 125.129 12.8311C125.129 11.8313 125.931 11.0141 126.924 11.0141C127.917 11.0141 128.729 11.8226 128.729 12.8311C128.729 13.8396 127.926 14.6394 126.924 14.6394ZM142.958 16.9259L142.881 16.9606C139.6 18.6038 135.757 18.6038 132.476 16.9606L132.399 16.9259C132.01 16.7346 131.768 16.3347 131.768 15.9C131.768 15.2654 132.278 14.7524 132.908 14.7524H142.458C143.088 14.7524 143.597 15.2654 143.597 15.9C143.597 16.3347 143.347 16.7346 142.967 16.9259H142.958ZM148.657 14.2047C147.664 14.2047 146.861 13.3962 146.861 12.3964C146.861 11.3966 147.664 10.5881 148.657 10.5881C149.65 10.5881 150.462 11.3966 150.462 12.3964C150.462 13.3962 149.659 14.2047 148.657 14.2047Z"
				fill="#00E9DB"
			/>
		</svg>
	);
};
