import { FiErrorIcon, FiSuccessIcon, FiWaringIcon } from '@/assets/icons';
import { Box, Flex, Text } from '@chakra-ui/layout';

export interface ToastProps {
	type: 'success' | 'warning' | 'error';
	message: React.ReactNode;
	title?: string;
}
const ToastConfigs: Record<
	ToastProps['type'],
	{
		title: string;
		icon: React.ReactNode;
	}
> = {
	'success': {
		title: 'Congratulations!',
		icon: <FiSuccessIcon />,
	},
	'warning': {
		title: 'Warning!',
		icon: <FiWaringIcon width={'24px'} height={'24px'} fill="orange" />,
	},
	'error': {
		title: 'Error!',
		icon: <FiErrorIcon />,
	},
};
export const Toast: React.FC<ToastProps> = ({ type, message, title }) => {
	return (
		<Flex gap={3}>
			<Box>{ToastConfigs[type].icon}</Box>
			<Flex flexDirection="column" gap={1}>
				<Text fontSize="sm" fontWeight={600}>
					{title || ToastConfigs[type].title}
				</Text>
				<Text fontSize="xs" lineHeight="18px">
					{message}
				</Text>
			</Flex>
		</Flex>
	);
};
