import React from 'react';

interface IProps extends React.SVGAttributes<HTMLOrSVGElement> {
	dark?: boolean;
}

// logo gray or cyant
export const AppLogoIcon: React.FC<IProps> = ({ width = 64, height = 64, dark, ...rest }) => {
	if (dark)
		return (
			<svg
				xmlns="http://www.w3.org/2000/svg"
				width={width}
				height={height}
				{...rest}
				viewBox="0 0 64 64"
				fill="none"
			>
				<path
					d="M54.0544 41.9201L54.4631 42.3701C52.1704 49.1801 45.6911 54.0001 38.1751 54.0001H24.4789C19.3951 54.0001 14.9693 51.4501 12.3477 47.6301C13.7432 49.1301 15.7269 50.0801 17.9498 50.0801H38.1352C43.7074 50.0801 48.4323 46.0501 49.3992 40.5801L51.5125 40.7101C52.4794 40.7701 53.3965 41.2001 54.0544 41.9201Z"
					fill="black"
					fillOpacity="0.32"
				/>
				<path
					d="M44.9523 8H25.5244C24.9064 8 24.2884 8.03 23.6903 8.12C21.9957 8.2 20.3709 8.62 18.9155 9.33L19.0152 9.17C13.1539 6.05 5 10.76 5 10.76C9.21653 11.32 11.5391 14.94 12.516 16.94C12.3764 17.45 12.2568 17.97 12.1771 18.5L9.15672 38.59C8.86764 40.59 9.38598 42.51 10.4526 44C10.5124 44.08 10.5722 44.17 10.632 44.24C11.9877 45.99 14.0909 47.13 16.5032 47.13H35.971C40.4966 47.13 44.4439 44.39 46.1485 40.38L40.975 40.07C39.6393 39.99 38.4531 39.11 38.0743 37.82C37.9946 37.58 37.9447 37.33 37.9148 37.09C37.8849 36.85 37.865 36.61 37.865 36.37C37.865 35.7 37.9946 35.06 38.164 34.41C38.2537 34.09 38.3634 33.78 38.473 33.47C38.5926 33.15 38.7123 32.84 38.8319 32.54C39.3801 31.17 40.5962 29.61 42.0516 29.37C44.0452 29.02 46.1186 28.89 47.9826 28.86L49.7669 14.49C50.0062 12.57 49.2586 10.78 47.9428 9.59C50.7837 10.26 52.8072 13 52.4284 16.06L50.8634 28.88C51.5811 28.91 52.2191 28.94 52.7574 28.97C54.113 29.06 55.2095 29.83 56.017 30.9L56.944 21.1C57.6218 14.09 52.0596 8.02 44.9523 8.02V8ZM18.2377 24.83C17.0914 24.83 16.1643 23.9 16.1643 22.75C16.1643 21.6 17.0914 20.66 18.2377 20.66C19.384 20.66 20.321 21.59 20.321 22.75C20.321 23.91 19.394 24.83 18.2377 24.83ZM36.7485 27.46L36.6588 27.5C32.8709 29.39 28.4351 29.39 24.6472 27.5L24.5575 27.46C24.1089 27.24 23.8298 26.78 23.8298 26.28C23.8298 25.55 24.4179 24.96 25.1456 24.96H36.1704C36.8981 24.96 37.4862 25.55 37.4862 26.28C37.4862 26.78 37.1971 27.24 36.7585 27.46H36.7485ZM43.3275 24.33C42.1812 24.33 41.2541 23.4 41.2541 22.25C41.2541 21.1 42.1812 20.17 43.3275 20.17C44.4739 20.17 45.4109 21.1 45.4109 22.25C45.4109 23.4 44.4838 24.33 43.3275 24.33Z"
					fill="black"
					fillOpacity="0.32"
				/>
			</svg>
		);
	return (
		<svg width={width} height={height} viewBox="0 0 55 52" {...rest} fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M10.4857 15.398C11.5563 9.24798 16.8946 4.75879 23.1371 4.75879H46.6893C51.7586 4.75879 55.6465 9.25862 54.9107 14.2742L51.236 39.3239C50.2022 46.3705 44.1575 51.5932 37.0355 51.5932H15.8618C9.76485 51.5932 5.1415 46.0954 6.1872 40.0888L10.4857 15.398Z"
				fill="#00E9DB"
			/>
			<path
				d="M6.71027 11.6216C7.78093 5.47161 13.1192 0.982422 19.3617 0.982422H44.0319C48.1361 0.982422 51.3046 4.59145 50.7732 8.66117L47.0345 37.2953C46.2486 43.3144 41.1201 47.8168 35.0499 47.8168H12.0864C5.98946 47.8168 1.36611 42.3191 2.41181 36.3125L6.71027 11.6216Z"
				fill="white"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M7.15258 8.33418C6.66835 9.35871 6.31383 10.4616 6.11171 11.6238L2.40892 32.9149C1.36438 38.921 5.98755 44.4176 12.0838 44.4176H33.7104C38.6429 44.4176 42.8101 40.7587 43.448 35.8676L46.9968 8.66029C47.5276 4.59085 44.3593 0.982422 40.2554 0.982422H18.7635C16.9795 0.982422 15.2694 1.34906 13.7116 2.01632C7.82714 0.61502 2.68393 2.95816 0.40918 4.75901C3.52323 5.49175 5.77664 6.86298 7.15258 8.33418Z"
				fill="#00E9DB"
			/>
			<path
				d="M14.7609 17.6021C14.7609 18.8537 13.7463 19.8683 12.4947 19.8683C11.2431 19.8683 10.2285 18.8537 10.2285 17.6021C10.2285 16.3505 11.2431 15.3359 12.4947 15.3359C13.7463 15.3359 14.7609 16.3505 14.7609 17.6021Z"
				fill="white"
			/>
			<path
				d="M41.9552 17.6021C41.9552 18.8537 40.9406 19.8683 39.689 19.8683C38.4375 19.8683 37.4229 18.8537 37.4229 17.6021C37.4229 16.3505 38.4375 15.3359 39.689 15.3359C40.9406 15.3359 41.9552 16.3505 41.9552 17.6021Z"
				fill="white"
			/>
			<path
				d="M18.997 20.9719C18.6011 20.5234 18.949 19.8662 19.5471 19.8662H31.8824C32.4806 19.8662 32.8285 20.5234 32.4326 20.9719C28.8893 24.9858 22.5403 24.9858 18.997 20.9719Z"
				fill="white"
			/>
			<path
				d="M35.163 26.381C31.8734 30.0909 32.7304 33.383 34.1544 35.3172C34.7773 36.1631 35.8137 36.5409 36.8624 36.6019C41.3593 36.8635 48.4936 36.8635 50.365 36.8635C52.4801 36.8635 54.0161 39.6333 54.5197 40.6405V27.7988C52.7067 25.3815 50.9945 25.029 50.365 25.1549H38.1297C37.0146 25.1549 35.9028 25.5467 35.163 26.381Z"
				fill="white"
			/>
		</svg>
	);
};
