import { NAVIGATE_PATHS } from '@/app-constants/router';
import Swap from '@/app-views/swap/pages';
import LimitOrder from '@/app-views/swap/pages/LimitOrder';
import { RouteObject } from 'react-router';
import { ConfirmTransaction } from '@/app-views/swap/pages/ConfirmTransaction';
import ListOrders from '@/app-views/swap/pages/ListOrders';
import { ConfirmTransactionLimit } from '@/app-views/swap/pages/ConfirmTransactionLimit';

export const TradingRouter: RouteObject[] = [
	{
		path: NAVIGATE_PATHS.Swap.Main,
		children: [
			{
				index: true,
				element: <Swap />,
			},
			{
				path: NAVIGATE_PATHS.Swap.ConfirmTransaction,
				element: <ConfirmTransaction />,
			},
		],
	},
	{
		path: NAVIGATE_PATHS.Limit.Main,
		children: [
			{ index: true, element: <LimitOrder /> },
			{
				path: NAVIGATE_PATHS.Limit.LimitHistory,
				element: <ListOrders />,
			},
			{
				path: NAVIGATE_PATHS.Limit.ConfirmTransaction,
				element: <ConfirmTransactionLimit />,
			},
		],
	},
];
