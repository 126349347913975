import purify from 'dompurify';
export const escapeScriptHtml = (str: string) => {
	return purify.sanitize(str, { sanitize: true, ADD_ATTR: ['target'] });
};

export function formatTelegramStartParams(params) {
	const parameters = (params as any)
		.replaceAll('--', '%')
		.replaceAll('__', '=')
		.replaceAll('-', '&')
		.replaceAll('%5F', '_')
		.replaceAll('%2D', '-')
		.replaceAll('%2E', '.')
		.replaceAll('%3A', ':')
		.replaceAll('%2C', ',')
		.replaceAll('%2F', '/');
	return decodeURIComponent(parameters);
}
