import {
	Box,
	Button,
	Card,
	CardBody,
	CardHeader,
	InputRightElement,
	Text,
	InputGroup,
	Flex,
	Spinner,
} from '@chakra-ui/react';
import isEmpty from 'lodash/isEmpty';
import { useEffect, useMemo, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import { getSdkError } from '@walletconnect/utils';
import { useTranslation } from 'react-i18next';
import { isMobile } from 'react-device-detect';
import { InputCustom, TextSmall, Toast } from '@/app-components/common';
import { getShortAddress } from '@/app-helpers/address';
import { useWallet } from '@/app-hooks/wallet';

import { CancelIcon } from '@/assets/images/svg';
import { TelegramCore } from '@/app-cores/telegram';
import { WALLET_CONNECT_TG_CLOUD_KEY } from '../data/tgCloud';
import { FeatureConfig, useQueryFeatureConfig } from '@/app-hooks/api/configuration';
import { FeatureConfigWrap } from '@/app-helpers/components';
import { FiInfoIcon } from '@/assets/icons';
import { useConnect, useConnectedManagerInApp } from '../hooks';
import { web3wallet } from '../utils/WalletConnectUtil';
import SettingsStore from '../store/SettingsStore';
import { STORAGE_KEYS } from '@/app-constants/storage';

interface ConnectButtonProps {
	hostname: string;
}
export const ConnectButton: React.FunctionComponent<ConnectButtonProps> = ({ hostname }) => {
	const { onConnect } = useConnect();
	const { address } = useWallet();
	const [uri, setUri] = useState('');
	const [show, setShow] = useState(false);
	const { isConnected, disconnect, session } = useConnectedManagerInApp(hostname);
	const [loading, setLoading] = useState(false);
	const [isChecking, setChecking] = useState(true);
	const isConnectInApp = useRef(false);
	const isExisted = useRef(false);
	const isShowOnDesktop = useQueryFeatureConfig(FeatureConfig.CONNECT_BUTTON_ON_DESKTOP);
	const { t } = useTranslation();
	const whiteList = t('whiteListShowWalletConnectButton');
	const isOnlyShowQR = isMobile
		? whiteList && whiteList.split(',').some((w) => hostname?.includes(w))
		: isShowOnDesktop;

	useEffect(() => {
		const isFirstTimeViewDappPage = localStorage.getItem(STORAGE_KEYS.TOBI_FIRST_TIME_VIEW_DAPP_PAGE);
		if (!isFirstTimeViewDappPage) {
			setShow(true);
			localStorage.setItem(STORAGE_KEYS.TOBI_FIRST_TIME_VIEW_DAPP_PAGE, '1');
		}
	}, []);

	useEffect(() => {
		if (!isConnected || isEmpty(session)) return;
		const pingToPair = async () => {
			try {
				const result = await web3wallet.core.pairing.ping({
					topic: session.pairingTopic,
				});
				if (typeof result === 'undefined') {
					console.log('Ping to pair successfully');
					isExisted.current = true;
					setChecking(false);
				}
			} catch (error) {
				console.error('Error while pinging:', error);
			}
		};
		const disconnectIfNotExisted = () => {
			const timeoutRef = setTimeout(() => {
				if (!isExisted.current && !isConnectInApp.current && isConnected) {
					console.log('Trigger disconnect topic');
					web3wallet
						.disconnectSession({
							topic: session.topic,
							reason: getSdkError('USER_DISCONNECTED'),
						})
						.then(() => {
							SettingsStore.setSessions(Object.values(web3wallet.getActiveSessions()));
						})
						.catch((e) => {
							console.error('Error while disconnecting session:', e);
						})
						.finally(() => {
							setChecking(false);
						});
				}
			}, 8000);

			return () => clearTimeout(timeoutRef);
		};
		pingToPair();
		const cleanupTimeout = disconnectIfNotExisted();
		return cleanupTimeout;
	}, [isConnected, session]);

	const connectFormElement = useMemo(() => {
		return (
			<InputGroup>
				<InputCustom
					placeholder="Wallet Connect URI"
					fontSize="sm"
					border="none"
					_focus={{
						boxShadow: 'none',
					}}
					pl={0}
					value={uri}
					onChange={(e) => setUri(e.target.value)}
				/>

				<InputRightElement cursor="pointer" width="auto">
					<Button
						colorScheme="cyan"
						disabled={!uri}
						onClick={() => {
							isConnectInApp.current = true;
							onConnect(uri, true);
							setShow(false);
							setUri('');
							localStorage.removeItem(WALLET_CONNECT_TG_CLOUD_KEY.CONNECT_IN_DAPP_BROWSER.KEY);
						}}
					>
						Connect
					</Button>
				</InputRightElement>
			</InputGroup>
		);
	}, [onConnect, uri]);

	const handleDisconnect = async () => {
		try {
			setLoading(true);
			await disconnect(session);
			toast(<Toast type="success" message={`Disconnect with ${session.peer.metadata.name} successfully`} />);
		} catch (error) {
			console.error('disconnect error:', error);
			toast(<Toast type="error" message="Disconnect with dApp failed" />);
		} finally {
			setLoading(false);
		}
	};
	const disconnectElement = () => {
		return (
			<Flex>
				<Box wordBreak="break-all">
					<Text fontSize="sm">Wallet: {getShortAddress(address, { start: 3, end: 3 })}</Text>
					<TextSmall mt={0} color="green.200">
						Connected
					</TextSmall>
				</Box>
				<Box ml="auto">
					<Button width="166px" colorScheme="red" color="white" onClick={handleDisconnect}>
						{loading ? <Spinner size="sm" /> : 'Disconnect Wallet '}
					</Button>
				</Box>
			</Flex>
		);
	};

	const popover = (
		<Card
			id="popover-basic"
			style={{
				borderBottomRightRadius: 0,
				borderBottomLeftRadius: 0,
			}}
		>
			<CardHeader pb={4}>
				<Text textAlign="center" fontWeight={600} fontSize="xs">
					{t('walletConnect.hideInstruction')}
				</Text>
			</CardHeader>
			<hr />

			<CardBody mt={0}>
				<Text fontSize="sm" mb={4}>
					{t('walletConnect.instruction.title')}
				</Text>
				<hr />
				<Text fontSize="sm" mt={4}>
					<strong> {t('walletConnect.instruction.step1')}</strong>{' '}
					{isOnlyShowQR
						? t('walletConnect.instruction.step1Description')
						: t('walletConnect.instruction.step1ForExistTobiDescription')}
				</Text>
				<Text fontSize="sm" mt={4}>
					<strong> {t('walletConnect.instruction.step2')}</strong>{' '}
					{isOnlyShowQR
						? t('walletConnect.instruction.step2Description')
						: t('walletConnect.instruction.step2ForExistTobiDescription')}
				</Text>
				<FeatureConfigWrap feature={FeatureConfig.ALLOW_CONNECT_DAPP_WITH_URI}>
					<Text fontSize="sm" my={4}>
						<strong> {t('walletConnect.instruction.step3')}</strong>{' '}
						{isOnlyShowQR
							? t('walletConnect.instruction.step3Description')
							: t('walletConnect.instruction.step3ForExistTobiDescription')}
					</Text>
					<hr />
				</FeatureConfigWrap>
				{isConnected ? (
					<Box
						mt={4}
						py={2}
						px={5}
						backgroundColor="white"
						borderRadius={16}
						border="1px"
						borderColor="gray.100"
					>
						{disconnectElement()}
					</Box>
				) : (
					<FeatureConfigWrap feature={FeatureConfig.ALLOW_CONNECT_DAPP_WITH_URI}>
						<Box
							mt={4}
							py={2}
							px={5}
							backgroundColor="white"
							borderRadius={16}
							border="1px"
							borderColor="gray.100"
						>
							{connectFormElement}
						</Box>
					</FeatureConfigWrap>
				)}
			</CardBody>
		</Card>
	);
	const getButtonStyle = () => {
		if (show || isConnected) return 'white';
		return 'cyan';
	};
	const renderButton = () => {
		if (isConnected && isChecking && !isConnectInApp.current)
			return (
				<Button
					backgroundColor="transparent"
					_hover={{
						backgroundColor: 'transparent',
					}}
				>
					Connecting
					<Box as="span" ml={4} transform="translateY(2px)" className="dot-loader"></Box>
				</Button>
			);

		return (
			<Button colorScheme={getButtonStyle()} onClick={() => setShow(!show)} isDisabled={!hostname}>
				<Text color={isConnected ? 'green.200' : 'black'} as="span" mr={2}>
					{isConnected ? 'Tobi Wallet Connected' : t('walletConnect.connectTobiWallet')}
				</Text>
				{show ? (
					<CancelIcon fill={isConnected ? '#00C170' : '#000'} />
				) : (
					<FiInfoIcon fill={isConnected ? '#00C170' : '#000'} height={22} />
				)}
			</Button>
		);
	};
	return (
		<Flex justifyContent="end">
			<Box pr={4} ml="auto">
				{renderButton()}
			</Box>
			{show && (
				<Box
					position="absolute"
					width="100%"
					style={{
						bottom: 62,
						zIndex: 100000,
						left: 0,
					}}
				>
					{popover}
				</Box>
			)}
		</Flex>
	);
};
