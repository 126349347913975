import { CHAIN_CONFIG, ChainId } from '@/app-constants/chains';
import { MpcWalletProvider } from '@/app-cores/mpc-wallet/wallet';
import { getEnvironment } from '@/app-helpers';
import { compareChain } from '@/app-helpers/address';
import { getCachedFeatureFlags } from '@/app-helpers/data';
import { FeatureConfig } from '@/app-hooks/api/configuration';
import { Contract } from 'ethers';

export const getShortTxHash = (hash: string) => {
	if (!hash) {
		return;
	}

	return [hash.slice(0, 5), '...', hash.slice(-3)].join('');
};

export const getSignerContract = (contractAddress: string, chainId: string | number, abi: any) => {
	const networkChain = CHAIN_CONFIG[chainId];
	const provider = new MpcWalletProvider(networkChain.rpcUrls.default.http);
	const signer = provider.getSigner();
	const contract = new Contract(contractAddress, abi, signer);
	return { contract, signer, provider };
};

export const getSigner = (chainId: string | number) => {
	const networkChain = CHAIN_CONFIG[chainId];
	const provider = new MpcWalletProvider(networkChain.rpcUrls.default.http);
	const signer = provider.getSigner();
	return { signer, provider };
};

export const isSupportSignTxs = (chainId: string | ChainId) => {
	const disabledSui = getCachedFeatureFlags()?.[FeatureConfig.DISABLE_SUI_NETWORK];
	return disabledSui ? chainId !== ChainId.SUI : true;
};
