import React from 'react';

interface IProps extends React.SVGAttributes<HTMLOrSVGElement> {}

export const FiSecurityIcon: React.FC<IProps> = ({ width = 48, height = 48, ...rest }) => {
	return (
		<svg width={width} height={height} viewBox="0 0 49 48" fill="none" xmlns="http://www.w3.org/2000/svg">
			<g id="encrypted 1">
				<g id="Group">
					<path
						id="Vector"
						d="M41.367 10.7307V22.9327C41.367 25.9528 39.9828 29.4583 37.4704 32.8034C34.5424 36.7008 30.2293 40.141 24.9975 42.7521L24.5002 43L24.0029 42.7521C18.771 40.141 14.458 36.7008 11.5299 32.8034C9.01753 29.4583 7.6333 25.9528 7.6333 22.9327V10.7307L15.8392 5H33.1611L41.367 10.7307Z"
						fill="#00E9DB"
					/>
					<path
						id="Vector_2"
						d="M35.5 17.1706L25.5 27.3822L25.4791 27.404L22.9363 30L15.5 23.0899L17.6397 20.8166L22.8319 25.6422L25.4791 22.9404L25.5 22.9187L33.2548 15L35.5 17.1706Z"
						fill="black"
					/>
				</g>
			</g>
		</svg>
	);
};
