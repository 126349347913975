import { BASE_BORDER_COLOR } from '@/app-theme/theme';
import { ChevronDownIcon } from '@/assets/images/svg/chevron-down-icon';
import {
	Box,
	BoxProps,
	Button,
	Menu,
	MenuButton,
	MenuItem,
	MenuList,
	MenuProps,
	Select,
	SelectProps,
} from '@chakra-ui/react';

export default function BaseSelect({ children, ...rest }: SelectProps) {
	return (
		<Select
			{...rest}
			sx={{
				...rest.sx,
				borderColor: BASE_BORDER_COLOR,
				':focus-visible': { boxShadow: 'none !important', borderColor: BASE_BORDER_COLOR },
			}}
		>
			{children}
		</Select>
	);
}

// default select can not update position of menu, so we need to use Menu component instead
export function SelectCustom({
	placement,
	options,
	value,
	placeholder,
	background,
	fontSize = 'sm',
	onChange,
	...rest
}: Omit<MenuProps & BoxProps, 'children'> & {
	value: string;
	options: { value: string; label: string }[];
	placeholder?: string;
	onChange: (v: string) => void;
}) {
	return (
		<Menu placement={placement} {...rest}>
			<MenuButton
				as={Button}
				fontWeight={400}
				sx={{
					border: `1px solid ${BASE_BORDER_COLOR}`,
					background: 'white',
					fontSize,
				}}
				_active={{ background: 'white' }}
				_hover={{ background: 'white' }}
				rightIcon={<ChevronDownIcon width={16} height={16} />}
				size="sm"
			>
				{value ? options.find((e) => e.value === value)?.label : placeholder || options[0]?.label}
			</MenuButton>
			<MenuList
				minW="0"
				border={`1px solid ${BASE_BORDER_COLOR}`}
				py={0}
				rounded={8}
				width={'fit-content'}
				overflow="auto"
				sx={{ fontSize }}
			>
				{options.map((chain) => {
					return (
						<MenuItem
							_focus={{ background: 'white' }}
							key={chain.label}
							as={Box}
							px={3}
							py={2}
							onClick={() => onChange(chain?.value)}
						>
							{chain.label}
						</MenuItem>
					);
				})}
			</MenuList>
		</Menu>
	);
}
