import { SignClientTypes } from '@walletconnect/types';
import { buildApprovedNamespaces, getSdkError } from '@walletconnect/utils';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { isEmpty } from 'lodash-es';
import { EIP155_SIGNING_METHODS } from '../data/EIP155Data';
import { getHostName, paringTopicManager, paringTrackingManager } from '../utils/HelperUtil';
import { web3wallet } from '../utils/WalletConnectUtil';

import { getChainData } from '../data/chainsUtil';
import { useWallet } from '@/app-hooks/wallet';
import { AppLogoIconCircle } from '@/assets/images/svg';
import { CheckCircleIcon } from '@/assets/images/svg/check-circle-icon';
import {
	Box,
	Card,
	Center,
	Drawer,
	DrawerBody,
	DrawerContent,
	DrawerFooter,
	DrawerHeader,
	DrawerOverlay,
	Flex,
	Image,
	List,
	ListItem,
	Tag,
	TagLabel,
	Text,
} from '@chakra-ui/react';
import { toast } from 'react-toastify';
import { useSnapshot } from 'valtio';
import { isMobile } from 'react-device-detect';
import { useModalStore } from '../store/ModalStore';
import SettingsStore from '../store/SettingsStore';
import { Loading, Toast, Tooltip } from '@/app-components/common';
import { EIP155_CHAINS } from '@/app-constants/chains';
import { MpcWallet } from '@/app-cores/mpc-wallet/wallet';
import { SOLANA_CHAINS, SOLANA_SIGNING_METHODS } from '../data/SolanaData';
import { TelegramCore } from '@/app-cores/telegram';
import { WALLET_CONNECT_TG_CLOUD_KEY } from '../data/tgCloud';
import { useScanDomains } from '../hooks/blow-fish/useScanDomain';
import { BlowFishWaning } from './BlowFishWaning';
import { TransactionItemInfo } from '../components/TransactionItemInfo';
import { FiAlertIcon, FiInfoIcon } from '@/assets/icons';
import { ActionConfirm } from '../components/ActionConfirm';
import { ConnectionType } from '@/app-types/connection-type';
import { TRON_CHAINS, TRON_SIGNING_METHODS } from '../data/TronData';

const CONNECTION_TIMEOUT = 3000;

function checkAndCloseAppIfNeed() {
	if (!isMobile) return;
	const isInDapp = localStorage.getItem(WALLET_CONNECT_TG_CLOUD_KEY.CONNECT_IN_DAPP_BROWSER.KEY);
	if (isInDapp === WALLET_CONNECT_TG_CLOUD_KEY.CONNECT_IN_DAPP_BROWSER.VALUE) {
		TelegramCore.WebApp.close();
	}
}
const getRiskColor = (riskScore: number) => {
	if (riskScore < 0.1) {
		return 'green';
	} else if (riskScore < 0.5) {
		return 'yellow';
	} else {
		return 'red';
	}
};
const STATUS_DOMAIN_ANALYSIS = {
	PROCESSING: {
		color: '#FF4D00',
		bgColor: 'rgba(255, 77, 0, 0.08)',
		detail: 'The analysis is under way.',
	},
	PROCESSED: {
		color: '#00A560',
		bgColor: 'rgba(0, 165, 96, 0.08)',
		detail: 'Our analysis completed successfully.',
	},
	UNPROCESSABLE: {
		color: '#E32A2A',
		bgColor: 'rgba(227, 42, 42, 0.08)',
		detail: 'The URL submitted is invalid',
	},
};

export default function SessionProposalModal() {
	const { smartAccountSponsorshipEnabled, smartAccountEnabled, isInternal } = useSnapshot(SettingsStore.state);
	// Get proposal data and wallet address from store
	const { data, open, onClose, onCloseWalletConnect } = useModalStore();
	const proposal = data?.proposal as SignClientTypes.EventArguments['session_proposal'];
	const [isLoadingApprove, setIsLoadingApprove] = useState(false);
	const [isLoadingReject, setIsLoadingReject] = useState(false);
	const paringSuccess = useRef(false);
	const timeoutRef = useRef(null);
	const { address } = useWallet();
	const solAddress = MpcWallet.getSolanaWalletAddress();
	const metadata = proposal.params.proposer.metadata;
	const { data: scanDomainData, isLoading: isScanningDomain } = useScanDomains(proposal?.id, [metadata?.url]);
	const supportedNamespaces = useMemo(() => {
		// eip155
		const eip155Chains = Object.keys(EIP155_CHAINS);
		const eip155Methods = Object.values(EIP155_SIGNING_METHODS);
		// solana
		const solanaChains = Object.keys(SOLANA_CHAINS);
		const solanaMethods = Object.values(SOLANA_SIGNING_METHODS);

		//tron
		const tronChains = Object.keys(TRON_CHAINS);
		const tronMethods = Object.values(TRON_SIGNING_METHODS);
		return {
			eip155: {
				chains: eip155Chains,
				methods: eip155Methods,
				events: ['connect', 'disconnect', 'accountsChanged', 'chainChanged'],
				accounts: eip155Chains.map((chain) => `${chain}:${address}`).flat(),
			},
			solana: {
				chains: solanaChains,
				methods: solanaMethods,
				events: [],
				accounts: solanaChains.map((chain) => `${chain}:${solAddress}`).flat(),
			},
			tron: {
				chains: tronChains,
				methods: tronMethods,
				events: [],
				accounts: tronChains.map((chain) => `${chain}:${MpcWallet.getTronWalletAddress()}`),
			},
		};
	}, [address, solAddress]);

	const requestedChains = useMemo(() => {
		if (!proposal) return [];
		const required = [];
		for (const [key, values] of Object.entries(proposal.params.requiredNamespaces)) {
			const chains = key.includes(':') ? key : values.chains;
			required.push(chains);
		}

		const optional = [];
		for (const [key, values] of Object.entries(proposal.params.optionalNamespaces)) {
			const chains = key.includes(':') ? key : values.chains;
			optional.push(chains);
		}
		console.log('requestedChains', [...new Set([...required.flat(), ...optional.flat()])]);

		return [...new Set([...required.flat(), ...optional.flat()])];
	}, [proposal]);

	// the chains that are supported by the wallet from the proposal
	const supportedChains = useMemo(
		() =>
			requestedChains.map((chain) => {
				const chainData = getChainData(chain!);
				if (!chainData) return null;

				return chainData;
			}),
		[requestedChains],
	);

	// get required chains that are not supported by the wallet
	const notSupportedChains = useMemo(() => {
		if (!proposal) return [];
		const required = [];
		for (const [key, values] of Object.entries(proposal?.params?.requiredNamespaces)) {
			const chains = key.includes(':') ? key : values.chains;
			required.push(chains);
		}
		return required.flat().filter(
			(chain) =>
				!supportedChains
					.map((supportedChain) => {
						return `${supportedChain?.namespace}:${supportedChain?.chainId}`;
					})
					.includes(chain!),
		);
	}, [proposal, supportedChains]);

	const namespaces = useMemo(() => {
		try {
			return buildApprovedNamespaces({
				proposal: proposal?.params,
				supportedNamespaces,
			});
		} catch (error) {
			console.log('buildApprovedNamespaces error', error);
		}
	}, [proposal?.params, supportedNamespaces]);

	useEffect(() => {
		if (notSupportedChains?.length < 1) return;
		toast(
			<Toast
				type="error"
				title="Unsupported chain"
				message="Tobi Wallet currently does not support this network."
			/>,
		);
	}, [notSupportedChains]);

	const onApprove = useCallback(async () => {
		if (isLoadingApprove || !proposal) return;
		if (proposal) {
			setIsLoadingApprove(true);
			try {
				const approveRes = await web3wallet.approveSession({
					id: proposal.id,
					namespaces,
				});
				paringTrackingManager.setActiveTime(ConnectionType.WalletConnect, proposal.params.pairingTopic);
				// Sometime connect success but Dapp not received response, trigger ping to check connect
				const pingToPair = async () => {
					try {
						const result = await web3wallet.core.pairing.ping({
							topic: proposal.params.pairingTopic,
						});
						if (typeof result === 'undefined') {
							paringSuccess.current = true;
							SettingsStore.setSessions(Object.values(web3wallet.getActiveSessions()));
							if (isInternal) {
								paringTopicManager.setTopic(proposal.params.pairingTopic);
							}
							toast(
								<Toast
									type="success"
									title="Connect Success"
									message={`Connect with ${metadata.name} successfully`}
								/>,
							);
							if (isMobile) {
								const isInDapp = localStorage.getItem(
									WALLET_CONNECT_TG_CLOUD_KEY.CONNECT_IN_DAPP_BROWSER.KEY,
								);
								if (isInDapp === WALLET_CONNECT_TG_CLOUD_KEY.CONNECT_IN_DAPP_BROWSER.VALUE) {
									localStorage.setItem(
										WALLET_CONNECT_TG_CLOUD_KEY.RE_INIT_EVENT.KEY,
										WALLET_CONNECT_TG_CLOUD_KEY.RE_INIT_EVENT.VALUE,
									);
									localStorage.removeItem(WALLET_CONNECT_TG_CLOUD_KEY.CONNECT_IN_DAPP_BROWSER.KEY);
									TelegramCore.WebApp.close();
								}
								onClose();
								setIsLoadingApprove(false);
							} else {
								onClose();
								setIsLoadingApprove(false);
							}
						}
					} catch (error) {
						console.error('Ping to pare error:', error);
					}
				};
				pingToPair();
				timeoutRef.current = setTimeout(() => {
					if (!paringSuccess.current) {
						console.log('Trigger disconnect topic');
						web3wallet
							.disconnectSession({
								topic: approveRes?.topic,
								reason: getSdkError('USER_DISCONNECTED'),
							})
							.then(() => {
								toast(
									<Toast
										type="error"
										message="Connection error. Please try again with a new connection"
									/>,
								);
								SettingsStore.setSessions(Object.values(web3wallet.getActiveSessions()));
								checkAndCloseAppIfNeed();
							})
							.catch((e) => {
								console.error('Error while disconnecting session:', e);
							})
							.finally(() => {
								setIsLoadingApprove(false);
							});
					}
				}, CONNECTION_TIMEOUT);
			} catch (e) {
				toast(<Toast type="error" message={(e as Error).message} />);
				setIsLoadingApprove(true);
				onClose();
			}
		}

		// eslint-disable-next-line
	}, [namespaces, proposal, smartAccountSponsorshipEnabled, smartAccountEnabled, isInternal]);

	const onReject = useCallback(async () => {
		if (isLoadingReject) return;
		if (proposal) {
			try {
				setIsLoadingReject(true);
				await new Promise((resolve) => setTimeout(resolve, 1000));
				await web3wallet.rejectSession({
					id: proposal.id,
					reason: getSdkError('USER_REJECTED_METHODS'),
				});
			} catch (e) {
				setIsLoadingReject(false);
				toast(<Toast type="error" message={(e as Error).message} />);
			}
		}
		checkAndCloseAppIfNeed();
		setIsLoadingReject(false);
		onClose();
		onCloseWalletConnect();

		// if (!isInternal) {
		// 	MainRouter.navigate('/');
		// }
	}, [isLoadingReject, onClose, onCloseWalletConnect, proposal]);

	useEffect(() => {
		return () => clearTimeout(timeoutRef.current);
	}, []);

	if (notSupportedChains?.length > 0) return null;
	const scanResult = scanDomainData?.[0];
	if (isScanningDomain) return <Loading />;
	return (
		<Drawer isOpen={open} placement="bottom" onClose={onClose} closeOnOverlayClick={false} trapFocus={false}>
			<DrawerOverlay />
			<DrawerContent>
				<DrawerHeader>
					<Center gap={2}>
						<AppLogoIconCircle />
						<Image
							width={16}
							height={16}
							borderRadius="50%"
							src={metadata.icons[0]}
							alt={metadata.name}
							fallbackSrc="/assets/images/dapp-fallback.svg"
						/>
					</Center>
					<Text textAlign="center" mt={4}>
						Connect with {metadata.name}
					</Text>
					<Center gap={1} mt={2}>
						<Text
							textAlign="center"
							as="a"
							display="block"
							fontSize="sm"
							href={metadata.url}
							target="_blank"
							color="gray"
						>
							{metadata.url}
						</Text>
						{scanResult && (
							<Tag
								size="sm"
								backgroundColor={STATUS_DOMAIN_ANALYSIS[scanResult?.status]?.bgColor}
								variant="solid"
								gap={1}
								borderRadius="full"
							>
								<TagLabel
									fontSize="smaller"
									color={STATUS_DOMAIN_ANALYSIS[scanResult?.status]?.color}
									fontWeight={500}
								>
									{scanResult?.status}
								</TagLabel>
								<Tooltip
									label={STATUS_DOMAIN_ANALYSIS[scanResult?.status]?.detail}
									hasArrow
									color="white"
									fontSize="xs"
									placement="top"
									backgroundColor={STATUS_DOMAIN_ANALYSIS[scanResult?.status]?.color}
								>
									<FiInfoIcon fill={STATUS_DOMAIN_ANALYSIS[scanResult?.status]?.color} />
								</Tooltip>
							</Tag>
						)}
					</Center>
				</DrawerHeader>

				<DrawerBody pt={1}>
					{scanResult && (
						<Card px={5} py={3}>
							<TransactionItemInfo
								title="Scam Probability"
								value={
									<Flex gap={1} color={getRiskColor(scanResult.riskScore || 0)}>
										{(scanDomainData?.[0]?.riskScore || 0) * 100}%
										{scanResult?.riskScore < 0.1 ? (
											<CheckCircleIcon />
										) : (
											<FiAlertIcon
												width={20}
												height={20}
												fill={STATUS_DOMAIN_ANALYSIS[scanResult?.status]?.color}
											/>
										)}
									</Flex>
								}
							/>
						</Card>
					)}

					{!isEmpty(scanDomainData?.[0].warnings) && (
						<Box mt={2}>
							<BlowFishWaning warnings={scanResult.warnings} />
						</Box>
					)}
					<Card mt={2} p={5}>
						<Box>
							<Text fontSize="xs" fontWeight={500}>
								PERMISSION REQUEST
							</Text>
							<Text py={5} fontSize="sm">
								By connecting, you're allowing the site to perform these actions:
							</Text>
							<hr />
						</Box>
						<Box pt={5}>
							<List>
								<ListItem display="flex" alignItems="center" gap={2}>
									<CheckCircleIcon className="me-1" />
									<Text fontSize="sm">View your balance & activities</Text>
								</ListItem>
								<ListItem display="flex" alignItems="center" gap={2} mt={3}>
									<CheckCircleIcon className="me-1" />
									<Text fontSize="sm">Send approval requests</Text>
								</ListItem>
							</List>
						</Box>
					</Card>
				</DrawerBody>

				<DrawerFooter gap={2}>
					<ActionConfirm
						onApprove={onApprove}
						onReject={onReject}
						isLoadingApprove={isLoadingApprove}
						isLoadingReject={isLoadingReject}
					/>
				</DrawerFooter>
			</DrawerContent>
		</Drawer>
	);
}
