import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Box, Container, Text } from '@chakra-ui/react';
import { toast } from 'react-toastify';
import { NAVIGATE_PATHS } from '@/app-constants/router';
import { useOnEventCallback } from '@/app-hooks/common';
import PageTransition from '@/app-components/layout/PageTransition/PageTransition';
import { FiGoogleDriverIcon } from '@/assets/icons';
import { RecoveryType } from '../../components/RecoveryType';
import PageNavigation from '@/app-components/layout/PageWrapper/PageWrapper';
import { UploadFile } from '../../components/UploadFile';
import { RECOVERY_ACTION, RECOVERY_TYPE } from '@/app-constants/backup';
import { createGoogleAuthUrl } from '@/app-helpers/backup';
import { TelegramCore } from '@/app-cores/telegram';
import { Toast } from '@/app-components/common';
import { parseErrorMessage } from '@/app-helpers/error-handling';
import { useQueryUserProfile } from '@/app-hooks/api/user/useQueryUserProfile';
import { STORAGE_KEYS } from '@/app-constants/storage';

const RECOVERY_TYPES = [
	{
		img: <FiGoogleDriverIcon />,
		type: RECOVERY_TYPE.DRIVE,
		title: 'Google Drive',
		description: 'Retrieve your recovery key share from your Google Drive',
	},
];

interface RefreshKeyshareSelectMethodProps {}

export const RefreshKeyshareSelectMethod: React.FunctionComponent<RefreshKeyshareSelectMethodProps> = () => {
	const navigate = useNavigate();
	const { t } = useTranslation();
	const { data: userProfile } = useQueryUserProfile();

	const onRecovery = useOnEventCallback(async (type: string) => {
		try {
			const { url, state } = await createGoogleAuthUrl();

			if (!state) {
				throw new Error('State not found');
			}
			TelegramCore.openExternalURL(url);
			return navigate(
				`${NAVIGATE_PATHS.Recovery.GDriveCallback}?state=${state}&action=${RECOVERY_ACTION.MIGRATE_KEYSHARE}`,
			);
		} catch (e) {
			console.log('ERROR', e);
			toast(<Toast type="error" message={`Failed to process: ${parseErrorMessage(e)}`} />);
		}
	});

	const handleUploadedFile = (file: File) => {
		if (!file) return;
		try {
			const reader = new FileReader();
			reader.onload = async function (event) {
				const backupKeyShare = event.target.result as string;
				localStorage.setItem(STORAGE_KEYS.TOBI_UNSECURED_KEY_STORE, backupKeyShare);
				navigate(NAVIGATE_PATHS.ProcessRefreshKeyshare);
			};
			reader.readAsText(file);
		} catch (error) {
			console.error('Parse file error: ' + error);
		}
	};
	const recoveryType = userProfile?.backupType
		? RECOVERY_TYPES.find((rType) => rType.type === userProfile?.backupType)
		: null;

	return (
		<PageTransition>
			<PageNavigation>
				<Container p={6} height="100vh" overflow="auto">
					<Text as="h1" textAlign="center" fontSize="28px" fontWeight={500} my={2}>
						Access your backup
					</Text>
					<Text textAlign="center" color="gray.400">
						Access your wallet’s recovery key share
					</Text>
					<Box mt={4}>
						{recoveryType ? (
							<RecoveryType recoveryType={recoveryType} onClick={() => onRecovery(recoveryType.type)} />
						) : null}
					</Box>
					{userProfile?.backupType === 'drive' && (
						<Text my={2} fontSize="xs" fontWeight={500} textAlign="center">
							Or
						</Text>
					)}

					<UploadFile
						onUploaded={handleUploadedFile}
						showIcon
						title="Upload Key Share"
						description="Upload your recovery key share"
					/>
					<Box mt={6} fontSize="xs">
						<Text fontWeight={500} fontSize="medium">
							Why do you need to access my key share?
						</Text>
						<Text color="gray.400" mt={3}>
							Tobi uses state-of-the-art Multi-Party Computation (MPC) for wallet security, meaning we
							create multiple “key shares” that need to come together to operate your wallet. In some
							instances, we require all key shares (including your recovery key share) to come together in
							order to perform certain actions, such as this update.
						</Text>
						<Text fontSize="xs" color="gray.400" mt={3}>
							<Box color="black" as="strong">
								Important:
							</Box>{' '}
							Tobi will only access your recovery key share to perform this update and will not store it
							anywhere else. Your recovery key share will continue to be under your control alone once
							this update is complete.
						</Text>
					</Box>
				</Container>
			</PageNavigation>
		</PageTransition>
	);
};
