import {
	Box,
	Center,
	Divider,
	Drawer,
	DrawerBody,
	DrawerCloseButton,
	DrawerContent,
	DrawerHeader,
	DrawerOverlay,
	List,
	ListItem,
	Text,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { TokenTypes } from '@/app-views/wallet/components/Portfolio/ListTokens';
import { FiCheckIcon } from '@/assets/icons';

const OptionItem = ({ data, onClick, selected }: { data: TokenOption; onClick: () => void; selected?: TokenTypes }) => {
	const { t } = useTranslation();
	const isSelected = data.value === selected;
	const isChild = data.parent !== null;
	return (
		<>
			<ListItem
				onClick={onClick}
				display={'flex'}
				cursor={'pointer'}
				justifyContent={'space-between'}
				fontSize={'14px'}
				fontWeight={'500'}
				alignItems={'center'}
				backgroundColor={isSelected ? 'gray.100' : 'transparent'}
				py={3}
				px={isChild ? 4 : 6}
				transform={isChild ? `translateX(24px)` : ''}
				borderLeft={data.parent === '' ? '1px solid rgba(0, 0, 0, 0.08)' : ''}
			>
				<Center gap={2}>
					{data.label}
					{isSelected && <FiCheckIcon fill="#00C170" />}
				</Center>
				<Text fontSize={'12px'} color={'gray'} transform={isChild ? 'translateX(-32px)' : ''}>
					<Text as="span" color={'black'} fontWeight={'500'}>
						{data.total}
					</Text>{' '}
					{t('cryptos.tokens')}
				</Text>
			</ListItem>
			{data.value === TokenTypes.ALL && (
				<Box px={6} my={2}>
					<Divider borderColor={'gray.200'} variant={'dashed'} />
				</Box>
			)}
		</>
	);
};

export type TokenOption = { label: string; total: number; value: TokenTypes; parent?: string };
export type TokenListOptions = TokenOption[];
export default function TokenFilterDrawer({
	isOpen,
	onClose,
	options,
	onSelect,
	selected,
}: {
	isOpen: boolean;
	onClose: () => void;
	onSelect: (v: TokenTypes) => void;
	options: TokenListOptions;
	selected?: TokenTypes;
}) {
	const { t } = useTranslation();
	return (
		<Drawer placement={'bottom'} onClose={onClose} isOpen={isOpen}>
			<DrawerOverlay />
			<DrawerContent>
				<DrawerCloseButton />
				<DrawerHeader
					borderBottomWidth="1px"
					py={3}
					textAlign={'center'}
					fontSize={'16px'}
					borderColor={'gray.100'}
				>
					{t('filter')}
				</DrawerHeader>
				<DrawerBody py={5} px={0}>
					<List overflow="hidden">
						{options.map((el) => (
							<OptionItem
								data={el}
								key={el.value}
								onClick={() => {
									onSelect(el.value);
									onClose();
								}}
								selected={selected}
							/>
						))}
					</List>
				</DrawerBody>
			</DrawerContent>
		</Drawer>
	);
}
