import { Center, CenterProps, Divider, Text } from '@chakra-ui/react';

interface OrProps extends CenterProps {}
export const Or: React.FunctionComponent<OrProps> = (props) => {
	return (
		<Center gap={2} {...props}>
			<Divider border="1px solid rgba(0, 0, 0, 0.08)" />
			<Text fontSize="sm">or</Text>
			<Divider border="1px solid rgba(0, 0, 0, 0.08)" borderColor="rgba(0, 0, 0, 0.08)" />
		</Center>
	);
};
