import { WebApp } from 'node_modules/@vkruglikov/react-telegram-web-app/lib/core/twa-types';
import { TelegramWebAppVersion6_2 } from 'node_modules/@vkruglikov/react-telegram-web-app/lib/core/twa-types/WebAppVersion_6.2';
import qs from 'qs';

export type ConfirmPopupCallback = {
	onOk?: () => void;
	onCancel?: () => void;
};
// const initData =
// 	'query_id=AAEwREFxAAAAADBEQXGNYcR6&user=%7B%22id%22%3A1900102704%2C%22first_name%22%3A%22Ti%E1%BA%BFn%22%2C%22last_name%22%3A%22%C4%90inh%20V%C4%83n%22%2C%22username%22%3A%22matthewdinh%22%2C%22language_code%22%3A%22en%22%2C%22allows_write_to_pm%22%3Atrue%7D&auth_date=1712551966&hash=6adcf4922c1b695ca74f4fc69a59af670cd7f4f351bf2f48fb7933fb72c1675b';
class TelegramCore {
	#webApp: TelegramWebApp;

	constructor() {
		this.#webApp =
			typeof window !== 'undefined' && (window as any)?.Telegram?.WebApp ? (window as any).Telegram.WebApp : null;

		if (!this.#webApp) {
			throw new Error('App must be run in Telegram');
		}
	}

	get WebApp() {
		return this.#webApp;
	}

	public ready(): boolean {
		return !!this.#webApp.initData;
	}

	public getInitData(): string {
		return this.#webApp.initData;
	}

	public getInitDataObject(): qs.ParsedQs {
		return qs.parse(this.#webApp.initData);
	}

	public getInitDataUnsafe(): TelegramWebAppVersion6_2.WebAppInitData {
		return this.#webApp.initDataUnsafe;
	}

	public sendData(data: unknown[]): any {
		return this.#webApp.sendData(data);
	}

	public getUser(): TelegramWebAppVersion6_2.WebAppUser {
		return this.#webApp.initDataUnsafe?.user;
	}

	public getUserId(): number {
		return this.getUser().id;
	}

	public closeWebApp(): void {
		this.#webApp.close();
	}

	public withInitData(original: Record<string, any>) {
		return {
			...original,
			...this.getInitDataObject(),
		};
	}

	public openExternalURL(url: string) {
		this.#webApp.openLink(url);
	}

	public showConfirm(message: string, { onOk, onCancel }: ConfirmPopupCallback) {
		this.#webApp.showPopup(
			{
				message: message,
				buttons: [
					{
						id: 'popup_ok',
						type: 'ok',
					},
					{
						id: 'popup_cancel',
						type: 'cancel',
					},
				],
			},
			(id: string) => {
				if (id === 'popup_ok') {
					return onOk?.();
				}
				return onCancel?.();
			},
		);
	}
}

export type TelegramWebApp = WebApp;

export type ITelegramCore = typeof TelegramCore;

export default new TelegramCore();
