import { memo } from 'react';
import { Box, Center, Flex, Text } from '@chakra-ui/react';
import { FiCheckMarkDoneIcon, FiTimeIcon, FiTobiWalletIcon } from '@/assets/icons';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import { Message } from '@/app-cores/api/app-copilot/type';
import { escapeScriptHtml } from '@/app-helpers/string';
dayjs.extend(relativeTime);

interface MessageItemProps {
	data: Message;
	status: 'Pending' | 'Success';
}
export const MessageItem = memo(function Item({ data, status }: MessageItemProps) {
	const isUser = data.chatId === data.senderId;
	if (!data.message.text) return null;

	const message = (
		<Box
			borderRadius={12}
			borderTopRightRadius={isUser ? 0 : 12}
			borderTopLeftRadius={!isUser ? 0 : 12}
			px={4}
			py={2}
			backgroundColor={isUser ? 'cyan.400' : 'rgba(0, 0, 0, 0.08)'}
		>
			<Text
				wordBreak="break-word"
				dangerouslySetInnerHTML={{
					__html: escapeScriptHtml(
						data?.message?.text?.replace(/\n/g, '<br>').replace(/\t/g, '&nbsp;&nbsp;&nbsp;&nbsp;'),
					),
				}}
			>
				{/* {data.message.text} */}
			</Text>
		</Box>
	);
	return (
		<Flex
			_notFirst={{
				mt: 3,
			}}
			alignItems={isUser ? 'end' : 'start'}
			flexDirection="column"
		>
			{isUser ? (
				message
			) : (
				<Flex gap={3}>
					<Box backgroundColor="transparent">
						<FiTobiWalletIcon />
					</Box>
					<Box>
						<Text fontWeight={600} mb={1}>
							Assistant
						</Text>
						{message}
					</Box>
				</Flex>
			)}
			<Center width="100%" mt={1} gap={1} color="gray.400">
				<Text fontSize="xs" width="100%" textAlign="end">
					{dayjs(data.createdAt).fromNow()}
				</Text>
				{isUser ? status === 'Pending' ? <FiTimeIcon /> : <FiCheckMarkDoneIcon /> : null}
			</Center>
		</Flex>
	);
});
