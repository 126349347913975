import { CHAIN_CONFIG } from '@/app-constants/chains';
import {
	Avatar,
	Button,
	Card,
	Center,
	Divider,
	Drawer,
	DrawerBody,
	DrawerContent,
	DrawerFooter,
	DrawerHeader,
	DrawerOverlay,
	Flex,
	Spinner,
	Text,
} from '@chakra-ui/react';
import { TransactionItemInfo } from '../components/TransactionItemInfo';
import { useProposalAction } from '../hooks';
import { useModalStore } from '../store/ModalStore';
import { botConnectorHandler } from '../utils/bot-connector.handler';
import { getSignParamsMessage } from '../utils/helper.util';

export default function SessionSignModal() {
	// Get request and wallet data from store
	const { data, open, onClose } = useModalStore();
	const { onApprove, isPendingApprove, isPendingReject, onReject } = useProposalAction(data);

	if (
		data.payload.method !== 'personal_sign' &&
		data.payload.method !== 'eth_signMessage' &&
		data.payload.method !== 'eth_signTransaction'
	) {
		// Just for typing;
		return <></>;
	}

	const metadata = botConnectorHandler.getClient(data.clientId)?.metadata;
	const message = getSignParamsMessage(data.payload.data);

	return (
		<Drawer isOpen={open} placement="bottom" onClose={onClose} size="full" trapFocus={false}>
			<DrawerOverlay />
			<DrawerContent>
				<DrawerHeader>
					<DrawerHeader borderBottomWidth="1px" borderColor="rgba(0, 0, 0, 0.08)">
						<Center>
							<Text fontSize={'16px'} fontWeight={'500'}>
								Signature request
							</Text>
						</Center>
					</DrawerHeader>
				</DrawerHeader>
				<DrawerBody pt={0}>
					<Flex flexDirection={'column'} alignItems={'center'} gap={'20px'}>
						<Center border="1px solid rgba(0, 0, 0, 0.08)" gap={2} borderRadius={52} px={2} py={1}>
							<Avatar src={metadata.icons[0]} name={metadata.name} width={8} height={8} />
							<Text fontSize="sm">{metadata.name}</Text>
						</Center>
						<Text textAlign={'center'} fontSize={'24px'} lineHeight={'30px'}>
							You are signing
						</Text>
						<Card padding={'16px'} width={'100%'} background={'gray.100'} gap={'8px'}>
							<Flex justifyContent={'space-between'} flexDirection="column">
								<TransactionItemInfo title="Network" value={CHAIN_CONFIG[data.payload.chainId]?.name} />
								<Divider />
								<TransactionItemInfo title="Messages" value={message} />
							</Flex>
						</Card>
					</Flex>
				</DrawerBody>

				<DrawerFooter display="flex" flexDirection="column" gap={2}>
					<Button
						isDisabled={isPendingApprove || isPendingReject}
						size="lg"
						fontWeight="medium"
						width="100%"
						onClick={onReject}
					>
						{isPendingReject ? <Spinner size="sm" /> : 'Deny'}
					</Button>
					<Button
						isDisabled={isPendingApprove || isPendingReject}
						size="lg"
						fontWeight="medium"
						colorScheme="cyan"
						width="100%"
						onClick={onApprove}
					>
						{isPendingApprove ? <Spinner size="sm" color="white" /> : 'Approve'}
					</Button>
				</DrawerFooter>
			</DrawerContent>
		</Drawer>
	);
}
