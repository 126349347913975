import { InputCustom, Toast } from '@/app-components/common';
import { useMutationSendVerifyCode, useMutationVerifyCode } from '@/app-hooks/api/mpc-wallet/useMutationVerifyCode';
import { Button, Flex, FormControl, FormErrorMessage, FormLabel, Text } from '@chakra-ui/react';
import { useFormik } from 'formik';
import { toast } from 'react-toastify';
import { EMAIL_REGEX } from '../../../../../app-constants/regex';
import { useTranslation } from 'react-i18next';
import { DATADOG_ACTIONS, DATADOG_ERROR_TAGS, dataDogAddAction, dataDogAddError } from '@/app-services/monitor/datadog';

interface SendFormProps {
	onSendEmail: (payload: { email?: string; code?: string }) => void;
	isSending: boolean;
	onIosKeyboardOpen?: (isOpen: boolean, fileName: 'email' | 'otp') => void;
}
export const SendForm: React.FC<SendFormProps> = ({ onSendEmail, isSending, onIosKeyboardOpen }) => {
	const { t } = useTranslation();
	const { handleChange, values, setFieldValue, submitForm, errors, setFieldError } = useFormik({
		initialValues: {
			email: '',
			code: '',
			action: 'backup',
		},
		enableReinitialize: true,
		onSubmit: async (values, helper) => {
			if (!EMAIL_REGEX.test(values.email)) {
				helper.setFieldError('email', 'Invalid email');
				return;
			}

			if (isIdle) {
				sendVerifyCode([values]);
				return;
			}

			if (!values.code) {
				helper.setFieldError('code', 'Verify code is empty');

				return;
			}
			verifyCode([values]);
			dataDogAddAction(DATADOG_ACTIONS.BACKUP_VERIFY_OTP);
		},
	});

	const { mutateAsync: sendVerifyCode, isIdle } = useMutationSendVerifyCode({
		onSuccess: () => {
			toast(
				<Toast
					title={t('storeBackup.toast.sendOTP.title')}
					type="success"
					message={t('storeBackup.toast.sendOTP.message')}
				/>,
			);
		},
	});
	const { mutateAsync: verifyCode } = useMutationVerifyCode({
		onSuccess: ({ success }) => {
			if (!success) {
				setFieldError('code', 'Verify code is invalid');
			} else {
				onSendEmail(values);
			}
		},
		onError(error) {
			dataDogAddError(error, {
				tags: {
					name: DATADOG_ERROR_TAGS.CREATE_WALLET,
					function: 'verifyOTPCode',
				},
			});
		},
	});

	console.log(errors);
	return (
		<Flex gap={5} direction={'column'}>
			<FormControl isInvalid={!!errors.email}>
				<FormLabel fontSize={'.75rem'} color={'gray.400'}>
					Your email address
				</FormLabel>
				<InputCustom
					placeholder="example@email.com"
					px={4}
					py={6}
					backgroundColor="gray.100"
					borderRadius={12}
					border="none"
					mt={2}
					fontSize="sm"
					name="email"
					onChange={handleChange}
					value={values.email}
					placeHolderStyle={{
						left: '1rem',
						fontSize: '14px',
					}}
					onIosKeyboardOpen={(isOpen) => onIosKeyboardOpen(isOpen, 'email')}
				/>
				<FormErrorMessage>{errors.email}</FormErrorMessage>
			</FormControl>
			{!isIdle && (
				<>
					<FormControl isInvalid={!!errors.code}>
						<FormLabel fontSize={'.75rem'} color={'gray.400'}>
							Your email verification OTP
						</FormLabel>
						<InputCustom
							px={4}
							py={6}
							backgroundColor="gray.100"
							borderRadius={12}
							border="none"
							mt={2}
							fontSize="sm"
							name="code"
							onChange={(event) => {
								setFieldValue('code', String(event.target.value).toUpperCase());
							}}
							value={values.code}
							placeHolderStyle={{
								left: '1rem',
								fontSize: '14px',
							}}
							onIosKeyboardOpen={(isOpen) => onIosKeyboardOpen(isOpen, 'otp')}
						/>
						<FormErrorMessage>{errors.code}</FormErrorMessage>
					</FormControl>
					<Text fontSize={'xs'}>
						{t('storeBackup.sendOTPRemind')}
						&nbsp;
						<Text
							cursor={'pointer'}
							onClick={() => sendVerifyCode([values])}
							as="strong"
							decoration={'underline'}
						>
							Resend
						</Text>
					</Text>
				</>
			)}

			<Button
				isDisabled={!values.email}
				size="lg"
				fontWeight="medium"
				colorScheme="cyan"
				width="100%"
				onClick={submitForm}
				isLoading={isSending}
			>
				{isIdle ? 'Continue' : 'Verify'}
			</Button>
		</Flex>
	);
};
