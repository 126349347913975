import React from 'react';

interface IProps extends React.SVGAttributes<HTMLOrSVGElement> {}

export const FiBillIcon: React.FC<IProps> = ({ width = 24, height = 24, ...rest }) => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 25 24" fill="none">
			<path
				d="M6 2C5.1775 2 4.5 2.6775 4.5 3.5V21.5C4.50004 21.5989 4.52939 21.6955 4.58433 21.7777C4.63928 21.8599 4.71736 21.924 4.8087 21.9618C4.90004 21.9996 5.00055 22.0095 5.09752 21.9903C5.1945 21.971 5.28358 21.9234 5.35352 21.8535L6.54688 20.6602L8.19922 21.9004C8.28591 21.9657 8.39148 22.001 8.5 22.001C8.60852 22.001 8.71409 21.9657 8.80078 21.9004L10.5 20.625L12.1992 21.9004C12.2859 21.9657 12.3915 22.001 12.5 22.001C12.6085 22.001 12.7141 21.9657 12.8008 21.9004L14.5 20.625L16.1992 21.9004C16.2859 21.9657 16.3915 22.001 16.5 22.001C16.6085 22.001 16.7141 21.9657 16.8008 21.9004L18.4531 20.6602L19.6465 21.8535C19.7164 21.9234 19.8055 21.971 19.9025 21.9903C19.9994 22.0095 20.1 21.9996 20.1913 21.9618C20.2826 21.924 20.3607 21.8599 20.4157 21.7777C20.4706 21.6955 20.5 21.5989 20.5 21.5V3.5C20.5 2.6775 19.8225 2 19 2H6Z"
				fill="#33D17A"
			/>
			<path
				d="M18 2C18.8225 2 19.5 2.6775 19.5 3.5V21.5C19.4926 21.5551 19.4761 21.6085 19.4512 21.6582L19.6465 21.8535C19.9615 22.1683 20.4998 21.9453 20.5 21.5V3.5C20.5 2.6775 19.8225 2 19 2H18Z"
				fill="#2EC27E"
			/>
			<path
				d="M9.5 15C9.36739 15 9.24021 15.0527 9.14645 15.1464C9.05268 15.2402 9 15.3674 9 15.5C9 15.6326 9.05268 15.7598 9.14645 15.8536C9.24021 15.9473 9.36739 16 9.5 16H11.5C11.6326 16 11.7598 15.9473 11.8536 15.8536C11.9473 15.7598 12 15.6326 12 15.5C12 15.3674 11.9473 15.2402 11.8536 15.1464C11.7598 15.0527 11.6326 15 11.5 15H9.5Z"
				fill="#241F31"
			/>
			<path
				d="M9.5 13C9.36739 13 9.24021 13.0527 9.14645 13.1464C9.05268 13.2402 9 13.3674 9 13.5C9 13.6326 9.05268 13.7598 9.14645 13.8536C9.24021 13.9473 9.36739 14 9.5 14H11.5C11.6326 14 11.7598 13.9473 11.8536 13.8536C11.9473 13.7598 12 13.6326 12 13.5C12 13.3674 11.9473 13.2402 11.8536 13.1464C11.7598 13.0527 11.6326 13 11.5 13H9.5Z"
				fill="#241F31"
			/>
			<path
				d="M15.5 11C15.2239 11 15 11.2239 15 11.5V12C14.1776 12 13.5 12.6776 13.5 13.5C13.5 14.3224 14.1776 15 15 15H16C16.2856 15 16.5 15.2144 16.5 15.5C16.5 15.7856 16.2856 16 16 16H15C14.7144 16 14.5 15.7856 14.5 15.5C14.5 15.2239 14.2761 15 14 15C13.7239 15 13.5 15.2239 13.5 15.5C13.5 16.3224 14.1776 17 15 17V17.5C15 17.7761 15.2239 18 15.5 18C15.7761 18 16 17.7761 16 17.5V17C16.8224 17 17.5 16.3224 17.5 15.5C17.5 14.6776 16.8224 14 16 14H15C14.7144 14 14.5 13.7856 14.5 13.5C14.5 13.2144 14.7144 13 15 13H16C16.2856 13 16.5 13.2144 16.5 13.5C16.5 13.7761 16.7239 14 17 14C17.2761 14 17.5 13.7761 17.5 13.5C17.5 12.6776 16.8224 12 16 12V11.5C16 11.2239 15.7761 11 15.5 11Z"
				fill="white"
			/>
			<path
				d="M8 9C7.86739 9 7.74021 9.05268 7.64645 9.14645C7.55268 9.24021 7.5 9.36739 7.5 9.5C7.5 9.63261 7.55268 9.75979 7.64645 9.85355C7.74021 9.94732 7.86739 10 8 10H13C13.1326 10 13.2598 9.94732 13.3536 9.85355C13.4473 9.75979 13.5 9.63261 13.5 9.5C13.5 9.36739 13.4473 9.24021 13.3536 9.14645C13.2598 9.05268 13.1326 9 13 9H8Z"
				fill="#241F31"
			/>
			<path
				d="M15 9C14.8674 9 14.7402 9.05268 14.6464 9.14645C14.5527 9.24021 14.5 9.36739 14.5 9.5C14.5 9.63261 14.5527 9.75979 14.6464 9.85355C14.7402 9.94732 14.8674 10 15 10H17C17.1326 10 17.2598 9.94732 17.3536 9.85355C17.4473 9.75979 17.5 9.63261 17.5 9.5C17.5 9.36739 17.4473 9.24021 17.3536 9.14645C17.2598 9.05268 17.1326 9 17 9H15Z"
				fill="#241F31"
			/>
			<path
				d="M8 7C7.86739 7 7.74021 7.05268 7.64645 7.14645C7.55268 7.24021 7.5 7.36739 7.5 7.5C7.5 7.63261 7.55268 7.75979 7.64645 7.85355C7.74021 7.94732 7.86739 8 8 8H13C13.1326 8 13.2598 7.94732 13.3536 7.85355C13.4473 7.75979 13.5 7.63261 13.5 7.5C13.5 7.36739 13.4473 7.24021 13.3536 7.14645C13.2598 7.05268 13.1326 7 13 7H8Z"
				fill="#241F31"
			/>
			<path
				d="M15 7C14.8674 7 14.7402 7.05268 14.6464 7.14645C14.5527 7.24021 14.5 7.36739 14.5 7.5C14.5 7.63261 14.5527 7.75979 14.6464 7.85355C14.7402 7.94732 14.8674 8 15 8H17C17.1326 8 17.2598 7.94732 17.3536 7.85355C17.4473 7.75979 17.5 7.63261 17.5 7.5C17.5 7.36739 17.4473 7.24021 17.3536 7.14645C17.2598 7.05268 17.1326 7 17 7H15Z"
				fill="#241F31"
			/>
			<path
				d="M8 5C7.86739 5 7.74021 5.05268 7.64645 5.14645C7.55268 5.24021 7.5 5.36739 7.5 5.5C7.5 5.63261 7.55268 5.75979 7.64645 5.85355C7.74021 5.94732 7.86739 6 8 6H13C13.1326 6 13.2598 5.94732 13.3536 5.85355C13.4473 5.75979 13.5 5.63261 13.5 5.5C13.5 5.36739 13.4473 5.24021 13.3536 5.14645C13.2598 5.05268 13.1326 5 13 5H8Z"
				fill="#241F31"
			/>
			<path
				d="M15 5C14.8674 5 14.7402 5.05268 14.6464 5.14645C14.5527 5.24021 14.5 5.36739 14.5 5.5C14.5 5.63261 14.5527 5.75979 14.6464 5.85355C14.7402 5.94732 14.8674 6 15 6H17C17.1326 6 17.2598 5.94732 17.3536 5.85355C17.4473 5.75979 17.5 5.63261 17.5 5.5C17.5 5.36739 17.4473 5.24021 17.3536 5.14645C17.2598 5.05268 17.1326 5 17 5H15Z"
				fill="#241F31"
			/>
		</svg>
	);
};
