import React from 'react';

interface IProps extends React.SVGAttributes<HTMLOrSVGElement> {}

export const ErrorIcon: React.FC<IProps> = ({ width = 56, height = 56, ...rest }) => {
	return (
		<svg width={width} height={height} {...rest} viewBox="0 0 57 56" fill="none" xmlns="http://www.w3.org/2000/svg">
			<rect x="0.5" width="56" height="56" rx="28" fill="#BF2323" />
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M35.2071 21.2929C35.5976 21.6834 35.5976 22.3166 35.2071 22.7071L23.2071 34.7071C22.8166 35.0976 22.1834 35.0976 21.7929 34.7071C21.4024 34.3166 21.4024 33.6834 21.7929 33.2929L33.7929 21.2929C34.1834 20.9024 34.8166 20.9024 35.2071 21.2929Z"
				fill="white"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M21.7929 21.2929C22.1834 20.9024 22.8166 20.9024 23.2071 21.2929L35.2071 33.2929C35.5976 33.6834 35.5976 34.3166 35.2071 34.7071C34.8166 35.0976 34.1834 35.0976 33.7929 34.7071L21.7929 22.7071C21.4024 22.3166 21.4024 21.6834 21.7929 21.2929Z"
				fill="white"
			/>
		</svg>
	);
};
