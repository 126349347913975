import { Address, fromNano, toNano } from '@ton/core';
import { useEffect, useRef, useState } from 'react';
import { useTonConnectModal, useTonConnectUI, useTonWallet } from '@tonconnect/ui-react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { formatUnits } from 'ethers';
import { TonIcon } from '@/assets/icons';
import { getShortAddress, isNativeToken } from '@/app-helpers/address';
import { MpcWallet } from '@/app-cores/mpc-wallet/wallet';
import { isTonChain } from '@/app-helpers/token';
import { buildDepositJettonTransaction, buildDepositTonTransaction } from './helper';
import { getTonCoinTransferBalance } from '@/app-helpers/ton';
import { DATADOG_ACTIONS, DATADOG_ERROR_TAGS, dataDogAddAction, dataDogAddError } from '@/app-services/monitor/datadog';
import { Toast } from '@/app-components/common';
import { ProviderSelection } from '@/app-features/deposit/components/ProviderSelection';
import { DepositForm } from '@/app-features/deposit/components/DepositForm';
import { useDepositStore } from '@/app-features/deposit/store';
import { useSearchSingleToken } from '@/app-hooks/api/portfolio';
import { ChainId, STABLE_COINS } from '@/app-constants/chains';

export const TonConnect = () => {
	const { t } = useTranslation();
	const wallet = useTonWallet();
	const {
		setDepositAssetType,
		depositAssetsType,
		setDisconnectingType,
		disconnectingType,
		setToken,
		token,
		initToken,
		state,
		tokenInfo,
		setAmountDeposited,
		setShowDepositResult,
	} = useDepositStore();
	const { data: DEFAULT_TOKEN } = useSearchSingleToken({
		address: STABLE_COINS[ChainId.TON]?.USDT,
		chainId: ChainId.TON,
	});

	const { open: openTonConnect } = useTonConnectModal();
	const [tonConnectUI] = useTonConnectUI();
	const [isLoading, setIsLoading] = useState(false);
	const isConnecting = useRef(false);
	const receiverAddress = MpcWallet.getTonWalletAddress();

	useEffect(() => {
		if (wallet && isConnecting.current) {
			dataDogAddAction(DATADOG_ACTIONS.DEPOSIT_TON_CONNECT_SUCCESS);
		}
	}, [wallet]);

	const onSelectTonConnect = () => {
		dataDogAddAction(DATADOG_ACTIONS.DEPOSIT_USE_TON_CONNECT);
		!initToken && setToken(DEFAULT_TOKEN);
		if (!wallet) {
			isConnecting.current = true;
			return openTonConnect();
		}
		// console.log('🚀 ~ onSelectTonConnect ~ isLoadingBalance:', balance, isLoadingBalance);

		// if (isLoadingBalance) return;
		// if (balance === 0) {
		// 	return toast(
		// 		<Toast
		// 			type="warning"
		// 			title="Warning"
		// 			message={t('deposit.notEnoughToken', { symbol: token?.symbol || DEFAULT_TOKEN.symbol })}
		// 		/>,
		// 	);
		// }
		setDepositAssetType('Ton');
	};

	const handleDisconnect = async () => {
		try {
			setDisconnectingType('Ton');
			isConnecting.current = false;
			await tonConnectUI.disconnect();
		} catch (error) {
			console.error('Disconnect with Ton connect error: ' + error);
		} finally {
			setDisconnectingType(undefined);
			dataDogAddAction(DATADOG_ACTIONS.DEPOSIT_TON_DISCONNECT);
		}
	};
	const isOpen = depositAssetsType === 'Ton';
	const address = wallet?.account?.address;
	const walletAddress = address
		? Address.parse(address).toString({
				bounceable: false,
		  })
		: undefined;

	const handleDeposit = async () => {
		// if (state.tokenValue > balance) {
		// 	return toast(
		// 		<Toast
		// 			title="Error"
		// 			message={t('deposit.moreThanLimit', { max: balance, symbol: token.symbol })}
		// 			type="error"
		// 		/>,
		// 	);
		// }
		if (!state.tokenValue || !isTonChain(tokenInfo?.chainId)) return;
		setIsLoading(true);
		try {
			let transaction;
			if (isNativeToken(tokenInfo.address)) {
				transaction = buildDepositTonTransaction({
					value: +state.tokenValue,
				});
				setAmountDeposited(fromNano(toNano(state.tokenValue)));
			} else {
				transaction = await buildDepositJettonTransaction({
					address: wallet.account.address,
					token: tokenInfo,
					value: state.tokenValue.toString(),
				});
				const jettonTransferBalance = getTonCoinTransferBalance(
					state.tokenValue.toString(),
					tokenInfo.decimals,
				);
				setAmountDeposited(formatUnits(jettonTransferBalance, tokenInfo.decimals));
			}
			await tonConnectUI.sendTransaction(transaction);
			setShowDepositResult(true);
			setDepositAssetType(undefined);
			toast(
				<Toast
					title="Success"
					message={t('toast.depositTokenSuccess', { symbol: tokenInfo.symbol })}
					type="success"
				/>,
			);
			dataDogAddAction(DATADOG_ACTIONS.DEPOSIT_TON_CONNECT_SIGN_TRANSACTION.SUCCESS);
		} catch (error) {
			console.error('deposit ton via ton connect error', error);
			dataDogAddAction(DATADOG_ACTIONS.DEPOSIT_TON_CONNECT_SIGN_TRANSACTION.FAIL);
			dataDogAddError(error, {
				tags: {
					name: DATADOG_ERROR_TAGS.DEPOSIT,
					function: 'depositWithTonConnect',
				},
			});
		} finally {
			setIsLoading(false);
		}
	};
	return (
		<>
			<ProviderSelection
				onClick={onSelectTonConnect}
				icon={<TonIcon />}
				text={
					walletAddress
						? getShortAddress(walletAddress, {
								start: 6,
								end: 4,
						  })
						: t('tonConnect')
				}
				onDisconnect={handleDisconnect}
				isConnected={!!walletAddress}
				isDisconnecting={disconnectingType === 'Ton'}
			/>
			{isOpen && (
				<DepositForm
					isOpen={isOpen}
					onClose={() => setDepositAssetType(undefined)}
					isLoading={isLoading}
					onDeposit={handleDeposit}
					fromWallet={{
						address: walletAddress,
						logo: <TonIcon width={40} height={40} />,
					}}
					toWallet={{
						address: receiverAddress,
						logo: '',
						isTobi: true,
					}}
				/>
			)}
		</>
	);
};
