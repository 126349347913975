/* eslint-disable react-hooks/rules-of-hooks */
import { useCallback, useState } from 'react';
import { useSnapshot } from 'valtio';
import { getSdkError } from '@walletconnect/utils';
import {
	Button,
	Card,
	Center,
	Drawer,
	DrawerBody,
	DrawerContent,
	DrawerFooter,
	DrawerHeader,
	DrawerOverlay,
	Flex,
	Img,
	Spinner,
	Text,
} from '@chakra-ui/react';
import SettingsStore from '../store/SettingsStore';
import { web3wallet } from '../utils/WalletConnectUtil';
import { useModalStore } from '../store/ModalStore';
import { MpcWallet } from '@/app-cores/mpc-wallet/wallet';
import { useWallet } from '@/app-hooks/wallet';
import { getHostName } from '../utils/HelperUtil';
import { TransactionItemInfo } from '../components/TransactionItemInfo';

export default function AuthRequestModal() {
	const { data, open, onClose } = useModalStore();
	const { address } = useWallet();
	const [isLoadingApprove, setIsLoadingApprove] = useState(false);
	const [isLoadingReject, setIsLoadingReject] = useState(false);
	// Get request and wallet data from store
	const request = data?.request;
	// Ensure request and wallet are defined
	if (!request) {
		return <div>Missing request data</div>;
	}
	const requestSession = data?.requestSession;
	const { peer } = requestSession;

	const iss = `did:pkh:eip155:1:${address}`;

	// Get required request data
	const { params } = request;

	const message = web3wallet.formatMessage(params.cacaoPayload, iss);

	// Handle approve action (logic varies based on request method)
	const onApprove = useCallback(async () => {
		if (request) {
			setIsLoadingApprove(true);
			const signature = await MpcWallet.signMessage(message);
			await web3wallet.respondAuthRequest(
				{
					id: request.id,
					signature: {
						s: signature,
						t: 'eip191',
					},
				},
				iss,
			);
			setIsLoadingApprove(false);
			onClose();
		}
		// eslint-disable-next-line
	}, [request, address, message, iss]);

	// Handle reject action
	const onReject = useCallback(async () => {
		if (request) {
			setIsLoadingReject(true);
			await web3wallet.respondAuthRequest(
				{
					id: request.id,
					error: getSdkError('USER_REJECTED'),
				},
				iss,
			);
			setIsLoadingReject(false);
			onClose();
		}
		// eslint-disable-next-line
	}, [request, iss]);

	return (
		<Drawer isOpen={open} placement="bottom" onClose={onClose} size="full" trapFocus={false}>
			<DrawerOverlay />
			<DrawerContent>
				<DrawerHeader>
					<DrawerHeader borderBottomWidth="1px" borderColor="rgba(0, 0, 0, 0.08)">
						<Center>
							<Text fontSize={'16px'} fontWeight={'500'}>
								Signature request
							</Text>
						</Center>
					</DrawerHeader>
				</DrawerHeader>
				<DrawerBody pt={0}>
					<Flex flexDirection={'column'} alignItems={'center'} gap={'20px'}>
						<Center border="1px solid rgba(0, 0, 0, 0.08)" gap={2} borderRadius={52} px={2} py={1}>
							<Img width={8} height={8} src={peer?.metadata?.icons?.[0]} alt="" borderRadius="100%" />
							<Text fontSize="sm">{getHostName(peer?.metadata?.url)}</Text>
						</Center>
						<Text textAlign={'center'} fontSize={'24px'} lineHeight={'30px'}>
							You are signing
						</Text>
						<Card padding={'16px'} width={'100%'} background={'gray.100'} gap={'8px'}>
							<Flex justifyContent={'space-between'} flexDirection="column">
								<TransactionItemInfo title="Messages" value={message} />
							</Flex>
						</Card>
					</Flex>
				</DrawerBody>

				<DrawerFooter display="flex" flexDirection="column" gap={2}>
					<Button size="lg" fontWeight="medium" width="100%" onClick={onReject}>
						{isLoadingReject ? <Spinner size="sm" /> : 'Deny'}
					</Button>
					<Button size="lg" fontWeight="medium" colorScheme="cyan" width="100%" onClick={onApprove}>
						{isLoadingApprove ? <Spinner size="sm" color="white" /> : 'Approve'}
					</Button>
				</DrawerFooter>
			</DrawerContent>
		</Drawer>
	);
}
