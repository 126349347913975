import { ButtonSecondary, CTAButton, InputSearch, Loading, Toast } from '@/app-components/common';
import {
	Card,
	Flex,
	HStack,
	Image,
	List,
	ListItem,
	Text,
	VStack,
	useDisclosure,
	Avatar,
	Divider,
	ButtonProps,
	Tag,
	Center,
	Spinner,
} from '@chakra-ui/react';
import React, { CSSProperties, ReactNode, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useNavigate, useParams } from 'react-router';
import { formatNumber, formatUnits, formatUsd } from '@/app-helpers/number';
import { colors } from '@/app-theme/theme';

import { FiMoreIcon } from '@/assets/icons';
import { useRequestFundStore } from '@/app-store/request-fund';
import { RequestFundLayout, UserInfoRequestFund } from '@/app-views/request-fund';

import { useInviteFriend } from '@/app-views/account-setting/pages/InviteCode/InviteCode';
import { useQueryGetRequestFundById, useUpdateRequestPayment } from '@/app-hooks/api/payment/useClaimFund';
import { UserRequestPayment } from '@/app-cores/api';
import dayjs from 'dayjs';
import { useSharingStore } from '@/app-components/common/Sharing';
import { Actions } from '@/app-features/app-bot-connector/hooks';
import { TelegramCore, toStartParam } from '@/app-cores/telegram';
import { toast } from 'react-toastify';
import { parseErrorMessage } from '@/app-helpers/error-handling';
import ConfirmModal from '@/app-components/common/ConfirmModal';

const UserItem = ({
	data,
	amount,
	isMyCreatedRequest,
	onInvite,
	onRemind,
	onMarkRequest,
}: {
	data: UserRequestPayment;
	amount: ReactNode;
	isMyCreatedRequest: boolean;
	onInvite: () => void;
	onRemind: () => void;
	onMarkRequest: (data: UserRequestPayment) => void;
}) => {
	const { isOpen, onClose, onOpen } = useDisclosure();
	const { isPaid, username, firstName, lastName, telegramId } = data;
	const { reset } = useRequestFundStore();
	const { t } = useTranslation();
	const isWalletCreated = data.hasWallet;
	const btnProps: Partial<ButtonProps> = {
		width: 'fit-content',
		size: 'sm',
		background: colors.gray[100],
		lineHeight: 'unset',
	};

	useEffect(() => {
		reset();
	}, [reset]);

	const [loading, setLoading] = useState(false);

	const onChangeStatus = async () => {
		setLoading(true);
		onClose();
		await onMarkRequest(data);
		setLoading(false);
	};

	const name = firstName || lastName ? `${firstName} ${lastName}` : username;

	return (
		<Card p={3} display={'flex'} gap={3}>
			<Flex p={0} align={'center'} gap={4} justifyContent={'space-between'}>
				<UserInfoRequestFund data={data} maxLengthUsername={22} />
				<HStack spacing={4}>
					{amount}
					{/* <FiMoreIcon style={{ transform: 'rotate(90deg)' }} onClick={onOpen} /> */}
				</HStack>
				<ConfirmModal
					onClose={onClose}
					isOpen={isOpen}
					rejectBtnLabel={t('button.cancel')}
					onOk={onChangeStatus}
				>
					<Center fontSize={'14px'} mt={4}>
						{!isPaid
							? t('requestPayment.confirmMarkAsPaid', { name })
							: t('requestPayment.confirmMarkAsUnPaid', { name })}
					</Center>
				</ConfirmModal>
			</Flex>
			<>
				<Divider borderBottom={`1px dotted ${colors.gray[200]}`} />
				<Flex gap={2} justify={'flex-end'}>
					{!isWalletCreated && isMyCreatedRequest ? (
						<ButtonSecondary {...btnProps} onClick={onInvite}>
							Send Invitation
						</ButtonSecondary>
					) : (
						!isPaid &&
						isMyCreatedRequest && (
							<ButtonSecondary {...btnProps} onClick={onRemind}>
								{t('requestPayment.sendRemind')}
							</ButtonSecondary>
						)
					)}
					{isMyCreatedRequest && (
						<ButtonSecondary {...btnProps} minW={isPaid ? '130px' : '110px'} onClick={onOpen}>
							{loading ? (
								<Spinner size={'sm'} />
							) : isPaid ? (
								t('requestPayment.unMarkAsPaid')
							) : (
								t('requestPayment.markAsPaid')
							)}
						</ButtonSecondary>
					)}
				</Flex>
			</>
		</Card>
	);
};

const RequestFundDetail = () => {
	const { t } = useTranslation();
	const { onInvite } = useInviteFriend();
	const { onOpen } = useSharingStore();

	const { requestId } = useParams();
	const { data, isFetched, error } = useQueryGetRequestFundById(requestId);
	const { payers = [], createdAt, id, message, creatorId } = data || {};

	const { mutateAsync } = useUpdateRequestPayment();
	const onMarkRequest = async (user: UserRequestPayment) => {
		const { isPaid, telegramId } = user;
		try {
			await mutateAsync({ id, isPaid: !isPaid, telegramId });
			toast(
				<Toast
					type="success"
					message={t(isPaid ? 'requestPayment.markAsUnpaidSuccess' : 'requestPayment.markAsPaidSuccess')}
				/>,
			);
		} catch (error) {
			console.log('update request error', error);
			toast(<Toast type="error" message={parseErrorMessage(error)} />);
		}
	};

	if (!isFetched) return <Loading />;

	const unpaidFriends = payers.filter((e) => !e.isPaid);
	const paidFriends = payers.filter((e) => e.isPaid);

	const totalUsd = payers.reduce((rs, item) => rs + +item.amount, 0);
	const totalUnpaid = unpaidFriends.length;

	const onRemind = (amountUsd: string) => {
		const startParams = toStartParam({
			act: Actions.SEND,
			requestId: id,
			amountUsd,
			teleId: TelegramCore.getUserId(),
		});
		onOpen({
			link: `https://t.me/${import.meta.env.VITE_BOT_USERNAME}/wallet?startapp=${startParams}`,
			message: t('requestPayment.remindMessage'),
		});
	};

	const renderListFriend = (msg, list: UserRequestPayment[]) => {
		if (!list.length) return null;
		return (
			<VStack fontSize={'14px'} spacing={2} color={colors.gray[400]} align={'flex-start'}>
				<Text>{msg}</Text>
				<Flex gap={4} direction={'column'} w={'100%'}>
					{list?.map((el, i) => {
						return (
							<UserItem
								key={el.telegramId}
								data={el}
								amount={
									<Text
										fontSize={'14px'}
										fontWeight={'600'}
										color={el.isPaid ? colors.green[200] : colors.orange[100]}
									>
										{formatUsd(el.amount)} {!el.isPaid ? 'pending' : 'paid'}
									</Text>
								}
								onInvite={onInvite}
								onRemind={() => onRemind(el.amount)}
								onMarkRequest={onMarkRequest}
								isMyCreatedRequest={creatorId === TelegramCore.getUserId()?.toString()}
							/>
						);
					})}
				</Flex>
			</VStack>
		);
	};

	return (
		<RequestFundLayout title={!error ? dayjs(createdAt).format('MM-DD-YYYY HH:mm') : ''}>
			<Flex direction={'column'} w={'100%'} gap={4} py={4}>
				{error ? (
					<Center>Not found your request</Center>
				) : (
					<>
						<Flex align={'center'} flexDirection={'column'} gap={2}>
							<Text fontSize={'28px'} fontWeight={'bold'}>
								{formatUsd(totalUsd)}
							</Text>
							<Tag
								background={!totalUnpaid ? colors.green[100] : colors.orange[8]}
								color={!totalUnpaid ? colors.green[200] : colors.orange[100]}
							>
								{!totalUnpaid
									? `${payers.length}/${payers.length} paid`
									: `${totalUnpaid}/${payers.length} pending`}
							</Tag>
						</Flex>

						{message && (
							<Card
								fontSize={'12px'}
								borderRadius={'12px'}
								textAlign={'center'}
								px={3}
								py={2}
								boxShadow={'none'}
							>
								{message}
							</Card>
						)}

						<Divider borderBottom={`1px dotted ${colors.gray[200]}`} />

						{renderListFriend(`${unpaidFriends.length} people not paid`, unpaidFriends)}

						{paidFriends.length > 0 && unpaidFriends.length > 0 && (
							<Divider borderBottom={`1px dotted ${colors.gray[200]}`} />
						)}

						{renderListFriend(`${paidFriends.length} people paid`, paidFriends)}
					</>
				)}
			</Flex>
		</RequestFundLayout>
	);
};

export default RequestFundDetail;
