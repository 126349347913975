import { create } from 'zustand';
const initializeData = {
	recoveryFile: null,
};
export interface IRecoveryFileStore {
	recoveryFile: File;
	setRecoveryFile: (file: File) => void;
	reset: () => void;
}

export const useRecoveryWalletStore = create<IRecoveryFileStore>((set) => ({
	...initializeData,
	setRecoveryFile: (file) => set({ recoveryFile: file }),
	reset: () => set(initializeData),
}));
