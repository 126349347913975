import { QUERY_KEYS } from '@/app-constants';
import { BalanceResponse } from '@/app-cores/api/bff';
import { queryClient } from '@/app-cores/query-client';

export const getCachedPortfolioBalance = () => {
	const cached = queryClient.getQueryCache().find({
		queryKey: [QUERY_KEYS.GET_PORTFOLIO_BALANCE],
	});
	const response = cached?.state?.data as BalanceResponse;
	return response;
};

export const getCachedFeatureFlags = () => {
	const cached = queryClient.getQueryCache().find({
		queryKey: [QUERY_KEYS.GET_FLAGS],
	});
	const response = (cached?.state?.data as Record<string, boolean>) ?? {};
	return response;
};
