import { Block, Toast } from '@/app-components/common';
import { CheckCircleIcon } from '@/assets/images/svg/check-circle-icon';
import {
	Avatar,
	Button,
	Center,
	Drawer,
	DrawerBody,
	DrawerCloseButton,
	DrawerContent,
	DrawerFooter,
	DrawerOverlay,
	List,
	ListItem,
	Spinner,
	Text,
} from '@chakra-ui/react';
import { useState } from 'react';
import { toast } from 'react-toastify';
import { StoreSession, useConnectionManager } from '../hooks';
import { getHostName } from '../utils/HelperUtil';
import { useTranslation } from 'react-i18next';

interface PopupConfirmProps {
	session: StoreSession;
	isOpen: boolean;
	onClose: () => void;
}
export const ConfirmDisconnectPopup: React.FC<PopupConfirmProps> = ({ isOpen, session, onClose }) => {
	const [loading, setLoading] = useState(false);
	const { disconnect } = useConnectionManager();
	const { t } = useTranslation();
	const handleDisconnect = async () => {
		try {
			setLoading(true);
			await disconnect(session);
			toast(
				<Toast
					type="success"
					message={t('walletConnect.disconnectDappSuccess', {
						dapp: session.metadata.name,
					})}
					title="Success"
				/>,
			);
			onClose();
		} catch (error) {
			console.error('disconnect error:', error);
			toast(
				<Toast
					type="error"
					message={t('walletConnect.disconnectDappFailed', {
						dapp: session.metadata.name,
					})}
				/>,
			);
		} finally {
			setLoading(false);
		}
	};
	return (
		<Drawer isOpen={isOpen} placement="bottom" onClose={onClose} trapFocus={false}>
			<DrawerOverlay />
			<DrawerContent>
				<DrawerCloseButton />
				<Center gap={2} pt={4}>
					<Avatar
						name={session.metadata.name}
						src={session.metadata.icons[0]}
						border="1px solid gray"
						size="lg"
					/>
				</Center>
				<Text textAlign="center" mt={4}>
					{session.metadata.name}
				</Text>

				<Text
					mt={2}
					textAlign="center"
					as="a"
					display="block"
					fontSize="xs"
					href={session.metadata.url}
					target="_blank"
					color="gray"
				>
					{getHostName(session.metadata.url)}
				</Text>

				<DrawerBody mt={3}>
					<Block>
						<Text fontSize="xs" fontWeight={500} mb={5}>
							{t('walletConnect.authorizedPermissions')}
						</Text>

						<List>
							<ListItem display="flex" alignItems="center" gap={2}>
								<CheckCircleIcon className="me-1" />
								<Text fontSize="sm">{t('walletConnect.viewYourBalanceActivities')}</Text>
							</ListItem>
							<ListItem display="flex" alignItems="center" gap={2} mt={3}>
								<CheckCircleIcon className="me-1" />
								<Text fontSize="sm">{t('walletConnect.sendApprovalRequests')}</Text>
							</ListItem>
						</List>
					</Block>
				</DrawerBody>

				<DrawerFooter display="flex" flexDirection="column" gap={2}>
					<Button size="lg" fontWeight="medium" width="100%" colorScheme="red" onClick={handleDisconnect}>
						{loading ? <Spinner size="sm" /> : t('walletConnect.disconnectThisDApp')}
					</Button>
				</DrawerFooter>
			</DrawerContent>
		</Drawer>
	);
};
