import { ButtonSecondary, CTAButton } from '@/app-components/common';
import {
	ButtonProps,
	Center,
	Drawer,
	DrawerBody,
	DrawerCloseButton,
	DrawerContent,
	DrawerFooter,
	DrawerHeader,
	DrawerOverlay,
	Flex,
	Text,
} from '@chakra-ui/react';
import { ReactNode, useState } from 'react';
import { useTranslation } from 'react-i18next';
export default function ConfirmModal({
	onOk,
	onReject,
	onClose,
	isOpen,
	children,
	title,
	okBtnLabel,
	rejectBtnLabel,
	okBtnProps,
	action = true,
}: {
	onOk?: () => void;
	onReject?: () => void;
	onClose: () => void;
	isOpen: boolean;
	children: ReactNode;
	title?: string;
	okBtnLabel?: string;
	rejectBtnLabel?: string;
	okBtnProps?: ButtonProps;
	action?: boolean;
}) {
	const { t } = useTranslation();
	return (
		<Drawer placement={'bottom'} onClose={onClose} isOpen={isOpen} autoFocus={false}>
			<DrawerOverlay />
			<DrawerContent>
				<DrawerCloseButton />
				<DrawerHeader borderBottomWidth="1px">{title || 'Confirm'}</DrawerHeader>
				<DrawerBody>{children}</DrawerBody>
				{action && (
					<DrawerFooter display="flex" gap={2}>
						<ButtonSecondary
							height="48px"
							fontWeight="medium"
							fontSize={'14px'}
							flex={1}
							onClick={onReject || onClose}
						>
							{rejectBtnLabel || t('button.close')}
						</ButtonSecondary>
						<CTAButton
							colorScheme={'cyan'}
							height="48px"
							fontWeight="medium"
							fontSize={'14px'}
							flex={1}
							{...okBtnProps}
							onClick={onOk}
						>
							{okBtnLabel || t('button.confirm')}
						</CTAButton>
					</DrawerFooter>
				)}
			</DrawerContent>
		</Drawer>
	);
}
