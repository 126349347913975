import { AuthClient } from '@/app-cores/auth';
import { MpcWallet } from '@/app-cores/mpc-wallet/wallet';
import axios, { AxiosError, AxiosInstance, AxiosRequestConfig, CreateAxiosDefaults } from 'axios';

let pendingPromise: any = null;

interface AxiosRequestConfigCustom extends AxiosRequestConfig {
	isRetry: boolean;
}

export const handleErrorWithRefreshToken = async (axiosInstance: AxiosInstance, error: AxiosError) => {
	const originalRequest = error.config as AxiosRequestConfigCustom;
	// auto refresh token and retry
	const accessToken = await AuthClient.authHeader();
	if (error?.response?.status === 401 && !originalRequest.isRetry && accessToken) {
		originalRequest.isRetry = true;
		if (!pendingPromise) {
			pendingPromise = await AuthClient.verifyAndRefreshToken();
		}
		try {
			await pendingPromise;
			await MpcWallet.initialize(() => AuthClient.authHeader());
			pendingPromise = null;
			if (originalRequest.headers) {
				originalRequest.headers['Authorization'] = await AuthClient.authHeader();
			}
			return axiosInstance(originalRequest);
		} catch (error) {
			pendingPromise = null;
			throw error;
		}
	}
	return Promise.reject(error);
};

const createAxiosInstance = (option: CreateAxiosDefaults) => {
	const instance = axios.create(option);

	// Attach access token on each request
	instance.interceptors.request.use(async (config) => {
		config.headers.Authorization = await AuthClient.authHeader();
		return config;
	}, null);

	instance.interceptors.response.use(
		(response) => response,
		(error: AxiosError) => handleErrorWithRefreshToken(instance, error),
	);

	return instance;
};

export const axiosWalletConnectServiceAPI = createAxiosInstance({
	baseURL: import.meta.env.VITE_WALLET_CONNECT_SERVICE_API ?? 'https://walletconnect-dev.tobiwallet.dev',
	timeout: 20000,
});

const { VITE_BOT_API, VITE_LOCAL_BOT_API, VITE_USE_LOCAL_BOT, DEV } = import.meta.env;

export const BOT_BASE_API_URL = DEV && VITE_USE_LOCAL_BOT === '1' ? VITE_LOCAL_BOT_API : VITE_BOT_API;

export const axiosBotAPI = createAxiosInstance({
	baseURL: BOT_BASE_API_URL,
	timeout: 20000,
});

export const TON_CENTER_BASE_API_URL = import.meta.env.VITE_TON_CENTER_BASE_API_URL || 'https://toncenter.com/api/v2';
export const TON_CENTER_API_KEY = import.meta.env.VITE_TON_CENTER_API_KEY;

export const axiosTonCenterServiceAPI = axios.create({
	baseURL: TON_CENTER_BASE_API_URL,
	timeout: 20000,
	headers: {
		'X-Api-Key': TON_CENTER_API_KEY,
	},
});
export const axiosTonXServiceAPI = axios.create({
	baseURL: 'https://try.readme.io/https://mainnet-rpc.tonxapi.com/v2/json-rpc/caa4e740-a341-49a5-8dfc-46e2903e00c6',
	timeout: 20000,
});

export const axiosTonCenterService = createAxiosInstance({
	baseURL: `${BOT_BASE_API_URL}/trading/toncenter`,
	timeout: 20000,
});
export const axiosTonXService = createAxiosInstance({
	baseURL: `${BOT_BASE_API_URL}/trading/tonX`,
	timeout: 20000,
});

export const axiosInfuraService = createAxiosInstance({
	baseURL: `${BOT_BASE_API_URL}/trading/infura`,
	timeout: 20000,
});

export const blowFishService = createAxiosInstance({
	baseURL: `${BOT_BASE_API_URL}/trading/blowfish`,
	timeout: 20000,
});

export const tonAPIService = createAxiosInstance({
	baseURL: `${BOT_BASE_API_URL}/trading/tonapi`,
	timeout: 60000,
});
