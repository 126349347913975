import { useEffect, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import { isEmpty } from 'lodash-es';
import { useContactStore, useSentTokenStore } from '@/app-store';
import { ConfirmSentErc20Asset } from './ConfirmSentErc20Asset';
import { ConfirmSendTonAsset } from './ConfirmSentTonAsset';
import { getTokenInfo, isSolanaChain, isTonChain } from '@/app-helpers/token';
import { ConfirmSendSolAsset } from './ConfirmSendSolAsset';
import { usePortfolioBalance, useTokenBalance } from '@/app-hooks/api/portfolio/usePortfolioBalance';
import { useQueryContactByWalletAddress } from '@/app-hooks/api/accouts';
import { Loading } from '@/app-components/common';
import { useTokenPriceSingleToken } from '@/app-hooks/api/portfolio/useTokenPrices';
import { calcAmount } from '@/app-hooks/swap/helper';

const BotConfirmTransaction = () => {
	const [searchParams] = useSearchParams();
	const { setAmount, setMessage, setTokenSent, token, setUsdAmount, tokenInfo } = useSentTokenStore();
	const { setAddress, setContact } = useContactStore();
	const { to, token: tokenAddress, chainId, amount, message } = Object.fromEntries(searchParams.entries());
	const { data: contact, isLoading: isFetchingContact } = useQueryContactByWalletAddress(to);
	const { data: tokenPriceUsd = 0 } = useTokenPriceSingleToken({
		chainId: chainId,
		address: tokenAddress,
	});

	const { data: findToken, isFetching } = useTokenBalance({ tokenAddress, chainId });

	useEffect(() => {
		if (isEmpty(findToken)) return;
		setAmount(amount);
		setMessage(message);
		setTokenSent(findToken);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [findToken]);

	useEffect(() => {
		if (!tokenPriceUsd || isEmpty(findToken)) return;
		const data = calcAmount({
			amountUsd: +amount * tokenPriceUsd,
			token: findToken,
		});
		setUsdAmount(data.amountUsd);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [tokenPriceUsd, findToken]);

	useEffect(() => {
		if (isEmpty(contact) && !isFetchingContact) return setAddress(to);
		setContact(contact);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [contact, isFetchingContact]);

	if (isFetching || isFetchingContact || isEmpty(token)) return <Loading />;
	if (isTonChain(tokenInfo?.chainId)) return <ConfirmSendTonAsset source="bot" />;
	if (isSolanaChain(tokenInfo?.chainId)) return <ConfirmSendSolAsset source="bot" />;
	return <ConfirmSentErc20Asset source="bot" />;
};
export default BotConfirmTransaction;
