import { useGlobalToast } from '@/app-store';
import truncate from 'lodash/truncate';
import i18n from 'i18next';

const matchPatterns = (patterns: string[], error: string) =>
	patterns.some((pattern) => error.toLowerCase().includes(pattern.toLowerCase()));

// error by message
function parseKnownPattern(text: string): string | undefined {
	const error = text?.toLowerCase?.() || '';

	if (
		matchPatterns(
			[
				'mintotalamountout',
				'err_limit_out',
				'return amount is not enough',
				'code=call_exception',
				'none of the calls threw an error',
				'failed to swap with aggr',
				'router: expired',
			],
			error,
		)
	)
		return i18n.t('errors.swap.rateStale');

	if (matchPatterns(['slippage'], error)) return i18n.t('errors.swap.slippageError');

	if (
		matchPatterns(
			['insufficient funds for intrinsic transaction cost', 'OutOfFund', 'insufficient balance for transfer'],
			error,
		)
	)
		return i18n.t('errors.common.notEnoughGas');

	if (matchPatterns(['max fee per gas less than block base fee'], error))
		return i18n.t('errors.common.feeLessThanBaseFee');

	if (matchPatterns(['eip-1559 transaction do not support gasPrice'], error))
		return i18n.t('errors.common.notSupportGasPrice');

	if (matchPatterns(['invalid value for value.data'], error)) return i18n.t('errors.common.txsDataInvalid');

	if (['[object Object]', 'null'].includes(error)) return i18n.t('errors.common.general');
}

export const parseErrorMessage = (e: any, fallback = i18n.t('errors.common.general')): string => {
	try {
		let message =
			i18n.t(`errors.byCode.${e?.code}`, { defaultValue: '' }) ||
			e?.data?.message ||
			e?.reason ||
			e?.networkError?.result?.message ||
			e?.error?.message ||
			parseKnownPattern(typeof e === 'string' ? e : e?.message) ||
			e?.message;

		const maxLength = 300;
		if (message?.length > maxLength) message = truncate(message, { length: maxLength });
		return message || fallback;
	} catch (error) {
		console.error('parse error', error);
		return fallback;
	}
};

export function showTransactionError(error) {
	useGlobalToast.getState().addToast({
		type: 'error',
		message: parseErrorMessage(error),
	});
}

export * from './telifi-error';
