import React from 'react';

interface IProps extends React.SVGAttributes<HTMLOrSVGElement> {}

export const FiArrowRightIcon: React.FC<IProps> = ({ width = 20, height = 20, fill = 'black', style }) => {
	return (
		<svg
			width={width}
			height={width}
			style={style}
			viewBox="0 0 20 20"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M3.3335 9.99984C3.3335 9.5396 3.70659 9.1665 4.16683 9.1665H15.8335C16.2937 9.1665 16.6668 9.5396 16.6668 9.99984C16.6668 10.4601 16.2937 10.8332 15.8335 10.8332H4.16683C3.70659 10.8332 3.3335 10.4601 3.3335 9.99984Z"
				fill={fill}
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M9.41058 3.57757C9.73602 3.25214 10.2637 3.25214 10.5891 3.57757L16.4224 9.41091C16.7479 9.73634 16.7479 10.264 16.4224 10.5894L10.5891 16.4228C10.2637 16.7482 9.73602 16.7482 9.41058 16.4228C9.08514 16.0973 9.08514 15.5697 9.41058 15.2442L14.6547 10.0002L9.41058 4.75609C9.08514 4.43065 9.08514 3.90301 9.41058 3.57757Z"
				fill={fill}
			/>
		</svg>
	);
};
