import { useQuery } from '@tanstack/react-query';
import { ConnectRequest, DAppManifest } from '../types';
import { getManifest } from '../services/connectService';

export const useManifest = (params: ConnectRequest | null) => {
	const response = useQuery({
		queryKey: ['get-manifest', params],
		queryFn: async () => {
			return await getManifest(params!);
		},
	});
	return response;
};
