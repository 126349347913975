import { PaymentMethod } from '@/app-cores/api';
import { ServiceOnRampAPI } from '@/app-cores/api/bot/service-onramp';
import { useQuery } from '@tanstack/react-query';

export const useQueryPaymentMethods = () => {
	const response = useQuery({
        queryKey: ['query-payment-methods'],

        queryFn: async () => {
			return ServiceOnRampAPI.getPaymentMethods();
		},

        staleTime: 60 * 1000 * 30
    });
	return response;
};
