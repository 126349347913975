import { useTranslation } from 'react-i18next';
import { Center, Container, Text } from '@chakra-ui/react';
import PageTransition from '@/app-components/layout/PageTransition/PageTransition';
import { FiRestoreIcon } from '@/assets/icons';
import PageNavigation from '@/app-components/layout/PageWrapper/PageWrapper';
import { UploadFile } from '../../components/UploadFile';

interface RecoveryForNewTGAccountProps {}
export type AccountType = 'newAccount' | 'existingAccount';
export const RecoveryForNewTGAccount: React.FunctionComponent<RecoveryForNewTGAccountProps> = () => {
	const { t } = useTranslation();

	return (
		<PageTransition>
			<PageNavigation>
				<Container p={8} height="100vh" overflow="auto">
					<Center>
						<FiRestoreIcon />
					</Center>
					<Text as="h1" textAlign="center" fontSize="28px" fontWeight={500} my={2}>
						{t('recoveryMyAccount.title')}
					</Text>
					<Text mt={2} mb={6} textAlign="center" fontSize="xs" fontWeight={400} color="gray.400">
						{t('recoveryMyAccount.description')}
					</Text>
					<UploadFile
						showIcon
						title={t('recovery.methods.uploadFile.title')}
						description={t('recovery.methods.uploadFile.description')}
						accountType="newAccount"
					/>
				</Container>
			</PageNavigation>
		</PageTransition>
	);
};
