import { RouteObject } from 'react-router';
import { NAVIGATE_PATHS } from '@/app-constants/router';
import BotConfirmTransaction from '@/app-views/sent-token/pages/BotConfirmTransaction';

export const BotRouter: RouteObject[] = [
	{
		path: NAVIGATE_PATHS.Bot.ConfirmSendTransaction,
		element: <BotConfirmTransaction />,
	},
];
