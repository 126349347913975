import { TransactionType } from '@/app-types';
import { TransactionResponse } from 'ethers';
import { ChainId } from '../../../app-constants/chains';

export type IBalanceChange = {
	tokenAddress: string;
	amount: string;
	amountUsd?: number;
	tokenName?: string;
	tokenSymbol?: string;
	tokenDecimals?: number;
	tokenLogo?: string;
	chainId?: ChainId | string; // custom field, will have value incase tx is cross chain

	toAddress?: string;
	toName?: string; // receiver's name in Tobi
	toAvatar?: string; // receiver's logo in Tobi
	fromAddress?: string;
	fromName?: string; // sender's name in Tobi
	fromAvatar?: string; // sender's logo in Tobi
};

// from backend
export enum TxStatus {
	Waiting = 'waiting',
	Pending = 'pending',
	Submitted = 'submitted', // custom
	SubmitFailed = 'submitted_failed', // custom
	Success = 'success',
	Failed = 'failed',
}

export type TxContract = {
	address: string;
	logo?: string;
	name?: string;
	functionSelector?: string;
	functionName?: string;
};

export type TxDetailTokenApprove = {
	type: TransactionType;
	spenderAddress: string;
	spenderName?: string;
	spenderAvatar?: string;
} & IBalanceChange;

type CommonPayload = { type: TransactionType; sent: IBalanceChange[]; received: IBalanceChange[] };

export type TxDetailTokenSend = CommonPayload;

export type TxDetailTokenReceive = CommonPayload;

export type TxDetailTokenContractInteraction = {
	contracts: TxContract[]; // contract address what wallet interacts with
} & CommonPayload;

export type TxDetail =
	| TxDetailTokenApprove
	| TxDetailTokenSend
	| TxDetailTokenReceive
	| TxDetailTokenContractInteraction;

export type IActivity = {
	id: string;
	walletAddress: string;
	chainId: ChainId | string;
	hash: string;
	time: number;
	status: TxStatus;
	failReason?: string;
	gas: string;
	gasUsd: number;
	details: TxDetail[];
	memo?: string;
	isScam?: boolean;
	metadata?: Record<string, any>;

	rawTransaction?: TransactionResponse; // Just for pending activity, FE side only
	transactionType?: TransactionType; // Just for pending activity, FE side only
	crossChainTx?: IActivity;
};

export type ObserverTransaction = {
	tobiId: string;
	signatureFeeTransaction: string;
	backupFeeSignature: string;

	hash: string;
	chainId: string;
	amount: string; // fee amount
	provider: string;
	providerId: string;
	isSwapSameChain: boolean;

	affiliateCode: string;
	communityId: string;

	swapResult?: 'swapped'; // sol
	feeError?: string; // sol
	feeHash?: string; // sol
	walletPublicKey?: string; // ton
};

export interface WalletActivity {
	time: string;
	transactionType: 'swap' | 'send' | 'receive'; // Assuming there could be other transaction types
	transactionStatus: TxStatus;
	sendDetail?: SendDetail;
	swapDetail?: SwapDetail;
	receiveDetail?: SendDetail;
	transactionHash: string;
}

interface SendDetail {
	idTobi: string;
	chainId: string;
	direction: 'in' | 'out';
	amount: number;
	sender: UserDetail;
	receiver: UserDetail;
	transactionHash: string;
	gasFee: number;
}

interface UserDetail {
	userId: string;
	address: string;
	username: string;
	avatar: string;
}

interface SwapDetail {
	tokenIn: TokenDetail;
	tokenOut: TokenDetail;
	amountIn: number;
	amountOut: number;
	gasAmount: number;
	transactionHash: string;
	gasFee: number;
}

interface TokenDetail {
	idTobi: string;
	chainId: string;
}
