import React from 'react';

interface IProps extends React.SVGAttributes<HTMLOrSVGElement> {}

export const SolanaIcon: React.FC<IProps> = ({ width = 56, height = 56, ...rest }) => {
	return (
		<svg width={width} height={height} viewBox="0 0 55 56" fill="none" xmlns="http://www.w3.org/2000/svg">
			<g clipPath="url(#clip0_8601_5507)">
				<path
					d="M27.2319 0.0556641H27.2764C42.2951 0.0556641 54.4748 12.2354 54.4748 27.2541V28.7682C54.4748 43.7869 42.2951 55.9666 27.2764 55.9666H27.2319C12.2132 55.9666 0.0334473 43.7869 0.0334473 28.7682V27.2541C0.0334473 12.2354 12.2132 0.0556641 27.2319 0.0556641Z"
					fill="#232323"
				/>
				<path
					d="M38.8883 21.0975C38.7993 21.1977 38.6879 21.2757 38.5655 21.3202C38.443 21.3759 38.3094 21.3981 38.1758 21.3981H12.9591C12.0684 21.3981 11.612 20.2848 12.2354 19.6168L16.377 15.2192C16.4661 15.119 16.5774 15.0411 16.711 14.9854C16.8335 14.9297 16.9671 14.9075 17.1007 14.9075H42.4176C43.3193 14.9075 43.7647 16.0319 43.1301 16.6999L38.8883 21.0975ZM38.8883 40.7588C38.6991 40.948 38.443 41.0594 38.1758 41.0594H12.9591C12.0684 41.0594 11.612 39.9683 12.2354 39.3226L16.377 35.0252C16.4661 34.925 16.5885 34.847 16.711 34.8025C16.8335 34.7468 16.9671 34.7246 17.1007 34.7246H42.4176C43.3193 34.7246 43.7647 35.8268 43.1301 36.4725L38.8883 40.7588ZM38.8883 25.1166C38.6991 24.9274 38.443 24.816 38.1758 24.816H12.9591C12.0684 24.816 11.612 25.9071 12.2354 26.5528L16.377 30.8502C16.4661 30.9504 16.5885 31.0283 16.711 31.0729C16.8335 31.1285 16.9671 31.1508 17.1007 31.1508H42.4176C43.3193 31.1508 43.7647 30.0486 43.1301 29.4029L38.8883 25.1166Z"
					fill="url(#paint0_linear_8601_5507)"
				/>
			</g>
			<defs>
				<linearGradient
					id="paint0_linear_8601_5507"
					x1="13.8725"
					y1="41.3857"
					x2="41.4199"
					y2="14.5661"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#CF41E8" />
					<stop offset="1" stopColor="#10F2B0" />
				</linearGradient>
				<clipPath id="clip0_8601_5507">
					<rect width="54.5527" height="56" fill="white" />
				</clipPath>
			</defs>
		</svg>
	);
};
