import { axiosBotAPI } from '@/app-cores/api/axios';
export const ServiceEmail = {
	sendEmailBackupKey: async (payload: FormData): Promise<any> => {
		const response = await axiosBotAPI.post('/user/key-share/backup-email', payload);
		const { data } = response.data;
		return data;
	},

	sendVerifyCode: async (payload: { email: string; action: string }): Promise<any> => {
		const response = await axiosBotAPI.post('/auth/verify-code', payload);
		return response.data;
	},

	verifyCode: async (payload: { code: string; action: string }): Promise<any> => {
		const response = await axiosBotAPI.put('/auth/verify-code', payload);
		return response.data;
	},
	sendBackupKeyToUsersTG: async (payload: FormData): Promise<any> => {
		const response = await axiosBotAPI.post('/user/key-share/backup-telegram', payload);
		const { data } = response.data;
		return data;
	},
};
