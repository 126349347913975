import { useEffect, useState } from 'react';
import { isAndroid, isMobile } from 'react-device-detect';
import { toast } from 'react-toastify';
import { Contract, ethers, formatUnits } from 'ethers';
import { EthereumProvider } from '@walletconnect/ethereum-provider';
import { EventsCtrl, ExplorerCtrl, CoreUtil } from '@walletconnect/modal-core';
import { useTranslation } from 'react-i18next';
import { FiWalletConnectIcon } from '@/assets/icons';
import { EVM_CHAINS } from '@/app-constants/chains';
import { getShortAddress, isNativeToken } from '@/app-helpers/address';
import { MpcWallet } from '@/app-cores/mpc-wallet/wallet';
import { getSendBalance } from '@/app-helpers/token';
import { parseErrorMessage } from '@/app-helpers/error-handling';
import { TelegramCore } from '@/app-cores/telegram';
import { erc20Abi } from '@/app-hooks/wallet';
import { DATADOG_ACTIONS, DATADOG_ERROR_TAGS, dataDogAddAction, dataDogAddError } from '@/app-services/monitor/datadog';
import { Loading, Toast } from '@/app-components/common';
import { ProviderSelection } from '@/app-features/deposit/components/ProviderSelection';
import { DepositForm } from '@/app-features/deposit/components/DepositForm';
import { QrCodeURI } from './QRModal';
import { ITokenSearch } from '@/app-cores/api/bff';
import { useDepositStore } from '@/app-features/deposit/store';
import { WALLET_UNIVERSAL_LINKS } from './walletUniversalLinks';

const optionalChains = EVM_CHAINS.map((chain) => chain.id);

const DEFAULT_TOKEN = {
	'chainId': '1',
	'address': '0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee',
	'name': 'Ethereum',
	'symbol': 'ETH',
	'decimals': 18,
	'description':
		'Ethereum is a global, open-source platform for decentralized applications. In other words, the vision is to create a world computer that anyone can build applications in a decentralized manner; while all states and data are distributed and publicly accessible. Ethereum supports smart contracts in which developers can write code in order to program digital value. Examples of decentralized apps (dapps) that are built on Ethereum includes tokens, non-fungible tokens, decentralized finance apps, lending protocol, decentralized exchanges, and much more.\\r\\n\\r\\nOn Ethereum, all transactions and smart contract executions require a small fee to be paid. This fee is called Gas. In technical terms, Gas refers to the unit of measure on the amount of computational effort required to execute an operation or a smart contract. The more complex the execution operation is, the more gas is required to fulfill that operation. Gas fees are paid entirely in Ether (ETH), which is the native coin of the blockchain. The price of gas can fluctuate from time to time depending on the network demand.',
	'logo': 'https://assets.coingecko.com/coins/images/279/large/ethereum.png?1696501628',
	'isErc20': false,
	'cgkId': 'ethereum',
	'isIndexed': true,
	'trendingWeight': 9,
	'featuredWeight': 6,
	'isNative': true,
	'createdAt': '1709954276',
	'last24hPriceUsd': '3312.230000000000000000000000000000',
} as unknown as ITokenSearch;

// TODO: Workaround for android bug on telegram mini app
if (isAndroid) {
	CoreUtil.isAndroid = (): boolean => false;
	CoreUtil.openHref = (href: string, target: '_blank' | '_self') => {
		console.log('href:', href);
		const universalLink = WALLET_UNIVERSAL_LINKS[href] || href;
		console.log('wallet selected  universal link', universalLink);
		window.open(universalLink);
	};
}

export const WalletConnect = () => {
	const [isConnecting, setIsConnecting] = useState(false);
	const [provider, setProvider] = useState(undefined);
	const {
		depositAssetsType,
		setDepositAssetType,
		token,
		initToken,
		tokenInfo,
		setToken,
		state,
		setValue,
		setErrorMessage,
		disconnectingType,
		setDisconnectingType,
		setAmountDeposited,
		setShowDepositResult,
	} = useDepositStore();
	const [uri, setUri] = useState('');
	const [isInitializing, setIsInitializing] = useState(true);
	const [isDepositLoading, setIsDepositLoading] = useState(false);
	const { t } = useTranslation();
	const receiverAddress = MpcWallet.getWalletAddress().evmAddress;
	useEffect(() => {
		async function initProvider() {
			const provider = await EthereumProvider.init({
				projectId: import.meta.env.VITE_WALLET_CONNECT_DAPP_PROJECT_ID,
				chains: [1],
				optionalChains: optionalChains as number[],
				methods: ['personal_sign', 'eth_sendTransaction'],
				showQrModal: isMobile,
				qrModalOptions: {
					themeMode: 'light',
					themeVariables: {
						'--wcm-z-index': '100000',
					},
					explorerExcludedWalletIds: [
						'4622a2b2d6af1c9844944291e5e7351a6aa24cd7b23099efac1b2fd875da31a0',
						'fa977f7d37f533bd283e44268d020c6852433f091f5373dd33ae7dc0d4522e9a',
					],
					explorerRecommendedWalletIds: [
						'c57ca95b47569778a828d19178114f4db188b89b763c899ba0be274e97267d96',
						'1ae92b26df02f0abca6304df07debccd18262fdf5fe82daa81593582dac9a369',
						'225affb176778569276e484e1b92637ad061b01e13a048b35a9d280c3b58970f',
						'c03dfee351b6fcc421b4494ea33b9d4b92a984f87aa76d1663bb28705e95034a',
						'ecc4036f814562b41a5268adc86270fba1365471402006302e70169465b7ac18',
						'ef333840daf915aafdc4a004525502d6d49d77bd9c65e0642dbaefb3c2893bef',
						'bc949c5d968ae81310268bf9193f9c9fb7bb4e1283e1284af8f2bd4992535fd6',
					],
				},
				metadata: {
					name: 'Tobi Token Bot',
					description: 'Wallet for WalletConnect',
					url: 'https://app.tobiwallet.app/',
					icons: ['https://app.tobiwallet.app/icons/favicon.png'],
					redirect: {
						native: 'tg://',
						universal: import.meta.env.VITE_TELEGRAM_BOT_URL,
					},
				},
			});
			setProvider(provider);
			setIsInitializing(false);
		}
		initProvider();
	}, []);

	useEffect(() => {
		if (!provider) return;
		provider.on('display_uri', (uri) => {
			setUri(uri);
			setIsConnecting(false);
		});
	}, [provider]);

	useEffect(() => {
		if (!provider) return;
		provider.on('accountsChanged', (account: string[]) => {
			console.log('on account change', account);
			if (account?.length > 0) {
				dataDogAddAction(DATADOG_ACTIONS.DEPOSIT_WALLET_CONNECT_SUCCESS);
			}
		});
	}, [provider]);

	useEffect(() => {
		EventsCtrl.subscribe(async (event) => {
			console.log('event', event);
			const wallet = ExplorerCtrl.state?.recomendedWallets?.find(
				(wallet) => wallet.id === (event.data as any)?.walletId,
			);
			const walletUrl = wallet?.mobile?.universal;
			const name = wallet?.name;
			if (walletUrl) {
				const href = CoreUtil.formatUniversalUrl(walletUrl, uri, name!);
				CoreUtil.openHref(href, '_self');
			}
		});
	}, [uri]);

	async function onConnect() {
		try {
			setIsConnecting(true);
			dataDogAddAction(DATADOG_ACTIONS.DEPOSIT_USE_WALLET_CONNECT);
			await provider.connect();
			setDepositAssetType('Evm');
		} catch (error) {
			console.log('onConnect error:', error);
			dataDogAddError(error, {
				tags: {
					name: DATADOG_ERROR_TAGS.DEPOSIT,
					function: 'connectWithEvmWallet',
				},
			});
			throw new Error('providerClient is not initialized');
		} finally {
			setIsConnecting(false);
			setUri('');
		}
	}

	async function handleDisconnect() {
		try {
			setDisconnectingType('Evm');
			dataDogAddAction(DATADOG_ACTIONS.DEPOSIT_WALLET_CONNECT_DISCONNECT);
			await provider.disconnect();
		} catch (error) {
			console.error('disconnect error', error);
		} finally {
			setDisconnectingType(undefined);
		}
	}

	const depositNativeToken = async (signer: ethers.JsonRpcSigner) => {
		const value = getSendBalance(state.tokenValue?.toString(), tokenInfo.decimals);
		const txhash = await signer.sendTransaction({
			to: receiverAddress,
			value: value,
		});
		return {
			txhash,
			amount: formatUnits(value, tokenInfo.decimals),
		};
	};

	const depositERC20Token = async (signer: ethers.JsonRpcSigner) => {
		const erc20Contract = new Contract(tokenInfo.address, erc20Abi, signer);
		const value = getSendBalance(state.tokenValue?.toString(), tokenInfo.decimals);
		const txhash = await erc20Contract.transfer(receiverAddress, value);
		return {
			txhash,
			amount: formatUnits(value, tokenInfo.decimals),
		};
	};

	const handleDeposit = async () => {
		try {
			setIsDepositLoading(true);
			const ethersWeb3Provider = new ethers.BrowserProvider(provider);
			const signer = await ethersWeb3Provider.getSigner(provider.accounts[0]);
			const request = isNativeToken(tokenInfo.address) ? depositNativeToken(signer) : depositERC20Token(signer);
			const walletUrl = provider?.session?.peer?.metadata?.redirect?.universal;
			if (walletUrl && isMobile) {
				TelegramCore.openExternalURL(walletUrl);
			}
			const { amount } = await request;
			setAmountDeposited(amount);
			setShowDepositResult(true);
			setValue('0', 0, 0);
			setDepositAssetType(undefined);
			toast(
				<Toast
					title="Success"
					message={t('deposit.depositSuccessMessage', {
						symbol: tokenInfo.symbol,
					})}
					type="success"
				/>,
			);
			dataDogAddAction(DATADOG_ACTIONS.DEPOSIT_WALLET_CONNECT_SIGN_TRANSACTION.SUCCESS);
		} catch (error) {
			console.log('request deposit via wallet connect', error, error.message);
			dataDogAddAction(DATADOG_ACTIONS.DEPOSIT_WALLET_CONNECT_SIGN_TRANSACTION.FAIL);
			dataDogAddError(error, {
				tags: {
					name: DATADOG_ERROR_TAGS.DEPOSIT,
					function: 'handleDepositEvmAsset',
				},
			});
			const errorMsg = parseErrorMessage(error);
			if (errorMsg) {
				return setErrorMessage(errorMsg);
			}
			toast(<Toast title="Error" message={'Transaction error, please try again.'} type="error" />);
		} finally {
			setIsDepositLoading(false);
		}
	};
	const account = provider?.accounts?.[0];
	const isOpen = depositAssetsType === 'Evm';
	return (
		<>
			<ProviderSelection
				onClick={() => {
					if (isInitializing) return;
					if (!initToken) {
						setToken(DEFAULT_TOKEN);
					}
					if (!provider?.accounts[0] && !provider?.session) {
						onConnect();
					} else {
						setDepositAssetType('Evm');
					}
				}}
				icon={<FiWalletConnectIcon width={48} height={48} />}
				text={
					account
						? getShortAddress(account, {
								start: 6,
								end: 4,
						  })
						: t('deposit.walletConnect')
				}
				onDisconnect={handleDisconnect}
				isConnected={!!account}
				isDisconnecting={disconnectingType === 'Evm'}
				isInitializing={isInitializing}
			/>
			{isConnecting && <Loading backgroundColor="transparent" showSpinner />}

			{isOpen && (
				<DepositForm
					isOpen={isOpen}
					onClose={() => setDepositAssetType(undefined)}
					isLoading={isDepositLoading}
					onDeposit={handleDeposit}
					fromWallet={{
						address: account,
						logo: provider?.session?.peer?.metadata?.icons?.[0],
					}}
					toWallet={{
						address: receiverAddress,
						logo: '',
						isTobi: true,
					}}
				/>
			)}
			{uri && !isMobile && <QrCodeURI isOpen={!!uri} onClose={() => setUri('')} uri={uri} />}
		</>
	);
};
