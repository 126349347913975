export const WALLET_CONNECT_TG_CLOUD_KEY = {
	RE_INIT_EVENT: {
		KEY: 're_init_event',
		VALUE: '1',
	},
	CONNECT_IN_DAPP_BROWSER: {
		KEY: 'connect_in_dapp_browser',
		VALUE: '1',
	},
};
