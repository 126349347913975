import {
	Avatar,
	Box,
	Center,
	Drawer,
	DrawerBody,
	DrawerCloseButton,
	DrawerContent,
	DrawerHeader,
	DrawerOverlay,
	Flex,
	Grid,
	GridItem,
	Text,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { create } from 'zustand';
import { TelegramCore } from '../../../app-cores/telegram';
import { FiCopyIcon, FiSuccessIcon } from '../../../assets/icons';
import { TelegramIcon } from '../../../assets/images/svg/telegram-logo-icon';
import { CopyToClipboard } from '../CopyToClipboard';

const initializeData = {
	isOpen: false,
	payload: {
		link: '',
		message: '',
	},
};
export interface ISharingStore {
	isOpen: boolean;
	payload: { link: string; message: string };
	onOpen: (payload: { link: string; message: string }) => void;
	onClose: () => void;
}

export const useSharingStore = create<ISharingStore>((set) => ({
	...initializeData,
	onOpen: (payload) => {
		set({ isOpen: true, payload });
	},
	onClose: () => set({ isOpen: false, payload: { ...initializeData.payload } }),
}));

export const SharingDrawer: React.FC = () => {
	const { t } = useTranslation();
	const { isOpen, payload, onClose } = useSharingStore();

	const onTelegramShare = () => {
		TelegramCore.WebApp.openTelegramLink(
			`https://t.me/share/url?url=${encodeURIComponent(payload.link)}&text=${encodeURIComponent(
				payload.message,
			)}`,
		);
	};

	return (
		<Drawer placement="bottom" onClose={onClose} isOpen={isOpen}>
			<DrawerOverlay />
			<DrawerContent>
				<DrawerHeader borderBottomWidth="1px" borderBottomColor="rgba(0, 0, 0, 0.08)">
					<Center>
						<Text fontSize={'16px'} fontWeight={'500'}>
							Share
						</Text>
					</Center>
					<DrawerCloseButton />
				</DrawerHeader>
				<DrawerBody px={4} py={5} display={'flex'} flexDirection={'column'} gap={6}>
					<Grid templateColumns="repeat(2, 1fr)" gap={2}>
						<GridItem
							w="100%"
							py={6}
							display={'flex'}
							gap={2.5}
							borderRadius={'1rem'}
							cursor={'pointer'}
							flexDirection={'column'}
							alignItems={'center'}
							backgroundColor={'gray.100'}
							onClick={onTelegramShare}
						>
							<Avatar bg="blue.100" icon={<TelegramIcon />} />
							<Text fontSize={'xs'} fontWeight={510}>
								Telegram
							</Text>
						</GridItem>
						<CopyToClipboard
							text={payload.link}
							showIcon={false}
							callback={() =>
								toast(
									<Flex gap={3}>
										<Box>
											<FiSuccessIcon />
										</Box>
										<Text fontSize="xs" lineHeight="18px">
											{t('toast.linkCopied')}
										</Text>
									</Flex>,
								)
							}
						>
							<GridItem
								w="100%"
								py={6}
								display={'flex'}
								gap={2.5}
								borderRadius={'1rem'}
								cursor={'pointer'}
								flexDirection={'column'}
								alignItems={'center'}
								backgroundColor={'gray.100'}
							>
								<Avatar bg="cyan.400" icon={<FiCopyIcon fill="black" />} />
								<Text fontSize={'xs'} fontWeight={510}>
									Copy Link
								</Text>
							</GridItem>
						</CopyToClipboard>
					</Grid>
				</DrawerBody>
			</DrawerContent>
		</Drawer>
	);
};
