import { QUERY_KEYS } from '../../app-constants';
import { ChainId } from '../../app-constants/chains';
import {
	ActivitiesServiceAPI,
	IActivity,
	TxDetailTokenContractInteraction,
	TxDetailTokenSend,
	TxStatus,
} from '../../app-cores/api/activities';
import { queryClient } from '../../app-cores/query-client';
import { compareAddress } from '@/app-helpers/address';
import { ONE_DAY, ONE_HOUR } from '@/app-hooks/api/portfolio/constant';
import { MetadataEvm, TransactionWatcher, useTransactionWatcherStore } from '@/app-store/transaction-watcher';
import { useRef } from 'react';
import { useInterval } from '@chakra-ui/react';
import { SelectedRoute } from '@/app-store/swap';
import { SwapService } from '@/app-hooks/swap';
import { TransactionType } from '@/app-types';
import { TokenInfo } from '@/app-cores/api/bff';
import { getMyWalletAddressByChain, tryParseAmount } from '@/app-hooks/swap/helper';
import { truncateToFixed } from '@/app-helpers/number';
import { ChainType } from '@/app-contexts/wallet-provider/type';

export const useWatchTransactions = async (enable: boolean) => {
	const {
		pendingEvmTransactions,
		pendingSolTransactions,
		pendingTonTransactions,
		pendingTronTransactions,
		pendingSuiTransactions,
		removePendingTransaction,
	} = useTransactionWatcherStore();

	// new chain, only put here
	const transactions = [
		{ txs: pendingEvmTransactions, chain: ChainType.EVM },
		{ txs: pendingSolTransactions, chain: ChainType.SOLANA },
		{ txs: pendingTonTransactions, chain: ChainType.TON },
		{ txs: pendingTronTransactions, chain: ChainType.TRON },
		{ txs: pendingSuiTransactions, chain: ChainType.SUI },
	];

	const hasPending = transactions
		.map((e) => e.txs)
		.some((e) => {
			return !!Object.keys(e ?? {}).length;
		});

	const checking = useRef(false);
	const checkTransactions = async () => {
		if (checking.current) return;
		try {
			checking.current = true;
			const activities = await ActivitiesServiceAPI.getWalletActivities({});

			const checkSingleTxsFn = (
				{ hash, type }: { type: ChainType; hash: string },
				originTxs: TransactionWatcher<MetadataEvm | IActivity>,
			) => {
				const {
					metadata: { time },
					callbackFns,
				} = originTxs;

				const transaction =
					type === ChainType.TON
						? activities?.reverse()?.find((e) => +e.time >= time - 10) // frontend data vs on chain data may diff.
						: activities?.find((e) => compareAddress(hash, e.transactionHash));

				const callback = (status: TxStatus) => {
					[
						QUERY_KEYS.GET_TOKEN_ALLOWANCE,
						QUERY_KEYS.GET_PORTFOLIO_BALANCE,
						QUERY_KEYS.GET_WALLET_ACTIVITY,
						QUERY_KEYS.GET_TOBI_FARM_POINT,
						QUERY_KEYS.GET_NOTIFICATIONS,
					].forEach((key) =>
						queryClient.invalidateQueries({
							queryKey: [key],
						}),
					);
					callbackFns?.forEach((fn) => fn?.({ status }));
					removePendingTransaction({ hash, type });
				};

				const handlePending = () => {
					if (Date.now() - time * 1000 > ONE_HOUR * 2) {
						callback(TxStatus.Failed);
					}
					console.log('txs pending', hash);
				};

				if (!transaction || transaction.transactionStatus === TxStatus.Pending) {
					handlePending();
					return;
				}

				callback(transaction.transactionStatus);
			};

			const checkListTxs = (data: Record<string, TransactionWatcher<any>>, type: ChainType) => {
				Object.keys(data || {}).forEach((hash) => {
					checkSingleTxsFn({ hash, type }, data[hash]);
				});
			};

			transactions.map(({ txs, chain }) => checkListTxs(txs, chain));
		} catch (error) {
			console.log('watch txs err', error);
		} finally {
			checking.current = false;
		}
	};

	useInterval(checkTransactions, hasPending && enable ? 5000 : null);
};

const getCommonPayload = (chainId: ChainId | string) => ({
	id: Date.now().toString(),
	gasUsd: 0,
	walletAddress: getMyWalletAddressByChain(chainId),
	chainId,
	gas: '',
	status: TxStatus.Pending,
	time: (Date.now() / 1000) | 0,
});

export const getPendingContractInteractionTxs = ({
	contract = '',
	hash,
	chainId,
}: {
	contract?: string;
	hash: string;
	chainId: ChainId | string;
}): IActivity => {
	return {
		hash,
		...getCommonPayload(chainId),
		details: [
			{
				type: TransactionType.ContractInteraction,
				contracts: [{ address: contract }],
				sent: [],
				received: [],
			} as TxDetailTokenContractInteraction,
		],
	};
};
