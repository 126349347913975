import { MainButton, MainButtonProps } from '@vkruglikov/react-telegram-web-app';
import React from 'react';

interface TgMainButtonProps extends MainButtonProps {
	visible?: boolean;
	text?: string;
	bgColor?: string;
	textColor?: string;
	onClick?: () => void;
}

const TgMainButton: React.FC<TgMainButtonProps> = ({
	visible = true,
	bgColor,
	textColor,
	onClick,
	text,
	disabled,
	...rest
}) => {
	return (
		<>
			{visible ? (
				<MainButton
					color={bgColor || '#00E9DB'}
					textColor={textColor || '#FFFFFF'}
					onClick={() => {
						!disabled && onClick?.();
					}}
					text={text}
					disabled={disabled}
					{...rest}
				/>
			) : null}
		</>
	);
};

export default TgMainButton;
