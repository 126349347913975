import React from 'react';

interface IProps extends React.SVGAttributes<HTMLOrSVGElement> {}

export const FiRefreshKeyshareIcon: React.FC<IProps> = ({ width = 153, height = 153, ...rest }) => {
	return (
		<svg width={width} height={height} viewBox="0 0 153 153" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M112.313 88.3L107.888 83.8875C107.528 83.5509 107.055 83.3636 106.563 83.3636C106.07 83.3636 105.597 83.5509 105.238 83.8875L95.0751 94.05C94.8997 94.2232 94.7605 94.4296 94.6654 94.657C94.5704 94.8845 94.5215 95.1285 94.5215 95.375C94.5215 95.6215 94.5704 95.8656 94.6654 96.093C94.7605 96.3205 94.8997 96.5268 95.0751 96.7L99.5001 101.125C99.8514 101.477 100.328 101.675 100.825 101.675C101.322 101.672 101.797 101.474 102.15 101.125L112.313 91C112.491 90.8233 112.633 90.6131 112.729 90.3814C112.826 90.1496 112.876 89.9011 112.876 89.65C112.876 89.3989 112.826 89.1504 112.729 88.9187C112.633 88.6869 112.491 88.4767 112.313 88.3Z"
				fill="#00E9DB"
			/>
			<path
				d="M100.288 82.25L95.8625 77.8375C95.6893 77.6622 95.483 77.5229 95.2555 77.4279C95.0281 77.3329 94.784 77.2839 94.5375 77.2839C94.291 77.2839 94.047 77.3329 93.8195 77.4279C93.5921 77.5229 93.3858 77.6622 93.2125 77.8375L86 84.9875C85.6519 85.3407 85.4568 85.8166 85.4568 86.3125C85.4568 86.8084 85.6519 87.2844 86 87.6375L90.425 92.0625C90.5986 92.2372 90.805 92.3758 91.0324 92.4702C91.2599 92.5646 91.5038 92.613 91.75 92.6125C92.2469 92.6107 92.723 92.4131 93.075 92.0625L100.275 84.8625C100.614 84.5127 100.804 84.0456 100.806 83.5588C100.809 83.0719 100.623 82.603 100.288 82.25Z"
				fill="#00E9DB"
			/>
			<path
				d="M105.525 101.85L72.55 68.875C72.3574 68.6771 72.1222 68.5257 71.8622 68.4326C71.6023 68.3394 71.3244 68.3069 71.05 68.3375C70.7766 68.3636 70.5121 68.4486 70.2747 68.5867C70.0374 68.7248 69.8327 68.9127 69.675 69.1375C68.6252 70.6811 67.2936 72.0127 65.75 73.0625C65.4533 73.2647 65.2205 73.5474 65.0788 73.8773C64.9371 74.2072 64.8924 74.5706 64.95 74.925C65.014 75.3094 65.1977 75.6637 65.475 75.9375L98.5 108.913C98.8516 109.264 99.3281 109.461 99.825 109.461C100.322 109.461 100.798 109.264 101.15 108.913L105.575 104.5C105.926 104.148 106.123 103.672 106.123 103.175C106.123 102.678 105.926 102.202 105.575 101.85H105.525Z"
				fill="black"
			/>
			<path
				d="M57.5376 42.5375C53.9038 42.5499 50.355 43.6379 47.3387 45.6645C44.3224 47.6911 41.9738 50.5654 40.5889 53.925C39.204 57.2846 38.8449 60.979 39.5568 64.5424C40.2688 68.1058 42.0199 71.3787 44.5894 73.9482C47.159 76.5177 50.4318 78.2688 53.9952 78.9808C57.5586 79.6928 61.2531 79.3336 64.6126 77.9487C67.9722 76.5639 70.8465 74.2152 72.8731 71.1989C74.8997 68.1826 75.9877 64.6338 76.0001 61C76.005 58.5741 75.5309 56.1711 74.6048 53.9289C73.6787 51.6867 72.319 49.6494 70.6036 47.934C68.8882 46.2186 66.8509 44.8589 64.6087 43.9328C62.3665 43.0067 59.9635 42.5326 57.5376 42.5375ZM57.5376 67.225C56.3015 67.225 55.0931 66.8585 54.0653 66.1717C53.0375 65.4849 52.2364 64.5088 51.7634 63.3668C51.2903 62.2248 51.1665 60.9681 51.4077 59.7557C51.6488 58.5433 52.2441 57.4297 53.1182 56.5556C53.9923 55.6815 55.1059 55.0863 56.3183 54.8451C57.5307 54.6039 58.7873 54.7277 59.9294 55.2008C61.0714 55.6738 62.0475 56.4749 62.7343 57.5027C63.421 58.5305 63.7876 59.7389 63.7876 60.975C63.7909 61.7979 63.6317 62.6133 63.319 63.3745C63.0064 64.1356 62.5466 64.8276 61.9659 65.4106C61.3852 65.9936 60.6951 66.4562 59.9352 66.7719C59.1752 67.0875 58.3605 67.25 57.5376 67.25V67.225Z"
				fill="black"
			/>
			<circle
				cx="76.5"
				cy="76.5"
				r="70.5"
				transform="rotate(-90 76.5 76.5)"
				stroke="url(#paint0_angular_6529_7706)"
				strokeWidth="12"
			/>
			<defs>
				<radialGradient
					id="paint0_angular_6529_7706"
					cx="0"
					cy="0"
					r="1"
					gradientUnits="userSpaceOnUse"
					gradientTransform="translate(76.5 76.5) rotate(90) scale(76.5)"
				>
					<stop stopColor="#00E9DB" />
					<stop offset="1" stopColor="#00E9DB" stopOpacity="0.08" />
				</radialGradient>
			</defs>
		</svg>
	);
};
