import React from 'react';

interface IProps extends React.SVGAttributes<HTMLOrSVGElement> {}

export const FiFarmIcon: React.FC<IProps> = ({ width = 24, height = 24, ...rest }) => {
	return (
		<svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
			<path
				d="M21.875 6.75C21.875 6.15656 21.5304 5.64366 21.0304 5.40037C21.0915 5.23688 21.125 5.05983 21.125 4.875C21.125 4.04658 20.4534 3.375 19.625 3.375C19.4832 3.375 19.3461 3.39469 19.2161 3.43144C19.07 2.75602 18.4691 2.25 17.75 2.25C17.4825 2.25 17.2315 2.32003 17.014 2.44266C17.0055 2.44045 16.997 2.43825 16.9885 2.43609C16.9961 2.37511 17 2.31305 17 2.25C17 1.42158 16.3284 0.75 15.5 0.75C14.6716 0.75 14 1.42158 14 2.25C14 2.31305 14.0039 2.37511 14.0114 2.43614C14.003 2.4383 13.9944 2.4405 13.9859 2.4427C13.7685 2.32003 13.5175 2.25 13.25 2.25C12.5309 2.25 11.93 2.75602 11.7839 3.43144C11.6539 3.39469 11.5168 3.375 11.375 3.375C10.5466 3.375 9.875 4.04658 9.875 4.875C9.875 5.05983 9.90847 5.23688 9.96964 5.40037C9.46962 5.64366 9.125 6.15656 9.125 6.75C9.125 7.14084 9.27448 7.49681 9.51945 7.76377C9.50656 7.92412 9.5 8.08631 9.5 8.25V15.75C9.5 19.0637 12.1863 21.75 15.5 21.75C18.8137 21.75 21.5 19.0637 21.5 15.75V8.25C21.5 8.08631 21.4934 7.92412 21.4805 7.76377C21.7255 7.49681 21.875 7.14084 21.875 6.75Z"
				fill="#69A041"
			/>
			<path
				d="M14 2.25C14 2.31305 14.0039 2.37511 14.0114 2.43614C14.003 2.4383 13.9944 2.4405 13.9859 2.4427C13.7685 2.32003 13.5175 2.25 13.25 2.25C12.5309 2.25 11.93 2.75602 11.7839 3.43144C11.6539 3.39469 11.5168 3.375 11.375 3.375C10.5466 3.375 9.875 4.04658 9.875 4.875C9.875 5.05983 9.90847 5.23688 9.96964 5.40037C9.46962 5.64366 9.125 6.15656 9.125 6.75C9.125 7.14084 9.27448 7.49681 9.51945 7.76377C9.50656 7.92412 9.5 8.08631 9.5 8.25V15.75C9.5 17.4068 10.8432 18.75 12.5 18.75C14.1568 18.75 15.5 17.4068 15.5 15.75V0.75C14.6716 0.75 14 1.42158 14 2.25Z"
				fill="#91B945"
			/>
			<path
				d="M19.7349 12.4848L17.8865 14.3332C17.3981 14.8215 16.9693 15.3561 16.6026 15.9314C16.5213 15.396 16.4466 14.8597 16.3789 14.3225C16.8053 13.1295 17.4944 12.036 18.4168 11.1135L20.2652 9.26517C20.4116 9.11869 20.4116 8.88131 20.2652 8.73483C20.1187 8.58844 19.8813 8.58844 19.7349 8.73483L17.8865 10.5832C17.2122 11.2575 16.6513 12.0196 16.2115 12.8562C16.1672 12.4247 16.1272 11.9928 16.0916 11.5605C16.4499 9.98325 17.2451 8.53533 18.4168 7.36355L18.7652 7.01517C18.9116 6.86869 18.9116 6.63131 18.7652 6.48483C18.6187 6.33844 18.3813 6.33844 18.2349 6.48483L17.8865 6.8332C17.077 7.64278 16.4306 8.57888 15.9615 9.61875C15.9041 8.51217 15.875 7.40367 15.875 6.29433V4.875C15.875 4.66791 15.7071 4.5 15.5 4.5C15.2929 4.5 15.125 4.66791 15.125 4.875V6.29428C15.125 7.40363 15.0959 8.51208 15.0386 9.6187C14.5694 8.57883 13.9231 7.64273 13.1136 6.83316L12.7652 6.48478C12.6188 6.33844 12.3813 6.33839 12.2349 6.48478C12.0884 6.63127 12.0884 6.86864 12.2349 7.01513L12.5832 7.3635C13.755 8.53528 14.5501 9.98316 14.9084 11.5604C14.8728 11.9927 14.8328 12.4247 14.7885 12.8562C14.3487 12.0196 13.7878 11.2575 13.1135 10.5832L11.2652 8.73478C11.1187 8.58839 10.8813 8.58839 10.7349 8.73478C10.5884 8.88127 10.5884 9.11864 10.7349 9.26513L12.5832 11.1135C13.5056 12.0359 14.1948 13.1294 14.6211 14.3224C14.5534 14.8596 14.4787 15.396 14.3974 15.9314C14.0307 15.356 13.6019 14.8215 13.1135 14.3332L11.2652 12.4848C11.1187 12.3384 10.8813 12.3384 10.7349 12.4848C10.5884 12.6313 10.5884 12.8686 10.7349 13.0151L12.5832 14.8635C13.2506 15.5309 13.7958 16.2879 14.2078 17.1073C14.0123 18.2523 13.7858 19.3925 13.5281 20.5265L13.3409 21.3497C14.011 21.6083 14.7389 21.75 15.5 21.75C16.2611 21.75 16.9891 21.6083 17.6591 21.3498L17.472 20.5265C17.2143 19.3925 16.9878 18.2523 16.7922 17.1074C17.2042 16.2879 17.7494 15.531 18.4168 14.8635L20.2652 13.0152C20.4116 12.8687 20.4116 12.6313 20.2652 12.4848C20.1187 12.3384 19.8813 12.3384 19.7349 12.4848Z"
				fill="#DCFFA0"
			/>
			<path
				d="M19.7347 12.4848L17.8863 14.3332C17.398 14.8215 16.9691 15.3561 16.6025 15.9314C16.5212 15.396 16.4464 14.8597 16.3787 14.3225C16.8051 13.1295 17.4943 12.036 18.4167 11.1135L20.2651 9.26517C20.4115 9.11869 20.4115 8.88131 20.2651 8.73483C20.1186 8.58844 19.8812 8.58844 19.7347 8.73483L17.8863 10.5832C17.2121 11.2575 16.6512 12.0196 16.2114 12.8562C16.1671 12.4247 16.127 11.9928 16.0914 11.5605C16.4498 9.98325 17.2449 8.53533 18.4167 7.36355L18.7651 7.01517C18.9115 6.86869 18.9115 6.63131 18.7651 6.48483C18.6186 6.33844 18.3812 6.33844 18.2347 6.48483L17.8863 6.8332C17.0768 7.64278 16.4305 8.57888 15.9614 9.61875C15.904 8.51217 15.8749 7.40367 15.8749 6.29433V4.875C15.8749 4.66791 15.707 4.5 15.4999 4.5C15.2928 4.5 15.1249 4.66791 15.1249 4.875V6.29428C15.1249 11.0831 14.5892 15.8568 13.5279 20.5265L13.3408 21.3498C14.0108 21.6083 14.7388 21.75 15.4999 21.75C16.261 21.75 16.989 21.6083 17.6589 21.3498L17.4718 20.5265C17.2141 19.3925 16.9876 18.2523 16.792 17.1074C17.204 16.2879 17.7493 15.531 18.4167 14.8635L20.2651 13.0152C20.4115 12.8687 20.4115 12.6313 20.2651 12.4848C20.1186 12.3384 19.8812 12.3384 19.7347 12.4848Z"
				fill="#AFD763"
			/>
			<path
				d="M15.4999 18.028V4.5C15.2928 4.5 15.1249 4.66791 15.1249 4.875V6.29428C15.1249 10.1872 14.7709 14.0702 14.0683 17.8954C13.9867 18.3398 14.326 18.75 14.7779 18.75C15.1766 18.75 15.4999 18.4267 15.4999 18.028Z"
				fill="#DCFFA0"
			/>
			<path
				d="M8 9.375C7.79291 9.375 7.625 9.20709 7.625 9V8.625C7.625 7.59113 8.46613 6.75 9.5 6.75C9.70709 6.75 9.875 6.91791 9.875 7.125C9.875 7.33209 9.70709 7.5 9.5 7.5C8.87966 7.5 8.375 8.0047 8.375 8.625V9C8.375 9.20709 8.20709 9.375 8 9.375Z"
				fill="#4B8C41"
			/>
			<path
				d="M8 18C10.4853 18 12.5 15.9853 12.5 13.5C12.5 11.0147 10.4853 9 8 9C5.51472 9 3.5 11.0147 3.5 13.5C3.5 15.9853 5.51472 18 8 18Z"
				fill="#E1322D"
			/>
			<path d="M6.5 13.5H3.5C3.5 11.0147 5.51473 9 8 9V12C8 12.8284 7.32842 13.5 6.5 13.5Z" fill="#F04B37" />
			<path
				d="M5.75 12C6.16421 12 6.5 11.6642 6.5 11.25C6.5 10.8358 6.16421 10.5 5.75 10.5C5.33579 10.5 5 10.8358 5 11.25C5 11.6642 5.33579 12 5.75 12Z"
				fill="#F5826E"
			/>
			<path
				d="M10.625 8.625H5.375C5.16791 8.625 5 8.79291 5 9C5 9.20709 5.16791 9.375 5.375 9.375H7.09466L6.60983 9.85983C6.46334 10.0063 6.46334 10.2437 6.60983 10.3902C6.75627 10.5366 6.99369 10.5366 7.14013 10.3902L8 9.5303L8.85983 10.3902C8.93305 10.4634 9.029 10.5 9.125 10.5C9.221 10.5 9.31695 10.4634 9.39017 10.3902C9.53666 10.2437 9.53666 10.0063 9.39017 9.85983L8.90534 9.375H10.625C10.8321 9.375 11 9.20709 11 9C11 8.79291 10.8321 8.625 10.625 8.625Z"
				fill="#69A041"
			/>
			<path
				d="M4.25 23.25C3.83581 23.25 3.5 22.9142 3.5 22.5C3.5 21.816 3.76634 21.173 4.25 20.6894L4.68936 20.25C5.17292 19.7664 5.816 19.5 6.5 19.5C6.91419 19.5 7.25 19.8358 7.25 20.25C7.25 20.6642 6.91419 21 6.5 21C6.21669 21 5.95034 21.1103 5.75 21.3106L5.31064 21.75C5.11034 21.9503 5 22.2167 5 22.5C5 22.9142 4.66419 23.25 4.25 23.25Z"
				fill="#4B8C41"
			/>
			<path
				d="M6.5 18C5.816 18 5.17292 17.7336 4.68936 17.25L4.25 16.8106C3.76634 16.327 3.5 15.684 3.5 15C3.5 14.5858 3.83581 14.25 4.25 14.25C4.66419 14.25 5 14.5858 5 15C5 15.2833 5.11034 15.5497 5.31064 15.75L5.75 16.1894C5.95034 16.3897 6.21669 16.5 6.5 16.5C6.91419 16.5 7.25 16.8358 7.25 17.25C7.25 17.6642 6.91419 18 6.5 18Z"
				fill="#69A041"
			/>
			<path
				d="M2.74999 21.375C2.55804 21.375 2.36613 21.3017 2.21969 21.1553C1.92677 20.8624 1.92677 20.3875 2.21969 20.0947L2.84097 19.4733C3.5493 18.765 4.49102 18.375 5.49264 18.375H6.49999C6.91418 18.375 7.24999 18.7108 7.24999 19.125C7.24999 19.5392 6.91418 19.875 6.49999 19.875H5.49264C4.89166 19.875 4.32663 20.109 3.90166 20.534L3.28033 21.1553C3.13385 21.3018 2.94194 21.375 2.74999 21.375Z"
				fill="#69A041"
			/>
			<path
				d="M6.49999 19.125H5.49264C4.49102 19.125 3.5493 18.735 2.84102 18.0266L2.21969 17.4053C1.92677 17.1124 1.92677 16.6375 2.21969 16.3447C2.51257 16.0518 2.98746 16.0518 3.28033 16.3447L3.90166 16.966C4.32663 17.391 4.89166 17.625 5.49264 17.625H6.49999C6.91418 17.625 7.24999 17.9608 7.24999 18.375C7.24999 18.7892 6.91418 19.125 6.49999 19.125Z"
				fill="#91B945"
			/>
			<path
				d="M8 15H17.2684C19.2575 15 21.1652 15.7902 22.5717 17.1967L23.4848 18.1098C23.6546 18.2796 23.75 18.5099 23.75 18.75C23.75 18.9901 23.6546 19.2204 23.4848 19.3902L22.5717 20.3033C21.1652 21.7098 19.2575 22.5 17.2684 22.5H8C7.17158 22.5 6.5 21.8284 6.5 21V16.5C6.5 15.6716 7.17158 15 8 15Z"
				fill="#FAA037"
			/>
			<path
				d="M23.75 18.75H8.75C8.33577 18.75 8 19.0858 8 19.5V22.5H17.2684C19.2575 22.5 21.1652 21.7098 22.5717 20.3033L23.4848 19.3902C23.6546 19.2204 23.75 18.9901 23.75 18.75Z"
				fill="#F57828"
			/>
			<path
				d="M8.75 16.875V15H8V16.875C8 17.0821 8.16791 17.25 8.375 17.25C8.58209 17.25 8.75 17.0821 8.75 16.875Z"
				fill="#F57828"
			/>
			<path
				d="M12.875 16.875V15H12.125V16.875C12.125 17.0821 12.2929 17.25 12.5 17.25C12.7071 17.25 12.875 17.0821 12.875 16.875Z"
				fill="#F57828"
			/>
			<path
				d="M17 16.875V15H16.25V16.875C16.25 17.0821 16.4179 17.25 16.625 17.25C16.8321 17.25 17 17.0821 17 16.875Z"
				fill="#F57828"
			/>
			<path
				d="M11 20.625V22.5H10.25V20.625C10.25 20.4179 10.4179 20.25 10.625 20.25C10.8321 20.25 11 20.4179 11 20.625Z"
				fill="#D65A0A"
			/>
			<path
				d="M14.75 20.625V22.5H14V20.625C14 20.4179 14.1679 20.25 14.375 20.25C14.5821 20.25 14.75 20.4179 14.75 20.625Z"
				fill="#D65A0A"
			/>
		</svg>
	);
};
