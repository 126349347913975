import {
	init_dkg,
	join_dkg,
	init_dkr,
	join_dkr,
	init_eddsa_dkg,
	join_eddsa_dkg,
	genInstanceId,
	init_dsg,
	genPartyKey,
	Keyshare,
	KeyshareV0,
	EddsaKeyshare,
	KeygenSetup,
	init_eddsa_dsg,
	init_dkr2,
	join_dkr2,
	init_eddsa_dkr,
	join_eddsa_dkr,
} from '@telifi/dkls-wasm';
import {
	createKeygenSetupOpts,
	createKeyMigrateSetupOpts,
	createSignSetupOptsWebCloud,
	startDkg,
	startEddsaDkg,
	startDkr,
	startDsg,
	configs,
	wsUrl,
	loadWasm,
	encodeHex,
	decodeHex,
	verifyingKey,
	encPublicKey,
	askForPartyKey,
	askForPartyEncKey,
	startEddsaDsg,
	startDkm,
	askForPartyId,
	askForEddsaPartyId,
	startEddsaDkr,
	startDsgPublicSign,
} from './lib';
import i18n from '@/app-cores/i18n';

export class MpcClient {
	#setup;
	#setup_sk: Uint8Array;
	#party_sks: Array<Uint8Array>;
	#cloud_party_vk: Uint8Array;
	#cloud_party_enc_key: Uint8Array;

	#authFn?: () => Promise<string>;

	async keygen(threshold: number = 2, tss: number = 50) {
		const setup = this.#setup;
		try {
			const parties = [
				...this.#party_sks.map((sk) => {
					return { rank: 0, publicKey: verifyingKey(sk) };
				}),
				{ rank: 0, publicKey: this.#cloud_party_vk },
			];

			const opts = await createKeygenSetupOpts(this.#setup_sk, parties, threshold, tss);
			const msgRelayUrl = wsUrl(setup.relay);
			const web_party = init_dkg(
				opts,
				encodeHex(this.#party_sks[0]),
				msgRelayUrl,
				encodeHex(genInstanceId()), // seed
			);

			const web_party2 = join_dkg(
				encodeHex(opts.instance),
				encodeHex(verifyingKey(this.#setup_sk)),
				encodeHex(this.#party_sks[1]),
				msgRelayUrl,
				encodeHex(genInstanceId()),
				async (setup: KeygenSetup) => {
					console.log('validate', setup); //, setup.rank(0), setup.verifyingKey(0));
					return true;
				},
			);

			const [share, share2] = await Promise.all([
				web_party,
				web_party2,
				startDkg(
					setup.endpoint,
					opts.instance,
					verifyingKey(this.#setup_sk),
					this.#cloud_party_vk,
					this.#authFn,
				),
			]);
			return [share, share2];
		} catch (err) {
			console.error('keygen error', err);
			throw err;
		}
	}

	async eddsa_keygen(threshold: number = 2, tss: number = 50) {
		const setup = this.#setup;
		try {
			const parties = [
				...this.#party_sks.map((sk) => {
					return { rank: 0, publicKey: verifyingKey(sk), encPublicKey: encPublicKey(sk) };
				}),
				{ rank: 0, publicKey: this.#cloud_party_vk, encPublicKey: this.#cloud_party_enc_key },
			];

			const opts = await createKeygenSetupOpts(this.#setup_sk, parties, threshold, tss);
			const msgRelayUrl = wsUrl(setup.relay);

			const web_party = init_eddsa_dkg(
				opts,
				encodeHex(this.#party_sks[0]),
				encodeHex(this.#party_sks[0]),
				msgRelayUrl,
				encodeHex(genInstanceId()), // seed
			);

			const web_party2 = join_eddsa_dkg(
				encodeHex(opts.instance),
				encodeHex(verifyingKey(this.#setup_sk)),
				encodeHex(this.#party_sks[1]),
				encodeHex(this.#party_sks[1]),
				msgRelayUrl,
				encodeHex(genInstanceId()),
				async (setup: KeygenSetup) => {
					console.log('validate', setup); //, setup.rank(0), setup.verifyingKey(0));
					return true;
				},
			);

			const [share, share2] = await Promise.all([
				web_party,
				web_party2,
				startEddsaDkg(
					setup.endpoint,
					opts.instance,
					verifyingKey(this.#setup_sk),
					this.#cloud_party_vk,
					this.#cloud_party_enc_key,
					this.#authFn,
				),
			]);
			return [share, share2];
		} catch (err) {
			console.error('keygen error', err);
			throw err;
		}
	}

	async keyMigrate(keyshare1: KeyshareV0, keyshare2: KeyshareV0, threshold: number = 2, tss: number = 50) {
		const setup = this.#setup;
		try {
			const parties = [
				...this.#party_sks.map((sk) => {
					return { rank: 0, publicKey: verifyingKey(sk) };
				}),
				{ rank: 0, publicKey: this.#cloud_party_vk },
			];

			const opts = await createKeyMigrateSetupOpts(this.#setup_sk, parties, threshold, tss);
			const msgRelayUrl = wsUrl(setup.relay);
			const web_party = init_dkr(
				opts,
				encodeHex(this.#party_sks[0]),
				msgRelayUrl,
				encodeHex(genInstanceId()), // seed
				keyshare1,
			);

			const web_party2 = join_dkr(
				encodeHex(opts.instance),
				encodeHex(verifyingKey(this.#setup_sk)),
				encodeHex(this.#party_sks[1]),
				msgRelayUrl,
				encodeHex(genInstanceId()),
				async (setup: KeygenSetup) => {
					console.log('validate', setup); //, setup.rank(0), setup.verifyingKey(0));
					return true;
				},
				keyshare2,
			);

			const [share, share2] = await Promise.all([
				web_party,
				web_party2,
				startDkm(
					setup.endpoint,
					opts.instance,
					verifyingKey(this.#setup_sk),
					this.#cloud_party_vk,
					keyshare1.publicKey(),
					this.#authFn,
				),
			]);

			return [share, share2];
		} catch (err) {
			console.error('key migration error', err);
			throw err;
		}
	}

	// For tobi usecase, we have totally 3 parties and threshold is the fixed number 2.
	// So the length of [keyshares] should be 1 or 2
	async refreshEcdsaKeyshare(keyshares: Keyshare[], tss: number = 50) {
		if (keyshares.length <= 0) {
			throw new Error('Not enough keyshares');
		}
		const keyshare1 = keyshares[0];
		const threshold = keyshare1.threshold();
		const totalParties = keyshare1.total_parties();

		if (keyshares.length < threshold - 1) {
			throw new Error('Not enough keyshares');
		}

		const resp = await askForPartyId(this.#setup.endpoint, 1, encodeHex(keyshare1.publicKey()), this.#authFn);
		const cloudPartyId = resp.party_id;
		const partyIds = keyshares.map((keyshare) => keyshare.partyId());
		partyIds.push(cloudPartyId);
		const lostPartyIds = [...Array(totalParties).keys()].filter((it) => !partyIds.includes(it));
		const lostPartyIdArray = new Uint8Array(lostPartyIds);

		const setup = this.#setup;

		try {
			const parties = [
				...this.#party_sks.map((sk) => {
					return { rank: 0, publicKey: verifyingKey(sk) };
				}),
				{ rank: 0, publicKey: this.#cloud_party_vk },
			];

			const opts = await createKeyMigrateSetupOpts(this.#setup_sk, parties, threshold, tss);
			const msgRelayUrl = wsUrl(setup.relay);

			const promises = [
				init_dkr2(
					opts,
					encodeHex(this.#party_sks[keyshare1.partyId()]),
					msgRelayUrl,
					encodeHex(genInstanceId()), // seed
					keyshare1,
					lostPartyIdArray,
					false,
				),
			];

			let lost_idx = 0;
			for (let i = 1; i < totalParties - 1; i++) {
				const is_lost = i >= keyshares.length;
				const keyshare = !is_lost ? keyshares[i] : keyshare1;
				const party_id = !is_lost ? keyshare.partyId() : lostPartyIds[lost_idx++];
				const party = join_dkr2(
					encodeHex(opts.instance),
					encodeHex(verifyingKey(this.#setup_sk)),
					encodeHex(this.#party_sks[party_id]),
					msgRelayUrl,
					encodeHex(genInstanceId()),
					async (setup: KeygenSetup) => {
						// console.log('validate', setup); //, setup.rank(0), setup.verifyingKey(0));
						return true;
					},
					keyshare,
					lostPartyIdArray,
					is_lost,
				);
				promises.push(party);
			}

			promises.push(
				startDkr(
					setup.endpoint,
					opts.instance,
					verifyingKey(this.#setup_sk),
					this.#cloud_party_vk,
					keyshare1.publicKey(),
					lostPartyIdArray,
					this.#authFn,
				),
			);

			const [share, share2] = await Promise.all(promises);
			return [share, share2];
		} catch (err) {
			console.error('key refresh error', err);
			throw err;
		}
	}

	async refreshEddsaKeyshare(keyshares: EddsaKeyshare[], tss: number = 50) {
		if (keyshares.length <= 0) {
			throw new Error('Not enough keyshares');
		}
		const keyshare1 = keyshares[0];
		const threshold = keyshare1.threshold();
		const totalParties = keyshare1.total_parties();

		if (keyshares.length < threshold - 1) {
			throw new Error('Not enough keyshares');
		}

		const resp = await askForEddsaPartyId(this.#setup.endpoint, 1, encodeHex(keyshare1.publicKey()), this.#authFn);
		const cloudPartyId = resp.party_id;
		const partyIds = keyshares.map((keyshare) => keyshare.partyId());
		partyIds.push(cloudPartyId);
		const lostPartyIds = [...Array(totalParties).keys()].filter((it) => !partyIds.includes(it));
		const lostPartyIdArray = new Uint8Array(lostPartyIds);

		const setup = this.#setup;

		try {
			const parties = [
				...this.#party_sks.map((sk) => {
					return { rank: 0, publicKey: verifyingKey(sk), encPublicKey: encPublicKey(sk) };
				}),
				{ rank: 0, publicKey: this.#cloud_party_vk, encPublicKey: this.#cloud_party_enc_key },
			];

			const opts = await createKeyMigrateSetupOpts(this.#setup_sk, parties, threshold, tss);
			const msgRelayUrl = wsUrl(setup.relay);

			const promises = [
				init_eddsa_dkr(
					opts,
					encodeHex(this.#party_sks[keyshare1.partyId()]),
					encodeHex(this.#party_sks[keyshare1.partyId()]),
					msgRelayUrl,
					encodeHex(genInstanceId()),
					keyshare1,
					lostPartyIdArray,
					false,
				),
			];

			let lost_idx = 0;
			for (let i = 1; i < totalParties - 1; i++) {
				const is_lost = i >= keyshares.length;
				const keyshare = !is_lost ? keyshares[i] : keyshare1;
				const party_id = !is_lost ? keyshare.partyId() : lostPartyIds[lost_idx++];
				const party = join_eddsa_dkr(
					encodeHex(opts.instance),
					encodeHex(verifyingKey(this.#setup_sk)),
					encodeHex(this.#party_sks[party_id]),
					encodeHex(this.#party_sks[party_id]),
					msgRelayUrl,
					encodeHex(genInstanceId()),
					async (setup: KeygenSetup) => {
						// console.log('validate', setup); //, setup.rank(0), setup.verifyingKey(0));
						return true;
					},
					keyshare,
					lostPartyIdArray,
					is_lost,
				);
				promises.push(party);
			}

			promises.push(
				startEddsaDkr(
					setup.endpoint,
					opts.instance,
					verifyingKey(this.#setup_sk),
					this.#cloud_party_vk,
					this.#cloud_party_enc_key,
					keyshare1.publicKey(),
					lostPartyIdArray,
					this.#authFn,
				),
			);

			const [share, share2] = await Promise.all(promises);
			return [share, share2];
		} catch (err) {
			console.error('eddsa key refresh error', err);
			throw err;
		}
	}

	async sign(keyShare: Keyshare, signMessage: string, threshold: number = 2) {
		const setup = this.#setup;

		try {
			const parties = [
				{ rank: 0, publicKey: verifyingKey(this.#party_sks[0]) },
				{ rank: 0, publicKey: this.#cloud_party_vk },
			];

			const opts = createSignSetupOptsWebCloud(
				this.#setup_sk,
				keyShare.publicKey(),
				parties,
				decodeHex(signMessage),
			);
			const msgRelayUrl = wsUrl(setup.relay);
			const web_party = init_dsg(
				opts,
				encodeHex(this.#party_sks[0]),
				msgRelayUrl,
				encodeHex(opts.instance), // seed
				keyShare,
			);

			const [signed] = await Promise.all([
				web_party,
				startDsg(
					setup.endpoint,
					opts.instance,
					verifyingKey(this.#setup_sk),
					this.#cloud_party_vk,
					this.#authFn,
				),
			]);
			return signed;
		} catch (error) {
			console.error('ecdsa_sign error', error);
			const errorCode = error?.response?.data?.code;
			const errorMessageKey = `errors.${errorCode}`;
			if (i18n.exists(errorMessageKey)) {
				error.message = i18n.t(errorMessageKey);
			}
			throw error;
		}
	}

	async publicSign(keyShare: Keyshare, signMessage: string, threshold: number = 2) {
		const setup = this.#setup;

		try {
			const parties = [
				{ rank: 0, publicKey: verifyingKey(this.#party_sks[0]) },
				{ rank: 0, publicKey: this.#cloud_party_vk },
			];

			const opts = createSignSetupOptsWebCloud(
				this.#setup_sk,
				keyShare.publicKey(),
				parties,
				decodeHex(signMessage),
			);
			const msgRelayUrl = wsUrl(setup.relay);
			const web_party = init_dsg(
				opts,
				encodeHex(this.#party_sks[0]),
				msgRelayUrl,
				encodeHex(opts.instance), // seed
				keyShare,
			);

			const [signed] = await Promise.all([
				web_party,
				startDsgPublicSign(
					setup.endpoint,
					opts.instance,
					verifyingKey(this.#setup_sk),
					this.#cloud_party_vk,
					this.#authFn,
				),
			]);
			return signed;
		} catch (error) {
			console.error('ecdsa_sign error', error);
			const errorCode = error?.response?.data?.code;
			const errorMessageKey = `errors.${errorCode}`;
			if (i18n.exists(errorMessageKey)) {
				error.message = i18n.t(errorMessageKey);
			}
			throw error;
		}
	}
	async eddsa_sign(keyShare: EddsaKeyshare, signMessage: Buffer, threshold: number = 2) {
		const setup = this.#setup;

		try {
			const parties = [
				{
					rank: 0,
					publicKey: verifyingKey(this.#party_sks[0]),
					encPublicKey: encPublicKey(this.#party_sks[0]),
				},
				{ rank: 0, publicKey: this.#cloud_party_vk, encPublicKey: this.#cloud_party_enc_key },
			];

			const opts = createSignSetupOptsWebCloud(this.#setup_sk, keyShare.publicKey(), parties, signMessage);
			const msgRelayUrl = wsUrl(setup.relay);
			const web_party = init_eddsa_dsg(
				opts,
				encodeHex(this.#party_sks[0]),
				msgRelayUrl,
				encodeHex(opts.instance), // seed
				keyShare,
			);

			const [signed] = await Promise.all([
				web_party,
				startEddsaDsg(
					setup.endpoint,
					opts.instance,
					verifyingKey(this.#setup_sk),
					this.#cloud_party_vk,
					this.#authFn,
				),
			]);
			return signed;
		} catch (error) {
			console.error('eddsa_sign error', error);
			const errorCode = error?.response?.data?.code;
			const errorMessageKey = `errors.${errorCode}`;
			if (i18n.exists(errorMessageKey)) {
				error.message = i18n.t(errorMessageKey);
			}
			throw error;
		}
	}

	async init(authFn?: () => Promise<string>) {
		await loadWasm();
		this.#setup = configs();

		// Set the authorization function
		this.#authFn = authFn;

		// We use the same mechanism to generate Setup/Party key
		this.#setup_sk = genPartyKey();
		this.#party_sks = [genPartyKey(), genPartyKey()];

		// Retrieve the party key available for cloud node
		try {
			const resp = await askForPartyKey(this.#setup.endpoint, this.#authFn);
			this.#cloud_party_vk = decodeHex(resp.party_vk);
		} catch (err) {
			console.error('askForPartyKey error: ', err);
		}

		// Retrieve the party key available for cloud node
		try {
			const resp = await askForPartyEncKey(this.#setup.endpoint, this.#authFn);
			this.#cloud_party_enc_key = decodeHex(resp.party_enc_key);
		} catch (err) {
			console.error('askForPartyEncKey error: ', err);
		}
	}
}
export type IMpcClient = typeof MpcClient;

export default new MpcClient();
