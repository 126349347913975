import {
	Box,
	Center,
	Divider,
	Drawer,
	DrawerBody,
	DrawerCloseButton,
	DrawerContent,
	DrawerHeader,
	DrawerOverlay,
	Image,
	Text,
} from '@chakra-ui/react';
import { ReactNode, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { CTAButton } from '@/app-components/common/Button/Button';
import { CHAIN_CONFIG_MAP, ChainId, EVM_CHAINS } from '@/app-constants/chains';
import { getShortAddress } from '@/app-helpers/address';
import { FiDoubleRightIcon, FiTobiWalletIcon, FiWalletCircleIcon } from '@/assets/icons';
import { DepositAmount } from './DepositAmount';
import { useDepositStore } from '../store';
import SelectTokenWithLabel from '@/app-components/common/SelectToken';
import { SearchMode } from '@/app-components/common/crypto-search';
import { useWalletTokenBalance } from '@/app-hooks/wallet/useWalletBalance';

type WalletInfo = {
	logo: string | ReactNode;
	address: string;
	isTobi?: boolean;
};
interface DepositFormProps {
	onClose: () => void;
	isOpen: boolean;
	onDeposit: () => void;
	isLoading?: boolean;
	fromWallet?: WalletInfo;
	toWallet?: WalletInfo;
	availableAmount?: number;
}

const WalletDeposit = ({ walletInfo, iconFirst }: { walletInfo: WalletInfo; iconFirst?: boolean }) => {
	const renderWalletIcon = () => {
		if (walletInfo?.isTobi) return <FiTobiWalletIcon />;
		if (walletInfo?.logo) {
			return typeof walletInfo.logo === 'string' ? (
				<Image width={10} height={10} alt="" src="" />
			) : (
				walletInfo.logo
			);
		}
		return <FiWalletCircleIcon />;
	};

	return (
		<Center gap={3}>
			<Text fontSize="xs" fontWeight={600} order={iconFirst ? 1 : 0}>
				{getShortAddress(walletInfo?.address, {
					start: 6,
					end: 4,
				})}
			</Text>
			{renderWalletIcon()}
		</Center>
	);
};

export const DepositForm: React.FC<DepositFormProps> = ({
	isOpen,
	onClose,
	onDeposit,
	isLoading,
	fromWallet,
	toWallet,
}) => {
	const { t } = useTranslation();
	const { state, setValue, errorMessage, setToken, token, tokenInfo } = useDepositStore();
	const { data: tokenBalance } = useWalletTokenBalance(fromWallet.address, tokenInfo);
	const { priceUsd: tokenPriceUsd = 0, chainId } = tokenInfo || {};

	const onValueChange = (val) => {
		setValue(val, tokenPriceUsd, tokenBalance?.balanceFormatted || 0);
	};
	const isNoneEvm = [ChainId.SOL.toString(), ChainId.TON.toString()].includes(chainId);
	return (
		<Drawer
			placement="bottom"
			onClose={() => {
				onClose();
			}}
			isOpen={isOpen}
			closeOnOverlayClick={false}
		>
			<DrawerOverlay />
			<DrawerContent>
				<DrawerHeader borderBottomWidth="1px" borderBottomColor="rgba(0, 0, 0, 0.08)">
					<Center>
						<Text fontSize={'16px'} fontWeight={'500'}>
							{t('deposit.deposit')}
						</Text>
					</Center>
					<DrawerCloseButton />
				</DrawerHeader>
				<DrawerBody px={4} py={4} display={'flex'} flexDirection={'column'} gap={4}>
					<Center gap={3}>
						<WalletDeposit walletInfo={fromWallet} />
						<FiDoubleRightIcon />
						<WalletDeposit walletInfo={toWallet} iconFirst />
					</Center>
					<Divider border="1px dashed rgba(0, 0, 0, 0.16)" my={1} mb={2} />
					<Text fontSize={'12px'} fontWeight={'500'}>
						{t('cryptos.token')}
					</Text>
					<SelectTokenWithLabel
						label={''}
						searchMode={SearchMode.SELECT_ANY_TOKEN}
						token={token as any}
						onSelectToken={(token) => setToken(token)}
						chainStyle={{
							width: '12px',
							height: '12px',
						}}
						autoFocus={false}
						placement="bottom"
						networkText={`On ${CHAIN_CONFIG_MAP[chainId]?.name} Network`}
						chainId={isNoneEvm ? chainId : ''}
						chainIds={isNoneEvm ? [] : EVM_CHAINS.map((e) => e.id)}
					/>
					<DepositAmount
						onValueChange={onValueChange}
						availableAmount={tokenBalance?.balanceFormatted}
						availableUsd={(tokenBalance?.balanceFormatted || 0) * tokenPriceUsd}
						onMax={onValueChange}
					/>
					<Box px={4} pt={4}>
						<CTAButton
							onClick={onDeposit}
							colorScheme="cyan"
							size="lg"
							fontWeight="medium"
							width="100%"
							isDisabled={!state.tokenValue || !!errorMessage?.toLowerCase()?.includes('insufficient')}
							isLoading={isLoading}
						>
							{t('button.deposit')}
						</CTAButton>
					</Box>
				</DrawerBody>
			</DrawerContent>
		</Drawer>
	);
};
