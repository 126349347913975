import { useMutation } from '@tanstack/react-query';
import axios, { AxiosRequestConfig } from 'axios';
import { ethers } from 'ethers';
import { GenericRoute, SelectedRoute } from '@/app-store/swap';
import { CHAIN_CONFIG } from '@/app-constants/chains';
import { calculatePriceImpact, commonGetRoute, isRouteParamsEmpty } from '@/app-hooks/swap/index';
import { TokenInfo } from '@/app-cores/api/bff';
import {
	ArgsGetRoute,
	ExtractRouteInfo,
	FEE_ACCOUNTS,
	SWAP_FEE_PERCENT,
	SwapAbstract,
	SwapProvider,
	UsdRouteInfo,
} from '@/app-hooks/swap/type';
import { useSubmitEVMTransaction } from '@/app-hooks/transactions';
import { isNativeToken } from '@/app-helpers/address';
import { MpcWallet } from '@/app-cores/mpc-wallet/wallet';
import { AUTO_SLIPPAGE } from '@/app-views/swap/components/SlippageSetting';
import { uniqueId } from '@/app-helpers/random';
import { calcRateSwap, filterParams, getSwapType, tryParseAmount } from '@/app-hooks/swap/helper';
import { TransactionType } from '@/app-types';
import { useTakeFeeSwap } from '@/app-hooks/swap/useTakeFeeSwap';

const formatRouteKyberSwap = (routeData: GetRouteDataKyber, params: ArgsGetRoute): SelectedRoute =>
	routeData
		? {
				...routeData,
				route: routeData,
				id: uniqueId(),
				provider: SwapProvider.KYBER,
				timestamp: Date.now(),
				params,
		  }
		: undefined;

const formatBuildRouteSwap = (routeData: BuildRouteDataKyber, route: SelectedRoute): SelectedRoute =>
	routeData
		? {
				...routeData,
				route: routeData as any,
				id: uniqueId(),
				tokenIn: route.tokenIn,
				tokenOut: route.tokenOut,
				params: route.params,
				provider: SwapProvider.KYBER,
				timestamp: Date.now(),
		  }
		: undefined;

type GetRouteParams = {
	tokenIn: string;
	tokenOut: string;
	amountIn: string;
	gasInclude?: string;
	gasPrice?: string;
	clientId: string;
	feeReceiver: string;
	isInBps: boolean;
	feeAmount: number;
	chargeFeeBy: string;
};

type Route = {
	pool: string;

	tokenIn: string;
	swapAmount: string;

	tokenOut: string;
	amountOut: string;

	limitReturnAmount: string;
	exchange: string;
	poolLength: number;
	poolType: string;
	extra: string;
};

type RouteSummary = {
	tokenIn: string;
	amountIn: string;
	amountInUsd: string;

	tokenOut: string;
	amountOut: string;
	amountOutUsd: string;
	tokenOutMarketPriceAvailable: null;

	gas: string;
	gasUsd: string;
	gasPrice: string;

	route: Route[][];
};

const KYBER_AGGREGATOR_DOMAIN = 'https://aggregator-api.kyberswap.com';
const clientId = 'tobi';

class Kyber extends SwapAbstract<GetRouteDataKyber> {
	async getRoute(paramsSwap: ArgsGetRoute, signal: AbortSignal) {
		const payload = getRouteParamsSwapKyber(paramsSwap, signal);
		if (!payload) return;
		const data = await commonGetRoute(payload, paramsSwap);
		return formatRouteKyberSwap(data, paramsSwap);
	}
	formatSlippage(slippage: string | number): number {
		return +slippage === AUTO_SLIPPAGE ? 50 : (+slippage * 100) | 0; // 0 5 10 (default 0.5%)
	}

	async buildRoute({
		route,
		slippage,
	}: {
		route: SelectedRoute<GetRouteDataKyber>;
		slippage: number;
	}): Promise<SelectedRoute<GenericRoute>> {
		const { route: routeData, tokenIn } = route;

		const params: BuildRouteArgs = {
			recipient: MpcWallet.getWalletAddress().evmAddress,
			sender: MpcWallet.getWalletAddress().evmAddress,
			chainId: tokenIn.chainId,
			routeSummary: routeData.routeSummary,
			slippage: this.formatSlippage(slippage),
			transactionTimeout: Math.floor(Date.now() / 1000) + 1200,
		};
		const response = await axios(getBuildRouteParams(params));
		const data = response.data.data;

		return formatBuildRouteSwap(data, route);
	}

	extractRoute(
		params: SelectedRoute<BuildRouteDataKyber | GetRouteDataKyber>,
		prices: UsdRouteInfo,
	): ExtractRouteInfo {
		return getExtractRouteKyberSwap(params, prices);
	}
}
export const KyberSwap = new Kyber();

const getRouteParamsSwapKyber = (args: ArgsGetRoute, signal?: AbortSignal): AxiosRequestConfig => {
	const slug = CHAIN_CONFIG[args?.tokenIn?.chainId]?.kyberAggregatorSlug;

	if (isRouteParamsEmpty(args) || !slug) return;
	const { tokenIn, tokenOut, amountIn } = args;

	const params: GetRouteParams = {
		tokenIn: tokenIn?.address,
		tokenOut: tokenOut?.address,
		amountIn,
		gasInclude: 'true', // default
		gasPrice: '', // default
		clientId,
		isInBps: true,
		feeAmount: SWAP_FEE_PERCENT * 100,
		chargeFeeBy: 'currency_out',
		feeReceiver: FEE_ACCOUNTS.EVM,
	};

	filterParams(params);
	return { url: `${KYBER_AGGREGATOR_DOMAIN}/${slug}/api/v1/routes`, params, signal };
};

type BuildRouteArgs = {
	recipient: string;
	routeSummary: RouteSummary | undefined;
	slippage: number;
	transactionTimeout: number;
	sender: string;
	chainId: string;
};

type BuildRoutePayload = {
	routeSummary: RouteSummary;
	deadline: number;
	slippageTolerance: number;
	sender: string;
	recipient: string;
	source: string;
	skipSimulateTx: boolean;
	permit?: string;
};

export type RouteKyber = GetRouteDataKyber | BuildRouteDataKyber;

export type GetRouteDataKyber = {
	routeSummary: RouteSummary;
	routerAddress: string;
	// custom
	tokenIn: TokenInfo;
	tokenOut: TokenInfo;
};

export type BuildRouteDataKyber = {
	data: string;
	amountIn: string;
	amountInUsd: string;
	amountOut: string;
	amountOutUsd: string;
	feeUsd: string;
	outputChange?: {
		percent: number;
	};
	gas: string;
	gasPrice: string;
	gasUsd: string;
	routerAddress: string;
	// custom
	tokenIn: TokenInfo;
	tokenOut: TokenInfo;
};

const getBuildRouteParams = (args: BuildRouteArgs): AxiosRequestConfig => {
	const { recipient, routeSummary, transactionTimeout, slippage, sender, chainId } = args;

	const payload: BuildRoutePayload = {
		routeSummary,
		deadline: Math.floor(Date.now() / 1000) + transactionTimeout,
		slippageTolerance: slippage,
		sender,
		recipient,
		source: clientId,
		skipSimulateTx: false,
	};

	const url = `${KYBER_AGGREGATOR_DOMAIN}/${CHAIN_CONFIG[chainId].kyberAggregatorSlug}/api/v1/route/build`;
	return { url, method: 'POST', data: payload };
};

export const getExtractRouteKyberSwap = (
	selectedRoute: SelectedRoute<BuildRouteDataKyber | GetRouteDataKyber>,
	{ usdPriceIn, usdPriceOut }: UsdRouteInfo,
): ExtractRouteInfo => {
	const routeSwap =
		(selectedRoute?.route as GetRouteDataKyber)?.routeSummary || (selectedRoute?.route as BuildRouteDataKyber);

	const tokenIn = selectedRoute?.tokenIn;
	const tokenOut = selectedRoute?.tokenOut;

	return {
		amountOut: routeSwap?.amountOut,
		amountIn: routeSwap?.amountIn,
		gasUsd: routeSwap?.gasUsd,
		tokenIn,
		tokenOut,
		gasNative: routeSwap ? BigInt(routeSwap?.gas ?? '0') * BigInt(routeSwap?.gasPrice ?? '0') : BigInt(0),
		dappInfo: {
			logo: '/icons/brands/kyberswap.png',
			domain: SwapProvider.KYBER,
		},
	};
};

export const useExecuteRouteKyberSwap = (chainId: string) => {
	const { sentTransaction } = useSubmitEVMTransaction(chainId);
	const takeFee = useTakeFeeSwap();
	const response = useMutation({
		mutationKey: ['exe-route-kyber'],
		mutationFn: async (routeData: SelectedRoute<BuildRouteDataKyber>) => {
			const { tokenIn, route, params } = routeData;
			return sentTransaction({
				to: route.routerAddress,
				data: route.data,
				value: isNativeToken(tokenIn.address) ? BigInt(params.amountIn) : BigInt(0),
				chainId: tokenIn.chainId,
				gasLevel: 'low',
				transactionType: TransactionType.Swap,
				metadata: routeData,
			});
		},
		onSuccess: ({ hash, nonce }, route) => {
			takeFee({ hash, route: route as any, nonce });
		},
	});
	return response;
};
