import {
	Box,
	Button,
	Center,
	Drawer,
	DrawerBody,
	DrawerCloseButton,
	DrawerContent,
	DrawerHeader,
	DrawerOverlay,
	Text,
} from '@chakra-ui/react';
import { QRCodeSVG } from 'qrcode.react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { toast } from 'react-toastify';
import { Block } from '../Block';
import { ITokenBalance, TokenInfo } from '@/app-cores/api/bff';
import { Toast } from '../Toast';
import { CHAIN_CONFIG_MAP } from '@/app-constants/chains';
import { FiCopyIcon } from '@/assets/icons';
import { useTranslation } from 'react-i18next';
import { isMobile } from 'react-device-detect';

interface QrCodeAddressProps {
	token: TokenInfo | Pick<ITokenBalance, 'chainId' | 'address' | 'symbol' | 'logo'>;
	isOpen: boolean;
	onClose: () => void;
	address: string;
}
export const QrCodeAddress: React.FC<QrCodeAddressProps> = ({ token, isOpen, onClose, address }) => {
	const chainName = CHAIN_CONFIG_MAP[token?.chainId]?.name;
	const { t } = useTranslation();
	return (
		<Drawer isOpen={isOpen} onClose={onClose} placement="bottom">
			<DrawerOverlay />
			<DrawerContent>
				<DrawerHeader borderBottomWidth="1px" borderBottomColor="rgba(0, 0, 0, 0.08)">
					{t('deposit.depositNetworkTitle', {
						network: chainName,
					})}
				</DrawerHeader>
				<DrawerCloseButton />
				<DrawerBody
					pt={6}
					pb={4}
					display="flex"
					justifyContent="space-between"
					alignItems="center"
					flexDirection="column"
					gap={isMobile ? 8 : 4}
				>
					<Text fontSize="sm" fontWeight={400} textAlign="center" px={10}>
						{t('deposit.depositWaringMessage', {
							network: chainName,
						})}
					</Text>
					<Box>
						<QRCodeSVG
							value={address}
							style={{
								width: '210px',
								height: '210px',
							}}
							imageSettings={{
								src: token.logo,
								width: 24,
								height: 24,
								excavate: false,
							}}
						/>
					</Box>
					<Block fontSize="sm" wordBreak="break-all">
						<Text fontSize="xs" fontWeight={600} mb={3} textAlign="center" color="gray.400">
							{t('deposit.depositNetworkTitle', {
								network: chainName,
							})}{' '}
						</Text>
						<Text mb={3} textAlign="center">
							{address}
						</Text>
						<CopyToClipboard
							text={address}
							onCopy={(_, success) => {
								toast(
									<Toast
										type="success"
										title="Success"
										message={t('deposit.copyWalletAddressSuccess', {
											network: chainName,
										})}
									/>,
								);
							}}
						>
							<Button
								fontSize="xs"
								fontWeight={600}
								size="sm"
								variant="outline"
								backgroundColor="white"
								border="1px solid rgba(0, 0, 0, 0.08)"
								width="100%"
								py={5}
							>
								<FiCopyIcon fill="black" width={20} height={20} style={{ marginRight: 4 }} />
								{t('cryptos.copyAddress')}
							</Button>
						</CopyToClipboard>
					</Block>
				</DrawerBody>
			</DrawerContent>
		</Drawer>
	);
};
