import { Box, Center, Divider, Text } from '@chakra-ui/react';
import { CommingSoon } from '@/app-components/common';
import { FiBillIcon, FiRightIcon, FiStartIcon } from '@/assets/icons';

export const Rewards = () => {
	return (
		<CommingSoon
			display="flex"
			flexDirection="column"
			flex={1}
			backgroundColor="white"
			py={3}
			px={4}
			borderRadius={8}
			overflow="hidden"
		>
			<Box>
				<Center justifyContent="start" fontSize="xs" fontWeight={600}>
					Rewards <FiRightIcon width={20} height={20} fill="gray" />
				</Center>
			</Box>
			<Box mt={3}>
				<Text fontSize="x-small" color="gray.400">
					Points Earned
				</Text>
				<Center mt={1}>
					<FiStartIcon />
					<Text ml={2} fontSize="xs" fontWeight={700}>
						0
					</Text>
					<Text flex={1} textAlign="end" fontSize="xs" fontWeight={500} color="gray.200">
						Points
					</Text>
				</Center>
				<Divider mt={2} borderColor="gray.200" variant={'dashed'} />
			</Box>
			<Box mt={3}>
				<Text fontSize="x-small" color="gray.400">
					Fees Earned
				</Text>
				<Center mt={1}>
					<FiBillIcon />
					<Text ml={2} fontSize="xs" fontWeight={700}>
						$0
					</Text>
					<Text flex={1} textAlign="end" fontSize="xs" fontWeight={600} color="gray.200">
						Fees
					</Text>
				</Center>
			</Box>
		</CommingSoon>
	);
};
