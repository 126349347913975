import { Box, BoxProps } from '@chakra-ui/react';

interface SpinnerProps extends BoxProps {
	spinnerBarColor?: string;
}
export const Spinner: React.FC<SpinnerProps> = ({ className, spinnerBarColor = '#161515', ...rest }) => {
	return (
		<Box className={`spinner ${className}`} width={14} height={14} bgColor="rgba(0, 0, 0, 0.08)" {...rest}>
			{[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((i) => {
				return <Box bgColor={spinnerBarColor} key={i} className={`bar${i}`}></Box>;
			})}
		</Box>
	);
};
