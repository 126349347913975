import { useMemo } from 'react';
import { Avatar, Center, Text } from '@chakra-ui/react';
import { CopyToClipboard } from '@/app-components/common';
import { WalletActivity } from '@/app-cores/api/activities';
import { getShortAddress } from '@/app-helpers/address';
import { TransactionType } from '@/app-types';
import { FiDownLeftIcon, FiSwapIcon, FiUpRightIcon } from '@/assets/icons';
import { Address } from '@ton/core';

interface ActivityItemActionProps {
	data: WalletActivity;
}

const ActionType: Partial<{
	[k in TransactionType]: {
		icon: any;
		actionName?: string;
	};
}> = {
	[TransactionType.Send]: {
		icon: <FiUpRightIcon />,
		actionName: 'Send',
	},
	[TransactionType.Swap]: {
		icon: <FiSwapIcon />,
		actionName: 'Trade',
	},
	[TransactionType.Receive]: {
		icon: <FiDownLeftIcon />,
		actionName: 'Receive',
	},
};

interface ActionDetailProps {
	rawAddress: string;
	user: WalletActivity['sendDetail']['sender'];
	chainId: string;
	direction: 'to' | 'from';
}
const ActionDetail: React.FC<ActionDetailProps> = ({ rawAddress, user, chainId, direction }) => {
	const isTonChain = chainId === 'TON';
	const address = isTonChain
		? Address.parse(rawAddress).toString({
				bounceable: false,
		  })
		: rawAddress;
	return (
		<Center fontSize="xs" color="gray.400" gap={1}>
			{direction}
			{user.username && (
				<CopyToClipboard text={address}>
					<Center>
						<Avatar width={4} height={4} mx={1} src={user.avatar} /> {user.username}
					</Center>
				</CopyToClipboard>
			)}
			{!user.username && (
				<CopyToClipboard text={address}>
					(
					{getShortAddress(address, {
						start: 4,
						end: 3,
					})}
					)
				</CopyToClipboard>
			)}
		</Center>
	);
};

export const ActivityItemAction: React.FC<ActivityItemActionProps> = ({ data }) => {
	const objectInteraction = useMemo(() => {
		switch (data.transactionType) {
			case TransactionType.Send: {
				return (
					<ActionDetail
						chainId={data.sendDetail?.chainId}
						rawAddress={data.sendDetail.receiver.address}
						user={data.sendDetail.receiver}
						direction="to"
					/>
				);
			}
			case TransactionType.Receive: {
				return (
					<ActionDetail
						chainId={data.receiveDetail?.chainId}
						rawAddress={data.receiveDetail.sender.address}
						user={data.receiveDetail.sender}
						direction="from"
					/>
				);
			}
			// case TransactionType.Swap: {
			// 	return <Text>via KyberSwap</Text>;
			// }
			default: {
				return null;
			}
		}
	}, [data]);

	return (
		<Center justifyContent="space-between" mt={2}>
			<Center gap={2}>
				<Avatar bg="cyan.400" width={8} height={8} icon={ActionType[data.transactionType].icon} src="" />
				<Text fontWeight={600}>{ActionType[data.transactionType].actionName}</Text>
			</Center>
			{objectInteraction}
		</Center>
	);
};
