import { STORAGE_KEYS } from '@/app-constants/storage';
import { TelegramCore } from '@/app-cores/telegram';
import { ReturnStrategy } from '@tonconnect/ui-react';

export const tonConnectReturnStrategyManager = {
	getReturnStrategies: () => {
		return JSON.parse(localStorage.getItem(STORAGE_KEYS.TOBI_RETURN_STRATEGY_LOCAL_STORE_KEY)) || {};
	},
	getReturnStrategy: (sessionId: string) => {
		return tonConnectReturnStrategyManager.getReturnStrategies()[sessionId];
	},
	addReturnStrategy: (sessionId, value) => {
		const strategies = tonConnectReturnStrategyManager.getReturnStrategies();
		strategies[sessionId] = value;
		localStorage.setItem(STORAGE_KEYS.TOBI_RETURN_STRATEGY_LOCAL_STORE_KEY, JSON.stringify(strategies));
	},
	removeReturnStrategy: (sessionId) => {
		const strategies = tonConnectReturnStrategyManager.getReturnStrategies();
		delete strategies[sessionId];
		localStorage.setItem(STORAGE_KEYS.TOBI_RETURN_STRATEGY_LOCAL_STORE_KEY, JSON.stringify(strategies));
	},
};

export function getReturnStrategyWhenError(clientSessionId: string): ReturnStrategy {
	let rt = tonConnectReturnStrategyManager.getReturnStrategy(clientSessionId);
	if (rt === 'none') {
		rt = 'back';
	}
	return rt;
}
