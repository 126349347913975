import { NAVIGATE_PATHS } from '@/app-constants/router';
import AllSet from '@/app-views/allset/Allset';
import OnBoarding from '@/app-views/onboarding';
import BackupGDriveCallback from '@/app-views/onboarding/pages/BackupGDriveCallback/BackupGDriveCallback';
import BackupICloudCallback from '@/app-views/onboarding/pages/BackupICloudCallback/BackupICloudCallback';
import RecoveryGDriveCallback from '@/app-views/onboarding/pages/RecoveryGDriveCallback/RecoveryGDriveCallback';
import RecoveryICloudCallback from '@/app-views/onboarding/pages/RecoveryICloudCallback/RecoveryICloudCallback';
import RecoverySelectMethod from '@/app-views/onboarding/pages/RecoverySelectMethod/RecoverySelectMethod';
import StoreBackup from '@/app-views/onboarding/pages/StoreBackup/StoreBackup';
import { RouteObject } from 'react-router';
import { CommingSoon } from '../app-views/comming-soon';
import { InputSecretKey } from '@/app-views/onboarding/pages/InputSecretKey';
import { RecoveryByFileCallback } from '@/app-views/onboarding/pages/RecoveryByFileCallback';
import { RefreshKeyshare } from '@/app-views/onboarding/pages/RefreshKeyshare';
import { ProcessRefreshKeyshare } from '@/app-views/onboarding/pages/ProcessRefreshKeyshare';
import { RefreshKeyshareSelectMethod } from '@/app-views/onboarding/pages/RefreshSelectMethod';
import { RecoveryForNewTGAccount } from '@/app-views/onboarding/pages/RecoveryForNewTGAccount';

export const OnboardingRouter: RouteObject[] = [
	{
		path: NAVIGATE_PATHS.CreateWallet.Onboarding,
		element: <OnBoarding />,
	},
	{
		path: NAVIGATE_PATHS.CreateWallet.StoreBackupKey,
		element: <StoreBackup />,
	},
	{
		path: NAVIGATE_PATHS.CreateWallet.BackupGdriveCallback,
		element: <BackupGDriveCallback />,
	},
	{
		path: '/backup-icloud-callback',
		element: <BackupICloudCallback />,
	},
	{
		path: NAVIGATE_PATHS.Recovery.SelectMethod,
		children: [
			{
				index: true,
				element: <RecoverySelectMethod />,
			},
			{
				path: NAVIGATE_PATHS.Recovery.InputSecretKey,
				element: <InputSecretKey />,
			},
			{
				path: NAVIGATE_PATHS.Recovery.GDriveCallback,
				element: <RecoveryGDriveCallback />,
			},
			{
				path: NAVIGATE_PATHS.Recovery.ICloudCallback,
				element: <RecoveryICloudCallback />,
			},
			{
				path: NAVIGATE_PATHS.Recovery.FileUploadCallBack,
				element: <RecoveryByFileCallback />,
			},
		],
	},
	{
		path: NAVIGATE_PATHS.RefreshKeyshare,
		children: [
			{
				index: true,
				element: <RefreshKeyshare />,
			},
			{
				path: NAVIGATE_PATHS.ProcessRefreshKeyshare,
				element: <ProcessRefreshKeyshare />,
			},
			{
				path: NAVIGATE_PATHS.RefreshKeyshareMethod,
				element: <RefreshKeyshareSelectMethod />,
			},
		],
	},
	{
		path: '/all-set',
		element: <AllSet />,
	},
	{
		path: NAVIGATE_PATHS.CommingSoon,
		element: <CommingSoon />,
	},
	{
		path: NAVIGATE_PATHS.Recovery.NewTGAccount,
		element: <RecoveryForNewTGAccount />,
	},
];
