import { ITokenSearch, TokenInfo } from '@/app-cores/api/bff';
import { getTokenInfo } from '@/app-helpers/token';
import { create } from 'zustand';
import { persist, createJSONStorage } from 'zustand/middleware';

const initializeData = {
	amount: '',
	usdAmount: '',
	messages: '',
	token: null,
	tokenInfo: null,
	isInsufficient: false,
	isInsufficientGas: false,
	gasFee: '0',
};
export interface ISentCryptoStore {
	amount: string;
	messages: string;
	token: ITokenSearch;
	tokenInfo: TokenInfo;
	isInsufficient: boolean;
	isInsufficientGas: boolean;
	gasFee: string;
	usdAmount: string;
	setUsdAmount: (amount: string) => void;
	setAmount: (amount: string) => void;
	setMessage: (message: string) => void;
	setTokenSent: (token: ITokenSearch) => void;
	setIsInsufficient: (isInsufficient: boolean) => void;
	setIsInsufficientGas: (isInsufficientGas: boolean) => void;
	setGasFee: (gas: string) => void;
	reset: () => void;
}

export const useSentTokenStore = create<ISentCryptoStore>()(
	persist(
		(set) => ({
			...initializeData,
			setAmount: (amount) => set({ amount }),
			setUsdAmount: (usdAmount) => set({ usdAmount }),
			setMessage: (mes) => set({ messages: mes }),
			setTokenSent: (tk) => set({ token: tk, tokenInfo: getTokenInfo(tk) }),
			setIsInsufficient: (isInsufficient) => set({ isInsufficient: isInsufficient }),
			setIsInsufficientGas: (isInsufficientGas) => set({ isInsufficientGas: isInsufficientGas }),
			setGasFee: (gas) => set({ gasFee: gas }),
			reset: () =>
				set((state) => ({
					...state,
					...initializeData,
				})),
		}),
		{
			name: 'sent-token-storage',
			storage: createJSONStorage(() => sessionStorage),
		},
	),
);
