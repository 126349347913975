import { TextSmall } from '@/app-components/common';
import { formatUsd } from '@/app-helpers/number';
import {
	usePortfolioBalance,
	usePortfolioBalanceByCategories,
	usePortfolioChart,
} from '@/app-hooks/api/portfolio/usePortfolioBalance';
import { BASE_BORDER_COLOR } from '@/app-theme/theme';
import { BalanceChart } from '@/app-views/wallet/components/WalletBalance/BalanceChart';
import { ChevronRightIcon } from '@/assets/images/svg';
import {
	Box,
	Center,
	Drawer,
	DrawerBody,
	DrawerCloseButton,
	DrawerContent,
	DrawerHeader,
	DrawerOverlay,
	Flex,
	Skeleton,
	Text,
	useDisclosure,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { Balance } from '../../../../app-components/common/Balance';
import { FeatureConfigWrap } from '../../../../app-helpers/components';
import { FeatureConfig } from '../../../../app-hooks/api/configuration';
import { usePortfolioState } from '@/app-views/wallet/components/Portfolio/state';

interface WalletBalanceProps {
	isBackedUp?: boolean;
	threshold?: number;
}

export const WalletBalance: React.FC<WalletBalanceProps> = () => {
	const { data } = usePortfolioBalanceByCategories({ refetchInterval: 3000 });
	const { refreshingBalance } = usePortfolioState();
	const { isOpen, onOpen, onClose } = useDisclosure();
	const { t } = useTranslation();
	const { data: balanceChartData, isFetching } = usePortfolioChart({ enabled: isOpen });

	const isProfit = data?.totalUsd24hChangeUsd > 0;
	const totalUsd = data?.totalUsd || 0;
	if (refreshingBalance)
		return (
			<Center flexDirection="column" gap={2} height="94px">
				<Skeleton width="100%" height="60px" endColor={'gray.200'} borderRadius="1rem" />
				<Skeleton width="100%" height={'16px'} endColor={'gray.200'} borderRadius="1rem" />
			</Center>
		);
	return (
		<Box>
			<Balance amount={totalUsd} />
			<Flex
				alignItems={'center'}
				justifyContent={'center'}
				userSelect={'none'}
				cursor={'pointer'}
				minHeight={'22px'}
			>
				<Text
					onClick={onOpen}
					as="span"
					color={!data?.totalUsd24hChangeUsd ? undefined : isProfit ? 'green.200' : 'red.600'}
					fontSize={'14px'}
					lineHeight={'16px'}
					fontWeight={'500'}
				>
					{!data?.totalUsd24hChangeUsd ? '' : isProfit ? '+' : ''}
					{formatUsd(data?.totalUsd24hChangeUsd) || 0} ({data?.totalUsd24hChangePercent?.toFixed(2) || 0}%)
				</Text>

				<Text
					as="span"
					ml="1"
					fontSize="xs"
					fontWeight={'400'}
					onClick={() => {
						onOpen();
					}}
				>
					24 {t('hours')}
				</Text>
				<FeatureConfigWrap feature={FeatureConfig.BALANCE_ANALYTIC}>
					<ChevronRightIcon
						onClick={onOpen}
						style={{ height: '22px', width: '22px', transform: 'translateY(1px)' }}
					/>
				</FeatureConfigWrap>
			</Flex>
			<FeatureConfigWrap feature={FeatureConfig.BALANCE_ANALYTIC}>
				<Drawer isOpen={isOpen} placement="bottom" onClose={onClose} trapFocus={false}>
					<DrawerOverlay />
					<DrawerContent>
						<DrawerCloseButton />
						<DrawerHeader>{t('cryptos.balanceAnalysis')}</DrawerHeader>
						<DrawerBody
							display={'flex'}
							flexDirection={'column'}
							alignItems={'center'}
							borderTop={`1px solid ${BASE_BORDER_COLOR}`}
							padding={'16px 12px'}
							overflowX={'hidden'}
						>
							<TextSmall my={0}>{t('cryptos.portfolioValue')}</TextSmall>
							<Balance amount={totalUsd} />
							<BalanceChart chartData={balanceChartData} isFetching={isFetching} />
						</DrawerBody>
					</DrawerContent>
				</Drawer>
			</FeatureConfigWrap>
		</Box>
	);
};
