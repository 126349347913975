import { Avatar, Box, Flex, Text } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { isEmpty } from 'lodash-es';
import { toast } from 'react-toastify';
import { useLayoutEffect, useRef, useState } from 'react';
import { Block, Toast, UserAddress } from '..';
import { FiScanIcon } from '@/assets/icons';
import { ContactPopup } from '../Contact/PopupContact';
import { useBackButton, useContactStore, useSentTokenStore } from '@/app-store';
import { displayUserName, getAccountFullName } from '@/app-helpers/display';
import { getBlockChainNameByAddress, getBlockChainNameByChainId, getShortAddress } from '@/app-helpers/address';
import { getContactWalletAddress } from '@/app-helpers/contact';
import { METAMASK_QR_CODE_REGEX } from '@/app-constants/regex';
import { ContactManagementAPI } from '@/app-cores/api/contact';
import { useScanWC } from '@/app-features/app-wallet-connect/hooks';

const ContactSelected = () => {
	const { contact, address } = useContactStore();
	const { tokenInfo } = useSentTokenStore();
	const userNameRef = useRef<HTMLDivElement>(null);
	const [fullNameWidth, setFullNameWidth] = useState('150px');

	useLayoutEffect(() => {
		if (!userNameRef.current || !contact) return;
		const width = userNameRef.current.getBoundingClientRect().width;
		const fullNameWidth = `calc(100vw - 162px - ${width}px)`;
		setFullNameWidth(fullNameWidth);
	}, [contact]);

	const blockChain = tokenInfo?.chainId
		? getBlockChainNameByChainId(tokenInfo?.chainId)
		: getBlockChainNameByAddress(address);
	const walletAddress = getContactWalletAddress(contact, blockChain);
	return (
		<Flex alignItems="center" gap={3}>
			<Avatar src={contact.avatar} name={getAccountFullName(contact)} size="sm"></Avatar>
			<Box>
				<Flex alignItems="center" gap={1}>
					<Text
						fontSize="sm"
						fontWeight={600}
						whiteSpace="nowrap"
						overflow="hidden"
						textOverflow="ellipsis"
						maxWidth={fullNameWidth}
						title={getAccountFullName(contact)}
					>
						{getAccountFullName(contact)}
					</Text>
					<Text
						ref={userNameRef}
						fontSize="xs"
						color="gray.400"
						whiteSpace="nowrap"
						overflow="hidden"
						textOverflow="ellipsis"
					>
						{displayUserName(contact.username)}
					</Text>
				</Flex>
				<Text fontSize="xs" color="gray.400">
					{getShortAddress(walletAddress)}
				</Text>
			</Box>
		</Flex>
	);
};

export const SelectContact = () => {
	const { setOpen, contact, address, setContact, setAddress } = useContactStore();
	const { token, tokenInfo } = useSentTokenStore();
	const { t } = useTranslation();
	const { setOpen: setOpenScanPopup, setOnScan } = useScanWC();
	const { setShow: setShowBackButton } = useBackButton();

	const onScan = async (data: string) => {
		if (!data) return;
		const isMetamaskQrCode = METAMASK_QR_CODE_REGEX.test(data);
		const address = isMetamaskQrCode ? data.split(':')[1].split('@')[0] : data;
		const blockChainName = getBlockChainNameByAddress(address);
		if (!isMetamaskQrCode && !blockChainName) return;

		const contact = await ContactManagementAPI.getContactByWalletAddress(address);
		if (contact) {
			setContact(contact);
			setAddress(null);
			setOpenScanPopup(false);
			return;
		}
		setContact(null);
		if (token && getBlockChainNameByChainId(tokenInfo?.chainId) !== blockChainName) {
			toast(
				<Toast
					type="error"
					title="Do not matching network"
					message="The asset you want to send and the receiving address do not match the network"
				/>,
			);
			setOpenScanPopup(false);
		} else {
			setAddress(address);
			setOpenScanPopup(false);
		}
	};

	const renderContent = () => {
		if (!isEmpty(contact)) return <ContactSelected />;

		if (address)
			return (
				<UserAddress
					address={address}
					_hover={{
						backgroundColor: 'transparent',
					}}
					px={0}
				/>
			);
		return (
			<Text color="gray.400" fontWeight={500} fontSize="sm">
				{t('cryptos.addressOrTelegramUser')}
			</Text>
		);
	};
	return (
		<>
			<Block
				display="flex"
				alignItems="center"
				justifyContent="space-between"
				onClick={() => setOpen(true)}
				cursor="pointer"
				height={14}
				backgroundColor="gray.100"
			>
				{renderContent()}
				<button
					onClick={(event) => {
						event.stopPropagation();
						setOpenScanPopup(true);
						setOnScan(onScan);
						setShowBackButton({
							isShow: true,
							backButtonCallback: () => setOpenScanPopup(false),
							skipNavigation: true,
						});
					}}
				>
					<FiScanIcon />
				</button>
			</Block>
			{open && <ContactPopup />}
		</>
	);
};
