import { CHAIN_CONFIG } from '@/app-constants/chains';
import { useQuery } from '@tanstack/react-query';
import { TransactionResponse, ethers } from 'ethers';
import { isEmpty } from 'lodash-es';
import { getGasAfterBuffer, getMaxFeePerGas } from '.';
import { useUserSettingsStore } from '@/app-store/settings';
import { useQueryGasPrice } from '../api/transactions/useQueryGasPrice';
import { toGwei } from '@/app-helpers/token';
import { TransactionType } from '@/app-types';

const BUFFER_GAS_PERCENT = 20n;

export const useEstimateSpeedUpGasFee = ({
	transaction,
	bufferGasPercent = BUFFER_GAS_PERCENT,
	hasGasSetting,
}: {
	transaction: TransactionResponse;
	bufferGasPercent?: bigint;
	hasGasSetting: boolean;
}) => {
	const chainId = Number(transaction?.chainId);
	const chainConfig = CHAIN_CONFIG[chainId];

	const { data: gasPriceData } = useQueryGasPrice(chainId);
	const { gasFeeType } = useUserSettingsStore();

	const response = useQuery({
		queryKey: ['estimate-speed-up-gas-fee', transaction?.hash],
		queryFn: async () => {
			const gasPrice = getGasAfterBuffer(BigInt(transaction?.gasPrice || 0n), bufferGasPercent);
			const gasLimit = BigInt(transaction?.gasLimit || 0n);
			if (!hasGasSetting) {
				return {
					maxPriorityFeePerGas: transaction.maxPriorityFeePerGas,
					maxFeePerGas: transaction.maxFeePerGas,
					gasLimit,
					gasFeeInNativeToken: chainConfig.isEIP1559
						? ethers.formatEther(transaction.maxFeePerGas * gasLimit)
						: ethers.formatEther(gasPrice * gasLimit),
					nonce: transaction?.nonce,
					gasPrice,
				};
			}

			let maxPriorityFeePerGas =
				toGwei(gasPriceData?.high?.suggestedMaxPriorityFeePerGas) || transaction?.gasPrice;
			let maxFeePerGas = toGwei(gasPriceData?.high?.suggestedMaxFeePerGas) || transaction?.gasPrice;

			if (gasFeeType !== 'aggressive')
				return {
					maxPriorityFeePerGas: maxPriorityFeePerGas,
					maxFeePerGas: maxFeePerGas,
					gasLimit: transaction?.gasLimit,
					gasFeeInNativeToken: chainConfig.isEIP1559
						? ethers.formatEther(maxFeePerGas * gasLimit)
						: ethers.formatEther(gasPrice * gasLimit),
					nonce: transaction?.nonce,
					gasPrice: gasPrice,
				};

			maxPriorityFeePerGas = getGasAfterBuffer(maxPriorityFeePerGas, bufferGasPercent);
			maxFeePerGas = getMaxFeePerGas(maxPriorityFeePerGas, maxFeePerGas);
			const gasFeeInNativeToken = chainConfig.isEIP1559
				? ethers.formatEther(maxFeePerGas * gasLimit)
				: ethers.formatEther(gasPrice * gasLimit);
			return {
				maxPriorityFeePerGas,
				maxFeePerGas,
				gasLimit,
				gasFeeInNativeToken,
				nonce: transaction?.nonce,
				gasPrice,
			};
		},
		enabled: !isEmpty(transaction),
	});
	return response;
};
