import { motion, Transition, useAnimation } from 'framer-motion';
import { useEffect, useState } from 'react';

const transition: Transition = {
	repeatType: 'reverse',
	repeat: 1,
	ease: 'linear',
	duration: 10,
};
interface CopilotIconProps {
	skipAnimation?: boolean;
	onClick?: () => void;
}
export const CopilotIcon: React.FC<CopilotIconProps> = ({ skipAnimation = true, onClick }) => {
	const [isRotating, setIsRotating] = useState(false);
	const pinkTopAnimation = useAnimation();
	const pinkLefLAnimation = useAnimation();
	const blueMidAnimation = useAnimation();
	const blueRightAnimation = useAnimation();
	const intersectAnimation = useAnimation();
	const greenLeftAnimation = useAnimation();
	const greenRightAnimation = useAnimation();
	const pinkBottomAnimation = useAnimation();
	const highLightAnimation = useAnimation();
	const tobiIconAnimation = useAnimation();
	useEffect(() => {
		if (!isRotating) {
			return;
		}
		async function playAnimation() {
			await tobiIconAnimation.start({
				scale: 0,
				opacity: 1,
			});
			pinkTopAnimation.start({
				rotate: 320,
				filter: 'hue-rotate(-270deg)',
				opacity: 1,
			});
			pinkLefLAnimation.start({
				rotate: -360,
				filter: 'hue-rotate(-220deg)',
				opacity: 1,
			});
			blueMidAnimation.start({
				rotate: -360,
				filter: 'hue-rotate(-150deg)',
				rotateX: '75deg',
				opacity: 1,
			});
			blueRightAnimation.start({
				rotate: -360,
				filter: 'hue-rotate(720deg)',
				rotateY: '75deg',
				rotateZ: '-375deg',
				opacity: 1,
			});
			intersectAnimation.start({
				rotate: 30,
				filter: 'hue-rotate(0deg)',
				rotateX: '15deg',
				rotateY: '15deg',
				rotateZ: '15deg',
				perspective: '5px',
				opacity: 1,
			});
			greenLeftAnimation.start({
				rotate: 360,
				filter: 'hue-rotate(180deg)',
				rotateX: '75deg',
				rotateY: '-75deg',
				opacity: 1,
			});
			greenRightAnimation.start({
				rotate: -300,
				filter: 'hue-rotate(300deg)',
				rotateX: '15deg',
				rotateY: '-15deg',
				perspective: '-1px',
				opacity: 1,
			});
			pinkBottomAnimation.start({
				rotate: 400,
				filter: 'hue-rotate(0deg)',
				rotateX: '75deg',
				rotateY: '1deg',
				rotateZ: '0deg',
				opacity: 1,
			});
			await highLightAnimation.start({
				rotate: 360,
				filter: 'hue-rotate(0deg)',
				opacity: 1,
			});
			tobiIconAnimation.start({
				scale: 1,
			});
		}
		playAnimation();
		return () => {
			pinkTopAnimation.stop();
			pinkLefLAnimation.stop();
			blueMidAnimation.stop();
			blueRightAnimation.stop();
			intersectAnimation.stop();
			greenLeftAnimation.stop();
			greenRightAnimation.stop();
			pinkBottomAnimation.stop();
			highLightAnimation.stop();
			tobiIconAnimation.stop();
		};
	}, [isRotating]);

	useEffect(() => {
		if (skipAnimation) return;
		const intervalTime = setInterval(() => {
			setIsRotating((pre) => !pre);
		}, 30000);
		return () => clearInterval(intervalTime);
	}, [skipAnimation]);

	return (
		<motion.div
			style={{
				cursor: 'pointer',
				perspective: 1000,
				width: 48,
				height: 48,
				borderRadius: '100%',
				padding: 0,
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center',
				position: 'relative',
				background:
					'radial-gradient(79.72% 57.35% at -4.44% 68.05%, rgba(61, 48, 65, 0.88) 0%, rgba(61, 48, 65, 0.00) 100%), radial-gradient(31.9% 31.9% at 66.57% 97.34%, rgba(92, 71, 99, 0.80) 0%, rgba(92, 71, 99, 0.00) 100%), radial-gradient(50.01% 50.01% at 97.34% 33.88%, rgba(74, 143, 159, 0.85) 0%, rgba(74, 143, 159, 0.00) 100%), radial-gradient(44.65% 37.64% at 33.73% 16.42%, rgba(125, 113, 148, 0.90) 0%, rgba(125, 113, 148, 0.00) 100%), #081939',
			}}
			onClick={() => onClick?.()}
		>
			{/* pink top */}
			<motion.svg
				style={{
					position: 'absolute',
					top: 6,
					left: 15,
					zIndex: 1,
				}}
				initial={{
					rotate: -360,
					filter: 'hue-rotate(60deg)',
					opacity: 0.36,
				}}
				animate={pinkTopAnimation}
				transition={transition}
				xmlns="http://www.w3.org/2000/svg"
				width="12"
				height="19"
				viewBox="0 0 91 152"
				fill="none"
			>
				<path
					d="M2.25696 35.6812C-12.8783 -12.0605 56.9902 -13.2102 81.4383 42.6699C93.8002 70.9249 91.4287 111.095 88.7724 151.358C50.7792 106.713 10.1926 60.7128 2.25696 35.6812Z"
					fill="url(#paint0_linear_1_314)"
				/>
				<defs>
					<linearGradient
						id="paint0_linear_1_314"
						x1="0.132798"
						y1="33.3537"
						x2="84.5198"
						y2="19.3804"
						gradientUnits="userSpaceOnUse"
					>
						<stop stopColor="#E375A0" />
						<stop offset="1" stopColor="#E375A0" stopOpacity="0" />
					</linearGradient>
				</defs>
			</motion.svg>
			{/* pink left */}
			<motion.svg
				style={{
					position: 'absolute',
					top: 13,
					left: 9,
					zIndex: 2,
				}}
				initial={{
					rotate: 0,
					opacity: 0.36,
					filter: 'hue-rotate(300deg)',
				}}
				animate={pinkLefLAnimation}
				transition={transition}
				xmlns="http://www.w3.org/2000/svg"
				width="37"
				height="28"
				viewBox="0 0 37 28"
				fill="none"
			>
				<path
					d="M18.2786 27.6381C33.4805 24.9554 39.8902 11.8061 34.1379 3.43846C31.455 -0.464128 27.2534 0.260387 18.2786 0.797855C9.30378 1.33532 0.383934 2.84266 0.383789 14.573C0.383644 26.3033 11.2936 28.8707 18.2786 27.6381Z"
					fill="url(#paint0_radial_11448_5532)"
				/>
				<path
					d="M18.2786 27.6381C33.4805 24.9554 39.8902 11.8061 34.1379 3.43846C31.455 -0.464128 27.2534 0.260387 18.2786 0.797855C9.30378 1.33532 0.383934 2.84266 0.383789 14.573C0.383644 26.3033 11.2936 28.8707 18.2786 27.6381Z"
					fill="url(#paint1_linear_11448_5532)"
				/>
				<defs>
					<radialGradient
						id="paint0_radial_11448_5532"
						cx="0"
						cy="0"
						r="1"
						gradientUnits="userSpaceOnUse"
						gradientTransform="translate(9.04633 4.4901) rotate(69.3339) scale(17.3028 20.8411)"
					>
						<stop stopColor="#B850C1" />
						<stop offset="1" stopColor="#DCADE0" stopOpacity="0" />
					</radialGradient>
					<linearGradient
						id="paint1_linear_11448_5532"
						x1="19.4489"
						y1="0.342849"
						x2="19.2881"
						y2="5.72822"
						gradientUnits="userSpaceOnUse"
					>
						<stop stopColor="#B850C1" />
						<stop offset="1" stopColor="#DCADE0" stopOpacity="0" />
					</linearGradient>
				</defs>
			</motion.svg>
			{/* blue middle */}
			<motion.svg
				style={{
					position: 'absolute',
					top: 7,
					left: 14,
					zIndex: 3,
					transformStyle: isRotating ? 'preserve-3d' : 'unset',
					opacity: 0.36,
				}}
				animate={blueMidAnimation}
				initial={{
					rotate: 420,
					filter: 'hue-rotate(0deg)',
					rotateX: '375deg',
				}}
				transition={transition}
				xmlns="http://www.w3.org/2000/svg"
				width="28"
				height="33"
				viewBox="0 0 28 33"
				fill="none"
			>
				<g>
					<path
						d="M0.785156 20.8632C0.784888 13.0171 3.20981 5.69831 4.42991 2.23336C4.93193 0.807657 6.8107 0.401742 8.06327 1.24776C10.1961 2.53382 14.4929 5.65816 18.8302 8.6534C23.3374 11.7659 29.8368 15.2069 26.8539 25.4108C24.5939 33.142 13.5663 32.7922 13.5663 32.7922C13.5663 32.7922 0.785558 32.5795 0.785156 20.8632Z"
						fill="url(#paint0_linear_11448_5533)"
					/>
				</g>
				<defs>
					<linearGradient
						id="paint0_linear_11448_5533"
						x1="3.48342"
						y1="24.5555"
						x2="16.8326"
						y2="19.4431"
						gradientUnits="userSpaceOnUse"
					>
						<stop stopColor="#7EA1E4" />
						<stop offset="1" stopColor="#70CBFF" stopOpacity="0" />
					</linearGradient>
				</defs>
			</motion.svg>
			{/* blue right */}
			<motion.svg
				style={{
					position: 'absolute',
					top: 6,
					left: 12,
					zIndex: 4,
					transformStyle: isRotating ? 'preserve-3d' : 'unset',
				}}
				animate={blueRightAnimation}
				initial={{
					rotate: 420,
					filter: 'hue-rotate(-50deg)',
					rotateY: '0deg',
					rotateZ: '1125deg',
					opacity: 0.36,
				}}
				transition={transition}
				xmlns="http://www.w3.org/2000/svg"
				width="33"
				height="38"
				viewBox="0 0 33 38"
				fill="none"
			>
				<path
					d="M26.449 33.4344C32.6974 27.9671 34.8669 16.5386 30.6774 9.43825C26.488 2.3379 20.5194 0.684471 15.5532 0.917261C10.587 1.15005 0.499923 4.25525 0.0738773 15.4025C-0.352168 26.5498 5.4453 31.2779 11.5092 36.6053C13.8522 38.6637 20.2007 38.9016 26.449 33.4344Z"
					fill="url(#paint0_linear_11448_5534)"
				/>
				<defs>
					<linearGradient
						id="paint0_linear_11448_5534"
						x1="32.7694"
						y1="17.603"
						x2="16.4485"
						y2="24.9583"
						gradientUnits="userSpaceOnUse"
					>
						<stop stopColor="#5BAEEC" />
						<stop offset="1" stopColor="#5BAEEC" stopOpacity="0" />
					</linearGradient>
				</defs>
			</motion.svg>
			{/* intersect */}
			<motion.svg
				style={{
					position: 'absolute',
					top: 6,
					left: 12,
					zIndex: 5,
					transformStyle: isRotating ? 'preserve-3d' : 'unset',
				}}
				animate={intersectAnimation}
				initial={{
					rotate: -420,
					filter: 'hue-rotate(720deg)',
					rotateX: '15deg',
					rotateY: '15deg',
					rotateZ: '15deg',
					perspective: '-5px',
					opacity: 0.36,
				}}
				transition={transition}
				xmlns="http://www.w3.org/2000/svg"
				width="35"
				height="32"
				viewBox="0 0 35 32"
				fill="none"
			>
				<path
					d="M4.74059 29.0489C5.09786 29.2602 5.41698 29.4657 5.65545 29.6793C8.76575 32.4645 13.5338 32.169 18.583 30.8485C23.9088 29.4557 32.5472 26.9459 33.9497 20.2992C34.7443 16.5337 31.861 11.4756 27.9936 7.3716C24.4708 3.63317 20.1728 0.686437 16.2794 0.229616C15.3039 0.115157 14.1135 0.104341 12.8361 0.27058C5.44029 1.79642 1.44791 5.93148 0.838115 14.3466C0.228325 22.7617 0.916589 24.3861 4.74059 29.0489Z"
					fill="url(#paint0_linear_11448_5535)"
					fillOpacity="0.78"
				/>
				<defs>
					<linearGradient
						id="paint0_linear_11448_5535"
						x1="5.8384"
						y1="14.872"
						x2="27.486"
						y2="18.8966"
						gradientUnits="userSpaceOnUse"
					>
						<stop stopColor="#85FAFE" stopOpacity="0" />
						<stop offset="1" stopColor="#85FAFE" />
					</linearGradient>
				</defs>
			</motion.svg>
			{/* green left */}

			<motion.svg
				style={{
					position: 'absolute',
					top: 9,
					left: 7,
					zIndex: 6,
					transformStyle: isRotating ? 'preserve-3d' : 'unset',
				}}
				animate={greenLeftAnimation}
				initial={{
					rotate: -360,
					filter: 'hue-rotate(50deg)',
					rotateX: '75deg',
					rotateY: '0deg',
					opacity: 0.36,
				}}
				transition={transition}
				xmlns="http://www.w3.org/2000/svg"
				width="36"
				height="27"
				viewBox="0 0 36 27"
				fill="none"
			>
				<g>
					<path
						d="M0.654154 17.177C-0.504447 9.12775 2.90912 0.450195 10.7159 0.450195C16.8791 0.450195 20.3619 5.16197 25.0968 8.25697C30.9115 12.0578 35.3689 14.679 35.3689 18.529C35.3689 25.514 24.9548 29.2237 15.0216 25.2474C7.77392 22.3461 1.81275 25.2262 0.654154 17.177Z"
						fill="url(#paint0_radial_11448_5536)"
						fillOpacity="0.8"
					/>
				</g>
				<defs>
					<radialGradient
						id="paint0_radial_11448_5536"
						cx="0"
						cy="0"
						r="1"
						gradientUnits="userSpaceOnUse"
						gradientTransform="translate(9.71247 4.58389) rotate(84.3771) scale(15.8402 17.6917)"
					>
						<stop stopColor="#65ECE8" />
						<stop offset="1" stopColor="#8BECE9" stopOpacity="0" />
					</radialGradient>
				</defs>
			</motion.svg>
			{/* green right */}

			<motion.svg
				style={{
					position: 'absolute',
					top: 9,
					left: 7,
					zIndex: 7,
					transformStyle: isRotating ? 'preserve-3d' : 'unset',
				}}
				animate={greenRightAnimation}
				initial={{
					rotate: 360,
					filter: 'hue-rotate(-15deg)',
					rotateX: '15deg',
					rotateY: '15deg',
					perspective: '1px',
					opacity: 0.36,
				}}
				transition={transition}
				xmlns="http://www.w3.org/2000/svg"
				width="36"
				height="27"
				viewBox="0 0 36 27"
				fill="none"
			>
				<path
					d="M0.654154 17.177C-0.504447 9.12775 2.90912 0.450195 10.7159 0.450195C16.8791 0.450195 20.3619 5.16197 25.0968 8.25697C30.9115 12.0578 35.3689 14.679 35.3689 18.529C35.3689 25.514 24.9548 29.2237 15.0216 25.2474C7.77392 22.3461 1.81275 25.2262 0.654154 17.177Z"
					fill="url(#paint0_linear_11448_5537)"
				/>
				<defs>
					<linearGradient
						id="paint0_linear_11448_5537"
						x1="28.8426"
						y1="22.5887"
						x2="33.5046"
						y2="13.6667"
						gradientUnits="userSpaceOnUse"
					>
						<stop stopColor="#7BE7F6" stopOpacity="0" />
						<stop offset="0.75" stopColor="#7BE7F6" />
					</linearGradient>
				</defs>
			</motion.svg>
			{/* bottom pink */}

			<motion.svg
				style={{
					position: 'absolute',
					top: 25,
					left: 26,
					zIndex: 8,
					transformStyle: isRotating ? 'preserve-3d' : 'unset',
					opacity: 0.36,
				}}
				animate={pinkBottomAnimation}
				initial={{
					rotate: -360,
					filter: 'hue-rotate(230deg)',
					rotateX: '75deg',
					rotateY: '0deg',
					rotateZ: '0deg',
				}}
				transition={transition}
				xmlns="http://www.w3.org/2000/svg"
				width="10"
				height="19"
				viewBox="0 0 10 19"
				fill="none"
			>
				<path
					d="M1.54529 14.0108C4.10151 21.0406 10.5634 18.8383 9.56873 13.3005C9.09556 10.6663 5.1521 5.86264 0.948274 0.922852C0.631535 5.72377 0.281588 10.5356 1.54529 14.0108Z"
					fill="url(#paint0_radial_11448_5538)"
				/>
				<path
					d="M1.54529 14.0108C4.10151 21.0406 10.5634 18.8383 9.56873 13.3005C9.09556 10.6663 5.1521 5.86264 0.948274 0.922852C0.631535 5.72377 0.281588 10.5356 1.54529 14.0108Z"
					fill="url(#paint1_linear_11448_5538)"
				/>
				<defs>
					<radialGradient
						id="paint0_radial_11448_5538"
						cx="0"
						cy="0"
						r="1"
						gradientUnits="userSpaceOnUse"
						gradientTransform="translate(2.55976 16.7803) rotate(-68.2079) scale(9.94774 12.7717)"
					>
						<stop stopColor="#E375A0" stopOpacity="0" />
						<stop offset="1" stopColor="#FF91C6" />
					</radialGradient>
					<linearGradient
						id="paint1_linear_11448_5538"
						x1="1.56568"
						y1="3.00513"
						x2="2.27574"
						y2="7.83355"
						gradientUnits="userSpaceOnUse"
					>
						<stop stopColor="white" />
						<stop offset="1" stopColor="white" stopOpacity="0" />
					</linearGradient>
				</defs>
			</motion.svg>
			{/* hight light */}

			<motion.svg
				style={{
					position: 'absolute',
					top: 14,
					left: 18,
					zIndex: 9,
				}}
				animate={highLightAnimation}
				initial={{
					rotate: 250,
					filter: 'hue-rotate(230deg)',
					opacity: 0.36,
				}}
				transition={transition}
				xmlns="http://www.w3.org/2000/svg"
				width="12"
				height="15"
				viewBox="0 0 240 267"
				fill="none"
			>
				<g filter="url(#filter0_df_1_322)">
					<path
						d="M159.339 137.064C159.339 164.431 137.154 186.616 109.787 186.616C82.4207 186.616 60.2357 164.431 60.2357 137.064C60.2357 126.542 63.5156 116.785 69.1087 108.761C74.7663 100.645 78.6056 99.5912 72.1396 92.103C63.74 82.3754 56.3559 70.3109 97.5207 63.809C135.92 57.7409 155.234 55.467 144.879 90.9007C142.515 98.9914 145.128 101.327 150.048 108.171C155.895 116.305 159.339 126.282 159.339 137.064Z"
						fill="white"
					/>
				</g>
				<defs>
					<filter
						id="filter0_df_1_322"
						x="0.235733"
						y="0.259048"
						width="239.103"
						height="266.357"
						filterUnits="userSpaceOnUse"
						colorInterpolationFilters="sRGB"
					>
						<feFlood floodOpacity="0" result="BackgroundImageFix" />
						<feColorMatrix
							in="SourceAlpha"
							type="matrix"
							values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
							result="hardAlpha"
						/>
						<feOffset dx="10" dy="10" />
						<feGaussianBlur stdDeviation="35" />
						<feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 1 0" />
						<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1_322" />
						<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1_322" result="shape" />
						<feGaussianBlur stdDeviation="12.5" result="effect2_foregroundBlur_1_322" />
					</filter>
				</defs>
			</motion.svg>
			<motion.div
				style={{
					position: 'absolute',
					top: '50%',
					left: '50%',
					zIndex: 12,
					transform: 'translateZ(100px) translate(-50%, -50%)',
					transformStyle: 'preserve-3d',
				}}
			>
				<motion.svg
					initial={{
						scale: 1,
					}}
					animate={tobiIconAnimation}
					transition={{
						ease: 'linear',
						duration: 2,
					}}
					xmlns="http://www.w3.org/2000/svg"
					width={32}
					height={32}
					viewBox="0 0 64 64"
					fill="none"
				>
					<path
						d="M54.0544 41.9201L54.4631 42.3701C52.1704 49.1801 45.6911 54.0001 38.1751 54.0001H24.4789C19.3951 54.0001 14.9693 51.4501 12.3477 47.6301C13.7432 49.1301 15.7269 50.0801 17.9498 50.0801H38.1352C43.7074 50.0801 48.4323 46.0501 49.3992 40.5801L51.5125 40.7101C52.4794 40.7701 53.3965 41.2001 54.0544 41.9201Z"
						fill="#00E9DB"
						// fillOpacity="0.36"
					/>
					<path
						d="M44.9523 8H25.5244C24.9064 8 24.2884 8.03 23.6903 8.12C21.9957 8.2 20.3709 8.62 18.9155 9.33L19.0152 9.17C13.1539 6.05 5 10.76 5 10.76C9.21653 11.32 11.5391 14.94 12.516 16.94C12.3764 17.45 12.2568 17.97 12.1771 18.5L9.15672 38.59C8.86764 40.59 9.38598 42.51 10.4526 44C10.5124 44.08 10.5722 44.17 10.632 44.24C11.9877 45.99 14.0909 47.13 16.5032 47.13H35.971C40.4966 47.13 44.4439 44.39 46.1485 40.38L40.975 40.07C39.6393 39.99 38.4531 39.11 38.0743 37.82C37.9946 37.58 37.9447 37.33 37.9148 37.09C37.8849 36.85 37.865 36.61 37.865 36.37C37.865 35.7 37.9946 35.06 38.164 34.41C38.2537 34.09 38.3634 33.78 38.473 33.47C38.5926 33.15 38.7123 32.84 38.8319 32.54C39.3801 31.17 40.5962 29.61 42.0516 29.37C44.0452 29.02 46.1186 28.89 47.9826 28.86L49.7669 14.49C50.0062 12.57 49.2586 10.78 47.9428 9.59C50.7837 10.26 52.8072 13 52.4284 16.06L50.8634 28.88C51.5811 28.91 52.2191 28.94 52.7574 28.97C54.113 29.06 55.2095 29.83 56.017 30.9L56.944 21.1C57.6218 14.09 52.0596 8.02 44.9523 8.02V8ZM18.2377 24.83C17.0914 24.83 16.1643 23.9 16.1643 22.75C16.1643 21.6 17.0914 20.66 18.2377 20.66C19.384 20.66 20.321 21.59 20.321 22.75C20.321 23.91 19.394 24.83 18.2377 24.83ZM36.7485 27.46L36.6588 27.5C32.8709 29.39 28.4351 29.39 24.6472 27.5L24.5575 27.46C24.1089 27.24 23.8298 26.78 23.8298 26.28C23.8298 25.55 24.4179 24.96 25.1456 24.96H36.1704C36.8981 24.96 37.4862 25.55 37.4862 26.28C37.4862 26.78 37.1971 27.24 36.7585 27.46H36.7485ZM43.3275 24.33C42.1812 24.33 41.2541 23.4 41.2541 22.25C41.2541 21.1 42.1812 20.17 43.3275 20.17C44.4739 20.17 45.4109 21.1 45.4109 22.25C45.4109 23.4 44.4838 24.33 43.3275 24.33Z"
						fill="#00E9DB"
						// fillOpacity="0.36"
					/>
				</motion.svg>
			</motion.div>
		</motion.div>
	);
};
