import React from 'react';

interface IProps extends React.SVGAttributes<HTMLOrSVGElement> {
	size?: number;
}

export const FiCloseIcon: React.FC<IProps> = ({ size = 20, ...rest }) => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 20 20" fill="none" {...rest}>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M10 2.50016C5.85787 2.50016 2.5 5.85803 2.5 10.0002C2.5 14.1423 5.85787 17.5002 10 17.5002C14.1421 17.5002 17.5 14.1423 17.5 10.0002C17.5 5.85803 14.1421 2.50016 10 2.50016ZM0.833336 10.0002C0.833336 4.93755 4.93739 0.833496 10 0.833496C15.0626 0.833496 19.1667 4.93755 19.1667 10.0002C19.1667 15.0628 15.0626 19.1668 10 19.1668C4.93739 19.1668 0.833336 15.0628 0.833336 10.0002Z"
				fill="#777777"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M13.0893 6.91058C13.4147 7.23602 13.4147 7.76366 13.0893 8.08909L8.08925 13.0891C7.76382 13.4145 7.23618 13.4145 6.91074 13.0891C6.5853 12.7637 6.5853 12.236 6.91074 11.9106L11.9107 6.91058C12.2362 6.58514 12.7638 6.58514 13.0893 6.91058Z"
				fill="#777777"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M6.91074 6.91058C7.23618 6.58514 7.76382 6.58514 8.08925 6.91058L13.0893 11.9106C13.4147 12.236 13.4147 12.7637 13.0893 13.0891C12.7638 13.4145 12.2362 13.4145 11.9107 13.0891L6.91074 8.08909C6.5853 7.76366 6.5853 7.23602 6.91074 6.91058Z"
				fill="#777777"
			/>
		</svg>
	);
};
