import { axiosBotAPI } from '@/app-cores/api/axios';
import { ContactAccount, QueryParams } from './types';

class ContactManagement {
	async getContactList(
		queryParams: QueryParams,
	): Promise<{ walletUsers: ContactAccount[]; nonWalletUsers: ContactAccount[]; recentUsers: ContactAccount[] }> {
		const response = await axiosBotAPI.get('/accounts/contacts', {
			params: queryParams,
		});
		return response.data.data;
	}
	async getContactById(contactId: string): Promise<ContactAccount> {
		const response = await axiosBotAPI.get(`/accounts/contacts/telegram/${contactId}`);
		return response.data.data;
	}

	async getContactByWalletAddress(walletAddress: string): Promise<ContactAccount> {
		const response = await axiosBotAPI.get(`/accounts/contacts/wallet/${walletAddress}`);
		return response.data.data;
	}
}

export type IContactManagementAPI = typeof ContactManagement;

export const ContactManagementAPI = new ContactManagement();
