import { Addresses } from '@/app-components/common/AddressByChain/Addresses';
import {
	Center,
	Drawer,
	DrawerBody,
	DrawerCloseButton,
	DrawerContent,
	DrawerHeader,
	DrawerOverlay,
	Text,
} from '@chakra-ui/react';
import { useLocation, useNavigate } from 'react-router';
import { useDepositStore } from '../store';
import { NetWorkChain } from '@/app-contexts/wallet-provider/type';
import { useBuyOnTransak } from '@/app-hooks/common';
import { DATADOG_ACTIONS, dataDogAddAction } from '@/app-services/monitor/datadog';
import { ChainId } from '@/app-constants/chains';

export const NetworkSelection = () => {
	const { isOpenNetworkSelection, onToggleNetworkSelection, onClose } = useDepositStore();
	const { buildTransakURL } = useBuyOnTransak();
	const navigate = useNavigate();
	const { pathname } = useLocation();
	const handleSelectNetwork = (network: NetWorkChain) => {
		const url = buildTransakURL({
			chainId: network.id as string,
			callbackUrl: pathname,
		});
		onToggleNetworkSelection(false);
		onClose();
		const networkName = network.id === ChainId.TON ? 'TON' : network.id === ChainId.SOL ? 'SOL' : 'EVM';
		dataDogAddAction(DATADOG_ACTIONS.DEPOSIT_BY_TRANSAK_SELECTED[networkName]);
		navigate(url);
	};
	return (
		<Drawer
			isOpen={isOpenNetworkSelection}
			placement="bottom"
			onClose={() => onToggleNetworkSelection(false)}
			trapFocus={false}
		>
			<DrawerOverlay />
			<DrawerContent>
				<DrawerCloseButton top="0.75rem" />
				<DrawerHeader borderBottomWidth="1px" borderBottomColor="gray.100">
					<Center>
						<Text fontSize="medium" fontWeight={600}>
							Buy with Fiat
						</Text>
					</Center>
				</DrawerHeader>

				<DrawerBody mt={3}>
					<Addresses showCopyAndQRCode={false} onItemClick={handleSelectNetwork} />
				</DrawerBody>
			</DrawerContent>
		</Drawer>
	);
};
