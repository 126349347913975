import { ITokenSearch } from '@/app-cores/api/bff';
import { Box, Center, Flex, Text } from '@chakra-ui/react';
import TokenLogo from '../Avatar/TokenLogo';
import { CHAIN_CONFIG } from '@/app-constants/chains';
import { formatCurrency, formatUnits, formatUsd } from '@/app-helpers/number';
import { getTokenInfo } from '@/app-helpers/token';

interface TokenCardProps {
	token: ITokenSearch;
	tokenPriceUsd: number;
}
export const TokenCard: React.FC<TokenCardProps> = ({ token }) => {
	const tokenInfo = getTokenInfo(token);
	return (
		<Box>
			<Flex justifyContent="space-between">
				<Center gap={3}>
					<TokenLogo
						size={32}
						symbol={tokenInfo?.symbol}
						logo={tokenInfo?.logo}
						chainId={tokenInfo?.chainId}
					/>
					<Flex flexDirection="column">
						<Text fontWeight={600} fontSize="sm">
							{tokenInfo.name}
						</Text>
						<Text color="gray.400" fontSize="xs">
							{tokenInfo.symbol} • {CHAIN_CONFIG[tokenInfo.chainId].name}
						</Text>
					</Flex>
				</Center>
				<Center flexDirection="column" alignItems="flex-end">
					<Text fontSize="xs" fontWeight={500}>
						{formatUsd(+tokenInfo?.usdValue)}
					</Text>
					<Text fontSize="xs">{formatUnits(tokenInfo?.balance, tokenInfo?.decimals)}</Text>
				</Center>
			</Flex>
		</Box>
	);
};
