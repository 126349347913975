// import { useInAppAConnect } from '../hooks';
import { useBotConnector } from '../hooks';
import { BotConnectorRequestModal } from './WalletConnectRequestModal';

export const BotConnector = () => {
	useBotConnector();

	return (
		<>
			{/* <ConnectButton /> */}
			<BotConnectorRequestModal />
		</>
	);
};
