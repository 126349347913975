import { Header } from '@/app-components/common';
import PageTransition from '@/app-components/layout/PageTransition/PageTransition';
import { Box, Button, Container, Heading, Text } from '@chakra-ui/react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import PageNavigation from '@/app-components/layout/PageWrapper/PageWrapper';

export const DepositTransactionResult = () => {
	const navigate = useNavigate();
	const [searchParams] = useSearchParams();
	const cryptoAmount = searchParams.get('amount');
	const cryptoSymbol = searchParams.get('symbol');
	const callbackUrl = searchParams.get('callbackUrl');

	const onClickCheckBalance = () => {
		navigate('/');
	};
	return (
		<PageTransition>
			<PageNavigation
				onBack={() => {
					if (callbackUrl && callbackUrl !== 'null') {
						history.replaceState(null, null, '/');
						return navigate(callbackUrl);
					}
				}}
			>
				<Container className="pageContent">
					<Box className="pageFixed" py={4}>
						<Header>Tobi</Header>
						<Box
							display={'flex'}
							flexDirection="column"
							alignItems={'center'}
							justifyContent={'center'}
							flexGrow={1}
							pb={5}
							px={6}
						>
							<Heading mb={3} display="flex">
								{cryptoAmount} {cryptoSymbol}
							</Heading>
							<Text fontSize="28px" fontWeight={700} textAlign={'center'}>
								has been successfully deposited to your balance
							</Text>
						</Box>
						<Box px={4}>
							<Button
								colorScheme="cyan"
								onClick={onClickCheckBalance}
								borderRadius={'1rem'}
								size="lg"
								fontWeight="medium"
								width="100%"
							>
								Check your balance
							</Button>
						</Box>
					</Box>
				</Container>
			</PageNavigation>
		</PageTransition>
	);
};
