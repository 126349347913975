import { BaseInput, ButtonSecondary, CTAButton, InputSearch, Loading, Numeric, Toast } from '@/app-components/common';
import {
	Box,
	Button,
	Card,
	CardBody,
	Center,
	Divider,
	Flex,
	HStack,
	Image,
	List,
	ListItem,
	Text,
	VStack,
	useDisclosure,
	Avatar,
	InputGroup,
	InputLeftElement,
} from '@chakra-ui/react';
import React, { ReactNode, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TelegramCore } from '@/app-cores/telegram';
import { FiArrowRightIcon, FiEditIcon, FiMailIcon, FiPlusIcon, FiSettingIcon } from '@/assets/icons';

import { useNavigate } from 'react-router';
import { NAVIGATE_PATHS } from '@/app-constants/router';
import { formatNumber, formatUnits, formatUsd, truncateToFixed } from '@/app-helpers/number';
import { BASE_BORDER_COLOR, colors } from '@/app-theme/theme';

import { ContactAccount } from '@/app-cores/api/contact';
import { useRequestFundStore } from '@/app-store/request-fund';
import { RequestFundLayout, UserInfoRequestFund } from '@/app-views/request-fund';
import { FiTrashIcon } from '@/assets/icons/fi-trash-icon';
import { toast } from 'react-toastify';

const UserItem = ({
	data,
	amount,
	onChangeAmount,
	onRemoveFriend,
}: {
	data: ContactAccount;
	amount: string;
	onChangeAmount: (id: string, v: string) => void;
	onRemoveFriend: (v: ContactAccount) => void;
}) => {
	const { _id } = data;

	return (
		<Flex p={0} align={'center'} gap={4}>
			<Card flex={1} flexDirection={'row'} p={2} gap={4} justifyContent={'space-between'} align={'center'}>
				<Flex flex={1}>
					<UserInfoRequestFund data={data} maxLengthUsername={22} />
				</Flex>
				<Numeric
					useIosCustom={false}
					prefix={'$'}
					allowNegative={false}
					value={amount}
					allowLeadingZeros
					placeholder="$0"
					inputMode="decimal"
					inputType={'number'}
					style={{
						border: `1px solid ${BASE_BORDER_COLOR}`,
						textAlign: 'right',
						fontWeight: '500',
						fontSize: '14px',
						outline: 'none',
						padding: '12px 16px',
						boxShadow: 'none',
						height: '42px',
						borderRadius: '8px',
						width: '80px',
					}}
					onValueChange={(value, source) => {
						if (source.source === 'prop') return;
						onChangeAmount?.(_id, value.value);
					}}
				/>
			</Card>
			<FiTrashIcon style={{ minWidth: '18px', minHeight: '18px' }} onClick={() => onRemoveFriend(data)} />
		</Flex>
	);
};

const CreateRequestFund = () => {
	const { t } = useTranslation();
	const {
		friends = [],
		setFriends,
		setAmount,
		amountMapping,
		message,
		setMessage,
		totalAmount,
		setTotalAmount,
	} = useRequestFundStore();

	const navigate = useNavigate();

	const checkChangeTotalAmount = (newMap: Record<string, string>, friends: ContactAccount[]) => {
		const isFillAll = friends.every((e) => !!newMap[e._id]);
		const currentTotal = Object.values(newMap).reduce((rs, item) => rs + +item, 0);
		if (currentTotal > +totalAmount || isFillAll) setTotalAmount(currentTotal.toString());
	};

	const onRemoveFriend = (data: ContactAccount) => {
		const newFriends = friends.filter((e) => e._id !== data._id);
		setFriends(newFriends);
		setAmount(data._id, '');
		const { [data._id]: _, ...newMap } = amountMapping;
		checkChangeTotalAmount(newMap, newFriends);
	};

	const onChangeAmount = (id: string, amount: string) => {
		const newMap = { ...amountMapping, [id]: amount };
		if (amount) {
			checkChangeTotalAmount(newMap, friends);
		}
		setAmount(id, amount);
	};

	const splitEqually = () => {
		if (!totalAmount.trim()) return;
		friends.forEach((el) => {
			setAmount(el._id, (+totalAmount / friends.length).toString());
		});
	};

	const onChangeTotal = (total) => {
		setTotalAmount?.(total);
		if (friends.length === 1) {
			setAmount(friends[0]._id, total);
		}
	};

	const onNext = () => {
		if (!friends.length) {
			toast(<Toast type="error" message={t('requestPayment.emptyPayer')} />);
			return;
		}
		if (friends.some((el) => !amountMapping[el._id]) || +totalAmount === 0) {
			toast(<Toast type="error" message={t('requestPayment.missingAmount')} />);
			return;
		}
		const total = friends.reduce((rs, el) => rs + +(amountMapping[el._id] || 0), 0);
		if (Math.abs(total - +totalAmount) > 0.000001) {
			toast(<Toast type="error" message={t('requestPayment.totalAmountMismatch', { total: totalAmount })} />);
			return;
		}
		navigate(NAVIGATE_PATHS.RequestFund.Confirm);
	};

	return (
		<RequestFundLayout
			title={t('requestPayment.editAmount')}
			action={
				<Flex p={4} py={3} align={'center'} gap={3} flexDirection={'column'} background={colors.gray[100]}>
					<InputGroup>
						<InputLeftElement pointerEvents="none">
							<FiEditIcon style={{ transform: 'translateY(2px)' }} />
						</InputLeftElement>
						<BaseInput
							value={message}
							onChange={(e) => setMessage(e.target.value)}
							placeholder="Message"
							size={'lg'}
							paddingLeft={'40px'}
							sx={{
								background: 'white',
								borderRadius: '8px',
								border: 'none',
								fontSize: '14px',
							}}
						/>
					</InputGroup>
					<CTAButton
						width={'100%'}
						size={'lg'}
						colorScheme={'cyan'}
						minHeight={'56px'}
						flex={1}
						onClick={onNext}
					>
						Continue
					</CTAButton>
				</Flex>
			}
		>
			<Flex direction={'column'} gap={4} py={3} pb={4} flex={1} height={'100%'}>
				<VStack align={'center'} minW={'120px'} spacing={2} justify={'center'}>
					<Text fontSize={'12px'} color={colors.gray[400]}>
						Total
					</Text>
					<Flex align={'center'}>
						<Numeric
							useIosCustom={false}
							autoFocus
							prefix={'$'}
							allowNegative={false}
							value={truncateToFixed(totalAmount, 2)}
							allowLeadingZeros
							placeholder="$0"
							inputMode="decimal"
							inputType={'number'}
							style={{
								textAlign: 'center',
								backgroundColor: 'transparent',
								fontWeight: '700',
								fontSize: '48px',
								outline: 'none',
								lineHeight: '48px',
								height: '48px',
								maxWidth: '90vw',
								width: 'fit-content',
								caretColor: colors.cyan[500],
								border: 'none',
								boxShadow: 'none',
							}}
							onValueChange={(value, source) => {
								if (source.source === 'prop') return;
								onChangeTotal?.(value.value);
							}}
						/>
					</Flex>
					<ButtonSecondary size={'sm'} onClick={splitEqually}>
						{t('requestPayment.splitEqually')}
					</ButtonSecondary>
				</VStack>

				<Flex direction={'column'} gap={2}>
					<Text fontSize={'12px'} color={colors.gray[400]}>
						{friends.length} Recipient
					</Text>
					<List spacing={4}>
						{friends?.map((el) => {
							return (
								<ListItem key={el._id}>
									<UserItem
										data={el}
										amount={amountMapping[el._id]}
										onChangeAmount={onChangeAmount}
										onRemoveFriend={onRemoveFriend}
									/>
								</ListItem>
							);
						})}
					</List>

					<Center mt={2}>
						<ButtonSecondary background={colors.gray[100]} size={'sm'} width={'42px'} borderRadius={'8px'}>
							<FiPlusIcon onClick={() => navigate(-1)} style={{ minWidth: '20px' }} />
						</ButtonSecondary>
					</Center>
				</Flex>
			</Flex>
		</RequestFundLayout>
	);
};

export default CreateRequestFund;
