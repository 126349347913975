import { ITokenSearch, TokenInfo } from '@/app-cores/api/bff';

import { create } from 'zustand';

import { InputMode } from '@/app-store/swap';
import { getTokenInfo } from '@/app-helpers/token';
export enum OrderMode {
	SELL = 'Sell',
	BUY = 'Buy',
}

export const DEFAULT_ORDER_MODE = OrderMode.BUY;

const initializeData = {
	tokenIn: undefined,
	tokenOut: undefined,
	tokenInfoIn: undefined,
	tokenInfoOut: undefined,
	orderMode: DEFAULT_ORDER_MODE,
	amountOut: '',
	amountIn: '',
	rate: '',
	inputMode: InputMode.AMOUNT,
	amountInUsd: '',
	amountOutUsd: '',
	selectedRoute: undefined,
	expiredAt: null,
	cancellingOrders: {},
};
interface ISwapStore {
	tokenIn: ITokenSearch | undefined; // display in token select
	tokenOut: ITokenSearch | undefined;
	tokenInfoIn: TokenInfo;
	tokenInfoOut: TokenInfo;
	orderMode: OrderMode;
	amountIn: string;
	amountOut: string;
	amountInUsd: string;
	amountOutUsd: string;
	inputMode: InputMode;
	rate: string;
	expiredAt: Date | null | number; // null: never, number: 1 days 2 days, Date: custom
	cancellingOrders: Record<string, any>;
	setAmountIn: (amount: string, amountInUsd: string) => void;
	setAmountOut: (amount: string, amountInUsd: string) => void;
	setRate: (amount: string) => void;
	setInputMode: (v: InputMode) => void;
	setTokenIn: (token: ITokenSearch | undefined) => void;
	setTokenOut: (token: ITokenSearch | undefined) => void;
	setPair: (tokenIn: ITokenSearch | undefined, tokenOut: ITokenSearch | undefined) => void;
	setExpire: (v: number | null | Date) => void;
	setOrderMode: (v: OrderMode) => void;
	setCancellingOrders: (data: { id: string; cancelling: boolean }) => void;
	reset: () => void;
}

export const useLimitStore = create<ISwapStore>()((set) => ({
	...initializeData,
	setInputMode: (inputMode) => {
		set({ inputMode });
	},
	setExpire: (expiredAt) => {
		set({ expiredAt });
	},
	setAmountIn: (amountIn, amountInUsd) => {
		set((state) => {
			return { ...state, amountIn, amountInUsd };
		});
	},
	setOrderMode: (orderMode) => {
		set((state) => {
			return { ...state, orderMode };
		});
	},
	setAmountOut: (amountOut, amountOutUsd) => {
		set((state) => {
			return { ...state, amountOut, amountOutUsd };
		});
	},
	setRate: (rate) => {
		set((state) => {
			return { ...state, rate };
		});
	},
	setCancellingOrders: ({ id, cancelling }) => {
		set((state) => {
			const cancellingOrders = { ...state.cancellingOrders };
			if (cancelling) cancellingOrders[id] = true;
			else delete cancellingOrders[id];
			return { ...state, cancellingOrders };
		});
	},
	setTokenIn: (tk) =>
		set((state) => {
			return {
				tokenIn: tk,
				tokenInfoIn: getTokenInfo(tk),
			};
		}),
	setTokenOut: (tk) =>
		set((state) => {
			return { tokenOut: tk, tokenInfoOut: getTokenInfo(tk) };
		}),
	setPair: (tokenIn, tokenOut) =>
		set(() => {
			return {
				tokenIn,
				tokenOut,
				tokenInfoIn: getTokenInfo(tokenIn),
				tokenInfoOut: getTokenInfo(tokenOut),
				sellToken: tokenIn,
				buyToken: tokenOut,
			};
		}),
	reset: () =>
		set((state) => ({
			...state,
			...initializeData,
			cancellingOrders: state.cancellingOrders, // don't reset
		})),
}));
