import { TobiFarmType } from '@/app-hooks/api/dapps/useTobiFarm';
import { axiosBotAPI } from '../axios';
import { DappQuery, GetDappRes } from './type';

class MarketingService {
	async getDapp(params: DappQuery): Promise<GetDappRes> {
		const response = await axiosBotAPI.get('dapp', {
			params: params,
		});
		return response.data;
	}
	async getTobiFarm(): Promise<TobiFarmType[]> {
		const response = await axiosBotAPI.get('marketing/farm', {});
		return response.data?.data ?? [];
	}
}

export const MarketingServiceAPI = new MarketingService();
