import { ITokenSearch } from '@/app-cores/api/bff';
import { ListCryptoItem } from '@/app-views/portfolio/components/ListCrypto';
import { Box, Button, ButtonProps, Flex, List, Stack, useDisclosure } from '@chakra-ui/react';

import { LocalLoader } from '@/app-components/common';
import NoData from '@/app-components/common/NoData';
import { useGetTokenLabels } from '@/app-hooks/api/tokens/useUserTokenSettings';
import { MAP_TOKEN_LIST_LABEL } from '@/app-views/wallet/components/Portfolio';
import TokenFilterDrawer, { TokenListOptions } from '@/app-views/wallet/components/Portfolio/TokenFilterDrawer';
import { ChevronDownIcon } from '@/assets/images/svg/chevron-down-icon';
import debounce from 'lodash/debounce';
import { CSSProperties, memo, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { FixedSizeList } from 'react-window';
import { FiPlusIcon } from '../../../../assets/icons';
import { GasWarning } from './GasWarning';
import ImportToken from './ImportToken';
import { usePortfolioState } from './state';
import { FeatureConfigWrap } from '../../../../app-helpers/components';
import { FeatureConfig } from '../../../../app-hooks/api/configuration';
import { ELEMENT_IDS } from '@/app-constants';
import NoAsset from '@/app-views/wallet/components/Portfolio/NoAsset';
import { getTokenId, getTokenInfo, isTronChain } from '@/app-helpers/token';
import { navigateToTokenDetail } from '@/app-helpers/navigate';
import { colors } from '@/app-theme/theme';
import Warning from '@/app-components/common/Warning';

const WrapListCryptoItem = ({ data, index, style }: { data: ITokenSearch[]; index: number; style: CSSProperties }) => {
	const getTokenLabels = useGetTokenLabels();
	const item = data[index];
	return (
		<ListCryptoItem
			style={style}
			getTokenLabels={getTokenLabels}
			data={item}
			onClick={() => {
				const { idTobi } = getTokenInfo(item);
				navigateToTokenDetail({ tobiId: idTobi });
			}}
		/>
	);
};

const chunkSize = 30;
const maxToken = 500;
const ListTokenLocal = memo(({ balances }: { balances: ITokenSearch[] }) => {
	const [visibleTokens, setVisibleTokens] = useState(balances.slice(0, chunkSize));
	const totalVisible = useRef(chunkSize);
	totalVisible.current = Math.max(visibleTokens.length, chunkSize);

	useEffect(() => {
		setVisibleTokens(balances.slice(0, totalVisible.current));
	}, [balances]);

	const scrolling = useRef(false);

	const onReset = useMemo(
		() =>
			debounce(() => {
				setTimeout(() => {
					scrolling.current = false;
				}, 500);
			}, 500),
		[],
	);

	useEffect(() => {
		const node = document.getElementById(ELEMENT_IDS.HOME_CONTAINER);
		if (!node) return;
		const onscroll = function () {
			// Check if the user has scrolled to the bottom
			if (
				window.innerHeight + window.scrollY >= document.body.offsetHeight &&
				visibleTokens.length < balances.length &&
				visibleTokens.length < maxToken &&
				!scrolling.current
			) {
				scrolling.current = true;
				setVisibleTokens(balances.slice(0, visibleTokens.length + chunkSize));
				onReset();
			}
		};
		node.addEventListener('scroll', onscroll);
		return () => {
			node.removeEventListener('scroll', onscroll);
		};
	}, [balances, visibleTokens.length, onReset]);

	return (
		<Box style={{ flex: 1 }}>
			{visibleTokens.length > 0 && (
				<List>
					<FixedSizeList
						overscanCount={10}
						height={itemSize * visibleTokens.length}
						itemCount={visibleTokens.length}
						itemSize={itemSize}
						width={'100%'}
						itemData={visibleTokens}
						itemKey={(item, data) => getTokenId(data[item])}
					>
						{WrapListCryptoItem}
					</FixedSizeList>
				</List>
			)}
		</Box>
	);
});

const itemSize = 55;

export enum TokenTypes {
	DEFAULT = '',
	ALL = 'main',
	HIDDEN = 'hidden',
	SPAM = 'spam',
	IMPORTED = 'custom',
	TESTNET = 'testnet',
}

const btnProps: ButtonProps = {
	fontSize: 'xs',
	borderRadius: '1rem',
	colorScheme: 'white',
	color: 'black',
	size: 'sm',
	gap: '4px',
	sx: { background: 'gray.100', borderRadius: '68px', padding: '4px 12px', alignItems: 'center' },
};

export default function ListTokens({
	balances: visibleBalances,
	tokenType,
	setTokenType,
	tokenOptions,
	isLoading,
	showGasWarning,
	emptyPortfolio,
}: {
	balances: ITokenSearch[] | undefined;
	isLoading: boolean;
	tokenType: TokenTypes;
	setTokenType: (v: TokenTypes) => void;
	tokenOptions: TokenListOptions;
	showGasWarning: boolean;
	emptyPortfolio: boolean;
}) {
	const { isOpen, onOpen, onClose } = useDisclosure();
	const { isOpen: isOpenFilter, onOpen: onOpenFilter, onClose: onCloseFilter } = useDisclosure();
	const { selectedChain } = usePortfolioState();
	const { t } = useTranslation();

	return (
		<Flex gap={'12px'} direction={'column'}>
			<Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'}>
				<Button {...btnProps} onClick={onOpenFilter}>
					{visibleBalances.length} {t(MAP_TOKEN_LIST_LABEL[tokenType])}
					<ChevronDownIcon height={22} />
				</Button>
				<TokenFilterDrawer
					isOpen={isOpenFilter}
					onClose={onCloseFilter}
					options={tokenOptions}
					onSelect={(val: TokenTypes) => setTokenType(val)}
					selected={tokenType}
				/>

				<FeatureConfigWrap feature={FeatureConfig.IMPORT_TOKEN}>
					<Flex gap={'6px'}>
						<Button
							{...btnProps}
							onClick={() => {
								onOpen();
							}}
						>
							<FiPlusIcon width={'16px'} />
							{t('cryptos.import')}
							<ImportToken isOpen={isOpen} onClose={onClose} />
						</Button>
					</Flex>
				</FeatureConfigWrap>
			</Stack>
			{!!showGasWarning &&
				selectedChain &&
				(isTronChain(selectedChain) ? (
					<Warning background={colors.gray[100]} msg={t('cryptos.tronWarning')} />
				) : (
					<GasWarning chainId={selectedChain} />
				))}
			{isLoading ? (
				<LocalLoader height="150px" />
			) : !visibleBalances.length ? (
				emptyPortfolio ? (
					<NoAsset style={{ border: 'none', padding: 0 }} />
				) : (
					<NoData msg={t('cryptos.noTokenInThisList')} />
				)
			) : (
				<ListTokenLocal balances={visibleBalances} />
			)}
		</Flex>
	);
}
