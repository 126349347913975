import { axiosBotAPI } from '@/app-cores/api/axios';

export const PhantomConnectService = {
	getCallbackData: async (state: string, abortController: AbortController): Promise<string> => {
		const response = await axiosBotAPI.get(`/dapp/phantom/polling/${state}`, {
			signal: abortController.signal,
		});
		return response.data.data as string;
	},
};
