import { Collapse, Divider, Flex, FlexProps, Text, useDisclosure } from '@chakra-ui/react';

import ApproveModal from '@/app-components/ApproveModal';

import { InputMode } from '@/app-store/swap';
import { TransactionType } from '@/app-types';

import BuyToken from '@/app-views/swap/components/BuyToken';
import { AmountInput, SelectTokenLabel, TokenAmountInput } from '@/app-components/common/SelectToken/TokenAmount';
import Warning from '@/app-components/common/Warning';

import { SwapErrorInfo } from '@/app-hooks/swap';
import { ITokenSearch } from '@/app-cores/api/bff';
import { OnValueChange } from 'react-number-format';
import { SwapErrorType } from '@/app-hooks/swap/type';
import { OrderMode, useLimitStore } from '@/app-store/swap/limit';
import { AmountSuggestion } from '@/app-components/common';
import { ONE_DAY } from '@/app-hooks/api/portfolio/constant';
import { formatDisplayExpiredTime } from '@/app-hooks/limit/helper';
import { formatCurrency, formatNumber } from '@/app-helpers/number';
import { useState } from 'react';
import DatePicker from '@/app-components/common/DatePicker';
import { LimitOrderConfig } from '@/app-hooks/limit';

type FormProps = {
	onSelectTokenIn: (token: ITokenSearch) => void;
	onSelectTokenOut: (token: ITokenSearch) => void;
	onMaxBalanceOut: () => void;
	onMaxBalanceIn: () => void;
	onChangeAmount: OnValueChange;
	onChangeAmountOut: OnValueChange;
	onChangeRate: OnValueChange;
	hideApproveModal: () => void;
	toggleInputMode: () => void;
	onClickMarket: () => void;

	openApprove: boolean;
	parsedAmount: bigint | undefined;
	isNonWallet: boolean;
	containerProps: FlexProps;
	usdPriceTokenIn: string | number | undefined;
	usdPriceTokenOut: string | number | undefined;
	errorMsg: SwapErrorInfo;
	limitOrderConfig: LimitOrderConfig | undefined;
	marketRate: number;
	orderMode: OrderMode;
	allowSwitchAmountMode: boolean;
};

export default function LimitForm(props: FormProps) {
	const [customExpireDate, setCustomExpireDate] = useState<Date>();

	const { isOpen, onOpen, onClose } = useDisclosure();

	const expiredOptions = [
		...[1, 7, 30].map((days) => ({ label: formatDisplayExpiredTime(ONE_DAY * days), value: ONE_DAY * days })),
		{ label: 'Never', value: null },
		{ label: 'Custom', value: customExpireDate, onClick: onOpen },
	];

	const {
		expiredAt,
		amountIn,
		amountOut,
		amountInUsd,
		amountOutUsd,
		rate,
		inputMode,
		tokenIn,
		tokenOut,
		setExpire,
		tokenInfoIn,
		tokenInfoOut,
	} = useLimitStore();

	const onSelectCustomExpireDate = (v: Date) => {
		setCustomExpireDate(v);
		setExpire(v || null);
	};

	const tokenAmountMode = inputMode === InputMode.AMOUNT;
	const {
		onMaxBalanceIn,
		onMaxBalanceOut,
		errorMsg,
		orderMode,
		onChangeAmount,
		onChangeAmountOut,
		onChangeRate,
		openApprove,
		hideApproveModal,
		parsedAmount,
		usdPriceTokenIn,
		usdPriceTokenOut,
		toggleInputMode,
		isNonWallet,
		containerProps,
		limitOrderConfig,
		marketRate,
		onClickMarket,
		allowSwitchAmountMode,
	} = props;

	const propsIn = {
		label: `Amount in ${tokenInfoIn ? tokenInfoIn?.symbol : ''}`,
		token: tokenIn,
		tokenInfo: tokenInfoIn,
		value: tokenAmountMode ? amountIn : amountInUsd,
		usdValue: tokenAmountMode ? amountInUsd : amountIn,
		onValueChange: onChangeAmount,
		onMax: onMaxBalanceIn,
		usdPrice: usdPriceTokenIn,
		inputStyle: {
			color: errorMsg?.messages?.some((e) => e.errorType === SwapErrorType.VALIDATE_AMOUNT) ? 'red' : undefined,
		},
	};

	const propsOut = {
		label: `Total ${tokenInfoOut ? `(${tokenInfoOut?.symbol})` : ''}`,
		token: tokenOut,
		tokenInfo: tokenInfoOut,
		value: tokenAmountMode ? amountOut : amountOutUsd,
		usdValue: tokenAmountMode ? amountOutUsd : amountOut,
		onValueChange: onChangeAmountOut,
		onMax: onMaxBalanceOut,
		usdPrice: usdPriceTokenOut,
		inputStyle: {
			color: errorMsg?.messages?.some((e) => e.errorType === SwapErrorType.VALIDATE_AMOUNT_OUT)
				? 'red'
				: undefined,
		},
	};

	const renderTokenInput = ({ usdValue, ...props }: typeof propsIn) => (
		<TokenAmountInput
			inputMode={inputMode}
			toggleInputMode={toggleInputMode}
			prefix={props.tokenInfo?.symbol}
			usdValue={tokenAmountMode ? '' : usdValue}
			allowSwitchAmountMode={allowSwitchAmountMode}
			{...props}
		/>
	);

	const isSellMode = orderMode === OrderMode.SELL;

	const marketText = formatCurrency(marketRate);
	return (
		<>
			<Flex flexDirection={'column'} flex={1} gap={'16px'} {...containerProps}>
				<Divider variant={'dashed'} />
				<AmountInput
					label={`Price ${
						tokenInfoIn && tokenInfoOut ? `(${tokenInfoIn.symbol}/${tokenInfoOut.symbol})` : ``
					}`}
					token={tokenOut}
					value={rate}
					onValueChange={onChangeRate}
					placeholder="Price"
					action={
						<SelectTokenLabel onClick={onClickMarket} text={marketText ? `Market: ${marketText}` : ''} />
					}
				/>

				{renderTokenInput(propsIn)}
				{renderTokenInput(propsOut)}

				<Collapse in={!!errorMsg?.messages.length}>
					<Flex flexDirection="column" gap="14px">
						{errorMsg.messages.map((e) =>
							[SwapErrorType.VALIDATE_AMOUNT, SwapErrorType.VALIDATE_AMOUNT_OUT].includes(
								e.errorType,
							) ? null : [SwapErrorType.FUND, SwapErrorType.GAS].includes(e.errorType) ? (
								<BuyToken
									errorMsg={e}
									key={e.msg}
									tokenInfoOut={isSellMode ? tokenInfoOut : tokenInfoIn}
									tokenInfoIn={isSellMode ? tokenInfoIn : tokenInfoOut}
									buyNative={e.errorType === SwapErrorType.GAS}
									isNonWallet={isNonWallet}
									tokenIn={isSellMode ? tokenIn : tokenOut}
								/>
							) : (
								<Warning key={e.msg} msg={e.msg} title={e?.title} status={e?.uiType || e?.type} />
							),
						)}
					</Flex>
				</Collapse>

				<Divider variant={'dashed'} />

				<Text fontSize={'14px'} fontWeight={'600'}>
					Expiry
				</Text>

				<AmountSuggestion
					itemStyle={{ background: 'gray.100' }}
					onSelect={(v, opt) => {
						if (opt.onClick) return;
						setExpire(v);
						setCustomExpireDate(undefined);
					}}
					value={expiredAt}
					data={expiredOptions}
				/>
			</Flex>

			<DatePicker isOpen={isOpen} onClose={onClose} date={customExpireDate} onChange={onSelectCustomExpireDate} />

			<ApproveModal
				isOpen={openApprove}
				approveInfo={{
					chainId: tokenInfoIn?.chainId,
					contract: limitOrderConfig?.contractAddress,
					amount: parsedAmount?.toString() ?? '',
					token: tokenInfoIn,
					type: TransactionType.Swap,
				}}
				dappInfo={limitOrderConfig?.dappInfo}
				onClose={hideApproveModal}
			/>
		</>
	);
}
