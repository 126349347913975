/* eslint-disable react-hooks/rules-of-hooks */
import { useCallback, useMemo, useState } from 'react';
import {
	Card,
	Center,
	Divider,
	Drawer,
	DrawerBody,
	DrawerContent,
	DrawerFooter,
	DrawerHeader,
	DrawerOverlay,
	Flex,
	Image,
	Text,
} from '@chakra-ui/react';
import { toast } from 'react-toastify';
import { DiffSignEnum } from '@blowfishxyz/api-client/v20230605';
import { isEmpty } from 'lodash-es';
import { fromNano } from '@ton/core';
import { getChainId } from '../utils/HelperUtil';
import { web3wallet } from '../utils/WalletConnectUtil';
import { useModalStore } from '../store/ModalStore';
import { Loading, Toast } from '@/app-components/common';
import { approveSolanaRequest, rejectSolanaRequest } from '../utils/SolanaRequestHandlerUtil';
import { useScanSolTransaction } from '../hooks/blow-fish/useScanSolTransaction';
import { MpcWallet } from '@/app-cores/mpc-wallet/wallet';
import { TokenAmount } from '../components/TokenAmount';
import { BlowFishWaning } from './BlowFishWaning';
import { CommonTransactionInfo } from '../components/CommonTransactionInfo';
import { useEstimateSolGasFee } from '../hooks/useEstimateSolGasFee';
import { formatCurrency } from '@/app-helpers/number';
import { ActionConfirm } from '../components/ActionConfirm';
import { RawTransaction } from '../components/RawTransaction';

export default function SessionSignTransactionSolanaModal() {
	const { data, open, onClose } = useModalStore();
	const requestEvent = data?.requestEvent;
	const requestSession = data?.requestSession;
	const [isLoadingApprove, setIsLoadingApprove] = useState(false);
	const [isLoadingReject, setIsLoadingReject] = useState(false);
	const { topic, params } = requestEvent;
	const { request, chainId } = params;
	const { peer } = requestSession;
	const chainID = getChainId(chainId);
	const walletAddress = MpcWallet.getSolanaWalletAddress();
	const { data: scanTransactionData, isLoading: isScanningTransaction } = useScanSolTransaction(requestEvent.id, {
		metadata: {
			origin: peer?.metadata?.url,
		},
		transactions: [request.params.transaction],
		userAccount: walletAddress,
		simulateExpired: false,
	});
	const { data: estimateGasFee } = useEstimateSolGasFee(requestEvent.id, request.params.transaction);

	const onApprove = useCallback(async () => {
		if (isLoadingApprove) return;
		if (requestEvent) {
			setIsLoadingApprove(true);
			try {
				const response = await approveSolanaRequest(requestEvent);
				await web3wallet.respondSessionRequest({
					topic,
					response,
				});
				if ((response as any)?.result) {
					toast(<Toast type="success" title="Success" message="Sign transaction successfully" />);
				}
			} catch (e) {
				console.error('approve sol transaction error', e);
				setIsLoadingApprove(false);
				toast(<Toast type="error" message={(e as Error).message} />);
			}
			onClose();
			setIsLoadingApprove(false);
		}
		// eslint-disable-next-line
	}, [requestEvent, topic]);

	// Handle reject action
	const onReject = useCallback(async () => {
		if (isLoadingReject) return;
		if (requestEvent) {
			setIsLoadingReject(true);
			const response = rejectSolanaRequest(requestEvent);
			try {
				await web3wallet.respondSessionRequest({
					topic,
					response,
				});
			} catch (e) {
				toast(<Toast type="error" message={(e as Error).message} />);
			} finally {
				onClose();
			}
			setIsLoadingReject(false);
		}
		// eslint-disable-next-line
	}, [requestEvent, topic]);

	const aggregated = scanTransactionData?.aggregated;
	const stateChange = aggregated?.expectedStateChanges?.[walletAddress];

	const assetView = useMemo(() => {
		if (isEmpty(stateChange)) return null;
		return stateChange?.map((asset, i) => {
			switch (asset.rawInfo.kind) {
				case 'SOL_TRANSFER':
				case 'SPL_TRANSFER': {
					const data = asset.rawInfo.data;
					const amountChange = data.diff.digits;
					return (
						<TokenAmount
							key={i}
							amount={data?.diff?.sign === DiffSignEnum.Minus ? amountChange : amountChange}
							decimals={data?.asset?.decimals}
							image={data?.asset?.imageUrl}
							symbol={data?.asset?.symbol}
							type={data?.diff?.sign === DiffSignEnum.Plus ? 'IN' : 'OUT'}
							dollarValuePerToken={data?.asset?.price?.dollarValuePerToken}
						/>
					);
				}

				default:
					break;
			}
		});
	}, [stateChange]);
	if (isScanningTransaction) return <Loading />;
	return (
		<Drawer isOpen={open} placement="bottom" onClose={onClose} closeOnOverlayClick={false} trapFocus={false}>
			<DrawerOverlay />
			<DrawerContent>
				<DrawerHeader>
					<DrawerHeader borderBottomWidth="1px" borderColor="rgba(0, 0, 0, 0.08)">
						<Center>
							<Text fontSize={'16px'} fontWeight={'500'}>
								Confirm Transaction
							</Text>
						</Center>
					</DrawerHeader>
				</DrawerHeader>
				<DrawerBody pt={0}>
					<Flex flexDirection={'column'} alignItems={'center'} gap={'20px'}>
						<Flex flexDirection="column" width="100%" gap={1}>
							<Card px={4} py={2} width={'100%'}>
								<Center gap={2} borderRadius={52} px={2} py={1}>
									<Image
										width={8}
										height={8}
										src={peer?.metadata?.icons?.[0]}
										alt=""
										borderRadius="100%"
										fallbackSrc="/assets/images/dapp-fallback.svg"
									/>
									<Text fontSize="sm">{peer?.metadata?.url}</Text>
								</Center>
								<Divider height="1px" mt={2} borderBottom="1px dashed rgba(0, 0, 0, 0.16)" />
								{assetView && <Flex flexDirection="column">{assetView}</Flex>}
								<Divider borderBottom="1px dashed rgba(0, 0, 0, 0.16)" />
								<CommonTransactionInfo
									chainId={chainID}
									gasFee={estimateGasFee ? fromNano(estimateGasFee) : ''}
									walletAddress={walletAddress}
								>
									<RawTransaction
										rawData={JSON.stringify(
											scanTransactionData?.perTransaction || request,
											null,
											2,
										)}
									/>
								</CommonTransactionInfo>
							</Card>
						</Flex>
						{(scanTransactionData?.aggregated?.action === 'WARN' ||
							scanTransactionData?.aggregated?.action === 'BLOCK') && (
							<BlowFishWaning warnings={scanTransactionData?.aggregated?.warnings} />
						)}
					</Flex>
				</DrawerBody>

				<DrawerFooter display="flex" gap={2}>
					<ActionConfirm
						onApprove={onApprove}
						onReject={onReject}
						isLoadingApprove={isLoadingApprove}
						isLoadingReject={isLoadingReject}
					/>
				</DrawerFooter>
			</DrawerContent>
		</Drawer>
	);
}
