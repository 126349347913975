import { ServiceEmail } from '@/app-cores/api/bot/service-email';
import { UseMutationOptions, useMutation } from '@tanstack/react-query';

export const useMutationSendEmailBackupKey = (options?: UseMutationOptions<void, unknown, FormData>) => {
	const response = useMutation<void, unknown, FormData>({
		mutationKey: ['mutation-send-email-backup-key'],
		mutationFn: async (payload: FormData) => {
			return ServiceEmail.sendEmailBackupKey(payload);
		},
		...options,
	});

	return response;
};
