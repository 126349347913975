import { Button, Center } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { ITransactionStatus } from '@/app-types';

const CancelSpeedUpButtons = ({ onCancel, onSpeedUp, status }: { onCancel; onSpeedUp; status: ITransactionStatus }) => {
	const { t } = useTranslation();
	if (
		[ITransactionStatus.SUCCESS, ITransactionStatus.CANCEL_SUCCESS, ITransactionStatus.CANCEL_ERROR].includes(
			status,
		)
	)
		return null;
	return (
		<Center width="100%" gap={2} px={4}>
			<Button display="block" width="100%" size="sm" colorScheme={'gray'} onClick={onSpeedUp}>
				{t('button.speedUp')}
			</Button>
			<Button
				display="block"
				width="100%"
				size="sm"
				colorScheme={'gray'}
				onClick={onCancel}
				isDisabled={status === ITransactionStatus.CANCELING}
			>
				{t('button.cancel')}
			</Button>
		</Center>
	);
};

export default CancelSpeedUpButtons;
