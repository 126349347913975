import { SendTransactionRequest } from '@tonconnect/ui-react';
import { Address, beginCell, toNano } from '@ton/core';
import { TokenInfo } from '@/app-cores/api/bff';
import { TonWallet } from '@/app-cores/mpc-wallet/ton/TonWallet';
import { MpcWallet } from '@/app-cores/mpc-wallet/wallet';
import { getTonCoinTransferBalance } from '@/app-helpers/ton';
import { jettonTransferForwardAmount } from '@/app-hooks/transactions';

export const buildDepositJettonTransaction = async ({
	address,
	token,
	value,
}: {
	address: string;
	token: TokenInfo;
	value: string;
}) => {
	const tonSpaceAddress = Address.parse(address);
	const jettonWalletContract = await TonWallet.getTonSpaceJettonWalletAddress(tonSpaceAddress, token.address);
	const jettonTransferBalance = getTonCoinTransferBalance(value, token.decimals);
	const body = beginCell()
		.storeUint(0xf8a7ea5, 32) // jetton transfer op code
		.storeUint(0, 64) // query_id:uint64
		.storeCoins(jettonTransferBalance) // amount:(VarUInteger 16) -  Jetton amount for transfer (decimals = 6 - jUSDT, 9 - default)
		.storeAddress(Address.parse(MpcWallet.getTonWalletAddress())) // destination:MsgAddress
		.storeAddress(tonSpaceAddress) // response_destination:MsgAddress
		.storeUint(0, 1) // custom_payload:(Maybe ^Cell)
		.storeCoins(jettonTransferForwardAmount) // forward_ton_amount:(VarUInteger 16) - if >0, will send notification message
		.storeUint(0, 1) // forward_payload:(Either Cell ^Cell)
		.endCell();
	const transaction = {
		validUntil: Math.floor(Date.now() / 1000) + 360,
		messages: [
			{
				address: jettonWalletContract.toString(), // sender jetton wallet
				amount: toNano(0.05).toString(), // for commission fees, excess will be returned
				payload: body.toBoc().toString('base64'), // payload with jetton transfer body
			},
		],
	};
	return transaction;
};

export const buildDepositTonTransaction = ({ value }: { value: number }) => {
	const transaction: SendTransactionRequest = {
		validUntil: Math.floor(Date.now() / 1000) + 600,
		messages: [
			{
				address: MpcWallet.getTonWalletAddress(),
				amount: toNano(value).toString(),
			},
		],
	};
	return transaction;
};
