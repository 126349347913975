import { Box, Center, Text } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { ChainIcons } from '../ChainIcons';
import { MAINNET_CHAINS } from '@/app-constants/chains';
import { FiRightIcon, FiWalletAddressIcon } from '@/assets/icons';
import { useWalletAddressStore } from '@/app-store';

const chianIds = MAINNET_CHAINS.slice(0, 6).map((chain) => {
	return chain.id;
});

export const AddressByChain = () => {
	const { setOpen } = useWalletAddressStore();
	const { t } = useTranslation();
	return (
		<Center
			justifyContent="space-between"
			px={4}
			py={3}
			backgroundColor="gray.100"
			borderRadius={12}
			cursor="pointer"
			onClick={() => {
				setOpen(true);
			}}
		>
			<Center gap={3}>
				<FiWalletAddressIcon />
				<Text fontSize="xs" fontWeight={600}>
					{t('myAddresses')}
				</Text>
			</Center>
			<Center flex={1} justifyContent="flex-end">
				<ChainIcons chains={chianIds} showMore />
				<Box ml={2}>
					<FiRightIcon width={20} height={20} />
				</Box>
			</Center>
		</Center>
	);
};
