import { isEmpty, omit } from 'lodash-es';
import { CHAIN_CONFIG, MAINNET_CHAINS } from '../../../app-constants/chains';
import { MpcWallet, MpcWalletProvider } from '../../../app-cores/mpc-wallet/wallet';
import { uniqueId } from '../../../app-helpers/random';
import { useTransactionWatcherStore } from '../../../app-store';
import { TransactionType } from '../../../app-types';
import { BotConnectorServiceAPI } from '../service';
import { ModalData, SessionProposal } from '../store/store.type';
import { EIP155_SIGNING_METHODS, getSignParamsMessage, getSignTypedDataParamsData } from './helper.util';
import { NaclHandler } from './nacl.util';
import { TobiStorage } from './store.util';

export class BotConnector {
	public readonly keyPair = new NaclHandler();
	private readonly storage = new TobiStorage();
	constructor() {}

	init() {}

	getPublicKey(clientId: string) {
		const client = this.getClient(clientId);

		if (!client?.topicId) {
			return;
		}

		const keyPair = this.keyPair.getKeyPair(client.publicKey, client.topicId);

		return this.keyPair.toHexString(keyPair.publicKey);
	}

	getClient(clientId: string): SessionProposal['connectionData'] & { topicId: string } {
		return this.storage.get(['clients', clientId]);
	}

	setClient(clientId: string, data: any) {
		return this.storage.set(['clients', clientId], data);
	}

	activeClient(clientId: string, data: SessionProposal['connectionData']) {
		return this.storage.set(['actives', clientId], data);
	}

	getActiveClients() {
		return this.storage.get('actives');
	}

	unActiveClient(clientId: string) {
		const actives = this.storage.get(['actives']);
		delete actives[clientId];
		this.storage.set('actives', { ...actives });
		this.storage.set(['clients', clientId], undefined);

		return;
	}

	async approve(proposal: ModalData) {
		switch (proposal.payload.method) {
			case 'connect':
				return this.approveConnect(proposal);
			case 'personal_sign':
			case 'eth_signMessage':
				return this.approvePersonalSign(proposal);
			case 'eth_signTransaction':
				return this.approveSignTransaction(proposal);
			case 'eth_sendTransaction':
				return this.approveSendTransaction(proposal);
			case 'eth_signTypedData_v4':
				return this.approveSignTypedData(proposal);
			default:
				break;
		}
	}

	async reject(proposal: ModalData) {
		await this.responseToTextBot(proposal.clientId, {
			id: proposal.payload.id,
			type: proposal.payload.method,
			status: 'rejected',
		});

		// Extra handle if need
		switch (proposal.payload.method) {
			case 'connect':
				return this.rejectConnect(proposal);
			default:
				break;
		}
		return;
	}

	private async approveConnect(originPayload: ModalData) {
		if (originPayload.payload.method !== 'connect') {
			return 'Wrong method';
		}

		const proposal = originPayload.payload;
		const walletAddress = MpcWallet.getWalletAddress();
		const topicId = uniqueId();
		const keyPair = this.keyPair.getKeyPair(proposal.connectionData.publicKey, topicId);

		const payload = {
			id: proposal.id,
			topicId,
			publicKey: this.keyPair.toHexString(keyPair.publicKey),
			connection: {
				eip155: {
					chains: MAINNET_CHAINS.map((item) => item.id),
					methods: Object.values(EIP155_SIGNING_METHODS),
					accounts: [walletAddress.evmAddress],
				},
			},
		};

		const result = await this.responseToTextBot(originPayload.clientId, {
			id: originPayload.payload.id,
			type: originPayload.payload.method,
			publicKey: this.keyPair.toHexString(keyPair.publicKey),
			data: this.keyPair.encrypt(JSON.stringify(payload), proposal.connectionData.publicKey, topicId),
		});

		this.setClient(originPayload.clientId, {
			...this.getClient(originPayload.clientId),
			topicId,
		});

		this.activeClient(originPayload.clientId, proposal.connectionData);

		return result;
	}

	private rejectConnect(originPayload: ModalData) {
		this.unActiveClient(originPayload.clientId);
	}

	private async approvePersonalSign(originPayload: ModalData) {
		if (originPayload.payload.method !== 'personal_sign') {
			return 'Wrong method';
		}

		const message = getSignParamsMessage(originPayload.payload.data);
		const signMessage = await MpcWallet.signMessage(message);
		const client = this.getClient(originPayload.clientId);

		return this.responseToTextBot(originPayload.clientId, {
			id: originPayload.payload.id,
			type: originPayload.payload.method,
			topicId: originPayload['topicId'],
			data: this.keyPair.encrypt(signMessage, client.publicKey, originPayload['topicId']),
		});
	}

	private async approveSignTransaction(originPayload: ModalData) {
		if (originPayload.payload.method !== 'eth_signTransaction') {
			return 'Wrong method';
		}

		const message = getSignParamsMessage(originPayload.payload.data);
		const signMessage = await MpcWallet.signRawTransaction(message);
		const client = this.getClient(originPayload.clientId);

		return this.responseToTextBot(originPayload.clientId, {
			id: originPayload.payload.id,
			type: originPayload.payload.method,
			topicId: originPayload['topicId'],
			data: this.keyPair.encrypt(signMessage, client.publicKey, originPayload['topicId']),
		});
	}

	private async approveSendTransaction(originPayload: ModalData) {
		if (originPayload.payload.method !== 'eth_sendTransaction') {
			return 'Wrong method';
		}

		try {
			const params = originPayload.payload;
			const provider = new MpcWalletProvider(CHAIN_CONFIG[params.chainId].rpcUrls.default.http);
			const signer = provider.getSigner();
			const client = this.getClient(originPayload.clientId);

			const hash = await signer.sendTransaction({
				...params.data,
			});
			const receipt = typeof hash === 'string' ? hash : hash?.hash;
			await Promise.all([
				useTransactionWatcherStore.getState().addPendingEvmTransaction({
					transaction: hash,
					metadata: { transactionType: TransactionType.ContractInteraction },
				}),
				this.responseToTextBot(originPayload.clientId, {
					id: originPayload.payload.id,
					type: 'eth_sendTransaction',
					topicId: originPayload['topicId'],
					data: this.keyPair.encrypt(receipt, client.publicKey, originPayload['topicId']),
				}),
			]);
		} catch (error) {
			console.error(`approveSendTransaction error`, error);
		}
	}

	private async approveSignTypedData(originPayload: ModalData) {
		if (originPayload.payload.method !== 'eth_signTypedData_v4') {
			return 'Wrong method';
		}

		const params = originPayload.payload;
		const { domain, types, message: data } = getSignTypedDataParamsData(params.data);
		const signedData = await MpcWallet.signTypedData(domain, omit(types, 'EIP712Domain'), data);
		const client = this.getClient(originPayload.clientId);

		return this.responseToTextBot(originPayload.clientId, {
			id: originPayload.payload.id,
			type: originPayload.payload.method,
			topicId: originPayload['topicId'],
			data: this.keyPair.encrypt(signedData, client.publicKey, originPayload['topicId']),
		});
	}

	private responseToTextBot(clientId: string, payload: Record<string, any>) {
		return BotConnectorServiceAPI.emit(clientId, payload);
	}
}

export const botConnectorHandler = new BotConnector();
