import React from 'react';

interface IProps extends React.SVGAttributes<HTMLOrSVGElement> {}

export const FiWalletCircleIcon: React.FC<IProps> = ({ width = 40, height = 40, fill = 'white', ...rest }) => {
	return (
		<svg width={width} height={height} viewBox="0 0 41 40" fill="none" xmlns="http://www.w3.org/2000/svg">
			<circle cx="20.5" cy="20" r="20" fill="#DFF8FF" />
			<path
				d="M28.0781 11.9172C27.4211 10.0594 25.7469 8.5805 23.8406 8.33597C22.5 8.16878 20.6703 8.03207 18.1688 8.06566C15.6672 8.09925 13.8344 8.29222 12.4898 8.49535C10.268 8.84535 8.28359 10.7993 7.90234 13.0368C7.90234 13.0368 7.31406 16.1938 7.19922 20.4453H28.7352C28.7352 20.4453 28.7352 14.8149 28.0781 11.9172Z"
				fill="url(#paint0_linear_8983_25856)"
			/>
			<path
				d="M33.5773 17.2329C33.3242 15.2547 31.5624 13.5813 29.5007 13.3079C25.8679 12.9477 20.5015 12.9172 20.4999 12.9305C18.1788 12.9063 12.7445 13.1282 11.4999 13.3079C10.239 13.4704 9.09993 14.1672 8.33743 15.1219C7.84489 15.7278 7.52865 16.4576 7.42337 17.2313C7.27572 18.4266 7.15853 20.0594 7.2179 22.2938C7.27728 24.5282 7.48665 26.1657 7.69603 27.3649C8.05697 29.35 9.8429 31.0938 11.8304 31.4274C13.0343 31.6571 18.2663 31.9743 20.5023 31.9352C22.7367 31.9758 27.9687 31.6571 29.1742 31.4274C31.1617 31.0938 32.9476 29.35 33.3085 27.3649C33.5179 26.1657 33.7234 24.529 33.7867 22.2938C33.8499 20.0586 33.7249 18.4282 33.5773 17.2329Z"
				fill="url(#paint1_linear_8983_25856)"
			/>
			<path
				d="M29.1579 24.2758C28.7935 24.276 28.4372 24.168 28.1342 23.9657C27.8311 23.7633 27.5948 23.4756 27.4553 23.139C27.3158 22.8023 27.2792 22.4318 27.3503 22.0744C27.4214 21.717 27.5968 21.3887 27.8545 21.131C28.1122 20.8733 28.4405 20.6978 28.7979 20.6268C29.1554 20.5557 29.5258 20.5923 29.8625 20.7318C30.1991 20.8713 30.4868 21.1076 30.6892 21.4106C30.8915 21.7137 30.9995 22.07 30.9993 22.4344C30.9993 22.9228 30.8053 23.3911 30.46 23.7365C30.1147 24.0818 29.6463 24.2758 29.1579 24.2758Z"
				fill="url(#paint2_linear_8983_25856)"
			/>
			<defs>
				<linearGradient
					id="paint0_linear_8983_25856"
					x1="13.8008"
					y1="24.2547"
					x2="23.3844"
					y2="7.6555"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#34344F" />
					<stop offset="0.54" stopColor="#353551" />
					<stop offset="1" stopColor="#666684" />
				</linearGradient>
				<linearGradient
					id="paint1_linear_8983_25856"
					x1="30.1132"
					y1="31.7516"
					x2="10.8882"
					y2="12.5266"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#1EB4EB" />
					<stop offset="0.54" stopColor="#1EB4EB" />
					<stop offset="1" stopColor="#92F4FE" />
				</linearGradient>
				<linearGradient
					id="paint2_linear_8983_25856"
					x1="30.4603"
					y1="23.7367"
					x2="27.8556"
					y2="21.1321"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#BCE4ED" />
					<stop offset="0.57" stopColor="white" />
					<stop offset="1" stopColor="white" />
				</linearGradient>
			</defs>
		</svg>
	);
};
