import { ActionBlock } from '@/app-components/common/ActionBlock';
import { useWalletAddressStore } from '@/app-store';
import { FiCopyIcon } from '@/assets/icons';
import { Box, Center } from '@chakra-ui/react';

export const WalletAddress = () => {
	const { setOpen } = useWalletAddressStore();
	return (
		<ActionBlock
			onClick={() => {
				setOpen(true);
			}}
		>
			<Center width={8} height={8}>
				<FiCopyIcon width={20} height={20} />
			</Center>
		</ActionBlock>
	);
};
