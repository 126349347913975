import { BACKUP_FOLDER_NAME } from '@/app-constants/backup';
import axios, { AxiosInstance } from 'axios';
import { GDriveFile } from '.';

export class GDriveSession {
	private readonly GOOGLE_API_URL = 'https://www.googleapis.com';
	private readonly MIMETYPE_FOLDER = 'application/vnd.google-apps.folder';

	private accessToken: string;

	private axiosClient: AxiosInstance;

	constructor(accessToken: string) {
		this.accessToken = accessToken;
		this.axiosClient = axios.create({
			baseURL: this.GOOGLE_API_URL,
			headers: {
				Authorization: `Bearer ${this.accessToken}`,
			},
		});
	}

	public async createBackupFolder(): Promise<GDriveFile> {
		const response = await this.axiosClient.post(
			'/drive/v2/files',
			{
				title: BACKUP_FOLDER_NAME,
				mimeType: this.MIMETYPE_FOLDER,
			},
			{
				headers: {
					'Content-Type': 'application/json',
				},
			},
		);
		const data = response.data;
		return {
			id: data.id,
			kind: data.kind,
			mimeType: data.mimeType,
			name: data.title,
		};
	}

	public async scanBackupFolders(): Promise<GDriveFile[]> {
		try {
			const response = await this.axiosClient.get('/drive/v3/files', {
				params: {
					q: `name='${BACKUP_FOLDER_NAME}' and mimeType='${this.MIMETYPE_FOLDER}' and trashed=false`,
				},
			});
			const { files } = response.data;
			return files;
		} catch (e) {
			return [];
		}
	}

	public async findBackupFileByWalletAddress(walletAddress: string): Promise<GDriveFile[]> {
		try {
			const folders = await this.scanBackupFolders();

			if (!folders.length) {
				return [];
			}

			const folderIncludes = [];
			folders.forEach((folder) => {
				folderIncludes.push(`'${folder.id}' in parents`);
			});

			const response = await this.axiosClient.get('/drive/v3/files', {
				params: {
					q: `(${folderIncludes.join(
						' or ',
					)}) and name='${walletAddress.toLowerCase()}.txt' and trashed=false`,
				},
			});
			const { files } = response.data;
			return files;
		} catch (e) {
			return [];
		}
	}

	public async exportFileContentTextPlain(fileId: string): Promise<string> {
		const response = await this.axiosClient.get(`/drive/v3/files/${fileId}`, {
			params: {
				alt: 'media',
				q: 'trashed=false',
			},
		});
		return response.data;
	}

	public async getOrCreateOneBackupFolder(): Promise<GDriveFile> {
		const folders = await this.scanBackupFolders();

		if (folders.length > 0) {
			return folders[0];
		}

		const newFolder = await this.createBackupFolder();
		return newFolder;
	}

	public async uploadBackupKey(walletAddress: string, key: string, folderId?: string): Promise<GDriveFile> {
		const file = new Blob([key], { type: 'text/plain' });
		const metadata = {
			name: `${walletAddress.toLowerCase()}.txt`,
			mimeType: 'text/plain',
			parents: [folderId],
		};
		const form = new FormData();
		form.append('metadata', new Blob([JSON.stringify(metadata)], { type: 'application/json' }));
		form.append('file', file);
		return await this.uploadMultipart(form);
	}

	public async uploadMultipart(form: FormData): Promise<GDriveFile> {
		return await this.axiosClient.post('/upload/drive/v3/files?uploadType=multipart', form);
	}

	public async getUserInfo(): Promise<{
		data: {
			email: string;
		};
	}> {
		return this.axiosClient.get('/oauth2/v1/userinfo');
	}
}
