import React from 'react';

interface IProps extends React.SVGAttributes<HTMLOrSVGElement> {}

export const FiBlockChainIcon: React.FC<IProps> = ({ width = 49, height = 48, ...rest }) => {
	return (
		<svg width={width} height={height} viewBox="0 0 49 48" fill="none" xmlns="http://www.w3.org/2000/svg">
			<g id="blockchain 1" clipPath="url(#clip0_4983_13441)">
				<path
					id="Vector"
					d="M14.1038 30.1469C13.8731 30.1469 13.6384 30.0905 13.4227 29.9697C12.743 29.5932 12.4987 28.7367 12.8763 28.0581L18.379 18.0587C18.7554 17.3816 19.612 17.1318 20.2906 17.5123C20.9703 17.8884 21.2149 18.7453 20.8373 19.4239L15.3343 29.4233C15.0776 29.8858 14.5986 30.1469 14.1038 30.1469Z"
					fill="#22222D"
				/>
				<path
					id="Vector_2"
					d="M34.8958 30.1469C34.4015 30.1469 33.9221 29.8858 33.6654 29.4233L28.1623 19.4239C27.7848 18.7453 28.0294 17.8884 28.7091 17.5123C29.3888 17.1318 30.2442 17.3816 30.6207 18.0587L36.1234 28.0581C36.5009 28.7363 36.2567 29.5932 35.577 29.9697C35.3613 30.0905 35.1266 30.1469 34.8958 30.1469Z"
					fill="#22222D"
				/>
				<path
					id="Vector_3"
					d="M31.5312 35.3438C31.5312 36.1311 30.9127 36.75 30.125 36.75H18.875C18.0873 36.75 17.4688 36.1311 17.4688 35.3438C17.4688 34.556 18.0873 33.9375 18.875 33.9375H30.125C30.9127 33.9375 31.5312 34.556 31.5312 35.3438Z"
					fill="#22222D"
				/>
				<path
					id="Vector_4"
					d="M48.303 31.2374L43.4844 31.125L38.5625 36.75L35.75 42.2344L38.5625 48C38.8156 48 39.0404 47.9436 39.2656 47.803L47.8251 42.7969C47.8815 42.7687 47.9375 42.7126 47.9939 42.6844C48.303 42.4032 48.5 41.9813 48.5 41.5876V31.9406C48.5 31.6875 48.4436 31.4344 48.303 31.2374Z"
					fill="#00F5E6"
				/>
				<path
					id="Vector_5"
					d="M33.6406 31.125L28.9158 31.2374C28.7751 31.4626 28.7188 31.6875 28.7188 31.9406V41.5873C28.7188 41.9813 28.9158 42.4032 29.2249 42.6844C29.2812 42.7123 37.8594 47.803 37.8594 47.803C38.0846 47.9436 38.3094 48 38.5625 48V36.75L33.6406 31.125Z"
					fill="#00E9DB"
				/>
				<path
					id="Vector_6"
					d="M48.3027 31.2374L38.5623 36.75L28.9155 31.2374C28.9434 31.2096 28.9716 31.1532 28.9998 31.125C29.1122 30.9562 29.2528 30.8438 29.4216 30.7313L37.8591 25.697C38.0844 25.5564 38.337 25.5 38.5623 25.5C38.7875 25.5 39.0402 25.5564 39.2654 25.697L47.7966 30.7313C47.9655 30.8438 48.1061 30.9844 48.2185 31.125C48.2467 31.1532 48.2745 31.2096 48.3027 31.2374Z"
					fill="#00FFF0"
				/>
				<path
					id="Vector_7"
					d="M20.0842 31.2374L15.3594 31.125L10.4375 36.75L7.625 42.2344L10.4375 48C10.6906 48 10.9154 47.9436 11.1406 47.803L19.6063 42.7969C19.6627 42.7687 19.7188 42.7126 19.7751 42.6844C20.0842 42.4032 20.2812 41.9813 20.2812 41.5876V31.9406C20.2812 31.6875 20.2249 31.4344 20.0842 31.2374Z"
					fill="#00F5E6"
				/>
				<path
					id="Vector_8"
					d="M5.51562 31.125L0.697021 31.2374C0.556396 31.4626 0.5 31.6875 0.5 31.9406V41.5873C0.5 41.9813 0.697021 42.4032 1.0061 42.6844C1.0625 42.7123 9.73438 47.803 9.73438 47.803C9.95959 47.9436 10.1844 48 10.4375 48V36.75L5.51562 31.125Z"
					fill="#00E9DB"
				/>
				<path
					id="Vector_9"
					d="M20.084 31.2374L10.4373 36.75L0.696777 31.2374C0.724609 31.2096 0.752808 31.1532 0.781006 31.125C0.893433 30.9562 1.03406 30.8438 1.20288 30.7313L9.73413 25.697C9.95935 25.5564 10.212 25.5 10.4373 25.5C10.6625 25.5 10.9152 25.5564 11.1404 25.697L19.5779 30.7313C19.7467 30.8438 19.8873 30.9844 19.9998 31.125C20.028 31.1532 20.0558 31.2096 20.084 31.2374Z"
					fill="#00FFF0"
				/>
				<path
					id="Vector_10"
					d="M34.1467 5.83118L29.4219 5.71875L24.5 11.3438L21.6875 16.8281L24.5 22.5938C24.7531 22.5938 24.9779 22.5374 25.2031 22.3967L33.6688 17.3906C33.7252 17.3624 33.7812 17.3064 33.8376 17.2782C34.1467 16.9969 34.3438 16.5751 34.3438 16.1814V6.5343C34.3438 6.28125 34.2874 6.0282 34.1467 5.83118Z"
					fill="#00F5E6"
				/>
				<path
					id="Vector_11"
					d="M19.5781 5.71875L14.8533 5.83118C14.7126 6.0564 14.6562 6.28125 14.6562 6.5343V16.181C14.6562 16.5751 14.8533 16.9969 15.1624 17.2782C15.2187 17.306 23.7969 22.3967 23.7969 22.3967C24.0221 22.5374 24.2469 22.5938 24.5 22.5938V11.3438L19.5781 5.71875Z"
					fill="#00E9DB"
				/>
				<path
					id="Vector_12"
					d="M34.1465 5.83118L24.4998 11.3438L14.853 5.83118C14.8809 5.80334 14.9091 5.74695 14.9373 5.71875C15.0497 5.54993 15.1903 5.4375 15.3591 5.32507L23.7966 0.197021C24.0219 0.0563964 24.2745 0 24.4998 0C24.725 0 24.9777 0.0563964 25.2029 0.197021L33.6404 5.32507C33.8092 5.4375 33.9498 5.57812 34.0623 5.71875C34.0905 5.74695 34.1183 5.80334 34.1465 5.83118Z"
					fill="#00F5E6"
				/>
				<path
					id="Vector_13"
					d="M34.1467 5.83118L24.5 11.3438V0C24.7252 0 24.9779 0.0563964 25.2031 0.197021L33.6406 5.32507C33.8094 5.4375 33.9501 5.57812 34.0625 5.71875C34.0907 5.74695 34.1185 5.80334 34.1467 5.83118Z"
					fill="#00FFF0"
				/>
			</g>
			<defs>
				<clipPath id="clip0_4983_13441">
					<rect width="48" height="48" fill="white" transform="translate(0.5)" />
				</clipPath>
			</defs>
		</svg>
	);
};
