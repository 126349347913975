import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import { TAppConnection, TON_CONNECT_KEY } from '../types';

const initializeData = {
	connections: {},
};
export interface ITAppConnectionStore {
	connections: Record<string, TAppConnection>;
	addConnection: (connection: TAppConnection) => void;
	removeConnection: (connection: TAppConnection) => void;
}

export const useTAppConnections = create<ITAppConnectionStore>()(
	persist(
		(set) => ({
			...initializeData,
			addConnection: (cont: TAppConnection) =>
				set((state) => {
					const connections = {
						...state.connections,
						[cont.clientSessionId]: cont,
					};
					return {
						connections: connections,
					};
				}),
			removeConnection: (cont: TAppConnection) =>
				set((state) => {
					const { [cont.clientSessionId]: _, ...remainingConnections } = state.connections;
					return {
						connections: remainingConnections,
					};
				}),
		}),
		{
			name: TON_CONNECT_KEY.CONNECTIONS,
		},
	),
);
