import React from 'react';

interface IProps extends React.SVGAttributes<HTMLOrSVGElement> {}

export const AvaxIcon: React.FC<IProps> = ({ width = 56, height = 56, ...rest }) => {
	return (
		<svg width={width} height={height} viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
			<g clipPath="url(#clip0_8601_5473)">
				<path d="M45.2393 9.60645H10.6862V41.032H45.2393V9.60645Z" fill="white" />
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M55.9442 28.0001C55.9442 43.4437 43.425 55.9629 27.9814 55.9629C12.538 55.9629 0.0186768 43.4437 0.0186768 28.0001C0.0186768 12.5567 12.538 0.0373535 27.9814 0.0373535C43.425 0.0373535 55.9442 12.5567 55.9442 28.0001ZM20.0576 39.1279H14.6308C13.4905 39.1279 12.9272 39.1279 12.5838 38.9082C12.2128 38.6677 11.9861 38.2693 11.9586 37.8295C11.938 37.4244 12.2197 36.9297 12.783 35.9405L26.1824 12.3222C26.7525 11.3192 27.041 10.8178 27.4051 10.6323C27.7966 10.4331 28.2638 10.4331 28.6553 10.6323C29.0194 10.8178 29.3079 11.3192 29.8781 12.3222L32.6327 17.1307L32.6468 17.1553C33.2626 18.2312 33.5749 18.7768 33.7112 19.3495C33.8623 19.9746 33.8623 20.6341 33.7112 21.2592C33.5738 21.8362 33.2647 22.3858 32.6396 23.478L25.6012 35.9199L25.583 35.9517C24.9631 37.0366 24.649 37.5864 24.2136 38.0012C23.7396 38.4547 23.1694 38.7842 22.5443 38.97C21.9741 39.1279 21.3353 39.1279 20.0576 39.1279ZM33.762 39.1279H41.538C42.6852 39.1279 43.2623 39.1279 43.606 38.9015C43.9768 38.661 44.2103 38.2555 44.2311 37.8161C44.2509 37.4241 43.9753 36.9486 43.4354 36.0169C43.4168 35.9851 43.3982 35.9529 43.3792 35.92L39.4842 29.2568L39.4398 29.1817C38.8925 28.2562 38.6162 27.7888 38.2614 27.6081C37.8701 27.4089 37.4095 27.4089 37.0181 27.6081C36.6609 27.7936 36.3724 28.2813 35.8022 29.2636L31.921 35.9269L31.9077 35.9498C31.3396 36.9306 31.0556 37.4207 31.0761 37.8228C31.1036 38.2626 31.3303 38.6677 31.7012 38.9082C32.0378 39.1279 32.6148 39.1279 33.762 39.1279Z"
					fill="#E84142"
				/>
			</g>
			<defs>
				<clipPath id="clip0_8601_5473">
					<rect width="55.9628" height="56" fill="white" />
				</clipPath>
			</defs>
		</svg>
	);
};
