/**
 * @desc Refference list of eip155 chains
 * @url https://chainlist.org
 */

import { ChainId, RPC } from '@/app-constants/chains';

/**
 * Types
 */
export type TEIP155Chain = keyof typeof EIP155_CHAINS;

export type EIP155Chain = {
	chainId: ChainId;
	name: string;
	logo: string;
	rgb: string;
	rpc: string;
	namespace: string;
	smartAccountEnabled?: boolean;
};

/**
 * Chains
 */
const env = import.meta.env.VITE_ENVIRONMENT;

export const EIP155_MAINNET_CHAINS: Record<string, EIP155Chain> = {
	'eip155:1': {
		chainId: 1,
		name: 'Ethereum',
		logo: '/chain-logos/eip155-1.png',
		rgb: '99, 125, 234',
		rpc: RPC[env][ChainId.ETH],
		namespace: 'eip155',
	},
	'eip155:43114': {
		chainId: 43114,
		name: 'Avalanche C-Chain',
		logo: '/chain-logos/eip155-43113.png',
		rgb: '232, 65, 66',
		rpc: RPC[env][ChainId.AVAX],
		namespace: 'eip155',
	},
	'eip155:137': {
		chainId: 137,
		name: 'Polygon',
		logo: '/chain-logos/eip155-137.png',
		rgb: '130, 71, 229',
		rpc: RPC[env][ChainId.POLYGON],
		namespace: 'eip155',
	},
	'eip155:10': {
		chainId: 10,
		name: 'Optimism',
		logo: '/chain-logos/eip155-10.png',
		rgb: '235, 0, 25',
		rpc: RPC[env][ChainId.OP],
		namespace: 'eip155',
	},
	// 'eip155:324': {
	// 	chainId: 324,
	// 	name: 'zkSync Era',
	// 	logo: '/chain-logos/eip155-324.svg',
	// 	rgb: '242, 242, 242',
	// 	rpc: 'https://mainnet.era.zksync.io/',
	// 	namespace: 'eip155',
	// },
	'eip155:42161': {
		chainId: 42161,
		name: 'Arbitrum',
		logo: '/chain-logos/eip155-42161.svg',
		rgb: '242, 242, 242',
		rpc: RPC[env][ChainId.ARB],
		namespace: 'eip155',
	},
	'eip155:56': {
		chainId: 56,
		name: 'BNB Chain',
		logo: '/chain-logos/eip155-56.svg',
		rgb: '242, 242, 242',
		rpc: RPC[env][ChainId.BNB],
		namespace: 'eip155',
	},
	'eip155:8453': {
		chainId: 8453,
		name: 'Base',
		logo: '/chain-logos/eip155-8453.svg',
		rgb: '242, 242, 242',
		rpc: RPC[env][ChainId.BASE],
		namespace: 'eip155',
	},
	// 'eip155:59144': {
	// 	chainId: 59144,
	// 	name: 'Linea',
	// 	logo: '/chain-logos/eip155-59144.svg',
	// 	rgb: '242, 242, 242',
	// 	rpc: 'https://rpc.linea.build',
	// 	namespace: 'eip155',
	// },
};

const EIP155_TEST_CHAINS: Record<string, EIP155Chain> = {
	'eip155:11155111': {
		chainId: 11155111,
		name: 'Ethereum Sepolia',
		logo: '/chain-logos/eip155-1.png',
		rgb: '99, 125, 234',
		rpc: 'https://gateway.tenderly.co/public/sepolia',
		namespace: 'eip155',
		smartAccountEnabled: false,
	},
	'eip155:43113': {
		chainId: 43113,
		name: 'Avalanche Fuji',
		logo: '/chain-logos/eip155-43113.png',
		rgb: '232, 65, 66',
		rpc: 'https://api.avax-test.network/ext/bc/C/rpc',
		namespace: 'eip155',
	},
	'eip155:80001': {
		chainId: 80001,
		name: 'Polygon Mumbai',
		logo: '/chain-logos/eip155-137.png',
		rgb: '130, 71, 229',
		rpc: 'https://matic-mumbai.chainstacklabs.com',
		namespace: 'eip155',
		smartAccountEnabled: false,
	},
	'eip155:420': {
		chainId: 420,
		name: 'Optimism Goerli',
		logo: '/chain-logos/eip155-10.png',
		rgb: '235, 0, 25',
		rpc: 'https://goerli.optimism.io',
		namespace: 'eip155',
	},
	// 'eip155:280': {
	// 	chainId: 280,
	// 	name: 'zkSync Era Testnet',
	// 	logo: '/chain-logos/eip155-324.svg',
	// 	rgb: '242, 242, 242',
	// 	rpc: 'https://testnet.era.zksync.dev/',
	// 	namespace: 'eip155',
	// },
	'eip155:84532': {
		chainId: 84532,
		name: 'Base Sepolia Testnet',
		logo: '/chain-logos/eip155-84532.svg',
		rgb: '242, 242, 242',
		rpc: 'https://base-sepolia.blockpi.network/v1/rpc/public',
		namespace: 'eip155',
	},
};

export const EIP155_CHAINS = { ...EIP155_MAINNET_CHAINS, ...EIP155_TEST_CHAINS };

/**
 * Methods
 */
export const EIP155_SIGNING_METHODS = {
	PERSONAL_SIGN: 'personal_sign',
	ETH_SIGN: 'eth_sign',
	ETH_SIGN_TRANSACTION: 'eth_signTransaction',
	ETH_SIGN_TYPED_DATA: 'eth_signTypedData',
	ETH_SIGN_TYPED_DATA_V3: 'eth_signTypedData_v3',
	ETH_SIGN_TYPED_DATA_V4: 'eth_signTypedData_v4',
	ETH_SEND_RAW_TRANSACTION: 'eth_sendRawTransaction',
	ETH_SEND_TRANSACTION: 'eth_sendTransaction',
	WALLET_SWITCH_ETHEREUM_CHAIN: 'wallet_switchEthereumChain',
	WALLET_ADD_ETHEREUM_CHAIN: 'wallet_addEthereumChain',
};
