import React from 'react';

interface IProps extends React.SVGAttributes<HTMLOrSVGElement> {}

export const FiTobiWalletIcon: React.FC<IProps> = ({ width = 40, height = 40, ...rest }) => {
	return (
		<svg width={width} height={height} viewBox="0 0 41 40" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M20.5 40C31.5457 40 40.5 31.0457 40.5 20C40.5 8.9543 31.5457 0 20.5 0C9.4543 0 0.5 8.9543 0.5 20C0.5 31.0457 9.4543 40 20.5 40Z"
				fill="#22222D"
			/>
			<g clipPath="url(#clip0_9004_9585)">
				<path
					d="M30.7075 25.533L30.8973 25.7404C29.8324 28.8791 26.8227 31.1007 23.3315 31.1007H16.9695C14.6081 31.1007 12.5522 29.9254 11.3345 28.1647C11.9827 28.8561 12.9041 29.2939 13.9367 29.2939H23.313C25.9013 29.2939 28.096 27.4365 28.5451 24.9154L29.5268 24.9753C29.9759 25.003 30.4019 25.2012 30.7075 25.533Z"
					fill="#00E9DB"
				/>
				<path
					d="M26.48 9.89929H17.4556C17.1685 9.89929 16.8814 9.91312 16.6036 9.9546C15.8165 9.99147 15.0617 10.185 14.3857 10.5123L14.432 10.4385C11.7094 9.00054 7.92188 11.1714 7.92188 11.1714C9.88048 11.4295 10.9593 13.0979 11.4131 14.0197C11.3483 14.2548 11.2927 14.4944 11.2557 14.7387L9.85269 23.9982C9.71842 24.92 9.95919 25.8049 10.4546 26.4916C10.4824 26.5285 10.5102 26.57 10.538 26.6022C11.1677 27.4088 12.1447 27.9342 13.2652 27.9342H22.3081C24.4102 27.9342 26.2438 26.6714 27.0356 24.8232L24.6325 24.6803C24.012 24.6434 23.461 24.2378 23.2851 23.6433C23.248 23.5327 23.2249 23.4174 23.211 23.3068C23.1971 23.1962 23.1878 23.0856 23.1878 22.975C23.1878 22.6662 23.248 22.3712 23.3268 22.0716C23.3684 21.9241 23.4194 21.7812 23.4703 21.6384C23.5259 21.4909 23.5814 21.348 23.637 21.2097C23.8916 20.5783 24.4565 19.8593 25.1326 19.7487C26.0586 19.5874 27.0217 19.5275 27.8876 19.5136L28.7164 12.8905C28.8275 12.0056 28.4802 11.1806 27.869 10.6321C29.1887 10.9409 30.1286 12.2038 29.9527 13.6141L29.2257 19.5228C29.5591 19.5367 29.8554 19.5505 30.1055 19.5643C30.7352 19.6058 31.2445 19.9607 31.6195 20.4539L32.0502 15.9371C32.365 12.7062 29.7813 9.90851 26.48 9.90851V9.89929ZM14.0709 17.6562C13.5384 17.6562 13.1078 17.2276 13.1078 16.6975C13.1078 16.1675 13.5384 15.7343 14.0709 15.7343C14.6033 15.7343 15.0386 16.1629 15.0386 16.6975C15.0386 17.2322 14.608 17.6562 14.0709 17.6562ZM22.6693 18.8684L22.6276 18.8868C20.8681 19.7579 18.8076 19.7579 17.0481 18.8868L17.0064 18.8684C16.7981 18.767 16.6684 18.555 16.6684 18.3245C16.6684 17.9881 16.9416 17.7161 17.2796 17.7161H22.4007C22.7387 17.7161 23.0119 17.9881 23.0119 18.3245C23.0119 18.555 22.8776 18.767 22.6739 18.8684H22.6693ZM25.7252 17.4258C25.1927 17.4258 24.7621 16.9971 24.7621 16.4671C24.7621 15.9371 25.1927 15.5084 25.7252 15.5084C26.2577 15.5084 26.6929 15.9371 26.6929 16.4671C26.6929 16.9971 26.2623 17.4258 25.7252 17.4258Z"
					fill="#00E9DB"
				/>
			</g>
			<defs>
				<clipPath id="clip0_9004_9585">
					<rect width="34" height="31" fill="white" transform="translate(3 5)" />
				</clipPath>
			</defs>
		</svg>
	);
};
