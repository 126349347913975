import { lazy } from 'react';
import { createBrowserRouter } from 'react-router-dom';
import { NAVIGATE_PATHS } from '@/app-constants/router';
import { TransactionResult } from '@/app-views/txs-result/pages/TransactionResult';

import { OnboardingRouter } from './onboarding.router';
import { PortfolioRouter } from './portfolio-router';
import { TradingRouter } from './swap.router';
import { WalletRouter } from './wallet.router';
import { getEnvironment } from '@/app-helpers';
import { BotRouter } from './bot.router';
import { RouterErrorBoundary } from '@/app-components/layout/RouterErrorBoudary';
import { AppLayout } from '@/app-components/layout/AppLayout';

const Icons = lazy(() => import('@/app-views/testing/Icons'));

const AppRouter = [
	{
		path: NAVIGATE_PATHS.transactionResult(':type', ':chainId', ':txHash'),
		element: <TransactionResult />,
	},
];

if (getEnvironment('dev')) {
	AppRouter.push({
		path: NAVIGATE_PATHS.Testing.AppIcon,
		element: <Icons />,
	});
}

const MainRouter = createBrowserRouter([
	{
		element: <AppLayout />,
		errorElement: <RouterErrorBoundary />,
		children: [
			...OnboardingRouter,
			...TradingRouter,
			...WalletRouter,
			...PortfolioRouter,
			...AppRouter,
			...BotRouter,
		],
	},
]);

export { MainRouter };
