import { useQuery } from '@tanstack/react-query';
import { isEmpty } from 'lodash-es';
import { tronWallet } from '@/app-cores/mpc-wallet/tron';
import { MpcWallet } from '@/app-cores/mpc-wallet/wallet';
import { isNativeToken } from '@/app-helpers/address';
import { TSentTronTransaction } from '@/app-types';
import { ContractFunctionParameter, TriggerConstantContractOptions } from 'node_modules/tronweb/lib/esm/types';
import { getTronTransferBalance } from '@/app-helpers/tron';
import { getNativeToken } from '@/app-helpers/token';
import { truncateToFixed } from '@/app-helpers/number';
import { ChainId } from '@/app-constants/chains';
import { parseUnits } from 'ethers';

const BUFFER_FEE = 0.5;
const ACTIVE_ACCOUNT_FEE = 1.1;

// generic
function estimateBandwidth(signedTxn) {
	const DATA_HEX_PROTOBUF_EXTRA = 3;
	const MAX_RESULT_SIZE_IN_TX = 64;
	const A_SIGNATURE = 67;
	let len = signedTxn.raw_data_hex.length / 2 + DATA_HEX_PROTOBUF_EXTRA + MAX_RESULT_SIZE_IN_TX;
	const signatureListSize = signedTxn.signature.length;
	for (let i = 0; i < signatureListSize; i++) {
		len += A_SIGNATURE;
	}
	return len;
}

export type EstimateGasTronParams = {
	contractAddress: string;
	functionSelector: string;
	options?: TriggerConstantContractOptions;
	parameters: ContractFunctionParameter[];
};
export const estimateTronGasFee = async ({
	contractAddress,
	functionSelector,
	options = {},
	parameters,
}: EstimateGasTronParams) => {
	const unsignedTransaction = await tronWallet.tronWeb.transactionBuilder.triggerConstantContract(
		contractAddress,
		functionSelector,
		options,
		parameters,
		MpcWallet.getTronWalletAddress(),
	);
	const [signedTransaction, chainParameters] = await Promise.all([
		tronWallet.signTransaction(unsignedTransaction.transaction),
		tronWallet.tronWeb.trx.getChainParameters(),
	]);
	const energyPrice = chainParameters.find((param) => param.key === 'getEnergyFee').value;
	const gasFeeSun = ((unsignedTransaction.energy_used || 0) * energyPrice) / 1000000;
	const bandwidth = estimateBandwidth(signedTransaction);
	const gasFeeTron = gasFeeSun + bandwidth / 1000;

	const native = getNativeToken(ChainId.TRON);
	const totalFeeBigInt = parseUnits(truncateToFixed(gasFeeTron, native.decimals), native.decimals);

	return { ...unsignedTransaction, totalFee: gasFeeTron, totalFeeBigInt };
};

export const estimateSendTronToken = async (sendData: TSentTronTransaction) => {
	const FROM_ADDRESS = MpcWallet.getTronWalletAddress();
	const receiverAccount = await tronWallet.tronWeb.trx.getAccount(sendData.to);
	const accountResources = await tronWallet.tronWeb.trx.getAccountResources(FROM_ADDRESS);
	const isReceiverActive = (receiverAccount?.active_permission?.[0]?.type as any) === 'Active';
	const activeAccountFee = isReceiverActive ? 0 : ACTIVE_ACCOUNT_FEE;
	const isEnoughBandwidth = accountResources.freeNetLimit - (accountResources.freeNetUsed || 0) >= 300;
	if (isNativeToken(sendData.token.address)) {
		if (!isEnoughBandwidth) return activeAccountFee + BUFFER_FEE;
		return activeAccountFee;
	}

	const payload = {
		contractAddress: sendData.token.address,
		functionSelector: 'transfer(address,uint256)',
		options: {},
		parameters: [
			{ type: 'address', value: sendData.to },
			{
				type: 'uint256',
				value: getTronTransferBalance(sendData.amount, sendData.token.decimals),
			},
		],
	};

	const { totalFee } = await estimateTronGasFee(payload);
	return totalFee;
};

export const useEstimateTronGasFee = (sendData: TSentTronTransaction) => {
	const response = useQuery({
		queryKey: ['estimate-transfer-tron-asset', sendData.to, sendData.token.address, sendData?.message],
		queryFn: async () => {
			return estimateSendTronToken(sendData);
		},
		enabled: !isEmpty(sendData.amount) && !isEmpty(sendData.to) && !isEmpty(sendData.token),
		staleTime: 1000 * 30,
	});
	return response;
};
