import React from 'react';

interface IProps extends React.SVGAttributes<HTMLOrSVGElement> {}

export const CheckCircleIcon: React.FC<IProps> = ({ width = 20, height = 20, color = '#00C170', ...rest }) => {
	return (
		<svg width={width} height={height} viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M13.0522 3.43559C11.5749 2.77734 9.92437 2.61427 8.34682 2.9707C6.76927 3.32713 5.34919 4.18395 4.29839 5.41339C3.24758 6.64283 2.62235 8.17901 2.51593 9.79283C2.40952 11.4066 2.82763 13.0116 3.7079 14.3684C4.58817 15.7252 5.88344 16.761 7.40053 17.3215C8.91762 17.882 10.5752 17.9371 12.1262 17.4785C13.6771 17.0199 15.0383 16.0723 16.0067 14.777C16.9751 13.4816 17.4988 11.9079 17.4997 10.2906V9.5244C17.4997 9.06416 17.8728 8.69107 18.333 8.69107C18.7932 8.69107 19.1663 9.06416 19.1663 9.5244V10.2911C19.1652 12.2678 18.5251 14.1917 17.3416 15.7749C16.158 17.3581 14.4944 18.5163 12.5988 19.0768C10.7032 19.6373 8.67716 19.57 6.82294 18.8849C4.96872 18.1999 3.38561 16.9338 2.30972 15.2755C1.23384 13.6172 0.722816 11.6556 0.852878 9.68316C0.98294 7.71073 1.74711 5.83317 3.03143 4.33053C4.31575 2.82788 6.05139 1.78064 7.97951 1.34501C9.90763 0.909376 11.9249 1.10868 13.7305 1.91321C14.1509 2.10053 14.3399 2.59317 14.1525 3.01357C13.9652 3.43396 13.4726 3.62291 13.0522 3.43559Z"
				fill={color}
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M18.9226 3.0348C19.2482 3.36007 19.2485 3.88771 18.9232 4.21331L10.5899 12.555C10.4336 12.7114 10.2216 12.7993 10.0005 12.7993C9.77945 12.7994 9.5674 12.7116 9.41107 12.5553L6.91107 10.0553C6.58563 9.72983 6.58563 9.2022 6.91107 8.87676C7.23651 8.55132 7.76414 8.55132 8.08958 8.87676L10 10.7872L17.7441 3.03539C18.0694 2.70979 18.597 2.70952 18.9226 3.0348Z"
				fill={color}
			/>
		</svg>
	);
};
