import { useNavigate } from 'react-router-dom';
import { Box, BoxProps, Button, Center, Flex, Skeleton, Text } from '@chakra-ui/react';
import { NAVIGATE_PATHS } from '@/app-constants/router';
import { useDepositStore } from '@/app-features/deposit/store';
import { usePortfolioBalance, usePortfolioBalanceByCategories } from '@/app-hooks/api/portfolio/usePortfolioBalance';
import { FiPlusIcon, FiRightIcon } from '@/assets/icons';
import { RequireWallet } from '@/app-components/common';
import { displayCurrency } from '@/app-helpers/display';
import { formatUsd } from '@/app-helpers/number';
import { useWalletStore } from '@/app-store';

function calculateFontSize(value: number) {
	return -3 * value + 44;
}
interface BalanceProps extends BoxProps {
	amount: number;
	useCustomSize?: boolean;
}

export const Balance: React.FC<BalanceProps> = ({ amount, ...boxProps }) => {
	const parse = displayCurrency(formatUsd(amount || 0, { showDollar: false }));
	const numberDigit = parse?.value?.length;
	return (
		<Box display="flex" gap={1} justifyContent="center" {...boxProps}>
			<Box>
				<Text
					fontSize={numberDigit > 4 ? `${calculateFontSize(numberDigit)}` : boxProps.fontSize || '32px'}
					as="span"
					fontWeight={400}
				>
					${parse.value}
				</Text>
				<Text fontSize="xl" as="span">
					.{parse.digit}
				</Text>
			</Box>
		</Box>
	);
};

export const Portfolio = () => {
	const navigate = useNavigate();
	const { onOpen: onOpenDeposit } = useDepositStore();
	const { data, isLoading, isFetched, isRefetching } = usePortfolioBalanceByCategories({ refetchInterval: 3000 });
	const { walletStatus } = useWalletStore();
	const totalUsd = data?.totalUsd || 0;
	const isProfit = data?.totalUsd24hChangeUsd > 0;
	const textColor = !data?.totalUsd24hChangeUsd ? undefined : isProfit ? '#00AFA5' : 'red.600';

	const isShowSkeleton = isLoading && walletStatus !== 'REQUIRE_CREATE';
	const renderBalanceInfo = () => {
		if (isShowSkeleton) return <Skeleton height="30px" width="100%" startColor="white" endColor="#bfc3c9" />;
		return (
			<Center flexDirection="column">
				{totalUsd > 0 ? (
					<>
						<Text color={textColor} fontSize="sm" textAlign="end">
							{!data?.totalUsd24hChangeUsd ? '' : isProfit ? '+' : ''}
							{formatUsd(data?.totalUsd24hChangeUsd) || 0}
						</Text>
						<Text color="gray.400" fontSize="xs" textAlign="end">
							{data?.totalUsd24hChangePercent?.toFixed(2) || 0}% 24h
						</Text>
					</>
				) : (
					<RequireWallet
						mt={2}
						loadingComponent={<Skeleton height="32px" width="100%" startColor="white" endColor="#bfc3c9" />}
					>
						<Button
							colorScheme="cyan"
							borderRadius={8}
							fontSize="xs"
							size="sm"
							onClick={(e) => {
								e.stopPropagation();
								onOpenDeposit();
							}}
						>
							<FiPlusIcon width={16} height={16} />
							Deposit Now
						</Button>
					</RequireWallet>
				)}
			</Center>
		);
	};
	return (
		<Flex
			onClick={() => {
				if (walletStatus === 'CREATING' || walletStatus === 'INITIALIZING') return;
				if (walletStatus === 'REQUIRE_RECOVERY') {
					return navigate(NAVIGATE_PATHS.Recovery.SelectMethod);
				}
				if (walletStatus === 'REQUIRE_BACKUP') {
					return navigate(NAVIGATE_PATHS.CreateWallet.StoreBackupKey);
				}
				if (walletStatus === 'REQUIRE_CREATE') {
					return navigate(NAVIGATE_PATHS.CreateWallet.Onboarding);
				}
				navigate(NAVIGATE_PATHS.Wallet.Wallet);
			}}
			flexDirection="column"
			gap={4}
			flex={1}
			backgroundColor="white"
			pb={3}
			pt="6px"
			px={4}
			borderRadius={8}
			cursor="pointer"
		>
			<Center justifyContent="space-between">
				<Box>
					<Center fontSize="xs" fontWeight={600}>
						Portfolio <FiRightIcon width={20} height={20} fill="gray" />
					</Center>
				</Box>
				{totalUsd > 0 ? (
					<RequireWallet
						transform="translateX(10px)"
						loadingComponent={
							<Skeleton
								height="32px"
								width="32px"
								borderRadius="100%"
								startColor="white"
								endColor="#bfc3c9"
							/>
						}
					>
						<Center
							w={8}
							h={8}
							border="1px solid rgba(0, 0, 0, 0.08)"
							borderRadius="100%"
							fontSize="xs"
							onClick={(e) => {
								e.stopPropagation();
								onOpenDeposit();
							}}
							cursor="pointer"
						>
							<Box>
								<FiPlusIcon width={16} height={16} />
							</Box>
						</Center>
					</RequireWallet>
				) : (
					<Box w={8} h={8}></Box>
				)}
			</Center>
			<Center flexDirection="column">
				{isShowSkeleton ? (
					<Center height="57px" width="100%">
						<Skeleton height="42px" width="100%" startColor="white" endColor="#bfc3c9" />
					</Center>
				) : (
					<Balance amount={totalUsd} justifyContent="start" fontSize="32px" />
				)}
			</Center>
			{renderBalanceInfo()}
		</Flex>
	);
};
