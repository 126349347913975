import { QUERY_KEYS } from '@/app-constants';
import { ChainId, NATIVE_TOKEN_ADDRESS } from '@/app-constants/chains';
import { CreateRequestPayload, ServiceRequestPayment, UpdateRequestPayload, UserPaymentAmount } from '@/app-cores/api';
import { PaymentService } from '@/app-cores/api/bot/service-payment';
import { queryClient } from '@/app-cores/query-client';
import { TelegramCore } from '@/app-cores/telegram';
import { compareChain } from '@/app-helpers/address';
import { useMutation, useQuery } from '@tanstack/react-query';

export const useClaimTestnetToken = () => {
	return useMutation({
		mutationKey: ['mutation-claim-testnet'],
		mutationFn: async (): Promise<boolean> => {
			return PaymentService.claim({
				chainId: ChainId.BERACHAIN_TESTNET,
				tokenAddress: NATIVE_TOKEN_ADDRESS,
			});
		},
		onSuccess: () => {
			queryClient.invalidateQueries({
				queryKey: [QUERY_KEYS.GET_CLAIM_TESTNET_STATUS],
			});
		},
	});
};

export const useQueryStatusClaim = ({ chainId, tokenAddress }) => {
	const response = useQuery({
		queryKey: [QUERY_KEYS.GET_CLAIM_TESTNET_STATUS, chainId, tokenAddress],
		queryFn: async () => {
			return PaymentService.getStatusClaim({
				chainId,
				tokenAddress,
			});
		},
		staleTime: 10 * 60 * 1000,
		enabled: compareChain(chainId, ChainId.BERACHAIN_TESTNET) && !!tokenAddress,
	});
	const data = response.data;
	return { ...response, canClaim: +data?.claimedTimes < +data?.maxClaimTimes };
};

export const useQueryGetRequestFund = (enabled: boolean) => {
	const response = useQuery({
		queryKey: [QUERY_KEYS.GET_REQ_PAYMENTS],
		queryFn: async () => {
			return ServiceRequestPayment.getMyRequests();
		},
		staleTime: 10 * 60 * 1000,
		enabled,
	});
	return response;
};

export const useQueryGetRequestFundById = (id: string) => {
	const response = useQuery({
		queryKey: [QUERY_KEYS.GET_REQ_PAYMENTS, id],
		queryFn: async () => {
			const request = await ServiceRequestPayment.getRequestById(id);
			const myId = TelegramCore.getUserId().toString();
			if (request && request.creatorId !== myId && !request.payers.some((e) => e.telegramId === myId)) {
				throw new Error('Dont have permission to access this request');
			}
			return request;
		},
		staleTime: 10 * 60 * 1000,
		enabled: !!id,
	});
	return response;
};

export const useCreateRequestPayment = () => {
	return useMutation({
		mutationKey: ['mutation-create-request'],
		mutationFn: async (data: CreateRequestPayload) => {
			return ServiceRequestPayment.createRequest(data);
		},
		onSuccess: () => {
			queryClient.invalidateQueries({
				queryKey: [QUERY_KEYS.GET_REQ_PAYMENTS],
			});
		},
	});
};

export const useUpdateRequestPayment = () => {
	return useMutation({
		mutationKey: ['mutation-update-request'],
		mutationFn: async (data: UpdateRequestPayload) => {
			return ServiceRequestPayment.updateRequest(data);
		},
		onSuccess: (_, data) => {
			queryClient.invalidateQueries({
				queryKey: [QUERY_KEYS.GET_REQ_PAYMENTS, data.id],
			});
		},
	});
};
