import { isEmpty } from 'lodash-es';
import { PublicKey } from '@solana/web3.js';
import { ethers, isAddress } from 'ethers';
import { Address } from '@ton/ton';
import { TronWeb } from 'tronweb';
import { isValidSuiAddress, SUI_TYPE_ARG } from '@mysten/sui/utils';

import {
	CHAIN_CONFIG,
	NATIVE_TOKEN_ADDRESS,
	SOL_ADDRESS_DEBRIDGE_OR_SWING,
	TON_NATIVE_ADDRESS,
	STABLE_COINS,
	ZERO_ADDRESS,
	ChainId,
	SUI_NATIVE_ADDRESS,
	TRON_NATIVE_ADDRESS,
} from '@/app-constants/chains';
import { ITokenSearch, TokenInfo } from '@/app-cores/api/bff';
import { WalletType } from '@/app-cores/api';
import { getTokenInfo } from '@/app-helpers/token';

const safeGetAddress = (address: string, forceEvm = true) => {
	try {
		if (!address) {
			return '';
		}
		return !forceEvm ? address : ethers.getAddress(address);
	} catch (e) {}
	return '';
};

export const getShortAddress = (address: string, options?: { start?: number; end?: number }) => {
	const { start = 7, end = 5 } = options || {};

	const validAddress = safeGetAddress(address, false);

	if (!validAddress) {
		return '';
	}

	return address.substr(0, start) + '...' + address.substr(address.length - end, address.length);
};

export const compareAddress = (address1: string, address2: string) => {
	if (!address1 || !address2) {
		return false;
	}
	return address1.toLowerCase() === address2.toLowerCase();
};

export const compareChain = (chain1: string | number, chain2: string | number) => {
	if (!chain1 || !chain2) {
		return false;
	}
	return chain1.toString().toLowerCase() === chain2.toString().toLowerCase();
};

// compare token by address and chainId
export const compareToken = (token1: any, token2: any) => {
	return (
		token1 &&
		token2 &&
		compareAddress(token1.address || token1.contractAddress, token2.address || token2.contractAddress) &&
		compareChain(token1.chainId, token2.chainId)
	);
};

export const compareTobiToken = (token1: ITokenSearch, token2: ITokenSearch) => {
	if (!token1 || !token2) return false;
	const { idTobi: idTobi1, chainId: chainId1 } = getTokenInfo(token1);
	const { idTobi: idTobi2, chainId: chainId2 } = getTokenInfo(token2);
	return idTobi1 === idTobi2 && compareChain(chainId1, chainId2);
};

export const isNativeToken = (address: string) => {
	return [
		NATIVE_TOKEN_ADDRESS,
		ZERO_ADDRESS,
		TRON_NATIVE_ADDRESS,
		TON_NATIVE_ADDRESS,
		SUI_NATIVE_ADDRESS,
		SUI_TYPE_ARG,
		SOL_ADDRESS_DEBRIDGE_OR_SWING,
	].some((e) => compareAddress(address, e));
};

export const isNativeTobiToken = (token: ITokenSearch) => {
	return isNativeToken(getTokenInfo(token).address);
};

export const isStableCoin = (token: TokenInfo) => {
	if (!token) return false;
	const data = STABLE_COINS[token?.chainId] || {};
	return Object.values(data).some((address: string) => compareAddress(address, token?.address));
};

export const isWrapNativeToken = (address: string, chainId: string) => {
	return address?.toLowerCase() === CHAIN_CONFIG[chainId].wrapNativeToken?.address.toLowerCase();
};

export const getBlockChainNameByAddress = (address: string): WalletType | undefined => {
	if (isEmpty(address)) return undefined;
	try {
		if (isAddress(address)) return WalletType.EVM;
	} catch (error) {}
	try {
		const solAddress = new PublicKey(address);
		if (PublicKey.isOnCurve(solAddress)) return WalletType.SOL;
	} catch (error) {}
	try {
		const tonAddress = Address.parse(address);
		if (tonAddress) return WalletType.TON;
	} catch (error) {}
	try {
		const isTronAddress = TronWeb.isAddress(address);
		if (isTronAddress) return WalletType.TRON;
	} catch (error) {}
	try {
		const isSuiAddress = isValidSuiAddress(address);
		if (isSuiAddress) return WalletType.SUI;
	} catch (error) {}
	return undefined;
};

export function getBlockChainNameByChainId(chainId: string): WalletType {
	if ([ChainId.TON, ChainId.TRON, ChainId.SOL, ChainId.SUI].includes(chainId as ChainId))
		return chainId as WalletType;
	return WalletType.EVM;
}
