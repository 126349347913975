import { RouteObject } from 'react-router';

import { NAVIGATE_PATHS } from '@/app-constants/router';
import TokenDetail from '../app-views/portfolio/pages/token';

export const PortfolioRouter: RouteObject[] = [
	{
		path: NAVIGATE_PATHS.Portfolio.asset,
		element: <TokenDetail />,
	},
];
