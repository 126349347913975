import { memo, useRef } from 'react';
import { Card } from '@chakra-ui/react';
import { TxStatus, WalletActivity } from '@/app-cores/api/activities';
import { ActivityItemHeader } from './Header';
import { ActivityItemAction } from './Action';
import { ActivityItemTokenFlow } from './TokenFlow';
import { useIntersectionObserver } from '@/app-hooks/common';
import { useTransactionWatcherStore } from '@/app-store';

interface ActivityItemProps {
	data: WalletActivity;
	isPending?: boolean;
	listData: WalletActivity[];
}
export const ActivityItemRender: React.FC<ActivityItemProps> = ({ data, isPending, listData }) => {
	const containerRef = useRef<HTMLDivElement>(null);
	const entry = useIntersectionObserver(containerRef, {
		threshold: 0.1,
		freezeOnceVisible: true,
		rootMargin: '-50px',
	});

	return (
		<Card fontSize="sm" px={5} py={4} mb={3} ref={containerRef}>
			<ActivityItemHeader listData={listData} data={data} />
			<ActivityItemAction data={data} />
			<ActivityItemTokenFlow data={data} visible={entry?.isIntersecting} />
		</Card>
	);
};
export const ActivityItem = memo(ActivityItemRender);
