import { useNavigate } from 'react-router-dom';
import { ConfirmSendTransaction, Source } from '@/app-components/common';
import { useSentTokenStore, useContactStore } from '@/app-store';
import { NAVIGATE_PATHS } from '@/app-constants/router';
import { usePriceNativeToken } from '@/app-hooks/api/portfolio/useTokenPrices';
import { TransactionType } from '@/app-types';
import { DATADOG_ERROR_TAGS, dataDogAddError } from '@/app-services/monitor/datadog';
import { showTransactionError } from '@/app-helpers/error-handling';
import { useEstimateTronGasFee } from '@/app-hooks/transactions/tron/useEstimateTronGasFee';
import { formatUsd } from '@/app-helpers/number';
import { useSentTronToken } from '@/app-hooks/transactions/tron/useMutationSendTronAsset';
interface ConfirmSentTronAssetProps {
	source?: Source;
}
export const ConfirmSentTronAsset: React.FC<ConfirmSentTronAssetProps> = ({ source }) => {
	const { token, amount, messages, tokenInfo } = useSentTokenStore();
	const { contact, address } = useContactStore();
	const sentTo = contact?.wallets?.tron?.address || address;
	const { data: estimateGasFee } = useEstimateTronGasFee({ to: sentTo, amount, token: tokenInfo, message: messages });

	const { data: nativePriceUsd = 0 } = usePriceNativeToken({
		chainId: tokenInfo?.chainId,
	});
	const { sendTransaction, isPending } = useSentTronToken();
	const navigate = useNavigate();
	const handleConfirmTransaction = async () => {
		try {
			if (isPending) return;
			const rs = await sendTransaction({
				amount,
				to: sentTo,
				token: tokenInfo,
				message: messages,
				gasFee: estimateGasFee,
			});
			navigate(NAVIGATE_PATHS.transactionResult(TransactionType.Send, tokenInfo.chainId, rs.transaction.txID));
		} catch (error) {
			showTransactionError(error);
			dataDogAddError(error, {
				tags: {
					name: DATADOG_ERROR_TAGS.SEND,
					function: 'transferTRC20Asset',
				},
			});
		}
	};

	const gasFee = {
		feeUsd: formatUsd(estimateGasFee * nativePriceUsd),
		feeToken: estimateGasFee,
	};
	return (
		<ConfirmSendTransaction
			feeToken={gasFee.feeToken?.toString()}
			feeUsd={gasFee.feeUsd}
			isLoading={isPending}
			onConfirm={handleConfirmTransaction}
			source={source}
		/>
	);
};
