import {
	Box,
	Button,
	CloseButton,
	Drawer,
	DrawerBody,
	DrawerContent,
	DrawerFooter,
	DrawerHeader,
	DrawerOverlay,
	Text,
} from '@chakra-ui/react';
import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { Balance } from '@/app-components/common/Balance';
import { NATIVE_TOKEN_ADDRESS } from '@/app-constants/chains';
import { usePriceNativeToken, useTokenPriceSingleToken } from '@/app-hooks/api/portfolio/useTokenPrices';
import { useCancelTransaction, useEstimateCancelGasFee } from '@/app-hooks/transactions';
import { useTransactionWatcherStore } from '@/app-store';
import { useActivitiesState } from './state';
import { ITransactionStatus, TransactionType } from '@/app-types';
import { Toast } from '@/app-components/common';
import { useTranslation } from 'react-i18next';
import { TxStatus } from '@/app-cores/api/activities';
import { ChainType } from '@/app-contexts/wallet-provider/type';

const CancelActivity: React.FC<{
	onStatusChange?: (status: ITransactionStatus) => void;
	onClickButtonCancel?: () => void;
}> = ({ onStatusChange = () => void 0, onClickButtonCancel }) => {
	const navigate = useNavigate();
	const { t } = useTranslation();
	const [isCanceling, setIsCanceling] = useState(false);
	const { cancelActivity, currentTx, currentTxType } = useActivitiesState();
	const { data: estCancelGasFeeData, error } = useEstimateCancelGasFee({
		transaction: currentTx,
	});
	const { addPendingEvmTransaction, removePendingTransaction } = useTransactionWatcherStore();

	const { data: feePriceUsd = 0 } = usePriceNativeToken({
		chainId: currentTx?.chainId?.toString(),
	});

	const { cancelTransaction } = useCancelTransaction({
		txHash: currentTx?.hash,
	});

	const onCancelTransaction = async () => {
		try {
			setIsCanceling(true);
			onStatusChange?.(ITransactionStatus.CANCELING);
			onClickButtonCancel?.();
			const cancelRes = await cancelTransaction();
			addPendingEvmTransaction({
				transaction: cancelRes,
				callback: ({ status }) => {
					setIsCanceling(false);
					if (status === TxStatus.Success) {
						cancelActivity();
						removePendingTransaction({ hash: currentTx?.hash, type: ChainType.EVM });
						toast(<Toast type="success" message={t('transactionResult.cancelSuccess')} />);
					} else {
						toast(<Toast type="error" message={t('transactionResult.cancelFailed')} />);
					}
				},
				metadata: { transactionType: currentTxType },
			});
		} catch (error) {
			setIsCanceling(false);
			onStatusChange?.(ITransactionStatus.CANCEL_ERROR);
			cancelActivity();
			toast(<Toast type="error" message={t('transactionResult.cancelFailed')} />);
			console.error('onCancelTransaction error:', error);
		}
	};

	return (
		<Drawer placement="bottom" onClose={() => cancelActivity()} isOpen={!!currentTx} trapFocus={false}>
			<DrawerOverlay />
			<DrawerContent borderRadius={'8px 8px 0 0'}>
				<DrawerHeader
					display={'flex'}
					justifyContent={'space-between'}
					alignItems={'center'}
					borderBottomWidth="1px"
					borderColor={'gray.100'}
					textAlign={'center'}
				>
					<Text flex={1}>{t('cryptos.cancelTitle')}</Text>
					<CloseButton onClick={() => cancelActivity()} />
				</DrawerHeader>
				<DrawerBody>
					<Text textAlign={'center'} fontSize={'xs'}>
						{t('cryptos.cancelationGasFee')}
					</Text>
					<Balance amount={(+estCancelGasFeeData?.gasFeeInNativeToken || 0) * feePriceUsd} />
					<Box
						fontSize={'sm'}
						color={'orange.500'}
						border={'1px solid'}
						borderColor={'gray.100'}
						borderRadius={12}
						px={6}
						py={3}
					>
						{t('cryptos.cancelDescription')}
					</Box>
				</DrawerBody>
				<DrawerFooter display={'flex'} gap={2}>
					<Button onClick={() => cancelActivity()} mb={2} flex={1} borderRadius={'.75rem'}>
						{t('button.close')}
					</Button>
					<Button
						isDisabled={isCanceling}
						isLoading={isCanceling}
						onClick={onCancelTransaction}
						colorScheme={'cyan'}
						mb={2}
						flex={1}
					>
						{t('button.okIAgree')}
					</Button>
				</DrawerFooter>
			</DrawerContent>
		</Drawer>
	);
};

export default CancelActivity;
