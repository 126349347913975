import { CardSelection } from '@/app-components/common';
import { FiLogoutIcon, FiRightIcon } from '@/assets/icons';
import { Box, Button, Center, Flex, Spinner, Text } from '@chakra-ui/react';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

interface ProviderSelectionProps {
	onClick: () => void;
	icon?: React.ReactNode;
	text: string;
	isConnected?: boolean;
	onDisconnect?: () => void;
	isDisconnecting?: boolean;
	isInitializing?: boolean;
}
export const ProviderSelection: React.FC<ProviderSelectionProps> = ({
	onClick,
	text,
	icon,
	isConnected,
	onDisconnect,
	isDisconnecting,
	isInitializing,
}) => {
	const { t } = useTranslation();
	const status = useMemo(() => {
		if (isInitializing)
			return (
				<Center>
					<Text fontSize="xs" fontWeight={500} color="gray.400">
						Initializing
					</Text>
					<Box ml={4} className="dot-loader" width="3px"></Box>
				</Center>
			);
		if (!isConnected) return <FiRightIcon width={20} height={20} />;

		return (
			<Center gap={2}>
				<Button
					size="sm"
					fontSize="xs"
					fontWeight={500}
					border="1px solid rgba(0, 0, 0, 0.08)"
					backgroundColor="transparent"
				>
					{t('deposit.deposit')}
				</Button>
				<Center
					cursor="pointer"
					backgroundColor="rgba(227, 42, 42, 0.08)"
					_hover={{
						backgroundColor: 'rgba(227, 42, 42, 0.5)',
					}}
					fontSize="xs"
					onClick={(e) => {
						if (isDisconnecting) return;
						e.stopPropagation();
						onDisconnect?.();
					}}
					width={8}
					height={8}
					borderRadius="100%"
				>
					{isDisconnecting ? <Spinner size="sm" color="red" /> : <FiLogoutIcon />}
				</Center>
			</Center>
		);
	}, [isConnected, isDisconnecting, isInitializing]);
	return (
		<CardSelection
			icon={icon}
			leftContent={
				<Text fontWeight={600} fontSize="sm">
					{text}
				</Text>
			}
			rightContent={status}
			onClick={onClick}
			cursor="pointer"
		/>
	);
};
