import { TelegramCore } from '@/app-cores/telegram';
import { parseErrorMessage } from '@/app-helpers/error-handling';
import { JsonStorage } from '@/app-helpers/json-storage';
import * as jose from 'jose';
import { IdPayload } from './types';
import { STORAGE_KEYS } from '@/app-constants/storage';

export class AuthTokenManager {
	readonly ACCESS_TOKEN = 'accessToken';

	#accessToken: string;

	#storage: JsonStorage;

	#payload: IdPayload;

	#tokenAuthority: Map<string, boolean>;

	constructor() {
		this.#storage = new JsonStorage({
			storageKey: STORAGE_KEYS.TOBI_AUTH_TOKEN,
		});

		this.#tokenAuthority = new Map<string, boolean>();
	}

	get accessToken() {
		return this.#accessToken;
	}

	get payload() {
		return this.#payload;
	}

	exist() {
		return !!this.#accessToken;
	}

	applyJwtPayload(jwtPayload: jose.JWTPayload) {
		if (!jwtPayload) {
			return;
		}

		const payloadUser = jwtPayload.user as IdPayload['user'];
		this.#payload = {
			aud: jwtPayload.aud as string,
			exp: jwtPayload.exp,
			iat: jwtPayload.iat,
			iss: jwtPayload.iss,
			jti: jwtPayload.jti,
			sub: jwtPayload.sub,
			user: {
				id: payloadUser.id,
				telegram_id: payloadUser.telegram_id,
				family_name: payloadUser.family_name,
				given_name: payloadUser.given_name,
				username: payloadUser.username,
				wallet: payloadUser.wallet,
			},
		};
	}

	async decodeToken(token: string) {
		try {
			return jose.decodeJwt(token);
		} catch (e) {
			return null;
		}
	}

	async verifyToken(token: string) {
		// If given token is already verified, return decoded payload only without verifying JWT again
		if (this.#tokenAuthority.get(token) === true) {
			const payload = await this.decodeToken(token);
			const isExpired = !payload || payload.exp * 1000 <= Date.now();
			if (isExpired) {
				throw new Error('Token is expired');
			}
			return payload;
		}

		const publicKey = await jose.importSPKI(import.meta.env.VITE_JWT_PUBLIC_KEY, 'RS256');
		const { payload } = await jose.jwtVerify(token, publicKey);

		this.#tokenAuthority.set(token, true);
		return payload;
	}

	async init() {
		const accessToken = this.#storage.get<string>(this.ACCESS_TOKEN);

		if (accessToken) {
			await this.setTokens({ accessToken });

			// Token may be expired
			// Decode jwt for payload without verifying JWT (wait for refreshing token to claim new)
			if (!this.#payload) {
				const rawDecoded = await this.decodeToken(accessToken);
				this.applyJwtPayload(rawDecoded);
			}
		}
	}

	async setTokens({ accessToken }: { accessToken: string }) {
		if (!accessToken) {
			return;
		}

		try {
			const payload = await this.verifyToken(accessToken);
			this.applyJwtPayload(payload);

			this.#accessToken = accessToken;

			this.#storage.set(this.ACCESS_TOKEN, accessToken);
		} catch (e) {
			console.warn('Error while setting tokens: ' + parseErrorMessage(e));
		}
	}

	async verify(hint: 'access_token' | 'refresh_token') {
		try {
			const jwt = this.#accessToken;
			await this.verifyToken(jwt);
			return true;
		} catch (e) {
			console.warn(`Error while validating ${hint}: ` + parseErrorMessage(e));
		}
		return false;
	}
}
