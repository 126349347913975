import { Toast } from '@/app-components/common';
import { RECOVERY_TYPE } from '@/app-constants/backup';
import { MpcWallet } from '@/app-cores/mpc-wallet/wallet';
import { buildEncryptUnsecuredKeyShare } from '@/app-helpers/backup';
import { useMutationSendEmailBackupKey } from '@/app-hooks/api/mpc-wallet';
import { useMutationSetBackupType } from '@/app-hooks/api/user/useMutationSetBackupType';
import { Center, Text } from '@chakra-ui/layout';
import { Drawer, DrawerBody, DrawerCloseButton, DrawerContent, DrawerHeader, DrawerOverlay } from '@chakra-ui/modal';
import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { toast } from 'react-toastify';
import { ConfirmReceiveEmail } from './components/ConfrimRecieve';
import { SendForm } from './components/SentForm';
import { DATADOG_ACTIONS, DATADOG_ERROR_TAGS, dataDogAddAction, dataDogAddError } from '@/app-services/monitor/datadog';
import { tourGuideManager } from '@/app-views/wallet/components/TourGuide/helper';

export const FILE_NAME_PREFIX = 'DO_NOT_DELETE';
interface SendEmailProps {
	isOpen: boolean;
	onClose: () => void;
	encryptedPassword: string;
}

type View = 'sent' | 'confirm' | 'code';

const DrawerTitle: Record<View, string> = {
	sent: 'Enter Email',
	code: 'Enter Code',
	confirm: 'Confirm Receipt',
};

export const SendEmail: React.FC<SendEmailProps> = ({ isOpen, onClose, encryptedPassword }) => {
	const [view, setView] = useState<View>('sent');
	const { t } = useTranslation();
	const { mutateAsync: sendEmailBackupKey, isPending } = useMutationSendEmailBackupKey();
	const { mutateAsync: setBackupType } = useMutationSetBackupType();
	const navigate = useNavigate();
	const [resendCount, setResendCount] = useState(0);
	const payloadRef = useRef(null);
	const bodyContainerRef = useRef<HTMLDivElement>(null);

	const handleSentEmail = async (payload?: { email: string; code: string }) => {
		if (payload) {
			payloadRef.current = payload;
		}
		try {
			const { email, code } = payload || {};
			if (isPending) return;
			const wallet = MpcWallet.getWalletAddress().evmAddress;
			const fileName = `${FILE_NAME_PREFIX}_${wallet}.txt`;
			const keyShareStore = buildEncryptUnsecuredKeyShare(encryptedPassword);
			const formData = new FormData();
			formData.append(
				'file',
				new Blob([keyShareStore], {
					type: 'text/plain',
				}),
				fileName,
			);
			formData.append('email', email || payloadRef.current.email);
			formData.append('code', code || payloadRef.current.code);
			const data = await sendEmailBackupKey(formData);
			setResendCount((preCount) => preCount + 1);
			setView('confirm');
			console.log('sent email result', data);
			toast(
				<Toast
					title={t('storeBackup.toast.backupSuccess.title')}
					type="success"
					message={t('storeBackup.toast.backupSuccess.message')}
				/>,
			);
			dataDogAddAction(DATADOG_ACTIONS.BACKUP_SENT_MAIL);
		} catch (error) {
			console.log('sent email error:', error);
			dataDogAddError(error, {
				tags: {
					name: DATADOG_ERROR_TAGS.CREATE_WALLET,
					function: 'sendEmail',
				},
			});
			console.log('ERROR', error);
			toast(
				<Toast
					title={t('storeBackup.toast.backupError.title')}
					type="error"
					message={t('storeBackup.toast.backupError.message')}
				/>,
			);
		}
	};

	const handleConfirmReceiveEmail = async () => {
		try {
			await setBackupType({
				type: RECOVERY_TYPE.EMAIL,
				email: payloadRef.current.email,
			});
			dataDogAddAction(DATADOG_ACTIONS.BACKUP_CONFIRM_RECEIVE_EMAIL);
			onClose();
			tourGuideManager.enable();
			navigate('/');
		} catch (error) {
			console.error('store backup type error', error);
		}
	};

	return (
		<Drawer placement="bottom" onClose={onClose} isOpen={isOpen}>
			<DrawerOverlay />
			<DrawerContent>
				<DrawerHeader borderBottomWidth="1px" borderBottomColor="rgba(0, 0, 0, 0.08)">
					<Center>
						<Text fontSize={'16px'} fontWeight={'500'}>
							{DrawerTitle[view]}
						</Text>
					</Center>
					<DrawerCloseButton />
				</DrawerHeader>
				<DrawerBody px={4} py={5} ref={bodyContainerRef}>
					{view === 'confirm' ? (
						<ConfirmReceiveEmail
							onResentEmail={handleSentEmail}
							isResendingEmail={isPending}
							resendCount={resendCount}
							onConfirm={handleConfirmReceiveEmail}
						/>
					) : (
						<SendForm
							onSendEmail={handleSentEmail}
							isSending={isPending}
							onIosKeyboardOpen={(isOpen, view) => {
								if (!isOpen) {
									return (bodyContainerRef.current.style.paddingBottom = '20px');
								}
								bodyContainerRef.current.style.paddingBottom = '300px';
							}}
						/>
					)}
				</DrawerBody>
			</DrawerContent>
		</Drawer>
	);
};
