import { NAVIGATE_PATHS } from '@/app-constants/router';
import { FiHomeIcon, FiLeftIcon, FiRightIcon } from '@/assets/icons';
import { Button } from '@chakra-ui/button';
import { Center } from '@chakra-ui/layout';
import { BoxProps } from '@chakra-ui/react';
import { isMobile } from 'react-device-detect';
import { useNavigate } from 'react-router-dom';
interface DappNavigateProps extends BoxProps {
	isDisabled?: boolean;
	parentUrl?: string;
}
export const DappNavigate: React.FC<DappNavigateProps> = ({ isDisabled, parentUrl, ...rest }) => {
	const navigation = useNavigate();
	return (
		<Center flex={1} justifyContent={isMobile ? 'space-around' : 'start'} {...rest}>
			<Button
				variant="outline"
				border="none"
				_hover={{
					backgroundColor: 'transparent',
				}}
				onClick={() => {
					history.back();
				}}
				p={0}
				isDisabled={isDisabled}
			>
				<FiLeftIcon />
			</Button>
			<Button
				variant="outline"
				border="none"
				_hover={{
					backgroundColor: 'transparent',
				}}
				onClick={() => {
					history.forward();
				}}
				p={0}
				isDisabled={isDisabled}
			>
				<FiRightIcon width={24} height={24} />
			</Button>
			<Button
				variant="outline"
				border="none"
				_hover={{
					backgroundColor: 'transparent',
				}}
				onClick={() => {
					history.replaceState(null, null, '/');
					navigation(NAVIGATE_PATHS.Dapp.DappBrowser);
				}}
				p={0}
				isDisabled={isDisabled}
			>
				<FiHomeIcon />
			</Button>
		</Center>
	);
};
