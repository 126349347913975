import { Container } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

export const Fallback = ({ error }: any) => {
	const { t } = useTranslation();
	return (
		<Container className="d-flex justify-content-center align-items-center flex-column vh-100 w-100">
			<h4>{t('errors.somethingWentWrong')}</h4>
			<p className="small">{error?.message}</p>
		</Container>
	);
};
