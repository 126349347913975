import {
	Box,
	Button,
	Center,
	CloseButton,
	Drawer,
	DrawerBody,
	DrawerContent,
	DrawerHeader,
	DrawerOverlay,
	DrawerProps,
	Flex,
	Spinner,
	Text,
} from '@chakra-ui/react';
import { FiDownLeftIcon, FiRightIcon, FiSwapIcon } from '../../../assets/icons';
import { ButtonPrimary } from '../Button/Button';
import { CHAIN_CONFIG_MAP, NATIVE_TOKEN_ADDRESS } from '@/app-constants/chains';
import { useNavigateToSwapPage, useNavigateToUrl } from '@/app-helpers/navigate';
import { useSearchSingleToken } from '@/app-hooks/api/portfolio';
import { useDepositStore } from '@/app-features/deposit/store';
import { DATADOG_ACTIONS, dataDogAddAction } from '@/app-services/monitor/datadog';
import { getNativeTobiId, getNativeToken, getTokenInfo } from '@/app-helpers/token';
import { useTranslation } from 'react-i18next';
import TokenLogo from '@/app-components/common/Avatar/TokenLogo';
import { delay } from '@/app-helpers';
import { useToast } from 'react-toastify';
import { Toast } from '@/app-components/common/Toast';
import { toast } from 'react-toastify';
import { useClaimTestnetToken, useQueryStatusClaim } from '@/app-hooks/api/payment/useClaimFund';
import { useNavigate } from 'react-router';
interface TopupTokenProps extends Omit<DrawerProps, 'children'> {
	chainId: string;
}
export const TopupToken: React.FC<TopupTokenProps> = ({ onClose, isOpen, chainId }) => {
	const network = CHAIN_CONFIG_MAP[chainId];
	const { onOpen, setInitToken } = useDepositStore();
	const navigateToSwap = useNavigateToSwapPage();
	const { data: tokenOut } = useSearchSingleToken({
		tobiId: getNativeTobiId(chainId),
		chainId,
	});
	const { symbol } = getTokenInfo(tokenOut);

	return (
		<Drawer placement="bottom" onClose={onClose} isOpen={isOpen} trapFocus={false}>
			<DrawerOverlay />
			<DrawerContent borderRadius={'8px 8px 0 0'}>
				<DrawerHeader
					display={'flex'}
					justifyContent={'space-between'}
					alignItems={'center'}
					borderBottomWidth="1px"
					borderColor={'gray.100'}
					textAlign={'center'}
				>
					<Text mr={-8} flex={1} fontSize={'medium'}>
						Get {symbol}
					</Text>
					<CloseButton onClick={onClose} />
				</DrawerHeader>
				<DrawerBody pb={6}>
					<Flex
						py={4}
						px={6}
						borderRadius={12}
						width={'100%'}
						fontSize="sm"
						gap={4}
						backgroundColor="gray.100"
						cursor="pointer"
						onClick={() => {
							setInitToken(tokenOut);
							onOpen();
							dataDogAddAction(DATADOG_ACTIONS.DEPOSIT_TOP_UP_GAS);
						}}
					>
						<ButtonPrimary width={10} height={10}>
							<Box>
								<FiDownLeftIcon />
							</Box>
						</ButtonPrimary>
						<Center justifyContent="space-between" flex={1}>
							<Box>
								<Text fontWeight={600}>Deposit {symbol}</Text>
								<Text fontSize="xs" color="gray.400">
									Receive {symbol} on the {network?.name} network
								</Text>
							</Box>
							<FiRightIcon width={20} height={20} />
						</Center>
					</Flex>
					<Flex
						py={4}
						px={6}
						mt={2}
						borderRadius={12}
						width={'100%'}
						fontSize="sm"
						gap={4}
						backgroundColor="gray.100"
						cursor="pointer"
						onClick={() => {
							tokenOut && navigateToSwap({ tokenOut });
						}}
					>
						<ButtonPrimary width={10} height={10}>
							<Box>
								<FiSwapIcon />
							</Box>
						</ButtonPrimary>
						<Center justifyContent="space-between" flex={1}>
							<Box>
								<Text fontWeight={600}>Swap to {symbol}</Text>
								<Text fontSize="xs" color="gray.400">
									Swap your assets to {symbol} on the {network?.name} network
								</Text>
							</Box>
							<Box>
								<FiRightIcon width={20} height={20} />
							</Box>
						</Center>
					</Flex>
				</DrawerBody>
			</DrawerContent>
		</Drawer>
	);
};

export const FaucetTestnet: React.FC<TopupTokenProps> = ({ onClose, isOpen, chainId }) => {
	const { t } = useTranslation();
	const native = getNativeToken(chainId);
	const { data: tokenOut } = useSearchSingleToken({
		tobiId: getNativeTobiId(chainId),
		chainId,
	});
	const { logo, symbol } = getTokenInfo(tokenOut);

	const { isFetching, canClaim } = useQueryStatusClaim({ chainId, tokenAddress: NATIVE_TOKEN_ADDRESS });

	const { mutateAsync, isPending } = useClaimTestnetToken();
	const navigate = useNavigateToUrl('https://bartio.faucet.berachain.com', window.location.pathname);

	const onFaucet = async () => {
		if (isPending) return;
		if (!canClaim) {
			navigate();
			return;
		}
		try {
			await mutateAsync();
			toast(<Toast type="success" message={t('testnet.claimSuccess')} />);
		} catch (error) {
			toast(<Toast type="error" message={t('testnet.claimError')} />);
		}
		onClose();
	};

	return (
		<Drawer placement="bottom" onClose={onClose} isOpen={isOpen} trapFocus={false}>
			<DrawerOverlay />
			<DrawerContent borderRadius={'8px 8px 0 0'}>
				<DrawerHeader
					display={'flex'}
					justifyContent={'space-between'}
					alignItems={'center'}
					borderBottomWidth="1px"
					borderColor={'gray.100'}
					textAlign={'center'}
				>
					<Text mr={-8} flex={1} fontSize={'medium'}>
						{t('button.claim')} {native?.symbol}
					</Text>
					<CloseButton onClick={onClose} />
				</DrawerHeader>
				<DrawerBody pb={6} flexDirection={'column'} display={'flex'} gap={6}>
					<Flex direction={'column'} align={'center'} gap={2} fontWeight={'500'}>
						<Text textAlign={'center'}>
							{!isFetching && !canClaim ? t('testnet.alreadyClaim') : t('testnet.faucetForTobiUserDesc')}
						</Text>
						{canClaim && (
							<>
								<TokenLogo logo={logo} symbol={symbol} size={64} />1 {native?.symbol}
							</>
						)}
					</Flex>
					<ButtonPrimary w={'100%'} size={'lg'} onClick={onFaucet}>
						{isPending ? <Spinner /> : canClaim ? t('button.claim') : t('button.go')}
					</ButtonPrimary>
				</DrawerBody>
			</DrawerContent>
		</Drawer>
	);
};
