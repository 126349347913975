import React from 'react';

interface IProps extends React.SVGAttributes<HTMLOrSVGElement> {}

export const FiTrashIcon: React.FC<IProps> = ({ width = 20, height = 20, ...rest }) => {
	return (
		<svg width={width} height={height} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M1.66699 5.00008C1.66699 4.53984 2.04009 4.16675 2.50033 4.16675H17.5003C17.9606 4.16675 18.3337 4.53984 18.3337 5.00008C18.3337 5.46032 17.9606 5.83342 17.5003 5.83342H2.50033C2.04009 5.83342 1.66699 5.46032 1.66699 5.00008Z"
				fill="black"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M8.33301 2.49992C8.11199 2.49992 7.90003 2.58772 7.74375 2.744C7.58747 2.90028 7.49967 3.11224 7.49967 3.33325V4.99992C7.49967 5.46016 7.12658 5.83325 6.66634 5.83325C6.2061 5.83325 5.83301 5.46016 5.83301 4.99992V3.33325C5.83301 2.67021 6.0964 2.03433 6.56524 1.56548C7.03408 1.09664 7.66997 0.833252 8.33301 0.833252H11.6663C12.3294 0.833252 12.9653 1.09664 13.4341 1.56548C13.9029 2.03433 14.1663 2.67021 14.1663 3.33325V4.99992C14.1663 5.46016 13.7932 5.83325 13.333 5.83325C12.8728 5.83325 12.4997 5.46016 12.4997 4.99992V3.33325C12.4997 3.11224 12.4119 2.90028 12.2556 2.744C12.0993 2.58772 11.8874 2.49992 11.6663 2.49992H8.33301ZM4.16634 4.16658C4.62658 4.16658 4.99967 4.53968 4.99967 4.99992V16.6666C4.99967 16.8876 5.08747 17.0996 5.24375 17.2558C5.40003 17.4121 5.61199 17.4999 5.83301 17.4999H14.1663C14.3874 17.4999 14.5993 17.4121 14.7556 17.2558C14.9119 17.0996 14.9997 16.8876 14.9997 16.6666V4.99992C14.9997 4.53968 15.3728 4.16658 15.833 4.16658C16.2932 4.16658 16.6663 4.53968 16.6663 4.99992V16.6666C16.6663 17.3296 16.4029 17.9655 15.9341 18.4344C15.4653 18.9032 14.8294 19.1666 14.1663 19.1666H5.83301C5.16997 19.1666 4.53408 18.9032 4.06524 18.4344C3.5964 17.9655 3.33301 17.3296 3.33301 16.6666V4.99992C3.33301 4.53968 3.7061 4.16658 4.16634 4.16658Z"
				fill="black"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M8.33333 8.33325C8.79357 8.33325 9.16667 8.70635 9.16667 9.16659V14.1666C9.16667 14.6268 8.79357 14.9999 8.33333 14.9999C7.8731 14.9999 7.5 14.6268 7.5 14.1666V9.16659C7.5 8.70635 7.8731 8.33325 8.33333 8.33325Z"
				fill="black"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M11.6663 8.33325C12.1266 8.33325 12.4997 8.70635 12.4997 9.16659V14.1666C12.4997 14.6268 12.1266 14.9999 11.6663 14.9999C11.2061 14.9999 10.833 14.6268 10.833 14.1666V9.16659C10.833 8.70635 11.2061 8.33325 11.6663 8.33325Z"
				fill="black"
			/>
		</svg>
	);
};
