import { getSdkError } from '@walletconnect/utils';
import { isEmpty, toArray } from 'lodash-es';
import { useEffect, useMemo } from 'react';
import { useSnapshot } from 'valtio';
import SettingsStore from '../store/SettingsStore';
import { paringTrackingManager } from '../utils/HelperUtil';
import { web3wallet } from '../utils/WalletConnectUtil';
import { useTAppConnections } from '@/app-features/app-ton-connect/store/ConnectionStore';
import { httpBridgeService } from '@/app-features/app-ton-connect/services/httpBridgeService';
import { ConnectionType } from '@/app-types/connection-type';
import { botConnectorHandler } from '@/app-features/app-bot-connector/utils/bot-connector.handler';
import { tonConnectReturnStrategyManager } from '@/app-features/app-ton-connect/utils/returnStrategyManager';
import { tonConnectEventId } from '@/app-features/app-ton-connect/utils/common';

export interface StoreSession {
	id: string;
	type: ConnectionType;
	metadata: {
		name: string;
		icons: string[] | readonly string[];
		url: string;
	};
	data?: any;
}

export const useConnectionManager = () => {
	const { sessions: walletConnectSessions } = useSnapshot(SettingsStore.state);
	const textBotSessions = botConnectorHandler.getActiveClients();
	const { connections: tonConnections, removeConnection } = useTAppConnections();

	const disconnect = async (session: StoreSession) => {
		switch (session.type) {
			case ConnectionType.TextBot: {
				return botConnectorHandler.unActiveClient(session.id);
			}
			case ConnectionType.TonConnect: {
				console.log('session', session);
				const lastEventId = +tonConnectEventId.getEventId();
				const rs = await httpBridgeService.sendEventToBridge({
					messages: {
						event: 'disconnect',
						id: lastEventId,
						payload: {},
					},
					clientSessionId: session.data.clientSessionId,
					sessionKeyPair: session.data.sessionKeyPair,
				});
				tonConnectReturnStrategyManager.removeReturnStrategy(session.data?.clientSessionId);
				removeConnection(session.data);
				tonConnectEventId.storeEventId(lastEventId + 1);
				return rs;
			}
			default: {
				await web3wallet.disconnectSession({
					topic: session.data.topic,
					reason: getSdkError('USER_DISCONNECTED'),
				});
				return SettingsStore.setSessions(Object.values(web3wallet.getActiveSessions()));
			}
		}
	};

	const sessions = useMemo(() => {
		const walletConnects: StoreSession[] = toArray(walletConnectSessions).map<StoreSession>((item) => ({
			id: item.pairingTopic,
			type: ConnectionType.WalletConnect,
			metadata: item.peer.metadata,
			data: item,
		}));
		if (!isEmpty(textBotSessions)) {
			const textBots = Object.entries(textBotSessions)
				.map<StoreSession>(([id, item]: [string, Record<string, any>]) => {
					if (!item || !('metadata' in item)) {
						return;
					}

					return {
						id,
						type: ConnectionType.TextBot,
						metadata: item.metadata,
						data: item,
					};
				})
				.filter(Boolean);

			walletConnects.push(...(textBots || []));
		}

		if (!isEmpty(tonConnections)) {
			const tonConnects = Object.values(tonConnections).map((c: any) => {
				return {
					id: c.clientSessionId,
					type: ConnectionType.TonConnect,
					metadata: {
						name: c.manifest.name,
						icons: c.manifest.iconUrl,
						url: c.manifest.url,
					},
					data: c,
				};
			});

			walletConnects.push(...(tonConnects || []));
		}

		return walletConnects;
	}, [textBotSessions, walletConnectSessions, tonConnections]);

	useEffect(() => {
		const activeTimes = paringTrackingManager.getParingActiveTimes();
		if (!isEmpty(activeTimes)) {
			Object.keys(activeTimes[ConnectionType.WalletConnect] || {})?.forEach((key) => {
				const isExited = walletConnectSessions.find((s) => s.pairingTopic === key);
				if (!isExited) {
					paringTrackingManager.removeParingTopic(ConnectionType.WalletConnect, key);
				}
			});
		}

		if (!isEmpty(textBotSessions)) {
			Object.keys(activeTimes[ConnectionType.TextBot] || {})?.forEach((key) => {
				const isExited = textBotSessions[key];
				if (!isExited) {
					paringTrackingManager.removeParingTopic(ConnectionType.TextBot, key);
				}
			});
		}
	}, [textBotSessions, walletConnectSessions]);

	return {
		sessions,
		sessionCount: sessions.length || 0,
		disconnect,
	};
};
