import { TelegramCore } from '@/app-cores/telegram';
import { CreateRequestPayload, RequestPayment, UpdateRequestPayload, UserPaymentAmount } from './types';
import { axiosBotAPI } from '@/app-cores/api/axios';

export const ServiceRequestPayment = {
	/**
	 * For creator
	 * Payment requests from current user
	 * @param args
	 * @returns
	 */
	getMyRequests: async (): Promise<{ data: RequestPayment[]; totalToPaid: number }> => {
		const response = await axiosBotAPI.get('wallet/request-payments', {
			params: {},
		});
		const list = response.data?.data ?? [];
		const totalToPaid = list.filter((item) => {
			return item.payers.some((e) => !e.isPaid && e.telegramId === TelegramCore.getUserId().toString());
		}).length;
		return { data: list, totalToPaid };
	},

	/**
	 * For creator
	 * Get a payment request by id
	 * @param id
	 * @returns
	 */
	getRequestById: async (id: string): Promise<RequestPayment> => {
		const response = await axiosBotAPI.get(`wallet/request-payments/${id}`);
		return response.data?.data;
	},

	/**
	 * For creator
	 * Create a payment requests to specific users
	 * @param requestPayment
	 */
	createRequest: async (data: CreateRequestPayload): Promise<RequestPayment> => {
		const response = await axiosBotAPI.post('wallet/request-payments', data);
		return response.data?.data;
	},

	/**
	 * mark as paid/unpaid
	 * @param id
	 */
	updateRequest: async ({ id, ...rest }: UpdateRequestPayload): Promise<void> => {
		await axiosBotAPI.put<{ status: boolean }>(`wallet/request-payments/${id}/payers`, rest);
	},
};
