import React from 'react';

interface IProps extends React.SVGAttributes<HTMLOrSVGElement> {}

export const BnbIcon: React.FC<IProps> = ({ width = 56, height = 56, ...rest }) => {
	return (
		<svg width={width} height={height} viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
			<g clipPath="url(#clip0_8601_5480)">
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M28 0C43.4651 0 56 12.5349 56 28C56 43.4651 43.4651 56 28 56C12.5349 56 0 43.4651 0 28C0 12.5349 12.5349 0 28 0Z"
					fill="#F0B90B"
				/>
				<path
					d="M15.3888 27.9999L15.409 35.4038L21.7 39.1057V43.4403L11.7272 37.5913V25.8349L15.3888 27.9999ZM15.3888 20.5961V24.9105L11.725 22.7432V18.4288L15.3888 16.2615L19.0705 18.4288L15.3888 20.5961ZM24.3272 18.4288L27.991 16.2615L31.6727 18.4288L27.991 20.5961L24.3272 18.4288Z"
					fill="white"
				/>
				<path
					d="M18.0363 33.8692V29.5346L21.7 31.7019V36.0163L18.0363 33.8692ZM24.3273 40.6583L27.9911 42.8256L31.6728 40.6583V44.9727L27.9911 47.14L24.3273 44.9727V40.6583ZM36.9273 18.4288L40.5911 16.2615L44.2728 18.4288V22.7432L40.5911 24.9105V20.5961L36.9273 18.4288ZM40.5911 35.4038L40.6113 27.9999L44.275 25.8326V37.589L34.3023 43.4381V39.1035L40.5911 35.4038Z"
					fill="white"
				/>
				<path d="M37.9638 33.8693L34.3 36.0164V31.702L37.9638 29.5347V33.8693Z" fill="white" />
				<path
					d="M37.9638 22.1307L37.984 26.4653L31.675 30.1672V37.589L28.0113 39.7361L24.3475 37.589V30.1672L18.0385 26.4653V22.1307L21.718 19.9634L27.9888 23.6833L34.2978 19.9634L37.9795 22.1307H37.9638ZM18.0363 14.7291L27.9911 8.85986L37.9638 14.7291L34.3 16.8964L27.9911 13.1765L21.7 16.8964L18.0363 14.7291Z"
					fill="white"
				/>
			</g>
			<defs>
				<clipPath id="clip0_8601_5480">
					<rect width="56" height="56" fill="white" />
				</clipPath>
			</defs>
		</svg>
	);
};
