import React from 'react';

interface IProps extends React.SVGAttributes<HTMLOrSVGElement> {}

export const FiMailIcon: React.FC<IProps> = ({ width = 20, height = 20, ...rest }) => {
	return (
		<svg width={width} height={height} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M3.33325 4.16667C2.87682 4.16667 2.49992 4.54357 2.49992 5V15C2.49992 15.4564 2.87682 15.8333 3.33325 15.8333H16.6666C17.123 15.8333 17.4999 15.4564 17.4999 15V5C17.4999 4.54357 17.123 4.16667 16.6666 4.16667H3.33325ZM0.833252 5C0.833252 3.6231 1.95635 2.5 3.33325 2.5H16.6666C18.0435 2.5 19.1666 3.6231 19.1666 5V15C19.1666 16.3769 18.0435 17.5 16.6666 17.5H3.33325C1.95635 17.5 0.833252 16.3769 0.833252 15V5Z"
				fill="#7A7A7A"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M0.983964 4.52202C1.24789 4.14498 1.7675 4.05329 2.14454 4.31722L9.99999 9.81603L17.8554 4.31722C18.2325 4.05329 18.7521 4.14498 19.016 4.52202C19.2799 4.89906 19.1882 5.41867 18.8112 5.6826L10.4779 11.5159C10.1909 11.7168 9.80904 11.7168 9.52211 11.5159L1.18877 5.6826C0.81173 5.41867 0.720035 4.89906 0.983964 4.52202Z"
				fill="#7A7A7A"
			/>
		</svg>
	);
};
