import { UseQueryOptions, keepPreviousData, useQuery } from '@tanstack/react-query';
import { InfuraServiceAPI } from '@/app-cores/api/infura';
import { EIP155GasPrice } from '@/app-cores/api/infura/type';
import { QUERY_KEYS } from '@/app-constants';
import { isEvmChain } from '@/app-helpers/token';
import isEmpty from 'lodash/isEmpty';
import { getSigner } from '@/app-helpers/web3';
import { queryClient } from '@/app-cores/query-client';
import { setDefaultGasFeeData } from '@/app-hooks/transactions';

export async function getGasInfo(chainId: string): Promise<{ feeData: EIP155GasPrice; gasPrice: bigint }> {
	if (!isEvmChain(chainId)) return;
	const cached = queryClient.getQueryCache().find({
		queryKey: [QUERY_KEYS.GET_INFURA_FEE_DATA, chainId],
	});

	const feeData = (cached?.state?.data || {}) as EIP155GasPrice;

	const { provider } = getSigner(chainId);
	const gas = await provider.getFeeData();
	if (isEmpty(feeData)) setDefaultGasFeeData(feeData, gas);
	return { feeData, gasPrice: gas.gasPrice };
}

export const useQueryGasPrice = (chainId: number, options?: UseQueryOptions<EIP155GasPrice>) => {
	const response = useQuery({
		queryKey: [QUERY_KEYS.GET_INFURA_FEE_DATA, chainId?.toString()],
		queryFn: async () => {
			return InfuraServiceAPI.getEIP155GasPrice(chainId);
		},
		placeholderData: keepPreviousData,
		staleTime: 15 * 1000,
		refetchInterval: 15 * 1000,
		enabled: !!chainId && isEvmChain(chainId),
		...options,
	});
	return response;
};
