import { useModalStore } from '../store/ModalStore';
import { SessionUnSupportedMethodModal } from './SessionUnSupportedMethodModal';
import AuthRequestModal from './AuthRequestModal';
import SessionApprovalModal from './SessionAprrovalModal';
import SessionSignMessageSolanaModal from './SessionSignMessageSolanaModal';
import SessionSignTransactionSolanaModal from './SessionSignTransactionSolanaModal';
import SessionProposalModal from './SessionProposalModal';
import SessionSendTransactionModal from './SessionSendTransactionModal';
import SessionSignModal from './SessionSignModal';
import SessionSignTypedDataModal from './SessionSignTypedDataModal';
import SessionSignMessageTronModal from './SessionSignMessageTronModal';
import SessionSignTransactionTronModal from './SessionSignTransactionTronModal';

export const WalletConnectRequestModal = () => {
	const { open, view } = useModalStore();
	if (!open) return null;
	switch (view) {
		case 'AuthRequestModal':
			return <AuthRequestModal />;
		case 'SessionProposalModal':
			return <SessionProposalModal />;
		case 'SessionSendTransactionModal':
			return <SessionSendTransactionModal />;
		case 'SessionSignModal':
			return <SessionSignModal />;
		case 'SessionSignTypedDataModal':
			return <SessionSignTypedDataModal />;
		case 'SessionUnSupportedMethodModal':
			return <SessionUnSupportedMethodModal />;
		case 'SessionApprovalModal':
			return <SessionApprovalModal />;
		case 'SessionSignMessageSolanaModal':
			return <SessionSignMessageSolanaModal />;
		case 'SessionSignTransactionSolanaModal':
			return <SessionSignTransactionSolanaModal />;
		case 'SessionSignMessageTronModal':
			return <SessionSignMessageTronModal />;
		case 'SessionSignTransactionTronModal':
			return <SessionSignTransactionTronModal />;
		default:
			break;
	}
};
