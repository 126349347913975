import { isEmpty } from 'lodash-es';
import { useMemo } from 'react';
import { UseQueryOptions, useQuery, keepPreviousData } from '@tanstack/react-query';
import { ONE_MINUTE } from './constant';
import { ChainId, NATIVE_TOKEN_ADDRESS, ZERO_ADDRESS } from '@/app-constants/chains';
import { compareAddress, isNativeToken } from '@/app-helpers/address';
import { getNativeTobiId, getTokenInfo, getWrapNativeToken } from '@/app-helpers/token';
import { ServiceTokenPriceAPI } from '@/app-cores/api/token-price';
import { ChartService, TokenPriceChartParams, ChartData } from '@/app-cores/api/bff/chart';
import { useSearchSingleToken } from '@/app-hooks/api/portfolio/useSearchToken';

export const useTokenPriceChart = (payload: TokenPriceChartParams, options?: UseQueryOptions<any>) => {
	const { interval, from, to, tobiId } = payload;
	const response = useQuery<ChartData>({
		queryKey: ['token-price-chart', interval, tobiId, from, to],
		queryFn: () => ChartService.fetchPriceChart(payload),
		enabled: !!tobiId && !!interval,
		staleTime: ONE_MINUTE * 5,
		...options,
	});
	return response;
};

const formatChainPrice = (prices: any, chainId: string) => {
	if (!prices?.length) return {};
	return prices.reduce(
		(acc, { address, marketPrice, price }: { address: string; marketPrice: number; price: number }) => {
			if (acc[address]) return acc; // dont override
			const priceValue = marketPrice || price || 0;
			acc[address.toLowerCase()] = priceValue;
			acc[address] = priceValue;
			if (compareAddress(address, getWrapNativeToken(chainId).address)) {
				acc[NATIVE_TOKEN_ADDRESS.toLowerCase()] = priceValue;
				acc[ZERO_ADDRESS] = priceValue;
				acc[NATIVE_TOKEN_ADDRESS] = priceValue;
			}
			return acc;
		},
		{},
	);
};

export type TokenPriceData = Record<string, Record<string, number>>;

const getPriceParams = (tokens: Array<{ chainId: string | number; address: string }>) => {
	if (isEmpty(tokens)) return {};
	return tokens.reduce((rs, token) => {
		if (!token) return rs;
		rs[token.chainId] ||= [];
		rs[token.chainId].push(token);
		if (!isNativeToken(token.address)) return rs;

		//With native token have to mapping with wrap token to get price
		const mapNativeTokenWithWrapNativeToken = () => {
			const wrapToken = getWrapNativeToken(token?.chainId);
			switch (token.chainId) {
				// With Sol token have to mapping to Sol wrap to get price
				case ChainId.SOL:
				case ChainId.BERACHAIN_TESTNET: {
					rs[token.chainId] ||= [];
					rs[token.chainId].push({
						address: wrapToken?.address,
						chainId: token.chainId,
					});
					break;
				}
				default:
					rs[token.chainId].push({
						...token,
						address: wrapToken?.address.toLowerCase(),
					});
			}
			return rs;
		};
		return mapNativeTokenWithWrapNativeToken();
	}, {});
};

const formatResultMultiChains = (responses: PromiseSettledResult<any[]>[], chainIds: string[]) => {
	const data = responses.map((e) => (e.status === 'fulfilled' ? e.value : {}));
	const groupTokenPriceByChainId = chainIds.reduce((rs, chainId, i) => {
		rs[chainId] = formatChainPrice(data[i], chainId);
		return rs;
	}, {});
	return groupTokenPriceByChainId;
};

export const fetchPricesMultiChainTokens = async (
	tokens: Array<{ chainId: string | number | ChainId; address: string }>,
): Promise<TokenPriceData> => {
	const groupTokenByChainId = getPriceParams(tokens);
	const chainIds = Object.keys(groupTokenByChainId);
	const promises = chainIds.map((chainId) =>
		ServiceTokenPriceAPI.getTokenPrices(groupTokenByChainId[chainId], chainId as ChainId),
	);
	const responses = await Promise.allSettled(promises);
	return formatResultMultiChains(responses, chainIds);
};
/**
 * @deprecated
 */
const useTokenPrices = (tokens: Array<{ chainId: string | number | ChainId; address: string }>) => {
	const queryKey = useMemo(() => {
		const keys = tokens
			.filter((e) => !!e?.address && e?.address !== 'undefined')
			.map((e) => `${e.chainId}-${e.address}`);
		return [...new Set(keys)].join(',');
	}, [tokens]);

	const response = useQuery({
		queryKey: ['token-prices', queryKey],
		queryFn: async (): Promise<TokenPriceData> => fetchPricesMultiChainTokens(tokens),
		enabled: !!tokens.length,
		gcTime: 30_000,
		placeholderData: keepPreviousData,
		staleTime: Infinity,
	});
	return response;
};

/**
 *
 * @deprecated
 */
export const useTokenPriceSingleToken = ({ chainId, address }: { chainId?: string | number; address?: string }) => {
	const params = useMemo(
		() => [{ chainId, address }].filter((item) => !!item.chainId && !!item.address),
		[chainId, address],
	);

	const response = useTokenPrices(params);
	return { ...response, data: response.data?.[chainId]?.[address.toLowerCase()] };
};

export const usePriceNativeToken = ({ chainId }: { chainId?: string | number }) => {
	const { data, ...response } = useSearchSingleToken(
		{ tobiId: getNativeTobiId(chainId), chainId },
		{ enabled: !!chainId },
	);
	const { priceUsd } = getTokenInfo(data);
	return { ...response, data: priceUsd };
};
