import { keepPreviousData, useQuery, UseQueryOptions } from '@tanstack/react-query';
import { CopilotServiceAPI } from '@/app-cores/api/app-copilot';
import { Message } from '@/app-cores/api/app-copilot/type';

export const CONVERSATION_PAGE_SIZE = 10;
export const useQueryBotConversations = (
	page: number,
	options?: Omit<UseQueryOptions<Message[]>, 'queryKey' | 'queryFn'>,
) => {
	const response = useQuery({
		queryKey: ['query-telegram-messages', page],
		queryFn: async () => {
			return await CopilotServiceAPI.getConversations({
				limit: CONVERSATION_PAGE_SIZE,
				skip: CONVERSATION_PAGE_SIZE * (page - 1),
			});
		},
		placeholderData: keepPreviousData,
		...options,
	});

	return response;
};
