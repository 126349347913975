import { useQuery } from '@tanstack/react-query';
import { ContactManagementAPI } from '@/app-cores/api/contact';

export const useQueryContactById = (contactId: string) => {
	const response = useQuery({
		queryKey: ['query-contact-detail', contactId],
		queryFn: async () => {
			return await ContactManagementAPI.getContactById(contactId);
		},
		staleTime: 10 * 60 * 1000,
		enabled: !!contactId, //cache 10mins
	});

	return response;
};
