import { TransactionType } from '@/app-types';
import { TransactionResponse } from 'ethers';
import { create } from 'zustand';
import { createJSONStorage, persist } from 'zustand/middleware';
import { ONE_DAY } from '../../app-hooks/api/portfolio/constant';
import { IActivity, TxStatus } from '@/app-cores/api/activities';
import { ChainType } from '@/app-contexts/wallet-provider/type';
import { STORAGE_KEYS } from '@/app-constants/storage';
import { getPendingContractInteractionTxs } from '@/app-store/transaction-watcher/evmWatcher';
import { ChainId } from '@/app-constants/chains';

export type TransactionCallback = (receipt: { status: TxStatus; msg?: string }) => void;

export type MetadataEvm = TransactionResponse & {
	transactionType?: TransactionType;
	time?: number;
};

export type TransactionWatcher<T extends MetadataEvm | IActivity> = {
	callbackFns: TransactionCallback[];
	metadata: T;
};

type PendingPayload = {
	hash: string | number;
	callback?: TransactionCallback;
	trackingData?: any;
};

export type MetadataSolTcat = {
	is2LegRoute?: boolean;
	desHash?: string;
	amountOut?: number;
	hash?: string;
	providerId?: string;
	rawProvider?: string;
	status?: string;
	time?: number;

	// token info
	tobiIdOut?: string;
	symbolOut?: string;
};

export interface ITransactionWatcherStore {
	metadata: Record<string, MetadataSolTcat>; // hash => metadata (only for sol to tcat)
	saveMetadata: (hash: string, metadata: MetadataSolTcat) => void;

	pendingEvmTransactions: Record<string, TransactionWatcher<MetadataEvm>>;
	addPendingEvmTransaction: (data: {
		transaction: TransactionResponse;
		callback?: TransactionCallback;
		metadata: Partial<MetadataEvm>;
		trackingData?: any;
	}) => void;

	pendingTonTransactions: Record<string /** seqno */, TransactionWatcher<IActivity>>;
	addPendingTonTransaction: (data: PendingPayload) => void;

	pendingSolTransactions: Record<string, TransactionWatcher<IActivity>>;
	addPendingSolTransaction: (data: PendingPayload) => void;

	pendingTronTransactions: Record<string, TransactionWatcher<IActivity>>;
	addPendingTronTransaction: (data: PendingPayload) => void;

	pendingSuiTransactions: Record<string, TransactionWatcher<IActivity>>;
	addPendingSuiTransaction: (data: PendingPayload) => void;

	removePendingTransaction: (data: { hash: string | number; type: ChainType }) => void;
}

const convertBigIntField = <T>(obj: T, level = 1): T => {
	if (obj === null || typeof obj !== 'object' || level >= 2) return obj;

	return Object.keys(obj || {}).reduce((rs, key) => {
		const value = obj[key];

		if (typeof value === 'bigint') {
			rs[key] = value.toString();
		} else if (typeof value === 'object' && value !== null) {
			rs[key] = Array.isArray(value)
				? value.map((item) => convertBigIntField(item, level + 1))
				: convertBigIntField(value, level + 1);
		} else {
			rs[key] = value;
		}

		return rs;
	}, {} as T);
};

const pushPendingTransaction = (
	{ hash, callback }: PendingPayload,
	transactions: Record<string, TransactionWatcher<IActivity>>,
	chainId: ChainId,
) => {
	const existInfo = transactions[hash];
	const metadata = existInfo?.metadata || getPendingContractInteractionTxs({ hash: hash?.toString(), chainId });
	transactions[hash] = {
		metadata: {
			hash,
			...existInfo?.metadata,
			...convertBigIntField(metadata),
			time: existInfo?.metadata?.time || (Date.now() / 1000) | 0,
		},
		callbackFns: [].concat(existInfo?.callbackFns || []).concat(callback || []),
	};
	return transactions;
};

export const useTransactionWatcherStore = create<ITransactionWatcherStore>()(
	persist(
		(set) => ({
			metadata: {},
			saveMetadata(hash, metadata) {
				set((state) => {
					return { metadata: { ...state.metadata, [hash]: { ...metadata, hash, time: Date.now() } } };
				});
			},
			pendingEvmTransactions: {},
			pendingTonTransactions: {},
			pendingSolTransactions: {},
			pendingTronTransactions: {},
			pendingSuiTransactions: {},
			addPendingEvmTransaction: ({
				transaction: txResponse,
				callback,
				metadata: { transactionType, ...extra } = {} as MetadataEvm,
			}) => {
				return set((state) => {
					if (!txResponse) {
						return {};
					}

					const { hash, nonce } = txResponse;
					const exitsInfo = state.pendingEvmTransactions[hash];

					const transactions = { ...state.pendingEvmTransactions };
					transactions[hash] = {
						metadata: convertBigIntField<MetadataEvm>({
							...exitsInfo?.metadata,
							...(txResponse?.toJSON?.() || txResponse),
							...extra,
							time: exitsInfo?.metadata.time || (Date.now() / 1000) | 0,
							transactionType: transactionType || exitsInfo?.metadata?.transactionType,
							nonce: nonce || exitsInfo?.metadata.nonce,
						}),
						callbackFns: [].concat(exitsInfo?.callbackFns || []).concat(callback || []),
					};

					return { pendingEvmTransactions: transactions };
				});
			},

			addPendingTonTransaction: (pendingPayload) =>
				set((state) => {
					const pendingTonTransactions = pushPendingTransaction(
						pendingPayload,
						{
							...state.pendingTonTransactions,
						},
						ChainId.TON,
					);
					return { pendingTonTransactions };
				}),
			addPendingSolTransaction: (pendingPayload) =>
				set((state) => {
					const pendingSolTransactions = pushPendingTransaction(
						pendingPayload,
						{
							...state.pendingSolTransactions,
						},
						ChainId.SOL,
					);
					return { pendingSolTransactions };
				}),
			addPendingTronTransaction: (pendingPayload) =>
				set((state) => {
					const pendingTronTransactions = pushPendingTransaction(
						pendingPayload,
						{
							...state.pendingTronTransactions,
						},
						ChainId.TRON,
					);
					return { pendingTronTransactions };
				}),
			addPendingSuiTransaction: (pendingPayload) =>
				set((state) => {
					const pendingSuiTransactions = pushPendingTransaction(
						pendingPayload,
						{
							...state.pendingSuiTransactions,
						},
						ChainId.SUI,
					);
					return { pendingSuiTransactions };
				}),
			removePendingTransaction: ({ hash: txHash, type }) =>
				set((state) => {
					switch (type) {
						case ChainType.SOLANA: {
							const { [txHash]: pendingTx, ...pendingSolTransactions } = state.pendingSolTransactions;
							return { pendingSolTransactions };
						}
						case ChainType.EVM: {
							const { [txHash]: pendingTx, ...pendingEvmTransactions } = state.pendingEvmTransactions;
							return { pendingEvmTransactions };
						}
						case ChainType.TRON: {
							const { [txHash]: pendingTx, ...pendingTronTransactions } = state.pendingTronTransactions;
							return { pendingTronTransactions };
						}
						case ChainType.SUI: {
							const { [txHash]: pendingTx, ...pendingSuiTransactions } = state.pendingSuiTransactions;
							return { pendingSuiTransactions };
						}
						case ChainType.TON: {
							const pendingTonTransactions = Object.fromEntries(
								Object.entries(state.pendingTonTransactions).filter((item) => item[0] > txHash),
							);
							return { pendingTonTransactions };
						}
						default:
							return state;
					}
				}),
		}),

		{
			name: STORAGE_KEYS.TOBI_TRANSACTION_WATCHER_STORAGE,
			storage: createJSONStorage(() => localStorage),
			skipHydration: true,
		},
	),
);

export const usePendingEvmTransaction = (hash) =>
	useTransactionWatcherStore((state) => state.pendingEvmTransactions[hash]?.metadata);
