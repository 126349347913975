import { useEffect, useRef, useState } from 'react';
import { Box } from '@chakra-ui/react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { isMobile } from 'react-device-detect';
import PageNavigation from '@/app-components/layout/PageWrapper/PageWrapper';
import { Loading } from '@/app-components/common';
import PageTransition from '@/app-components/layout/PageTransition/PageTransition';
import { NAVIGATE_PATHS } from '@/app-constants/router';
import { createWeb3Wallet, web3wallet } from '../utils/WalletConnectUtil';
import { TelegramCore } from '@/app-cores/telegram';
import { WALLET_CONNECT_TG_CLOUD_KEY } from '../data/tgCloud';
import { getHostName, paringTopicManager } from '../utils/HelperUtil';
import SettingsStore from '../store/SettingsStore';

const INTERVAL_CHECK_CONNECTION_TIME = 5000;

export const TobiDapp = () => {
	const [searchParams] = useSearchParams();
	const [loading, setLoading] = useState(true);
	const navigate = useNavigate();
	const dappUrl = searchParams.get('dappUrl');
	const parentUrl = searchParams.get('parentUrl');
	const hostname = getHostName(dappUrl);
	const iframeRef = useRef<HTMLIFrameElement>(null);

	useEffect(() => {
		if (!isMobile) return;
		// Mask as connected in Dapp Browser
		localStorage.setItem(
			WALLET_CONNECT_TG_CLOUD_KEY.CONNECT_IN_DAPP_BROWSER.KEY,
			WALLET_CONNECT_TG_CLOUD_KEY.CONNECT_IN_DAPP_BROWSER.VALUE,
		);
		return () => localStorage.removeItem(WALLET_CONNECT_TG_CLOUD_KEY.CONNECT_IN_DAPP_BROWSER.KEY);
	}, []);

	useEffect(() => {
		if (!iframeRef.current) return;
		const handleLoader = () => {
			setLoading(false);
		};
		iframeRef.current?.addEventListener('load', handleLoader);
		return () => {
			iframeRef.current?.removeEventListener('load', handleLoader);
		};
	}, []);

	useEffect(() => {
		SettingsStore.setIsInternal(true);
		return () => SettingsStore.setIsInternal(false);
	}, []);

	useEffect(() => {
		paringTopicManager.setHostName(hostname);
	}, [hostname]);

	useEffect(() => {
		if (!isMobile) return;
		let timer;
		async function triggerInitWallet() {
			timer = setInterval(() => {
				const isReInitWeb3 = localStorage.getItem(WALLET_CONNECT_TG_CLOUD_KEY.RE_INIT_EVENT.KEY);
				if (isReInitWeb3 === WALLET_CONNECT_TG_CLOUD_KEY.RE_INIT_EVENT.VALUE) {
					createWeb3Wallet('').then(() => {
						console.log('Wallet reinitialized');
						SettingsStore.setReinitialized(new Date().toISOString());
						SettingsStore.setSessions(Object.values(web3wallet.getActiveSessions()));
					});
					clearInterval(timer);
					localStorage.removeItem(WALLET_CONNECT_TG_CLOUD_KEY.RE_INIT_EVENT.KEY);
				}
			}, INTERVAL_CHECK_CONNECTION_TIME);
		}
		triggerInitWallet();
		return () => clearInterval(timer);
	}, []);

	return (
		<PageTransition
			style={{
				flexDirection: 'column',
				flexGrow: 1,
				overflow: 'hidden',
			}}
		>
			<PageNavigation
				onBack={() => {
					if (parentUrl !== '/') {
						history.replaceState(null, null, '/');
						return navigate(parentUrl);
					}
					navigate('/');
				}}
			>
				<Box overflow="auto" height="100%" position="relative">
					<iframe
						ref={iframeRef}
						seamless
						src={dappUrl}
						style={{
							height: '100%',
							width: '100%',
						}}
						title={hostname}
						sandbox="allow-scripts allow-same-origin allow-popups allow-forms allow-modals allow-storage-access-by-user-activation"
						allowFullScreen
						loading="lazy"
						allow="camera; microphone; geolocation;"
					></iframe>
					{loading && <Loading opacity={0.6} />}
				</Box>
			</PageNavigation>
		</PageTransition>
	);
};
