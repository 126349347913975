import { EIP155_CHAINS } from '@/app-constants/chains';
import { MpcWallet, MpcWalletProvider } from '@/app-cores/mpc-wallet/wallet';
import { showTransactionError } from '@/app-helpers/error-handling';
import { formatJsonRpcError, formatJsonRpcResult } from '@json-rpc-tools/utils';
import { SignClientTypes } from '@walletconnect/types';
import { getSdkError } from '@walletconnect/utils';
import { omit } from 'lodash-es';
import { EIP155_SIGNING_METHODS, TEIP155Chain } from '../data/EIP155Data';
import SettingsStore from '../store/SettingsStore';
import { getSignParamsMessage, getSignTypedDataParamsData } from '../utils/HelperUtil';
import { useTransactionWatcherStore } from '@/app-store';
import { TransactionType } from '@/app-types';

type RequestEventArgs = Omit<SignClientTypes.EventArguments['session_request'], 'verifyContext'>;

export async function approveEIP155Request(requestEvent: RequestEventArgs) {
	const { params, id } = requestEvent;
	console.log('approveEIP155Request params:', params);
	SettingsStore.setActiveChainId(params.chainId);

	try {
		switch (params.request.method) {
			case EIP155_SIGNING_METHODS.PERSONAL_SIGN:
			case EIP155_SIGNING_METHODS.ETH_SIGN: {
				const message = getSignParamsMessage(params.request.params);
				const signedMessage = await MpcWallet.signMessage(message);
				return formatJsonRpcResult(id, signedMessage);
			}

			case EIP155_SIGNING_METHODS.ETH_SIGN_TYPED_DATA:
			case EIP155_SIGNING_METHODS.ETH_SIGN_TYPED_DATA_V3:
			case EIP155_SIGNING_METHODS.ETH_SIGN_TYPED_DATA_V4: {
				const { domain, types, message: data } = getSignTypedDataParamsData(params.request.params);
				const signedData = await MpcWallet.signTypedData(domain, omit(types, 'EIP712Domain'), data);
				return formatJsonRpcResult(id, signedData);
			}

			case EIP155_SIGNING_METHODS.ETH_SEND_TRANSACTION: {
				const sendTransaction = params.request.params[0];
				const provider = new MpcWalletProvider(EIP155_CHAINS[params.chainId as TEIP155Chain].rpc);
				const signer = provider.getSigner();
				const receipt = await signer.sendTransaction(sendTransaction);
				const hash = typeof receipt === 'string' ? receipt : receipt?.hash;
				useTransactionWatcherStore.getState().addPendingEvmTransaction({
					transaction: receipt,
					metadata: { transactionType: TransactionType.ContractInteraction },
				});

				return formatJsonRpcResult(id, hash);
			}

			case EIP155_SIGNING_METHODS.ETH_SIGN_TRANSACTION:
				return formatJsonRpcResult(id, '0x');

			default:
				throw new Error(getSdkError('INVALID_METHOD').message);
		}
	} catch (error) {
		console.error('Wallet connect transaction error', error);
		showTransactionError(error);
		return formatJsonRpcError(id, error.message);
	}
}

export function rejectEIP155Request(request: RequestEventArgs) {
	const { id } = request;
	return formatJsonRpcError(id, getSdkError('USER_REJECTED').message);
}
