import { Toast } from '@/app-components/common';
import { QUERY_KEYS } from '@/app-constants';
import { BffServiceAPI } from '@/app-cores/api/bff';
import { ServiceNotification } from '@/app-cores/api/bot/service-notification';
import { delay } from '@/app-helpers';
import { getShortAddress } from '@/app-helpers/address';
import { formatCurrency } from '@/app-helpers/number';
import { getTokenInfo } from '@/app-helpers/token';
import { useMutation, useQuery } from '@tanstack/react-query';
import { formatUnits } from 'ethers';
import { useEffect } from 'react';
import { toast } from 'react-toastify';

export const useGetNotifications = () => {
	return useQuery({
		queryKey: [QUERY_KEYS.GET_NOTIFICATIONS],
		queryFn: () => ServiceNotification.getNotifications(),
		refetchInterval: 10000,
	});
};

export const useReadNotification = () => {
	return useMutation({
		mutationKey: ['mutation-reject-transaction'],
		mutationFn: async (ids: string[]) => {
			return ServiceNotification.readNotification({ ids });
		},
	});
};

export const useShowNotification = () => {
	const { data } = useGetNotifications();
	const { mutateAsync } = useReadNotification();
	useEffect(() => {
		if (!data?.length) return;
		const toastIds = data.map((e) => e.id);
		data.forEach(async (e, i) => {
			if (e.userRead) return;
			try {
				const { metadata } = e;
				const activity = metadata?.activity;
				if (!activity) return;
				let msg;
				//  todo fetch by list token if needed
				switch (activity?.transactionType) {
					case 'receive': {
						const { receiveDetail } = activity;
						if (!receiveDetail) return;
						const { sender, amount, idTobi } = receiveDetail;
						const token = await BffServiceAPI.searchExactSingleToken({ tobiId: idTobi });
						if (!token) return;
						const { symbol, decimals } = getTokenInfo(token);
						msg = `You just received ${formatCurrency(
							formatUnits(amount.toString(), decimals),
						)} ${symbol} from ${sender?.username ? sender?.username : getShortAddress(sender?.address)}`;
						break;
					}
					default:
						break;
				}
				if (msg) {
					toast(<Toast type="success" message={msg} />, { autoClose: 5000 });
				}
			} catch (error) {
				console.log('parse noti error', error, e);
			}
		});
		if (toastIds?.length) mutateAsync(toastIds);
	}, [data, mutateAsync]);
};
