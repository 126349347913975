import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import PageTransition from '@/app-components/layout/PageTransition/PageTransition';
import { toSearchQuery } from '@/app-helpers/url';
import { NAVIGATE_PATHS } from '@/app-constants/router';
import { Header, Loading } from '@/app-components/common';
import { useQueryPaymentMethods } from '@/app-hooks/api/on-ramp';
import { useWallet } from '@/app-hooks/wallet';
import { Box, Container, Flex, Text } from '@chakra-ui/react';
import { useSearchParams } from 'react-router-dom';
import PageNavigation from '@/app-components/layout/PageWrapper/PageWrapper';

export default function DepositMethod() {
	const [searchParams] = useSearchParams();
	const navigate = useNavigate();
	const { t } = useTranslation();
	const { address } = useWallet();
	const { data: paymentMethods, isLoading } = useQueryPaymentMethods();
	const chainId = searchParams.get('chainId');
	const tokenSymbol = searchParams.get('tokenSymbol');
	const callbackUrl = searchParams.get('callbackUrl');

	const handleSelectDepositMethod = (paymentMethod: string) => {
		// navigate(`/deposit/amount/?${toSearchQuery({ paymentMethod })}`);
		navigate(
			`${NAVIGATE_PATHS.Deposit.Transak}/?${toSearchQuery({
				paymentMethod,
				address,
				chainId,
				tokenSymbol,
				callbackUrl,
			})}`,
		);
	};
	return (
		<PageTransition>
			<PageNavigation>
				<Container py={4} px={8} height="100vh" backgroundColor="#F9F9F9">
					{isLoading && <Loading />}
					<Header>{t('deposit.title')}</Header>
					<Box mt={5}>
						{paymentMethods?.map((method, i) => (
							<Flex
								cursor="pointer"
								gap={5}
								py={4}
								px={6}
								height={'64px'}
								borderRadius={8}
								backgroundColor="white"
								key={method.id}
								onClick={() => handleSelectDepositMethod(method.id)}
								mb={3}
							>
								<img
									width={40}
									height={40}
									alt={method.name}
									src={method.icon}
									className="object-fit-scale"
								/>
								<Text fontWeight={500}>{t(method.name)}</Text>
							</Flex>
						))}
					</Box>
				</Container>
			</PageNavigation>
		</PageTransition>
	);
}
