import React from 'react';

interface IProps extends React.SVGAttributes<HTMLOrSVGElement> {}

export const FiScanIcon: React.FC<IProps> = ({ width = 20, height = 20, ...rest }) => {
	return (
		<svg width={width} height={height} {...rest} viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M0 2.72748C0 1.658 0.866983 0.791016 1.93646 0.791016H4.16667C4.6269 0.791016 5 1.16411 5 1.62435C5 2.08459 4.6269 2.45768 4.16667 2.45768H2.38878C1.98997 2.45768 1.66667 2.78098 1.66667 3.1798V4.95768C1.66667 5.41792 1.29357 5.79102 0.833333 5.79102C0.373096 5.79102 0 5.41792 0 4.95768V2.72748Z"
				fill="black"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M20 2.72748C20 1.658 19.133 0.791016 18.0635 0.791016H15.8333C15.3731 0.791016 15 1.16411 15 1.62435C15 2.08459 15.3731 2.45768 15.8333 2.45768H17.6112C18.01 2.45768 18.3333 2.78098 18.3333 3.1798V4.95768C18.3333 5.41792 18.7064 5.79102 19.1667 5.79102C19.6269 5.79102 20 5.41792 20 4.95768V2.72748Z"
				fill="black"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M0 18.8546C0 19.924 0.866983 20.791 1.93646 20.791H4.16667C4.6269 20.791 5 20.4179 5 19.9577C5 19.4974 4.6269 19.1243 4.16667 19.1243H2.38878C1.98997 19.1243 1.66667 18.801 1.66667 18.4022V16.6243C1.66667 16.1641 1.29357 15.791 0.833333 15.791C0.373096 15.791 0 16.1641 0 16.6243V18.8546Z"
				fill="black"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M20 18.8546C20 19.924 19.133 20.791 18.0635 20.791H15.8333C15.3731 20.791 15 20.4179 15 19.9577C15 19.4974 15.3731 19.1243 15.8333 19.1243H17.6112C18.01 19.1243 18.3333 18.801 18.3333 18.4022V16.6243C18.3333 16.1641 18.7064 15.791 19.1667 15.791C19.6269 15.791 20 16.1641 20 16.6243V18.8546Z"
				fill="black"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M15.0002 6.79118C15.0002 6.23889 14.5524 5.79118 14.0002 5.79118H6.00016C5.44788 5.79118 5.00016 6.23889 5.00016 6.79118V14.7912C5.00016 15.3435 5.44788 15.7912 6.00016 15.7912H14.0002C14.5524 15.7912 15.0002 15.3435 15.0002 14.7912V6.79118ZM5.55572 4.12451C4.32842 4.12451 3.3335 5.11943 3.3335 6.34673V15.2356C3.3335 16.4629 4.32842 17.4578 5.55572 17.4578H14.4446C15.6719 17.4578 16.6668 16.4629 16.6668 15.2356V6.34673C16.6668 5.11943 15.6719 4.12451 14.4446 4.12451H5.55572Z"
				fill="black"
			/>
			<path
				d="M19.2857 11.6242H0.714286C0.524845 11.6242 0.343164 11.5364 0.209209 11.3801C0.0752549 11.2238 0 11.0119 0 10.7909C0 10.5698 0.0752549 10.3579 0.209209 10.2016C0.343164 10.0453 0.524845 9.95752 0.714286 9.95752H19.2857C19.4752 9.95752 19.6568 10.0453 19.7908 10.2016C19.9247 10.3579 20 10.5698 20 10.7909C20 11.0119 19.9247 11.2238 19.7908 11.3801C19.6568 11.5364 19.4752 11.6242 19.2857 11.6242Z"
				fill="#00E9DB"
			/>
		</svg>
	);
};
