import React from 'react';

interface IProps extends React.SVGAttributes<HTMLOrSVGElement> {}

export const BaseIcon: React.FC<IProps> = ({ width = 56, height = 56, ...rest }) => {
	return (
		<svg width={width} height={height} viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
			<g clipPath="url(#clip0_8601_5512)">
				<mask
					id="mask0_8601_5512"
					// style="mask-type:luminance"
					maskUnits="userSpaceOnUse"
					x="0"
					y="0"
					width="56"
					height="56"
				>
					<path
						d="M28 56C12.535 56 0 43.465 0 28C0 12.535 12.535 0 28 0C43.465 0 56 12.535 56 28C56 43.465 43.465 56 28 56Z"
						fill="white"
					/>
				</mask>
				<g mask="url(#mask0_8601_5512)">
					<path
						fillRule="evenodd"
						clipRule="evenodd"
						d="M28 28.0001L-11.6032 27.7559L-11.5674 26.2999L28 28.0001Z"
						fill="#FEFEFF"
					/>
					<path
						fillRule="evenodd"
						clipRule="evenodd"
						d="M28 28.0001L-11.5741 26.5418L-11.5136 25.3278L28 28.0001Z"
						fill="#FDFEFF"
					/>
					<path
						fillRule="evenodd"
						clipRule="evenodd"
						d="M28 28.0001L-11.527 25.5719L-11.4374 24.3601L28 28.0001Z"
						fill="#FCFDFF"
					/>
					<path
						fillRule="evenodd"
						clipRule="evenodd"
						d="M28 28L-11.4554 24.6019L-11.3367 23.3923L28 28Z"
						fill="#FBFCFF"
					/>
					<path
						fillRule="evenodd"
						clipRule="evenodd"
						d="M28 28.0001L-11.3591 23.6343L-11.2112 22.4292L28 28.0001Z"
						fill="#FAFCFF"
					/>
					<path
						fillRule="evenodd"
						clipRule="evenodd"
						d="M28 28.0001L-11.2404 22.6711L-11.0634 21.4683L28 28.0001Z"
						fill="#F9FBFF"
					/>
					<path
						fillRule="evenodd"
						clipRule="evenodd"
						d="M28 28L-11.097 21.7079L-10.8909 20.5117L28 28Z"
						fill="#F8FAFF"
					/>
					<path
						fillRule="evenodd"
						clipRule="evenodd"
						d="M28 28L-10.9312 20.7514L-10.696 19.5597L28 28Z"
						fill="#F7FAFF"
					/>
					<path
						fillRule="evenodd"
						clipRule="evenodd"
						d="M28 28L-10.743 19.7994L-10.4765 18.6122L28 28Z"
						fill="#F6F9FF"
					/>
					<path
						fillRule="evenodd"
						clipRule="evenodd"
						d="M28 28L-10.5303 18.8496L-10.2346 17.6714L28 28Z"
						fill="#F5F8FF"
					/>
					<path
						fillRule="evenodd"
						clipRule="evenodd"
						d="M28 28.0001L-10.2928 17.9066L-9.97023 16.7351L28 28.0001Z"
						fill="#F4F8FF"
					/>
					<path
						fillRule="evenodd"
						clipRule="evenodd"
						d="M28 28.0001L-10.0352 16.9703L-9.6813 15.8077L28 28.0001Z"
						fill="#F3F7FF"
					/>
					<path
						fillRule="evenodd"
						clipRule="evenodd"
						d="M28 28L-9.75299 16.0407L-9.36995 14.8871L28 28Z"
						fill="#F2F6FF"
					/>
					<path
						fillRule="evenodd"
						clipRule="evenodd"
						d="M28 28L-9.4483 15.1178L-9.03838 13.9731L28 28Z"
						fill="#F1F6FF"
					/>
					<path
						fillRule="evenodd"
						clipRule="evenodd"
						d="M28 28.0001L-9.11908 14.2017L-8.68228 13.0682L28 28.0001Z"
						fill="#F0F5FF"
					/>
					<path
						fillRule="evenodd"
						clipRule="evenodd"
						d="M28 28.0001L-8.76959 13.2945L-8.30591 12.1722L28 28.0001Z"
						fill="#EFF4FF"
					/>
					<path
						fillRule="evenodd"
						clipRule="evenodd"
						d="M28 28L-8.39777 12.3962L-7.90497 11.2874L28 28Z"
						fill="#EEF3FF"
					/>
					<path
						fillRule="evenodd"
						clipRule="evenodd"
						d="M28 28.0001L-8.00354 11.5092L-7.48386 10.4116L28 28.0001Z"
						fill="#EDF3FF"
					/>
					<path
						fillRule="evenodd"
						clipRule="evenodd"
						d="M28 28L-7.58911 10.6311L-7.04255 9.54468L28 28Z"
						fill="#ECF2FF"
					/>
					<path
						fillRule="evenodd"
						clipRule="evenodd"
						d="M28 28.0001L-7.15234 9.762L-6.5789 8.69128L28 28.0001Z"
						fill="#EBF1FF"
					/>
					<path
						fillRule="evenodd"
						clipRule="evenodd"
						d="M28 28.0001L-6.69312 8.90632L-6.09504 7.8468L28 28.0001Z"
						fill="#EAF1FF"
					/>
					<path
						fillRule="evenodd"
						clipRule="evenodd"
						d="M28 28L-6.21375 8.05954L-5.58879 7.01794L28 28Z"
						fill="#E9F0FF"
					/>
					<path
						fillRule="evenodd"
						clipRule="evenodd"
						d="M28 28L-5.71423 7.22628L-5.06463 6.19812L28 28Z"
						fill="#E8EFFF"
					/>
					<path
						fillRule="evenodd"
						clipRule="evenodd"
						d="M28 28L-5.19458 6.40416L-4.5181 5.39392L28 28Z"
						fill="#E7EFFF"
					/>
					<path
						fillRule="evenodd"
						clipRule="evenodd"
						d="M28 28.0001L-4.65472 5.59559L-3.95584 4.60327L28 28.0001Z"
						fill="#E6EEFF"
					/>
					<path
						fillRule="evenodd"
						clipRule="evenodd"
						d="M28 28L-4.09473 4.80257L-3.37121 3.82593L28 28Z"
						fill="#E5EDFF"
					/>
					<path
						fillRule="evenodd"
						clipRule="evenodd"
						d="M28 28.0001L-3.51678 4.02085L-2.76862 3.06213L28 28.0001Z"
						fill="#E4EDFF"
					/>
					<path
						fillRule="evenodd"
						clipRule="evenodd"
						d="M28 28L-2.9187 3.25472L-2.14814 2.31616L28 28Z"
						fill="#E3ECFF"
					/>
					<path
						fillRule="evenodd"
						clipRule="evenodd"
						d="M28 28.0001L-2.30048 2.50438L-1.50752 1.58374L28 28.0001Z"
						fill="#E2EBFF"
					/>
					<path
						fillRule="evenodd"
						clipRule="evenodd"
						d="M28 28.0001L-1.66656 1.76742L-0.851205 0.866943L28 28.0001Z"
						fill="#E1EBFF"
					/>
					<path
						fillRule="evenodd"
						clipRule="evenodd"
						d="M28 28.0001L-1.01471 1.04841L-0.17695 0.168091L28 28.0001Z"
						fill="#E0EAFF"
					/>
					<path
						fillRule="evenodd"
						clipRule="evenodd"
						d="M28 28.0001L-0.344971 0.345023L0.515189 -0.515137L28 28.0001Z"
						fill="#DFE9FF"
					/>
					<path
						fillRule="evenodd"
						clipRule="evenodd"
						d="M28 28.0001L0.344971 -0.3449L1.22305 -1.18042L28 28.0001Z"
						fill="#DEE9FF"
					/>
					<path
						fillRule="evenodd"
						clipRule="evenodd"
						d="M28 28L1.04834 -1.01472L1.94882 -1.83008L28 28Z"
						fill="#DDE8FF"
					/>
					<path
						fillRule="evenodd"
						clipRule="evenodd"
						d="M28 28L1.76733 -1.66651L2.68797 -2.45947L28 28Z"
						fill="#DCE7FF"
					/>
					<path
						fillRule="evenodd"
						clipRule="evenodd"
						d="M28 28L2.50433 -2.30048L3.44289 -3.07104L28 28Z"
						fill="#DBE7FF"
					/>
					<path
						fillRule="evenodd"
						clipRule="evenodd"
						d="M28 28.0001L3.2547 -2.91863L4.21342 -3.66455L28 28.0001Z"
						fill="#DAE6FF"
					/>
					<path
						fillRule="evenodd"
						clipRule="evenodd"
						d="M28 28.0001L4.02081 -3.51671L4.99745 -4.24023L28 28.0001Z"
						fill="#D9E5FF"
					/>
					<path
						fillRule="evenodd"
						clipRule="evenodd"
						d="M28 28L4.80255 -4.0947L5.79487 -4.79358L28 28Z"
						fill="#D8E5FF"
					/>
					<path
						fillRule="evenodd"
						clipRule="evenodd"
						d="M28 28.0001L5.59552 -4.65462L6.60576 -5.32886L28 28.0001Z"
						fill="#D7E4FF"
					/>
					<path
						fillRule="evenodd"
						clipRule="evenodd"
						d="M28 28L6.40417 -5.19452L7.43233 -5.84412L28 28Z"
						fill="#D6E3FF"
					/>
					<path
						fillRule="evenodd"
						clipRule="evenodd"
						d="M28 28.0001L7.22626 -5.71415L8.26786 -6.33911L28 28.0001Z"
						fill="#D5E3FF"
					/>
					<path
						fillRule="evenodd"
						clipRule="evenodd"
						d="M28 28.0001L8.05951 -6.21369L9.11679 -6.81177L28 28.0001Z"
						fill="#D4E2FF"
					/>
					<path
						fillRule="evenodd"
						clipRule="evenodd"
						d="M28 28.0001L8.90625 -6.69304L9.97697 -7.26648L28 28.0001Z"
						fill="#D3E1FF"
					/>
					<path
						fillRule="evenodd"
						clipRule="evenodd"
						d="M28 28.0001L9.7619 -7.15221L10.8483 -7.69653L28 28.0001Z"
						fill="#D2E0FF"
					/>
					<path
						fillRule="evenodd"
						clipRule="evenodd"
						d="M28 28L10.631 -7.58913L11.7286 -8.10657L28 28Z"
						fill="#D1E0FF"
					/>
					<path
						fillRule="evenodd"
						clipRule="evenodd"
						d="M28 28.0001L11.5091 -8.00342L12.6201 -8.49622L28 28.0001Z"
						fill="#D0DFFF"
					/>
					<path
						fillRule="evenodd"
						clipRule="evenodd"
						d="M28 28L12.3962 -8.39773L13.5207 -8.86365L28 28Z"
						fill="#CFDEFF"
					/>
					<path
						fillRule="evenodd"
						clipRule="evenodd"
						d="M28 28.0001L13.2944 -8.7695L14.4301 -9.2063L28 28.0001Z"
						fill="#CEDEFF"
					/>
					<path
						fillRule="evenodd"
						clipRule="evenodd"
						d="M28 28L14.2016 -9.11901L15.3462 -9.52893L28 28Z"
						fill="#CDDDFF"
					/>
					<path
						fillRule="evenodd"
						clipRule="evenodd"
						d="M28 28.0001L15.1177 -9.44822L16.2713 -9.82678L28 28.0001Z"
						fill="#CCDCFF"
					/>
					<path
						fillRule="evenodd"
						clipRule="evenodd"
						d="M28 28L16.0406 -9.75293L17.2032 -10.1046L28 28Z"
						fill="#CBDCFF"
					/>
					<path
						fillRule="evenodd"
						clipRule="evenodd"
						d="M28 28.0001L16.9702 -10.0351L18.1417 -10.3577L28 28.0001Z"
						fill="#CADBFF"
					/>
					<path
						fillRule="evenodd"
						clipRule="evenodd"
						d="M28 28.0001L17.9066 -10.2927L19.0848 -10.5884L28 28.0001Z"
						fill="#C9DAFF"
					/>
					<path
						fillRule="evenodd"
						clipRule="evenodd"
						d="M28 28L18.8496 -10.5302L20.0346 -10.7946L28 28Z"
						fill="#C8DAFF"
					/>
					<path
						fillRule="evenodd"
						clipRule="evenodd"
						d="M28 28.0001L19.7994 -10.7429L20.9888 -10.9781L28 28.0001Z"
						fill="#C7D9FF"
					/>
					<path
						fillRule="evenodd"
						clipRule="evenodd"
						d="M28 28L20.7513 -10.9312L21.9475 -11.1395L28 28Z"
						fill="#C6D8FF"
					/>
					<path
						fillRule="evenodd"
						clipRule="evenodd"
						d="M28 28L21.7078 -11.0969L22.9107 -11.2761L28 28Z"
						fill="#C5D8FF"
					/>
					<path
						fillRule="evenodd"
						clipRule="evenodd"
						d="M28 28.0001L22.671 -11.2402L23.8761 -11.3881L28 28.0001Z"
						fill="#C4D7FF"
					/>
					<path
						fillRule="evenodd"
						clipRule="evenodd"
						d="M28 28.0001L23.6342 -11.3589L24.8438 -11.4777L28 28.0001Z"
						fill="#C3D6FF"
					/>
					<path
						fillRule="evenodd"
						clipRule="evenodd"
						d="M28 28L24.6019 -11.4553L25.8138 -11.5449L28 28Z"
						fill="#C2D6FF"
					/>
					<path
						fillRule="evenodd"
						clipRule="evenodd"
						d="M28 28.0001L25.5718 -11.527L26.7859 -11.5852L28 28.0001Z"
						fill="#C1D5FF"
					/>
					<path
						fillRule="evenodd"
						clipRule="evenodd"
						d="M28 28L26.5417 -11.574L27.7558 -11.6031L28 28Z"
						fill="#C0D4FF"
					/>
					<path
						fillRule="evenodd"
						clipRule="evenodd"
						d="M28 28.0001L27.5139 -11.5986H28.728L28 28.0001Z"
						fill="#BFD4FF"
					/>
					<path
						fillRule="evenodd"
						clipRule="evenodd"
						d="M28 28.0001L28.4861 -11.5986L29.7002 -11.5673L28 28.0001Z"
						fill="#BED3FF"
					/>
					<path
						fillRule="evenodd"
						clipRule="evenodd"
						d="M28 28.0001L29.4582 -11.574L30.6701 -11.5135L28 28.0001Z"
						fill="#BDD2FF"
					/>
					<path
						fillRule="evenodd"
						clipRule="evenodd"
						d="M28 28.0001L30.4282 -11.527L31.64 -11.4374L28 28.0001Z"
						fill="#BCD2FF"
					/>
					<path
						fillRule="evenodd"
						clipRule="evenodd"
						d="M28 28L31.3981 -11.4553L32.6077 -11.3366L28 28Z"
						fill="#BBD1FF"
					/>
					<path
						fillRule="evenodd"
						clipRule="evenodd"
						d="M28 28L32.3635 -11.359L33.5709 -11.2112L28 28Z"
						fill="#BAD0FF"
					/>
					<path
						fillRule="evenodd"
						clipRule="evenodd"
						d="M28 28.0001L33.329 -11.2402L34.5318 -11.0633L28 28.0001Z"
						fill="#B9D0FF"
					/>
					<path
						fillRule="evenodd"
						clipRule="evenodd"
						d="M28 28L34.2899 -11.0969L35.4883 -10.8908L28 28Z"
						fill="#B8CFFF"
					/>
					<path
						fillRule="evenodd"
						clipRule="evenodd"
						d="M28 28L35.2486 -10.9312L36.4403 -10.696L28 28Z"
						fill="#B7CEFF"
					/>
					<path
						fillRule="evenodd"
						clipRule="evenodd"
						d="M28 28L36.2006 -10.743L37.3878 -10.4765L28 28Z"
						fill="#B6CDFF"
					/>
					<path
						fillRule="evenodd"
						clipRule="evenodd"
						d="M28 28.0001L37.1504 -10.5302L38.3286 -10.2345L28 28.0001Z"
						fill="#B5CDFF"
					/>
					<path
						fillRule="evenodd"
						clipRule="evenodd"
						d="M28 28.0001L38.0934 -10.2927L39.2627 -9.97016L28 28.0001Z"
						fill="#B4CCFF"
					/>
					<path
						fillRule="evenodd"
						clipRule="evenodd"
						d="M28 28L39.0298 -10.0352L40.1923 -9.68124L28 28Z"
						fill="#B3CBFF"
					/>
					<path
						fillRule="evenodd"
						clipRule="evenodd"
						d="M28 28L39.9594 -9.75293L41.113 -9.36989L28 28Z"
						fill="#B2CBFF"
					/>
					<path
						fillRule="evenodd"
						clipRule="evenodd"
						d="M28 28.0001L40.8822 -9.44824L42.0269 -9.03832L28 28.0001Z"
						fill="#B1CAFF"
					/>
					<path
						fillRule="evenodd"
						clipRule="evenodd"
						d="M28 28L41.7984 -9.11902L42.9318 -8.68222L28 28Z"
						fill="#B0C9FF"
					/>
					<path
						fillRule="evenodd"
						clipRule="evenodd"
						d="M28 28.0001L42.7034 -8.76953L43.8278 -8.30585L28 28.0001Z"
						fill="#AFC9FF"
					/>
					<path
						fillRule="evenodd"
						clipRule="evenodd"
						d="M28 28.0001L43.6016 -8.39771L44.7126 -7.90491L28 28.0001Z"
						fill="#AEC8FF"
					/>
					<path
						fillRule="evenodd"
						clipRule="evenodd"
						d="M28 28.0001L44.4909 -8.00342L45.5885 -7.48374L28 28.0001Z"
						fill="#ADC7FF"
					/>
					<path
						fillRule="evenodd"
						clipRule="evenodd"
						d="M28 28L45.369 -7.58911L46.4554 -7.04255L28 28Z"
						fill="#ACC7FF"
					/>
					<path
						fillRule="evenodd"
						clipRule="evenodd"
						d="M28 28.0001L46.2381 -7.15222L47.3088 -6.57878L28 28.0001Z"
						fill="#ABC6FF"
					/>
					<path
						fillRule="evenodd"
						clipRule="evenodd"
						d="M28 28L47.0938 -6.69312L48.1533 -6.09504L28 28Z"
						fill="#AAC5FF"
					/>
					<path
						fillRule="evenodd"
						clipRule="evenodd"
						d="M28 28L47.9405 -6.21375L48.9821 -5.58879L28 28Z"
						fill="#A9C5FF"
					/>
					<path
						fillRule="evenodd"
						clipRule="evenodd"
						d="M28 28L48.7738 -5.71423L49.7997 -5.06463L28 28Z"
						fill="#A8C4FF"
					/>
					<path
						fillRule="evenodd"
						clipRule="evenodd"
						d="M28 28.0001L49.5958 -5.19446L50.6061 -4.51798L28 28.0001Z"
						fill="#A7C3FF"
					/>
					<path
						fillRule="evenodd"
						clipRule="evenodd"
						d="M28 28.0001L50.4045 -4.65466L51.3968 -3.95578L28 28.0001Z"
						fill="#A6C3FF"
					/>
					<path
						fillRule="evenodd"
						clipRule="evenodd"
						d="M28 28L51.1974 -4.09473L52.1741 -3.37121L28 28Z"
						fill="#A5C2FF"
					/>
					<path
						fillRule="evenodd"
						clipRule="evenodd"
						d="M28 28.0001L51.9792 -3.51672L52.9357 -2.76856L28 28.0001Z"
						fill="#A4C1FF"
					/>
					<path
						fillRule="evenodd"
						clipRule="evenodd"
						d="M28 28L52.7453 -2.9187L53.6838 -2.14814L28 28Z"
						fill="#A3C1FF"
					/>
					<path
						fillRule="evenodd"
						clipRule="evenodd"
						d="M28 28.0001L53.4957 -2.30042L54.4163 -1.50746L28 28.0001Z"
						fill="#A2C0FF"
					/>
					<path
						fillRule="evenodd"
						clipRule="evenodd"
						d="M28 28.0001L54.2326 -1.6665L55.1331 -0.851144L28 28.0001Z"
						fill="#A1BFFF"
					/>
					<path
						fillRule="evenodd"
						clipRule="evenodd"
						d="M28 28.0001L54.9517 -1.01465L55.832 -0.176889L28 28.0001Z"
						fill="#A0BFFF"
					/>
					<path
						fillRule="evenodd"
						clipRule="evenodd"
						d="M28 28L55.655 -0.344971L56.5152 0.515189L28 28Z"
						fill="#9FBEFF"
					/>
					<path
						fillRule="evenodd"
						clipRule="evenodd"
						d="M28 28L56.3427 0.344971L57.1805 1.22305L28 28Z"
						fill="#9EBDFF"
					/>
					<path
						fillRule="evenodd"
						clipRule="evenodd"
						d="M28 28L57.0147 1.04834L57.8301 1.94882L28 28Z"
						fill="#9DBDFF"
					/>
					<path
						fillRule="evenodd"
						clipRule="evenodd"
						d="M28 28.0001L57.6666 1.76746L58.4595 2.6881L28 28.0001Z"
						fill="#9CBCFF"
					/>
					<path
						fillRule="evenodd"
						clipRule="evenodd"
						d="M28 28.0001L58.3005 2.50439L59.071 3.44295L28 28.0001Z"
						fill="#9BBBFF"
					/>
					<path
						fillRule="evenodd"
						clipRule="evenodd"
						d="M28 28L58.9187 3.25476L59.6646 4.21348L28 28Z"
						fill="#9ABAFF"
					/>
					<path
						fillRule="evenodd"
						clipRule="evenodd"
						d="M28 28.0001L59.5168 4.02087L60.2403 4.99751L28 28.0001Z"
						fill="#99BAFF"
					/>
					<path
						fillRule="evenodd"
						clipRule="evenodd"
						d="M28 28.0001L60.0947 4.80261L60.7936 5.79493L28 28.0001Z"
						fill="#98B9FF"
					/>
					<path
						fillRule="evenodd"
						clipRule="evenodd"
						d="M28 28.0001L60.6547 5.59558L61.329 6.60582L28 28.0001Z"
						fill="#97B8FF"
					/>
					<path
						fillRule="evenodd"
						clipRule="evenodd"
						d="M28 28L61.1946 6.40417L61.8442 7.43233L28 28Z"
						fill="#96B8FF"
					/>
					<path
						fillRule="evenodd"
						clipRule="evenodd"
						d="M28 28.0001L61.7142 7.22632L62.3392 8.26792L28 28.0001Z"
						fill="#95B7FF"
					/>
					<path
						fillRule="evenodd"
						clipRule="evenodd"
						d="M28 28.0001L62.2138 8.05957L62.8118 9.11685L28 28.0001Z"
						fill="#94B6FF"
					/>
					<path
						fillRule="evenodd"
						clipRule="evenodd"
						d="M28 28L62.6931 8.90625L63.2643 9.97697L28 28Z"
						fill="#93B6FF"
					/>
					<path
						fillRule="evenodd"
						clipRule="evenodd"
						d="M28 28L63.1501 9.76196L63.6966 10.8484L28 28Z"
						fill="#92B5FF"
					/>
					<path
						fillRule="evenodd"
						clipRule="evenodd"
						d="M28 28.0001L63.5891 10.6311L64.1066 11.7287L28 28.0001Z"
						fill="#91B4FF"
					/>
					<path
						fillRule="evenodd"
						clipRule="evenodd"
						d="M28 28L64.0035 11.5092L64.4963 12.6202L28 28Z"
						fill="#90B4FF"
					/>
					<path
						fillRule="evenodd"
						clipRule="evenodd"
						d="M28 28.0001L64.3978 12.3962L64.8614 13.5207L28 28.0001Z"
						fill="#8FB3FF"
					/>
					<path
						fillRule="evenodd"
						clipRule="evenodd"
						d="M28 28L64.7696 13.2944L65.2064 14.4301L28 28Z"
						fill="#8EB2FF"
					/>
					<path
						fillRule="evenodd"
						clipRule="evenodd"
						d="M28 28.0001L65.119 14.2017L65.529 15.3463L28 28.0001Z"
						fill="#8DB2FF"
					/>
					<path
						fillRule="evenodd"
						clipRule="evenodd"
						d="M28 28L65.4461 15.1178L65.8269 16.2714L28 28Z"
						fill="#8CB1FF"
					/>
					<path
						fillRule="evenodd"
						clipRule="evenodd"
						d="M28 28L65.7507 16.0406L66.1046 17.2032L28 28Z"
						fill="#8BB0FF"
					/>
					<path
						fillRule="evenodd"
						clipRule="evenodd"
						d="M28 28.0001L66.033 16.9703L66.3578 18.1419L28 28.0001Z"
						fill="#8AB0FF"
					/>
					<path
						fillRule="evenodd"
						clipRule="evenodd"
						d="M28 28.0001L66.2928 17.9066L66.5885 19.0849L28 28.0001Z"
						fill="#89AFFF"
					/>
					<path
						fillRule="evenodd"
						clipRule="evenodd"
						d="M28 28L66.5302 18.8496L66.7946 20.0346L28 28Z"
						fill="#88AEFF"
					/>
					<path
						fillRule="evenodd"
						clipRule="evenodd"
						d="M28 28.0001L66.7408 19.7994L66.9782 20.9889L28 28.0001Z"
						fill="#87AEFF"
					/>
					<path
						fillRule="evenodd"
						clipRule="evenodd"
						d="M28 28.0001L66.9312 20.7515L67.1395 21.9476L28 28.0001Z"
						fill="#86ADFF"
					/>
					<path
						fillRule="evenodd"
						clipRule="evenodd"
						d="M28 28L67.097 21.7079L67.2762 22.9108L28 28Z"
						fill="#85ACFF"
					/>
					<path
						fillRule="evenodd"
						clipRule="evenodd"
						d="M28 28.0001L67.2403 22.6711L67.3882 23.8763L28 28.0001Z"
						fill="#84ACFF"
					/>
					<path
						fillRule="evenodd"
						clipRule="evenodd"
						d="M28 28L67.359 23.6343L67.4778 24.8439L28 28Z"
						fill="#83ABFF"
					/>
					<path
						fillRule="evenodd"
						clipRule="evenodd"
						d="M28 28L67.4554 24.6019L67.545 25.8138L28 28Z"
						fill="#82AAFF"
					/>
					<path
						fillRule="evenodd"
						clipRule="evenodd"
						d="M28 28.0001L67.527 25.5719L67.5853 26.786L28 28.0001Z"
						fill="#81AAFF"
					/>
					<path
						fillRule="evenodd"
						clipRule="evenodd"
						d="M28 28.0001L67.5741 26.5419L67.6032 27.7559L28 28.0001Z"
						fill="#80A9FF"
					/>
					<path
						fillRule="evenodd"
						clipRule="evenodd"
						d="M28 28L67.5965 27.5139V28.728L28 28Z"
						fill="#7FA8FF"
					/>
					<path
						fillRule="evenodd"
						clipRule="evenodd"
						d="M28 28L67.5965 28.4861L67.5674 29.7002L28 28Z"
						fill="#7EA7FF"
					/>
					<path
						fillRule="evenodd"
						clipRule="evenodd"
						d="M28 28L67.5741 29.4582L67.5136 30.6701L28 28Z"
						fill="#7DA7FF"
					/>
					<path
						fillRule="evenodd"
						clipRule="evenodd"
						d="M28 28L67.527 30.4282L67.4374 31.64L28 28Z"
						fill="#7CA6FF"
					/>
					<path
						fillRule="evenodd"
						clipRule="evenodd"
						d="M28 28L67.4554 31.3981L67.3366 32.6077L28 28Z"
						fill="#7BA5FF"
					/>
					<path
						fillRule="evenodd"
						clipRule="evenodd"
						d="M28 28L67.359 32.3635L67.2112 33.5709L28 28Z"
						fill="#7AA5FF"
					/>
					<path
						fillRule="evenodd"
						clipRule="evenodd"
						d="M28 28L67.2403 33.329L67.0611 34.5318L28 28Z"
						fill="#79A4FF"
					/>
					<path
						fillRule="evenodd"
						clipRule="evenodd"
						d="M28 28L67.097 34.2899L66.8909 35.4883L28 28Z"
						fill="#78A3FF"
					/>
					<path
						fillRule="evenodd"
						clipRule="evenodd"
						d="M28 28L66.9312 35.2486L66.6938 36.4403L28 28Z"
						fill="#77A3FF"
					/>
					<path
						fillRule="evenodd"
						clipRule="evenodd"
						d="M28 28L66.7408 36.2006L66.4765 37.3878L28 28Z"
						fill="#76A2FF"
					/>
					<path
						fillRule="evenodd"
						clipRule="evenodd"
						d="M28 28L66.5302 37.1504L66.2346 38.3286L28 28Z"
						fill="#75A1FF"
					/>
					<path
						fillRule="evenodd"
						clipRule="evenodd"
						d="M28 28L66.2928 38.0934L65.9702 39.2627L28 28Z"
						fill="#74A1FF"
					/>
					<path
						fillRule="evenodd"
						clipRule="evenodd"
						d="M28 28L66.033 39.0298L65.6813 40.1923L28 28Z"
						fill="#73A0FF"
					/>
					<path
						fillRule="evenodd"
						clipRule="evenodd"
						d="M28 28L65.7507 39.9594L65.3699 41.113L28 28Z"
						fill="#729FFF"
					/>
					<path
						fillRule="evenodd"
						clipRule="evenodd"
						d="M28 28L65.4461 40.8822L65.0384 42.0269L28 28Z"
						fill="#719FFF"
					/>
					<path
						fillRule="evenodd"
						clipRule="evenodd"
						d="M28 28L65.119 41.7984L64.6822 42.9318L28 28Z"
						fill="#709EFF"
					/>
					<path
						fillRule="evenodd"
						clipRule="evenodd"
						d="M28 28L64.7696 42.7034L64.3037 43.8278L28 28Z"
						fill="#6F9DFF"
					/>
					<path
						fillRule="evenodd"
						clipRule="evenodd"
						d="M28 28L64.3978 43.6016L63.905 44.7126L28 28Z"
						fill="#6E9DFF"
					/>
					<path
						fillRule="evenodd"
						clipRule="evenodd"
						d="M28 28L64.0035 44.4909L63.4838 45.5885L28 28Z"
						fill="#6D9CFF"
					/>
					<path
						fillRule="evenodd"
						clipRule="evenodd"
						d="M28 28L63.5891 45.369L63.0426 46.4554L28 28Z"
						fill="#6C9BFF"
					/>
					<path
						fillRule="evenodd"
						clipRule="evenodd"
						d="M28 28L63.1501 46.2381L62.5789 47.3088L28 28Z"
						fill="#6B9BFF"
					/>
					<path
						fillRule="evenodd"
						clipRule="evenodd"
						d="M28 28L62.6931 47.0938L62.095 48.1533L28 28Z"
						fill="#6A9AFF"
					/>
					<path
						fillRule="evenodd"
						clipRule="evenodd"
						d="M28 28L62.2138 47.9405L61.5888 48.9821L28 28Z"
						fill="#6999FF"
					/>
					<path
						fillRule="evenodd"
						clipRule="evenodd"
						d="M28 28L61.7142 48.7738L61.0646 49.7997L28 28Z"
						fill="#6899FF"
					/>
					<path
						fillRule="evenodd"
						clipRule="evenodd"
						d="M28 28L61.1946 49.5958L60.5181 50.6061L28 28Z"
						fill="#6798FF"
					/>
					<path
						fillRule="evenodd"
						clipRule="evenodd"
						d="M28 28L60.6547 50.4045L59.9558 51.3968L28 28Z"
						fill="#6697FF"
					/>
					<path
						fillRule="evenodd"
						clipRule="evenodd"
						d="M28 28L60.0947 51.1974L59.3712 52.1741L28 28Z"
						fill="#6597FF"
					/>
					<path
						fillRule="evenodd"
						clipRule="evenodd"
						d="M28 28L59.5168 51.9792L58.7686 52.9357L28 28Z"
						fill="#6496FF"
					/>
					<path
						fillRule="evenodd"
						clipRule="evenodd"
						d="M28 28L58.9187 52.7453L58.1482 53.6838L28 28Z"
						fill="#6395FF"
					/>
					<path
						fillRule="evenodd"
						clipRule="evenodd"
						d="M28 28L58.3005 53.4957L57.5075 54.4163L28 28Z"
						fill="#6294FF"
					/>
					<path
						fillRule="evenodd"
						clipRule="evenodd"
						d="M28 28L57.6666 54.2326L56.8512 55.1331L28 28Z"
						fill="#6194FF"
					/>
					<path
						fillRule="evenodd"
						clipRule="evenodd"
						d="M28 28L57.0147 54.9517L56.177 55.832L28 28Z"
						fill="#6093FF"
					/>
					<path
						fillRule="evenodd"
						clipRule="evenodd"
						d="M28 28L56.3427 55.655L55.4848 56.5152L28 28Z"
						fill="#5F92FF"
					/>
					<path
						fillRule="evenodd"
						clipRule="evenodd"
						d="M28 28L55.655 56.3427L54.777 57.1805L28 28Z"
						fill="#5E92FF"
					/>
					<path
						fillRule="evenodd"
						clipRule="evenodd"
						d="M28 28L54.9517 57.0147L54.0512 57.8301L28 28Z"
						fill="#5D91FF"
					/>
					<path
						fillRule="evenodd"
						clipRule="evenodd"
						d="M28 28L54.2326 57.6666L53.312 58.4595L28 28Z"
						fill="#5C90FF"
					/>
					<path
						fillRule="evenodd"
						clipRule="evenodd"
						d="M28 28L53.4957 58.3005L52.5571 59.071L28 28Z"
						fill="#5B90FF"
					/>
					<path
						fillRule="evenodd"
						clipRule="evenodd"
						d="M28 28L52.7453 58.9187L51.7866 59.6646L28 28Z"
						fill="#5A8FFF"
					/>
					<path
						fillRule="evenodd"
						clipRule="evenodd"
						d="M28 28L51.9792 59.5168L51.0026 60.2403L28 28Z"
						fill="#598EFF"
					/>
					<path
						fillRule="evenodd"
						clipRule="evenodd"
						d="M28 28L51.1974 60.0947L50.2051 60.7936L28 28Z"
						fill="#588EFF"
					/>
					<path
						fillRule="evenodd"
						clipRule="evenodd"
						d="M28 28L50.4045 60.6547L49.392 61.329L28 28Z"
						fill="#578DFF"
					/>
					<path
						fillRule="evenodd"
						clipRule="evenodd"
						d="M28 28L49.5958 61.1946L48.5677 61.8442L28 28Z"
						fill="#568CFF"
					/>
					<path
						fillRule="evenodd"
						clipRule="evenodd"
						d="M28 28L48.7738 61.7142L47.7322 62.3392L28 28Z"
						fill="#558CFF"
					/>
					<path
						fillRule="evenodd"
						clipRule="evenodd"
						d="M28 28L47.9405 62.2138L46.8832 62.8118L28 28Z"
						fill="#548BFF"
					/>
					<path
						fillRule="evenodd"
						clipRule="evenodd"
						d="M28 28L47.0938 62.6931L46.023 63.2643L28 28Z"
						fill="#538AFF"
					/>
					<path
						fillRule="evenodd"
						clipRule="evenodd"
						d="M28 28L46.2381 63.1501L45.1517 63.6966L28 28Z"
						fill="#528AFF"
					/>
					<path
						fillRule="evenodd"
						clipRule="evenodd"
						d="M28 28L45.369 63.5891L44.2714 64.1066L28 28Z"
						fill="#5189FF"
					/>
					<path
						fillRule="evenodd"
						clipRule="evenodd"
						d="M28 28L44.4909 64.0035L43.3798 64.4963L28 28Z"
						fill="#5088FF"
					/>
					<path
						fillRule="evenodd"
						clipRule="evenodd"
						d="M28 28L43.6016 64.3978L42.4794 64.8614L28 28Z"
						fill="#4F88FF"
					/>
					<path
						fillRule="evenodd"
						clipRule="evenodd"
						d="M28 28L42.7034 64.7696L41.5699 65.2064L28 28Z"
						fill="#4E87FF"
					/>
					<path
						fillRule="evenodd"
						clipRule="evenodd"
						d="M28 28L41.7984 65.119L40.6538 65.529L28 28Z"
						fill="#4D86FF"
					/>
					<path
						fillRule="evenodd"
						clipRule="evenodd"
						d="M28 28L40.8822 65.4461L39.7286 65.8269L28 28Z"
						fill="#4C86FF"
					/>
					<path
						fillRule="evenodd"
						clipRule="evenodd"
						d="M28 28L39.9594 65.7507L38.7968 66.1046L28 28Z"
						fill="#4B85FF"
					/>
					<path
						fillRule="evenodd"
						clipRule="evenodd"
						d="M28 28L39.0298 66.033L37.8582 66.3578L28 28Z"
						fill="#4A84FF"
					/>
					<path
						fillRule="evenodd"
						clipRule="evenodd"
						d="M28 28L38.0934 66.2928L36.913 66.5885L28 28Z"
						fill="#4984FF"
					/>
					<path
						fillRule="evenodd"
						clipRule="evenodd"
						d="M28 28L37.1504 66.5302L35.9654 66.7946L28 28Z"
						fill="#4883FF"
					/>
					<path
						fillRule="evenodd"
						clipRule="evenodd"
						d="M28 28L36.2006 66.7408L35.009 66.9782L28 28Z"
						fill="#4782FF"
					/>
					<path
						fillRule="evenodd"
						clipRule="evenodd"
						d="M28 28L35.2486 66.9312L34.0525 67.1395L28 28Z"
						fill="#4681FF"
					/>
					<path
						fillRule="evenodd"
						clipRule="evenodd"
						d="M28 28L34.2899 67.097L33.0893 67.2762L28 28Z"
						fill="#4581FF"
					/>
					<path
						fillRule="evenodd"
						clipRule="evenodd"
						d="M28 28L33.329 67.2403L32.1238 67.3882L28 28Z"
						fill="#4480FF"
					/>
					<path
						fillRule="evenodd"
						clipRule="evenodd"
						d="M28 28L32.3635 67.359L31.1562 67.4778L28 28Z"
						fill="#437FFF"
					/>
					<path
						fillRule="evenodd"
						clipRule="evenodd"
						d="M28 28L31.3981 67.4554L30.1862 67.545L28 28Z"
						fill="#427FFF"
					/>
					<path
						fillRule="evenodd"
						clipRule="evenodd"
						d="M28 28L30.4282 67.527L29.2141 67.5853L28 28Z"
						fill="#417EFF"
					/>
					<path
						fillRule="evenodd"
						clipRule="evenodd"
						d="M28 28L29.4582 67.5741L28.2419 67.6032L28 28Z"
						fill="#407DFF"
					/>
					<path
						fillRule="evenodd"
						clipRule="evenodd"
						d="M28 28L28.4861 67.5965H27.2698L28 28Z"
						fill="#3F7DFF"
					/>
					<path
						fillRule="evenodd"
						clipRule="evenodd"
						d="M28 28L27.5139 67.5965L26.2998 67.5674L28 28Z"
						fill="#3E7CFF"
					/>
					<path
						fillRule="evenodd"
						clipRule="evenodd"
						d="M28 28L26.5417 67.5741L25.3276 67.5136L28 28Z"
						fill="#3D7BFF"
					/>
					<path
						fillRule="evenodd"
						clipRule="evenodd"
						d="M28 28L25.5718 67.527L24.36 67.4374L28 28Z"
						fill="#3C7BFF"
					/>
					<path
						fillRule="evenodd"
						clipRule="evenodd"
						d="M28 28L24.6019 67.4554L23.3923 67.3366L28 28Z"
						fill="#3B7AFF"
					/>
					<path
						fillRule="evenodd"
						clipRule="evenodd"
						d="M28 28L23.6343 67.359L22.4291 67.2112L28 28Z"
						fill="#3A79FF"
					/>
					<path
						fillRule="evenodd"
						clipRule="evenodd"
						d="M28 28L22.671 67.2403L21.4681 67.0611L28 28Z"
						fill="#3979FF"
					/>
					<path
						fillRule="evenodd"
						clipRule="evenodd"
						d="M28 28L21.7078 67.097L20.5117 66.8909L28 28Z"
						fill="#3878FF"
					/>
					<path
						fillRule="evenodd"
						clipRule="evenodd"
						d="M28 28L20.7514 66.9312L19.5597 66.6938L28 28Z"
						fill="#3777FF"
					/>
					<path
						fillRule="evenodd"
						clipRule="evenodd"
						d="M28 28L19.7993 66.7408L18.6121 66.4765L28 28Z"
						fill="#3677FF"
					/>
					<path
						fillRule="evenodd"
						clipRule="evenodd"
						d="M28 28L18.8496 66.5302L17.6713 66.2346L28 28Z"
						fill="#3576FF"
					/>
					<path
						fillRule="evenodd"
						clipRule="evenodd"
						d="M28 28L17.9066 66.2928L16.735 65.9702L28 28Z"
						fill="#3475FF"
					/>
					<path
						fillRule="evenodd"
						clipRule="evenodd"
						d="M28 28L16.9702 66.033L15.8077 65.6813L28 28Z"
						fill="#3375FF"
					/>
					<path
						fillRule="evenodd"
						clipRule="evenodd"
						d="M28 28L16.0406 65.7507L14.887 65.3699L28 28Z"
						fill="#3274FF"
					/>
					<path
						fillRule="evenodd"
						clipRule="evenodd"
						d="M28 28L15.1177 65.4461L13.9731 65.0384L28 28Z"
						fill="#3173FF"
					/>
					<path
						fillRule="evenodd"
						clipRule="evenodd"
						d="M28 28L14.2016 65.119L13.0682 64.6822L28 28Z"
						fill="#3073FF"
					/>
					<path
						fillRule="evenodd"
						clipRule="evenodd"
						d="M28 28L13.2944 64.7696L12.1721 64.3037L28 28Z"
						fill="#2F72FF"
					/>
					<path
						fillRule="evenodd"
						clipRule="evenodd"
						d="M28 28L12.3962 64.3978L11.2874 63.905L28 28Z"
						fill="#2E71FF"
					/>
					<path
						fillRule="evenodd"
						clipRule="evenodd"
						d="M28 28L11.5091 64.0035L10.4115 63.4838L28 28Z"
						fill="#2D71FF"
					/>
					<path
						fillRule="evenodd"
						clipRule="evenodd"
						d="M28 28L10.631 63.5891L9.54462 63.0426L28 28Z"
						fill="#2C70FF"
					/>
					<path
						fillRule="evenodd"
						clipRule="evenodd"
						d="M28 28L9.76188 63.1501L8.69116 62.5789L28 28Z"
						fill="#2B6FFF"
					/>
					<path
						fillRule="evenodd"
						clipRule="evenodd"
						d="M28 28L8.9062 62.6931L7.84668 62.095L28 28Z"
						fill="#2A6EFF"
					/>
					<path
						fillRule="evenodd"
						clipRule="evenodd"
						d="M28 28L8.05948 62.2138L7.01788 61.5888L28 28Z"
						fill="#296EFF"
					/>
					<path
						fillRule="evenodd"
						clipRule="evenodd"
						d="M28 28L7.22622 61.7142L6.19806 61.0646L28 28Z"
						fill="#286DFF"
					/>
					<path
						fillRule="evenodd"
						clipRule="evenodd"
						d="M28 28L6.40416 61.1946L5.39392 60.5181L28 28Z"
						fill="#276CFF"
					/>
					<path
						fillRule="evenodd"
						clipRule="evenodd"
						d="M28 28L5.59553 60.6547L4.60321 59.9558L28 28Z"
						fill="#266CFF"
					/>
					<path
						fillRule="evenodd"
						clipRule="evenodd"
						d="M28 28L4.80257 60.0947L3.82593 59.3712L28 28Z"
						fill="#256BFF"
					/>
					<path
						fillRule="evenodd"
						clipRule="evenodd"
						d="M28 28L4.02079 59.5168L3.06207 58.7686L28 28Z"
						fill="#246AFF"
					/>
					<path
						fillRule="evenodd"
						clipRule="evenodd"
						d="M28 28L3.25472 58.9187L2.31616 58.1482L28 28Z"
						fill="#236AFF"
					/>
					<path
						fillRule="evenodd"
						clipRule="evenodd"
						d="M28 28L2.50432 58.3005L1.58368 57.5075L28 28Z"
						fill="#2269FF"
					/>
					<path
						fillRule="evenodd"
						clipRule="evenodd"
						d="M28 28L1.76736 57.6666L0.866882 56.8512L28 28Z"
						fill="#2168FF"
					/>
					<path
						fillRule="evenodd"
						clipRule="evenodd"
						d="M28 28L1.04829 57.0147L0.167969 56.177L28 28Z"
						fill="#2068FF"
					/>
					<path
						fillRule="evenodd"
						clipRule="evenodd"
						d="M28 28L0.344962 56.3427L-0.515198 55.4848L28 28Z"
						fill="#1F67FF"
					/>
					<path
						fillRule="evenodd"
						clipRule="evenodd"
						d="M28 28L-0.344961 55.655L-1.18048 54.777L28 28Z"
						fill="#1E66FF"
					/>
					<path
						fillRule="evenodd"
						clipRule="evenodd"
						d="M28 28L-1.01472 54.9517L-1.83008 54.0512L28 28Z"
						fill="#1D66FF"
					/>
					<path
						fillRule="evenodd"
						clipRule="evenodd"
						d="M28 28L-1.66657 54.2326L-2.45953 53.312L28 28Z"
						fill="#1C65FF"
					/>
					<path
						fillRule="evenodd"
						clipRule="evenodd"
						d="M28 28L-2.30048 53.4957L-3.07104 52.5571L28 28Z"
						fill="#1B64FF"
					/>
					<path
						fillRule="evenodd"
						clipRule="evenodd"
						d="M28 28L-2.91875 52.7453L-3.66467 51.7866L28 28Z"
						fill="#1A64FF"
					/>
					<path
						fillRule="evenodd"
						clipRule="evenodd"
						d="M28 28L-3.51684 51.9792L-4.24036 51.0026L28 28Z"
						fill="#1963FF"
					/>
					<path
						fillRule="evenodd"
						clipRule="evenodd"
						d="M28 28L-4.09476 51.1974L-4.79364 50.2051L28 28Z"
						fill="#1862FF"
					/>
					<path
						fillRule="evenodd"
						clipRule="evenodd"
						d="M28 28L-4.65474 50.4045L-5.32898 49.392L28 28Z"
						fill="#1762FF"
					/>
					<path
						fillRule="evenodd"
						clipRule="evenodd"
						d="M28 28L-5.19458 49.5958L-5.84418 48.5677L28 28Z"
						fill="#1661FF"
					/>
					<path
						fillRule="evenodd"
						clipRule="evenodd"
						d="M28 28L-5.71427 48.7738L-6.33923 47.7322L28 28Z"
						fill="#1560FF"
					/>
					<path
						fillRule="evenodd"
						clipRule="evenodd"
						d="M28 28L-6.21375 47.9405L-6.81183 46.8832L28 28Z"
						fill="#1460FF"
					/>
					<path
						fillRule="evenodd"
						clipRule="evenodd"
						d="M28 28L-6.6931 47.0938L-7.26654 46.023L28 28Z"
						fill="#135FFF"
					/>
					<path
						fillRule="evenodd"
						clipRule="evenodd"
						d="M28 28L-7.15233 46.2381L-7.69666 45.1517L28 28Z"
						fill="#125EFF"
					/>
					<path
						fillRule="evenodd"
						clipRule="evenodd"
						d="M28 28L-7.58913 45.369L-8.10657 44.2714L28 28Z"
						fill="#115EFF"
					/>
					<path
						fillRule="evenodd"
						clipRule="evenodd"
						d="M28 28L-8.00354 44.4909L-8.49634 43.3798L28 28Z"
						fill="#105DFF"
					/>
					<path
						fillRule="evenodd"
						clipRule="evenodd"
						d="M28 28L-8.39779 43.6016L-8.86371 42.4794L28 28Z"
						fill="#0F5CFF"
					/>
					<path
						fillRule="evenodd"
						clipRule="evenodd"
						d="M28 28L-8.76962 42.7034L-9.20642 41.5699L28 28Z"
						fill="#0E5BFF"
					/>
					<path
						fillRule="evenodd"
						clipRule="evenodd"
						d="M28 28L-9.11907 41.7984L-9.52899 40.6538L28 28Z"
						fill="#0D5BFF"
					/>
					<path
						fillRule="evenodd"
						clipRule="evenodd"
						d="M28 28L-9.44834 40.8822L-9.8269 39.7286L28 28Z"
						fill="#0C5AFF"
					/>
					<path
						fillRule="evenodd"
						clipRule="evenodd"
						d="M28 28L-9.753 39.9594L-10.1047 38.7968L28 28Z"
						fill="#0B59FF"
					/>
					<path
						fillRule="evenodd"
						clipRule="evenodd"
						d="M28 28L-10.0352 39.0298L-10.3578 37.8582L28 28Z"
						fill="#0A59FF"
					/>
					<path
						fillRule="evenodd"
						clipRule="evenodd"
						d="M28 28L-10.2928 38.0934L-10.5885 36.913L28 28Z"
						fill="#0958FF"
					/>
					<path
						fillRule="evenodd"
						clipRule="evenodd"
						d="M28 28L-10.5302 37.1504L-10.7946 35.9654L28 28Z"
						fill="#0857FF"
					/>
					<path
						fillRule="evenodd"
						clipRule="evenodd"
						d="M28 28L-10.7431 36.2006L-10.9783 35.009L28 28Z"
						fill="#0757FF"
					/>
					<path
						fillRule="evenodd"
						clipRule="evenodd"
						d="M28 28L-10.9312 35.2486L-11.1395 34.0525L28 28Z"
						fill="#0656FF"
					/>
					<path
						fillRule="evenodd"
						clipRule="evenodd"
						d="M28 28L-11.097 34.2899L-11.2762 33.0893L28 28Z"
						fill="#0555FF"
					/>
					<path
						fillRule="evenodd"
						clipRule="evenodd"
						d="M28 28L-11.2403 33.329L-11.3882 32.1238L28 28Z"
						fill="#0455FF"
					/>
					<path
						fillRule="evenodd"
						clipRule="evenodd"
						d="M28 28L-11.3591 32.3635L-11.4778 31.1562L28 28Z"
						fill="#0354FF"
					/>
					<path
						fillRule="evenodd"
						clipRule="evenodd"
						d="M28 28L-11.4554 31.3981L-11.545 30.1862L28 28Z"
						fill="#0253FF"
					/>
					<path
						fillRule="evenodd"
						clipRule="evenodd"
						d="M28 28L-11.527 30.4282L-11.5853 29.2141L28 28Z"
						fill="#0153FF"
					/>
					<path
						fillRule="evenodd"
						clipRule="evenodd"
						d="M28 28L-11.5741 29.4582L-11.6032 28.2419L28 28Z"
						fill="#0052FF"
					/>
					<path
						fillRule="evenodd"
						clipRule="evenodd"
						d="M28 28L-11.5988 28.4861V27.5139L28 28Z"
						fill="white"
					/>
				</g>
			</g>
			<defs>
				<clipPath id="clip0_8601_5512">
					<rect width="56" height="56" fill="white" />
				</clipPath>
			</defs>
		</svg>
	);
};
