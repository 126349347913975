import { getWrapNativeToken } from '@/app-helpers/token';
import { usePriceNativeToken, useTokenPriceSingleToken } from '@/app-hooks/api/portfolio/useTokenPrices';
import { useSpeedUpTransaction } from '@/app-hooks/transactions';
import { useTransactionWatcherStore } from '@/app-store';
import { ITransactionStatus, TransactionType } from '@/app-types';
import { Button } from '@chakra-ui/button';
import { Box, Center, Text } from '@chakra-ui/layout';
import {
	Drawer,
	DrawerBody,
	DrawerCloseButton,
	DrawerContent,
	DrawerFooter,
	DrawerHeader,
	DrawerOverlay,
} from '@chakra-ui/modal';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { GasOption, TextSmall, Toast } from '..';
import { TxStatus } from '@/app-cores/api/activities';
import { ChainType } from '@/app-contexts/wallet-provider/type';

interface SpeedUpTransactionProps {
	isOpen: boolean;
	onClose: () => void;
	txHash: string;
	transactionType: TransactionType;
	onStatusChange?: (status: ITransactionStatus) => void;
}
export const SpeedUpTransaction: React.FC<SpeedUpTransactionProps> = ({
	isOpen,
	onClose,
	txHash,
	transactionType,
	onStatusChange,
}) => {
	const [isSpeedUpLoading, setIsSpeedUpLoading] = useState(false);
	const { t } = useTranslation();
	const navigate = useNavigate();
	const { estSpeedUpGasFeeData, speedUpTransaction, chainId } = useSpeedUpTransaction({ txHash, transactionType });
	const { addPendingEvmTransaction, removePendingTransaction } = useTransactionWatcherStore();
	const { data: tokenPriceUsd = 0 } = usePriceNativeToken({
		chainId: chainId?.toString(),
	});

	const handleSpeedUpTransaction = async () => {
		try {
			setIsSpeedUpLoading(true);
			const speedRes = await speedUpTransaction();
			addPendingEvmTransaction({
				transaction: speedRes,
				callback: ({ status }) => {
					setIsSpeedUpLoading(false);

					if (status === TxStatus.Success) {
						removePendingTransaction({ hash: txHash, type: ChainType.EVM });
						toast(<Toast type="success" message={t('transactionResult.speedUpSuccess')} />);
						navigate('/');
					} else {
						toast(<Toast type="error" message={t('transactionResult.speedUpFailed')} />);
					}
					onClose();
				},
				metadata: { transactionType },
			});
		} catch (error) {
			setIsSpeedUpLoading(false);
			toast(<Toast type="error" message={t('transactionResult.speedUpFailed')} />);
			console.error('handleSpeedUpTransaction error:', error);
			onClose();
		}
	};
	return (
		<Drawer placement="bottom" onClose={onClose} isOpen={isOpen}>
			<DrawerOverlay />
			<DrawerContent>
				<DrawerHeader borderBottomWidth="1px" borderBottomColor="rgba(0, 0, 0, 0.08)">
					<Center>
						<Text fontSize={'16px'} fontWeight={'500'}>
							{t('button.speedUp')}
						</Text>
					</Center>
					<DrawerCloseButton />
				</DrawerHeader>
				<DrawerBody px={4}>
					<TextSmall my={4}>{t('cryptos.gasOptions')}</TextSmall>
					<GasOption
						type="aggressive"
						chainId={chainId?.toString()}
						gasFeeData={estSpeedUpGasFeeData}
						onSelect={() => {}}
						title="Aggressive"
						isSelected
						tokenPriceUsd={tokenPriceUsd}
					/>
					<Box
						mt={6}
						border="1px solid rgba(0, 0, 0, 0.08)"
						fontSize="sm"
						color="orange.500"
						borderRadius="12px"
						px={4}
						py={3}
					>
						{t('cryptos.speedUpDescription')}
					</Box>
				</DrawerBody>
				<DrawerFooter display={'flex'} gap={2}>
					<Button
						flex={1}
						fontSize="sm"
						backgroundColor="white"
						color="black"
						border="1px solid rgba(0, 0, 0, 0.08)"
						isDisabled={isSpeedUpLoading}
						onClick={onClose}
					>
						{t('button.close')}
					</Button>
					<Button
						isLoading={isSpeedUpLoading}
						colorScheme={'cyan'}
						flex={1}
						fontSize="sm"
						onClick={handleSpeedUpTransaction}
					>
						{t('button.okIAgree')}
					</Button>
				</DrawerFooter>
			</DrawerContent>
		</Drawer>
	);
};
