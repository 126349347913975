import { isAndroid } from 'react-device-detect';
import { FeatureConfig, useQueryFeatureConfig } from '../../app-hooks/api/configuration';

export const combineComponents = (...components: React.FC<any>[]): React.FC<any> => {
	return components.reduce(
		(AccumulatedComponents, CurrentComponent) => {
			return ({ children }: React.ComponentProps<React.FC<any>>): JSX.Element => {
				return (
					<AccumulatedComponents>
						<CurrentComponent>{children}</CurrentComponent>
					</AccumulatedComponents>
				);
			};
		},
		({ children }) => <>{children}</>,
	);
};

export function getTelegramShareLink(url: string, text: string) {
	return `https://telegram.me/share/url?url=${encodeURIComponent(url)}&text=${encodeURIComponent(text)}`;
}

export const FeatureConfigWrap: React.FC<
	React.PropsWithChildren<{
		feature: FeatureConfig;
		deviceFeatures?: {
			android?: FeatureConfig;
			forceShowOnAndroid?: boolean;
		};
		fallbackComponent?: React.ReactNode;
	}>
> = ({
	feature,
	children,
	deviceFeatures = {
		forceShowOnAndroid: true,
	},
	fallbackComponent,
}) => {
	const enable = useQueryFeatureConfig(feature);
	const enableOnAndroid = useQueryFeatureConfig(deviceFeatures?.android) || deviceFeatures?.forceShowOnAndroid;
	if (isAndroid) {
		return enable && enableOnAndroid && children;
	}
	return enable ? children : fallbackComponent;
};
