import { Box, Center, Container } from '@chakra-ui/react';
import { isMobile } from 'react-device-detect';
import { Outlet, useSearchParams } from 'react-router-dom';
import { Loading } from '@/app-components/common';
import { FeatureConfig, useQueryFeatureConfig } from '@/app-hooks/api/configuration';
import { useTranslation } from 'react-i18next';
import { getHostName } from '@/app-features/app-wallet-connect/utils/HelperUtil';
import { useModalStore } from '@/app-features/app-wallet-connect/store/ModalStore';
import { DappNavigate } from '@/app-features/app-wallet-connect/components/DappNavigate';
import { ConnectButton } from '@/app-features/app-wallet-connect/components/ConnectButton';

export const DappBrowserLayout = () => {
	const [searchParams] = useSearchParams();
	const dappUrl = searchParams.get('dappUrl');
	const dappHostname = getHostName(dappUrl);
	const parentUrl = searchParams.get('parentUrl');
	const { isWaiting } = useModalStore();
	const isShowOnDesktop = useQueryFeatureConfig(FeatureConfig.CONNECT_BUTTON_ON_DESKTOP);
	const isShowOnMobile = useQueryFeatureConfig(FeatureConfig.CONNECT_BUTTON_ON_MOBILE);
	const { t } = useTranslation();
	const whiteList = t('whiteListShowWalletConnectButton');
	const showConnectButton = () => {
		if (!dappHostname) return false;
		if (isMobile) {
			return isShowOnMobile || (whiteList && whiteList.split(',').some((w) => dappHostname?.includes(w)));
		}
		return isShowOnDesktop;
	};

	return (
		<Container className="pageContent">
			<Box className="pageFixed" pt={4} overflow="hidden">
				<Outlet />
				<Center backgroundColor="gray.100" justifyContent="start" py={3} gap={2}>
					<DappNavigate isDisabled={!dappHostname} parentUrl={parentUrl} />
					{showConnectButton() && <ConnectButton hostname={dappHostname} />}
				</Center>
			</Box>
			{isWaiting && <Loading opacity="0.8" />}
		</Container>
	);
};
