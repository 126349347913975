import { useModalStore } from '../store/ModalStore';
import SessionProposalModal from './SessionProposalModal';
import SessionSendTransactionModal from './SessionSendTransactionModal';
import SessionSignModal from './SessionSignModal';
import SessionSignTypedDataModal from './SessionSignTypedDataModal';
import { SessionUnSupportedMethodModal } from './SessionUnSupportedMethodModal';

export const BotConnectorRequestModal = () => {
	const { open, view } = useModalStore();
	if (!open) return null;
	switch (view) {
		case 'SessionProposalModal':
			return <SessionProposalModal />;
		case 'SessionSendTransactionModal':
			return <SessionSendTransactionModal />;
		case 'SessionSignModal':
			return <SessionSignModal />;
		case 'SessionSignTypedDataModal':
			return <SessionSignTypedDataModal />;
		case 'SessionUnSupportedMethodModal':
			return <SessionUnSupportedMethodModal />;
		default:
			break;
	}
};
