import { Button, Flex } from '@chakra-ui/react';
import { CSSProperties } from 'react';

type ItemData<T> = { label: string; value: T; onClick?: () => void };
interface AmountSuggestionProps<T> {
	onSelect?: (amount: T, option: ItemData<T>) => void;
	data?: ItemData<T>[];
	itemStyle?: CSSProperties;
	value?: T;
}
const defaultData = [25, 50, 75, 100].map((e) => ({ label: `${e}%`, value: e }));
export const AmountSuggestion = <T,>({
	onSelect,
	data = defaultData as ItemData<T>[],
	itemStyle,
	value,
}: AmountSuggestionProps<T>) => {
	return (
		<Flex gap={4} justifyContent="space-between" width="100%">
			{data.map((item, i) => (
				<Button
					fontSize={'12px'}
					borderRadius={'100px'}
					flex={1}
					display="flex"
					justifyContent={'center'}
					size="sm"
					variant="outline"
					backgroundColor="white"
					key={i}
					onClick={() => {
						onSelect(item.value, item);
						item?.onClick?.();
					}}
					sx={itemStyle}
					border={`1px solid ${String(item.value) === String(value) ? 'cyan' : 'transparent'}`}
				>
					{item.label}
				</Button>
			))}
		</Flex>
	);
};
