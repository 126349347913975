import queryString from 'query-string';
import { useGlobalToast } from '@/app-store';
import { parseTonConnect } from '../services/deeplinkService';
import { useModalStore } from '../store/ModalStore';
import { tonConnectReturnStrategyManager } from '../utils/returnStrategyManager';

export const useTonConnect = () => {
	const { onOpen, setPlatform } = useModalStore();
	const { addToast } = useGlobalToast();
	const onConnect = (url: string) => {
		try {
			const connectParams = parseTonConnect({ url });
			if (typeof connectParams === 'string') {
				throw new Error('Your QR code or URI link is not valid');
			}
			tonConnectReturnStrategyManager.addReturnStrategy(
				connectParams.clientSessionId,
				connectParams.returnStrategy,
			);
			onOpen('SessionProposalModal', {
				connectParams: connectParams,
			});

			if (connectParams.platform) {
				setPlatform(connectParams.platform);
			}
		} catch (error) {
			const { query } = queryString.parseUrl(url);
			if (query.platform) {
				setPlatform(query.platform as string);
			} else {
				addToast({
					title: 'Ton connect error',
					type: 'error',
					message: 'Your QR code or URI link is not valid',
				});
			}
		}
	};
	return {
		onConnect,
	};
};
