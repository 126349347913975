import React from 'react';

interface IProps extends React.SVGAttributes<HTMLOrSVGElement> {}

export const FiErrorIcon: React.FC<IProps> = ({ width = 24, height = 24, ...rest }) => {
	return (
		<svg width={width} height={height} viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
			<rect y="0.457031" width="24" height="24" rx="12" fill="#E32A2A" />
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M12.0001 6.45736C8.68637 6.45736 6.00008 9.14365 6.00008 12.4574C6.00008 15.7711 8.68637 18.4574 12.0001 18.4574C15.3138 18.4574 18.0001 15.7711 18.0001 12.4574C18.0001 9.14365 15.3138 6.45736 12.0001 6.45736ZM4.66675 12.4574C4.66675 8.40727 7.94999 5.12402 12.0001 5.12402C16.0502 5.12402 19.3334 8.40727 19.3334 12.4574C19.3334 16.5074 16.0502 19.7907 12.0001 19.7907C7.94999 19.7907 4.66675 16.5074 4.66675 12.4574Z"
				fill="white"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M11.9999 9.12402C12.3681 9.12402 12.6666 9.4225 12.6666 9.79069V12.4574C12.6666 12.8255 12.3681 13.124 11.9999 13.124C11.6317 13.124 11.3333 12.8255 11.3333 12.4574V9.79069C11.3333 9.4225 11.6317 9.12402 11.9999 9.12402Z"
				fill="white"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M11.3333 15.1237C11.3333 14.7555 11.6317 14.457 11.9999 14.457H12.0066C12.3748 14.457 12.6733 14.7555 12.6733 15.1237C12.6733 15.4919 12.3748 15.7904 12.0066 15.7904H11.9999C11.6317 15.7904 11.3333 15.4919 11.3333 15.1237Z"
				fill="white"
			/>
		</svg>
	);
};
