import React from 'react';

interface IProps extends React.SVGAttributes<HTMLOrSVGElement> {}

export const FiFireIcon: React.FC<IProps> = ({ width = 20, height = 20, ...rest }) => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 20 20" fill="none">
			<g clipPath="url(#clip0_13356_5020)">
				<path
					d="M15.4929 7.5909C15.4389 7.52332 15.3735 7.5366 15.3393 7.55039C15.3106 7.56203 15.2448 7.59922 15.2539 7.69242C15.2648 7.80434 15.271 7.91844 15.2721 8.0316C15.277 8.50105 15.0887 8.96098 14.7555 9.29344C14.4244 9.62375 13.9889 9.80172 13.5253 9.79656C12.8919 9.78848 12.3666 9.45813 12.0061 8.84121C11.708 8.33109 11.839 7.67316 11.9777 6.97656C12.0589 6.56883 12.1428 6.14719 12.1428 5.7459C12.1428 2.62133 10.0423 0.818672 8.79018 0.0222266C8.76429 0.00578125 8.73964 0 8.7178 0C8.68229 0 8.65417 0.0153125 8.6403 0.0246875C8.61343 0.0428906 8.57042 0.084375 8.58425 0.157813C9.06284 2.6993 7.63534 4.22785 6.12401 5.84613C4.5662 7.51422 2.80054 9.40488 2.80054 12.8147C2.80054 16.7768 6.02386 20.0001 9.98589 20.0001C13.2481 20.0001 16.1243 17.7257 16.9802 14.4692C17.5639 12.2488 16.9523 9.42035 15.4929 7.5909ZM10.1651 18.4664C9.17304 18.5116 8.22952 18.1558 7.50886 17.4667C6.79593 16.785 6.38702 15.8336 6.38702 14.8565C6.38702 13.0229 7.08812 11.6768 8.97382 9.88977C9.00468 9.86051 9.03628 9.85125 9.06382 9.85125C9.08878 9.85125 9.11042 9.85887 9.1253 9.86602C9.15667 9.88113 9.20823 9.91856 9.20128 9.99949C9.13386 10.7841 9.13503 11.4353 9.20472 11.9351C9.38284 13.2119 10.3175 14.0697 11.5306 14.0697C12.1253 14.0697 12.6919 13.8459 13.1258 13.4395C13.1762 13.3923 13.2324 13.3983 13.254 13.4029C13.2825 13.409 13.3208 13.4265 13.3408 13.4748C13.5207 13.9093 13.6127 14.3704 13.6141 14.8454C13.6198 16.7565 12.0726 18.3809 10.1651 18.4664Z"
					fill="#E64649"
				/>
			</g>
			<defs>
				<clipPath id="clip0_13356_5020">
					<rect width="20" height="20" fill="white" />
				</clipPath>
			</defs>
		</svg>
	);
};
