import React from 'react';

interface IProps extends React.SVGAttributes<HTMLOrSVGElement> {}

export const FiCreditCardIcon: React.FC<IProps> = ({ width = 40, height = 40, ...rest }) => {
	return (
		<svg width={width} height={height} viewBox="0 0 40 41" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M36.875 6.125H3.125C1.40195 6.125 0 7.52695 0 9.25V31.75C0 33.473 1.40195 34.875 3.125 34.875H36.875C38.598 34.875 40 33.473 40 31.75V9.25C40 7.52695 38.598 6.125 36.875 6.125Z"
				fill="#5D647F"
			/>
			<path
				d="M13.75 18.625H6.25C5.55961 18.625 5 18.0654 5 17.375V12.375C5 11.6846 5.55961 11.125 6.25 11.125H13.75C14.4404 11.125 15 11.6846 15 12.375V17.375C15 18.0654 14.4404 18.625 13.75 18.625Z"
				fill="#FFD100"
			/>
			<path
				d="M9.375 26.75H6.25C5.55961 26.75 5 26.1904 5 25.5V24.875C5 24.1846 5.55961 23.625 6.25 23.625H9.375C10.0654 23.625 10.625 24.1846 10.625 24.875V25.5C10.625 26.1904 10.0654 26.75 9.375 26.75Z"
				fill="#B8BAC0"
			/>
			<path
				d="M17.5 26.75H14.375C13.6846 26.75 13.125 26.1904 13.125 25.5V24.875C13.125 24.1846 13.6846 23.625 14.375 23.625H17.5C18.1904 23.625 18.75 24.1846 18.75 24.875V25.5C18.75 26.1904 18.1904 26.75 17.5 26.75Z"
				fill="#B8BAC0"
			/>
			<path
				d="M25.625 26.75H22.5C21.8096 26.75 21.25 26.1904 21.25 25.5V24.875C21.25 24.1846 21.8096 23.625 22.5 23.625H25.625C26.3154 23.625 26.875 24.1846 26.875 24.875V25.5C26.875 26.1904 26.3154 26.75 25.625 26.75Z"
				fill="#B8BAC0"
			/>
			<path
				d="M33.75 26.75H30.625C29.9346 26.75 29.375 26.1904 29.375 25.5V24.875C29.375 24.1846 29.9346 23.625 30.625 23.625H33.75C34.4404 23.625 35 24.1846 35 24.875V25.5C35 26.1904 34.4404 26.75 33.75 26.75Z"
				fill="#B8BAC0"
			/>
			<path
				d="M18.125 30.5H5.625C5.27953 30.5 5 30.2202 5 29.875C5 29.5298 5.27953 29.25 5.625 29.25H18.125C18.4705 29.25 18.75 29.5298 18.75 29.875C18.75 30.2202 18.4705 30.5 18.125 30.5Z"
				fill="#8A8895"
			/>
			<path
				d="M26.25 30.5H20.625C20.2795 30.5 20 30.2202 20 29.875C20 29.5298 20.2795 29.25 20.625 29.25H26.25C26.5955 29.25 26.875 29.5298 26.875 29.875C26.875 30.2202 26.5955 30.5 26.25 30.5Z"
				fill="#8A8895"
			/>
			<path d="M15 15.5H8.75V14.25H15V13H8.75V11.125H7.5V18.625H8.75V16.75H15V15.5Z" fill="#FF9500" />
			<path
				d="M31.25 18.625C33.3211 18.625 35 16.9461 35 14.875C35 12.8039 33.3211 11.125 31.25 11.125C29.1789 11.125 27.5 12.8039 27.5 14.875C27.5 16.9461 29.1789 18.625 31.25 18.625Z"
				fill="#FF4F19"
			/>
		</svg>
	);
};
