import { useMemo, useRef } from 'react';
import { Box, Flex, Skeleton, Text } from '@chakra-ui/react';
import { isNumber } from 'lodash-es';
import { BASE_BACKGROUND_COLOR, BASE_BORDER_COLOR } from '@/app-theme/theme';
import { InputMode } from '@/app-store/swap';
import { formatCurrency, formatUsd } from '@/app-helpers/number';
import { FiSwapIcon } from '@/assets/icons';
import { AmountSuggestion, Numeric } from '@/app-components/common';
import { useDepositStore } from '../store';

interface DepositAmountProps {
	onValueChange?: (value: string) => void;
	availableAmount?: number;
	availableUsd?: number;
	onMax: (amount: string) => void;
}

export const DepositAmount: React.FC<DepositAmountProps> = ({
	onValueChange,
	availableAmount,
	availableUsd,
	onMax,
}) => {
	const { state, inputMode, token, toggleInputMode, errorMessage, tokenInfo } = useDepositStore();
	const inputRef = useRef<HTMLInputElement>(null);
	const options = useMemo(() => {
		const suggestion = [1, 5, 10, 50];

		if (inputMode === InputMode.AMOUNT) {
			return suggestion.map((e) => ({ label: `${e} ${tokenInfo?.symbol}`, value: e }));
		}
		return suggestion.map((e) => ({ label: `$${e * 10}`, value: e * 10 }));
	}, [inputMode, tokenInfo?.symbol]);
	return (
		<Flex direction={'column'} gap={3}>
			<Flex justifyContent="space-between">
				<Text fontSize="xs" fontWeight={500}>
					Amount
				</Text>
				<Flex
					onClick={() => {
						if (inputMode === InputMode.AMOUNT) {
							onMax(availableAmount?.toString());
						} else {
							onMax(availableUsd?.toString());
						}
					}}
					cursor="pointer"
				>
					<Text fontSize="xs" fontWeight={500} color="gray.400" mr={1}>
						Available:
					</Text>
					{isNumber(availableAmount) ? (
						<Text fontSize="xs" fontWeight={500}>
							{formatCurrency(availableAmount)} {tokenInfo?.symbol} ≈ {formatUsd(availableUsd)}
						</Text>
					) : (
						<Skeleton opacity="0.1" height={'16px'} width="80px" />
					)}
				</Flex>
			</Flex>

			<Flex
				style={{
					backgroundColor: BASE_BACKGROUND_COLOR,
					padding: '8px 16px',
					borderRadius: '12px',
				}}
			>
				<Flex flexDirection={'column'} gap={'4px'} flex={1}>
					<Numeric
						prefix={inputMode === InputMode.AMOUNT ? undefined : '$'}
						allowNegative={false}
						value={state.rawValue}
						allowLeadingZeros
						placeholder="0"
						inputMode="decimal"
						inputType={inputMode === InputMode.USD ? 'usd' : 'number'}
						getInputRef={(ref) => (inputRef.current = ref)}
						useCustomInput
						style={{
							paddingLeft: 0,
							paddingRight: 0,
							fontWeight: '500',
							fontSize: '14px',
							border: 'none',
							outline: 'none',
							boxShadow: 'none',
							backgroundColor: 'inherit',
							maxWidth: !token ? '160px' : 'unset',
							height: '21px',
							flex: 1,
						}}
						onValueChange={({ value }) => onValueChange?.(value)}
					/>
					<Text
						onClick={() => inputRef.current?.focus()}
						as="span"
						fontSize={'12px'}
						whiteSpace={'nowrap'}
						lineHeight={'18px'}
					>
						{inputMode === InputMode.AMOUNT
							? `≈ ${formatUsd(state.usdValue)}`
							: `≈ ${formatCurrency(state.tokenValue)} ${tokenInfo?.symbol}`}
					</Text>
				</Flex>
				<Flex
					width={'70px'}
					userSelect={'none'}
					cursor={'pointer'}
					alignItems={'center'}
					gap={'8px'}
					height={'44px'}
					paddingLeft={'14px'}
					borderLeft={`1px solid ${BASE_BORDER_COLOR}`}
					onClick={toggleInputMode}
				>
					<Text fontSize={'12px'} fontWeight={'500'}>
						{inputMode}
					</Text>
					<FiSwapIcon width={14} height={14} />
				</Flex>
			</Flex>
			<Box height={2} mt={-3}>
				{errorMessage && (
					<Text color="red" fontSize="xs">
						{errorMessage}
					</Text>
				)}
			</Box>
			<AmountSuggestion
				itemStyle={{ backgroundColor: 'gray.100' }}
				onSelect={(vl) => onValueChange(vl + '')}
				value={+state.rawValue}
				data={options}
			/>
		</Flex>
	);
};
