import { useQuery } from '@tanstack/react-query';
import { ethers } from 'ethers';
import { CHAIN_CONFIG } from '@/app-constants/chains';

export function useGasFeeData(chainId: string) {
	const gasFeeData = useQuery({
		queryKey: ['get-gas-price'],
		queryFn: async () => {
			const provider = new ethers.JsonRpcProvider(CHAIN_CONFIG[+chainId].rpcUrls.default.http);
			return provider.getFeeData();
		},
		enabled: !!chainId,
	});
	return gasFeeData;
}
