import { NumericFormat, NumericFormatProps, OnValueChange } from 'react-number-format';
import { isIOS } from 'react-device-detect';
import { Ref, useState } from 'react';
import { Box, Center, InputProps } from '@chakra-ui/react';
import { BaseInput, InputCustom } from '@/app-components/common';
import { STORAGE_KEYS } from '@/app-constants/storage';

const NOT_NUMBER_REGEX = /[^0-9]/g;

export type InputType = 'usd' | 'number';

function countCharacter(str, x) {
	return [...str].filter((char) => char === x).length;
}

function persistDecimalSeparator(value: string, signFromKeyboard?: string): string | undefined {
	let rs;
	if (value.includes('.')) {
		rs = '.';
	}
	if (value.includes(',')) {
		rs = ',';
	}
	if (signFromKeyboard === '.' || signFromKeyboard === ',') {
		rs = signFromKeyboard;
	}
	if (rs) {
		sessionStorage.setItem(STORAGE_KEYS.TOBI_IOS_DECIMAL_SEPARATOR, rs);
		return rs;
	}
}
interface IOSNumberFormatProps extends InputProps {
	onValueChange?: OnValueChange;
	getInputRef?: ((el: HTMLInputElement) => void) | Ref<any>;
	inputType?: InputType;
	useCustomInput?: boolean;
	numericProps: NumericProps;
	useIosCustom?: boolean;
}
enum SourceType {
	event = 'event',
	props = 'prop',
}

const IOSNumberFormat: React.FC<IOSNumberFormatProps> = ({
	onValueChange,
	getInputRef,
	value = '',
	inputType,
	useCustomInput,
	onChange: onChangeProp,
	numericProps,
	useIosCustom = true,
	...rest
}) => {
	const valueProps = (value as string) || '';
	const decimalSeparator = sessionStorage.getItem(STORAGE_KEYS.TOBI_IOS_DECIMAL_SEPARATOR);

	let replacedValue = value as string;
	if (decimalSeparator) {
		replacedValue = valueProps.replace(NOT_NUMBER_REGEX, decimalSeparator);
	}

	const onChange = (value, event) => {
		onValueChange(
			{
				floatValue: value.replace(NOT_NUMBER_REGEX, '.'),
				formattedValue: value.replace(NOT_NUMBER_REGEX, '.'),
				value: value.replace(NOT_NUMBER_REGEX, '.'),
			},
			{
				source: SourceType.event,
				event,
			},
		);
	};

	if (!useIosCustom) {
		const handleChange = (event) => {
			const newValue = event.target.value;
			persistDecimalSeparator(newValue, event?.nativeEvent?.data ?? '');

			const decimalSeparator = sessionStorage.getItem(STORAGE_KEYS.TOBI_IOS_DECIMAL_SEPARATOR) || '';
			const prefix = rest.prefix ?? '';
			onChangeProp?.(event);

			const replacedValue = newValue.replace(prefix, '').replace(NOT_NUMBER_REGEX, decimalSeparator);

			if (replacedValue.startsWith(`.`) || replacedValue.startsWith(`,`)) return;
			const decimalCount = countCharacter(replacedValue, '.') + countCharacter(replacedValue, ',');
			if ((valueProps.includes(',') || valueProps.includes('.')) && decimalCount > 1) return;
			if (replacedValue === `0`) {
				return onChange(replacedValue, event);
			}
			if (replacedValue[1] === '.' || replacedValue[1] === ',') {
				return onChange(replacedValue, event);
			}

			return onChange(replacedValue.replace(/^0/, ''), event);
		};
		const { inputType, useIosCustom, ...rest } = numericProps;
		return (
			<NumericFormat
				{...rest}
				onChange={handleChange}
				decimalSeparator={decimalSeparator || undefined}
				allowedDecimalSeparators={[',', '.']}
			/>
		);
	}

	const handleChange = (event) => {
		const newValue = event.target.value;
		persistDecimalSeparator(newValue);
		onChangeProp?.(event);
		if (newValue.startsWith('.') || newValue.startsWith(',')) return;
		const decimalCount = countCharacter(newValue, '.') + countCharacter(newValue, ',');
		if ((valueProps.includes(',') || valueProps.includes('.')) && decimalCount > 1) return;
		if (newValue === '0') {
			return onChange(newValue, event);
		}
		if (newValue[1] === '.' || newValue[1] === ',') {
			return onChange(newValue, event);
		}

		return onChange(newValue.replace(/^0/, ''), event);
	};

	const InputComponent = useCustomInput ? InputCustom : BaseInput;
	// todo deprecated
	return (
		<Center height={'100%'}>
			<Box
				as="label"
				fontSize="sm"
				display={inputType === 'usd' && replacedValue ? 'inline' : 'none'}
				transform={useCustomInput && 'translateY(2px)'}
			>
				$
			</Box>
			<InputComponent
				ref={getInputRef}
				inputMode="decimal"
				onChange={handleChange}
				value={replacedValue}
				{...rest}
			/>
		</Center>
	);
};

interface NumericProps extends NumericFormatProps {
	inputType?: InputType;
	useCustomInput?: boolean;
	useIosCustom?: boolean; // Use this prop to use the new version of custom input
}
export const Numeric: React.FC<NumericProps> = (props) => {
	const { inputType, useCustomInput, useIosCustom, ...restNumericFormatProps } = props;
	if (isIOS) {
		const {
			type,
			displayType,
			renderText,
			valueIsNumericString,
			isAllowed,
			isCharacterSame,
			size,
			allowNegative,
			allowLeadingZeros,
			...rest
		} = props;

		return <IOSNumberFormat {...rest} useIosCustom={useIosCustom} numericProps={props} />;
	}
	return <NumericFormat thousandSeparator {...restNumericFormatProps} />;
};
