import { AmountSuggestion, InputCustom, Tooltip } from '@/app-components/common';
import { displaySlippage } from '@/app-helpers/display';
import { FiInfoIcon } from '@/assets/icons';
import { Flex, Text } from '@chakra-ui/react';

export const AUTO_SLIPPAGE = 0;
const options = [
	{ label: 'Auto', value: AUTO_SLIPPAGE },
	...[0.05, 0.1, 0.5, 1].map((e) => ({ label: `${e}%`, value: e })),
];

export default function SlippageSetting({ value, onChange }: { value; onChange: (v: number | string) => void }) {
	return (
		<Flex flexDirection={'column'} gap={'12px'}>
			<Flex alignItems={'flex-end'} justifyContent={'space-between'}>
				<Tooltip label="During your swap if the price changes by more than this %, your transaction will revert">
					<Flex fontSize={'12px'} alignItems={'center'} gap={'4px'}>
						Max Slippage <FiInfoIcon />
					</Flex>
				</Tooltip>

				<Text fontSize={'12px'} fontWeight={'500'}>
					Custom: {displaySlippage(value)}
				</Text>
			</Flex>
			<AmountSuggestion onSelect={(vl) => onChange(+vl)} data={options} value={value} />
			<InputCustom
				inputMode="decimal"
				type="number"
				max={19.99}
				sx={{ background: 'gray.100' }}
				value={value}
				borderRadius={'80px'}
				border={'none'}
				fontSize={'14px'}
				onChange={(e) => {
					const rawValue = e.target.value;
					const numVal = +rawValue;
					const numberRegex = /^(\d+)\.?(\d{1,6})?$/;
					if (
						rawValue &&
						(isNaN(numVal) ||
							numVal > 19.99 ||
							numVal < 0 ||
							!rawValue.match(numberRegex) ||
							rawValue.startsWith('00'))
					) {
						return;
					}
					onChange(rawValue);
				}}
			></InputCustom>
		</Flex>
	);
}
