import { useMutation } from '@tanstack/react-query';
import { TSentSUiTransaction } from '@/app-types';
import { suiWallet } from '@/app-cores/mpc-wallet/sui';
import { createTokenTransferTransaction, parseAmount } from './createTokenTransferTransaction';
import { useTransactionWatcherStore } from '@/app-store';
import { useGetCoins } from './useGetCoins';

export function useSentSuiToken(tokenAddress: string) {
	const { addPendingSuiTransaction } = useTransactionWatcherStore();
	const { data: coins } = useGetCoins(tokenAddress);
	const { mutateAsync: sendTransaction, ...result } = useMutation({
		mutationKey: ['sent-sui-transaction'],

		mutationFn: async (params: TSentSUiTransaction) => {
			const tx = createTokenTransferTransaction({
				amount: params.amount,
				to: params.to,
				token: params.token,
				isPayAllSui: params.isPayAllSui,
				coins: coins,
			});
			const hash = await suiWallet.signAndExecuteTransactionBlock({
				transactionBlock: tx,
				options: {
					showInput: true,
					showEffects: true,
					showEvents: true,
				},
			});
			console.log('🚀 ~ mutationFn: ~ hash:', hash);
			return hash;
		},
		onSuccess: ({ digest: hash }, { message, to, token, amount }) => {
			addPendingSuiTransaction({ hash });
		},
	});
	return {
		...result,
		sendTransaction,
	};
}
