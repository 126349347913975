import { CHAIN_CONFIG, ChainId } from '@/app-constants/chains';
import { ITokenSearch, TokenInfo } from '@/app-cores/api/bff';
import i18n from '@/app-cores/i18n';
import { compareChain, isNativeToken, isStableCoin, isWrapNativeToken } from '@/app-helpers/address';
import {
	countDecimals,
	formatCurrency,
	formatNumber,
	formatUnits,
	formatUsd,
	removeTrailingZeros,
	truncateToFixed,
} from '@/app-helpers/number';
import { formatTimeDuration } from '@/app-helpers/time';
import { getNativeToken, getTokenInfo, getWrapNativeToken, isEvmChain, isSolanaChain } from '@/app-helpers/token';
import { ONE_DAY } from '@/app-hooks/api/portfolio/constant';
import { FormatOrder, LimitProvider } from '@/app-hooks/limit/type';
import { SwapErrorInfo, ErrorMsg, getPriceImpactWarningMsg } from '@/app-hooks/swap';
import { calcAmount } from '@/app-hooks/swap/helper';
import { SwapErrorType, SwapProvider } from '@/app-hooks/swap/type';
import { InputMode } from '@/app-store/swap';
import { OrderMode } from '@/app-store/swap/limit';
import dayjs from 'dayjs';
import { ethers, parseUnits } from 'ethers';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

export const getLimitType = (tokenIn: TokenInfo, tokenOut: TokenInfo) => {
	if (!tokenIn || !tokenOut || !compareChain(tokenIn.chainId, tokenOut.chainId)) return LimitProvider.NOT_SUPPORT;

	if (isEvmChain(tokenIn.chainId) && isEvmChain(tokenOut.chainId)) {
		if (
			(isNativeToken(tokenIn.address) && isWrapNativeToken(tokenOut.address, tokenOut.chainId)) ||
			(isNativeToken(tokenOut.address) && isWrapNativeToken(tokenIn.address, tokenIn.chainId))
		)
			return LimitProvider.NOT_SUPPORT;

		return LimitProvider.KYBER;
	}

	if (isSolanaChain(tokenIn.chainId) && isSolanaChain(tokenOut.chainId)) return LimitProvider.JUPITER;

	return LimitProvider.NOT_SUPPORT;
};

export function calcInput(params: {
	amountOut: string;
	amountOutUsd: string;
	rate: string;
	tokenIn: ITokenSearch;
	tokenOut: ITokenSearch;
	inputMode: InputMode;
}) {
	try {
		const { rate, tokenOut, inputMode, tokenIn } = params;
		const isTokenMode = inputMode === InputMode.AMOUNT;
		const amount = isTokenMode ? params.amountOut : params.amountOutUsd;
		if (!amount) throw new Error();
		if (isTokenMode) {
			const value = +rate ? +amount / +rate : 0;
			const { amount: amountIn, amountUsd: amountInUsd } = calcAmount({
				token: tokenIn,
				amount: truncateToFixed(value, getTokenInfo(tokenIn).decimals),
			});
			const { amount: amountOut, amountUsd: amountOutUsd } = calcAmount({
				token: tokenOut,
				amount: params.amountOut,
			});
			return { amountOut, amountOutUsd, amountIn, amountInUsd };
		} else {
			const { amount: amountOut, amountUsd: amountOutUsd } = calcAmount({
				token: tokenOut,
				amount: +params.amountOutUsd / getTokenInfo(tokenOut).priceUsd,
				amountUsd: params.amountOutUsd,
			});
			const { amount: amountIn, amountUsd: amountInUsd } = calcAmount({
				token: tokenIn,
				amount: isStableCoin(getTokenInfo(tokenIn)) ? amountOutUsd : rate ? +amountOutUsd / +rate : 0,
				amountUsd: amountOutUsd,
			});

			return { amountOut, amountOutUsd, amountInUsd, amountIn };
		}
	} catch (error) {
		return { amountOut: '', amountOutUsd: '', amountIn: '', amountInUsdt: '' };
	}
}

export function calcOutput(params: {
	amountIn: string;
	amountInUsd: string;
	rate: string;
	tokenIn: ITokenSearch;
	tokenOut: ITokenSearch;
	inputMode: InputMode;
}) {
	try {
		const { rate, tokenOut, inputMode, tokenIn } = params;
		const isTokenMode = inputMode === InputMode.AMOUNT;
		const amount = isTokenMode ? params.amountIn : params.amountInUsd;
		if (!amount) throw new Error();
		if (isTokenMode) {
			const decimals = Math.max(getTokenInfo(tokenOut).decimals, 20); // large enough
			const value =
				parseUnits(truncateToFixed(amount, decimals), decimals) *
				parseUnits(truncateToFixed(rate, decimals), decimals);
			const { amount: amountOut, amountUsd: amountOutUsd } = calcAmount({
				token: tokenOut,
				amount: removeTrailingZeros(ethers.formatUnits(value, 2 * decimals)),
			});
			const { amount: amountIn, amountUsd: amountInUsd } = calcAmount({
				token: tokenIn,
				amount: params.amountIn,
			});
			return { amountOut, amountOutUsd, amountIn, amountInUsd };
		} else {
			const { amount: amountIn, amountUsd: amountInUsd } = calcAmount({
				token: tokenIn,
				amountUsd: params.amountInUsd,
				amount: isStableCoin(getTokenInfo(tokenIn))
					? +params.amountInUsd
					: rate
					? +params.amountInUsd / +rate
					: 0,
			});
			const { amount: amountOut, amountUsd: amountOutUsd } = calcAmount({
				token: tokenOut,
				amount: +amountIn * +rate,
				amountUsd: amountInUsd,
			});

			return { amountOut, amountOutUsd, amountInUsd, amountIn };
		}
	} catch (error) {
		return { amountOut: '', amountOutUsd: '', amountIn: '', amountInUsdt: '' };
	}
}

export function calcRateInput(params: {
	amountIn: string;
	amountOut: string;
	amountInUsd: string;
	amountOutUsd: string;
	tokenIn: ITokenSearch;
	tokenOut: ITokenSearch;
}) {
	try {
		const { tokenIn, tokenOut, amountIn, amountOut } = params;
		if (amountIn && amountIn === amountOut) return '1';
		const decimals = Math.min(getTokenInfo(tokenIn).decimals, getTokenInfo(tokenOut).decimals);
		return removeTrailingZeros(
			divideBigInt(
				parseUnits(truncateToFixed(amountOut, decimals), decimals),
				parseUnits(truncateToFixed(amountIn, decimals), decimals),
				decimals,
			),
		);
	} catch (error) {
		return '';
	}
}

function divideBigInt(numerator: bigint, denominator: bigint, precision) {
	// Scale the numerator
	const scaleFactor = 10n ** BigInt(precision);
	const scaledNumerator = numerator * scaleFactor;
	const result = scaledNumerator / denominator;

	// Convert result to string and insert decimal point
	const resultStr = result.toString();
	const integerPart = resultStr.slice(0, -precision) || '0';
	const fractionalPart = resultStr.slice(-precision).padStart(precision, '0');

	return `${integerPart}.${fractionalPart}`;
}

export const calcRateOrder = ({ tokenIn, tokenOut, amountIn, amountOut }: FormatOrder) => {
	try {
		return formatCurrency(
			+ethers.formatUnits(amountOut, tokenOut.decimals) / +ethers.formatUnits(amountIn, tokenIn.decimals),
		);
	} catch (error) {
		return '-';
	}
};

export const calculateRatePercent = (marketRate: number, rate: string | number, withSign = false) => {
	const percent = (+rate - marketRate) / marketRate;
	return `${withSign && percent > 0 ? '+' : ''}${(percent * 100).toFixed(2)}`;
};

export const formatDisplayExpiredTime = (durationInSeconds: number | Date) => {
	if (!durationInSeconds) return `Never`;
	if (typeof durationInSeconds === 'object') return dayjs(durationInSeconds).format('MM-DD-YYYY HH:mm');
	return formatTimeDuration(durationInSeconds / 1000);
};

export const formatExpiredTime = (expiredAt: null | Date | number, provider?: LimitProvider) => {
	if (!expiredAt) {
		if (provider === LimitProvider.KYBER) return Math.floor((Date.now() + ONE_DAY * 365) / 1000); // large enough
		return null;
	}
	if (typeof expiredAt === 'number') return Math.floor((Date.now() + expiredAt) / 1000);
	return Math.floor((expiredAt as Date).getTime() / 1000);
};

export const getMarketWarningMsg = ({
	marketRate,
	rate,
	tokenIn,
	tokenOut,
	orderMode,
}: {
	marketRate: number;
	rate: string;
	tokenIn: TokenInfo;
	tokenOut: TokenInfo;
	orderMode: OrderMode;
}): ErrorMsg | undefined => {
	if (!rate || !marketRate) return;
	const isSellMode = orderMode === OrderMode.SELL;
	const status = isSellMode ? 'lower' : 'larger';
	const percent = calculateRatePercent(marketRate, rate);
	const invalid = isSellMode ? +rate < +marketRate : +rate > +marketRate;
	if (invalid)
		return {
			type: Math.abs(+percent) >= 50 ? 'error' : 'warning',
			uiType: 'warning',
			title: i18n.t('limit.lowerThanMarketPrice', {
				percent,
				status,
			}),
			msg: i18n.t('limit.lowerThanMarketPriceDesc', {
				percent,
				rate: formatCurrency(rate),
				tokenIn: isSellMode ? tokenIn?.symbol : tokenOut?.symbol,
				tokenOut: isSellMode ? tokenOut?.symbol : tokenIn?.symbol,
				action: orderMode,
				status,
			}),
		};
};

export const getLimitTokenAddress = (token: TokenInfo) =>
	isNativeToken(token.address) ? getWrapNativeToken(token.chainId).address : token.address;

export const useValidateInput = ({
	tokenIn,
	tokenOut,
	parsedAmountIn,
	parseTokenInBalance,
	parsedAmountOut,
	parseTokenOutBalance,
	provider,
	showGasWarning,
	marketRate,
	rate,
	amountInDebounced,
	amountOutDebounced,
	amountInUsd,
	amountOutUsd,
	orderMode,
	remainAmount,
}: {
	tokenIn: TokenInfo | undefined;
	parsedAmountIn: bigint;
	parseTokenInBalance: bigint;
	parsedAmountOut: bigint;
	parseTokenOutBalance: bigint;
	provider: LimitProvider;
	showGasWarning: boolean;
	amountInDebounced: string;
	amountOutDebounced: string;
	marketRate: number | undefined;
	rate: string;
	tokenOut: TokenInfo | undefined;
	amountInUsd: string | undefined;
	amountOutUsd: string | undefined;
	orderMode: OrderMode;
	remainAmount: bigint;
}): SwapErrorInfo => {
	const { t } = useTranslation();
	return useMemo(() => {
		const chainId = tokenIn?.chainId;
		let lackOfGas = false,
			lackOfFund = false;

		const isSellMode = orderMode === OrderMode.SELL;
		const parsedAmount = isSellMode ? parsedAmountIn : parsedAmountOut;
		const parseBalance = isSellMode ? parseTokenInBalance : parseTokenOutBalance;
		const token = isSellMode ? tokenIn : tokenOut;

		if (!token) return { hasError: false, lackOfGas, lackOfFund, messages: [] };

		const errors: ErrorMsg[] = [];

		if (amountInDebounced && countDecimals(amountInDebounced) > tokenIn?.decimals) {
			errors.push({ type: 'error', msg: '', errorType: SwapErrorType.VALIDATE_AMOUNT });
		}
		if (amountOutDebounced && countDecimals(amountOutDebounced) > tokenOut?.decimals) {
			errors.push({ type: 'error', msg: '', errorType: SwapErrorType.VALIDATE_AMOUNT_OUT });
		}

		const error = getMarketWarningMsg({ rate, marketRate, tokenIn, tokenOut, orderMode });
		if (error) errors.push(error);

		switch (provider) {
			case LimitProvider.JUPITER: {
				const minAmount = 5;
				const amountUsd = isSellMode ? amountInUsd : amountOutUsd;
				if (amountUsd && +amountUsd < minAmount)
					errors.push({
						type: 'error',
						msg: t('limit.minAmount', { amount: minAmount }),
						errorType: SwapErrorType.VALIDATE,
					});
				break;
			}
			case LimitProvider.KYBER: {
				if (isNativeToken(token?.address)) {
					errors.push({
						type: 'warning',
						msg: t('limit.notSupportNative', { token: token?.symbol }),
					});
				}
				break;
			}
		}
		if (chainId && provider === LimitProvider.NOT_SUPPORT) {
			errors.push({ type: 'error', msg: t('limit.pairNotSupport'), errorType: SwapErrorType.VALIDATE });
		} else if (parseBalance < parsedAmount || (token && !parseBalance && !!parsedAmount)) {
			const error: ErrorMsg = {
				type: 'error',
				msg: t('limit.notEnoughToken', {
					token: token?.symbol,
					chainName: CHAIN_CONFIG[token?.chainId].name,
				}),
				errorType: SwapErrorType.FUND,
			};
			errors.push(error);
			lackOfFund = true;
		} else if (remainAmount < parseBalance && remainAmount < parsedAmount) {
			const error: ErrorMsg = {
				type: 'error',
				msg: t('limit.notEnoughTokenHasActiveOrder', {
					token: token?.symbol,
					remain: formatUnits(remainAmount, token?.decimals),
				}),
				errorType: SwapErrorType.FUND,
			};
			errors.push(error);
			lackOfFund = true;
		} else if (showGasWarning && !!parsedAmount) {
			const error: ErrorMsg = {
				type: 'error',
				msg: t('limit.notEnoughGas', {
					token: getNativeToken(token?.chainId)?.symbol,
					chainName: CHAIN_CONFIG[token?.chainId].name,
				}),
				errorType: SwapErrorType.GAS,
			};
			errors.push(error);
			lackOfGas = true;
		}

		return { hasError: errors.some((e) => e.type === 'error'), lackOfFund, lackOfGas, messages: errors };
	}, [
		provider,
		parsedAmountIn,
		tokenIn,
		parseTokenInBalance,
		showGasWarning,
		t,
		marketRate,
		remainAmount,
		rate,
		tokenOut,
		amountInDebounced,
		amountOutDebounced,
		amountInUsd,
		orderMode,
		amountOutUsd,
		parsedAmountOut,
		parseTokenOutBalance,
	]);
};

export const getOrderTokensLabel = (isSellMode: boolean) => {
	return {
		labelIn: isSellMode ? 'Sell' : 'Buy',
		labelOut: isSellMode ? 'Receive' : 'Using',
	};
};
