import { isAddress, isHexString, toUtf8String } from 'ethers';

/**
 * IMPORTANT:
 * Almost function here has been pick from app-wallet-connect module because
 * we implement the same concept with wallet connect.
 * But keep in mind that the payload of 2 module can be different.
 */

/**
 * Converts hex to utf8 string if it is valid bytes
 */
function convertHexToUtf8(value: string) {
	if (isHexString(value)) {
		return toUtf8String(value);
	}

	return value;
}

/**
 * Gets message from various signing request methods by filtering out
 * a value that is not an address (thus is a message).
 * If it is a hex string, it gets converted to utf8 string
 */
export function getSignParamsMessage(params: string[]) {
	const message = params.filter((p) => !isAddress(p))[0];

	return convertHexToUtf8(message);
}

export function getSignTypedDataParamsData(params: string[]) {
	const data = params.filter((p) => !isAddress(p))[0];

	if (typeof data === 'string') {
		return JSON.parse(data);
	}

	return data;
}

export function getChainId(chain: string = '') {
	return chain.split(':')[1];
}

/**
 * Methods
 */
export const EIP155_SIGNING_METHODS = {
	PERSONAL_SIGN: 'personal_sign',
	ETH_SIGN: 'eth_sign',
	ETH_SIGN_TRANSACTION: 'eth_signTransaction',
	ETH_SIGN_TYPED_DATA: 'eth_signTypedData',
	ETH_SIGN_TYPED_DATA_V3: 'eth_signTypedData_v3',
	ETH_SIGN_TYPED_DATA_V4: 'eth_signTypedData_v4',
	ETH_SEND_RAW_TRANSACTION: 'eth_sendRawTransaction',
	ETH_SEND_TRANSACTION: 'eth_sendTransaction',
};
