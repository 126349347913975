import React from 'react';

interface IProps extends React.SVGAttributes<HTMLOrSVGElement> {}

export const EthereumIcon: React.FC<IProps> = ({ width = 56, height = 56, ...rest }) => {
	return (
		<svg width={width} height={height} viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
			<g clipPath="url(#clip0_8601_5788)">
				<path
					d="M28 56C43.464 56 56 43.464 56 28C56 12.536 43.464 0 28 0C12.536 0 0 12.536 0 28C0 43.464 12.536 56 28 56Z"
					fill="#627EEA"
				/>
				<path d="M28.8715 7V22.5225L41.9913 28.385L28.8715 7Z" fill="white" fillOpacity="0.602" />
				<path d="M28.8715 7L15.75 28.385L28.8715 22.5225V7Z" fill="white" />
				<path d="M28.8715 38.444V48.9913L42 30.828L28.8715 38.444Z" fill="white" fillOpacity="0.602" />
				<path d="M28.8715 48.9913V38.4423L15.75 30.828L28.8715 48.9913Z" fill="white" />
				<path d="M28.8715 36.0028L41.9913 28.385L28.8715 22.526V36.0028Z" fill="white" fillOpacity="0.2" />
				<path d="M15.75 28.385L28.8715 36.0028V22.526L15.75 28.385Z" fill="white" fillOpacity="0.602" />
			</g>
			<defs>
				<clipPath id="clip0_8601_5788">
					<rect width="56" height="56" fill="white" />
				</clipPath>
			</defs>
		</svg>
	);
};
