import React from 'react';

interface IProps extends React.SVGAttributes<HTMLOrSVGElement> {}

export const FiShareIcon: React.FC<IProps> = ({ width = 21, height = 20, ...rest }) => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 21 20" fill="none">
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M3.83333 9.1665C4.29357 9.1665 4.66667 9.5396 4.66667 9.99984V16.6665C4.66667 16.8875 4.75446 17.0995 4.91074 17.2558C5.06702 17.412 5.27899 17.4998 5.5 17.4998H15.5C15.721 17.4998 15.933 17.412 16.0893 17.2558C16.2455 17.0995 16.3333 16.8875 16.3333 16.6665V9.99984C16.3333 9.5396 16.7064 9.1665 17.1667 9.1665C17.6269 9.1665 18 9.5396 18 9.99984V16.6665C18 17.3295 17.7366 17.9654 17.2678 18.4343C16.7989 18.9031 16.163 19.1665 15.5 19.1665H5.5C4.83696 19.1665 4.20107 18.9031 3.73223 18.4343C3.26339 17.9654 3 17.3295 3 16.6665V9.99984C3 9.5396 3.3731 9.1665 3.83333 9.1665Z"
				fill="black"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M9.91091 1.07757C10.2363 0.752137 10.764 0.752137 11.0894 1.07757L14.4228 4.41091C14.7482 4.73634 14.7482 5.26398 14.4228 5.58942C14.0973 5.91486 13.5697 5.91486 13.2442 5.58942L10.5002 2.84534L7.75609 5.58942C7.43065 5.91486 6.90301 5.91486 6.57757 5.58942C6.25214 5.26398 6.25214 4.73634 6.57757 4.41091L9.91091 1.07757Z"
				fill="black"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M10.4998 0.833496C10.9601 0.833496 11.3332 1.20659 11.3332 1.66683V12.5002C11.3332 12.9604 10.9601 13.3335 10.4998 13.3335C10.0396 13.3335 9.6665 12.9604 9.6665 12.5002V1.66683C9.6665 1.20659 10.0396 0.833496 10.4998 0.833496Z"
				fill="black"
			/>
		</svg>
	);
};
