import { AppLogoIcon } from '@/assets/icons';
import { BoxProps, Center, Flex } from '@chakra-ui/react';
import Lottie from 'lottie-react';
import RefreshAnimationData from '@/assets/animations/refresh.json';

// use for page
interface LoadingProps extends BoxProps {
	showSpinner?: boolean;
}

export const LoadingSpinner = () => {
	return (
		<Center position="relative" className="refresh-container" mb={5}>
			<Center className="circle-loader"></Center>
			<Center width={8} height={8} position="absolute" borderRadius="100%">
				<Lottie
					loop={true}
					autoplay={true}
					animationData={RefreshAnimationData}
					style={{
						width: '34px',
						height: '34px',
					}}
				/>
			</Center>
		</Center>
	);
};
export const Loading: React.FunctionComponent<LoadingProps> = ({ showSpinner, ...props }) => {
	return (
		<Center className="app-loading-wrap" {...props}>
			{showSpinner ? <LoadingSpinner /> : <AppLogoIcon width={90} height={90} className="app-loading" />}
		</Center>
	);
};

// loading for component
export const LocalLoader = ({ height = '100%', style }: { height?: string; style?: React.CSSProperties }) => {
	return (
		<Flex
			width={'100%'}
			height={height}
			minHeight={height}
			justifyContent={'center'}
			alignItems={'center'}
			flex={1}
			style={style}
		>
			<AppLogoIcon width={50} height={50} className="app-loading" />
		</Flex>
	);
};
