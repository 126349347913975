import { ButtonSecondary, CTAButton } from '@/app-components/common';
import ConfirmModal from '@/app-components/common/ConfirmModal';
import { FormatOrder, LimitProvider } from '@/app-hooks/limit/type';
import OrderItem from '@/app-views/swap/components/LimitOrder/OrderItem';
import {
	Center,
	Drawer,
	DrawerBody,
	DrawerCloseButton,
	DrawerContent,
	DrawerFooter,
	DrawerHeader,
	DrawerOverlay,
	Flex,
	Text,
} from '@chakra-ui/react';
import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

export default function CancelOrderModal({
	order,
	isOpen,
	cancelOrder,
	gasLessCancel,
	onClose,
}: {
	cancelOrder: () => void;
	gasLessCancel: () => void;
	onClose: () => void;
	isOpen: boolean;
	order: FormatOrder;
}) {
	const { t } = useTranslation();
	const { provider } = order;
	const isKyberOrder = provider === LimitProvider.KYBER;
	return (
		<ConfirmModal {...{ onClose, action: false }} isOpen={isOpen && !!order} title={t('limit.cancelOrder')}>
			<Flex flexDirection={'column'} alignItems={'center'} gap={'16px'} py={2}>
				<Text fontSize={'sm'}>Do you want to cancel this order ?</Text>
				<OrderItem order={order} forceHideCancel />

				<Flex display="flex" gap={4} height={'max-content'} width={'100%'}>
					<Flex direction={'column'} flex={1} gap={2}>
						<ButtonSecondary
							height="48px"
							fontWeight="medium"
							fontSize={'14px'}
							onClick={isKyberOrder ? gasLessCancel : onClose}
						>
							{isKyberOrder ? t('limit.gaslessCancel') : t('button.close')}
						</ButtonSecondary>
						{isKyberOrder && <Text fontSize={'10px'}>*{t('limit.gaslessCancelDesc')}</Text>}
					</Flex>

					<Flex direction={'column'} flex={1} gap={2}>
						<CTAButton
							colorScheme={'cyan'}
							height="48px"
							fontWeight="medium"
							fontSize={'14px'}
							onClick={cancelOrder}
						>
							{isKyberOrder ? t('limit.hardCancel') : t('button.confirm')}
						</CTAButton>
						{isKyberOrder && <Text fontSize={'10px'}>*{t('limit.hardCancelDesc')}</Text>}
					</Flex>
				</Flex>
			</Flex>
		</ConfirmModal>
	);
}
