import { Box, Center, Spinner } from '@chakra-ui/react';
import { isIOS } from 'react-device-detect';
import { memo, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { uniqueId, isEmpty, debounce } from 'lodash-es';
import { Message } from '@/app-cores/api/app-copilot/type';
import { CONVERSATION_PAGE_SIZE, useQueryBotConversations } from '../../hooks/useQueryMessages';
import { useMutationReplyMessage } from '../../hooks/useMutationSendMessage';
import { InputMessage } from './InputMessage';
import { Messages } from './Messages';

interface MessagesContainerProps {
	isVisible: boolean;
	onFocusInputOnIOS?: (isFocus: boolean) => void;
}
function generateUserLocaleMessage(message: string): Message {
	const id = uniqueId();
	return {
		chatId: id,
		id: id,
		senderId: id,
		message: {
			text: message,
		},
		createdAt: new Date().toISOString(),
		updatedAt: new Date().toISOString(),
	};
}

export const MessagesContainer = memo(({ isVisible, onFocusInputOnIOS }: MessagesContainerProps) => {
	const [currentPage, setCurrentPage] = useState(1);
	const { data, isFetching } = useQueryBotConversations(currentPage, {
		enabled: !!isVisible,
	});
	const { mutateAsync: sendMessage } = useMutationReplyMessage();
	const [messages, setMessages] = useState([]);
	const bottomRef = useRef<HTMLDivElement>(null);
	const containerRef = useRef<HTMLDivElement>(null);
	const preScrollHeightRef = useRef(0);
	const allowScrollBottom = useRef(true);
	const [messageLocalStatus, setMessageLocalStatus] = useState<Record<string, 'Pending' | 'Success'>>({});
	const allowLoadMore = useRef(false);

	allowLoadMore.current = isEmpty(data) ? false : data?.length < CONVERSATION_PAGE_SIZE ? false : true;

	useEffect(() => {
		if (!data) return;
		setMessages((preMessage) => [...data, ...preMessage]);
	}, [data]);

	useLayoutEffect(() => {
		if (isEmpty(messages) || !allowScrollBottom.current) return;
		bottomRef.current.scrollIntoView({
			behavior: 'smooth',
		});
	}, [messages]);

	useLayoutEffect(() => {
		if (allowScrollBottom.current) return;
		requestAnimationFrame(() => {
			containerRef.current.scrollTop = containerRef.current.scrollHeight - preScrollHeightRef.current;
		});
	}, [messages, containerRef]);

	const handleReply = async (msg: string) => {
		allowScrollBottom.current = true;
		const userMsg = generateUserLocaleMessage(msg);
		setMessageLocalStatus((pre) => ({
			...pre,
			[userMsg.id]: 'Pending',
		}));
		setMessages((pre) => [...pre, userMsg]);
		const data = await sendMessage(msg);
		if (isEmpty(data)) {
			bottomRef.current.scrollIntoView({
				behavior: 'smooth',
			});
		}
		setMessageLocalStatus((pre) => ({
			...pre,
			[userMsg.id]: 'Success',
		}));
		setMessages((pre) => [...pre, ...data]);
	};

	const handleLoadMoreMessages = debounce(() => {
		const threshold = 0;
		if (containerRef.current.scrollTop > threshold || isFetching) return;
		if (allowLoadMore.current) {
			allowScrollBottom.current = false;
			preScrollHeightRef.current = containerRef.current.scrollHeight;
			setCurrentPage((pre) => pre + 1);
		}
	}, 50);

	return (
		<>
			<Box
				px={3}
				ref={containerRef}
				className="hide-scrollbar"
				flex={1}
				overflow="auto"
				onScroll={handleLoadMoreMessages}
			>
				<Center width="100%" visibility={isFetching ? 'visible' : 'hidden'}>
					<Spinner thickness="1px" speed="0.65s" emptyColor="gray.200" color="cyan.400" size="xs" />
				</Center>
				<Messages data={messages} messageLocalStatus={messageLocalStatus} />
				<Box ref={bottomRef}></Box>
			</Box>
			<Box px={3} mt={2} minHeight={12} borderBottomLeftRadius={12} borderBottomRightRadius={12}>
				<InputMessage
					onReply={handleReply}
					onFocus={() => {
						isIOS && onFocusInputOnIOS?.(true);
					}}
					onBlur={() => {
						isIOS && onFocusInputOnIOS?.(false);
					}}
				/>
			</Box>
		</>
	);
});
