import { ButtonPrimary } from '@/app-components/common';
import { FiPlusIcon } from '@/assets/icons';
import { BoxProps, Flex, Image, List, Text } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useOnEventCallback } from '@/app-hooks/common';
import { useDepositStore } from '@/app-features/deposit/store';
import { CSSProperties } from 'react';

const props = {
	border: `1px solid #00000014`,
	colorScheme: 'white',
	color: 'black',
	borderRadius: 60,
	flex: 1,
	gap: '6px',
	fontSize: '12px',
};
export default function NoAsset(props: { px?: number; style?: CSSProperties }) {
	const { onOpen: openDeposit } = useDepositStore();

	const onBuyClick = useOnEventCallback(() => {
		openDeposit();
	});

	const { t } = useTranslation();
	return (
		<List
			spacing={3}
			padding={5}
			border={'1px'}
			borderRadius={'1rem'}
			borderColor={'#00000014'}
			marginTop={2}
			{...props}
		>
			<Flex justifyContent={'center'}>
				<Image src="/assets/images/no-data.svg" height={'64px'} />
			</Flex>
			<Text fontSize={'14px'}>{t('cryptos.noAsset')}</Text>
			<Flex gap={'8px'} justifyContent={'center'}>
				<ButtonPrimary onClick={onBuyClick}>
					<FiPlusIcon width={20} height={20} />
					{t('deposit.depositNow')}
				</ButtonPrimary>

				{/* <ButtonSecondary {...props} onClick={onOpen}>
					<FiPlusIcon />
					Import
					<ImportToken isOpen={isOpen} onClose={onClose} />
				</ButtonSecondary> */}
			</Flex>
		</List>
	);
}
