import { useMemo } from 'react';
import { isEmpty } from 'lodash-es';
import { Box, Center, CenterProps, Text } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { CTAButton } from '@/app-components/common';
import AppLayout from '@/app-components/layout/PageLayout';
import { NAVIGATE_PATHS } from '@/app-constants/router';
import { TelegramCore } from '@/app-cores/telegram';
import { useQueryUserProfile } from '@/app-hooks/api/user/useQueryUserProfile';
import { FiBlockChainIcon, FiDappIcon, FiInfoIcon, FiSecurityIcon, FiTobiLogoIcon } from '@/assets/icons';
import Warning from '../../../../app-components/common/Warning';
import { IntroItem } from '../../components';
import { DATADOG_ACTIONS, dataDogAddAction } from '@/app-services/monitor/datadog';
import { FeatureConfigWrap } from '@/app-helpers/components';
import { FeatureConfig } from '@/app-hooks/api/configuration';
import { MpcWallet } from '@/app-cores/mpc-wallet/wallet';

interface WellComeProps {
	fromBotRedirect: boolean;
	invitationCode: string;
	onClick: (type: 'create' | 'import') => void;
}

interface RecoveryMyAccount extends CenterProps {}
const RecoveryMyAccount: React.FunctionComponent<RecoveryMyAccount> = (props) => {
	const { t } = useTranslation();
	return (
		<FeatureConfigWrap feature={FeatureConfig.RECOVERY_MY_ACCOUNT}>
			<Center {...props}>
				<Link
					onClick={() => {
						dataDogAddAction(DATADOG_ACTIONS.RECOVERY_NEW_TG_WALLET.RECOVERY);
					}}
					to={NAVIGATE_PATHS.Recovery.NewTGAccount}
				>
					{t('onboarding.recoveryMyAccount')}
				</Link>
			</Center>
		</FeatureConfigWrap>
	);
};

const Policy = () => {
	const { t } = useTranslation();
	return (
		<Box fontSize="xs" mt={4} px={4} textAlign="center">
			{t('onboarding.policy.text')}{' '}
			<Link to={NAVIGATE_PATHS.AccountSettings.TermOfService}>
				<Box as="strong" textDecoration="underline">
					{t('onboarding.policy.termOfUse')}
				</Box>
			</Link>{' '}
			{t('onboarding.policy.and')}{' '}
			<Link to={NAVIGATE_PATHS.AccountSettings.PrivacyPolicy}>
				<Box as="strong" textDecoration="underline">
					{t('onboarding.policy.agreement')}
				</Box>
			</Link>
		</Box>
	);
};
const WellCome: React.FunctionComponent<WellComeProps> = ({ fromBotRedirect, onClick }) => {
	const { t } = useTranslation();
	const { data: user, isLoading: isProfileLoading } = useQueryUserProfile();
	const localKeyshare = MpcWallet.getKeyShares();

	const itemInfos = [
		{
			img: <FiSecurityIcon />,
			title: t('onboarding.items.1.title'),
			description: t('onboarding.items.1.description'),
		},
		{
			img: <FiBlockChainIcon />,
			title: t('onboarding.items.2.title'),
			description: t('onboarding.items.2.description'),
		},
		{
			img: <FiDappIcon />,
			title: t('onboarding.items.3.title'),
			description: t('onboarding.items.3.description'),
		},
	];

	const Footer = useMemo(() => {
		if (isProfileLoading || isEmpty(user)) return null;
		if (user.isLegacyKeyshare || (user.defaultWallet && !user.backupType && !localKeyshare)) {
			return (
				<Box pb={4} px={4}>
					<CTAButton
						size="lg"
						fontWeight="medium"
						colorScheme="cyan"
						width="100%"
						onClick={() => {
							dataDogAddAction(DATADOG_ACTIONS.CREATE_NEW_ACCOUNT);
							onClick?.('create');
						}}
					>
						{t('button.create')}
					</CTAButton>
					<Policy />
				</Box>
			);
		}
		//Hotfix for case user call api callback/keygen failed but profile has already default wallet

		if (!user.referral && !user.defaultWallet) {
			return (
				<Box pb={4} px={4}>
					<a href={`https://t.me/${import.meta.env.VITE_BOT_USERNAME}?start=`}>
						<CTAButton size="lg" fontWeight="medium" colorScheme="cyan" width="100%">
							{t('button.joinWaitList')}
						</CTAButton>
					</a>
					<RecoveryMyAccount fontWeight={600} fontSize="sm" pb={4} />
				</Box>
			);
			// if (fromBotRedirect) {
			// 	return (
			// 		<Box pb={4} px={4}>
			// 			<a href={`https://t.me/${import.meta.env.VITE_BOT_USERNAME}?start`}>
			// 				<CTAButton size="lg" fontWeight="medium" colorScheme="cyan" width="100%">
			// 					{t('button.joinWaitList')}
			// 				</CTAButton>
			// 			</a>
			// 			<RecoveryMyAccount fontWeight={600} fontSize="sm" pb={4} />
			// 		</Box>
			// 	);
			// } else {
			// 	return (
			// 		// <Box marginTop="auto" pb={4}>
			// 		// 	<InputInvite
			// 		// 		onSucess={async () => {
			// 		// 			await queryClient.invalidateQueries({
			// 		// 				queryKey: ['query-user-profile', TelegramCore.getUserId()],
			// 		// 			});
			// 		// 			setIsReferral(true);
			// 		// 		}}
			// 		// 	/>
			// 		// </Box>
			// 		<Box
			// 			marginTop={'auto'}
			// 			p={4}
			// 			gap={2}
			// 			display={'flex'}
			// 			flexDirection={'column'}
			// 			backgroundColor={'gray.100'}
			// 		>
			// 			<Warning
			// 				background="white"
			// 				status="warning"
			// 				icon={
			// 					<FiInfoIcon
			// 						fill="#FF4D00"
			// 						style={{ minWidth: '18px' }}
			// 						width={'18px'}
			// 						height={'18px'}
			// 					/>
			// 				}
			// 				msg={t('onboarding.unauthorized')}
			// 			/>
			// 			<CTAButton
			// 				onClick={() => TelegramCore.closeWebApp()}
			// 				size="lg"
			// 				fontWeight="medium"
			// 				colorScheme="cyan"
			// 				width="100%"
			// 			>
			// 				{t('onboarding.exitBtn')}
			// 			</CTAButton>
			// 			<RecoveryMyAccount fontWeight={600} fontSize="sm" pt={2} />
			// 		</Box>
			// 	);
			// }
		}

		return (
			<Box pb={4} px={4}>
				{!user?.defaultWallet ? (
					<>
						<CTAButton
							size="lg"
							fontWeight="medium"
							colorScheme="cyan"
							width="100%"
							onClick={() => {
								dataDogAddAction(DATADOG_ACTIONS.CREATE_NEW_ACCOUNT);
								onClick?.('create');
							}}
						>
							{t('button.create')}
						</CTAButton>
						<Center>
							<RecoveryMyAccount fontWeight={600} fontSize="sm" pt={4} />
						</Center>
					</>
				) : (
					<CTAButton
						size="lg"
						fontWeight="medium"
						width="100%"
						onClick={() => {
							dataDogAddAction(DATADOG_ACTIONS.RECOVERY_ACCOUNT);
							onClick?.('import');
						}}
					>
						{t('button.haveWallet')}
					</CTAButton>
				)}

				{!user?.defaultWallet && <Policy />}
			</Box>
		);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [fromBotRedirect, isProfileLoading, t, user]);
	return (
		<AppLayout
			px={2}
			header={
				<>
					<Center pt={6}>
						<FiTobiLogoIcon />
					</Center>
					<Text px={8} mt={8} fontSize="28px" fontWeight={500} textAlign="center" lineHeight="35px">
						{t('onboarding.title')}
					</Text>
					<Box as="hr" mt={6} />
				</>
			}
			footer={Footer}
		>
			<Box px={4} mt={-6} mb={4}>
				{itemInfos.map((item, i) => (
					<IntroItem key={i} img={item.img} description={item.description} title={item.title} />
				))}
			</Box>
		</AppLayout>
	);
};
export default WellCome;
