import wethAbi from '@/app-constants/json/weth.abi.json';
import { TokenInfo } from '@/app-cores/api/bff';
import { getWrapNativeToken } from '@/app-helpers/token';
import { getSignerContract } from '@/app-helpers/web3';
import { bufferGas } from '@/app-hooks/transactions';
import { useMutation } from '@tanstack/react-query';
import { useTransactionWatcherStore } from '../../app-store';
import { TransactionType } from '../../app-types';
import { isNativeToken } from '@/app-helpers/address';
import { TransactionResponse } from 'ethers';
import { queryClient } from '@/app-cores/query-client';
import { QUERY_KEYS } from '@/app-constants';

type WrapParams = {
	tokenIn: TokenInfo | undefined;
	tokenOut: TokenInfo | undefined;
	amount: string | undefined; // big number
	waitUtilCompleted?: boolean;
};

export default function useWrapUnWrapToken() {
	const { addPendingEvmTransaction } = useTransactionWatcherStore();
	const getHexValue = (amount) => `0x${BigInt(amount ?? '0')?.toString(16)}`;

	return useMutation({
		mutationKey: ['mutation-useWrapUnWrapToken'],
		mutationFn: async ({
			tokenIn,
			tokenOut,
			amount,
			waitUtilCompleted,
		}: WrapParams): Promise<TransactionResponse> => {
			if (!amount || !tokenIn || !tokenOut) throw new Error();
			const isWrap = isNativeToken(tokenIn?.address);
			const chainId = tokenIn?.chainId;
			const { contract, provider } = getSignerContract(getWrapNativeToken(chainId).address, chainId, wethAbi);
			const hexValue = getHexValue(amount);

			let gasLimit = 200_000n;
			try {
				gasLimit = await (isWrap
					? contract.deposit.estimateGas({ value: hexValue })
					: contract.withdraw.estimateGas(hexValue));
				gasLimit = bufferGas(gasLimit);
			} catch (error) {
				console.log('failed to estimate gas', error, gasLimit);
			}

			const gas = await provider.getFeeData();

			const payload = { gasLimit, gasPrice: gas.gasPrice };
			const txReceipt = await (isWrap
				? contract.deposit({ value: hexValue, ...payload })
				: contract.withdraw(hexValue, payload));

			if (waitUtilCompleted) {
				await txReceipt.wait();
			}
			return txReceipt;
		},
		onSuccess: (receipt, { tokenIn }) => {
			addPendingEvmTransaction({
				transaction: receipt,
				metadata: {
					transactionType: TransactionType.ContractInteraction,
					chainId: BigInt(receipt.chainId?.toString() || tokenIn.chainId),
				},
			});
			queryClient.invalidateQueries({
				queryKey: [QUERY_KEYS.GET_PORTFOLIO_BALANCE],
			});
		},
	});
}
