import { StorageImpl } from './storage-impl';
import { IStorage } from './types';

export interface JsonStorageOptions {
	storageKey: string;
}

export class JsonStorage {
	public storage: IStorage;

	public storageKey: string;

	constructor(options: JsonStorageOptions) {
		this.storage = new StorageImpl();
		this.storageKey = options.storageKey;

		try {
			if (!this.storage.getItem(this.storageKey)) {
				this.resetStore();
			}
		} catch (e) {
			// Storage is not available
		}
	}

	private getItemAsString = (key: string): string => {
		const value = this.storage.getItem(key);
		return value === undefined || value === null ? '' : value;
	};

	public toJSON(): string {
		return this.getItemAsString(this.storageKey);
	}

	public resetStore(): Record<string, unknown> {
		this.storage.setItem(this.storageKey, JSON.stringify({}));
		return this.getStore();
	}

	public getStore(): Record<string, unknown> {
		return JSON.parse(this.getItemAsString(this.storageKey));
	}

	public setStorageKey = (key: string) => {
		this.storageKey = key;
	};

	public get<T>(key: string): T {
		const store = JSON.parse(this.getItemAsString(this.storageKey));
		return store[key];
	}

	public set<T>(key: string, value: T): void {
		const store = JSON.parse(this.getItemAsString(this.storageKey));
		store[key] = value;
		this.storage.setItem(this.storageKey, JSON.stringify(store));
	}
}
