import { TOUR_GUIDE_STEPS_TARGET } from '@/app-views/wallet/components/TourGuide/stepConfigs';
import { motion } from 'framer-motion';
import { useRef } from 'react';

export const CopilotFallback = () => {
	const initIconPosition = useRef({
		x: document.body.clientWidth - 60,
		y: document.body.clientHeight - 90,
	});
	return (
		<motion.div
			style={{
				x: initIconPosition.current.x,
				y: initIconPosition.current.y,
				position: 'absolute',
			}}
			id={TOUR_GUIDE_STEPS_TARGET.HOME_PAGE.COPILOT}
		></motion.div>
	);
};
