import { Block } from '@/app-components/common';
import { Avatar, Box, Button, Flex, Text } from '@chakra-ui/react';
import { StoreSession } from '../hooks';
import { paringTrackingManager } from '../utils/HelperUtil';
import { useTranslation } from 'react-i18next';

interface ConnectionItemProps {
	session: StoreSession;
	onClick: (ss: StoreSession) => void;
}
export const ConnectionItem: React.FC<React.PropsWithChildren<ConnectionItemProps>> = ({ session, onClick }) => {
	const { t } = useTranslation();
	const activeTimes = paringTrackingManager.getParingActiveTimes();

	const activeTime = activeTimes[session.type]?.[session.id];
	return (
		<Block mb={4} backgroundColor="white">
			<Flex gap={3}>
				<Avatar
					name={session.metadata.name}
					src={session.metadata.icons[0]}
					border="1px solid rgba(0, 0, 0, 0.08)"
					loading="lazy"
				></Avatar>
				<Box>
					<Text fontWeight={600}>{session.metadata.name}</Text>
					<Text color="gray.400">{session.metadata.url}</Text>
				</Box>
			</Flex>
			<Box as="hr" my={3} />
			{activeTime && (
				<Text fontSize="xs" color="gray.400">
					{t('dapp.activeOn')}: {activeTime}
				</Text>
			)}
			<Button mt={3} size="sm" fontWeight="medium" width="100%" onClick={() => onClick(session)}>
				{t('button.disconnect')}
			</Button>
		</Block>
	);
};
