import { LocalLoader } from '@/app-components/common';
import NoData from '@/app-components/common/NoData';
import CursorPagination, { usePagination } from '@/app-components/common/Pagination';
import ChainSelect from '@/app-components/common/Select/ChainSelect';
import TobiTabs from '@/app-components/common/TobiTabs';
import AppLayout from '@/app-components/layout/PageLayout';
import { ChainId, EVM_CHAINS } from '@/app-constants/chains';
import { ChainType } from '@/app-contexts/wallet-provider/type';
import { isSolanaChain } from '@/app-helpers/token';
import { useGetLimitOrders, useGetTotalLimitOrders } from '@/app-hooks/limit';
import { FormatOrder, LimitOrderStatus } from '@/app-hooks/limit/type';
import { useLimitStore } from '@/app-store/swap/limit';
import { BASE_BACKGROUND_COLOR, BASE_BORDER_COLOR, colors } from '@/app-theme/theme';
import Header, { NavigateHeader } from '@/app-views/swap/components/Header';
import OrderItem from '@/app-views/swap/components/LimitOrder/OrderItem';
import TradeLayout from '@/app-views/swap/components/TradeLayout';
import { FiRefreshIcon } from '@/assets/icons/fi-refresh-icon';
import { Flex, Spinner, Text, Center } from '@chakra-ui/react';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

const chainOptions = [ChainId.SOL, ...EVM_CHAINS.map((e) => e.id)];

export default function ListOrders() {
	const { t } = useTranslation();
	const [activeTab, setActiveTab] = useState(LimitOrderStatus.OPEN);
	const { tokenOut } = useLimitStore();

	const [searchParams] = useSearchParams();
	const [chainId, setChainId] = useState(searchParams.get('chainId'));

	const isOpenOrder = activeTab === LimitOrderStatus.OPEN;
	const tabs = [
		{ label: t('limit.openOrders'), value: LimitOrderStatus.OPEN },
		{ label: t('limit.closedOrders'), value: LimitOrderStatus.CLOSED },
	];

	const pageSize = 5;
	const [lastOrder, setLastOrder] = useState<FormatOrder>();
	const [page, setPage] = useState(1);

	const respSingleChain = useGetLimitOrders({
		status: activeTab,
		pageSize,
		chainId: chainId as ChainId,
		cursor: lastOrder?.id,
		page,
	});
	const respAllChain = useGetTotalLimitOrders(true);

	const isAllChain = !chainId;

	const { data, refetch, isRefetching, isFetched } = isAllChain ? respAllChain : respSingleChain;

	const chainHasOrder = respAllChain?.data?.chainHasOrder || ChainId.SOL;

	const orders = data?.orders ?? [];
	const totalOrder = data?.total || 0;

	const { onBack, onNext, reset } = usePagination<FormatOrder>({
		page,
		setPage,
		...(isSolanaChain(chainId)
			? {
					data: orders,
					cursor: lastOrder,
					setCursor: setLastOrder,
			  }
			: {}),
	});

	useEffect(() => {
		reset();
		if (activeTab === LimitOrderStatus.CLOSED && !chainId) {
			setChainId(chainHasOrder);
		}
	}, [activeTab, reset, chainId, chainHasOrder]);

	const refetchOrder = () => {
		try {
			refetch();
		} catch (error) {}
	};

	const renderOrders = () => {
		return (
			<Flex direction={'column'} gap={3}>
				{!isFetched ? (
					<LocalLoader height="150px" />
				) : !totalOrder ? (
					<NoData msg={isOpenOrder ? t('limit.noOpenOrder') : t('limit.noOrderHistory')} />
				) : (
					orders?.map((e) => <OrderItem key={e.id} order={e} />)
				)}
			</Flex>
		);
	};

	return (
		<TradeLayout header={<NavigateHeader tokenOut={tokenOut} />} action={null}>
			<Flex direction={'column'} gap={'12px'} pb={6}>
				<Flex align={'center'} gap={'4px'} justify={'space-between'}>
					<Flex align={'center'} gap={'12px'}>
						<TobiTabs
							tabs={tabs}
							value={activeTab}
							onChange={setActiveTab}
							mode="underline"
							itemStyle={{ fontSize: { xs: '14px', base: '12px' } }}
						/>
						{isRefetching ? <Spinner size={'sm'} /> : <FiRefreshIcon size={18} onClick={refetchOrder} />}
					</Flex>
					<ChainSelect
						allChain={isOpenOrder}
						logoSize="18px"
						style={{
							border: `1px solid ${BASE_BORDER_COLOR}`,
							transform: { xs: 'scale(1)', base: 'scale(0.9)' },
							fontSize: { xs: '14px', base: '13px' },
							transformOrigin: 'right center',
							minWidth: 'fit-content',
						}}
						badgeInfo={respAllChain?.data?.orderCountMap}
						value={chainId}
						onChange={setChainId}
						chainIds={chainOptions}
					/>
				</Flex>
				<Text fontSize={'10px'} color={colors.orange[100]} fontWeight={'500'}>
					Due to the delay in transaction confirmation, your order may not update immediately.
				</Text>
				{renderOrders()}
				{!isOpenOrder && (
					<CursorPagination
						totalCount={totalOrder}
						pageSize={pageSize}
						currentPage={page}
						onNext={onNext}
						onBack={onBack}
					/>
				)}
			</Flex>
		</TradeLayout>
	);
}
