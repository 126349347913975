import { TAppRequest, RpcMethod } from '../types';
import { SessionCrypto, TonProofItemReplySuccess } from '@tonconnect/protocol';
import { isAndroid, isBrowser, isMacOs, isIOS, isWindows, isIPad13 } from 'react-device-detect';
import {
	ConnectRequest,
	DAppManifest,
	DeviceInfo,
	TON_CONNECT_KEY,
	TonConnectMessageRequest,
	TonConnectTAppRequest,
} from '../types';
import { Address } from '@ton/core';
import { sha256_sync } from '@ton/crypto';
import { MpcWallet } from '@/app-cores/mpc-wallet/wallet';

const getManifestResponse = async (manifestUrl: string) => {
	try {
		return await fetch(manifestUrl);
	} catch (e) {
		return await fetch(`https://manifest-proxy.nkuznetsov.workers.dev/${manifestUrl}`);
	}
};

export const getManifest = async (request: ConnectRequest) => {
	const response = await getManifestResponse(request.manifestUrl);
	if (response.status != 200) {
		throw new Error(`Failed to load Manifest: ${response.status}`);
	}
	const manifest: DAppManifest = await response.json();
	const isValid =
		manifest &&
		typeof manifest.url === 'string' &&
		typeof manifest.name === 'string' &&
		typeof manifest.iconUrl === 'string';

	if (!isValid) {
		throw new Error('Manifest is not valid');
	}
	return manifest;
};
function getPlatform(): DeviceInfo['platform'] {
	if (isMacOs) return 'mac';
	if (isWindows) return 'windows';
	if (isIOS) return 'iphone';
	if (isIPad13) return 'ipad';
	if (isBrowser) return 'browser';
	if (isAndroid) return 'android';
	return 'linux';
}
export const getDeviceInfo = (appVersion: string): DeviceInfo => {
	return {
		platform: getPlatform(),
		appName: 'tobi',
		appVersion: appVersion,
		maxProtocolVersion: 2,
		features: [
			'SendTransaction',
			{
				name: 'SendTransaction',
				maxMessages: 4,
			},
		],
	};
};
export const lastEventIdStored = {
	getLastEventId: () => {
		const result = localStorage.getItem(TON_CONNECT_KEY.LAST_EVENT_ID);
		return result ?? undefined;
	},
	setLastEventId: (lastEventId: string) => {
		localStorage.setItem(TON_CONNECT_KEY.LAST_EVENT_ID, lastEventId);
	},
};

export const decryptTonConnectMessage = ({
	message,
	from,
	connection,
}: TonConnectMessageRequest): TonConnectTAppRequest => {
	const sessionCrypto = new SessionCrypto(connection.sessionKeyPair);

	const request: TAppRequest<RpcMethod> = JSON.parse(
		sessionCrypto.decrypt(Buffer.from(message, 'base64'), Buffer.from(from, 'hex')),
	);
	return { request, connection, clientSessionId: from };
};
export interface ConnectProofPayload {
	timestamp: number;
	bufferToSign: Buffer;
	domainBuffer: Buffer;
	payload: string;
	origin: string;
}
export const tonConnectProofPayload = (origin: string, wallet: string, payload: string): ConnectProofPayload => {
	const timestamp = Math.round(Date.now() / 1000);
	const timestampBuffer = Buffer.allocUnsafe(8);
	timestampBuffer.writeBigInt64LE(BigInt(timestamp));

	const domainBuffer = Buffer.from(new URL(origin).host);

	const domainLengthBuffer = Buffer.allocUnsafe(4);
	domainLengthBuffer.writeInt32LE(domainBuffer.byteLength);

	const address = Address.parse(wallet);

	const addressWorkchainBuffer = Buffer.allocUnsafe(4);
	addressWorkchainBuffer.writeInt32BE(address.workChain);

	const addressBuffer = Buffer.concat([addressWorkchainBuffer, address.hash]);

	const messageBuffer = Buffer.concat([
		Buffer.from('ton-proof-item-v2/', 'utf8'),
		addressBuffer,
		domainLengthBuffer,
		domainBuffer,
		timestampBuffer,
		Buffer.from(payload),
	]);

	const bufferToSign = Buffer.concat([
		Buffer.from('ffff', 'hex'),
		Buffer.from('ton-connect', 'utf8'),
		Buffer.from(sha256_sync(messageBuffer)),
	]);

	return {
		timestamp,
		bufferToSign,
		domainBuffer,
		payload,
		origin,
	};
};

export const toTonProofItemReply = async (options: {
	proof: ConnectProofPayload;
}): Promise<TonProofItemReplySuccess> => {
	const result: TonProofItemReplySuccess = {
		name: 'ton_proof',
		proof: await toTonProofItem(options.proof),
	};
	return result;
};

export const toTonProofItem = async (proof: ConnectProofPayload, stateInit?: string) => {
	const signature = await MpcWallet.signEddsaMessage(sha256_sync(proof.bufferToSign));
	return createTonProofItem(signature, proof, stateInit);
};
export const createTonProofItem = (signature: Uint8Array, proof: ConnectProofPayload, stateInit?: string) => {
	return {
		timestamp: proof.timestamp, // 64-bit unix epoch time of the signing operation (seconds)
		domain: {
			lengthBytes: proof.domainBuffer.byteLength, // AppDomain Length
			value: proof.domainBuffer.toString('utf8'), // app domain name (as url part, without encoding)
		},
		signature: Buffer.from(signature).toString('base64'), // base64-encoded signature
		payload: proof.payload, // payload from the request,
		stateInit: stateInit, // state init for a wallet
	};
};

export function verifyConnectRequest(request: ConnectRequest) {
	if (!(request && request.manifestUrl && request.items?.length)) {
		throw new Error('Wrong request data');
	}
}
