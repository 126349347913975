import {
	Box,
	Button,
	Center,
	Drawer,
	DrawerBody,
	DrawerCloseButton,
	DrawerContent,
	DrawerHeader,
	DrawerOverlay,
	Text,
} from '@chakra-ui/react';
import { useRef, useState } from 'react';
import { isIOS } from 'react-device-detect';
import { InputPassword } from '@/app-components/common';
import { DATADOG_ACTIONS, dataDogAddAction } from '@/app-services/monitor/datadog';

interface CreatePasswordProps {
	onCreatePassword: (password: string) => void;
	isOpen: boolean;
	onClose: () => void;
}

export const CreatePassword: React.FunctionComponent<CreatePasswordProps> = ({ onCreatePassword, isOpen, onClose }) => {
	const [values, setValues] = useState({
		password: '',
		confirmPassword: '',
	});
	const [isError, setIsError] = useState(false);
	const bodyContentRef = useRef<HTMLDivElement>(null);
	const isValid = values.password && values.password === values.confirmPassword;
	const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const { name, value } = e.target;
		setValues((pre) => ({
			...pre,
			[name]: value,
		}));
	};
	return (
		<Drawer placement="bottom" onClose={onClose} isOpen={isOpen} closeOnOverlayClick={false}>
			<DrawerOverlay />
			<DrawerContent>
				<DrawerHeader borderBottomWidth="1px" borderBottomColor="rgba(0, 0, 0, 0.08)">
					<Center>
						<Text fontSize={'16px'} fontWeight={'500'}>
							Protect your key share
						</Text>
					</Center>
					<DrawerCloseButton />
				</DrawerHeader>
				<DrawerBody px={4} py={5} ref={bodyContentRef}>
					<InputPassword
						label="Password"
						value={values.password}
						name="password"
						onChange={handleChange}
						onBlur={(e) => {
							isIOS && (bodyContentRef.current.style.paddingBottom = '16px');
							if (e.target.value === '' && values.confirmPassword === '') return setIsError(false);
							if (e.target.value && values.confirmPassword) {
								setIsError(values.confirmPassword !== values.password);
							}
						}}
						onFocus={() => isIOS && (bodyContentRef.current.style.paddingBottom = '100px')}
					/>
					<Box mt={5}>
						<InputPassword
							isInvalid={!!isError}
							label="Confirm Password"
							value={values.confirmPassword}
							name="confirmPassword"
							onChange={handleChange}
							onBlur={() => {
								setIsError(values.confirmPassword !== values.password);
								isIOS && (bodyContentRef.current.style.paddingBottom = '16px');
							}}
							onFocus={() => isIOS && (bodyContentRef.current.style.paddingBottom = '200px')}
						/>
					</Box>
					<Box fontSize="xs" color="red" height={3} mt={2}>
						{isError && (
							<Text fontSize="xs" color="red">
								Password and confirm password does not match.
							</Text>
						)}
					</Box>

					<Box pt={5}>
						<Text fontSize="xs">
							<Text as="strong" fontWeight={500}>
								Important:{' '}
							</Text>
							This password will be used to encrypt your recovery key share. Please protect it, as it will
							be required to access your recovery key, and we cannot help you retrieve this password.
						</Text>
						<Button
							isDisabled={!isValid}
							mt={5}
							size="lg"
							fontWeight="medium"
							colorScheme="cyan"
							width="100%"
							onClick={() => {
								if (!isValid) return;
								dataDogAddAction(DATADOG_ACTIONS.BACKUP_CREATE_PASSWORD);
								onCreatePassword(values.password);
							}}
						>
							Create Password
						</Button>
					</Box>
				</DrawerBody>
			</DrawerContent>
		</Drawer>
	);
};
