import { isEmpty } from 'lodash-es';
import { useQuery } from '@tanstack/react-query';
import { isNativeTobiToken, isNativeToken } from '@/app-helpers/address';
import { TSentSolTransaction } from '@/app-types';
import { SolWallet } from '@/app-cores/mpc-wallet/solana/SolWallet';
import { buildSLPTransaction, buildSolTransaction } from '@/app-helpers/solana';
import { getTokenId } from '@/app-helpers/token';

export const estimateSolGasFee = async (sendData: TSentSolTransaction) => {
	const { connection, fromPubKey, blockHash } = await SolWallet.init('mainnet-beta', {
		commitment: 'confirmed',
	});
	if (isNativeToken(sendData.token.address)) {
		const transferTransaction = await buildSolTransaction({
			connection: connection,
			fromPubKey: fromPubKey,
			sendData: sendData,
		});
		transferTransaction.feePayer = fromPubKey;
		transferTransaction.recentBlockhash = blockHash;
		const gasFee = await transferTransaction.getEstimatedFee(connection);
		return { gasFee, connection };
	} else {
		const transferTransaction = await buildSLPTransaction({
			connection: connection,
			fromPubKey: fromPubKey,
			sendData: sendData,
		});
		transferTransaction.feePayer = fromPubKey;
		transferTransaction.recentBlockhash = blockHash;
		const gasFee = await transferTransaction.getEstimatedFee(connection);
		return { gasFee, connection };
	}
};

export const useEstimateSolGasFee = (sendData: TSentSolTransaction) => {
	const response = useQuery({
		queryKey: ['estimate-transfer-sol-asset', sendData.to, getTokenId(sendData.token), sendData?.message],
		queryFn: async () => {
			const { gasFee } = await estimateSolGasFee(sendData);
			return gasFee;
		},
		enabled: !isEmpty(sendData.amount) && !isEmpty(sendData.to) && !isEmpty(sendData.token),
		staleTime: 1000 * 30,
	});
	return response;
};
