import React from 'react';

interface IProps extends React.SVGAttributes<HTMLOrSVGElement> {}

export const FiGoogleDriverIcon: React.FC<IProps> = ({ width = 48, height = 48, ...rest }) => {
	return (
		<svg width={width} height={height} viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
			<g clipPath="url(#clip0_4960_12706)">
				<path
					d="M3.61529 39.2684L5.7256 42.9286C6.16407 43.6991 6.79529 44.305 7.53676 44.7462L15.1065 39.3792L15.1203 31.6739L7.5662 28.2705L7.67589e-06 31.6468C-0.00149232 32.499 0.216851 33.3517 0.655414 34.1222L3.61529 39.2684Z"
					fill="#0066DD"
				/>
				<path
					d="M24.0275 16.2947L24.4223 6.25747L16.4907 3.19531C15.7477 3.63388 15.1143 4.23753 14.673 5.00647L0.664219 29.1739C0.223031 29.9429 0.0015 30.7948 0 31.6469L15.1203 31.6739L24.0275 16.2947Z"
					fill="#00AD3C"
				/>
				<path
					d="M24.0276 16.2949L31.2935 12.386L31.611 3.22245C30.8695 2.78127 30.0176 2.55983 29.138 2.55823L18.9662 2.54005C18.0864 2.53845 17.2338 2.78436 16.4907 3.19545L24.0276 16.2949Z"
					fill="#00831E"
				/>
				<path
					d="M32.8795 31.7055L15.1201 31.6738L7.53662 44.7461C8.27809 45.1873 9.13 45.4088 10.0097 45.4104L37.9409 45.4602C38.8206 45.4618 39.6733 45.2159 40.4163 44.8048L40.4458 36.0242L32.8795 31.7055Z"
					fill="#0084FF"
				/>
				<path
					d="M40.4164 44.8056C41.1595 44.367 41.7929 43.7634 42.234 42.9945L43.1165 41.484L47.3357 34.2063C47.7769 33.4374 47.9984 32.5856 47.9999 31.7333L39.2605 27.1348L32.9072 31.7064L40.4164 44.8056Z"
					fill="#FF4131"
				/>
				<path
					d="M40.3833 17.1491L33.4221 5.04037C32.9837 4.26975 32.3524 3.66384 31.6109 3.22266L24.0273 16.2951L32.8795 31.7062L47.9723 31.7332C47.9738 30.881 47.7554 30.0283 47.3169 29.2577L40.3833 17.1491Z"
					fill="#FFBA00"
				/>
			</g>
			<defs>
				<clipPath id="clip0_4960_12706">
					<rect width="48" height="48" fill="white" />
				</clipPath>
			</defs>
		</svg>
	);
};
