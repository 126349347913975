import React from 'react';

interface IProps extends React.SVGAttributes<HTMLOrSVGElement> {}

export const FiTriangleIcon: React.FC<IProps> = ({ width = 14, height = 7, fill = '#00E9DB', ...rest }) => {
	return (
		<svg width={width} height={height} viewBox="0 0 14 7" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M6.99988 6.29278L1.20711 0.5L6.99988 0.5L12.7927 0.500001L6.99988 6.29278Z"
				fill={fill}
				stroke="white"
			/>
		</svg>
	);
};
