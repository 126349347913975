import { Toast } from '@/app-components/common';
import { CHAIN_CONFIG, ChainId, TobiChainName, TON_TCAT_ADDRESS, TON_TONKE_ADDRESS } from '@/app-constants/chains';
import { NAVIGATE_PATHS } from '@/app-constants/router';
import { ActivitiesServiceAPI, IActivity, IBalanceChange, TxStatus, WalletActivity } from '@/app-cores/api/activities';
import { axiosBotAPI } from '@/app-cores/api/axios';
import { BffServiceAPI, TokenInfo } from '@/app-cores/api/bff';
import i18n from '@/app-cores/i18n';
import { toStartParam } from '@/app-cores/telegram';
import { Actions } from '@/app-features/app-bot-connector/hooks';
import { compareAddress, isNativeToken } from '@/app-helpers/address';
import { useNavigateToConfirmSwap } from '@/app-helpers/navigate';
import { formatUnits, formatUsd } from '@/app-helpers/number';
import { uniqueId } from '@/app-helpers/random';
import {
	getNativeTobiId,
	getNativeToken,
	getTobiChainName,
	getTokenInfo,
	isSolanaChain,
	isTonChain,
} from '@/app-helpers/token';
import { parseUrlSearchParams, toQueryString } from '@/app-helpers/url';
import { calculatePriceImpact, getPayloadSwapTracking } from '@/app-hooks/swap';
import { DedustSwap, useExecuteRouteDedust } from '@/app-hooks/swap/dedust';
import {
	calcAmount,
	calcRateSwap,
	combineRouteMultiProvider,
	filterRouteSolTon,
	formatStepsSwap,
	getMinAmount,
} from '@/app-hooks/swap/helper';
import { RetroBridgeSwap, RouteRetroBridge, useExecuteRouteRetroBridge } from '@/app-hooks/swap/retrobridge';
import { RocketXSwap, RouteRocketX, useExecuteRouteRocketX } from '@/app-hooks/swap/rocketx';
import {
	ArgsGetRoute,
	ExtractRouteInfo,
	InternalStep,
	SwapAbstract,
	SwapProvider,
	SwapStatusResponse,
	SwapStepInfo,
	UsdRouteInfo,
} from '@/app-hooks/swap/type';
import { MetadataSolTcat, useTransactionWatcherStore } from '@/app-store';
import { useUserSettingsStore } from '@/app-store/settings';
import { GenericRoute, RouteExecuting, SelectedRoute, useSwapStore } from '@/app-store/swap';
import { Button, Flex, useInterval } from '@chakra-ui/react';
import { useMutation } from '@tanstack/react-query';
import { parseUnits } from 'ethers';
import { useEffect, useMemo, useRef } from 'react';
import { toast } from 'react-toastify';
//
type Route = RouteRocketX | RouteRetroBridge;
const isMock = false; // to debug
const RETRO_MOCK_ID = 'e3e488ee-facd-4165-b119-cf6009aa3130';
const SOL_MOCK_HASH = '23DsofQmDBnroRAZ38ofQh2AX8vEV4i6hcA16rTBT72ZqypXkXjfXTRsmQ1HbqUadY742vbFgyi85WtzBWt1BAJw';
const ROCKETX_MOCK_ID = 'a95c3ae9-3d0c-4529-adfa-7b06a4803c3b';

class Sol2Tcat extends SwapAbstract<Route> {
	currentRoute: SelectedRoute;
	getSwapProvider(route: SelectedRoute) {
		return route.provider === SwapProvider.RETROBRIDGE ? RetroBridgeSwap : RocketXSwap;
	}
	isTon2Tcat({ tokenIn, tokenOut }: { tokenIn: TokenInfo; tokenOut: TokenInfo }) {
		return (
			isNativeToken(tokenIn?.address) &&
			isTonChain(tokenIn.chainId) &&
			isTonChain(tokenOut?.chainId) &&
			(compareAddress(tokenOut?.address, TON_TCAT_ADDRESS) ||
				compareAddress(tokenOut?.address, TON_TONKE_ADDRESS))
		);
	}
	isMyRoute(paramsSwap: ArgsGetRoute) {
		const { tokenIn, tokenOut } = paramsSwap;
		return (
			isNativeToken(tokenIn?.address) &&
			isSolanaChain(tokenIn.chainId) &&
			//
			isTonChain(tokenOut?.chainId) &&
			(compareAddress(tokenOut?.address, TON_TCAT_ADDRESS) ||
				compareAddress(tokenOut?.address, TON_TONKE_ADDRESS))
		);
	}

	deductTonGas(amountOut: bigint) {
		const minTon = getMinAmount(SwapProvider.DEDUST, true) + BigInt(CHAIN_CONFIG[ChainId.TON].minForGas);
		let deductedAmount = amountOut - minTon;
		deductedAmount = deductedAmount < 0n ? 0n : deductedAmount;
		return deductedAmount;
	}
	async getRoute(paramsSwap: ArgsGetRoute, signal: AbortSignal): Promise<SelectedRoute> {
		const { tokenIn: sol, tokenOut: tcat, slippage } = paramsSwap;
		const ton = await BffServiceAPI.searchExactSingleToken({
			tobiId: getNativeTobiId(ChainId.TON),
			chainId: ChainId.TON,
		});

		const newParams = { ...paramsSwap, tokenOut: getTokenInfo(ton) };

		let routeSolTon;
		try {
			routeSolTon = await combineRouteMultiProvider(
				[RetroBridgeSwap.getRoute(newParams, signal, true), RocketXSwap.getRoute(newParams, signal, false)],
				40_000,
				filterRouteSolTon,
			);
		} catch (error) {
			console.log('tcat err', error);
			routeSolTon = await RocketXSwap.getRoute(newParams, signal, true);
		}

		if (!routeSolTon) return null;

		routeSolTon.allRoutes = []; // dont allow to select other routes
		const { amountOut } = this.getSwapProvider(routeSolTon).extractRoute(routeSolTon, {});

		const deductedAmount = this.deductTonGas(BigInt(amountOut));
		const routeTonTcat = await DedustSwap.getRoute(
			{
				amountIn: deductedAmount.toString(),
				tokenIn: getTokenInfo(ton),
				tokenOut: tcat,
				slippage,
			},
			signal,
		);

		return {
			tokenIn: sol,
			tokenOut: tcat,
			subRoutes: [routeSolTon, routeTonTcat],
			disableReason: routeSolTon.disableReason,
			disabled: routeSolTon.disabled,
			provider: SwapProvider.Sol2Tcat,
			route: routeSolTon,
			id: uniqueId(),
			routerAddress: '',
			timestamp: Date.now(),
			params: paramsSwap,
		};
	}

	async buildRoute({
		route,
		slippage,
		userAmount,
	}: {
		route: SelectedRoute<Route>;
		slippage: number;
		userAmount: string;
	}): Promise<SelectedRoute<GenericRoute>> {
		this.currentRoute = route;
		const newRoute = await this.getSwapProvider(route?.subRoutes[0]).buildRoute({
			slippage,
			route: route?.subRoutes[0] as any,
			userAmount,
		});
		newRoute.feeInfo = route?.feeInfo;
		route.subRoutes[0] = newRoute as any;
		return route;
	}

	extractRoute(params: SelectedRoute<Route>, prices: UsdRouteInfo): ExtractRouteInfo {
		const { tokenIn, tokenOut, subRoutes } = params;
		const [routeSolTon, routeTonTcat] = subRoutes;
		const {
			amountIn,
			gasUsd = 0,
			gasNative,
			tokenOut: ton,
		} = this.getSwapProvider(routeSolTon).extractRoute(routeSolTon as any, prices);

		const { amountOut } = DedustSwap.extractRoute(routeTonTcat, {
			...prices,
			usdPriceNative: ton.priceUsd,
		});

		return {
			tokenIn,
			tokenOut,
			amountIn,
			amountOut,
			gasUsd,
			gasNative,
			dappInfo: { logo: '', domain: '' },
		};
	}

	formatSteps({ status: stepNum, error }: RouteExecuting = {}): SwapStepInfo[] {
		const steps = [
			{
				message: 'SOL to TON',
				id: 1,
				status: TxStatus.Waiting,
				steps: [
					{
						message: i18n.t('tokenTrading.submitTransaction'),
						id: InternalStep.SRC_SUBMIT,
						status: TxStatus.Waiting,
					},
					{
						message: i18n.t('tokenTrading.confirmTransaction'),
						id: InternalStep.SRC_CONFIRM,
						status: TxStatus.Waiting,
					},
				],
			},
			{
				message: `TON to ${this.currentRoute?.tokenOut?.symbol}`,
				id: 2,
				status: TxStatus.Waiting,
				steps: [
					{
						message: i18n.t('tokenTrading.submitTransaction'),
						id: InternalStep.DES_SUBMIT,
						status: TxStatus.Waiting,
					},
					{
						message: i18n.t('tokenTrading.confirmTransaction'),
						id: InternalStep.DES_CONFIRM,
						status: TxStatus.Waiting,
					},
				],
			},
		];
		return formatStepsSwap({ steps, isFailed: error, stepNum });
	}

	isReceiveTon(activity: WalletActivity) {
		const { transactionStatus: status, transactionType, receiveDetail } = activity;
		return (
			transactionType === 'receive' && status === TxStatus.Success && receiveDetail?.chainId === TobiChainName.TON
		);
	}
	isCompletedTonToTcat = (activity: WalletActivity, allActivity: WalletActivity[]) => {
		const { transactionHash, time } = activity;
		const { metadata } = useTransactionWatcherStore.getState();
		const sol2TonInfo = Object.values(metadata).find((e) => e.desHash === transactionHash);
		const tonAmountOut = sol2TonInfo
			? parseUnits(sol2TonInfo?.amountOut?.toString() ?? '0', getNativeToken(ChainId.TON).decimals)
			: 0n;

		return (
			sol2TonInfo && // done sol to ton
			// done ton to tcat
			allActivity.some((e) => {
				const { transactionType, sendDetail, swapDetail } = e;
				const tobiIdTon = getNativeTobiId(ChainId.TON);
				const isSendTon =
					['send', 'swap'].includes(transactionType) &&
					(sendDetail?.idTobi || swapDetail?.tokenIn?.idTobi) === tobiIdTon;

				const tonAmountIn = BigInt((swapDetail?.amountIn || sendDetail?.amount) ?? 0n);

				return (
					isSendTon &&
					+e.time > +time &&
					(tonAmountIn + BigInt(CHAIN_CONFIG[ChainId.TON].minForGas) === tonAmountOut ||
						tonAmountIn === tonAmountOut + getMinAmount(SwapProvider.DEDUST, true))
				);
			})
		);
	};
}
const Sol2TcatSwap = new Sol2Tcat();
export default Sol2TcatSwap;

export const useExecuteRouteSol2Tcat = (tokenIn: TokenInfo, quickSwap?: boolean) => {
	const { mutateAsync: execRouteSolTonRetro } = useExecuteRouteRetroBridge();
	const { mutateAsync: execRouteSolTonRocketX } = useExecuteRouteRocketX(tokenIn);
	const { addPendingSolTransaction, saveMetadata } = useTransactionWatcherStore();

	const { mutateAsync: exeRouteDedust } = useExecuteRouteDedust();
	const { setSelectedRoute, selectedRoute } = useSwapStore(quickSwap);
	const { slippage } = useUserSettingsStore();

	const navigateToConfirm = useNavigateToConfirmSwap();

	const fetchRouteTon2Tcat = async (amount, routeTonTcat: SelectedRoute) => {
		try {
			const { tokenIn: ton, tokenOut: tcat } = routeTonTcat;
			const amountIn = parseUnits(amount, ton.decimals);
			const deductedAmount = Sol2TcatSwap.deductTonGas(amountIn);
			const routeTon2Tcat = await DedustSwap.getRoute({
				amountIn: deductedAmount.toString(),
				tokenIn: ton,
				tokenOut: tcat,
				slippage,
			});

			const isGoOut = !window.location.pathname.startsWith(NAVIGATE_PATHS.Swap.ConfirmTransaction);
			const goToConfirm = () => {
				navigateToConfirm(routeTon2Tcat, amount);
			};

			setSelectedRoute({
				...selectedRoute,
				subRoutes: [{ ...selectedRoute.subRoutes[0], executed: true }, routeTon2Tcat],
			});
			toast(
				<Toast
					type="success"
					message={
						<Flex>
							Leg 1 of the SOL to {tcat?.symbol} trade is complete. Please Confirm to continue Leg 2{' '}
							{isGoOut && 'here'}.{isGoOut && <Button onClick={goToConfirm}>Go</Button>}
						</Flex>
					}
				/>,
				{ autoClose: isGoOut ? false : 8000 },
			);
		} catch (error) {}
	};

	const executeRouteSol2Ton = ({
		routeData,
		onUpdate,
	}: {
		routeData: SelectedRoute<any>;
		onUpdate: (data: any) => void;
	}): Promise<{ hash: string }> => {
		return new Promise(async (resolve, reject) => {
			try {
				const { tokenOut: tcat, tokenIn: sol } = routeData;
				onUpdate({ status: InternalStep.SRC_SUBMIT });
				const [routeSolTon, routeTonTcat] = routeData.subRoutes;
				const isRetroBridge = routeSolTon.provider === SwapProvider.RETROBRIDGE;

				const data = isMock
					? { hash: SOL_MOCK_HASH }
					: await (isRetroBridge
							? execRouteSolTonRetro({ route: routeSolTon as any, skipAddPendingTxs: true })
							: execRouteSolTonRocketX({ route: routeSolTon as any, skipAddPendingTxs: true }));

				const hash = data?.hash ?? '';

				const payloadTracking: MetadataSolTcat = {
					is2LegRoute: true,
					tobiIdOut: tcat.idTobi,
					symbolOut: tcat.symbol,
				};
				const metadata = getPayloadSwapTracking(
					routeData?.subRoutes[0],
					isMock
						? { ...payloadTracking, providerId: isRetroBridge ? RETRO_MOCK_ID : ROCKETX_MOCK_ID }
						: payloadTracking,
				);

				const { tokenOut: ton } = routeSolTon;
				ActivitiesServiceAPI.submitMetadataSwap2LegTxs({
					metadata: {
						fromChain: getTobiChainName(ton.chainId),
						toChain: getTobiChainName(tcat.chainId),
						fromToken: ton.idTobi,
						toToken: tcat.idTobi,
						providerId: metadata.providerId,

						toTokenSymbol: tcat.symbol,
						fromTokenSymbol: ton.symbol,
					},
					hash,
					provider: metadata.swapProvider,
				}).catch(() => {});

				saveMetadata(hash, metadata);
				addPendingSolTransaction({
					hash,
					trackingData: routeSolTon,
				});

				let checking = false;
				const interval = setInterval(async () => {
					if (checking) return;
					try {
						checking = true;
						onUpdate({ status: InternalStep.SRC_CONFIRM });
						const txsStatus = await Sol2TcatSwap.getSwapProvider(routeSolTon).getStatus(
							isMock
								? { txId: isRetroBridge ? RETRO_MOCK_ID : hash, requestId: ROCKETX_MOCK_ID }
								: {
										txId: routeSolTon?.metadata?.transaction_id,
										requestId: routeSolTon.route.requestId,
								  },
						);
						if (txsStatus?.status !== 'Completed') return;

						const amountTon = txsStatus.amount_out.toString();
						clearInterval(interval);

						await fetchRouteTon2Tcat(amountTon, routeTonTcat);
						onUpdate({ status: InternalStep.DES_WAITING_FOR_ACTION });
						resolve(data);
					} catch (error) {
						onUpdate({ status: InternalStep.SRC_CONFIRM, error });
						clearInterval(interval);
						reject(error);
					} finally {
						checking = false;
					}
				}, 5000);
			} catch (error) {
				onUpdate({ status: InternalStep.SRC_SUBMIT, error });
				reject(error);
			}
		});
	};

	return useMutation({
		mutationKey: ['exe-sol2tcat'],
		mutationFn: async ({
			route: routeData,
			onUpdate,
		}: {
			route: SelectedRoute<Route>;
			onUpdate: (data: RouteExecuting) => void;
		}): Promise<{ hash: string }> => {
			if (!routeData.subRoutes?.[0]?.executed) {
				return executeRouteSol2Ton({ routeData, onUpdate });
			}
			try {
				onUpdate({ status: InternalStep.DES_SUBMIT });
				const data = isMock ? { seqno: 55 } : await exeRouteDedust({ route: routeData.subRoutes[1] });
				onUpdate({ status: InternalStep.DES_CONFIRM });
				return { hash: data.seqno.toString() };
			} catch (error) {
				onUpdate({ status: InternalStep.DES_SUBMIT, error });
				throw error;
			}
		},
	});
};

export const useCountSol2Tcat = () => {
	const { metadata } = useTransactionWatcherStore();

	const listSpecialTxs = Object.values(metadata).filter((e) => e?.is2LegRoute && !e?.desHash);

	const checkStatus = async (metadata: MetadataSolTcat): Promise<MetadataSolTcat | null> => {
		if (metadata?.desHash) return metadata;
		const provider = metadata?.rawProvider;
		let resp: SwapStatusResponse;
		switch (provider) {
			case SwapProvider.RETROBRIDGE:
				resp = await RetroBridgeSwap.getStatus({
					txId: metadata?.providerId,
				});
				break;
			case SwapProvider.ROCKET:
				resp = await RocketXSwap.getStatus({
					txId: metadata.hash,
					requestId: metadata?.providerId,
				});
				break;
			default:
				return null;
		}
		const { status, amount_out: amountOut, destination_tx_hash } = resp;
		return { ...metadata, status, amountOut, desHash: destination_tx_hash };
	};

	const checking = useRef(false);
	const checkStatusListTransaction = async () => {
		if (!listSpecialTxs?.length || checking.current) return;
		checking.current = true;
		const data = await Promise.allSettled(listSpecialTxs.map(checkStatus));
		const formatData = data.map((e) => (e.status === 'fulfilled' ? e.value : null)).filter(Boolean);
		const listCompleted = formatData.filter((e) => e.status === 'Completed');
		listCompleted.forEach((e) => {
			const { saveMetadata } = useTransactionWatcherStore.getState();
			saveMetadata(e.hash, e);
		});
		checking.current = false;
	};

	useEffect(() => {
		checkStatusListTransaction();
		// eslint-disable-next-line
	}, []);
	useInterval(checkStatusListTransaction, listSpecialTxs?.length ? 5000 : null);
};
