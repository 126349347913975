import React from 'react';

interface IProps extends React.SVGAttributes<HTMLOrSVGElement> {}

export const FiSearchIcon: React.FC<IProps> = ({ width = 20, height = 20, ...rest }) => {
	return (
		<svg width={width} height={width} {...rest} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M9.1665 3.33317C5.94484 3.33317 3.33317 5.94484 3.33317 9.1665C3.33317 12.3882 5.94484 14.9998 9.1665 14.9998C12.3882 14.9998 14.9998 12.3882 14.9998 9.1665C14.9998 5.94484 12.3882 3.33317 9.1665 3.33317ZM1.6665 9.1665C1.6665 5.02437 5.02437 1.6665 9.1665 1.6665C13.3086 1.6665 16.6665 5.02437 16.6665 9.1665C16.6665 13.3086 13.3086 16.6665 9.1665 16.6665C5.02437 16.6665 1.6665 13.3086 1.6665 9.1665Z"
				fill="#7A7A7A"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M13.2856 13.2856C13.611 12.9601 14.1387 12.9601 14.4641 13.2856L18.0891 16.9106C18.4145 17.236 18.4145 17.7637 18.0891 18.0891C17.7637 18.4145 17.236 18.4145 16.9106 18.0891L13.2856 14.4641C12.9601 14.1387 12.9601 13.611 13.2856 13.2856Z"
				fill="#7A7A7A"
			/>
		</svg>
	);
};
