import { IS_UPGRADED_EDDSA } from '@/app-constants/backup';
import { STORAGE_KEYS } from '@/app-constants/storage';
import { ServiceCloudKit, ServiceGoogle } from '@/app-cores/api';
import { BACKUP_KEYSHARE_VERSION_1, BACKUP_KEYSHARE_VERSION_2, MpcWallet } from '@/app-cores/mpc-wallet/wallet';
import {
	decryptKeyShare2,
	encryptKeyShare2,
	setRequireImportPreBackupKeyShare,
} from '@/app-cores/mpc-wallet/wallet/helper';

export type AuthUrlPayload = {
	url: string;
	state: string;
};

export const createICloudAuthUrl = async (): Promise<AuthUrlPayload> => {
	const authUrl = await ServiceCloudKit.getAuthUrl();
	const urlObject = new URL(authUrl);
	const state = urlObject.searchParams.get('state');

	const params = new URLSearchParams();
	params.append('auth_url', authUrl);
	params.append('oauth_type', 'apple');
	params.append('state', state);

	const middleManUrl = `${import.meta.env.VITE_OAUTH_MIDDLEMAN}/preprocess-oauth?${params.toString()}`;
	return {
		url: middleManUrl,
		state: state,
	};
};

export const createGoogleAuthUrl = async (): Promise<AuthUrlPayload> => {
	const authUrl = await ServiceGoogle.getAuthUrl();
	const urlObject = new URL(authUrl);
	const state = urlObject.searchParams.get('state');
	return {
		url: authUrl,
		state: state,
	};
};

export const buildEncryptUnsecuredKeyShare = (encryptedPassword: string) => {
	const backupKey2 = MpcWallet.getUnsecuredKeyShare2();
	const backupKeyEncrypted = encryptKeyShare2(backupKey2, encryptedPassword);
	const keyShareStore = `${BACKUP_KEYSHARE_VERSION_2}|${backupKeyEncrypted}`;
	return keyShareStore;
};

export const restoreEncryptUnsecuredKeyShare = (keyshare2Encrypted: string, encryptedPassword: string) => {
	const [version, ...keyShareEncrypted] = keyshare2Encrypted.split('|');

	if (version === BACKUP_KEYSHARE_VERSION_1) {
		const ecdsaKeyShare = keyShareEncrypted.join('');
		const keyShareObj = {
			ecdsaKeyShare: decryptKeyShare2(ecdsaKeyShare, encryptedPassword),
			eddsaKeyShare: '',
		};
		return JSON.stringify(keyShareObj);
	} else if (version === BACKUP_KEYSHARE_VERSION_2) {
		const keyShare2Obj = JSON.parse(decryptKeyShare2(keyShareEncrypted.join(''), encryptedPassword));
		const keyShareObj = {
			ecdsaKeyShare: keyShare2Obj.ecdsaKeyShare2,
			eddsaKeyShare: keyShare2Obj.eddsaKeyShare2,
		};
		return JSON.stringify(keyShareObj);
	}
	return JSON.stringify({
		ecdsaKeyShare: keyshare2Encrypted,
		eddsaKeyShare: '',
	});
};
export const storeNewUnsecuredKeyShare = (preBackupKeyShare: string, encryptedPassword: string) => {
	const [version, ...keyShareEncrypted] = preBackupKeyShare.split('|');
	const unsecuredKeyShare2 = MpcWallet.getUnsecuredKeyShare2();
	try {
		const unsecuredKeyShare2Obj = JSON.parse(unsecuredKeyShare2);
		if (version === BACKUP_KEYSHARE_VERSION_1) {
			const ecdsaKeyShare2EnCrypt = keyShareEncrypted.join('');
			unsecuredKeyShare2Obj.ecdsaKeyShare2 = decryptKeyShare2(ecdsaKeyShare2EnCrypt, encryptedPassword);
		} else {
			unsecuredKeyShare2Obj.ecdsaKeyShare2 = preBackupKeyShare;
		}
		localStorage.setItem(STORAGE_KEYS.TOBI_UNSECURED_KEY_STORE, JSON.stringify(unsecuredKeyShare2Obj));
		setRequireImportPreBackupKeyShare(0);
	} catch (error) {
		console.log('createNewUnsecuredKeyShare error', error);
		throw new Error('Your backup file or secret key incorrect');
	}
};
