import { EIP155_CHAINS } from '@/app-constants/chains';
import { SOLANA_CHAINS } from './SolanaData';
import { TRON_CHAINS } from './TronData';

export const ALL_CHAINS = {
	...EIP155_CHAINS,
	...SOLANA_CHAINS,
	...TRON_CHAINS,
};
export function getChainData(chainId?: string) {
	if (!chainId) return;
	const [namespace, reference] = chainId.toString().split(':');
	return Object.values(ALL_CHAINS).find((chain) => chain.chainId == reference && chain.namespace == namespace);
}
