import { axiosBotAPI } from '@/app-cores/api/axios';

export const ServiceGoogle = {
	getAuthUrl: async (): Promise<string> => {
		const response = await axiosBotAPI.get('/auth/google/auth_url');
		const { data } = response.data;
		return data as string;
	},

	getAccessTokenByAuthState: async (state: string): Promise<string> => {
		const response = await axiosBotAPI.get('/auth/google/polling', {
			params: {
				state,
			},
		});
		const { data } = response.data;
		return data as string;
	},
};
