import { Box, Button, Radio, Text } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

interface ConfirmReceiveEmailProps {
	onResentEmail: () => void;
	isResendingEmail: boolean;
	resendCount: number;
	onConfirm: () => void;
}
const RESEND_SECONDS = 60;
export const ConfirmReceiveEmail: React.FC<ConfirmReceiveEmailProps> = ({
	isResendingEmail,
	onResentEmail,
	resendCount,
	onConfirm,
}) => {
	const { t } = useTranslation();
	const [isConfirm, setIsConfirm] = useState(false);
	const [seconds, setSeconds] = useState(RESEND_SECONDS);

	useEffect(() => {
		const intervalTimer = setInterval(() => {
			setSeconds((prevSeconds) => {
				if (prevSeconds < 1) {
					clearInterval(intervalTimer);
					return 0;
				}
				return prevSeconds - 1;
			});
		}, 1000);
		if (seconds < 1) {
			clearInterval(intervalTimer);
		}
		return () => clearInterval(intervalTimer);
	}, [seconds]);

	useEffect(() => {
		console.log('resendCount', resendCount);
		if (resendCount > 0) {
			setSeconds(RESEND_SECONDS);
		}
	}, [resendCount]);

	const minutes = Math.floor(seconds / 60);
	const remainingSeconds = seconds % 60;
	return (
		<Box>
			<Radio
				colorScheme="cyan"
				fontSize="sm"
				value="1"
				onChange={(e) => {
					setIsConfirm(e.target.checked);
				}}
			>
				<Text fontSize="sm" fontWeight={400}>
					{t('storeBackup.confirmOption')}
				</Text>
			</Radio>
			<Text pl={6} display={'block'} fontSize={'xs'}>
				{t('storeBackup.confirmRemind')}{' '}
				<Text
					onClick={() => {
						if (seconds > 0) {
							return;
						}
						onResentEmail();
					}}
					cursor={seconds > 0 ? 'not-allowed' : 'pointer'}
					as="strong"
					color={seconds > 0 ? 'gray.200' : undefined}
					textDecoration={'underline'}
				>
					Resend{' '}
					{seconds > 0 &&
						`(after ${minutes?.toString()?.padStart(2, '0')}:${remainingSeconds
							?.toString()
							?.padStart(2, '0')})`}
				</Text>
			</Text>
			<Button
				onClick={onConfirm}
				isDisabled={!isConfirm}
				mt={2}
				size="lg"
				fontWeight="medium"
				colorScheme="cyan"
				width="100%"
			>
				Confirm
			</Button>
		</Box>
	);
};
