import { useEffect, useRef } from 'react';
import { toast } from 'react-toastify';
import Lottie, { LottieRefCurrentProps } from 'lottie-react';
import { Box, Center, Container, Text } from '@chakra-ui/react';
import { useNavigate } from 'react-router';
import CreateWalletAnimationData from '@/assets/animations/create-wallet.json';
import { Toast } from '@/app-components/common';
import { NAVIGATE_PATHS } from '@/app-constants/router';
import { MpcWallet } from '@/app-cores/mpc-wallet/wallet';
import { useMutationDeleteBackupType } from '@/app-hooks/api/user/useMutationSetBackupType';
import { STORAGE_KEYS } from '@/app-constants/storage';
// import { useSyncUserWallet } from '@/app-hooks/api/mpc-wallet';
import { AuthClient } from '@/app-cores/auth';

interface ProcessRefreshKeyshareProps {}
export const ProcessRefreshKeyshare: React.FunctionComponent<ProcessRefreshKeyshareProps> = () => {
	const navigate = useNavigate();
	const { mutateAsync: deleteBackupType } = useMutationDeleteBackupType();
	const animateRef = useRef<LottieRefCurrentProps>(null);
	// const { mutateAsync: syncUserWallet } = useSyncUserWallet();

	useEffect(() => {
		async function migrateKeyshare() {
			try {
				animateRef.current?.setSpeed(1);
				animateRef.current?.play();
				await MpcWallet.migrateKeyshare();
				const { keyShare2, solAddress, tonAddress } = await MpcWallet.createEddsaWallet();
				// await syncUserWallet({
				// 	'ton-base': tonAddress,
				// 	'solana': solAddress,
				// });
				await AuthClient.authenticate();
				localStorage.removeItem(STORAGE_KEYS.TOBI_USE_BACKUP_KEYSHARE);
				MpcWallet.persistKeyShare();
				MpcWallet.storeUnSecuredEddsaKeyShare(keyShare2);
				try {
					await deleteBackupType();
				} catch (error) {
					console.error('Failed to delete backup type: ' + error);
				}
				toast(
					<Toast
						type="success"
						title="Update successful!"
						message="Because this update involved upgrading your wallet security, you will need to back up your wallet again"
					/>,
				);

				//After refresh successfully, navigate to backup page
				navigate(NAVIGATE_PATHS.CreateWallet.StoreBackupKey);
			} catch (error) {
				console.error('Refresh keyshare wallet Error:', error);
				localStorage.removeItem(STORAGE_KEYS.TOBI_UNSECURED_KEY_STORE);
				localStorage.removeItem(STORAGE_KEYS.TOBI_UNSECURED_KEY_STORE_OLD);
				toast(<Toast type="error" title="Error" message="Refresh your Keyshare error." />);
				navigate(NAVIGATE_PATHS.Home);
			}
		}
		migrateKeyshare();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<Container>
			<Box height="100vh">
				<Center height="100%" flexDirection="column">
					<Lottie
						width={180}
						high={180}
						loop={false}
						autoplay={false}
						lottieRef={animateRef as any}
						animationData={CreateWalletAnimationData}
						style={{
							width: '180px',
							height: '180px',
						}}
					/>
					<Text fontSize="sm" mt={6}>
						Just a sec, Tobi will be right back...
					</Text>
				</Center>
			</Box>
		</Container>
	);
};
