import { CardSelection } from '@/app-components/common';
import { FiCreditCardIcon } from '@/assets/icons';
import { Box, Text } from '@chakra-ui/react';
import { NetworkSelection } from './NetworkSelection';
import { useDepositStore } from '../store';
import { useBuyOnTransak } from '@/app-hooks/common';
import { useLocation, useNavigate } from 'react-router';
import { NetWorkChain } from '@/app-contexts/wallet-provider/type';
import { DATADOG_ACTIONS, dataDogAddAction } from '@/app-services/monitor/datadog';
import { getTokenInfo } from '@/app-helpers/token';

export const Fiat = () => {
	const { isOpenNetworkSelection, onToggleNetworkSelection, initToken, onClose } = useDepositStore();
	const { buildTransakURL } = useBuyOnTransak();
	const navigate = useNavigate();
	const { pathname, search } = useLocation();
	const redirectToTransak = () => {
		const info = getTokenInfo(initToken);
		const url = buildTransakURL({
			chainId: info.chainId as string,
			tokenSymbol: info?.symbol,
			callbackUrl: `${pathname}${search}`,
		});
		onClose();
		navigate(url);
	};
	return (
		<>
			<CardSelection
				cursor="pointer"
				icon={<FiCreditCardIcon />}
				leftContent={
					<Box>
						<Text fontSize="sm" fontWeight={600}>
							Buy with Fiat
						</Text>
						<Text fontSize="xs" fontWeight={400} color="gray.400">
							Via Transak
						</Text>
					</Box>
				}
				onClick={() => {
					if (initToken) {
						dataDogAddAction(DATADOG_ACTIONS.DEPOSIT_BY_TRANSAK);
						return redirectToTransak();
					}
					onToggleNetworkSelection(true);
				}}
			/>
			{isOpenNetworkSelection && <NetworkSelection />}
		</>
	);
};
