import React from 'react';

interface IProps extends React.SVGAttributes<HTMLOrSVGElement> {}

export const FiUpRightIcon: React.FC<IProps> = ({ width = 24, height = 24, ...rest }) => {
	return (
		<svg width={width} height={height} {...rest} viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M17.8067 6.29289C18.1972 6.68342 18.1972 7.31658 17.8067 7.70711L7.80672 17.7071C7.41619 18.0976 6.78303 18.0976 6.3925 17.7071C6.00198 17.3166 6.00198 16.6834 6.3925 16.2929L16.3925 6.29289C16.783 5.90237 17.4162 5.90237 17.8067 6.29289Z"
				fill="black"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M6.09961 7C6.09961 6.44772 6.54732 6 7.09961 6H17.0996C17.6519 6 18.0996 6.44772 18.0996 7V17C18.0996 17.5523 17.6519 18 17.0996 18C16.5473 18 16.0996 17.5523 16.0996 17V8H7.09961C6.54732 8 6.09961 7.55228 6.09961 7Z"
				fill="black"
			/>
		</svg>
	);
};
