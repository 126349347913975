import { TransactionInstruction, SystemProgram, PublicKey, Transaction, Connection } from '@solana/web3.js';
import {
	createTransferInstruction,
	TOKEN_PROGRAM_ID,
	getAssociatedTokenAddress,
	getAccount,
	createAssociatedTokenAccountInstruction,
	ASSOCIATED_TOKEN_PROGRAM_ID,
} from '@solana/spl-token';
import BigNumber from 'bignumber.js';
import { TSentSolTransaction } from '@/app-types';
import { getDecimalCount } from '../token';

export function getSolCoinTransferBalance(amount: string, decimals: number) {
	const decimal = getDecimalCount(amount, decimals);
	if (!amount) return 0n;
	const numBig = new BigNumber(amount);
	const amountRounded = BigNumber(numBig.toFixed(decimal, BigNumber.ROUND_DOWN));
	return BigInt(amountRounded.multipliedBy(Math.pow(10, decimals)).toString());
}
export const getFinalSolGasFee = (fee: bigint, bufferPercent = 20n) => {
	if (!fee) return 0n;
	return fee + (fee * bufferPercent) / 100n;
};
export async function getMinimumBalanceForRentExemption(connection: Connection) {
	const dataSize = 16;
	const minBalanceForRent = await connection.getMinimumBalanceForRentExemption(dataSize);
	return minBalanceForRent;
}
export async function buildSolTransaction({
	connection,
	fromPubKey,
	sendData,
}: {
	connection: Connection;
	fromPubKey: PublicKey;
	sendData: TSentSolTransaction;
}) {
	let amountTransfer = getSolCoinTransferBalance(sendData.amount, sendData.token.decimals);
	const minBalanceForRent = await getMinimumBalanceForRentExemption(connection);

	const totalUsed = amountTransfer + getFinalSolGasFee(sendData.gasFee) + BigInt(minBalanceForRent);
	const balance = BigInt(sendData.token.balance ?? 0n);
	if (totalUsed >= balance) {
		amountTransfer = balance - getFinalSolGasFee(sendData.gasFee) - BigInt(minBalanceForRent);
		if (!sendData.autoDeductFee) {
			console.log('Not enough balance to cover gas fee', { totalUsed, balance });
			throw new Error('Not enough balance to cover gas fee');
		}
	}

	const transferTransaction = new Transaction().add(
		SystemProgram.transfer({
			fromPubkey: fromPubKey,
			toPubkey: new PublicKey(sendData.to),
			lamports: amountTransfer,
		}),
	);
	if (sendData.message) {
		transferTransaction.add(
			new TransactionInstruction({
				keys: [{ pubkey: fromPubKey, isSigner: true, isWritable: true }],
				data: Buffer.from(sendData.message, 'utf-8'),
				programId: new PublicKey('MemoSq4gqABAXKb96qnH8TysNcWxMyWCqXgDLGmfcHr'),
			}),
		);
	}
	return transferTransaction;
}

export async function checkIfTokenAccountExists(connection, receiverTokenAccountAddress) {
	try {
		await getAccount(connection, receiverTokenAccountAddress, 'confirmed', TOKEN_PROGRAM_ID);
		return true;
	} catch (error) {
		if ((error as Error).name === 'TokenAccountNotFoundError') return false;
		throw error;
	}
}

export async function buildSLPTransaction({
	connection,
	fromPubKey,
	sendData,
}: {
	connection: Connection;
	fromPubKey: PublicKey;
	sendData: TSentSolTransaction;
}) {
	const tokenMint = new PublicKey(sendData.token.address);
	const fromTokenAccount = await getAssociatedTokenAddress(tokenMint, fromPubKey);
	const toTokenAccount = await getAssociatedTokenAddress(tokenMint, new PublicKey(sendData.to));
	const isTokenAccountAlreadyMade = await checkIfTokenAccountExists(connection, toTokenAccount);
	const transferTransaction = new Transaction();
	if (!isTokenAccountAlreadyMade) {
		const createAccountInstruction = createAssociatedTokenAccountInstruction(
			fromPubKey,
			toTokenAccount,
			new PublicKey(sendData.to),
			tokenMint,
			TOKEN_PROGRAM_ID,
			ASSOCIATED_TOKEN_PROGRAM_ID,
		);
		transferTransaction.add(createAccountInstruction);
	}
	const amountTransfer = getSolCoinTransferBalance(sendData.amount, sendData.token.decimals);
	const transferInstruction = await createTransferInstruction(
		fromTokenAccount,
		toTokenAccount,
		fromPubKey,
		amountTransfer,
	);
	transferTransaction.add(transferInstruction);
	if (sendData.message) {
		transferTransaction.add(
			new TransactionInstruction({
				keys: [{ pubkey: fromPubKey, isSigner: true, isWritable: true }],
				data: Buffer.from(sendData.message, 'utf-8'),
				programId: new PublicKey('MemoSq4gqABAXKb96qnH8TysNcWxMyWCqXgDLGmfcHr'),
			}),
		);
	}
	return transferTransaction;
}
