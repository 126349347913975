import { StoreApi, create } from 'zustand';
import { ModalData } from './store.type';

type ModalView =
	| 'SessionProposalModal'
	| 'SessionSendTransactionModal'
	| 'SessionSignModal'
	| 'SessionSignTypedDataModal'
	| 'SessionUnSupportedMethodModal'
	| 'AuthRequestModal'
	| 'SessionApprovalModal';

export interface IBotConnectorStore {
	open: boolean;
	view?: ModalView;
	data?: ModalData;
	onOpen: (view: ModalView, data?: ModalData) => void;
	onClose: () => void;
}

export const useModalStore = create<IBotConnectorStore>((set: StoreApi<IBotConnectorStore>['setState']) => ({
	open: false,
	openWalletConnect: false,
	isWaiting: false,
	onOpen: (view: ModalView, data?: ModalData) => {
		set(() => ({ open: true, view, data, isWaiting: false }));
	},
	onClose: () => {
		set(() => ({ open: false, data: undefined }));
	},
}));
