import { useHideKeyboardOnEnter } from '@/app-hooks/common';
import { BASE_BACKGROUND_COLOR } from '@/app-theme/theme';
import { Box, Input, InputProps } from '@chakra-ui/react';
import React, { forwardRef, useEffect, useRef } from 'react';
import { isIOS } from 'react-device-detect';

interface InputCustomProps extends InputProps {
	placeHolderStyle?: React.CSSProperties;
	ref?: React.ForwardedRef<HTMLInputElement>;
	onIosKeyboardOpen?: (isOpen: boolean) => void;
}

// same as Charka Input but default props: spellcheck, ...
export const BaseInput = forwardRef<HTMLInputElement, InputCustomProps>(
	({ onBlur, onFocus, onKeyDown, onIosKeyboardOpen, ...rest }, ref) => {
		const onHideKeyBoard = useHideKeyboardOnEnter(onKeyDown);
		return (
			<Input
				autoComplete="off"
				autoCorrect="off"
				autoCapitalize="off"
				spellCheck="false"
				onKeyDown={onHideKeyBoard}
				ref={ref}
				onFocus={(e) => {
					onFocus?.(e);
					isIOS && onIosKeyboardOpen?.(true);
				}}
				onBlur={(e) => {
					onBlur?.(e);
					isIOS && onIosKeyboardOpen?.(false);
				}}
				{...rest}
			/>
		);
	},
);

// custom input to handle keyboard issue
export const InputCustom = forwardRef<HTMLInputElement, InputCustomProps>(
	({ value, placeholder, onBlur, onKeyDown, placeHolderStyle, ...rest }, ref) => {
		const placeHolderRef = useRef<HTMLInputElement>(null);
		const onHideKeyBoard = useHideKeyboardOnEnter(onKeyDown);

		useEffect(() => {
			if (!placeHolderRef.current) return;
			if (value) {
				placeHolderRef.current.style.display = 'none';
			} else {
				placeHolderRef.current.style.display = 'inline';
			}
		}, [value]);

		return (
			<Box position="relative" width="100%">
				<BaseInput
					ref={ref}
					value={value}
					onBlur={(e) => {
						window.scrollTo(0, 0);
						onBlur?.(e);
					}}
					onKeyDown={onHideKeyBoard}
					{...rest}
				/>
				<Box
					as="label"
					ref={placeHolderRef}
					htmlFor="customInput"
					style={placeHolderStyle}
					className="custom-placeholder"
				>
					{placeholder}
				</Box>
			</Box>
		);
	},
);

// input with same style
export const CommonInput = ({ style, ...props }: InputCustomProps & { disabled?: boolean }) => {
	return (
		<BaseInput
			{...props}
			sx={{
				...style,
				borderRadius: '12px',
				border: 'none',
				background: BASE_BACKGROUND_COLOR,
				fontSize: '14px',
				':disabled': {
					opacity: 0.8,
				},
			}}
		/>
	);
};
