import { ChainId } from '@/app-constants/chains';
import { TxStatus } from '@/app-cores/api/activities';
import { TokenInfo } from '@/app-cores/api/bff';
import { SwapProvider, UpdateStatusFn } from '@/app-hooks/swap/type';
import { TTransactionRequest } from '@/app-types';

export enum LimitProvider {
	NOT_SUPPORT = '404',
	KYBER = SwapProvider.KYBER,
	JUPITER = SwapProvider.JUPITER,
}

export enum LimitOrderStatus {
	OPEN = 'open',
	CLOSED = 'closed',

	CANCELLED = 'cancelled',
	FILLED = 'completed',
	PARTIALLY_FILLED = 'partially_filled',
}

export type ArgsGetOrders = {
	chainId: ChainId;
	status: LimitOrderStatus;
	pageSize: number;

	// paging number
	page?: number; // 1, 2, 3, ...

	// paging cursor
	cursor?: any;
};

export type CancelParams = { callback: UpdateStatusFn } & { data: FormatOrder; isGasLess: boolean };

export type ArgsCreateOrder = {
	amountIn: string | undefined;
	amountOut: string | undefined;
	tokenIn: TokenInfo | undefined;
	tokenOut: TokenInfo | undefined;
	expiredAt: number | null | Date; // timestamp
};

export type ArgsCancelOrder = {
	order: FormatOrder;
};

export type FormatOrder = {
	id: string;
	amountIn: string;
	amountOut: string;
	expiredAt: number | null;
	tokenIn: TokenInfo;
	tokenOut: TokenInfo;
	status: LimitOrderStatus;
	rawOrder: any;
	createdAt: number;
	txHash: string;
	chainId: ChainId;
	fillPercent: number;
	provider: LimitProvider;
};

export type ListOrderResponse = {
	total: number;
	orders: FormatOrder[];
	chainId: ChainId;
};

export abstract class LimitOrderAbstract {
	async init() {}
	abstract getOrders(params: ArgsGetOrders): Promise<ListOrderResponse>;
	abstract createOrder(params: ArgsCreateOrder, callback: UpdateStatusFn): Promise<any>;
	abstract cancelOrder(data: CancelParams): Promise<TTransactionRequest | undefined>;
}
