import { isHexString } from 'ethereumjs-util';
import { ethers } from 'ethers';

const decimalize = (input: string | number): string => {
	let value: string;

	if (typeof input === 'number') {
		value = input.toString();
	} else {
		value = input;
	}

	if (isHexString(value)) {
		const decimalNumber = parseInt(value, 16);
		value = decimalNumber.toString(10);
	}

	return value;
};

export const decimalizeAsNumber = (input: string | number): number => {
	return Number(decimalize(input));
};

// stringify number without scientific format
// e.g: (123456789123456789123456789).toString() => 1.2345678912345679e+26
//      toFixed(123456789123456789123456789) => 123456789123456800000000000
// https://stackoverflow.com/a/1685917/8153505
function _toFixed(x: number): string {
	if (Math.abs(x) < 1.0) {
		const e = parseInt(x.toString().split('e-')[1]);
		if (e) {
			x *= Math.pow(10, e - 1);
			return x.toString().split('.')[0] + '.' + '0'.repeat(e - 1) + x.toString().split('.')[1];
		}
	} else {
		let e = parseInt(x.toString().split('+')[1]);
		if (e > 20) {
			e -= 20;
			x /= Math.pow(10, e);
			return x.toString() + '0'.repeat(e);
		}
	}
	return x.toString();
}

export const toFixed = (x: number, decimals?: number) => {
	const fixedStr = _toFixed(x);
	if (decimals === undefined || !fixedStr.includes('.')) return fixedStr;
	const [base, float] = fixedStr.split('.');
	return `${base}.${float.slice(0, decimals)}`;
};

const smallDigits = ['₀', '₁', '₂', '₃', '₄', '₅', '₆', '₇', '₈', '₉'];
// format small value: 0.000008 => $0.0₅8
const formatSmallNumber = (
	valueNumber: number,
	{
		maximumFractionDigits,
		minimumFractionDigits,
		showDollar,
		testnet,
	}: {
		maximumFractionDigits: number;
		minimumFractionDigits: number;
		showDollar: boolean;
		testnet?: boolean;
	},
) => {
	const decimal = toFixed(valueNumber).split('.')[1];
	const numberOfLeadingZeros = -Math.floor(Math.log10(valueNumber) + 1);
	const slicedDecimal = decimal
		.replace(/^0+/, '')
		.slice(0, maximumFractionDigits ? maximumFractionDigits : 30)
		.slice(0, minimumFractionDigits ? minimumFractionDigits : 30)
		.replace(/0+$/, '');
	const currency = showDollar ? (testnet ? 't$' : '$') : '';

	if (numberOfLeadingZeros > 2) {
		const subscripts = numberOfLeadingZeros
			.toString()
			.split('')
			.map((item) => smallDigits[item])
			.join('');
		return `${currency}0.0${subscripts}${slicedDecimal}`;
	}
	return `${currency}0${slicedDecimal.length ? '.' + '0'.repeat(numberOfLeadingZeros) + slicedDecimal : ''}`;
};

// 1.0000 => 1
export function removeTrailingZeros(number): string {
	return number ? number.toString().replace(/(?:\.0*|(\.\d+?)0+)$/, '$1') : '';
}

/** // 1.236 => 1.23
 * don't like n.toFixed(2) will round number
 * */
export function truncateToFixed(num, decimalPlaces) {
	const numStr = String(num);
	if (numStr.endsWith('.') || (numStr.includes('.') && numStr.endsWith('0'))) return num;
	return removeTrailingZeros(toFixed(num, decimalPlaces));
}

// 1.234 => 3
export function countDecimals(number) {
	number = number + '';
	if (Math.floor(number) === number) return 0; // No decimals if it's an integer
	return number.toString().split('.')?.[1]?.length || 0;
}

// format rules: https://www.notion.so/calibervb/Number-Standards-8329510d442844429d9b1a4b7fedc73e

// format usd value in input
export const formatUsdAmountInput = (value: number | string) => {
	if (value === undefined || value === '') {
		return '';
	}

	if (Number(value).toString().length !== String(value).length) {
		// End with `,` or `.`
		return `${value}`;
	}

	if (+value === 0) {
		// Case 0.00...
		return `${value}`;
	}

	return (+truncateToFixed(value, 2)).toString();
};

/**
 * format token amount with our rules
 * @param options
 * - compact: 1000 => 1K
 */
export const formatCurrency = (
	value: string | number,
	options?: {
		compact?: boolean;
		fallbackValue?: string;
	},
) => {
	const decimals = 4;
	const { compact = false, fallbackValue } = options || {};

	if (isNaN(+value)) {
		return fallbackValue ?? '';
	}

	try {
		const valueNumber = Number(value);
		const sign = valueNumber < 0 ? '-' : '';
		if (valueNumber && Math.abs(valueNumber) < 1e-3) {
			return (
				sign +
				formatSmallNumber(valueNumber, {
					maximumFractionDigits: decimals,
					minimumFractionDigits: 0,
					showDollar: false,
				})
			);
		}

		if (valueNumber > 1e12) {
			return valueNumber.toExponential(1); // 24e123
		}

		const payload: Intl.NumberFormatOptions = {};
		if (valueNumber > 1e5) {
			payload.minimumFractionDigits = 0;
			payload.maximumFractionDigits = 0;
		} else if (valueNumber > 1e4) {
			payload.minimumFractionDigits = 1;
			payload.maximumFractionDigits = 1;
		} else if (valueNumber > 1e3) {
			payload.minimumFractionDigits = 2;
		} else if (valueNumber > 1e2) {
			payload.minimumFractionDigits = 3;
		} else if (valueNumber > 1) {
			payload.minimumFractionDigits = decimals;
		} else {
			payload.maximumSignificantDigits = decimals;
		}

		const formatter = new Intl.NumberFormat('en-US', {
			style: 'currency',
			currency: 'USD',
			currencyDisplay: 'symbol',
			notation: compact ? 'compact' : undefined,
			...payload,
		});

		const formatted = formatter.format(valueNumber);
		return removeTrailingZeros(formatted.replace('$', ''));
	} catch (error) {
		return fallbackValue || '';
	}
};

/**
 *  same as ethers.formatUnits but format with our rules, bigint => decimals number, formatUnits(123456789, 8) => 1.2346
 * @param amount
 * @param decimals
 * @param withFormat: format can be round up, if you want to use it with input amount => withFormat: false or use ethers.formatUnits
 * @returns
 */
export const formatUnits = (
	amount: string | ethers.BigNumberish,
	decimals: number,
	options?: { withFormat?: boolean },
) => {
	try {
		const { withFormat = true } = options || {};
		const value = ethers.formatUnits(amount, decimals);
		return withFormat ? formatCurrency(value) : removeTrailingZeros(value);
	} catch (error) {
		return '';
	}
};

// 12345678 => 12.345.678, use to format percent or number
export const formatNumber = (value: string | number, options?: { decimals?: number; fallbackValue?: string }) => {
	const { decimals = 4, fallbackValue } = options || {};

	if (isNaN(+value)) {
		return fallbackValue ?? '';
	}

	const formatter = new Intl.NumberFormat('en-US', {
		style: 'decimal',
		minimumFractionDigits: decimals,
		maximumFractionDigits: decimals,
	});

	return removeTrailingZeros(formatter.format(Number(value)));
};

// format usd value with our rules
export const formatUsd = (value: number | string, options: { showDollar?: boolean; testnet?: boolean } = {}) => {
	value = +value;
	const maximumFractionDigits = 2;
	const compact = value > 1e6;

	const { showDollar = true, testnet = false } = options || {};
	const prefix = !showDollar ? '' : testnet ? 't$' : '$';

	if (isNaN(+value)) {
		return '';
	}

	try {
		const valueNumber = Number(value);
		const sign = valueNumber < 0 ? '-' : '';
		if (valueNumber && Math.abs(valueNumber) < 0.0009) {
			return (
				sign +
				formatSmallNumber(valueNumber, {
					maximumFractionDigits,
					minimumFractionDigits: 0,
					showDollar,
					testnet,
				})
			);
		}

		if (valueNumber > 1e18) {
			return `${showDollar ? prefix : ''}${valueNumber.toExponential(1)}`; // 24e123
		}

		const formatter = new Intl.NumberFormat('en-US', {
			style: 'currency',
			currency: 'USD',
			currencyDisplay: 'symbol',
			...(value > 1
				? { maximumFractionDigits: 2, minimumFractionDigits: 2 }
				: { maximumSignificantDigits: 4, minimumSignificantDigits: 4 }),
			notation: compact ? 'compact' : undefined,
		});

		let formatted = formatter.format(Number(value));
		formatted = removeTrailingZeros(formatted);

		return showDollar ? formatted.replace('$', prefix) : formatted.replace('$', '');
	} catch (error) {
		return '';
	}
};
