import { v4 as uuidV4 } from 'uuid';

export const uniqueId = (): string => {
	return uuidV4();
};
export const randomColor = (str: string): string => {
	if (!str) return '#000000';
	let hash = 0;
	for (let i = 0; i < str.length; i++) {
		hash = str.charCodeAt(i) + ((hash << 5) - hash);
	}
	const c = (hash & 0x00ffffff).toString(16).toUpperCase();
	return '#' + '00000'.substring(0, 6 - c.length) + c;
};
