import { useMutation } from '@tanstack/react-query';
import { AuthClient } from '@/app-cores/auth';
import { initI18n } from '@/app-cores/i18n';
import { dataDogAddUserInfo } from '@/app-services/monitor/datadog';
import { useWalletStore } from '@/app-store';
import { MpcWallet } from '@/app-cores/mpc-wallet/wallet';

export const useMutationInitApp = ({ onCompleted }: { onCompleted?: () => void }) => {
	const { setDklsInitializeStatus } = useWalletStore();
	return useMutation({
		mutationKey: ['mutation-init-app'],
		mutationFn: async () => {
			try {
				dataDogAddUserInfo();
				await AuthClient.init();

				if (!AuthClient.tokenManager.exist()) {
					await AuthClient.authenticate();
				}
				MpcWallet.initialize(() => AuthClient.authHeader())
					.then(() => {
						console.log('DklsInitialize Status SUCCESS');
						setDklsInitializeStatus('SUCCESS');
					})
					.catch((error) => {
						console.log('DklsInitialize Status ERROR', error);
						setDklsInitializeStatus('ERROR');
					});

				initI18n();
				await onCompleted?.();
				return true;
			} catch (error) {
				console.error('InitApp Error', error);
				return false;
			}
		},
	});
};
