import { Loading } from '@/app-components/common';
import PageTransition from '@/app-components/layout/PageTransition/PageTransition';
import PageNavigation from '@/app-components/layout/PageWrapper/PageWrapper';
import { NAVIGATE_PATHS } from '@/app-constants/router';
import { Box, Center, Img } from '@chakra-ui/react';
import get from 'lodash/get';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSearchParams } from 'react-router-dom';

export function GoogleSearchControl() {
	const navigate = useNavigate();
	const [isLoading, setIsLoading] = useState(true);
	const [searchParams] = useSearchParams();
	const searchValue = searchParams.get('searchValue');
	useEffect(() => {
		const script = document.createElement('script');
		script.src = `https://cse.google.com/cse.js?cx=${import.meta.env.VITE_SEARCH_ENGINE_ID}`;
		document.head.append(script);
		script.onload = () => {
			setTimeout(() => {
				setIsLoading(false);
				const searchInput = document.querySelector('input.gsc-input') as HTMLInputElement;
				if (!searchInput) return;
				searchInput.placeholder = 'Search Google or type a URL';
				if (searchValue === 'null') return;
				searchInput.value = searchValue;
				const searchButton = document.querySelector('.gsc-search-button-v2') as HTMLButtonElement;
				if (searchButton) {
					searchButton.click();
				}
			}, 1000);
		};
	}, [searchValue]);

	useEffect(() => {
		const viewDapp = (url: string) => {
			navigate(`${NAVIGATE_PATHS.Dapp.TobiDapp}/?dappUrl=${url}`);
		};

		const myResultsReadyCallback = function (name, q, promos, results, resultsDiv) {
			const makeResultParts = (result) => {
				const title = document.createElement('div');
				title.classList.add('google-search-control__gs_title');
				const appTitle = document.createElement('h3');
				appTitle.innerHTML = get(result, 'title');
				appTitle.classList.add('google-search-control__gs_app-title');

				const urlObj = new URL(result.url);
				const url = urlObj.searchParams.get('q');
				const siteUrl = document.createElement('cite');
				siteUrl.innerHTML = url;
				siteUrl.classList.add('google-search-control__gs_url');

				title.appendChild(appTitle);
				title.appendChild(siteUrl);

				title.onclick = () => {
					viewDapp(url);
				};

				const content = document.createElement('div');
				content.innerHTML = get(result, 'content');
				content.classList.add('google-search-control__gs_content');
				const resultItem = document.createElement('div');
				resultItem.appendChild(title);
				resultItem.appendChild(content);
				return resultItem;
			};

			if (results) {
				const searchItem = document.createElement('div');
				searchItem.classList.add('gs-result');
				for (const result of results) {
					const resultItem = makeResultParts(result);
					searchItem.appendChild(resultItem);
				}
				resultsDiv.appendChild(searchItem);
			}
			return true;
		};
		window.__gcse = {
			parsetags: 'onload',
			searchCallbacks: {
				web: {
					ready: myResultsReadyCallback,
				},
			},
		};
	}, []);

	return (
		<PageTransition
			style={{
				flexDirection: 'column',
				flexGrow: 1,
				overflow: 'hidden',
			}}
		>
			<PageNavigation
				onBack={() => {
					history.replaceState(null, null, '/');
					navigate(NAVIGATE_PATHS.Dapp.DappBrowser);
				}}
			>
				{isLoading && <Loading opacity={0.8} />}
				<Box overflow="auto" height="100%" position="relative" className="google-search-control">
					<Center>
						<Img src="/assets/images/google.svg" />
					</Center>
					<div
						className="gcse-search"
						data-enableHistory="true"
						data-mobileLayout="enabled"
						data-enableOrderBy="false"
					></div>
				</Box>
			</PageNavigation>
		</PageTransition>
	);
}
