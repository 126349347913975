import { FiCopyIcon } from '@/assets/icons';
import { CheckCircleIcon } from '@/assets/images/svg/check-circle-icon';
import { Box, Placement, Tooltip } from '@chakra-ui/react';
import { CSSProperties, useEffect, useState } from 'react';
import { CopyToClipboard as ReactCopyToClipboard } from 'react-copy-to-clipboard';

interface CopyToClipboardProps {
	text: string;
	copyText?: string;
	copiedText?: string;
	showIcon?: boolean;
	tooltipPlacement?: Placement;
	contentElement?: React.ReactNode;
	callback?: () => void;
	iconSize?: number;
	containerStyle?: CSSProperties;
}

export const CopyToClipboard: React.FunctionComponent<React.PropsWithChildren<CopyToClipboardProps>> = ({
	copiedText = 'Copied!',
	copyText = 'Copy',
	text,
	showIcon = true,
	children,
	contentElement,
	tooltipPlacement = 'top',
	callback,
	iconSize,
	containerStyle,
}) => {
	const [copied, setCopied] = useState<boolean>(false);
	useEffect(() => {
		if (copied) {
			const hide = setTimeout(() => {
				setCopied(false);
			}, 700);
			return () => clearTimeout(hide);
		}
	}, [copied]);

	return (
		<ReactCopyToClipboard
			text={text}
			onCopy={(_, success) => {
				success && setCopied(true);
				callback?.();
			}}
		>
			<Box display="flex" alignItems="center" gap={2} style={containerStyle}>
				{children}
				{showIcon && (
					<Tooltip hasArrow label={copied ? copiedText : copyText} color="black" placement={tooltipPlacement}>
						<Box cursor="pointer">
							{contentElement ||
								(copied ? (
									<CheckCircleIcon width={iconSize || 16} height={iconSize || 16} color="#00E9DB" />
								) : (
									<FiCopyIcon width={iconSize} height={iconSize} />
								))}
						</Box>
					</Tooltip>
				)}
			</Box>
		</ReactCopyToClipboard>
	);
};
