import { FeeSwapParams, getPayloadFeeSwap } from '@/app-hooks/swap';
import { getGasInfo } from '@/app-hooks/api/transactions/useQueryGasPrice';
import { isChainHasSwapFee, isEvmChain, isSolanaChain, isTonChain, isTronChain } from '@/app-helpers/token';
import { estimateGasFeeSendToken, prepareSendEvmToken, prepareSendTonToken } from '@/app-hooks/transactions';
import { ActivitiesServiceAPI, ObserverTransaction } from '@/app-cores/api/activities';
import { SelectedRoute } from '@/app-store/swap';
import { useSentSolToken } from '@/app-hooks/transactions/sol/useMutationSentSolAsset';
import { delay } from '@/app-helpers';
import { ONE_MINUTE } from '@/app-hooks/api/portfolio/constant';
import { TSentSolTransaction, TSentTonTransaction, TSentTransaction, TSentTronTransaction } from '@/app-types';
import { prepareSendTronToken } from '@/app-hooks/transactions/tron/useMutationSendTronAsset';
import { truncateToFixed } from '@/app-helpers/number';
import { estimateTonGasFee } from '@/app-hooks/transactions/ton/useEstimateFeeSendTonAsset';
import { DATADOG_ERROR_TAGS, dataDogAddError } from '@/app-services/monitor/datadog';
import { TonWallet } from '@/app-cores/mpc-wallet/ton/TonWallet';
import { useParseBotStartRedirectParams } from '@/app-cores/telegram';

const expireTime = 3600; // one hour

export const useTakeFeeSwap = () => {
	const { sendTransaction } = useSentSolToken();
	const swapPrams = useParseBotStartRedirectParams();

	const takeFeeSolana = async (payload: ObserverTransaction, payloadSend: TSentSolTransaction) => {
		const totalTime = payload.isSwapSameChain ? ONE_MINUTE * 3 : 30 * ONE_MINUTE;
		const duration = 5000;
		let isSuccess = false;
		for (let i = 0; i <= Math.ceil(totalTime / duration); i++) {
			try {
				await delay(duration);
				const data: string = await ActivitiesServiceAPI.checkSwapTransaction({
					...payload,
					isSameChain: payload.isSwapSameChain,
				});
				if (data === 'swapped') {
					isSuccess = true;
					break;
				}
			} catch (error) {}
		}
		if (isSuccess) {
			try {
				const feeHash = await sendTransaction(payloadSend);
				await ActivitiesServiceAPI.submitMetadataSwapTxs({
					...payload,
					swapResult: 'swapped',
					feeError: '',
					feeHash,
				});
			} catch (error) {
				await ActivitiesServiceAPI.submitMetadataSwapTxs({
					...payload,
					swapResult: 'swapped',
					feeError: error?.toString(),
					feeHash: '',
				});
			}
		}
	};

	const takeFeeTron = async (payload: ObserverTransaction, payloadSend: TSentTronTransaction) => {
		const data = await prepareSendTronToken(payloadSend, expireTime);
		payload.signatureFeeTransaction = JSON.stringify(data);
		return payload;
	};

	const takeFeeTon = async (payload: ObserverTransaction, payloadSend: TSentTonTransaction) => {
		const { contract } = await TonWallet.create('mainnet');
		const seqno = await contract.getSeqno();
		const gasFee = await estimateTonGasFee(payloadSend); // todo merge

		const payloadWithTimeout = {
			...payloadSend,
			gasFee,
			timeout: Math.floor(Date.now() / 1000) + expireTime,
		};
		const { cell, wallet } = await prepareSendTonToken(payloadWithTimeout, seqno + 1);
		payload.signatureFeeTransaction = cell.toBoc().toString('base64');
		try {
			const { cell } = await prepareSendTonToken(payloadWithTimeout, seqno + 2);
			payload.backupFeeSignature = cell.toBoc().toString('base64');
		} catch (error) {
			console.log('backUpSignatureFeeTransaction err', error);
		}
		payload.walletPublicKey = wallet?.publicKey?.toString('hex');

		return payload;
	};

	const takeFeeEvm = async (
		payload: ObserverTransaction,
		payloadSend: TSentTransaction,
		{ nonce, chainId }: { nonce: number; chainId: string },
	) => {
		const { getSignedTransaction } = await prepareSendEvmToken({
			...payloadSend,
			nonce,
		});
		payload.signatureFeeTransaction = await getSignedTransaction(nonce ? nonce : undefined);
		try {
			payload.backupFeeSignature = await getSignedTransaction(nonce ? nonce + 1 : undefined);
		} catch (error) {
			console.log('backUpSignatureFeeTransaction err', error);
		}
		return payload;
	};

	return async (data: {
		route: SelectedRoute;
		hash: string;
		nonce?: number; // evm
		queryId?: number; // ton
	}) => {
		try {
			const { route, nonce } = data;
			const { tokenIn, feeInfo } = route;
			const chainId = tokenIn?.chainId;
			if (!isChainHasSwapFee(chainId)) return;
			console.log('start get fee', { feeInfo, nonce, chainId });
			if (!feeInfo) throw new Error('No fee info');

			const { EVM, SOL, TON, TRON } = feeInfo.wallets;

			const feeReceiver = isEvmChain(chainId)
				? EVM
				: isSolanaChain(chainId)
				? SOL
				: isTonChain(chainId)
				? TON
				: isTronChain(chainId)
				? TRON
				: undefined;

			if (!feeReceiver) throw new Error(`Empty fee address ${chainId}`);

			const feeAmount = truncateToFixed(feeInfo.fee, tokenIn.decimals);
			let payload = getPayloadFeeSwap({
				...data,
				feeAmount,
				signatureFeeTransaction: '',
				affiliateCode: swapPrams.affiliateCode,
				communityId: swapPrams.communityId,
			});
			const payloadSend = { amount: feeAmount, to: feeReceiver, token: tokenIn, autoDeductFee: true };

			if (isSolanaChain(chainId)) {
				await takeFeeSolana(payload, payloadSend);
				return;
			}
			if (isEvmChain(chainId)) {
				payload = await takeFeeEvm(payload, payloadSend, { nonce, chainId });
			}
			if (isTronChain(chainId)) {
				payload = await takeFeeTron(payload, payloadSend);
			}
			if (isTonChain(chainId)) {
				payload = await takeFeeTon(payload, payloadSend);
			}
			if (!payload.signatureFeeTransaction) throw new Error('Not found signatureFeeTransaction');
			await ActivitiesServiceAPI.submitMetadataSwapTxs(payload);
		} catch (error) {
			console.error('Take fee error:', error);
			dataDogAddError(error, {
				tags: {
					name: DATADOG_ERROR_TAGS.TRADE,
					function: 'takeSwapFee',
				},
			});
		}
	};
};
