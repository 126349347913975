import React from 'react';

interface IProps extends React.SVGAttributes<HTMLOrSVGElement> {}

export const TronIcon: React.FC<IProps> = ({ width = 56, height = 56, ...rest }) => {
	return (
		<svg width={width} height={height} viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M11.7905 18.8757H15.8142V17.0749C15.8142 15.3512 17.2058 13.9513 18.9283 13.9421C20.602 13.9332 21.9661 15.2837 21.975 16.9585C21.9751 16.986 21.9749 17.0135 21.9743 17.041L21.9342 18.8756H25.8352C27.5145 18.8756 28.8758 20.2379 28.8758 21.9182V25.947H30.7524C32.4079 25.947 33.75 27.29 33.75 28.9466C33.75 30.6032 32.4079 31.9462 30.7524 31.9462H28.8757V35.8995C28.8757 37.5799 27.5145 38.9421 25.8352 38.9421H22.0424V37.0315C22.0424 35.2323 20.5849 33.7738 18.7869 33.7738C16.9889 33.7738 15.5313 35.2323 15.5313 37.0315V38.9421H11.7905C10.1113 38.9421 8.75 37.5799 8.75 35.8995V32.0471H10.8646C12.64 32.0471 14.0793 30.6068 14.0793 28.8303C14.0793 27.0537 12.64 25.6134 10.8646 25.6134H8.75V21.9182C8.75 20.2378 10.1113 18.8757 11.7905 18.8757Z"
				fill="white"
			/>
			<circle cx="20" cy="20" r="20" fill="red" />
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M20.5907 19.3026L28.1464 17.9166L19.4797 28.6111L20.5907 19.3026ZM19.6141 18.8793L18.4497 28.6111L12.1662 12.6389L19.6141 18.8793ZM20.1048 17.5L12.9791 11.6666L24.6249 13.782L20.1048 17.5ZM25.8065 14.3056L28.2818 16.6797L21.511 17.9167L25.8065 14.3056ZM26.1264 13.0108L10 10L18.4874 31.6667L30.3125 17.0489L26.1264 13.0108Z"
				fill="white"
			/>
		</svg>
	);
};
