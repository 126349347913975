import { NAVIGATE_PATHS } from '@/app-constants/router';
import { useOnEventCallback } from '@/app-hooks/common';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { ButtonSecondary, CTAButton } from '../Button/Button';
import { ErrorIcon } from '@/assets/images/svg';
import { Box, Button, Center, Container, Flex, Text } from '@chakra-ui/react';
import { AppLogoIcon } from '@/assets/icons';
import { TelegramCore } from '@/app-cores/telegram';
import { TOBI_SUPPORT_BOT_LINK } from '@/app-constants';

interface ErrorViewProps {
	title: string;
	message?: React.ReactNode;
	children?: React.ReactNode;
	backButtonText?: string;
	onBack?: () => void;
	onRetry?: () => void;
}

export const ErrorViewCreateWallet = () => {
	return (
		<Container className="pageContent">
			<Center flexDirection="column" overflow="hidden" flexGrow={1} gap={4}>
				<Flex flexDirection={'column'} width={'100%'} gap={2} alignItems={'center'}>
					<AppLogoIcon />
					<Text fontWeight={400}>Oops!</Text>
					<Text mt={1} fontSize="xs" textAlign={'center'}>
						There was a network connection issue. Please check your connection and try again.
					</Text>
				</Flex>
				<Flex flexDirection={'column'} width={'100%'} gap={3}>
					<CTAButton
						colorScheme="cyan"
						size="lg"
						width="100%"
						onClick={() => {
							window.location.reload();
						}}
					>
						Retry
					</CTAButton>
					<ButtonSecondary
						size="lg"
						width="100%"
						onClick={() => {
							TelegramCore.WebApp.openTelegramLink(TOBI_SUPPORT_BOT_LINK);
						}}
					>
						Contact Support
					</ButtonSecondary>
				</Flex>
			</Center>
		</Container>
	);
};

const ErrorView: React.FC<ErrorViewProps> = ({ title, message, children, backButtonText, onBack, onRetry }) => {
	const navigate = useNavigate();
	const { t } = useTranslation();

	const onBackHome = useOnEventCallback(() => {
		if (typeof onBack === 'function') {
			onBack();
		} else {
			navigate(NAVIGATE_PATHS.Home);
		}
	});

	const handleRetry = useOnEventCallback(() => {
		if (typeof onRetry === 'function') {
			onRetry();
		}
	});

	return (
		<Container className="pageContent">
			<Box className="pageFixed" py={6} px={4}>
				<Center flexDirection="column" overflow="hidden" flexGrow={1}>
					<ErrorIcon width={56} height={56} />
					<Text mt={2} fontWeight={700}>
						{title}
					</Text>
					{message ? (
						<Text mt={1} fontSize="xs" color="gray.400" textAlign={'center'}>
							{message}
						</Text>
					) : null}
					{children}
				</Center>

				<Box>
					<Button display="block" mb={2} size="lg" fontWeight="medium" width="100%" onClick={onBackHome}>
						{backButtonText || t('button.backHome')}
					</Button>
					{typeof onRetry === 'function' && (
						<CTAButton
							colorScheme="cyan"
							size="lg"
							fontWeight="medium"
							width="100%"
							className="w-100"
							onClick={handleRetry}
						>
							{t('button.retry')}
						</CTAButton>
					)}
				</Box>
			</Box>
		</Container>
	);
};

export default ErrorView;
