import { TelegramCore } from '@/app-cores/telegram';

const TOUR_GUIDE_STORAGE = {
	ENABLE_HOME: {
		KEY: `@TOBI_${TelegramCore.getUserId()}_ENABLE_HOME_TOUR_GUIDE`,
		VALUE: '1',
	},
	ENABLE_TRADE: {
		KEY: `@TOBI_${TelegramCore.getUserId()}_ENABLE_TRADE_TOUR_GUIDE`,
		VALUE: '1',
	},
};
export const tourGuideManager = {
	enable: () => {
		localStorage.setItem(TOUR_GUIDE_STORAGE.ENABLE_HOME.KEY, TOUR_GUIDE_STORAGE.ENABLE_HOME.VALUE);
		localStorage.setItem(TOUR_GUIDE_STORAGE.ENABLE_TRADE.KEY, TOUR_GUIDE_STORAGE.ENABLE_TRADE.VALUE);
	},
	isEnableHomePage: () => {
		return localStorage.getItem(TOUR_GUIDE_STORAGE.ENABLE_HOME.KEY) === TOUR_GUIDE_STORAGE.ENABLE_HOME.VALUE;
	},
	isEnableTradePage: () => {
		return localStorage.getItem(TOUR_GUIDE_STORAGE.ENABLE_TRADE.KEY) === TOUR_GUIDE_STORAGE.ENABLE_TRADE.VALUE;
	},
	disableHomePage: () => {
		localStorage.removeItem(TOUR_GUIDE_STORAGE.ENABLE_HOME.KEY);
	},
	disableTradePage: () => {
		localStorage.removeItem(TOUR_GUIDE_STORAGE.ENABLE_TRADE.KEY);
	},
};
