import { Button } from '@chakra-ui/react';

interface ActionConfirmProps {
	onApprove: () => void;
	isLoadingApprove?: boolean;
	onReject: () => void;
	isLoadingReject?: boolean;
}
export const ActionConfirm: React.FC<ActionConfirmProps> = ({
	onApprove,
	onReject,
	isLoadingApprove,
	isLoadingReject,
}) => {
	return (
		<>
			<Button
				fontWeight="medium"
				size="lg"
				flex={2}
				colorScheme="cyan"
				onClick={onApprove}
				isLoading={isLoadingApprove}
				isDisabled={isLoadingApprove}
			>
				Confirm
			</Button>
			<Button
				fontWeight="medium"
				size="lg"
				flex={1}
				onClick={onReject}
				isLoading={isLoadingReject}
				isDisabled={isLoadingReject}
			>
				Cancel
			</Button>
		</>
	);
};
