// import { RELAYER_EVENTS } from '@walletconnect/core';
import { useEffect } from 'react';
import { useConnect } from '../hooks';
import useInitialization from '../hooks/useInitialization';
import useWalletConnectEventsManager from '../hooks/useWalletConnectEventsManager';
import { WalletConnectRequestModal } from './WalletConnectRequestModal';
import { parseUri } from '@walletconnect/utils';
import { TelegramCore } from '@/app-cores/telegram';
import { formatTelegramStartParams } from '@/app-helpers/string';

export const WalletConnect = () => {
	const initialized = useInitialization();
	const { onConnect } = useConnect();
	useWalletConnectEventsManager(initialized);
	const start_param = TelegramCore.WebApp.initDataUnsafe.start_param || '';
	const uri = formatTelegramStartParams(start_param);
	const { topic, symKey, relay } = parseUri(uri);
	const isRequestConnection = topic && relay && symKey;

	useEffect(() => {
		if (!initialized || !isRequestConnection) return;
		onConnect(uri);
		// web3wallet?.core.relayer.on(RELAYER_EVENTS.connect, (res) => {
		// 	console.log('Network connection is restored!', res);
		// });
		// web3wallet?.core.relayer.on(RELAYER_EVENTS.disconnect, (res) => {
		// 	console.log('Network connection lost', res);
		// });
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [initialized, isRequestConnection, uri]);

	// useInAppAConnect();
	if (!initialized) return null;

	return <WalletConnectRequestModal />;
};
