import React from 'react';

interface IProps extends React.SVGAttributes<HTMLOrSVGElement> {}

export const FiLogoutIcon: React.FC<IProps> = ({ width = 16, height = 16, ...rest }) => {
	return (
		<svg width={width} height={height} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M11.3333 4.66667L10.3933 5.60667L12.1133 7.33333H5.33331V8.66667H12.1133L10.3933 10.3867L11.3333 11.3333L14.6666 8L11.3333 4.66667ZM2.66665 3.33333H7.99998V2H2.66665C1.93331 2 1.33331 2.6 1.33331 3.33333V12.6667C1.33331 13.4 1.93331 14 2.66665 14H7.99998V12.6667H2.66665V3.33333Z"
				fill="#E32A2A"
			/>
		</svg>
	);
};
