import React from 'react';

interface IProps extends React.SVGAttributes<HTMLOrSVGElement> {}

export const FiTelegramIcon: React.FC<IProps> = ({ width = 48, height = 48, fill = 'black', ...rest }) => {
	return (
		<svg width={width} height={height} viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
			<g clipPath="url(#clip0_11662_10208)">
				<path
					d="M24 48C37.2548 48 48 37.2548 48 24C48 10.7452 37.2548 0 24 0C10.7452 0 0 10.7452 0 24C0 37.2548 10.7452 48 24 48Z"
					fill="url(#paint0_linear_11662_10208)"
				/>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M10.8641 23.7469C17.8605 20.6986 22.526 18.689 24.8603 17.7181C31.5253 14.9459 32.9103 14.4643 33.8129 14.4484C34.0115 14.4449 34.4554 14.4942 34.7429 14.7275C35.1695 15.0736 35.1719 15.8249 35.1246 16.3222C34.7634 20.1172 33.2006 29.3265 32.4055 33.577C32.069 35.3755 31.4066 35.9786 30.7653 36.0375C29.3716 36.1658 28.3133 35.1164 26.9634 34.2316C24.8511 32.8469 23.6577 31.985 21.6073 30.6339C19.2378 29.0724 20.7739 28.2142 22.1243 26.8116C22.4777 26.4446 28.6184 20.859 28.7373 20.3523C28.7522 20.2889 28.766 20.0527 28.6256 19.928C28.4853 19.8033 28.2782 19.8459 28.1288 19.8798C27.9169 19.9279 24.5425 22.1583 18.0055 26.5709C17.0476 27.2287 16.1801 27.5491 15.4028 27.5323C14.5458 27.5139 12.8975 27.0478 11.6721 26.6495C10.1691 26.161 8.97452 25.9026 9.07858 25.0729C9.13277 24.6406 9.7279 24.1987 10.8641 23.7469Z"
					fill="white"
				/>
			</g>
			<defs>
				<linearGradient
					id="paint0_linear_11662_10208"
					x1="24"
					y1="0"
					x2="24"
					y2="47.625"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#2AABEE" />
					<stop offset="1" stopColor="#229ED9" />
				</linearGradient>
				<clipPath id="clip0_11662_10208">
					<rect width="48" height="48" fill="white" />
				</clipPath>
			</defs>
		</svg>
	);
};
