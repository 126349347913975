import { ChainId, MAINNET_CHAINS, TEST_NETS } from '@/app-constants/chains';
import { FeatureConfig, useQueryFeatureConfig } from '@/app-hooks/api/configuration';
import { useMemo } from 'react';

const useChainList = () => {
	const isSupportTestnet = useQueryFeatureConfig(FeatureConfig.BERA_TESTNET_SUPPORT);
	const isDisableSuiNetwork = useQueryFeatureConfig(FeatureConfig.DISABLE_SUI_NETWORK);

	return useMemo(() => {
		let chainsSupport = isSupportTestnet ? MAINNET_CHAINS.concat(TEST_NETS) : MAINNET_CHAINS;
		if (isDisableSuiNetwork) chainsSupport = chainsSupport.filter((e) => e.id !== ChainId.SUI);
		const chainsOptions = chainsSupport.map((el) => ({ value: el.id.toString(), label: el.name }));
		return { chainsSupport, chainsOptions };
	}, [isSupportTestnet, isDisableSuiNetwork]);
};
export default useChainList;
