import { QUERY_KEYS } from '@/app-constants';
import { ContactManagementAPI, QueryParams } from '@/app-cores/api/contact';
import { UseQueryOptions, keepPreviousData, useQuery } from '@tanstack/react-query';

export const useQueryContactList = (
	queryParams: QueryParams,
	options?: Omit<UseQueryOptions<Awaited<ReturnType<typeof ContactManagementAPI.getContactList>>>, 'queryKey'>,
) => {
	const response = useQuery({
		queryKey: [QUERY_KEYS.GET_CONTACT_LIST, queryParams],
		queryFn: async () => {
			return await ContactManagementAPI.getContactList(queryParams);
		},
		placeholderData: keepPreviousData,
		...options,
	});

	return response;
};
