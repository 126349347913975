import React from 'react';

interface IProps extends React.SVGAttributes<HTMLOrSVGElement> {}

export const FiDappIcon: React.FC<IProps> = ({ width = 49, height = 48, ...rest }) => {
	return (
		<svg width={width} height={height} viewBox="0 0 49 48" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M16.7547 15.665C16.3681 15.665 16.0547 15.9784 16.0547 16.365V19.165C16.0547 19.5516 16.3681 19.865 16.7547 19.865H19.5547C19.9413 19.865 20.2547 19.5516 20.2547 19.165V16.365C20.2547 15.9784 19.9413 15.665 19.5547 15.665H16.7547Z"
				fill="black"
			/>
			<path
				d="M29.4456 15.665C29.059 15.665 28.7456 15.9784 28.7456 16.365V19.165C28.7456 19.5516 29.059 19.865 29.4456 19.865H32.2456C32.6322 19.865 32.9456 19.5516 32.9456 19.165V16.365C32.9456 15.9784 32.6322 15.665 32.2456 15.665H29.4456Z"
				fill="black"
			/>
			<path
				d="M16.7547 28.1338C16.3681 28.1338 16.0547 28.4472 16.0547 28.8338V31.6338C16.0547 32.0204 16.3681 32.3338 16.7547 32.3338H19.5547C19.9413 32.3338 20.2547 32.0204 20.2547 31.6338V28.8338C20.2547 28.4472 19.9413 28.1338 19.5547 28.1338H16.7547Z"
				fill="black"
			/>
			<path
				d="M29.4456 28.1338C29.059 28.1338 28.7456 28.4472 28.7456 28.8338V31.6338C28.7456 32.0204 29.059 32.3338 29.4456 32.3338H32.2456C32.6322 32.3338 32.9456 32.0204 32.9456 31.6338V28.8338C32.9456 28.4472 32.6322 28.1338 32.2456 28.1338H29.4456Z"
				fill="black"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M6.2959 12.8195C6.2959 9.89243 8.6688 7.51953 11.5959 7.51953H37.404C40.3311 7.51953 42.704 9.89243 42.704 12.8195V35.18C42.704 38.1072 40.3311 40.48 37.404 40.48H11.5959C8.6688 40.48 6.2959 38.1072 6.2959 35.18V12.8195ZM13.4546 16.3649C13.4546 14.5424 14.932 13.0649 16.7546 13.0649H19.5546C21.3771 13.0649 22.8546 14.5424 22.8546 16.3649V19.1649C22.8546 20.9875 21.3771 22.4649 19.5546 22.4649H16.7546C14.932 22.4649 13.4546 20.9875 13.4546 19.1649V16.3649ZM26.1455 16.3649C26.1455 14.5424 27.6229 13.0649 29.4455 13.0649H32.2455C34.068 13.0649 35.5455 14.5424 35.5455 16.3649V19.1649C35.5455 20.9875 34.068 22.4649 32.2455 22.4649H29.4455C27.6229 22.4649 26.1455 20.9875 26.1455 19.1649V16.3649ZM13.4546 28.8339C13.4546 27.0114 14.932 25.5339 16.7546 25.5339H19.5546C21.3771 25.5339 22.8546 27.0114 22.8546 28.8339V31.6339C22.8546 33.4565 21.3771 34.9339 19.5546 34.9339H16.7546C14.932 34.9339 13.4546 33.4565 13.4546 31.6339V28.8339ZM26.1455 28.8339C26.1455 27.0114 27.6229 25.5339 29.4455 25.5339H32.2455C34.068 25.5339 35.5455 27.0114 35.5455 28.8339V31.6339C35.5455 33.4565 34.068 34.9339 32.2455 34.9339H29.4455C27.6229 34.9339 26.1455 33.4565 26.1455 31.6339V28.8339Z"
				fill="#00E9DB"
			/>
		</svg>
	);
};
