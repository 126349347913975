import { isEmpty } from 'lodash-es';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Header, Loading } from '@/app-components/common';
import PageTransition from '@/app-components/layout/PageTransition/PageTransition';
import PageNavigation from '@/app-components/layout/PageWrapper/PageWrapper';
import { Box, Button, Center, Container } from '@chakra-ui/react';
import { ConfirmDisconnectPopup, ConnectionItem, EmptyConnection, SelectMethodPopup } from '../components';
import { StoreSession, useConnectionManager } from '../hooks';
import { useModalStore } from '../store/ModalStore';

export const WCConnections = () => {
	const { t } = useTranslation();
	const { sessions } = useConnectionManager();
	const [session, setSession] = useState<StoreSession>(null);
	const [openPopupConfirm, setOpenPopupConfirm] = useState(false);
	const [openSelectMethodPopup, setOpenSelectMethodPopup] = useState(false);
	const { isWaiting } = useModalStore();

	const renderContent = () => {
		if (sessions.length < 1) return <EmptyConnection onAddConnection={() => setOpenSelectMethodPopup(true)} />;
		return (
			<Box className="pageFixed" py={4}>
				<Center position="relative">
					<Header>{t('dapp.dAppConnections')}</Header>
					<Button
						onClick={() => setOpenSelectMethodPopup(true)}
						size="sm"
						colorScheme="cyan"
						position="absolute"
						right={4}
						top={-2}
					>
						+ {t('button.new')}
					</Button>
				</Center>
				<Box mt={4} flexDirection="column" overflow="hidden" className="scrollbar-trigger" flexGrow={1}>
					<Box overflow="auto" height="100%" px={4}>
						{sessions.map((session) => {
							return (
								<ConnectionItem
									session={session}
									key={session.id}
									onClick={() => {
										setSession(session);
										setOpenPopupConfirm(true);
									}}
								></ConnectionItem>
							);
						})}
					</Box>
				</Box>
			</Box>
		);
	};
	return (
		<PageTransition>
			<PageNavigation>
				<Container className="pageContent" backgroundColor="gray.100">
					{isWaiting && <Loading />}
					{renderContent()}
					{!isEmpty(session) && (
						<ConfirmDisconnectPopup
							isOpen={openPopupConfirm}
							session={session}
							onClose={() => setOpenPopupConfirm(false)}
						/>
					)}

					<SelectMethodPopup isOpen={openSelectMethodPopup} onClose={() => setOpenSelectMethodPopup(false)} />
				</Container>
			</PageNavigation>
		</PageTransition>
	);
};
