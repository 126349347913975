import React from 'react';

interface IProps extends React.SVGAttributes<HTMLOrSVGElement> {}

export const FiBookIcon: React.FC<IProps> = ({ width = 20, height = 20, fill = '#161515"', ...rest }) => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 20 20" fill="none" {...rest}>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M0.833313 2.49996C0.833313 2.03972 1.20641 1.66663 1.66665 1.66663H6.66665C7.77171 1.66663 8.83152 2.10561 9.61292 2.88701C10.3943 3.66842 10.8333 4.72822 10.8333 5.83329V17.5C10.8333 17.9602 10.4602 18.3333 9.99998 18.3333C9.53974 18.3333 9.16665 17.9602 9.16665 17.5C9.16665 17.0579 8.99105 16.634 8.67849 16.3214C8.36593 16.0089 7.94201 15.8333 7.49998 15.8333H1.66665C1.20641 15.8333 0.833313 15.4602 0.833313 15V2.49996ZM9.16665 14.6132V5.83329C9.16665 5.17025 8.90325 4.53437 8.43441 4.06553C7.96557 3.59668 7.32969 3.33329 6.66665 3.33329H2.49998V14.1666H7.49998C8.09017 14.1666 8.66423 14.3231 9.16665 14.6132Z"
				fill="#161515"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M10.3871 2.88701C11.1685 2.10561 12.2283 1.66663 13.3334 1.66663H18.3334C18.7936 1.66663 19.1667 2.03972 19.1667 2.49996V15C19.1667 15.4602 18.7936 15.8333 18.3334 15.8333H12.5C12.058 15.8333 11.6341 16.0089 11.3215 16.3214C11.0089 16.634 10.8334 17.0579 10.8334 17.5C10.8334 17.9602 10.4603 18.3333 10 18.3333C9.53978 18.3333 9.16669 17.9602 9.16669 17.5V5.83329C9.16669 4.72822 9.60567 3.66842 10.3871 2.88701ZM10.8334 14.6132C11.3358 14.3231 11.9098 14.1666 12.5 14.1666H17.5V3.33329H13.3334C12.6703 3.33329 12.0344 3.59668 11.5656 4.06553C11.0967 4.53437 10.8334 5.17025 10.8334 5.83329V14.6132Z"
				fill="#161515"
			/>
		</svg>
	);
};
