import React from 'react';

interface IProps extends React.SVGAttributes<HTMLOrSVGElement> {}

export const FiEditIcon: React.FC<IProps> = ({ width = 20, height = 20, ...rest }) => {
	return (
		<svg width={width} height={height} {...rest} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M9.1665 16.6668C9.1665 16.2066 9.5396 15.8335 9.99984 15.8335H17.4998C17.9601 15.8335 18.3332 16.2066 18.3332 16.6668C18.3332 17.1271 17.9601 17.5002 17.4998 17.5002H9.99984C9.5396 17.5002 9.1665 17.1271 9.1665 16.6668Z"
				fill="black"
				fillOpacity="0.32"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M14.9999 3.2321C14.752 3.2321 14.5144 3.33055 14.3391 3.50579L4.08566 13.7592L3.64516 15.5212L5.40715 15.0807L15.6606 4.82727C15.7474 4.7405 15.8162 4.63749 15.8632 4.52412C15.9101 4.41075 15.9343 4.28924 15.9343 4.16653C15.9343 4.04382 15.9101 3.92231 15.8632 3.80894C15.8162 3.69557 15.7474 3.59256 15.6606 3.50579C15.5738 3.41902 15.4708 3.35019 15.3574 3.30323C15.2441 3.25627 15.1226 3.2321 14.9999 3.2321ZM13.1606 2.32727C13.6484 1.83947 14.31 1.56543 14.9999 1.56543C15.3414 1.56543 15.6797 1.63271 15.9953 1.76343C16.3108 1.89414 16.5976 2.08574 16.8391 2.32727C17.0806 2.56881 17.2722 2.85555 17.403 3.17113C17.5337 3.48671 17.601 3.82495 17.601 4.16653C17.601 4.50811 17.5337 4.84635 17.403 5.16193C17.2722 5.47751 17.0806 5.76425 16.8391 6.00579L6.42244 16.4225C6.31564 16.5293 6.18183 16.605 6.0353 16.6416L2.70197 17.475C2.41799 17.546 2.11758 17.4628 1.9106 17.2558C1.70362 17.0488 1.62041 16.7484 1.6914 16.4644L2.52474 13.1311C2.56137 12.9846 2.63713 12.8507 2.74393 12.7439L13.1606 2.32727Z"
				fill="black"
				fillOpacity="0.32"
			/>
		</svg>
	);
};
