import { FiChristmasStartsIcon, FiMaximizeIcon, FiMinimizeIcon, FiXIcon } from '@/assets/icons';
import { Box, Center, Text } from '@chakra-ui/react';

interface ChatBoxHeaderProps {
	onExpand: () => void;
	isExpand: boolean;
	onClose: () => void;
}
export const ChatBoxHeader: React.FC<ChatBoxHeaderProps> = ({ onExpand, isExpand, onClose }) => {
	return (
		<Center justifyContent="space-between" borderBottom="1px solid rgba(0, 0, 0, 0.08)" px={3} pb={4}>
			<Center gap={2}>
				<FiChristmasStartsIcon />
				<Text fontSize="sm" fontWeight={500}>
					Tobi Assist
				</Text>
			</Center>
			<Center gap="10px">
				{!isExpand ? (
					<Box onClick={onExpand} cursor="pointer">
						<FiMaximizeIcon />
					</Box>
				) : (
					<Box onClick={onExpand} cursor="pointer">
						<FiMinimizeIcon />
					</Box>
				)}
				<Box onClick={onClose} cursor="pointer">
					<FiXIcon />
				</Box>
			</Center>
		</Center>
	);
};
