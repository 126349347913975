import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Flex } from '@chakra-ui/react';

import { LocalLoader } from '@/app-components/common';
import NoData from '@/app-components/common/NoData';
import { TradingViewChart } from '@/app-components/common/Chart/TradingViewChart';
import { ChartMode } from '@/app-store/settings';

interface BasicInformationProps {
	chartData: Array<{ timestamp: number; totalUsd: number }>;
	isFetching: boolean;
}

export const BalanceChart: React.FunctionComponent<BasicInformationProps> = ({ chartData: data, isFetching }) => {
	const { t } = useTranslation();
	const formatChartData = useMemo(() => {
		return data?.map((el) => ({ time: el.timestamp, value: el.totalUsd })) ?? [];
	}, [data]);

	if (!formatChartData.length || isFetching)
		return (
			<Flex height={'250px'} fontSize={'12px'} alignItems={'center'}>
				{isFetching ? <LocalLoader /> : <NoData msg={t('cryptos.noDataForChartBalance')} />}
			</Flex>
		);
	return <TradingViewChart chartData={formatChartData} mode={ChartMode.LINE} isLoading={isFetching} />;
};
