import { Web3WalletTypes } from '@walletconnect/web3wallet';
import { SignClientTypes } from '@walletconnect/types';
import { useCallback, useEffect, useRef } from 'react';
import { useSnapshot } from 'valtio';
import SettingsStore from '../store/SettingsStore';
import { web3wallet } from '../utils/WalletConnectUtil';
import { EIP155_SIGNING_METHODS } from '../data/EIP155Data';
import { useModalStore } from '../store/ModalStore';
import { getSignTypedDataParamsData, paringTrackingManager } from '../utils/HelperUtil';
import { useGlobalToast } from '@/app-store';
import { SOLANA_SIGNING_METHODS } from '../data/SolanaData';
import { ConnectionType } from '@/app-types/connection-type';
import { TRON_SIGNING_METHODS } from '../data/TronData';

export default function useWalletConnectEventsManager(initialized: boolean) {
	const { onOpen, isWaiting, setIsWaiting } = useModalStore();
	const { reinitialized } = useSnapshot(SettingsStore.state);
	const { addToast } = useGlobalToast();
	const timerRef = useRef<NodeJS.Timeout>(null);
	const onSessionProposal = useCallback((proposal: SignClientTypes.EventArguments['session_proposal']) => {
		console.log('proposal', proposal);
		SettingsStore.setCurrentRequestVerifyContext(proposal.verifyContext);
		onOpen('SessionProposalModal', { proposal });
		// eslint-disable-next-line
	}, []);

	const onAuthRequest = useCallback((request: Web3WalletTypes.AuthRequest) => {
		onOpen('AuthRequestModal', { request });
		// eslint-disable-next-line
	}, []);

	const onSessionRequest = useCallback(async (requestEvent: SignClientTypes.EventArguments['session_request']) => {
		const { topic, params, verifyContext } = requestEvent;
		const { request } = params;
		const requestSession = web3wallet.engine.signClient.session.get(topic);
		SettingsStore.setCurrentRequestVerifyContext(verifyContext);
		paringTrackingManager.setActiveTime(ConnectionType.WalletConnect, requestSession?.pairingTopic);
		switch (request.method) {
			case EIP155_SIGNING_METHODS.ETH_SIGN:
			case EIP155_SIGNING_METHODS.PERSONAL_SIGN:
				return onOpen('SessionSignModal', { requestEvent, requestSession });

			case EIP155_SIGNING_METHODS.ETH_SIGN_TYPED_DATA:
			case EIP155_SIGNING_METHODS.ETH_SIGN_TYPED_DATA_V3:
			case EIP155_SIGNING_METHODS.ETH_SIGN_TYPED_DATA_V4: {
				const transaction = getSignTypedDataParamsData(request.params);
				if (transaction?.primaryType === 'Permit') {
					return onOpen('SessionApprovalModal', { requestEvent, requestSession });
				}
				return onOpen('SessionSignTypedDataModal', { requestEvent, requestSession });
			}

			case EIP155_SIGNING_METHODS.ETH_SEND_TRANSACTION:
			case EIP155_SIGNING_METHODS.ETH_SIGN_TRANSACTION:
				return onOpen('SessionSendTransactionModal', { requestEvent, requestSession });
			case SOLANA_SIGNING_METHODS.SOLANA_SIGN_MESSAGE:
				return onOpen('SessionSignMessageSolanaModal', { requestEvent, requestSession });
			case SOLANA_SIGNING_METHODS.SOLANA_SIGN_TRANSACTION:
				return onOpen('SessionSignTransactionSolanaModal', { requestEvent, requestSession });
			case TRON_SIGNING_METHODS.TRON_SIGN_MESSAGE:
				return onOpen('SessionSignMessageTronModal', { requestEvent, requestSession });
			case TRON_SIGNING_METHODS.TRON_SIGN_TRANSACTION:
				return onOpen('SessionSignTransactionTronModal', { requestEvent, requestSession });
			default:
				return onOpen('SessionUnSupportedMethodModal', { requestEvent, requestSession });
		}
		// eslint-disable-next-line
	}, []);

	/** Set up WalletConnect event listeners */
	useEffect(() => {
		if (initialized && web3wallet) {
			web3wallet.on('session_proposal', onSessionProposal);
			web3wallet.on('session_request', onSessionRequest);
			web3wallet.on('auth_request', onAuthRequest);
			web3wallet.engine.signClient.events.on('session_ping', (data) => console.log('ping', data));
			web3wallet.on('session_delete', (data) => {
				console.log('session_delete event received', data, web3wallet.getActiveSessions());
				SettingsStore.setSessions(Object.values(web3wallet.getActiveSessions()));
			});
			web3wallet.engine.signClient.events.on('session_expire', (data) => {
				console.log('session_expire', data);
			});
			console.log('load sessions on init', web3wallet.getActiveSessions());
			SettingsStore.setInitialized(initialized);
			SettingsStore.setSessions(Object.values(web3wallet.getActiveSessions()));
		}
	}, [initialized, onAuthRequest, onSessionProposal, onSessionRequest, reinitialized]);

	useEffect(() => {
		if (!isWaiting) return;
		timerRef.current = setTimeout(() => {
			addToast({
				type: 'error',
				message: 'Connection Failed. Please try again.',
			});
			setIsWaiting(false);
		}, 5000);
		return () => clearTimeout(timerRef.current);
		// eslint-disable-next-line
	}, [isWaiting]);
}
