import { SessionTypes, Verify } from '@walletconnect/types';
import { proxy } from 'valtio';
import { web3wallet } from '../utils/WalletConnectUtil';
import { WalletConnectServiceAPI } from '@/app-cores/api/walletconnect';
import { STORAGE_KEYS } from '@/app-constants/storage';

interface State {
	testNets: boolean;
	account: number;
	eip155Address: string;
	relayerRegionURL: string;
	activeChainId: string;
	currentRequestVerifyContext?: Verify.Context;
	sessions: SessionTypes.Struct[];
	smartAccountSponsorshipEnabled: boolean;
	smartAccountEnabled: boolean;
	isInternal: boolean;
	initialized: boolean;
	reinitialized?: string;
}

const initialState: State = {
	testNets: typeof localStorage !== 'undefined' ? Boolean(localStorage.getItem(STORAGE_KEYS.TOBI_TEST_NETS)) : true,
	account: 0,
	activeChainId: '1',
	eip155Address: '',
	relayerRegionURL: '',
	sessions: [],
	smartAccountSponsorshipEnabled: false,
	smartAccountEnabled: false,
	isInternal: false,
	initialized: false,
	reinitialized: new Date().toISOString(),
};

const state = proxy<State>(initialState);

const SettingsStore = {
	state,

	setAccount(value: number) {
		state.account = value;
	},

	setEIP155Address(eip155Address: string) {
		state.eip155Address = eip155Address;
	},

	setActiveChainId(value: string) {
		state.activeChainId = value;
	},

	setCurrentRequestVerifyContext(context: Verify.Context) {
		state.currentRequestVerifyContext = context;
	},

	setSessions(sessions: SessionTypes.Struct[]) {
		state.sessions = sessions;

		if (sessions.length) {
			WalletConnectServiceAPI.subscribe(
				sessions.map((item) => ({
					topic: item.topic,
					key: web3wallet.core.crypto.keychain.get(item.topic),
					dapp: item.peer.metadata,
				})),
			);
		}
	},

	toggleTestNets() {
		state.testNets = !state.testNets;
		if (state.testNets) {
			state.smartAccountSponsorshipEnabled = true;
			localStorage.setItem(STORAGE_KEYS.TOBI_TEST_NETS, 'YES');
		} else {
			state.smartAccountSponsorshipEnabled = false;
			localStorage.removeItem(STORAGE_KEYS.TOBI_TEST_NETS);
		}
	},

	toggleSmartAccountSponsorship() {
		if (!state.testNets) return;
		state.smartAccountSponsorshipEnabled = !state.smartAccountSponsorshipEnabled;
	},

	toggleSmartAccountEnabled() {
		state.smartAccountEnabled = !state.smartAccountEnabled;
	},

	setRelayerRegionURL(relayerRegionURL: string) {
		state.relayerRegionURL = relayerRegionURL;
	},
	setIsInternal(isInternal: boolean) {
		state.isInternal = isInternal;
	},
	setInitialized(initialized: boolean) {
		state.initialized = initialized;
	},
	setReinitialized(id: string) {
		state.reinitialized = id;
	},
};

export default SettingsStore;
