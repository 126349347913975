import { Avatar, Box, BoxProps, Button, Flex, Text } from '@chakra-ui/react';
import { isAddress } from 'ethers';
import { Badge } from '..';
import { FiCheckIcon } from '@/assets/icons';
import { getBlockChainNameByChainId, getBlockChainNameByAddress, getShortAddress } from '@/app-helpers/address';
import { useSentTokenStore } from '@/app-store';

interface UserAddressProps extends Omit<BoxProps, 'address' | 'onSelect'> {
	address: string;
	selected?: boolean;
	onSelect?: (address: string) => void;
	label?: string;
}
export const UserAddress: React.FC<UserAddressProps> = ({ address, selected, onSelect, label, ...boxProps }) => {
	const blockChainNameByAddress = getBlockChainNameByAddress(address);
	const { token, tokenInfo } = useSentTokenStore();
	const blockChainName = getBlockChainNameByChainId(tokenInfo?.chainId);

	const isNotMatchAddressWithChain = tokenInfo?.chainId && blockChainName !== blockChainNameByAddress;
	if (!blockChainNameByAddress || isNotMatchAddressWithChain) return null;
	return (
		<Flex
			alignItems="center"
			gap={3}
			py={2}
			onClick={() => onSelect && onSelect(address)}
			px={4}
			_hover={{
				backgroundColor: 'gray.100',
			}}
			transition="300ms"
			cursor="pointer"
			borderRadius={8}
			{...boxProps}
		>
			<Avatar src="" name={address} size="sm"></Avatar>
			<Box>
				{label && (
					<Text fontSize="sm" fontWeight={600}>
						Address
					</Text>
				)}
				<Text fontSize="xs" color="gray.400" wordBreak="break-all" mr={2}>
					{getShortAddress(address, {
						start: 20,
						end: 8,
					})}
				</Text>
			</Box>
			{selected && (
				<Box marginLeft="auto" as="span">
					<Badge width={8} height={8} backgroundColor="rgba(0, 193, 112, 0.08)">
						<FiCheckIcon fill="#00C170" />
					</Badge>
				</Box>
			)}
		</Flex>
	);
};
