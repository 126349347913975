import { create } from 'zustand';
const initializeData = {
	isShow: false,
	skipNavigation: false,
};

type SetShowPayload = {
	isShow: boolean;
	backButtonCallback?: (...args: any[]) => void;
	skipNavigation?: boolean;
};
export interface IBackButtonStore {
	isShow: boolean;
	skipNavigation: boolean;
	callBack?: (...args: any[]) => void;
	setShow: (payload: SetShowPayload) => void;
	reset: () => void;
}

export const useBackButton = create<IBackButtonStore>((set) => ({
	...initializeData,
	setShow: (payload: SetShowPayload) =>
		set({
			isShow: payload.isShow,
			callBack: payload.backButtonCallback,
			skipNavigation: payload.skipNavigation,
		}),
	reset: () => set(initializeData),
}));
