import React from 'react';

interface Iprops {
	width?: number;
	height?: number;
	className?: string;
	style?: React.CSSProperties;
}

export const StampIcon: React.FC<Iprops> = (props) => {
	return (
		<svg {...props} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="56 40 281 30.94">
			<g filter="url(#filter0_d_6578_8755)">
				<path
					d="M103.931 51.4715C109.771 51.4715 114.505 46.4134 114.505 40.1738C114.505 40.1158 114.505 40.0578 114.504 40H131.752C131.751 40.0578 131.75 40.1158 131.75 40.1738C131.75 46.4134 136.485 51.4715 142.325 51.4715C148.165 51.4715 152.9 46.4134 152.9 40.1738C152.9 40.1158 152.899 40.0578 152.898 40H167.542C167.542 57.0868 180.507 70.9383 196.5 70.9383C212.493 70.9383 225.458 57.0868 225.458 40H239.776C239.775 40.0578 239.775 40.1158 239.775 40.1738C239.775 46.4134 244.51 51.4715 250.35 51.4715C256.19 51.4715 260.924 46.4134 260.924 40.1738C260.924 40.1158 260.924 40.0578 260.923 40H278.171C278.17 40.0578 278.169 40.1158 278.169 40.1738C278.169 46.4134 282.904 51.4715 288.744 51.4715C294.584 51.4715 299.319 46.4134 299.319 40.1738C299.319 40.1158 299.318 40.0578 299.317 40H337V70.9383H196.5H56V40H93.3571C93.3563 40.0578 93.3559 40.1158 93.3559 40.1738C93.3559 46.4134 98.0903 51.4715 103.931 51.4715Z"
					fill="white"
				/>
			</g>
			<defs>
				<filter
					id="filter0_d_6578_8755"
					x="-8"
					y="0"
					width="409"
					height="158.938"
					filterUnits="userSpaceOnUse"
					colorInterpolationFilters="sRGB"
				>
					<feFlood floodOpacity="0" result="BackgroundImageFix" />
					<feColorMatrix
						in="SourceAlpha"
						type="matrix"
						values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
						result="hardAlpha"
					/>
					<feOffset dy="24" /> <feGaussianBlur stdDeviation="32" />
					<feComposite in2="hardAlpha" operator="out" />
					<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.12 0" />
					<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_6578_8755" />
					<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_6578_8755" result="shape" />
				</filter>
			</defs>
		</svg>
	);
};
