import { Center } from '@chakra-ui/react';
import { useNavigate } from 'react-router';
import { NAVIGATE_PATHS } from '@/app-constants/router';
import { FiHomeIcon } from '@/assets/icons';
import { ActionBlock } from '@/app-components/common/ActionBlock';

export const HomeIcon = () => {
	const navigate = useNavigate();
	return (
		<ActionBlock onClick={() => navigate(NAVIGATE_PATHS.Home)}>
			<Center width={8} height={8}>
				<FiHomeIcon />
			</Center>
		</ActionBlock>
	);
};
