import { axiosBotAPI } from '@/app-cores/api/axios';
import { ChartInterval } from '@/app-store/settings';
import uniqBy from 'lodash/uniqBy';

export type ChartData = Array<{ open: number; close: number; low: number; high: number; timestamp: number }>;

export type TokenPriceChartParams = {
	interval: ChartInterval;
	from: number;
	to: number;
	tobiId: string;
};

class Chart {
	async fetchPriceChart(params: TokenPriceChartParams): Promise<ChartData> {
		const response = await axiosBotAPI.get(`v2/token/candlestick-chart`, { params });
		const data: ChartData = response.data?.data?.data ?? [];
		const formatData = uniqBy(data, 'timestamp');
		return formatData.sort((a, b) => a.timestamp - b.timestamp);
	}
}

export const ChartService = new Chart();
