import Warning from '@/app-components/common/Warning';
import { FiAlertIcon } from '@/assets/icons';
import { WarningInner } from '@blowfishxyz/api-client/v20230605';
import { Flex } from '@chakra-ui/react';

interface BlowFishWaningProps {
	warnings: WarningInner[];
}
export const BlowFishWaning: React.FunctionComponent<BlowFishWaningProps> = ({ warnings }) => {
	return (
		<Flex direction="column" width="100%" gap={2}>
			{warnings?.map((wr, i) => {
				const isWarning = wr.severity === 'WARNING';
				return (
					<Warning
						icon={<FiAlertIcon />}
						key={i}
						msg={wr.message}
						status={isWarning ? 'warning' : 'error'}
						title={`Transaction Warning ${wr.severity === 'CRITICAL' ? 'CRITICAL' : ''}`}
						background={isWarning ? 'rgba(255, 77, 0, 0.08)' : 'rgba(227, 42, 42, 0.08)'}
						style={{
							color: isWarning ? '#FF4D00' : '#E32A2A',
						}}
					/>
				);
			})}
		</Flex>
	);
};
