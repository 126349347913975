import { axiosBotAPI } from '@/app-cores/api/axios';

export const ServiceTranslate = {
	getLanguage: async (lang: 'en'): Promise<any> => {
		const response = await axiosBotAPI.get<any>(`translate/${lang}`);
		return response.data;
	},
	addLanguage: async (payload: any): Promise<any> => {
		const response = await axiosBotAPI.post<any>('translate', payload);
		return response.data;
	},
};
