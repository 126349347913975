import { create } from 'zustand';
const initializeData = {
	isOpen: false,
	onScan: null,
};
export interface IQrCodeStore {
	isOpen: boolean;
	onScan: any;
	setOpen: (open: boolean) => void;
	setOnScan: (cb: (uri: string) => void) => void;
	reset: () => void;
}

export const useQrCodeReaderStore = create<IQrCodeStore>((set) => ({
	...initializeData,
	setOpen: (open) => set({ isOpen: open }),
	setOnScan: (cb) => set({ onScan: cb }),
	reset: () => set(initializeData),
}));
