import { TelegramCore } from '@/app-cores/telegram';

export class TGCloudStorage {
	static async getItem<T>(key: string, transform?: (string) => T): Promise<T> {
		return new Promise((resolve, reject) => {
			TelegramCore.WebApp.CloudStorage.getItem(key, (error, response) => {
				if (error == null) {
					resolve(transform ? transform(response) : (response as T));
				} else reject(error);
			});
		});
	}

	static async setItem(key: string, value: string, onFinish?: () => void) {
		return new Promise((resolve, reject) => {
			TelegramCore.WebApp.CloudStorage.setItem(key, value, (error, response) => {
				if (error == null && response == true) {
					onFinish && onFinish();
					resolve(response);
				} else reject(Error(`Telegram Cloud Storage store failed with key: ${key}`));
			});
		});
	}

	static async removeItems(keys: string[]) {
		return new Promise((resolve, reject) => {
			TelegramCore.WebApp.CloudStorage.removeItems(keys);
		});
	}
}
