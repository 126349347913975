import { CHAIN_CONFIG, ChainId, SUI_NATIVE_ADDRESS, TON_NATIVE_ADDRESS } from '@/app-constants/chains';
import { TokenInfo } from '@/app-cores/api/bff';
import { isNativeToken } from '@/app-helpers/address';
import { commonGetRoute, displayMaxFee, isRouteParamsEmpty } from '@/app-hooks/swap';
import {
	ArgsGetRoute,
	FEE_ACCOUNTS,
	SWAP_FEE_PERCENT,
	SwapAbstract,
	SwapType,
	UsdRouteInfo,
} from '@/app-hooks/swap/type';
import { ExtractRouteInfo, SwapProvider } from '@/app-hooks/swap/type';
import { GenericRoute, SelectedRoute } from '@/app-store/swap';
import { useMutation } from '@tanstack/react-query';
import { MpcWallet } from '@/app-cores/mpc-wallet/wallet';
import { useSubmitTonTransaction } from '@/app-features/app-ton-connect/hooks/useMutationSentTransaction';
import { AUTO_SLIPPAGE } from '@/app-views/swap/components/SlippageSetting';
import { uniqueId } from '@/app-helpers/random';

import { getQuote, buildTx, QuoteResponse, estimateGasFee } from '@7kprotocol/sdk-ts';
import { Transaction } from '@mysten/sui/transactions';
import { suiWallet } from '@/app-cores/mpc-wallet/sui';
import { useTransactionWatcherStore } from '@/app-store';
import { BuildTxParams } from 'node_modules/@7kprotocol/sdk-ts/lib/esm/types/types/tx';
import { ethers, parseUnits } from 'ethers';
import { getNativeToken } from '@/app-helpers/token';
import { SUI_TYPE_ARG } from '@mysten/sui/utils';

const getAddress = (token: TokenInfo) =>
	isNativeToken(token?.address)
		? SUI_TYPE_ARG
		: token.address.includes(':')
		? token.address
		: `${token.address}::${token?.symbol?.toLowerCase()}::${token?.symbol}`;

type BuildRouteArgs = {
	route: Route7K;
	slippage: number;
	extendTx?: BuildTxParams['extendTx'];
};
class SevenKAgg extends SwapAbstract<Route7K> {
	swapData: Awaited<ReturnType<typeof buildTx>> = null;
	async getRoute(paramsSwap: ArgsGetRoute, signal: AbortSignal) {
		const payload = getRouteParamsSwap(paramsSwap);
		if (!payload) return;
		const data: Route7K = await getQuote(payload);
		const feeUsd = await this.estimateFee({
			route: data,
			slippage: paramsSwap.slippage,
		});
		data.feeUsd = feeUsd;
		if (signal.aborted) throw new Error('Cancelled');
		return formatRoute(data, paramsSwap);
	}

	async estimateFee(data: BuildRouteArgs) {
		try {
			const feeInUsd = await estimateGasFee(this._getPayloadBuildRoute(data));
			return feeInUsd;
		} catch (error) {
			return 0;
		}
	}

	private _getPayloadBuildRoute({ route, slippage, extendTx }: BuildRouteArgs) {
		const payload: BuildTxParams = {
			quoteResponse: route,
			accountAddress: MpcWallet.getSuiWalletAddress(),
			slippage: this.formatSlippage(slippage),
			commission: {
				partner: FEE_ACCOUNTS.SUI,
				commissionBps: SWAP_FEE_PERCENT * 100, // 0 means no fee, 1bps = 0.01%, for example, 20bps = 0.2%
			},
		};
		if (extendTx) payload.extendTx = extendTx;
		return payload;
	}
	async buildRoute({
		route,
		slippage,
	}: {
		route: SelectedRoute<Route7K>;
		slippage: number;
	}): Promise<SelectedRoute<GenericRoute>> {
		const result = await buildTx(
			this._getPayloadBuildRoute({
				route: route.route,
				slippage,
			}),
		);
		this.swapData = result;
		return route;
	}
	formatSlippage(slippage: string | number): number | string {
		return +slippage === AUTO_SLIPPAGE ? 0.01 : +slippage / 100;
	}

	extractRoute(params: SelectedRoute<Route7K>, prices: UsdRouteInfo): ExtractRouteInfo {
		return getExtractRoute(params, prices);
	}
}
export const SevenKSwap = new SevenKAgg();
const getRouteParamsSwap = (args: ArgsGetRoute) => {
	const { tokenIn, tokenOut, amountIn } = args;
	if (isRouteParamsEmpty(args)) return;
	const params = {
		tokenIn: getAddress(tokenIn),
		tokenOut: getAddress(tokenOut),
		amountIn,
	};
	return params;
};

export type Route7K = QuoteResponse & { feeUsd?: number };

const formatRoute = (routeData: Route7K, params: ArgsGetRoute): SelectedRoute<Route7K> =>
	routeData
		? {
				route: routeData,
				routerAddress: '',
				id: uniqueId(),
				provider: SwapProvider.SEVEN_K_SWAP,
				timestamp: Date.now(),
				tokenIn: params.tokenIn,
				tokenOut: params.tokenOut,
				params, // todo remove all tokenin token out in route
		  }
		: undefined;

const getExtractRoute = (
	selectedRoute: SelectedRoute<Route7K>,
	{ usdPriceIn, usdPriceOut, usdPriceNative }: UsdRouteInfo = {},
): ExtractRouteInfo => {
	const routeSwap = selectedRoute?.route;
	const tokenIn = selectedRoute?.tokenIn;
	const tokenOut = selectedRoute?.tokenOut;
	const gasUsdFrom7k = routeSwap?.feeUsd;
	const native = getNativeToken(ChainId.SUI);
	const showMaxFee = !gasUsdFrom7k;

	const gasNative =
		usdPriceNative && gasUsdFrom7k
			? parseUnits((gasUsdFrom7k / usdPriceNative)?.toFixed(native.decimals), native.decimals)
			: BigInt(CHAIN_CONFIG[ChainId.SUI].minForGas);

	const gasUsd =
		gasUsdFrom7k ||
		(gasNative && usdPriceNative ? +ethers.formatUnits(gasNative, native?.decimals) * usdPriceNative : undefined);

	return {
		amountOut: routeSwap?.returnAmountWithDecimal,
		amountIn: routeSwap?.swapAmountWithDecimal,
		tokenIn,
		tokenOut,
		gasUsd,
		gasNative,
		gasDisplay: showMaxFee
			? displayMaxFee({
					usdPriceNative,
					chainId: ChainId.SUI,
					gasNative: gasNative,
			  })
			: ``,
		dappInfo: {
			logo: '/icons/brands/7k.png',
			domain: SwapProvider.SEVEN_K_SWAP,
		},
	};
};

export const useExecuteRoute7K = () => {
	const { addPendingSuiTransaction } = useTransactionWatcherStore();
	const response = useMutation({
		mutationKey: ['build-route-7k'],
		mutationFn: async (routeData: SelectedRoute<Route7K>) => {
			const { digest } = await suiWallet.signAndExecuteTransactionBlock({
				transactionBlock: SevenKSwap.swapData.tx,
				options: {
					showInput: true,
					showEffects: true,
					showEvents: true,
				},
			});
			return { hash: digest };
		},
		onSuccess: ({ hash }, route) => {
			// todo take fee?
			addPendingSuiTransaction({
				hash,
				trackingData: route,
			});
		},
	});
	return response;
};
