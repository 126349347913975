export const useScanTransaction = () => {};
import { keepPreviousData, useQuery } from '@tanstack/react-query';
import { TSolScanTransaction } from '../../services/BlowFish/type';
import { BlowFistClientAPI } from '../../services/BlowFish/browser';

export const useScanSolTransaction = (requestId: number, payload: TSolScanTransaction) => {
	const res = useQuery({
		queryKey: ['blowfish-scan-sol-transaction', requestId],
		queryFn: async () => {
			return null;
			const data = await BlowFistClientAPI.scanSolTransaction(payload);
			return data;
		},
		placeholderData: keepPreviousData,
	});
	return res;
};
