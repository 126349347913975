import TokenLogo from '@/app-components/common/Avatar/TokenLogo';
import { CustomRadio } from '@/app-components/common/CustomRadio';
import { formatCurrency, formatUnits, formatUsd } from '@/app-helpers/number';
import { SwapService } from '@/app-hooks/swap';
import { formatTimeDuration } from '@/app-helpers/time';
import { getNativeToken, getTokenId, isEvmChain } from '@/app-helpers/token';
import { SelectedRoute, SwapDisableType } from '@/app-store/swap';
import { BASE_BORDER_COLOR, colors } from '@/app-theme/theme';
import ProviderNote from '@/app-views/swap/components/ProviderNote';
import { ChevronRightIcon } from '@/assets/images/svg';
import {
	Card,
	Flex,
	Divider,
	Text,
	Box,
	useDisclosure,
	Drawer,
	DrawerOverlay,
	DrawerCloseButton,
	DrawerBody,
	DrawerHeader,
	DrawerContent,
	Radio,
	Center,
} from '@chakra-ui/react';

import { CSSProperties, useEffect, useState } from 'react';
import { FiSwapIcon } from '@/assets/icons';
import { TokenPriceData, usePriceNativeToken } from '@/app-hooks/api/portfolio/useTokenPrices';
import { NATIVE_TOKEN_ADDRESS } from '@/app-constants/chains';
import { isNativeToken } from '@/app-helpers/address';
import { ONE_MINUTE } from '@/app-hooks/api/portfolio/constant';
import { TooltipInfo } from '@/app-components/common';
import { useTranslation } from 'react-i18next';

const invertRateCache = {};
const RouteRateInfo = ({ route, showUsd }: { route: SelectedRoute; showUsd?: boolean }) => {
	const { tokenIn, tokenOut, rate: rawRate } = SwapService.extractRouteInfo({ route });
	const routePairKey = getTokenId(tokenIn) + getTokenId(tokenOut);
	const [invert, _setInvert] = useState(
		typeof invertRateCache[routePairKey] === 'boolean' ? invertRateCache[routePairKey] : true,
	);

	const setInvert = (v: boolean) => {
		_setInvert(v);
		invertRateCache[routePairKey] = v;
	};

	const rate = rawRate ** (invert ? -1 : 1);
	const tokenLeft = invert ? tokenOut : tokenIn;
	const tokenRight = invert ? tokenIn : tokenOut;

	const usdPrice = tokenRight?.priceUsd;
	const useValue = rate * usdPrice;

	if (!rate) return null;
	return (
		<Flex
			whiteSpace={'nowrap'}
			lineHeight={'16px'}
			alignItems={'center'}
			fontWeight={'500'}
			gap={'4px'}
			fontSize={'10px'}
			onClick={(e) => {
				e.stopPropagation();
				setInvert(!invert);
			}}
		>
			1
			<Text as="span" color={'gray.400'}>
				{tokenLeft?.symbol}
			</Text>
			≈ {formatCurrency(rate)}{' '}
			<Text as="span" color={'gray.400'}>
				{tokenRight?.symbol}
			</Text>
			{showUsd && !!useValue && <>≈ {formatUsd(useValue)}</>}
			<FiSwapIcon height={12} style={{ marginLeft: '-6px' }} />
		</Flex>
	);
};

export const renderFeeRoute = ({
	gasDisplay,
	gasUsd,
	gasNative,
	native,
}: {
	gasDisplay;
	gasUsd;
	gasNative;
	native;
}): string => {
	if (gasDisplay) return gasDisplay;
	const gasNativeStr = gasNative ? `${formatUnits(gasNative.toString(), native?.decimals)} ${native?.symbol}` : '';
	if (!gasUsd) return gasNativeStr ? `≈ ${gasNativeStr}` : '';
	return `≈ ${formatUsd(gasUsd)} ${gasNativeStr ? `(${gasNativeStr})` : ``}`;
};

const RouteTag = ({ msg }: { msg: string }) => (
	<Box
		textTransform={'unset'}
		fontSize={'8px'}
		background={'rgba(0, 193, 112, 0.08)'}
		color="green.200"
		padding={'2px 4px'}
		fontWeight={'500'}
		borderColor={'green.200'}
		borderWidth={'1px'}
		borderRadius={'100px'}
		whiteSpace={'nowrap'}
		mr={5}
	>
		{msg}
	</Box>
);
export default function RouteItem({
	index,
	route,
	selectedRoute,
	selected,
	onClick,
	onChangeRoute,
	onChangeHeigh,
	isChildren,
}: {
	isChildren?: boolean;
	index?: number;
	route: SelectedRoute;
	selectedRoute?: SelectedRoute;
	selected: boolean;
	onClick: () => void;
	onChangeRoute: (v: SelectedRoute) => void;
	onChangeHeigh?: (id: number, height: string) => void;
}) {
	const { allRoutes = [], disabled, provider, bestReturn, lowestFee } = route;
	const isDisabledByIP = disabled === SwapDisableType.LOCATION;

	const usdPriceIn = route?.tokenIn?.priceUsd;
	const usdPriceOut = route?.tokenOut?.priceUsd;
	const { data: usdPriceNative } = usePriceNativeToken({ chainId: route?.tokenIn?.chainId });

	const {
		amountIn,
		amountOut,
		tokenIn,
		tokenOut,
		amountInUsd,
		amountOutUsd,
		gasUsd,
		duration,
		gasNative,
		gasDisplay,
	} = SwapService.extractRouteInfo({
		route,
		usdPriceIn,
		usdPriceOut,
		usdPriceNative,
	});

	const gasWarning = +gasUsd >= 5;

	const { isOpen, onOpen, onClose } = useDisclosure();
	const { t } = useTranslation();

	const native = getNativeToken(tokenIn?.chainId);

	const feeInfo = renderFeeRoute({ gasDisplay, gasNative, gasUsd, native });
	const logoStyle: CSSProperties = isDisabledByIP
		? {
				filter: 'grayscale(100%)',
		  }
		: undefined;

	return (
		<Card
			ref={(ref) => {
				if (ref && index !== undefined) {
					onChangeHeigh?.(index, `${ref.getBoundingClientRect().height}px`);
				}
			}}
			onClick={disabled && !isChildren ? undefined : onClick}
			padding={'12px 14px'}
			borderRadius={'16px'}
			backgroundColor={'gray.100'}
			display={'flex'}
			gap={'10px'}
			border={`1px solid ${selected ? 'cyan' : 'transparent'}`}
			position={'relative'}
		>
			<Flex justifyContent={'space-between'}>
				<Flex gap={'8px'} alignItems={'center'}>
					<ProviderNote
						provider={route?.subRoutes ? route?.subRoutes?.map((e) => e.provider) : provider}
						shorter={isChildren}
					/>
					<RouteRateInfo route={route} showUsd={!isChildren} />
				</Flex>

				{bestReturn && isChildren ? (
					<RouteTag msg={'Best Return'} />
				) : (
					lowestFee && isChildren && isEvmChain(tokenIn?.chainId) && <RouteTag msg="Lowest Fee" />
				)}

				<CustomRadio
					selected={selected}
					style={{
						position: 'absolute',
						right: '6px',
						top: '4px',
					}}
				/>
			</Flex>
			<Flex gap={'10px'} alignItems={'center'}>
				<TokenLogo
					size={32}
					chainId={tokenIn?.chainId}
					symbol={tokenIn?.symbol}
					logo={tokenIn?.logo}
					style={logoStyle}
				/>

				{isDisabledByIP ? (
					<Text
						textAlign={'center'}
						flex={1}
						fontSize={'14px'}
						color={'orange.100'}
						background={`rgba(0, 0, 0, 0.1)`}
						padding={'8px 0px'}
						borderRadius={'12px'}
						fontWeight={'500'}
						zIndex={1}
					>
						Unavailable in your region
					</Text>
				) : (
					<Flex flex={1} alignItems={'center'} flexDirection={'column'} gap={'4px'}>
						{feeInfo && (
							<Text fontSize={'10px'} color={gasWarning ? colors.orange[100] : undefined}>
								Fee: {feeInfo}
							</Text>
						)}
						<Flex width={'100%'} alignItems={'center'} position={'relative'}>
							<Divider variant="dashed" width={'100%'} />
							<ChevronRightIcon
								style={{
									right: '-10px',
									top: '-10px',
									width: '16px',
									position: 'absolute',
									color: 'rgba(0, 0, 0, 0.32)',
								}}
							/>
						</Flex>
						{duration && (
							<Flex fontSize={'10px'} onClick={(e) => e.stopPropagation()} gap={'4px'} align={'center'}>
								≈ {formatTimeDuration(duration)}
								{duration * 1000 >= ONE_MINUTE * 5 && (
									<TooltipInfo label={t('tokenTrading.slowRoute')} color="#FF4D00" />
								)}
							</Flex>
						)}
					</Flex>
				)}

				<TokenLogo
					symbol={tokenOut?.symbol}
					size={32}
					chainId={tokenOut?.chainId}
					logo={tokenOut?.logo}
					style={logoStyle}
				/>
			</Flex>

			<Box>
				<Flex
					justifyContent={'space-between'}
					fontSize={'12px'}
					gap={'4px'}
					fontWeight={'500'}
					alignItems={'center'}
				>
					{formatUnits(amountIn, tokenIn?.decimals)} {tokenIn?.symbol}
					<Text as={'span'} textAlign={'right'}>
						{formatUnits(amountOut, tokenOut?.decimals)} {tokenOut?.symbol}
					</Text>
				</Flex>

				<Flex
					justifyContent={'space-between'}
					color={'gray.400'}
					fontSize={'10px'}
					gap={'4px'}
					alignItems={'center'}
				>
					{formatUsd(+amountInUsd) || <div />}
					<Text as={'span'} textAlign={'right'}>
						{formatUsd(+amountOutUsd)}
					</Text>
				</Flex>
			</Box>

			{allRoutes?.length > 1 && (
				<>
					<Divider variant="dashed" sx={{ borderColor: BASE_BORDER_COLOR }} />
					<Flex
						justifyContent={'center'}
						fontWeight={'500'}
						alignItems={'center'}
						fontSize={'12px'}
						onClick={(e) => {
							e.stopPropagation();
							onOpen();
						}}
					>
						Other Routes{' '}
						<ChevronRightIcon height={20} width={20} style={{ transform: 'translateY(1px)' }} />
					</Flex>
					<Drawer isOpen={isOpen} placement="bottom" onClose={onClose}>
						<DrawerOverlay />
						<DrawerContent>
							<DrawerCloseButton />
							<DrawerHeader borderWidth={'1px'} borderColor={BASE_BORDER_COLOR}>
								All routes
							</DrawerHeader>

							<DrawerBody display={'flex'} flexDirection={'column'} gap={4} pb={4}>
								{allRoutes?.map((e, i) => {
									return (
										<RouteItem
											isChildren
											onChangeRoute={onChangeRoute}
											route={e}
											key={e.id + i}
											selected={selectedRoute?.id === e.id}
											onClick={() => {
												onChangeRoute(e);
												onClose();
											}}
										/>
									);
								})}
							</DrawerBody>
						</DrawerContent>
					</Drawer>
				</>
			)}

			{/* {isDisabledByIP && (
				<Center
					sx={{
						position: 'absolute',
						top: 0,
						left: 0,
						height: '100%',
						width: '100%',
						borderRadius: '16px',
					}}
					backgroundColor={'#F9F9F9'}
					opacity={0.5}
				/>
			)} */}
		</Card>
	);
}
