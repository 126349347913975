import { Badge } from '@/app-components/common/Badge';
import { CHAIN_CONFIG, ChainId } from '@/app-constants/chains';
import { isTestnetChain } from '@/app-helpers/token';
import useChainList from '@/app-hooks/wallet/useChainList';
import { BASE_BORDER_COLOR } from '@/app-theme/theme';
import { TokenLabel } from '@/app-views/portfolio/components/ListCrypto';
import { AppLogoIconCircle } from '@/assets/images/svg';
import { ChevronDownIcon } from '@/assets/images/svg/chevron-down-icon';
import {
	Box,
	Button,
	Flex,
	Image,
	Menu,
	MenuButton,
	MenuItem,
	MenuList,
	Placement,
	SystemStyleObject,
	Text,
} from '@chakra-ui/react';
import { isEmpty } from 'lodash';
import { CSSProperties, ReactNode, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

const ChainOption = ({
	value,
	label,
	onClick,
	style,
	logoSize = '26px',
	badge,
}: {
	value: string | ChainId;
	label: ReactNode;
	onClick?: () => void;
	style?: SystemStyleObject;
	logoSize?: string;
	badge?: string;
}) => {
	return (
		<Flex
			sx={{ cursor: 'pointer', userSelect: 'none', whiteSpace: 'nowrap', ...style }}
			alignItems={'center'}
			gap={'8px'}
			onClick={onClick}
		>
			{value ? (
				<Image src={CHAIN_CONFIG[value]?.logo} width={logoSize} height={logoSize} />
			) : (
				<AppLogoIconCircle width={logoSize} height={logoSize} />
			)}
			<Flex align={'center'} gap={1}>
				{label || CHAIN_CONFIG[value].name}
				{!!badge && (
					<Badge
						type="danger"
						sx={{
							fontSize: '10px',
							width: '16px',
							height: '16px',
						}}
					>
						{badge}
					</Badge>
				)}
			</Flex>
		</Flex>
	);
};

const ChainSelect = ({
	value,
	onChange,
	allChain = true,
	style,
	menuStyle,
	placeholder,
	logoSize,
	placement,
	chainIds,
	badgeInfo,
}: {
	value: ChainId | string;
	onChange: (v: string) => void;
	allChain?: boolean;
	style?: SystemStyleObject;
	menuStyle?: CSSProperties;
	placeholder?: string;
	logoSize?: string;
	placement?: Placement;
	chainIds?: ChainId[];
	badgeInfo?: Partial<Record<ChainId, any>>;
}) => {
	const { t } = useTranslation();
	const { chainsOptions } = useChainList();
	const options = useMemo(() => {
		let res = chainsOptions;
		if (!isEmpty(chainIds)) {
			res = res.filter((c) => chainIds.some((el) => el.toString() === c.value.toString()));
		}
		return allChain ? [{ label: t('cryptos.allChains'), value: '' }, ...res] : res;
	}, [allChain, t, chainIds, chainsOptions]);

	return (
		<Menu placement={placement}>
			<MenuButton
				as={Button}
				variant="ghost"
				backgroundColor="white"
				fontSize="sm"
				fontWeight={400}
				rightIcon={<ChevronDownIcon width={16} height={16} />}
				borderRadius={16}
				size="sm"
				_active={{
					backgroundColor: style?.background ?? 'white',
				}}
				_hover={{
					backgroundColor: style?.background ?? 'white',
				}}
				sx={style}
			>
				{value ? (
					<ChainOption
						logoSize={logoSize}
						value={value}
						label={options.find((e) => e.value === value)?.label}
					/>
				) : (
					placeholder || options[0]?.label
				)}
			</MenuButton>
			<MenuList
				minW="0"
				border={`1px solid ${BASE_BORDER_COLOR}`}
				pt={0}
				rounded={8}
				width={menuStyle?.width ?? 'fit-content'}
				maxHeight={menuStyle?.maxHeight}
				overflow="auto"
			>
				{options.map((chain) => {
					return (
						<MenuItem key={chain.label} as={Box} p={0}>
							<ChainOption
								logoSize={'24px'}
								style={{
									fontSize: '14px',
									fontWeight: '400',
									padding: '8px 14px',
									':hover': {
										background: 'gray.100',
									},
									width: '100%',
								}}
								key={chain.value}
								value={chain.value}
								badge={badgeInfo?.[chain.value]}
								label={
									<>
										{chain.label} {isTestnetChain(chain.value) && <TokenLabel type="testnet" />}
									</>
								}
								onClick={() => onChange(chain.value)}
							/>
						</MenuItem>
					);
				})}
			</MenuList>
		</Menu>
	);
};
export default ChainSelect;
