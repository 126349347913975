import React from 'react';

interface IProps extends React.SVGAttributes<HTMLOrSVGElement> {}

export const FiWaringIcon: React.FC<IProps> = ({ width = 16, height = 16, fill = 'white', ...rest }) => {
	return (
		<svg width={width} height={height} viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M0.666504 14.5002H15.3332L7.99984 1.8335L0.666504 14.5002ZM8.6665 12.5002H7.33317V11.1668H8.6665V12.5002ZM8.6665 9.8335H7.33317V7.16683H8.6665V9.8335Z"
				fill={fill}
			/>
		</svg>
	);
};
