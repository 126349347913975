import {
	Box,
	Button,
	Center,
	Drawer,
	DrawerBody,
	DrawerCloseButton,
	DrawerContent,
	DrawerHeader,
	DrawerOverlay,
	Text,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { FiCheckIcon } from '@/assets/icons';
import { useDepositStore } from '../store';

interface DepositResultProps {}
export const DepositResult: React.FC<DepositResultProps> = () => {
	const { isOpenDepositResult, setShowDepositResult, onClose, token, tokenInfo, amountDeposited, reset } =
		useDepositStore();
	const { t } = useTranslation();
	const handleClose = () => {
		onClose();
		reset();
	};
	return (
		<Drawer isOpen={isOpenDepositResult} onClose={handleClose} placement="bottom">
			<DrawerOverlay />
			<DrawerContent>
				<DrawerHeader borderBottomWidth="1px" borderBottomColor="rgba(0, 0, 0, 0.08)">
					<Text align="center" fontSize="16px" fontWeight={500}>
						{t('deposit.deposit')}
					</Text>
				</DrawerHeader>
				<DrawerCloseButton />
				<DrawerBody
					py={5}
					display="flex"
					justifyContent="space-between"
					alignItems="center"
					flexDirection="column"
					gap={5}
				>
					<Center width={14} height={14} borderRadius="100%" backgroundColor="#00E9DB">
						<FiCheckIcon />
					</Center>
					<Text textAlign="center" fontSize="28px" fontWeight={700} lineHeight="125%">
						{t('deposit.depositResult', {
							amount: amountDeposited,
							symbol: tokenInfo.symbol,
						})}
					</Text>
					<Button width="100%" colorScheme="cyan" size="lg" fontWeight="medium" onClick={handleClose}>
						{t('deposit.checkYourBalance')}
					</Button>
				</DrawerBody>
			</DrawerContent>
		</Drawer>
	);
};
