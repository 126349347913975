import { tronWallet } from '@/app-cores/mpc-wallet/tron';

export async function waitForTronTransaction(txID, timeout = 120000, interval = 3000) {
	return new Promise((resolve, reject) => {
		const startTime = Date.now();
		let checking = false;
		const checkTransaction = async () => {
			if (checking) return;
			checking = true;
			try {
				const txInfo = await tronWallet.tronWeb.trx.getTransactionInfo(txID);

				// Check if the transaction is confirmed
				if (txInfo.receipt && txInfo.receipt.result === 'SUCCESS') {
					console.log('Transaction confirmed successfully:', txInfo);
					clearInterval(intervalID); // Stop checking once confirmed
					resolve(txInfo); // Resolve the promise with transaction info
				} else {
					console.log({ txInfo });
				}
			} catch (err) {
				// Ignore errors if transaction is not found yet
				console.log('Waiting for transaction confirmation...', err, txID);
			}

			// Check if timeout is exceeded
			if (Date.now() - startTime >= timeout) {
				clearInterval(intervalID); // Stop checking on timeout
				reject(new Error('Transaction confirmation timed out'));
			}
			checking = false;
		};

		// Set the interval to check every `interval` milliseconds
		const intervalID = setInterval(checkTransaction, interval);
	});
}
