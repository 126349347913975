import { create } from 'zustand';
const initializeData = {
	isEnable: false,
};
export interface IDepositStore {
	isEnable: boolean;
	setEnable: (enable: boolean) => void;
	reset: () => void;
}

export const useCopilotStore = create<IDepositStore>((set, get) => ({
	...initializeData,
	setEnable: (isEnable: boolean) => {
		set({ isEnable: isEnable });
	},

	reset: () => set({ ...initializeData }),
}));
