import React from 'react';

interface IProps extends React.SVGAttributes<HTMLOrSVGElement> {}

export const FiPhantomWallet: React.FC<IProps> = ({ width = 56, height = 56, fill = 'white', ...rest }) => {
	return (
		<svg width={width} height={height} viewBox="0 0 1200 1200" fill="none" xmlns="http://www.w3.org/2000/svg">
			<g clipPath="url(#clip0_2596_138580)">
				<rect y="-0.000976562" width="1200" height="1200" fill="#AB9FF2" />
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M522.218 764.813C475.101 837.011 396.147 928.377 291.089 928.377C241.425 928.377 193.671 907.932 193.671 819.121C193.671 592.942 502.479 242.812 789.003 242.812C952.003 242.812 1016.95 355.901 1016.95 484.325C1016.95 649.167 909.979 837.65 803.647 837.65C769.901 837.65 753.346 819.121 753.346 789.731C753.346 782.064 754.62 773.758 757.167 764.813C720.874 826.788 650.835 884.292 585.253 884.292C537.499 884.292 513.304 854.262 513.304 812.093C513.304 796.759 516.487 780.786 522.218 764.813ZM769.035 479.871C769.035 517.293 746.956 536.003 722.258 536.003C697.185 536.003 675.481 517.293 675.481 479.871C675.481 442.449 697.185 423.738 722.258 423.738C746.956 423.738 769.035 442.449 769.035 479.871ZM909.367 479.872C909.367 517.294 887.288 536.005 862.59 536.005C837.517 536.005 815.813 517.294 815.813 479.872C815.813 442.45 837.517 423.74 862.59 423.74C887.288 423.74 909.367 442.45 909.367 479.872Z"
					fill="#FFFDF8"
				/>
			</g>
			<defs>
				<clipPath id="clip0_2596_138580">
					<rect y="-0.000976562" width="1200" height="1200" rx="600" fill="white" />
				</clipPath>
			</defs>
		</svg>
	);
};
