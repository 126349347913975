import { FiInfoIcon } from '@/assets/icons';
import { Tooltip } from '..';

interface InfoTooltipProps {
	label: React.ReactNode;
	color?: string;
}
export const TooltipInfo: React.FC<InfoTooltipProps> = ({ label, color }) => {
	return (
		<Tooltip hasArrow color="black" placement="top" label={label} pointerEvents="all">
			<FiInfoIcon fill={color} />
		</Tooltip>
	);
};
