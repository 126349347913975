import React, { CSSProperties } from 'react';

interface Iprops {
	width?: number;
	height?: number;
	className?: string;
	style?: CSSProperties;
	onClick?: () => void;
}

export const ChevronRightIcon: React.FC<Iprops> = ({ className, width = 24, height = 24, style, onClick }) => {
	return (
		<svg
			onClick={onClick}
			style={style}
			width={width}
			height={height}
			className={className}
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M6.91107 4.91058C7.23651 4.58514 7.76414 4.58514 8.08958 4.91058L13.0896 9.91058C13.415 10.236 13.415 10.7637 13.0896 11.0891L8.08958 16.0891C7.76414 16.4145 7.23651 16.4145 6.91107 16.0891C6.58563 15.7637 6.58563 15.236 6.91107 14.9106L11.3218 10.4998L6.91107 6.08909C6.58563 5.76366 6.58563 5.23602 6.91107 4.91058Z"
				fill="currentColor"
				fillRule="evenodd"
				clipRule="evenodd"
			/>
		</svg>
	);
};
