import { formatJsonRpcError, formatJsonRpcResult } from '@json-rpc-tools/utils';
import { SignClientTypes } from '@walletconnect/types';
import { getSdkError } from '@walletconnect/utils';
import bs58 from 'bs58';
import { VersionedTransaction } from '@solana/web3.js';
import { MpcWallet } from '@/app-cores/mpc-wallet/wallet';
import { SolWallet } from '@/app-cores/mpc-wallet/solana/SolWallet';
import { SOLANA_SIGNING_METHODS } from '../data/SolanaData';

export async function approveSolanaRequest(requestEvent: SignClientTypes.EventArguments['session_request']) {
	const { params, id } = requestEvent;
	const { request } = params;
	const { fromPubKey, connection } = await SolWallet.init('mainnet-beta', {
		commitment: 'confirmed',
	});
	switch (request.method) {
		case SOLANA_SIGNING_METHODS.SOLANA_SIGN_MESSAGE: {
			const signature = await MpcWallet.signEddsaMessage(bs58.decode(request.params.message));
			const bs58Signature = bs58.encode(signature);
			return formatJsonRpcResult(id, {
				signature: bs58Signature,
			});
		}

		case SOLANA_SIGNING_METHODS.SOLANA_SIGN_TRANSACTION: {
			const swapTransactionBuf = Buffer.from(request.params.transaction, 'base64');
			const transaction = VersionedTransaction.deserialize(swapTransactionBuf);
			transaction.addSignature(
				fromPubKey,
				await MpcWallet.signEddsaMessage(Buffer.from(transaction.message.serialize())),
			);
			const txnSignature = await connection.sendRawTransaction(transaction.serialize());

			return formatJsonRpcResult(id, {
				signature: txnSignature,
			});
		}

		default:
			throw new Error(getSdkError('INVALID_METHOD').message);
	}
}

export function rejectSolanaRequest(request: SignClientTypes.EventArguments['session_request']) {
	const { id } = request;
	return formatJsonRpcError(id, getSdkError('USER_REJECTED_METHODS').message);
}
