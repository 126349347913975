import { motion, useAnimation } from 'framer-motion';
import { isDesktop } from 'react-device-detect';
import { Box } from '@chakra-ui/react';
import { useCallback, useEffect, useRef, useState } from 'react';
import { ChatBoxHeader } from './Header';
import { MessagesContainer } from './MessageContainer';

interface MessageBoxProps {
	open: boolean;
	onClose: () => void;
	onCloseComplete: () => void;
}
const INIT_BOX_SIZE = {
	WIDTH: 300,
	HEIGHT: isDesktop ? 400 : 500,
};
export const MessageBox: React.FC<MessageBoxProps> = ({ open, onClose, onCloseComplete }) => {
	const [boxSize, setBoxSize] = useState({
		width: INIT_BOX_SIZE.WIDTH,
		height: INIT_BOX_SIZE.HEIGHT,
	});
	const preBoxSizeRef = useRef({
		width: INIT_BOX_SIZE.WIDTH,
		height: INIT_BOX_SIZE.HEIGHT,
	});
	const popupAnimation = useAnimation();
	const clientRef = useRef({
		width: document.body.clientWidth,
		height: document.body.clientHeight,
	});
	useEffect(() => {
		async function handlePopupAnimation() {
			if (!open) {
				await popupAnimation.start({
					scaleY: 0,
					scaleX: 0,
				});
				onCloseComplete();
			} else {
				popupAnimation.start({
					scaleY: 1,
					scaleX: 1,
					width: boxSize.width,
					height: boxSize.height,
				});
			}
		}
		handlePopupAnimation();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [open, boxSize]);

	const handleFocusInputOnIOS = useCallback((isFocus: boolean) => {
		if (isFocus) {
			setBoxSize((pre) => ({
				...pre,
				height: clientRef.current.height - 260,
			}));
		} else {
			setBoxSize(preBoxSizeRef.current);
		}
	}, []);

	return (
		<Box className={`tobi-copilot-box-container ${open ? 'open' : ''}`}>
			<motion.div
				style={{
					position: 'absolute',
					bottom: 62,
					right: 0,
					height: INIT_BOX_SIZE.HEIGHT,
					overflow: 'hidden',
					backgroundColor: '#fff',
					boxShadow: ' 0px 8px 32px 0px rgba(39, 39, 39, 0.16)',
					borderRadius: 12,
					padding: '12px 0px',
					display: 'flex',
					flexDirection: 'column',
				}}
				animate={popupAnimation}
				initial={{
					scaleY: 0,
					originY: 1,
					scaleX: 0,
					originX: 0.95,
					width: INIT_BOX_SIZE.WIDTH,
					height: INIT_BOX_SIZE.HEIGHT,
				}}
			>
				<ChatBoxHeader
					isExpand={boxSize.width !== INIT_BOX_SIZE.WIDTH}
					onExpand={() => {
						const newWidth =
							boxSize.width === INIT_BOX_SIZE.WIDTH ? clientRef.current.width - 24 : INIT_BOX_SIZE.WIDTH;
						const newHeight =
							boxSize.width === INIT_BOX_SIZE.WIDTH
								? clientRef.current.height - 110
								: INIT_BOX_SIZE.HEIGHT;
						setBoxSize({
							width: newWidth,
							height: newHeight,
						});
						preBoxSizeRef.current = {
							width: newWidth,
							height: newHeight,
						};
					}}
					onClose={onClose}
				/>
				<MessagesContainer isVisible={open} onFocusInputOnIOS={handleFocusInputOnIOS} />
			</motion.div>
		</Box>
	);
};
