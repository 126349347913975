import {
	Center,
	Drawer,
	DrawerBody,
	DrawerCloseButton,
	DrawerContent,
	DrawerHeader,
	DrawerOverlay,
	Text,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useWalletAddressStore } from '@/app-store';
import { Addresses } from './Addresses';

interface WalletAddressPopupProps {}

const WalletAddressPopup: React.FC<WalletAddressPopupProps> = () => {
	const { t } = useTranslation();
	const { isOpen, setOpen } = useWalletAddressStore();

	return (
		<Drawer
			isOpen={isOpen}
			placement="bottom"
			onClose={() => {
				setOpen(false);
			}}
			trapFocus={false}
		>
			<DrawerOverlay />
			<DrawerContent>
				<DrawerCloseButton top="0.75rem" />
				<DrawerHeader borderBottomWidth="1px" borderBottomColor="gray.100">
					{t('myAddresses')}
				</DrawerHeader>

				<DrawerBody mt={3}>
					<Addresses />
				</DrawerBody>
			</DrawerContent>
		</Drawer>
	);
};
export default WalletAddressPopup;
