import { computeAddress } from 'ethers';
import CryptoJS from 'crypto-js';
import { REQUIRE_IMPORT_PRE_BACKUP_KEY_SHARE } from '.';
import { STORAGE_KEYS } from '@/app-constants/storage';
import { isEmpty } from 'lodash-es';

export function getWalletAddress(publicKey: string) {
	if (!publicKey) return '';
	if (!publicKey.startsWith('0x')) {
		publicKey = '0x' + publicKey;
	}
	return computeAddress(publicKey);
}
export const encryptKeyShare2 = (text: string, encryptKey: string) => {
	return CryptoJS.AES.encrypt(text, encryptKey).toString();
};
export const decryptKeyShare2 = (text: string, encryptKey: string) => {
	const decrypted = CryptoJS.AES.decrypt(text, encryptKey);
	return decrypted.toString(CryptoJS.enc.Utf8);
};

export const compareUint8Arrays = (arr1: Uint8Array, arr2: Uint8Array) => {
	if (arr1?.length !== arr2?.length) return false;
	return arr1.every((value, index) => value === arr2[index]);
};

export const setRequireImportPreBackupKeyShare = (value) => {
	localStorage.setItem(STORAGE_KEYS.TOBI_REQUIRE_IMPORT_PRE_BACKUP_KEY_SHARE, value);
};
export const getRequireImportPreBackupKeyShare = () => {
	return localStorage.getItem(STORAGE_KEYS.TOBI_REQUIRE_IMPORT_PRE_BACKUP_KEY_SHARE);
};

export const getUnsecuredKeyStore = () => {
	let unsecuredKey = localStorage.getItem(STORAGE_KEYS.TOBI_UNSECURED_KEY_STORE);
	if (isEmpty(unsecuredKey)) {
		unsecuredKey = localStorage.getItem(STORAGE_KEYS.TOBI_UNSECURED_KEY_STORE_OLD);
	}
	return unsecuredKey;
};
