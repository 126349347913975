import { getSdkError } from '@walletconnect/utils';
import { useSnapshot } from 'valtio';
import { SessionTypes } from '@walletconnect/types';
import SettingsStore from '../store/SettingsStore';
import { useEffect, useState } from 'react';
import { paringTopicManager } from '../utils/HelperUtil';
import toArray from 'lodash/toArray';
import isEmpty from 'lodash/isEmpty';
import { web3wallet } from '../utils/WalletConnectUtil';
import { STORAGE_KEYS } from '@/app-constants/storage';

export const useConnectedManagerInApp = (hostname: string) => {
	const [isConnected, setIsConnected] = useState(false);
	const [session, setSession] = useState<SessionTypes.Struct>(null);
	const { sessions, initialized } = useSnapshot(SettingsStore.state);
	useEffect(() => {
		if (!initialized) return;
		if (isEmpty(sessions)) {
			localStorage.removeItem(STORAGE_KEYS.TOBI_PAIRING_TOPICS);
			setSession(null);
			setIsConnected(false);
		}
		if (isEmpty(sessions) || !hostname) return;
		const paringTopic = paringTopicManager.getTopic(hostname);
		const sessionsArr = toArray(sessions);
		const session = sessionsArr.find((ss) => {
			return ss.pairingTopic === paringTopic;
		});
		const isExist = !isEmpty(session);
		setIsConnected(isExist);
		setSession(session as SessionTypes.Struct);
		if (!isExist) {
			paringTopicManager.removeTopic(hostname);
		}
	}, [sessions, hostname, initialized]);

	const disconnect = async (session: SessionTypes.Struct) => {
		await web3wallet.disconnectSession({
			topic: session.topic,
			reason: getSdkError('USER_DISCONNECTED'),
		});
		SettingsStore.setSessions(Object.values(web3wallet.getActiveSessions()));
	};
	return { isConnected, session, disconnect };
};
